import {
  Button,
  Container,
  FormControl,
  Grid,
  InputLabel,
  LinearProgress,
  MenuItem,
  Paper,
  Select,
  SelectChangeEvent,
  TextField,
  Typography,
} from "@mui/material";
import {
  ChangeEvent,
  FC,
  FormEvent,
  FormEventHandler,
  ReactElement,
  useEffect,
  useState,
} from "react";
import CommonSnackBar from "../../Components/Common/CommonSnackbar";
import useValidation from "../../Hooks/useTypescriptValidation";
import { getRequest, postRequestMethod } from "../../api/api";
import { FEEDBACK_FORM_GET, POST_COMPLAINT_FEEDBACK } from "../../api/server";

interface ComplainFormProps {
  loginId: string;
}

interface Complain {
  against: string;
  by: string;
  complain: string;
  suggestion: string;
}

const ComplainForm: FC<ComplainFormProps> = ({ loginId }): ReactElement => {
  const { eventHandler } = useValidation();

  // state for loader control
  const [isLoading, setIsLoading] = useState<boolean>(false);

  // states for snackbar
  const [openSnackBar, setOpenSnackBar] = useState<boolean>(false);
  const [snackMessage, setSnackMessage] = useState<string>("");

  // state to hold dropdown data
  const [dropdownData, setDropdownData] = useState<any[] | undefined>(
    undefined
  );

  const [errors, setErrors] = useState<{ [key: string]: string }>({
    complain: "",
    suggestion: "",
  });

  const [complain, setComplain] = useState<Complain>({
    against: "",
    by: loginId,
    complain: "",
    suggestion: "",
  });

  const handleOpenSnackBar = () => {
    setOpenSnackBar(true);
    setTimeout(() => {
      setOpenSnackBar(false);
    }, 3000);
  };

  const handleChange = (
    e:
      | ChangeEvent<
          HTMLInputElement | { name?: string | undefined; value: unknown }
        >
      | SelectChangeEvent<string>
  ) => {
    const name = (e.target as HTMLInputElement)?.name || "";
    const value = (e.target as HTMLInputElement)?.value || e.target.value;

    setComplain((preComplain: Complain) => ({
      ...preComplain,
      [name]: value,
    }));
  };

  const handleSubmit: FormEventHandler<HTMLFormElement> = (
    e: FormEvent<HTMLFormElement>
  ) => {
    e.preventDefault();
    setIsLoading(true);
    postRequestMethod(POST_COMPLAINT_FEEDBACK, "", complain)
      .then((res: any) => {
        setIsLoading(false);
        setSnackMessage(res.data.message);
        handleOpenSnackBar();
        formClear();
      })
      .catch((error) => {
        setIsLoading(false);
        console.error("Error sending data:", error);
      });
  };

  const validationHandler = async (e: any, alterName?: any) => {
    const val = e.target.value;
    const id = alterName;
    if (id) {
      let prom = new Promise((resolve) => {
        if (true) {
          resolve(eventHandler(id, val));
        }
      });
      prom.then((res) => {
        setErrors({ ...errors, [e.target.name]: res });
      });
    }
    setComplain({ ...complain, [e.target.name]: val });
  };

  const formClear = () => {
    setComplain({
      against: "",
      by: loginId,
      complain: "",
      suggestion: "",
    });
    setErrors({
      complain: "",
      suggestion: "",
    });
  };

  useEffect(() => {
    const getStudentData = () => {
      getRequest(FEEDBACK_FORM_GET, "")
        .then((res: any) => {
          setDropdownData(res.data);
        })
        .catch((error) => {
          console.error("Error fetching data:", error);
        });
    };
    getStudentData();
  }, []);

  return (
    <>
      <Container>
        {isLoading && <LinearProgress />}
        <Paper elevation={20} sx={{ p: 2 }}>
          <Grid
            container
            spacing={2}
            padding={2}
            component="form"
            onSubmit={handleSubmit}
          >
            <Grid item xs={12}>
              <Typography variant="h5" fontWeight="bold" color="#0288d1">
                FILL A COMPLAIN AGAINST
              </Typography>
            </Grid>
            <Grid item xs={6}>
              <FormControl fullWidth>
                <InputLabel id="selectEmployee">Employee</InputLabel>
                <Select
                  required
                  labelId="selectEmployee"
                  id="employeeList"
                  name="against"
                  value={complain.against}
                  label="Employee"
                  onChange={handleChange}
                >
                  <MenuItem value="">-- select --</MenuItem>
                  {dropdownData?.map((detail: any) => (
                    <MenuItem
                      value={detail.empId}
                      key={detail._id}
                      id={detail._id}
                    >
                      {`${detail.firstName} ${detail.lastName} (${detail.type})
                        ${detail.empId}`}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
            </Grid>
            <Grid item xs={12}>
              <TextField
                label="Complain"
                multiline
                required
                minRows={4}
                fullWidth
                name="complain"
                value={complain.complain}
                placeholder="your complain write here.."
                sx={{ background: "white" }}
                error={Boolean(errors.complain)}
                helperText={errors.complain}
                onChange={(e: any) => {
                  handleChange(e);
                  validationHandler(e, "message");
                }}
              />
            </Grid>
            <Grid item xs={12}>
              <TextField
                label="Suggestion"
                multiline
                required
                minRows={4}
                fullWidth
                name="suggestion"
                value={complain.suggestion}
                placeholder="your suggestion write here.."
                sx={{ background: "white" }}
                error={Boolean(errors.suggestion)}
                helperText={errors.suggestion}
                onChange={(e: any) => {
                  handleChange(e);
                  validationHandler(e, "message");
                }}
              />
            </Grid>
            <Grid item xs={12}>
              <Button type="submit" variant="contained">
                Submit
              </Button>
              <CommonSnackBar message={snackMessage} open={openSnackBar} />
            </Grid>
          </Grid>
        </Paper>
      </Container>
    </>
  );
};

export default ComplainForm;
