import * as React from "react";
import {
  Container,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  FormControl,
  Grid,
  IconButton,
  InputAdornment,
  Stack,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  TablePagination,
  Paper,
  Tooltip,
  Typography,
} from "@mui/material";
import DownloadIcon from "@mui/icons-material/Download";
import { Button } from "@mui/material";
import TextField from "@mui/material/TextField";
import SearchIcon from "@mui/icons-material/Search";
import { useState, useEffect } from "react";
import {
  getRequest,
  getRequestById,
  getRequestByParams,
  updateRequest,
} from "../../api/api";
import {
  GET_STUDENT,
  STUDENT_INSTALLMENT_RECEIPT,
  VERIFY_PAYMENT_STUDENT,
} from "../../api/server";
import ReceiptLetter from "./ReceiptLetter";
import { PDFDownloadLink } from "@react-pdf/renderer";
import OfflinePinIcon from "@mui/icons-material/OfflinePin";
import CancelIcon from "@mui/icons-material/Cancel";
import "../../Components/Home/Home.scss";
import { useLocation, useParams } from "react-router-dom";

export default function ManageReceiptVerification() {
  let { stUniqueId } = useParams();
  const location = useLocation();
  const isEditRoute =
    location.pathname === `/dashboard/ManageStudent/PaidFees/${stUniqueId}`;
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [Detail, setDetail] = useState<any[]>([]);
  const [receipt, setReceipt] = useState<any[]>([]);
  const [openes, setOpenes] = useState(false);
  const [open, setOpen] = useState(false);
  const [searchApiData, setSearchApiData] = useState<any[]>([]);
  const [filterVal, setFilterVal] = useState("");
  const [showPDF, setShowPDF] = useState(false);
  const [fee, setFee] = useState({});

  const handleCloseRejectDialog = () => {
    setOpenes(false);
    setShowPDF(false);
  };

  const handleOpenRejectDialog = (id: any) => {
    setOpenes(true);
    setFee({ ...fee, fee: "Accept", id: id });
  };

  const handleCloseRejectCommentDialog = () => {
    setOpen(false);
  };

  const handleOpenRejectCommentDialog = (id: any) => {
    setOpen(true);
    setFee({ ...fee, fee: "Reject", id: id });
  };

  const InstallmentReceipt = async (id: any) => {
    try {
      let result = await getRequestById(STUDENT_INSTALLMENT_RECEIPT, id, "");
      if (result) {
        setReceipt(result.data[0]);
        setShowPDF(true);
      }
    } catch (error) {
      console.error("Error fetching data:", error);
    }
  };

  const viewDetails: any = async () => {
    try {
      if (isEditRoute) {
        if (stUniqueId) {
          let result = await getRequestByParams(GET_STUDENT, "", "", {
            stUniqueId,
          });
          if (result) {
            setDetail(result.data);
            setSearchApiData(result.data);
          }
        }
      } else {
        let result = await getRequest(GET_STUDENT, "");
        if (result) {
          setDetail(result.data);
          setSearchApiData(result.data);
        }
      }
    } catch (error) {
      console.error("Error fetching data:", error);
    }
  };

  const verified = async (id: any) => {
    try {
      await updateRequest(VERIFY_PAYMENT_STUDENT, "", id, "");
    } catch (error) {
      console.error("Error fetching data:", error);
    }
    viewDetails();
  };

  const changeRemarks = (event: any) => {
    setFee({ ...fee, rejectRecieptComment: event.target.value });
  };

  const handleChangePage = (event: any, newPage: any) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event: any) => {
    setRowsPerPage(+event.target.value);
    setPage(0);
  };

  const handleFilter = (e: any) => {
    if (e.target.value === "") {
      setDetail(searchApiData);
    } else {
      const filterResult: any = searchApiData.filter((item: any) => {
        const searchValue = e.target.value.toLowerCase();
        return Object.values(item).some((value) => {
          if (typeof value === "string" || typeof value === "number") {
            const itemValue = String(value).toLowerCase();
            return itemValue.includes(searchValue);
          }
          return false;
        });
      });

      setDetail(filterResult);
    }
    setFilterVal(e.target.value);
  };

  useEffect(() => {
    viewDetails();
  }, [isEditRoute, location]);

  return (
    <>
      <Container>
        <Paper elevation={20}>
          <Grid
            container
            sx={{
              p: 2,
              background: "#0288d1",
              color: "white",
            }}
          >
            <Grid item xs={6}>
              <Typography variant="h5" sx={{ mx: 3 }}>
                {stUniqueId
                  ? "Manage Paid Fees"
                  : "Manage Receipt Verification"}
              </Typography>
            </Grid>
            <Grid
              item
              xs={6}
              sx={{ display: "flex", justifyContent: "flex-end" }}
            >
              <FormControl>
                <TextField
                  label="Search by name and id"
                  size="small"
                  id="outlined-basic"
                  variant="outlined"
                  value={filterVal}
                  onInput={(e) => handleFilter(e)}
                  sx={{ mx: 3, background: "white" }}
                  InputProps={{
                    endAdornment: (
                      <InputAdornment position="end">
                        <IconButton edge="end">
                          <SearchIcon />
                        </IconButton>
                      </InputAdornment>
                    ),
                  }}
                />
              </FormControl>
            </Grid>
          </Grid>
          <TableContainer sx={{ p: 1 }} className="scrollBarCss">
            <Table sx={{ minWidth: 650 }} aria-label="simple table">
              <TableHead>
                <TableRow>
                  <TableCell
                    sx={{
                      textAlign: "center",
                      border: "1px solid #ddd",
                      fontSize: "15px",
                    }}
                  >
                    Sr No
                  </TableCell>
                  <TableCell
                    sx={{
                      textAlign: "center",
                      border: "1px solid #ddd",
                      fontSize: "15px",
                    }}
                  >
                    Installment Date
                  </TableCell>
                  <TableCell
                    sx={{
                      textAlign: "center",
                      border: "1px solid #ddd",
                      fontSize: "15px",
                    }}
                  >
                    Installment Amount
                  </TableCell>
                  <TableCell
                    sx={{
                      textAlign: "center",
                      border: "1px solid #ddd",
                      fontSize: "15px",
                    }}
                  >
                    Payment Mode
                  </TableCell>
                  <TableCell
                    sx={{
                      textAlign: "center",
                      border: "1px solid #ddd",
                      fontSize: "15px",
                    }}
                  >
                    Slip No
                  </TableCell>
                  <TableCell
                    sx={{
                      textAlign: "center",
                      border: "1px solid #ddd",
                      fontSize: "15px",
                    }}
                  >
                    Bank Name
                  </TableCell>
                  <TableCell
                    sx={{
                      textAlign: "center",
                      border: "1px solid #ddd",
                      fontSize: "15px",
                    }}
                  >
                    Cheque No
                  </TableCell>
                  <TableCell
                    sx={{
                      textAlign: "center",
                      border: "1px solid #ddd",
                      fontSize: "15px",
                    }}
                  >
                    Account No
                  </TableCell>
                  <TableCell
                    sx={{
                      textAlign: "center",
                      border: "1px solid #ddd",
                      fontSize: "15px",
                    }}
                  >
                    Transfer Id
                  </TableCell>
                  <TableCell
                    sx={{
                      textAlign: "center",
                      border: "1px solid #ddd",
                      fontSize: "15px",
                    }}
                  >
                    Transaction Id
                  </TableCell>
                  <TableCell
                    sx={{
                      textAlign: "center",
                      border: "1px solid #ddd",
                      fontSize: "15px",
                    }}
                  >
                    Payment Date
                  </TableCell>
                  <TableCell
                    sx={{
                      textAlign: "center",
                      border: "1px solid #ddd",
                      fontSize: "15px",
                    }}
                  >
                    Action
                  </TableCell>
                </TableRow>
              </TableHead>

              <TableBody>
                {Detail.slice(
                  page * rowsPerPage,
                  page * rowsPerPage + rowsPerPage
                ).map((item, index) => (
                  <React.Fragment key={index}>
                    <TableRow
                      key={item._id}
                      sx={{
                        textAlign: "center",
                        borderRight: "1px solid #ddd",
                      }}
                    >
                      <TableCell
                        sx={{
                          textAlign: "center",
                          border: "1px solid #ddd",
                          fontSize: "15px",
                        }}
                      >
                       {page * rowsPerPage + index + 1}
                      </TableCell>
                      <TableCell
                        component="th"
                        scope="row"
                        sx={{
                          textAlign: "center",
                          borderRight: "1px solid #ddd",
                        }}
                      >
                        {item.date}
                      </TableCell>
                      <TableCell
                        sx={{
                          textAlign: "center",
                          borderRight: "1px solid #ddd",
                        }}
                      >
                        {item.amount}
                      </TableCell>
                      <TableCell
                        sx={{
                          textAlign: "center",
                          borderRight: "1px solid #ddd",
                        }}
                      >
                        {item.paymentMode}
                      </TableCell>
                      <TableCell
                        sx={{
                          textAlign: "center",
                          borderRight: "1px solid #ddd",
                        }}
                      >
                        {item.slipNo ? item.slipNo : "----"}
                      </TableCell>
                      <TableCell
                        sx={{
                          textAlign: "center",
                          borderRight: "1px solid #ddd",
                        }}
                      >
                        {item.bankName ? item.bankName : "----"}
                      </TableCell>
                      <TableCell
                        sx={{
                          textAlign: "center",
                          borderRight: "1px solid #ddd",
                        }}
                      >
                        {item.chequeNo ? item.chequeNo : "----"}
                      </TableCell>
                      <TableCell
                        sx={{
                          textAlign: "center",
                          borderRight: "1px solid #ddd",
                        }}
                      >
                        {item.accountNo ? item.accountNo : "----"}
                      </TableCell>
                      <TableCell
                        sx={{
                          textAlign: "center",
                          borderRight: "1px solid #ddd",
                        }}
                      >
                        {item.transferId ? item.transferId : "----"}
                      </TableCell>
                      <TableCell
                        sx={{
                          textAlign: "center",
                          borderRight: "1px solid #ddd",
                        }}
                      >
                        {item.transactionId ? item.transactionId : "----"}
                      </TableCell>
                      <TableCell
                        sx={{
                          textAlign: "center",
                          borderRight: "1px solid #ddd",
                        }}
                      >
                        {item.createdAt}
                      </TableCell>
                      <TableCell
                        sx={{
                          textAlign: "center",
                          borderRight: "1px solid #ddd",
                        }}
                      >
                        <Stack direction="row">
                          <Tooltip title="Download Receipt" arrow>
                            <IconButton
                              onClick={() => InstallmentReceipt(item._id)}
                            >
                              <DownloadIcon
                                sx={{ fontSize: "25px", color: "#0288d1" }}
                              />
                            </IconButton>
                          </Tooltip>

                          <Dialog
                            open={showPDF}
                            onClose={handleCloseRejectDialog}
                          >
                            <DialogTitle>Download Receipt</DialogTitle>

                            <DialogActions>
                              <PDFDownloadLink
                                document={<ReceiptLetter props={receipt} />}
                                fileName="receipt.pdf"
                              >
                                {({ loading }) =>
                                  loading ? "Generating PDF..." : "Download PDF"
                                }
                                <Button onClick={handleCloseRejectDialog}>
                                  Yes
                                </Button>
                              </PDFDownloadLink>

                              <Button onClick={handleCloseRejectDialog}>
                                No
                              </Button>
                            </DialogActions>
                          </Dialog>
                          {isEditRoute ? null : (
                            <>
                              <Tooltip title="Verify Payment" arrow>
                                <IconButton
                                  onClick={() =>
                                    handleOpenRejectDialog(item._id)
                                  }
                                >
                                  <OfflinePinIcon
                                    sx={{ fontSize: "25px", color: "#0288d1" }}
                                  />
                                </IconButton>
                              </Tooltip>

                              <Dialog
                                open={openes}
                                onClose={handleCloseRejectDialog}
                              >
                                <DialogTitle>Verify Payment</DialogTitle>
                                <DialogContent>
                                  <Typography>
                                    Are you sure Verify Payment
                                  </Typography>
                                </DialogContent>
                                <DialogActions>
                                  <Button
                                    onClick={() => {
                                      handleCloseRejectDialog();
                                      verified(fee);
                                    }}
                                  >
                                    Yes
                                  </Button>

                                  <Button onClick={handleCloseRejectDialog}>
                                    No
                                  </Button>
                                </DialogActions>
                              </Dialog>
                              <Tooltip title="Reject Payment" arrow>
                                <IconButton
                                  onClick={() =>
                                    handleOpenRejectCommentDialog(item._id)
                                  }
                                >
                                  <CancelIcon
                                    sx={{ fontSize: "25px", color: "red" }}
                                  />
                                </IconButton>
                              </Tooltip>
                              <Dialog
                                open={open}
                                onClose={handleCloseRejectCommentDialog}
                              >
                                <DialogTitle>Reject Comment</DialogTitle>
                                <DialogContent>
                                  <Stack direction="row" spacing={1}>
                                    <TextField
                                      id="outlined-basic"
                                      size="small"
                                      label="Outlined"
                                      variant="outlined"
                                      onChange={changeRemarks}
                                    />
                                    <Button
                                      variant="contained"
                                      onClick={() => {
                                        handleCloseRejectCommentDialog();
                                        verified(fee);
                                      }}
                                    >
                                      Submit
                                    </Button>
                                  </Stack>
                                </DialogContent>
                              </Dialog>
                            </>
                          )}
                        </Stack>
                      </TableCell>
                    </TableRow>
                  </React.Fragment>
                ))}
              </TableBody>
            </Table>
          </TableContainer>

          <TablePagination
            rowsPerPageOptions={[10, 25, 50, 100]}
            component="div"
            count={Detail.length}
            rowsPerPage={rowsPerPage}
            page={page}
            onPageChange={handleChangePage}
            onRowsPerPageChange={handleChangeRowsPerPage}
          ></TablePagination>
        </Paper>
      </Container>
    </>
  );
}
