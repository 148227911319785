// REST-API integration

export const BASE_SERVER_URL: string = process.env.REACT_APP_BACKEND_BASE_URL || "";

// career
export const APPLY_JOB: string = BASE_SERVER_URL + "/career/addcareer";
export const GET_CAREER_DETAILS: string =
  BASE_SERVER_URL + "/career/get-career";
export const DELETE_CAREER_DETAILS: string = BASE_SERVER_URL + "/career";

// contact-us
export const CONTACT_US: string = BASE_SERVER_URL + "/contatUs/contactUsPost";

// pop-up
export const POST_POPUP_DATA: string = BASE_SERVER_URL + "/popUp/addpopupdata";
export const SEND_OTP: string = BASE_SERVER_URL + "/popUp/sendOtp";

// login
export const POST_LOGIN: string = BASE_SERVER_URL + "/login/auth";
export const POST_LOGOUT: string = BASE_SERVER_URL + "/logout/user";

// payment route
export const PAYMENT: string = BASE_SERVER_URL + "/paytm-payment/payment";

// lead module
export const GET_LEAD: string = BASE_SERVER_URL + "/lead/get-lead";
export const UPLOAD_LEAD: string = BASE_SERVER_URL + "/lead/uploadcsv";
export const CENTERHEAD_LIST: string =
  BASE_SERVER_URL + "/master/centerhead-list";
export const TEAMLEAD_LIST: string = BASE_SERVER_URL + "/master/teamlead-list";
export const EXECUTIVE_LIST: string =
  BASE_SERVER_URL + "/master/executive-list";
export const REJECTED_LEADS: string = BASE_SERVER_URL + "/lead/get-reject-lead";
export const POST_LEAD: string = BASE_SERVER_URL + "/lead/add-lead";
export const ADMIN_ASSIGN_LEAD: string =
  BASE_SERVER_URL + "/lead/admin-assign-lead";

// reference lead module
export const GET_REFERENCE_USER: string =
  BASE_SERVER_URL + "/ref-lead/get-reference-user";
export const POST_REF_LEAD: string =
  BASE_SERVER_URL + "/ref-lead/add-refered-lead";
export const STUDENT_DATA: string =
  BASE_SERVER_URL + "/ref-lead/get-student-name";
export const ASSIGN_REF_LEAD: string =
  BASE_SERVER_URL + "/ref-lead/assign-refered-ex";

// manage assigned lead admin
export const GET_LEAD_ADMIN: string = BASE_SERVER_URL + "/lead/admin-get-lead";

// manage assigned lead centerhead
export const GET_EX_LIST_BY_CH_ID: string =
  BASE_SERVER_URL + "/master/executive-list-by-centerhead-id";
export const GET_CH_LED: string = BASE_SERVER_URL + "/lead/centerhead-get-lead";
export const PUT_CH_ASSIGN_LEAD: string =
  BASE_SERVER_URL + "/lead/centerhead-assign-lead";

// manage assigned lead teamlead
export const GET_EX_LIST_BY_TL_ID: string =
  BASE_SERVER_URL + "/master/executive-list-by-id";
export const GET_TL_LED: string = BASE_SERVER_URL + "/lead/teamlead-get-lead";
export const PUT_TL_ASSIGN_LEAD: string =
  BASE_SERVER_URL + "/lead/teamlead-assign-lead";

// manage assigned lead executive
export const GET_EX_LED: string = BASE_SERVER_URL + "/lead/executive-get-lead";
export const GET_REF_LED: string =
  BASE_SERVER_URL + "/lead/executive-get-referred-lead";
export const REJECT_LED: string = BASE_SERVER_URL + "/lead//reject-lead";

// activity module
export const GET_ACTIVITY: string = BASE_SERVER_URL + "/activity/get-activity";
export const PUSH_ACTIVITY: string =
  BASE_SERVER_URL + "/activity/push-activity";
export const ADD_ACTIVITY: string = BASE_SERVER_URL + "/activity/add-activity";
export const STATIC_DATA: string = BASE_SERVER_URL + "/activity/static-data";
export const GET_ACTIVITY_TYPE: string =
  BASE_SERVER_URL + "/activity/get-activity-type";

// job module
export const POST_JOB: string = BASE_SERVER_URL + "/job/add-job";
export const UPDATE_DELETE_JOB: string = BASE_SERVER_URL + "/job";
export const GET_JOB: string = BASE_SERVER_URL + "/job/get-job";
export const GET_JOB_DETAILS: string = BASE_SERVER_URL + "/job/get-job-details";

// university module
export const POST_UNIVERSITY: string = BASE_SERVER_URL + "/university/add";
export const GET_UNIVERSITY: string = BASE_SERVER_URL + "/university";
export const UPDATE_UNIVERSITY: string = BASE_SERVER_URL + "/university/update";
export const DELETE_UNIVERSITY: string = BASE_SERVER_URL + "/university/delete";
export const UPLOAD_UNIVERSITY: string =
  BASE_SERVER_URL + "/university/importCSV";

// centerhead module
export const GET_EMPLOYEE_STATIC_DATA: string =
  BASE_SERVER_URL + "/master/employee-static-data";
export const POST_CENTER_HEAD: string =
  BASE_SERVER_URL + "/centerhead/register-centerhead";
export const UPDATE_CENTER_HEAD: string =
  BASE_SERVER_URL + "/centerhead/update-centerhead";
export const GET_CENTER_HEAD: string =
  BASE_SERVER_URL + "/aggregate/aggregate-centerhead";

// teamlead module
export const GET_TEAMLEAD: string =
  BASE_SERVER_URL + "/aggregate/aggregate-teamlead";
export const POST_TEAMLEAD: string =
  BASE_SERVER_URL + "/teamlead/register-teamlead";
export const UPDATE_TEAMLEAD: string =
  BASE_SERVER_URL + "/teamlead/update-teamlead";

// executive module
export const POST_EXECUTIVE: string =
  BASE_SERVER_URL + "/executive/register-executive";
export const GET_EXECUTIVE: string =
  BASE_SERVER_URL + "/aggregate/aggregate-executive";
export const GET_CH_TL_AGG: string =
  BASE_SERVER_URL + "/aggregate/aggregate-executive-dropdown";
export const UPDATE_EXECUTIVE: string =
  BASE_SERVER_URL + "/executive/update-executive";

// student module
export const POST_REGISTARTION: string = BASE_SERVER_URL + "/registration";
export const FIND_LEAD: string = BASE_SERVER_URL + "/lead";

// result module
export const POST_RESULT: string = BASE_SERVER_URL + "/result/add-result";
export const GET_RESULT: string = BASE_SERVER_URL + "/result/get-result";
export const DELETE_RESULT: string = BASE_SERVER_URL + "/result/delete-result";

// paper module
export const POST_PAPER: string = BASE_SERVER_URL + "/paper/add-paper";
export const GET_PAPER: string = BASE_SERVER_URL + "/paper/get-paper";
export const DELETE_PAPER: string = BASE_SERVER_URL + "/paper/delete-paper";

// setting module
export const GET_SETTING: string = BASE_SERVER_URL + "/setting/get";
export const UPDATE_SETTING: string = BASE_SERVER_URL + "/setting/put";

// report module
export const GET_COMPLAINT_FEEDBACK: string =
  BASE_SERVER_URL + "/complain/get-complains";
export const POST_COMPLAINT_FEEDBACK: string =
  BASE_SERVER_URL + "/complain/add-complain";
export const GET_FEE_STATICS: string = BASE_SERVER_URL + "/FeeStatics/get";
export const GET_RECEIPT: string = BASE_SERVER_URL + "/ManageAllReceipt/get";
export const GET_VISITOR: string = BASE_SERVER_URL + "/ManageVisitor/get";

// courier module
export const POST_COURIER: string = BASE_SERVER_URL + "/courier/SenddCourier";
export const GET_COURIER: string = BASE_SERVER_URL + "/courier/getAllCourier";
export const COURIER_DETAILS_GET: string = BASE_SERVER_URL + "/courier/get";

// leave module
export const POST_LEAVE: string = BASE_SERVER_URL + "/leave/post-leave";
export const GET_LEAVE_DATA: string = BASE_SERVER_URL + "/leave/get-leave-data";
export const UPDATE_LEAVE: string = BASE_SERVER_URL + "/leave/update-leave";
export const GET_EX_BY_TLID: string =
  BASE_SERVER_URL + "/aggregate/aggregate-executive-tl";
export const GET_LEAVE_BY_ID: string =
  BASE_SERVER_URL + "/leave/get-leave-by-id";

// employee form
export const UPDATE_EMPLOYEE: string = BASE_SERVER_URL + "/emp/updateEmp";
export const GET_EMPLOYEE_DETAILS: string =
  BASE_SERVER_URL + "/emp/get-employee-info";
export const DELETE_EMPLOYEE_FILES: string =
  BASE_SERVER_URL + "/emp/delete-files";

// profile form api for every login
export const GET_PROFILE: string =
  BASE_SERVER_URL + "/profile/get-profile-data";
export const UPDATE_PROFILE: string =
  BASE_SERVER_URL + "/profile/update-profile";
export const GET_CH_FROM_EMPLOYEE: string = BASE_SERVER_URL + "/profile/getCH";
export const GET_TL_FROM_EMPLOYEE: string = BASE_SERVER_URL + "/profile/getTL";
export const GET_EX_FROM_EMPLOYEE: string = BASE_SERVER_URL + "/profile/getEx";

// student module
export const GET_STUDENT: string =
  BASE_SERVER_URL + "/registration/get-Student-payment";
export const VERIFY_PAYMENT_STUDENT: string =
  BASE_SERVER_URL + "/registration/paymentVerify";
export const STUDENT_INSTALLMENT_RECEIPT: string =
  BASE_SERVER_URL + "/registration/student-Installment-Receipt";
export const STUDENT_PAID_INSTALLMENT_RECEIPT: string =
  BASE_SERVER_URL + "/registration/student-paid-Installment-Receipt";
export const GET_STUDENT_DATA_ON_TYPE_LID: string =
  BASE_SERVER_URL + "/registration/get-student-profile-data";
export const STUDENT_FIRST_PAID_INSTALLMENT_RECEIPT: string =
  BASE_SERVER_URL + "/registration/student-first-paid-installment";

// dashboard get student details
export const GET_DASHBOARD_DETAILS: string =
  BASE_SERVER_URL + "/registration/dashboardDetails";

// student module courier
export const STUDENT_COURIER_POST: string =
  BASE_SERVER_URL + "/registration/courierPost";
export const STUDENT_COURIER_GET: string =
  BASE_SERVER_URL + "/registration/courierGet";
export const STUDENT_DATA_GET: string =
  BASE_SERVER_URL + "/registration/studentDetailGet";
export const STUDENT_COURIER_PUT: string =
  BASE_SERVER_URL + "/registration/courierPut";

// manage student
export const GET_STUDENT_DETAILS: string =
  BASE_SERVER_URL + "/registration/get";
export const UPDATE_STUDENT_DETAILS: string =
  BASE_SERVER_URL + "/registration/put";
export const UPDATE_STUDENT_DETAILS_STATUS: string =
  BASE_SERVER_URL + "/registration/put";

// manage NOC
export const GET_STUDENT_MANAGE_NOC: string =
  BASE_SERVER_URL + "/registration/manageNocGet";
export const UPDATE_STUDENT_MANAGE_NOC: string =
  BASE_SERVER_URL + "/registration/manageNocPut";

// fee & payment
export const GET_STUDENT_FEE_PAYMENT: string =
  BASE_SERVER_URL + "/registration/StudentFeePayment";

// update fee
export const STUDENT_BY_ID: string =
  BASE_SERVER_URL + "/registration/get-student-by-id";
export const UPDATE_STUDENT_FEE: string =
  BASE_SERVER_URL + "/registration/update-fee";

// payment fee
export const UPDATE_STUDENT_PAYMENT_FEES: string =
  BASE_SERVER_URL + "/registration/add";
export const UPDATE_STUDENT_INFO: string =
  BASE_SERVER_URL + "/registration/updateInfo";

// re-registration
export const FIND_STUDENT_BY_ID: string =
  BASE_SERVER_URL + "/registration/find-student";
export const RE_REGISTRATION: string =
  BASE_SERVER_URL + "/registration/student-re-registration";

// manage commission module
export const GET_UNPAID_COMMISSION_CENTER_HEAD: string =
  BASE_SERVER_URL + "/commission/unpaid-commission-centerHead";
export const GET_PAID_COMMISSION_CENTER_HEAD: string =
  BASE_SERVER_URL + "/commission/paid-commission-centerHead";
export const GET_UNPAID_COMMISSION_TEAM_LEAD: string =
  BASE_SERVER_URL + "/commission/unpaid-commission-teamLead";
export const GET_PAID_COMMISSION_TEAM_LEAD: string =
  BASE_SERVER_URL + "/commission/paid-commission-teamLead";
export const GET_UNPAID_COMMISSION_EXECUTIVE: string =
  BASE_SERVER_URL + "/commission/unpaid-commission-executive";
export const GET_UNPAID_COMMISSION_STUDENT: string =
  BASE_SERVER_URL + "/commission/unpaid-commission-student";
export const GET_PAID_COMMISSION_EXECUTIVE: string =
  BASE_SERVER_URL + "/commission/paid-commission-executive";
export const GET_PAID_COMMISSION_STUDENT: string =
  BASE_SERVER_URL + "/commission/paid-commission-student";
export const UPDATE_COMMISSION_PAYMENT: string =
  BASE_SERVER_URL + "/commission/update-payment";

// latest report
export const STUDENT_MANAGE_FEE_REPORT: string =
  BASE_SERVER_URL + "/report/manage-fees-report";
export const UNIVERSITY_MANAGE_FEE_REPORT: string =
  BASE_SERVER_URL + "/report/university-name";
export const MANAGE_CONTACT_US: string =
  BASE_SERVER_URL + "/report/manage-contact-us";
export const UPLOAD_CSV_EXCEL: string =
  BASE_SERVER_URL + "/report/upload-csv-excel-sheet";
export const MANAGE_SALARY_SYSTEM: string =
  BASE_SERVER_URL + "/report/manage-salary";
export const GET_SALARY_BY_ID: string =
  BASE_SERVER_URL + "/report/manage-salary-by-id";
export const MANAGE_ALL_RECEIPT: string =
  BASE_SERVER_URL + "/report/manage-all-receipt";
export const MANAGE_FEE_STATIC: string =
  BASE_SERVER_URL + "/report/manage-fee-static";

// feedback form
export const FEEDBACK_FORM_GET: string = BASE_SERVER_URL + "/feedbackForm/get";
export const FEEDBACK_FORM_POST: string =
  BASE_SERVER_URL + "/feedbackForm/post";
export const FEEDBACK_FORM_DETAILS: string =
  BASE_SERVER_URL + "/feedbackForm/details";

// chatbot website
export const CHATBOT_GET: string = BASE_SERVER_URL + "/chatBot/get";
export const CHATBOT_POST: string = BASE_SERVER_URL + "/chatBot/post";
