import {
  Page,
  Text,
  Image,
  Document,
  StyleSheet,
  View,
} from "@react-pdf/renderer";

const styles = StyleSheet.create({
  body: {
    paddingTop: 35,
    paddingBottom: 65,
    paddingHorizontal: 35,
  },
  para1: {
    flexDirection: "row",
    flexWrap: "wrap",
  },
  para2: {
    flexDirection: "row",
    flexWrap: "wrap",
    marginTop: 15,
    padding: 10,
  },
  parasign: {
    flexDirection: "row",
    flexWrap: "wrap",
    marginTop: 100,
    padding: 10,
  },
  column1: {
    width: "20%",
    padding: 20,
  },
  column2: {
    width: "80%",
    padding: 10,
  },
  column3: {
    width: "33.33%",
    padding: 10,
  },
  column4: {
    width: "50%",
  },
  text: {
    fontSize: 12,
    textAlign: "justify",
  },
  textb: {
    fontSize: 12,
    textAlign: "right",
  },
  text1: {
    fontSize: 15,
    textAlign: "center",
    marginBottom: 10,
  },
  text3: {
    fontSize: 12,
    textAlign: "justify",
    padding: 30,
    marginTop: 5,
    textIndent: "50px",
    letterSpacing: "0px",
    lineHeight: "2px",
  },
  image: {
    height: 70,
    width: 70,
  },
  pageNumber: {
    position: "absolute",
    fontSize: 12,
    bottom: 30,
    left: 0,
    right: 0,
    textAlign: "center",
    color: "grey",
    fontFamily: "AntonFamily",
  },
  border: {
    borderBottom: " 1px solid black",
    margin: 5,
  },
  wish: {
    margin: 30,
  },
  sign: {
    margin: 30,
  },
});

const ReceiptLetter = (props: any) => {
  let date = new Date();
  let day = date.getDate();
  let month = date.getMonth() + 1;
  let year = date.getFullYear();
  let currentDate = `${day}-${month}-${year}`;
  console.log("krish", props.props?.courseData?.universityName);

  function convertToLetters(number: any) {
    const units = [
      "",
      "one",
      "two",
      "three",
      "four",
      "five",
      "six",
      "seven",
      "eight",
      "nine",
    ];
    const teens = [
      "ten",
      "eleven",
      "twelve",
      "thirteen",
      "fourteen",
      "fifteen",
      "sixteen",
      "seventeen",
      "eighteen",
      "nineteen",
    ];
    const tens = [
      "",
      "",
      "twenty",
      "thirty",
      "forty",
      "fifty",
      "sixty",
      "seventy",
      "eighty",
      "ninety",
    ];

    if (number === 0) {
      return "zero";
    }

    let result = "";

    if (number >= 1000) {
      result += convertToLetters(Math.floor(number / 1000)) + " thousand ";
      number %= 1000;
    }

    if (number >= 100) {
      result += convertToLetters(Math.floor(number / 100)) + " hundred ";
      number %= 100;
    }

    if (number >= 20) {
      result += tens[Math.floor(number / 10)] + " ";
      number %= 10;
    } else if (number >= 10) {
      result += teens[number - 10] + " ";
      number = 0;
    }

    if (number > 0) {
      result += units[number] + " ";
    }

    return result.trim();
  }

  const result = convertToLetters(
    props.props?.courseData?.installmentDetails[0]?.amount
  );
  console.log(result);
  return (
    <>
      <Document>
        {console.log(
          "university name",
          props?.props?.courseData?.universityName
        )}
        return(
        <Page size="A4">
          <View style={styles.para1}>
            <View style={styles.column1}>
              <Image
                style={styles.image}
                src="https://ik.imagekit.io/nwssoft/AboutUS-College-Logo/Logo/New_Logo_with_Crop/prena_acedemy_logo.jfif?updatedAt=1685344751380"
              ></Image>
            </View>
            <View style={styles.column2}>
              <Text style={styles.text}>PRERNA ACADEMY PVT. LTD., INDORE</Text>
              <Text style={styles.text}>
                Corporate Office : 82 Swami Vivekanand Nagar Telephone Square
                Kanadia Road Indore
              </Text>
              <Text style={styles.text}>(M.P) pin 452018</Text>
              <Text style={styles.text}>
                Ph. : (+91) 835 889 9900, (0731) 4298599
              </Text>
              <Text style={styles.text}>Toll Free No. : 1800120-224466</Text>
              <Text style={styles.text}>Email :info@prernaacademy.in</Text>
              <Text style={styles.text}>website: www.prernaacademy.in</Text>
            </View>
          </View>
          <View style={styles.border}></View>

          <View>
            <Text style={styles.text1}>FEES RECEIPT</Text>
          </View>

          <View style={styles.para2}>
            <Text style={styles.text}>Registration No. : PA/2023/0004</Text>
          </View>
          <View style={styles.para2}>
            <View style={styles.column3}>
              <Text style={styles.text}>Receipt No. : PA/REF/2023/0033</Text>
            </View>
            <View style={styles.column3}>
              <Text style={styles.text}>
                Slip No. :
                {props.props?.courseData?.installmentDetails[0]?.slipNo}
              </Text>
            </View>
            <View style={styles.column3}>
              <Text style={styles.text}>
                Date :{props.props?.courseData?.installmentDetails[0]?.date}
              </Text>
            </View>
          </View>
          <View style={styles.para2}>
            <View style={styles.column2}>
              <Text style={styles.text}>
                University : {`${props?.props?.courseData?.universityName}`}
              </Text>
            </View>
            <View style={styles.column2}>
              <Text style={styles.text}>
                Course : {props.props?.courseData?.courseName}
              </Text>
            </View>
            <View style={styles.column2}>
              <Text style={styles.text}>
                Total Fees :{props.props?.courseData?.courseFees}
              </Text>
            </View>

            <View style={styles.column2}>
              <Text style={styles.text}>
                Receive with thanks from Mr./Mrs.:
                {props?.props?.studentData?.firstName}{" "}
                {props.props?.studentData?.lastName}
              </Text>
              <Text style={styles.text}>
                enrollment for the course :{props.props?.courseData?.courseName}
              </Text>
              <Text style={styles.text}>
                a sum of rupees :
                {props.props?.courseData?.installmentDetails[0]?.amount}
              </Text>
              <Text style={styles.text}>
                in Cash / by Cheque / DD No. / Online :
                {props.props?.courseData?.installmentDetails[0]?.paymentMode}
              </Text>
              <Text style={styles.text}>{props.props1?.bankName}</Text>
              <Text style={styles.text}>
                towards course fee : {result + " " + "only"}
              </Text>
            </View>

            <View style={styles.column3}>
              <Text style={styles.text}>
                Fees Received Rs. :
                {props.props?.courseData?.installmentDetails[0]?.amount}
              </Text>
            </View>
            <View style={styles.column3}>
              <Text style={styles.text}>
                Installment Fee :
                {props.props?.courseData?.installmentDetails[0]?.amount}
              </Text>
              <Text style={styles.text}>
                Registration Fee : {props.props?.courseData?.regFees}
              </Text>
              <Text style={styles.text}>
                Total Fees Deposit :
                {props.props?.courseData?.installmentDetails[0]?.amount}
              </Text>
            </View>

            <View style={styles.column2}>
              <Text style={styles.text}>
                1.All Cheques and demand drafts are subject to realization.
              </Text>
              <Text style={styles.text}>
                2.This receipt must be kept for it any have to be produced in
                fuuture when demanded.
              </Text>
              <Text style={styles.text}>
                3.Fee once paid is neither refundable nor adjustable in any case
                * Subject to Indore Jurisdiiction.
              </Text>
              <Text style={styles.text}>
                4.Registration Fees & Caution Money not Included in TotalFee.
              </Text>
            </View>
          </View>
          <View style={styles.parasign}>
            <View style={styles.column4}>
              <Text style={styles.text}>Student Signature</Text>
            </View>
            <View style={styles.column4}>
              <Text style={styles.textb}>Authorised Signature</Text>
            </View>
          </View>
          <View></View>
        </Page>
        )
      </Document>
    </>
  );
};

export default ReceiptLetter;
