import {
  Avatar,
  Button,
  Container,
  Grid,
  InputLabel,
  Paper,
} from "@mui/material";

export default function DocumentInformationPreview(props: any) {
  const additionalFiles = props.Data?.employeeData?.fileInputs || [];
  return (
    <Container sx={{ mt: 3 }}>
      <Paper elevation={0}>
        <Grid
          container
          xs={12}
          rowSpacing={1}
          columnSpacing={{ xs: 1, sm: 2, md: 3 }}
          sx={{ m: 2 }}
        >
          {/* Photo */}
          <Grid item xs={12} sm={4}>
            <InputLabel
              htmlFor="standard-basic"
              sx={{ mt: 2, color: "#0288d1" }}
            >
              Photo :
            </InputLabel>
            <InputLabel htmlFor="standard-basic" sx={{ mt: 2, color: "black" }}>
              {props.Data?.employeeData?.photo?.originalname}
            </InputLabel>
            <Avatar
              alt={props.Data?.employeeData?.photo?.name}
              src={props.Data?.employeeData?.photo?.path}
              sx={{
                mt: 2,
                width: "50%",
                height: "50%",
                borderRadius: 0,
              }}
            />
            <Button
              variant="contained"
              href={props.Data?.employeeData?.photo?.path}
              sx={{ mt: 2 }}
            >
              Download
            </Button>
          </Grid>

          {/* Signature */}
          <Grid item xs={12} sm={4}>
            <InputLabel
              htmlFor="standard-basic"
              sx={{ mt: 2, color: "#0288d1" }}
            >
              Signature :
            </InputLabel>
            <InputLabel htmlFor="standard-basic" sx={{ mt: 2, color: "black" }}>
              {props.Data?.employeeData?.signature?.originalname}
            </InputLabel>
            <Avatar
              alt={props.Data?.employeeData?.signature?.name}
              src={props.Data?.employeeData?.signature?.path}
              sx={{
                mt: 2,
                width: "50%",
                height: "50%",
                borderRadius: 0,
              }}
            />
            <Button
              variant="contained"
              href={props.Data?.employeeData?.signature?.path}
              sx={{ mt: 2 }}
            >
              Download
            </Button>
          </Grid>
        </Grid>

        {/* Additional Files */}
        <InputLabel htmlFor="standard-basic" sx={{ mt: 5, color: "#0288d1" }}>
          Additional Files:
        </InputLabel>
        <Grid
          container
          xs={12}
          rowSpacing={1}
          columnSpacing={{ xs: 1, sm: 2, md: 3 }}
        >
          {additionalFiles.map((file: any, index: number) => (
            <Grid item key={index} xs={12} sm={4} sx={{ mt: 2 }}>
              <InputLabel
                htmlFor={`additional-file-${index}`}
                sx={{ mt: 2, color: "black" }}
              >
                {file.originalname}
              </InputLabel>
              <Button variant="contained" href={file.path} sx={{ mt: 2 }}>
                Download
              </Button>
            </Grid>
          ))}
        </Grid>
      </Paper>
    </Container>
  );
}
