import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Paper from "@mui/material/Paper";
import { Fragment, useEffect, useState } from "react";
import {
  Box,
  Container,
  IconButton,
  TablePagination,
  Typography,
  Grid,
  FormControl,
  InputAdornment,
} from "@mui/material";
import { Button } from "@mui/material";
import TextField from "@mui/material/TextField";
import SearchIcon from "@mui/icons-material/Search";
import { getRequest } from "../../api/api";
import { GET_VISITOR } from "../../api/server";
import "../../Components/Home/Home.scss";

export default function ManageVisitor() {
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [detail, setDetail] = useState<any[]>([]);
  const [searchApiData, setSearchApiData] = useState<any[]>([]);
  const [filterVal, setFilterVal] = useState("");

  const viewDetails = async () => {
    try {
      let result = await getRequest(GET_VISITOR, "");
      if (result) {
        setDetail(result.data);
        setSearchApiData(result.data);
      }
    } catch (error) {
      console.error("Error fetching data:", error);
    }
  };

  const handleChangePage = (event: any, newPage: any) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event: any) => {
    setRowsPerPage(+event.target.value);
    setPage(0);
  };

  //search by keyword function
  const handleFilter = (e: any) => {
    if (e.target.value === "") {
      setDetail(searchApiData);
    } else {
      const filterResult: any = searchApiData.filter((item: any) => {
        const searchValue = e.target.value.toLowerCase();
        return Object.values(item).some((value) => {
          if (typeof value === "string" || typeof value === "number") {
            const itemValue = String(value).toLowerCase();
            return itemValue.includes(searchValue);
          }
          return false;
        });
      });
      setDetail(filterResult);
    }
    setFilterVal(e.target.value);
  };

  useEffect(() => {
    viewDetails();
  }, []);

  return (
    <>
      <Container>
        <Paper elevation={20}>
          <Box
            sx={{
              display: "flex",
              flexDirection: "column",
            }}
            component={Paper}
          >
            <Grid
              container
              sx={{
                p: 2,
                background: "#0288d1",
                color: "white",
              }}
            >
              <Grid item xs={6}>
                <Typography variant="h5" sx={{ mx: 3 }}>
                  Manage Visitor
                </Typography>
              </Grid>
              <Grid
                item
                xs={6}
                sx={{ display: "flex", justifyContent: "flex-end" }}
              >
                <FormControl>
                  <TextField
                    label="Search "
                    size="small"
                    id="outlined-basic"
                    variant="outlined"
                    value={filterVal}
                    onInput={(e) => handleFilter(e)}
                    sx={{ mx: 3, background: "white" }}
                    InputProps={{
                      endAdornment: (
                        <InputAdornment position="end">
                          <IconButton edge="end">
                            <SearchIcon />
                          </IconButton>
                        </InputAdornment>
                      ),
                    }}
                  />
                </FormControl>
              </Grid>
            </Grid>
            <TableContainer className="scrollBarCss">
              <Table sx={{ minWidth: 650 }} aria-label="simple table">
                <TableHead>
                  <TableRow>
                    <TableCell
                      sx={{
                        textAlign: "center",
                        border: "1px solid #ddd",
                        fontSize: "15px",
                      }}
                    >
                      Sr.No
                    </TableCell>
                    <TableCell
                      sx={{
                        textAlign: "center",
                        border: "1px solid #ddd",
                        fontSize: "15px",
                      }}
                    >
                      IP Address
                    </TableCell>
                    <TableCell
                      sx={{
                        textAlign: "center",
                        border: "1px solid #ddd",
                        fontSize: "15px",
                      }}
                    >
                      City
                    </TableCell>
                    <TableCell
                      sx={{
                        textAlign: "center",
                        border: "1px solid #ddd",
                        fontSize: "15px",
                      }}
                    >
                      State
                    </TableCell>
                    <TableCell
                      sx={{
                        textAlign: "center",
                        border: "1px solid #ddd",
                        fontSize: "15px",
                      }}
                    >
                      Country
                    </TableCell>
                    <TableCell
                      sx={{
                        textAlign: "center",
                        border: "1px solid #ddd",
                        fontSize: "15px",
                      }}
                    >
                      Zipcode
                    </TableCell>
                    <TableCell
                      sx={{
                        textAlign: "center",
                        border: "1px solid #ddd",
                        fontSize: "15px",
                      }}
                    >
                      Date Create
                    </TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {detail
                    .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                    .map((item, index) => (
                      <Fragment key={item._id}>
                        <TableRow
                          key={item._id}
                          sx={{
                            textAlign: "center",
                            border: "1px solid #ddd",
                          }}
                        >
                          <TableCell
                            component="th"
                            scope="row"
                            sx={{
                              textAlign: "center",
                              border: "1px solid #ddd",
                            }}
                          >
                            {page * rowsPerPage + index + 1}
                          </TableCell>
                          <TableCell
                            component="th"
                            scope="row"
                            sx={{
                              textAlign: "center",
                              border: "1px solid #ddd",
                            }}
                          >
                            {item.IPv4}
                          </TableCell>
                          <TableCell
                            sx={{
                              textAlign: "center",
                              border: "1px solid #ddd",
                            }}
                          >
                            {item.city}
                          </TableCell>
                          <TableCell
                            sx={{
                              textAlign: "center",
                              border: "1px solid #ddd",
                            }}
                          >
                            {item.state}
                          </TableCell>
                          <TableCell
                            sx={{
                              textAlign: "center",
                              border: "1px solid #ddd",
                            }}
                          >
                            {item.country_name}
                          </TableCell>
                          <TableCell
                            sx={{
                              textAlign: "center",
                              border: "1px solid #ddd",
                            }}
                          >
                            {item.postal}
                          </TableCell>
                          <TableCell
                            sx={{
                              textAlign: "center",
                              border: "1px solid #ddd",
                            }}
                          >
                            {item.Created_At}
                          </TableCell>
                          <TableCell>
                            <Button variant="contained">Reply Mail</Button>
                          </TableCell>
                        </TableRow>
                      </Fragment>
                    ))}
                </TableBody>
              </Table>
            </TableContainer>

            <TablePagination
              rowsPerPageOptions={[10, 25, 50, 100]}
              component="div"
              count={detail.length}
              rowsPerPage={rowsPerPage}
              page={page}
              onPageChange={handleChangePage}
              onRowsPerPageChange={handleChangeRowsPerPage}
            ></TablePagination>
          </Box>
        </Paper>
      </Container>
    </>
  );
}
