import { Button } from "@mui/material";
import { useState } from "react";
import * as XLSX from "xlsx";
import CommonSnackBar from "./CommonSnackbar";

function TableToExcel({ tableData, fileName, headerMapping }: any) {
  const [snackMessage, setSnackMessage] = useState<string>("");
  const [openSnackBar, setOpenSnackBar] = useState<boolean>(false);

  const handleOpenSnackBar = (message: string) => {
    try {
      setSnackMessage(message);
      setOpenSnackBar(true);
    } catch (error) {
      console.error("Error in handleOpenSnackBar:", error);
    }
  };

  const handleCloseSnackBar = () => {
    try {
      setOpenSnackBar(false);
    } catch (error) {
      console.error("Error in handleCloseSnackBar:", error);
    }
  };

  const exportToExcel = () => {
    if (tableData.length === 0) {
      return handleOpenSnackBar("No data found..!");
    }
    const excelData = tableData.map((row: any) => {
      const rowData: any = {};
      Object.entries(headerMapping).forEach(
        ([excelHeader, dataKey]: [string, any]) => {
          //if there is nested object split the key if not return the same key
          const keys = dataKey.split(".");
          let nestedValue = row;
          //if there is nested object loop through it
          for (const key of keys) {
            nestedValue = nestedValue[key];
          }
          rowData[excelHeader] = nestedValue;
        }
      );
      return rowData;
    });
    // Create an Excel workbook
    const workbook = XLSX.utils.book_new();
    const worksheet = XLSX.utils.json_to_sheet(excelData);

    // Add the worksheet to the workbook
    XLSX.utils.book_append_sheet(workbook, worksheet, "Sheet1");

    // Save the workbook as a .xlsx file
    XLSX.writeFile(workbook, `${fileName}.xlsx`);
  };

  return (
    <>
      <Button
        variant="contained"
        onClick={exportToExcel}
        className="DownloadExcel"
        sx={{ background: "white", color: "black", px: 4 }}
      >
        Download Excel File
      </Button>
      <CommonSnackBar
        open={openSnackBar}
        autoHideDuration={3000}
        onClose={handleCloseSnackBar}
        message={snackMessage}
      />
    </>
  );
}

export default TableToExcel;
