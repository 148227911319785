import { styled } from "@mui/material/styles";
import Grid from "@mui/material/Grid";
import Typography from "@mui/material/Typography";
import { useTheme } from "@mui/material/styles";
import CardMedia from "@mui/material/CardMedia";
import { Container } from "@mui/system";
import { Button, Stack } from "@mui/material";
import ArrowRightAltIcon from "@mui/icons-material/ArrowRightAlt";
import ReadMoreIcon from "@mui/icons-material/ReadMore";
import { useNavigate } from "react-router-dom";

const FadeInContainer = styled(Grid)({
  animation: "fade-in 2.5s",
  "@keyframes fade-in": {
    from: { opacity: 0 },
    to: { opacity: 1 },
  },
});

export default function LandingPage() {
  const theme = useTheme();
  const navigate = useNavigate();
  return (
    <Container
      maxWidth="xl"
      sx={{
        marginTop: { xs: "40px", sm: "30px", lg: "10px" },
        p: "10px",
        backgroundImage:
          "url(https://ik.imagekit.io/nwssoft/NWS_Prerna_Project/Landing_Page/hero-bg__1_.png?updatedAt=1683110122152)",
        backgroundAttachment: "fixed",
        backgroundPosition: "top center",
        backgroundSize: "cover",
        height: "100vh",
      }}
    >
      {/* sub container`` */}
      <Grid
        container
        alignItems="center"
        justifyContent="space-evenly"
        gap={1}
        display="flex"
        flexWrap="wrap"
        sx={{
          paddingTop: {
            xs: "20%",
            sm: "20%",
            md: "10%",
            lg: "5%",
            xl: "5%",
          },
        }}
      >
        {/* welcome note container */}
        <FadeInContainer item xs={12} sm={12} md={5} lg={6} xl={6}>
          <Stack
            direction={"column"}
            sx={{
              maxWidth: "100%",
              justifyContent: {
                sx: "center",
                xs: "center",
                lg: "flex-start",
                xl: "flex-start",
                md: "flex-start",
              },

              fontFamily: "'Nunito Sans', sans-serif",
              alignItems: {
                sx: "center",
                xs: "center",
                lg: "flex-start",
                xl: "start",
                md: "flex-start",
              },
              textAlign: {
                xs: "left",
                md: "left",
                lg: "left",
                xl: "left",
              },
            }}
          >
            <Typography
              variant="h1"
              color="#191970"
              sx={{
                fontFamily: "'Nunito Sans', sans-serif",
                fontWeight: "600",
                fontSize: {
                  xs: " 3vw",
                  xl: " 3.6vw",
                },
                textShadow:
                  "0px 4px 3px rgba(0,0,0,0.1),0px 8px 13px rgba(0,0,0,0.0),0px 18px 23px rgba(0,0,0,0.0)",
              }}
            >
              WELCOME TO
            </Typography>

            <Typography
              variant="h1"
              color="#191970"
              sx={{
                fontWeight: "600",
                fontFamily: "'Nunito Sans', sans-serif",
                fontSize: {
                  xs: " 3vw",
                  xl: " 3.6vw",
                },
                textShadow:
                  "0px 4px 3px rgba(0,0,0,0.1),0px 8px 13px rgba(0,0,0,0.0),0px 18px 23px rgba(0,0,0,0.0)",
              }}
            >
              PRERNA ACADEMY PVT LTD
            </Typography>

            <Typography
              variant="h6"
              color="black"
              sx={{
                fontWeight: "600",
                color: "#444444",
                fontSize: {
                  xs: theme.typography.body2.fontSize,
                  md: theme.typography.body1.fontSize,
                  xl: theme.typography.h6.fontSize,
                },
                fontFamily: "'Nunito Sans', sans-serif",
              }}
            >
              Global Counseling & Information Centre for Online & Distance
              Education
            </Typography>

            {/* buttons grid */}
            <Grid
              display="flex"
              gap="20px"
              justifyContent="flex-start"
              alignItems="center"
              paddingTop="10px"
            >
              {/* get started */}
              <Button
                variant="contained"
                size="large"
                sx={{
                  fontFamily: "'Nunito Sans', sans-serif",
                  fontWeight: "600",
                  boxShadow: "5",
                  backgroundColor: "#4154f1",
                }}
                endIcon={<ArrowRightAltIcon />}
              >
                GET STARTED
              </Button>

              {/* learn more */}
              <Button
                variant="outlined"
                size="large"
                sx={{
                  color: "black",
                  boxShadow: "3",
                  fontFamily: "'Nunito Sans', sans-serif",
                  fontWeight: "600",
                }}
                startIcon={<ReadMoreIcon />}
                onClick={() => {
                  navigate("/aboutus");
                }}
              >
                Learn more about us...
              </Button>
            </Grid>
          </Stack>
        </FadeInContainer>

        {/* image container */}
        <FadeInContainer item xs={12} sm={12} md={5} lg={5} xl={5}>
          <Grid
            sx={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            <CardMedia
              component="img"
              image="https://ik.imagekit.io/nwssoft/NWS_Prerna_Project/Landing_Page/Homepage_img?updatedAt=1683106028198"
              sx={{
                objectFit: "contain",
                width: { xs: "70%", md: "80%", lg: "100%" },
                height: { sm: "50%", md: "100%", lg: "100%" },
              }}
            />
          </Grid>
        </FadeInContainer>
      </Grid>
    </Container>
  );
}
