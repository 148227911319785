import {
  Button,
  Grid,
  Paper,
  Step,
  StepButton,
  Stepper,
  Tooltip,
} from "@mui/material";
import React, { ChangeEvent, useEffect, useState } from "react";
import CommonSnackBar from "../../Components/Common/CommonSnackbar";
import india from "../../Components/Common/india.json";
import { getRequest, postRequestMethod } from "../../api/api";
import { GET_UNIVERSITY, POST_REGISTARTION } from "../../api/server";
import AcademyDetails from "./AcademicDetails";
import CourseDetails from "./CourseDetails";
import FileUpload from "./FileUpload";
import SearchLead from "./SearchLead";
import StudentDetails from "./StudentDetails";

interface studentDetails {
  firstName: string;
  lastName: string;
  email: string;
  contactNumber: number | undefined;
  dateOfBirth: string;
  gender: string;

  whatsappNumber: number | undefined;
  language: string;
  nationality: string;
  aadhaar: string;
  domicile: string;
  working: string;
  jobType: string | undefined;
  companyName: string | undefined;
  employmentStartDate: string | undefined;
  designation: string | undefined;
  religion: string;
  categories: string;
  maritalstatus: string;

  fatherName: string;
  motherName: string;
  annualIncome: number | undefined;
  parentMobileNumber: number | undefined;
  parentEmail: string;
  presentAddress1: string;
  presentAddress2: string;
  presentState: string;
  presentCity: string;
  presentZipCode: number | undefined;
  permanentAddress1: string;
  permanentAddress2: string;
  permanentState: string;
  permanentCity: string;
  permanentZipCode: number | undefined;
}

interface academicDetails {
  sscYear: number | undefined;
  sscBoardUniversity: string | undefined;
  sscDivisionGrade: string | undefined;
  sscPercentageCGPA: number | undefined;
  sscObtainedMaxTotalMax: number | undefined;

  hscYear: number | undefined;
  hscBoardUniversity: string | undefined;
  hscDivisionGrade: string | undefined;
  hscPercentageCGPA: number | undefined;
  hscObtainedMaxTotalMax: number | undefined;

  itiYear: number | undefined;
  itiBoardUniversity: string | undefined;
  itiDivisionGrade: string | undefined;
  itiPercentageCGPA: number | undefined;
  itiObtainedMaxTotalMax: number | undefined;

  graduationYear: number | undefined;
  graduationBoardUniversity: string | undefined;
  graduationDivisionGrade: string | undefined;
  graduationPercentageCGPA: number | undefined;
  graduationObtainedMaxTotalMax: number | undefined;

  postGraduationYear: number | undefined;
  postGraduationBoardUniversity: string | undefined;
  postGraduationDivisionGrade: string | undefined;
  postGraduationPercentageCGPA: number | undefined;
  postGraduationObtainedMaxTotalMax: number | undefined;

  otherYear: number | undefined;
  otherBoardUniversity: string | undefined;
  otherDivisionGrade: string | undefined;
  otherPercentageCGPA: number | undefined;
  otherObtainedMaxTotalMax: number | undefined;
}

interface courseDetails {
  admissionType: string;
  courseCategory: string;
  universityName: string;
  departmentName: string;
  courseName: string;
  specialization: string;
  mode: string;
  noOfSemsOrYears: number | "";
  admissionSemesterYear: number | "";
  admissionDate: string;
  sessionFrom: string;
  sessionTo: string;
  courseFees: number;
  feesReceived: number;
  regFees: number;
  cautionMoney: number;
  otherFees: number;
  discount: number;
  totalFee: number;
  paymentMode: string;
  depositDate: string;
  slipNo: number | undefined;
  bankName: string | undefined;
  chequeNo: number | undefined;
  accountNo: string | undefined;
  transferId: string | undefined;

  installmentStartDate: string;
  numberOfInstallment: number | undefined;

  installmentDetails:
    | [
        {
          date: string;
          amount: string | number;
        }
      ]
    | [];
}

interface FileInput {
  photo: File;
  signature: File;
  additionalFiles:
    | [
        {
          id: number;
          file: File;
        }
      ]
    | [];
}

interface Lead {
  ldUniqueId: string;
  fname: string;
  lname: string;
  email: string;
  phone: string;
}

interface DataPresent {
  email: string;
  contactNumber: string;
}

interface ShowSubmit {
  leadConnected: boolean;
  studentDetail: boolean;
  academicDetail: boolean;
  courseDetail: boolean;
  uploadDocument: boolean;
}

function NewRegistration(props: any) {
  const { types } = props;
  const steps = getSteps();

  const [studentData, setStudentData] = useState<studentDetails>({
    firstName: "",
    lastName: "",
    email: "",
    contactNumber: undefined,
    dateOfBirth: "",
    gender: "",
    whatsappNumber: undefined,
    language: "",
    nationality: "",
    aadhaar: "",
    domicile: "",
    working: "",
    jobType: undefined,
    companyName: undefined,
    employmentStartDate: undefined,
    designation: undefined,
    religion: "",
    categories: "",
    maritalstatus: "",
    fatherName: "",
    motherName: "",
    annualIncome: undefined,
    parentMobileNumber: undefined,
    parentEmail: "",
    presentAddress1: "",
    presentAddress2: "",
    presentState: "",
    presentCity: "",
    presentZipCode: undefined,
    permanentAddress1: "",
    permanentAddress2: "",
    permanentState: "",
    permanentCity: "",
    permanentZipCode: undefined,
  });

  const [academicData, setAcademicData] = useState<academicDetails>({
    sscYear: undefined,
    sscBoardUniversity: undefined,
    sscDivisionGrade: undefined,
    sscPercentageCGPA: undefined,
    sscObtainedMaxTotalMax: undefined,

    hscYear: undefined,
    hscBoardUniversity: undefined,
    hscDivisionGrade: undefined,
    hscPercentageCGPA: undefined,
    hscObtainedMaxTotalMax: undefined,

    itiYear: undefined,
    itiBoardUniversity: undefined,
    itiDivisionGrade: undefined,
    itiPercentageCGPA: undefined,
    itiObtainedMaxTotalMax: undefined,

    graduationYear: undefined,
    graduationBoardUniversity: undefined,
    graduationDivisionGrade: undefined,
    graduationPercentageCGPA: undefined,
    graduationObtainedMaxTotalMax: undefined,

    postGraduationYear: undefined,
    postGraduationBoardUniversity: undefined,
    postGraduationDivisionGrade: undefined,
    postGraduationPercentageCGPA: undefined,
    postGraduationObtainedMaxTotalMax: undefined,

    otherYear: undefined,
    otherBoardUniversity: undefined,
    otherDivisionGrade: undefined,
    otherPercentageCGPA: undefined,
    otherObtainedMaxTotalMax: undefined,
  });

  const [courseData, setCourseData] = useState<courseDetails>({
    admissionType: "",
    courseCategory: "",
    universityName: "",
    departmentName: "",
    courseName: "",
    specialization: "",
    mode: "",
    noOfSemsOrYears: "",
    admissionSemesterYear: "",
    admissionDate: "",
    sessionFrom: "",
    sessionTo: "",
    courseFees: 0,
    feesReceived: 0,
    regFees: 0,
    cautionMoney: 0,
    otherFees: 0,
    discount: 0,
    totalFee: 0,
    paymentMode: "",
    depositDate: "",
    slipNo: undefined,
    bankName: undefined,
    chequeNo: undefined,
    accountNo: undefined,
    transferId: undefined,
    installmentStartDate: "",
    numberOfInstallment: 0,
    installmentDetails: [],
  });

  const [fileInputs, setFileInputs] = useState<FileInput>({
    photo: new File([], ""),
    signature: new File([], ""),
    additionalFiles: [],
  });

  const [presentCity, setPresentCity] = useState<any>([]);
  const [permanentCity, setPermanentCity] = useState<any>([]);
  const [openSnackBar, setOpenSnackBar] = useState(false);
  const [snackMessage, setSnackMessage] = useState<string>("");
  const [activeStep, setActiveStep] = useState<number>(0);
  const [isPresent, setIsPresent] = useState(false);
  const [isChecked, setIsChecked] = useState(false);
  const [universityDetails, setUniversityDetails] = useState<any>({});
  const [leads, setLeads] = useState<Lead | undefined>();
  const [leadId, setLeadId] = useState<string>("");
  const [show, setShow] = useState<boolean>(false);

  const [showSubmit, setShowSubmit] = useState<ShowSubmit>({
    leadConnected: false,
    studentDetail: false,
    academicDetail: false,
    courseDetail: false,
    uploadDocument: false,
  });

  const [dataPresent, setDataPresent] = useState<DataPresent>({
    email: "",
    contactNumber: "",
  });

  const [uniDropData, setUniDropData] = useState<any>({
    universityName: [],
    selectedUniversity: [],
    department: [],
    course: [],
    specialization: [],
  });

  const [display, setDisplay] = React.useState({
    cash: false,
    cheque: false,
    acTransfer: false,
  });

  const resetFormState = () => {
    setStudentData({
      firstName: "",
      lastName: "",
      email: "",
      contactNumber: undefined,
      dateOfBirth: "",
      gender: "",
      whatsappNumber: undefined,
      language: "",
      nationality: "",
      aadhaar: "",
      domicile: "",
      working: "",
      jobType: undefined,
      companyName: undefined,
      employmentStartDate: undefined,
      designation: undefined,
      religion: "",
      categories: "",
      maritalstatus: "",
      fatherName: "",
      motherName: "",
      annualIncome: undefined,
      parentMobileNumber: undefined,
      parentEmail: "",
      presentAddress1: "",
      presentAddress2: "",
      presentState: "",
      presentCity: "",
      presentZipCode: undefined,
      permanentAddress1: "",
      permanentAddress2: "",
      permanentState: "",
      permanentCity: "",
      permanentZipCode: undefined,
    });

    setAcademicData({
      sscYear: undefined,
      sscBoardUniversity: undefined,
      sscDivisionGrade: undefined,
      sscPercentageCGPA: undefined,
      sscObtainedMaxTotalMax: undefined,

      hscYear: undefined,
      hscBoardUniversity: undefined,
      hscDivisionGrade: undefined,
      hscPercentageCGPA: undefined,
      hscObtainedMaxTotalMax: undefined,

      itiYear: undefined,
      itiBoardUniversity: undefined,
      itiDivisionGrade: undefined,
      itiPercentageCGPA: undefined,
      itiObtainedMaxTotalMax: undefined,

      graduationYear: undefined,
      graduationBoardUniversity: undefined,
      graduationDivisionGrade: undefined,
      graduationPercentageCGPA: undefined,
      graduationObtainedMaxTotalMax: undefined,

      postGraduationYear: undefined,
      postGraduationBoardUniversity: undefined,
      postGraduationDivisionGrade: undefined,
      postGraduationPercentageCGPA: undefined,
      postGraduationObtainedMaxTotalMax: undefined,

      otherYear: undefined,
      otherBoardUniversity: undefined,
      otherDivisionGrade: undefined,
      otherPercentageCGPA: undefined,
      otherObtainedMaxTotalMax: undefined,
    });

    setCourseData({
      admissionType: "",
      courseCategory: "",
      universityName: "",
      departmentName: "",
      courseName: "",
      specialization: "",
      mode: "",
      noOfSemsOrYears: "",
      admissionSemesterYear: "",
      admissionDate: "",
      sessionFrom: "",
      sessionTo: "",
      courseFees: 0,
      feesReceived: 0,
      regFees: 0,
      cautionMoney: 0,
      otherFees: 0,
      discount: 0,
      totalFee: 0,
      paymentMode: "",
      depositDate: "",
      slipNo: undefined,
      bankName: undefined,
      chequeNo: undefined,
      accountNo: undefined,
      transferId: undefined,
      installmentStartDate: new Date().toISOString().slice(0, 10),
      numberOfInstallment: undefined,
      installmentDetails: [],
    });

    setDisplay({
      cash: false,
      cheque: false,
      acTransfer: false,
    });

    setFileInputs({
      photo: new File([], ""),
      signature: new File([], ""),
      additionalFiles: [],
    });

    setLeads(undefined);

    setLeadId("");

    setIsPresent(false);
    setIsChecked(false);

    setDataPresent({
      email: "",
      contactNumber: "",
    });
  };

  function getForm(step: number) {
    switch (step) {
      case 0:
        return (
          <SearchLead
            setActiveStep={setActiveStep}
            setLeadId={setLeadId}
            leadId={leadId}
            setLeads={setLeads}
            leads={leads}
            setShowSubmit={setShowSubmit}
            types={types}
          />
        );
      case 1:
        return (
          <StudentDetails
            detail={studentData}
            handler={handleStudentData}
            setActiveStep={setActiveStep}
            presentCity={presentCity}
            permanentCity={permanentCity}
            isPresent={isPresent}
            setIsPresent={setIsPresent}
            dataPresent={dataPresent}
            setShowSubmit={setShowSubmit}
            setStudentData={setStudentData}
          />
        );
      case 2:
        return (
          <AcademyDetails
            detail={academicData}
            handler={handleAcademicData}
            setActiveStep={setActiveStep}
            setShowSubmit={setShowSubmit}
          />
        );
      case 3:
        return (
          <CourseDetails
            detail={courseData}
            handler={handleCourseData}
            setActiveStep={setActiveStep}
            uniDropData={uniDropData}
            dropDownData={dropDownData}
            setIsChecked={setIsChecked}
            isChecked={isChecked}
            display={display}
            setDisplay={setDisplay}
            setCourseData={setCourseData}
            setShowSubmit={setShowSubmit}
          />
        );
      case 4:
        return (
          <FileUpload
            detail={fileInputs}
            handlerFileChange={handleFileChange}
            handlerRemoveFile={handleRemoveButtonClick}
            handlerAddFile={handleAddButtonClick}
            setShowSubmit={setShowSubmit}
            setActiveStep={setActiveStep}
            showSubmit={showSubmit}
            setShow={setShow}
          />
        );
      default:
        return "";
    }
  }

  function getSteps() {
    return [
      "Lead Confirmation",
      "Personal Details",
      "Academic Details",
      "Course Details",
      "Upload Documents",
    ];
  }

  const handleStudentData = (e: any, key?: any) => {
    const { name, value } = e.target;
    setStudentData((prevData: any) => ({ ...prevData, [name]: value }));
  };

  const handleCourseData = (e: any) => {
    const { name, value } = e.target;
    setCourseData((prevData: any) => ({ ...prevData, [name]: value }));
  };

  const handleAcademicData = (e: any) => {
    const { name, value } = e.target;
    setAcademicData((prevData: any) => ({ ...prevData, [name]: value }));
  };

  const dropDownData = (e: any, children: any) => {
    const val = e.target.value;
    const name = e.target.name;
    const { id } = children.props;

    switch (name) {
      case "courseCategory":
        const university: any = [];
        universityDetails.forEach((obj: any) => {
          for (let i = 0; i < obj.courseCategory.length; i++) {
            if (obj.courseCategory[i].type === val) {
              university.push(obj);
              break;
            }
          }
        });
        setUniDropData({ ...uniDropData, universityName: university });
        break;

      case "universityName":
        const dept = uniDropData.universityName.filter(
          (obj: any) => obj.uId === id
        );
        setUniDropData({
          ...uniDropData,
          selectedUniversity: dept,
          department: dept[0]["department"],
        });
        break;

      case "departmentName":
        const course = uniDropData.selectedUniversity[0].course.filter(
          (obj: any) => obj.dId === id
        );
        setUniDropData({ ...uniDropData, course: course });
        break;

      case "courseName":
        const specialization =
          uniDropData.selectedUniversity[0].specialization.filter(
            (obj: any) => obj.cId === id
          );
        setUniDropData({ ...uniDropData, specialization: specialization });
        break;

      case "specialization":
        const mode = uniDropData.selectedUniversity[0].mode.filter(
          (obj: any) => obj.sId === id
        );
        const noOfSemsOrYears =
          uniDropData.selectedUniversity[0].noOfSemsOrNoOfYears.filter(
            (obj: any) => obj.sId === id
          );
        const courseFee = uniDropData.selectedUniversity[0].tuitionFee.filter(
          (obj: any) => obj.sId === id
        );
        const otherFee = uniDropData.selectedUniversity[0].otherFee.filter(
          (obj: any) => obj.sId === id
        );
        setCourseData((prevData: any) => ({
          ...prevData,
          mode: mode[0].type,
          admissionSemesterYear: Number(1),
          noOfSemsOrYears: Number(noOfSemsOrYears[0].value),
          courseFees: courseFee[0].value,
          otherFees: otherFee[0].value,
        }));
        break;
    }
  };

  const handleFileChange = (
    event: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>,
    index?: number
  ) => {
    const files = (event.target as HTMLInputElement).files;

    if (files && index) {
      let arr: any = fileInputs.additionalFiles;

      for (let i = 0; i < arr.length; i++) {
        if (arr[i].id === index) {
          arr[i].file = files[0];
        }
      }

      setFileInputs({
        ...fileInputs,
        additionalFiles: arr,
      });
    } else if (files) {
      setFileInputs({ ...fileInputs, [event.target.name]: files[0] });
    }
  };

  const handleRemoveButtonClick = (
    event: React.MouseEvent<SVGSVGElement, MouseEvent>,
    index: number
  ) => {
    setFileInputs((preFileInputs: FileInput) => {
      let arr: any = preFileInputs.additionalFiles.filter(
        (item: any) => item.id !== index
      );
      return {
        ...preFileInputs,
        additionalFiles: arr,
      };
    });
  };

  const handleAddButtonClick = () => {
    // to hide submit button
    setShow(false);
    let arr: any = fileInputs.additionalFiles;
    const newId = arr.length === 0 ? 0 : arr[arr.length - 1].id;
    arr.push({ id: newId + 1, file: new File([], "") });
    setFileInputs({ ...fileInputs, additionalFiles: arr });
  };

  const handleOpenSnackBar = () => {
    setOpenSnackBar(true);
    setTimeout(() => {
      setOpenSnackBar(false);
    }, 3000);
  };

  const postData = async (e: any) => {
    e.preventDefault();
    window.scroll(0, 0);
    const formData = new FormData();

    formData.append("studentData", JSON.stringify(studentData));
    formData.append("academicData", JSON.stringify(academicData));
    formData.append("courseData", JSON.stringify(courseData));
    formData.append("leadId", JSON.stringify(leadId));
    formData.append("photo", fileInputs.photo);
    formData.append("signature", fileInputs.signature);

    courseData.installmentDetails.forEach((item: any, index: any) => {
      formData.append(`installmentDetails[${index}][date]`, item.date);
      formData.append(`installmentDetails[${index}][amount]`, item.amount);
    });

    for (let i = 0; i < fileInputs.additionalFiles.length; i++) {
      formData.append("files", fileInputs.additionalFiles[i].file);
    }

    try {
      const response = await postRequestMethod(
        POST_REGISTARTION,
        "multipart/form-data",
        formData
      );
      if (response) {
        const data = response.data;
        const message = data?.message;

        if (data?.both) {
          setDataPresent({
            email: "Email is registered!",
            contactNumber: "Number is registered!",
          });
          setActiveStep(1);
          setSnackMessage("Email & Number is registered!");
          handleOpenSnackBar();
        } else if (data?.email) {
          setDataPresent({
            contactNumber: "",
            email: "Email is registered!",
          });
          setActiveStep(1);
          setSnackMessage("Email is registered!");
          handleOpenSnackBar();
        } else if (data?.phone) {
          setDataPresent({
            email: "",
            contactNumber: "Number is registered!",
          });
          setActiveStep(1);
          setSnackMessage("Number is registered!");
          handleOpenSnackBar();
        } else if (message) {
          setSnackMessage(message);
          handleOpenSnackBar();
          setTimeout(() => {
            setActiveStep(0);
            resetFormState();
          }, 3000);
        }
      }
    } catch (error) {
      console.error(error);
    }
  };

  useEffect(() => {
    showSubmit.leadConnected &&
    showSubmit.studentDetail &&
    showSubmit.academicDetail &&
    showSubmit.courseDetail &&
    showSubmit.uploadDocument &&
    activeStep === 4
      ? setShow(true)
      : setShow(false);
  }, [showSubmit, activeStep]);

  useEffect(() => {
    setShow(false);
  }, [activeStep]);

  useEffect(() => {
    let totalFee: number = Number(courseData?.courseFees);
    let courseFees: number = Number(courseData?.courseFees);
    let feesReceived: number = Number(courseData?.feesReceived);
    let discount: number = Number(courseData?.discount);

    if (courseData?.discount <= 0) {
      totalFee = courseFees - feesReceived;
    } else {
      totalFee = courseFees * ((100 - discount) / 100) - feesReceived;
    }

    setCourseData((preData: any) => ({
      ...preData,
      totalFee: totalFee,
    }));
  }, [courseData.discount, courseData.feesReceived]);

  useEffect(() => {
    const city = india.filter(
      (item) => item.state_name === studentData?.presentState
    );
    setPresentCity(city[0]?.cities);
  }, [studentData?.presentState]);

  useEffect(() => {
    const city = india.filter(
      (item) => item.state_name === studentData?.permanentState
    );
    setPermanentCity(city[0]?.cities);
  }, [studentData?.permanentState]);

  useEffect(() => {
    if (isPresent) {
      setStudentData((prevData: any) => ({
        ...prevData,
        permanentAddress1: studentData?.presentAddress1,
        permanentAddress2: studentData?.presentAddress2,
        permanentState: studentData?.presentState,
        permanentCity: studentData?.presentCity,
        permanentZipCode: studentData?.presentZipCode,
      }));
    } else {
      setStudentData((prevData: any) => ({
        ...prevData,
        permanentAddress1: "",
        permanentAddress2: "",
        permanentState: "",
        permanentCity: "",
        permanentZipCode: "",
      }));
    }
  }, [isPresent]);

  useEffect(() => {
    getRequest(GET_UNIVERSITY, "").then((res: any) =>
      setUniversityDetails(res.data)
    );
  }, []);

  useEffect(() => {
    function installmentFields() {
      let arr: any = [];
      let today: Date = new Date(courseData.installmentStartDate);

      if (courseData.numberOfInstallment) {
        for (let i = 0; i < courseData.numberOfInstallment; i++) {
          const futureDate = new Date(today);
          futureDate.setMonth(futureDate.getMonth() + i);

          if (courseData.totalFee !== undefined) {
            let amount: number = Number(
              (courseData.totalFee / courseData.numberOfInstallment).toFixed(0)
            );
            arr.push({
              date: futureDate.toISOString().slice(0, 10),
              amount: amount,
            });
          }
        }
        setCourseData((prevData: any) => ({
          ...prevData,
          installmentDetails: arr,
        }));
      } else {
        setCourseData((prevData: any) => ({
          ...prevData,
          installmentDetails: arr,
        }));
      }
    }
    if (courseData.installmentStartDate !== "") {
      installmentFields();
    }
  }, [courseData.numberOfInstallment]);

  return (
    <>
      <Paper sx={{ m: "2%", p: "5%" }}>
        <Grid container display="flex" justifyContent="center">
          <Grid item xs={12}>
            <Stepper alternativeLabel activeStep={activeStep}>
              {steps.map((step, index) => (
                <Step key={step}>
                  <Tooltip title={`Go to ${step}`} placement="top">
                    <StepButton
                      color="inherit"
                      onClick={() => setActiveStep(index)}
                    >
                      {step}
                    </StepButton>
                  </Tooltip>
                </Step>
              ))}
            </Stepper>
          </Grid>
          <Grid item xs={12}>
            <Paper elevation={10} sx={{ m: "2%", p: "5%" }}>
              <Grid item xs={12}>
                {getForm(activeStep)}
              </Grid>
              {show && (
                <Grid item xs={12} display="flex" justifyContent="center">
                  <Button variant="contained" onClick={postData} sx={{ mt: 2 }}>
                    Submit
                  </Button>
                </Grid>
              )}
              <CommonSnackBar message={snackMessage} open={openSnackBar} />
            </Paper>
          </Grid>
        </Grid>
      </Paper>
    </>
  );
}

export default NewRegistration;
