import {
  Box,
  Button,
  Checkbox,
  Container,
  FormControl,
  Grid,
  ImageList,
  ImageListItem,
  InputLabel,
  MenuItem,
  Select,
  TextField,
  Typography,
} from "@mui/material/";
import { ChangeEvent, FormEvent, useRef, useState } from "react";
import useValidation from "../../../Hooks/useTypescriptValidation";
import { postRequestMethod } from "../../../api/api";
import { POST_POPUP_DATA, SEND_OTP } from "../../../api/server";
import CommonSnackBar from "../../Common/CommonSnackbar";
import stateCity from "../../Common/stateCity.json";
import "./FirstLoadingForm.scss";

export default function FirstLoadingForm() {
  const { eventHandler } = useValidation();
  const formRef = useRef<HTMLFormElement>(null);

  const [statescity] = useState<any>(stateCity);
  const [openSnackBar, setOpenSnackBar] = useState<boolean>(false);
  const [snackMessage, setSnackMessage] = useState<string>("");
  const [showDateTimeField, setShowDateTimeField] = useState<boolean>(false);

  const [formData, setFormData] = useState<any>({
    name: "",
    email: "",
    contact: "",
    state: "",
    city: "",
    course: "",
    message: "",
    date: "",
  });

  const [errors, setErrors] = useState<any>({
    name: "",
    email: "",
    course: "",
    message: "",
  });

  const handleCheckboxChange = (event: ChangeEvent<HTMLInputElement>) => {
    setShowDateTimeField(event.target.checked);
  };

  const handleOpenSnackBar = () => {
    setOpenSnackBar(true);
    setTimeout(() => {
      setOpenSnackBar(false);
    }, 5000);
  };

  const handleStateCityChange = (e: any) => {
    setFormData({ ...formData, [e.target.name]: e.target.value });
  };

  const onSubmit = async (e: FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    if (errors.name || errors.email || errors.course || errors.message) {
      setSnackMessage("Please fill out the form");
      handleOpenSnackBar();
    } else {
      try {
        const res = await postRequestMethod(POST_POPUP_DATA, "", formData);
        if (res) {
          if (res.data?.email && res.data?.contact) {
            setSnackMessage("Email and Phone Number Already Present..!");
            handleOpenSnackBar();
          } else if (res.data?.email) {
            setSnackMessage("Email Already Present..!");
            handleOpenSnackBar();
          } else if (res.data?.contact) {
            setSnackMessage("Phone Number Already Present..!");
            handleOpenSnackBar();
          } else {
            setSnackMessage("Thanks for your response");
            handleOpenSnackBar();
            if (formRef.current) {
              formRef.current.reset();
              setFormData({
                name: "",
                email: "",
                contact: "",
                state: "",
                city: "",
                course: "",
                message: "",
                date: "",
              });
              setShowDateTimeField(false);
            }
          }
        }
      } catch (error) {
        console.error("Error submitting form:", error);
      }
    }
  };

  const handleSendOTP = () => {
    try {
      postRequestMethod(SEND_OTP, "", formData);
    } catch (error) {
      console.error("Error on submitting form", error);
    }
  };

  const validationHandler = async (e: any, alterName?: any) => {
    const val = e.target.value;
    const id = alterName;
    if (id) {
      let prom = new Promise((resolve, reject) => {
        if (true) {
          resolve(eventHandler(id, val));
        }
      });
      prom
        .then((res) => setErrors({ ...errors, [e.target.name]: res }))
        .then(() => setFormData({ ...formData, [e.target.name]: val }));
    } else {
      setFormData({ ...formData, [e.target.name]: val });
    }
  };

  return (
    <>
      <Container maxWidth="lg">
        <Box sx={{ flexGrow: 1 }}>
          <Typography className="formtext1">
            ​Fill all the required fields and send us. We will get in touch with
            you shortly!​
          </Typography>
          <Grid container spacing={2}>
            <Grid
              item
              xs={12}
              sm={12}
              md={6}
              component="form"
              onSubmit={onSubmit}
              ref={formRef}
            >
              <TextField
                sx={{ mt: 2, background: "white" }}
                fullWidth
                id="outlined-basic"
                label="Name"
                variant="outlined"
                name="name"
                size="small"
                error={Boolean(errors.name)}
                helperText={errors.name}
                onChange={(e) => {
                  validationHandler(e, "alphabetsAndSpace");
                }}
              />
              <TextField
                sx={{ mt: 2, background: "white" }}
                fullWidth
                id="outlined-basic"
                label="Email"
                variant="outlined"
                name="email"
                size="small"
                error={Boolean(errors.email)}
                helperText={errors.email}
                onChange={(e) => {
                  validationHandler(e, "email");
                }}
              />
              <TextField
                sx={{ mt: 2, background: "white" }}
                fullWidth
                id="outlined-basic"
                label="Contact"
                variant="outlined"
                name="contact"
                size="small"
                error={Boolean(errors.contact)}
                helperText={errors.contact}
                onChange={(event: any) => {
                  setFormData({ ...formData, contact: event.target.value });
                  validationHandler(event, "phone");
                }}
              />
              {/* <Button
                variant="contained"
                sx={{ mt: 2 }}
                onClick={handleSendOTP}
              >
                Verify Number
              </Button> */}
              {/* State */}
              <Grid item xs={12}>
                <FormControl fullWidth sx={{ mt: 2 }}>
                  <InputLabel id="demo-simple-country-label">State</InputLabel>
                  <Select
                    required
                    labelId="demo-simple-country-label"
                    name="state"
                    className="form-control"
                    label="State"
                    value={formData.state}
                    onChange={handleStateCityChange}
                    fullWidth
                    size="small"
                    sx={{ p: "3px" }}
                  >
                    <MenuItem defaultValue="">--select state--</MenuItem>
                    {statescity.map((state: any) => (
                      <MenuItem value={state.state_name} key={state.state_name}>
                        {state.state_name}
                      </MenuItem>
                    ))}
                  </Select>
                </FormControl>
              </Grid>
              {/* City */}
              <Grid item xs={12}>
                <FormControl fullWidth sx={{ mt: 2 }}>
                  <InputLabel id="demo-simple-country-label">City</InputLabel>
                  <Select
                    required
                    labelId="demo-simple-country-label"
                    name="city"
                    className="form-control"
                    label="City"
                    value={formData.city}
                    onChange={handleStateCityChange}
                    fullWidth
                    size="small"
                    sx={{ p: "3px" }}
                  >
                    <MenuItem defaultValue="">--select city--</MenuItem>
                    {formData.state &&
                      statescity
                        .find(
                          (state: any) => state.state_name === formData.state
                        )
                        ?.cities.map((city: any, index: any) => (
                          <MenuItem value={city} key={index}>
                            {city}
                          </MenuItem>
                        ))}
                  </Select>
                </FormControl>
              </Grid>
              <TextField
                sx={{ mt: 2, background: "white" }}
                fullWidth
                id="outlined-basic"
                label="Course"
                variant="outlined"
                name="course"
                size="small"
                error={Boolean(errors.course)}
                helperText={errors.course}
                onChange={(e) => {
                  validationHandler(e, "alphabetsAndSpace");
                }}
              />
              <TextField
                sx={{ mt: 2, background: "white" }}
                fullWidth
                id="outlined-basic"
                label="Message"
                variant="outlined"
                multiline
                rows={4}
                name="message"
                error={Boolean(errors.message)}
                helperText={errors.message}
                onChange={(e) => {
                  validationHandler(e, "message");
                }}
              />
              <Grid container>
                <Checkbox
                  checked={showDateTimeField}
                  onChange={handleCheckboxChange}
                />
                <Typography sx={{ mt: 1 }}>
                  If required schedule councelling meeting
                </Typography>
              </Grid>
              {showDateTimeField && (
                <TextField
                  sx={{ mt: 2, background: "white" }}
                  fullWidth
                  type="datetime-local"
                  name="date"
                  value={formData.date}
                  onChange={(event: any) => {
                    setFormData({
                      ...formData,
                      date: event.target.value,
                    });
                  }}
                />
              )}
              <Box textAlign="center" sx={{ my: 3 }}>
                <Button variant="contained" fullWidth type="submit">
                  Submit
                </Button>
                <CommonSnackBar message={snackMessage} open={openSnackBar} />
              </Box>
            </Grid>
            <Grid
              item
              xs={12}
              sm={12}
              md={6}
              sx={{ display: { xs: "none", sm: "block" } }}
            >
              <ImageList sx={{ width: "100%", height: "100%" }} cols={1}>
                <ImageListItem>
                  <img
                    src="https://ik.imagekit.io/nwssoft/Home_Page/MicrosoftTeams-image__9_.png?updatedAt=1680608756684"
                    loading="lazy"
                    alt="Image 1"
                  />
                </ImageListItem>
              </ImageList>
            </Grid>
          </Grid>
        </Box>
      </Container>
    </>
  );
}
