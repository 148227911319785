import {
  Page,
  Text,
  Image,
  Document,
  StyleSheet,
  View,
  PDFViewer,
} from "@react-pdf/renderer";
import { useEffect } from "react";

const styles = StyleSheet.create({
  body: {
    paddingTop: 25,
    paddingBottom: 45,
    paddingHorizontal: 35,
  },
  para1: {
    flexDirection: "row",
    flexWrap: "wrap",
  },
  para2: {
    flexDirection: "row",
    flexWrap: "wrap",
    marginTop: 10,
    // marginLeft: 10,
    // padding: 10,
  },
  para3: {
    flexDirection: "row",
    flexWrap: "wrap",
    marginTop: 15,
    // marginLeft: 10,
    // padding: 10,
  },
  para4: {
    flexDirection: "row",
    flexWrap: "wrap",
    marginTop: 25,

    // padding: 1,
  },
  para5: {
    marginTop: 15,
  },
  parasign: {
    flexDirection: "row",
    flexWrap: "wrap",
    marginTop: 100,
    // padding: 10,
  },
  column1: {
    width: "20%",
    padding: 20,
  },
  column2: {
    width: "80%",
    marginTop: 5,
    // padding: 10,
    // marginLeft: 50,
  },
  column3: {
    width: "33.33%",
    // padding: 1,
  },
  column4: {
    width: "50%",
  },
  text: {
    fontSize: 12,
    textAlign: "left",
    marginLeft: 10,
  },
  sliptext: {
    fontSize: 12,
    marginLeft: 10,
    textAlign: "justify",
    width: "33%",
  },
  text4: {
    fontSize: 12,
    textAlign: "justify",
  },
  textb: {
    fontSize: 12,
    textAlign: "right",
  },
  text1: {
    fontSize: 15,
    textAlign: "center",
    marginBottom: 10,
  },
  text3: {
    fontSize: 12,
    textAlign: "justify",
    padding: 30,
    marginTop: 5,
    textIndent: "50px",
    letterSpacing: "0px",
    lineHeight: "2px",
  },
  image: {
    height: 70,
    width: 70,
  },
  pageNumber: {
    position: "absolute",
    fontSize: 12,
    bottom: 30,
    left: 0,
    right: 0,
    textAlign: "center",
    color: "grey",
    fontFamily: "AntonFamily",
  },
  border: {
    borderBottom: " 1px solid black",
    margin: 5,
  },
  wish: {
    margin: 30,
  },
  sign: {
    margin: 30,
  },

  feeText: {
    textAlign: "center",
    marginTop: "5",
    marginLeft: 20,
    fontSize: 15,
  },
  receiptNo: {
    fontSize: 12,
    marginLeft: 220,
  },
  slip: {
    fontSize: 12,
    marginLeft: 300,
    padding: 3,
  },
  university: {
    fontSize: 12,
    padding: 3,
  },
  receive: {
    fontSize: 12,
    textAlign: "left",
    // marginLeft: 5,
    padding: 1,
  },
  receive1: {
    fontSize: 12,
    textAlign: "left",
    // marginLeft: 5,
  },
  widthStyle: {
    width: "100%",
  },
});

const FirstPaymentReceiptLetter = (props: any) => {
  let date = new Date();
  let day = date.getDate();
  let month = date.getMonth() + 1;
  let year = date.getFullYear();
  let currentDate = `${day}-${month}-${year}`;

  function convertToLetters(number: any) {
    const units = [
      "",
      "one",
      "two",
      "three",
      "four",
      "five",
      "six",
      "seven",
      "eight",
      "nine",
    ];
    const teens = [
      "ten",
      "eleven",
      "twelve",
      "thirteen",
      "fourteen",
      "fifteen",
      "sixteen",
      "seventeen",
      "eighteen",
      "nineteen",
    ];
    const tens = [
      "",
      "",
      "twenty",
      "thirty",
      "forty",
      "fifty",
      "sixty",
      "seventy",
      "eighty",
      "ninety",
    ];

    if (number === 0) {
      return "zero";
    }

    let result = "";

    if (number >= 1000) {
      result += convertToLetters(Math.floor(number / 1000)) + " thousand ";
      number %= 1000;
    }

    if (number >= 100) {
      result += convertToLetters(Math.floor(number / 100)) + " hundred ";
      number %= 100;
    }

    if (number >= 20) {
      result += tens[Math.floor(number / 10)] + " ";
      number %= 10;
    } else if (number >= 10) {
      result += teens[number - 10] + " ";
      number = 0;
    }

    if (number > 0) {
      result += units[number] + " ";
    }

    return result.trim();
  }

  const result = convertToLetters(props.props?.courseData?.courseFees);

  return (
    <>
      <Document>
        return(
        <Page size="A4" style={styles.body}>
          {console.log("props", props.props)}
          <View style={styles.para1}>
            <View style={styles.column1}>
              <Image
                style={styles.image}
                src="https://ik.imagekit.io/nwssoft/AboutUS-College-Logo/Logo/New_Logo_with_Crop/prena_acedemy_logo.jfif?updatedAt=1685344751380"
              ></Image>
            </View>
            <View style={styles.column2}>
              <Text style={styles.text}>PRERNA ACADEMY PVT. LTD., INDORE</Text>
              <Text style={styles.text}>
                Corporate Office : 82 Swami Vivekanand Nagar Telephone Square
                Kanadia Road Indore
              </Text>
              <Text style={styles.text}>(M.P) pin 452018</Text>
              <Text style={styles.text}>
                Ph. : (+91) 835 889 9900, (0731) 4298599
              </Text>
              <Text style={styles.text}>Toll Free No. : 1800120-224466</Text>
              <Text style={styles.text}>Email :info@prernaacademy.in</Text>
              <Text style={styles.text}>website: www.prernaacademy.in</Text>
            </View>
          </View>
          <View style={styles.border}></View>

          <View>
            <Text style={styles.feeText}>FEES RECEIPT</Text>
          </View>

          <View style={styles.para2}>
            <View>
              <Text style={styles.slip}>
                Registration No. : {props.props?.studentId}
              </Text>
              <Text style={styles.slip}>
                Receipt No. :{/* {props.props.receiptNo} */}
              </Text>
              <Text style={styles.slip}>
                Slip No. :{props.props?.courseData?.slipNo}
              </Text>
              <Text style={styles.slip}>
                Date :{props.props?.courseData?.depositDate}
              </Text>
            </View>
            <View>
              <Text style={styles.university}>
                University : {`${props?.props?.courseData?.universityName}`}
              </Text>
              <Text style={styles.university}>
                Course : {props.props?.courseData?.courseName}
              </Text>
              <Text style={styles.university}>
                Total Fees :{props.props?.courseData?.courseFees}
              </Text>
            </View>
            <View style={styles.para3}>
              <View style={styles.widthStyle}>
                <Text style={styles.receive}>
                  Receive with thanks from Mr./Mrs.:
                  {props?.props?.studentData?.firstName}{" "}
                  {props.props?.studentData?.lastName}
                </Text>
              </View>
              <View style={styles.widthStyle}>
                <Text style={styles.receive}>
                  enrollment for the course :
                  {props.props?.courseData?.courseName}
                </Text>
              </View>
              <View style={styles.widthStyle}>
                <Text style={styles.receive}>
                  a sum of rupees :{props.props?.courseData?.courseFees}
                </Text>
              </View>
              <View style={styles.widthStyle}>
                <Text style={styles.receive}>
                  in Cash / by Cheque / DD No. / Online :
                  {props.props?.courseData?.paymentMode}
                </Text>
              </View>
              <View style={styles.widthStyle}>
                <Text style={styles.receive}></Text>
                <Text style={styles.receive}>
                  towards course fee : {result + " " + "only"}
                </Text>
              </View>
              <View style={styles.widthStyle}>
                <Text style={styles.receive}>
                  Fees Received Rs. :{props.props?.courseData?.feesReceived}
                </Text>
              </View>
              <View style={styles.widthStyle}>
                <Text style={styles.receive}>
                  Registration Fee :{props.props?.courseData?.regFees}
                </Text>
              </View>
              <View style={styles.widthStyle}>
                <Text style={styles.receive}>
                  Caution Money :{props.props?.courseData?.cautionMoney}
                </Text>
              </View>
              <View style={styles.widthStyle}>
                <Text style={styles.receive}>
                  Other Fees :{props.props?.courseData?.otherFees}
                </Text>
              </View>

              <View style={styles.widthStyle}>
                <Text style={styles.receive}>
                  Total Fees Deposit :{props.props?.courseData?.feesReceived}
                </Text>
              </View>
            </View>
          </View>

          <View style={styles.para4}>
            <Text style={styles.receive}>
              1.All Cheques and demand drafts are subject to realization.
            </Text>
            <Text style={styles.receive}>
              2.This receipt must be kept for it any have to be produced in
              fuuture when demanded.
            </Text>
            <Text style={styles.receive}>
              3.Fee once paid is neither refundable nor adjustable in any case *
              Subject to Indore Jurisdiiction.
            </Text>
            <Text style={styles.receive}>
              4.Registration Fees & Caution Money not Included in TotalFee.
            </Text>
          </View>

          <View style={styles.parasign}>
            <View style={styles.column4}>
              <Text style={styles.text}>Student Signature</Text>
            </View>
            <View style={styles.column4}>
              <Text style={styles.textb}>Authorised Signature</Text>
            </View>
          </View>
          <View></View>
        </Page>
        )
      </Document>
    </>
  );
};

export default FirstPaymentReceiptLetter;
