import React, { useEffect } from "react";

import {
  Page,
  Text,
  Image,
  Document,
  StyleSheet,
  View,
} from "@react-pdf/renderer";
import { Padding } from "@mui/icons-material";

const styles = StyleSheet.create({
  para1: {
    flexDirection: "row",
    flexWrap: "wrap",
  },

  column1: {
    width: "20%",
    padding: 20,
  },
  column2: {
    width: "80%",
    padding: 10,
  },

  text: {
    fontSize: 11,
    textAlign: "justify",
  },
  HeadingText: {
    fontSize: 14,
    textAlign: "justify",
    fontWeight: "bold",
    paddingTop: 10,
    paddingBottom: 5,
    marginTop: 10,
  },
  personalDetails: {
    flexDirection: "row",
    flexWrap: "wrap",
  },

  personalDetailscolumn1: {
    width: "25%",
    padding: 3,
  },
  personalDetailscolumn2: {
    width: "25%",
    padding: 3,
  },
  personalDetailscolumn3: {
    width: "25%",
    padding: 3,
  },
  personalDetailscolumn4: {
    width: "25%",
    padding: 3,
  },
  image: {
    height: 70,
    width: 70,
  },

  border: {
    borderBottom: " 1px solid black",
    margin: 5,
  },
  page: {
    padding: 10,
    fontFamily: "Times-Roman",
  },
  page1: {
    padding: 30,
    fontFamily: "Times-Roman",
  },
  view: {
    padding: 20,
  },
  signature1: {
    width: "50%",
  },
  signature2: {
    width: "50%",
  },
});

export default function DownloadStudentProfileForm(props: any) {
  useEffect(() => {
    console.log("details in pfp", props.data);
  }, [props]);

  return (
    <>
      <Document>
        return(
        <Page size="A4" style={styles.page}>
          <View style={styles.para1}>
            <View style={styles.column1}>
              <Image
                style={styles.image}
                src="https://ik.imagekit.io/nwssoft/AboutUS-College-Logo/Logo/New_Logo_with_Crop/prena_acedemy_logo.jfif?updatedAt=1685344751380"
              ></Image>
            </View>
            <View style={styles.column2}>
              <Text style={styles.text}>PRERNA ACADEMY PVT. LTD., INDORE</Text>
              <Text style={styles.text}>
                Corporate Office : 82 Swami Vivekanand Nagar Telephone Square
                Kanadia Road Indore
              </Text>
              <Text style={styles.text}>(M.P) pin 452018</Text>
              <Text style={styles.text}>
                Ph. : (+91) 835 889 9900, (0731) 4298599
              </Text>
              <Text style={styles.text}>Toll Free No. : 1800120-224466</Text>
              <Text style={styles.text}>Email :info@prernaacademy.in</Text>
              <Text style={styles.text}>website: www.prernaacademy.in</Text>
            </View>
          </View>
          <View style={styles.border}></View>
          <View style={styles.view}>
            {/* Personal Details */}
            <Text style={styles.HeadingText}>Personal Details:</Text>
            <View style={styles.personalDetails}>
              <Text style={styles.text}>
                PA Enrollment No: {"                 "}
                {props?.data?.studentId}
              </Text>
            </View>
            <View style={styles.personalDetails}>
              <View style={styles.personalDetailscolumn1}>
                <Text style={styles.text}>First Name:</Text>
              </View>
              <View style={styles.personalDetailscolumn2}>
                <Text style={styles.text}>
                  {props?.data?.studentData?.firstName}
                </Text>
              </View>
              <View style={styles.personalDetailscolumn3}>
                <Text style={styles.text}>Last Name:</Text>
              </View>
              <View style={styles.personalDetailscolumn4}>
                <Text style={styles.text}>
                  {props?.data?.studentData?.lastName}
                </Text>
              </View>
            </View>
            <View style={styles.personalDetails}>
              <View style={styles.personalDetailscolumn1}>
                <Text style={styles.text}>Email:</Text>
              </View>
              <View style={styles.personalDetailscolumn2}>
                <Text style={styles.text}>
                  {props?.data?.studentData?.email}
                </Text>
              </View>
              <View style={styles.personalDetailscolumn3}>
                <Text style={styles.text}>Date Of Birth:</Text>
              </View>
              <View style={styles.personalDetailscolumn4}>
                <Text style={styles.text}>
                  {props?.data?.studentData?.dateOfBirth}
                </Text>
              </View>
            </View>
            <View style={styles.personalDetails}>
              <View style={styles.personalDetailscolumn1}>
                <Text style={styles.text}>Mobile No:</Text>
              </View>
              <View style={styles.personalDetailscolumn2}>
                <Text style={styles.text}>
                  {props?.data?.studentData?.contactNumber}
                </Text>
              </View>
              <View style={styles.personalDetailscolumn3}>
                <Text style={styles.text}>Whatsapp No:</Text>
              </View>
              <View style={styles.personalDetailscolumn4}>
                <Text style={styles.text}>
                  {props?.data?.studentData?.whatsappNumber}
                </Text>
              </View>
            </View>
            <View style={styles.personalDetails}>
              <View style={styles.personalDetailscolumn1}>
                <Text style={styles.text}>Gender:</Text>
              </View>
              <View style={styles.personalDetailscolumn2}>
                <Text style={styles.text}>
                  {props?.data?.studentData?.gender}
                </Text>
              </View>
              <View style={styles.personalDetailscolumn3}>
                <Text style={styles.text}>Adhar No: </Text>
              </View>
              <View style={styles.personalDetailscolumn4}>
                <Text style={styles.text}>
                  {props?.data?.studentData?.aadhaar}
                </Text>
              </View>
            </View>
            <View style={styles.personalDetails}>
              <View style={styles.personalDetailscolumn1}>
                <Text style={styles.text}>Catageory:</Text>
              </View>
              <View style={styles.personalDetailscolumn2}>
                <Text style={styles.text}>
                  {props?.data?.studentData?.categories}
                </Text>
              </View>
              <View style={styles.personalDetailscolumn3}>
                <Text style={styles.text}>Religion: </Text>
              </View>
              <View style={styles.personalDetailscolumn4}>
                <Text style={styles.text}>
                  {props?.data?.studentData?.religion}
                </Text>
              </View>
            </View>
            <View style={styles.personalDetails}>
              <View style={styles.personalDetailscolumn1}>
                <Text style={styles.text}>Nationality:</Text>
              </View>
              <View style={styles.personalDetailscolumn2}>
                <Text style={styles.text}>
                  {props?.data?.studentData?.nationality}
                </Text>
              </View>
              <View style={styles.personalDetailscolumn3}>
                <Text style={styles.text}>Domicile: </Text>
              </View>
              <View style={styles.personalDetailscolumn4}>
                <Text style={styles.text}>
                  {props?.data?.studentData?.domicile}
                </Text>
              </View>
            </View>
            <View style={styles.personalDetails}>
              <View style={styles.personalDetailscolumn1}>
                <Text style={styles.text}>Language:</Text>
              </View>
              <View style={styles.personalDetailscolumn2}>
                <Text style={styles.text}>
                  {props?.data?.studentData?.language}
                </Text>
              </View>
              <View style={styles.personalDetailscolumn3}>
                <Text style={styles.text}>Marital Status:</Text>
              </View>
              <View style={styles.personalDetailscolumn4}>
                <Text style={styles.text}>
                  {props?.data?.studentData?.maritalstatus}
                </Text>
              </View>
            </View>

            {/* </View> */}
            {/* Present Address */}
            <Text style={styles.HeadingText}>Present Address:</Text>
            <View style={styles.personalDetails}>
              <View style={styles.personalDetailscolumn1}>
                <Text style={styles.text}>Address 1:</Text>
              </View>
              <View style={styles.personalDetailscolumn2}>
                <Text style={styles.text}>
                  {props?.data?.studentData?.presentAddress1}
                </Text>
              </View>
              <View style={styles.personalDetailscolumn3}>
                <Text style={styles.text}>Address 2:</Text>
              </View>
              <View style={styles.personalDetailscolumn4}>
                <Text style={styles.text}>
                  {props?.data?.studentData?.presentAddress2}
                </Text>
              </View>
            </View>

            <View style={styles.personalDetails}>
              <View style={styles.personalDetailscolumn1}>
                <Text style={styles.text}>State:</Text>
              </View>
              <View style={styles.personalDetailscolumn2}>
                <Text style={styles.text}>
                  {props?.data?.studentData?.presentState}
                </Text>
              </View>
              <View style={styles.personalDetailscolumn3}>
                <Text style={styles.text}>City:</Text>
              </View>
              <View style={styles.personalDetailscolumn4}>
                <Text style={styles.text}>
                  {props?.data?.studentData?.presentCity}
                </Text>
              </View>
            </View>

            <View style={styles.personalDetails}>
              <View style={styles.personalDetailscolumn1}>
                <Text style={styles.text}>Zipcode:</Text>
              </View>
              <View style={styles.personalDetailscolumn2}>
                <Text style={styles.text}>
                  {props?.data?.studentData?.presentZipCode}
                </Text>
              </View>
            </View>

            {/* Permanent Address */}
            <Text style={styles.HeadingText}>Permanent Address:</Text>

            <View style={styles.personalDetails}>
              <View style={styles.personalDetailscolumn1}>
                <Text style={styles.text}>Address 1:</Text>
              </View>
              <View style={styles.personalDetailscolumn2}>
                <Text style={styles.text}>
                  {props?.data?.studentData?.permanentAddress1}
                </Text>
              </View>
              <View style={styles.personalDetailscolumn3}>
                <Text style={styles.text}>Address 2:</Text>
              </View>
              <View style={styles.personalDetailscolumn4}>
                <Text style={styles.text}>
                  {props?.data?.studentData?.permanentAddress2}
                </Text>
              </View>
            </View>

            <View style={styles.personalDetails}>
              <View style={styles.personalDetailscolumn1}>
                <Text style={styles.text}>State:</Text>
              </View>
              <View style={styles.personalDetailscolumn2}>
                <Text style={styles.text}>
                  {props?.data?.studentData?.permanentState}
                </Text>
              </View>
              <View style={styles.personalDetailscolumn3}>
                <Text style={styles.text}>City:</Text>
              </View>
              <View style={styles.personalDetailscolumn4}>
                <Text style={styles.text}>
                  {props?.data?.studentData?.permanentCity}
                </Text>
              </View>
            </View>

            <View style={styles.personalDetails}>
              <View style={styles.personalDetailscolumn1}>
                <Text style={styles.text}>Zipcode:</Text>
              </View>
              <View style={styles.personalDetailscolumn2}>
                <Text style={styles.text}>
                  {props?.data?.studentData?.permanentZipCode}
                </Text>
              </View>
            </View>
            {/* Course Details */}
            <Text style={styles.HeadingText}>Course Details:</Text>
            <View style={styles.personalDetails}>
              <View style={styles.personalDetailscolumn1}>
                <Text style={styles.text}>University Enrollment No:</Text>
              </View>
              <View style={styles.personalDetailscolumn2}>
                <Text style={styles.text}>{props?.data?.enrollmentNo}</Text>
              </View>
              <View style={styles.personalDetailscolumn3}>
                <Text style={styles.text}> Admission Date:</Text>
              </View>
              <View style={styles.personalDetailscolumn4}>
                <Text style={styles.text}>
                  {props?.data?.courseData?.admissionDate}
                </Text>
              </View>
            </View>

            <View style={styles.personalDetails}>
              <View style={styles.personalDetailscolumn1}>
                <Text style={styles.text}>University Name:</Text>
              </View>
              <View style={styles.personalDetailscolumn2}>
                <Text style={styles.text}>
                  {props?.data?.courseData?.universityName}
                </Text>
              </View>
              <View style={styles.personalDetailscolumn3}>
                <Text style={styles.text}> Course Name:</Text>
              </View>
              <View style={styles.personalDetailscolumn4}>
                <Text style={styles.text}>
                  {props?.data?.courseData?.courseName}
                </Text>
              </View>
            </View>

            <View style={styles.personalDetails}>
              <View style={styles.personalDetailscolumn1}>
                <Text style={styles.text}>Course Type:</Text>
              </View>
              <View style={styles.personalDetailscolumn2}>
                <Text style={styles.text}>{props?.data?.courseData?.mode}</Text>
              </View>
              <View style={styles.personalDetailscolumn3}>
                <Text style={styles.text}> Course Catageory:</Text>
              </View>
              <View style={styles.personalDetailscolumn4}>
                <Text style={styles.text}>
                  {props?.data?.courseData?.courseCategory}
                </Text>
              </View>
            </View>

            <View style={styles.personalDetails}>
              <View style={styles.personalDetailscolumn1}>
                <Text style={styles.text}>Registration Type:</Text>
              </View>
              <View style={styles.personalDetailscolumn2}>
                <Text style={styles.text}>
                  {props?.data?.courseData?.regType}
                </Text>
              </View>
              <View style={styles.personalDetailscolumn3}>
                <Text style={styles.text}> Department:</Text>
              </View>
              <View style={styles.personalDetailscolumn4}>
                <Text style={styles.text}>
                  {props?.data?.courseData?.departmentName}
                </Text>
              </View>
            </View>

            <View style={styles.personalDetails}>
              <View style={styles.personalDetailscolumn1}>
                <Text style={styles.text}>Specialization:</Text>
              </View>
              <View style={styles.personalDetailscolumn2}>
                <Text style={styles.text}>
                  {props?.data?.courseData?.specialization}
                </Text>
              </View>
              <View style={styles.personalDetailscolumn3}>
                <Text style={styles.text}> Session:</Text>
              </View>
              <View style={styles.personalDetailscolumn4}>
                <Text style={styles.text}>
                  {props?.data?.courseData?.sessionFrom} -{" "}
                  {props?.data?.courseData?.sessionTo}
                </Text>
              </View>
            </View>

            <View style={styles.personalDetails}>
              <View style={styles.personalDetailscolumn1}>
                <Text style={styles.text}>Course Year/Semester:</Text>
              </View>
              <View style={styles.personalDetailscolumn2}>
                <Text style={styles.text}>
                  {props?.data?.courseData?.admissionSemesterYear}
                </Text>
              </View>
              <View style={styles.personalDetailscolumn3}>
                <Text style={styles.text}> Total No Of Semester/Year:</Text>
              </View>
              <View style={styles.personalDetailscolumn4}>
                <Text style={styles.text}>
                  {props?.data?.courseData?.noOfSemsOrYears}
                </Text>
              </View>
            </View>

            {/* Fee Details */}

            <Text style={styles.HeadingText}>Fee Details:</Text>
            <View style={styles.personalDetails}>
              <View style={styles.personalDetailscolumn1}>
                <Text style={styles.text}>Total Fee:</Text>
              </View>
              <View style={styles.personalDetailscolumn2}>
                <Text style={styles.text}>
                  {props?.data?.courseData?.totalFee}
                </Text>
              </View>
              <View style={styles.personalDetailscolumn3}>
                <Text style={styles.text}> Registration Fee:</Text>
              </View>
              <View style={styles.personalDetailscolumn4}>
                <Text style={styles.text}>
                  {props?.data?.courseData?.regFees}
                </Text>
              </View>
            </View>

            <View style={styles.personalDetails}>
              <View style={styles.personalDetailscolumn1}>
                <Text style={styles.text}>Other Fee:</Text>
              </View>
              <View style={styles.personalDetailscolumn2}>
                <Text style={styles.text}>
                  {props?.data?.courseData?.otherFees}
                </Text>
              </View>
              <View style={styles.personalDetailscolumn3}>
                <Text style={styles.text}> Caution Money:</Text>
              </View>
              <View style={styles.personalDetailscolumn4}>
                <Text style={styles.text}>
                  {props?.data?.courseData?.cautionMoney}
                </Text>
              </View>
            </View>

            <View style={styles.personalDetails}>
              <View style={styles.personalDetailscolumn1}>
                <Text style={styles.text}>Fees Paid:</Text>
              </View>
              <View style={styles.personalDetailscolumn2}>
                <Text style={styles.text}>
                  {props?.data?.courseData?.feesReceived}
                </Text>
              </View>
            </View>
          </View>
        </Page>
        {/* Installments */}
        <Page size="A4" style={styles.page1}>
          <Text style={styles.HeadingText}>Installment Details:</Text>
          {/* <Text style={{ ...styles.text, marginLeft: 10 }}>Installments:</Text> */}
          <View
            style={{
              flexDirection: "row",
              marginBottom: 5,
              marginLeft: 2,
              padding: 5,
            }}
          >
            <Text
              style={{
                ...styles.text,
                flex: 1,
              }}
            >
              Installment Date:
            </Text>
            <Text style={{ ...styles.text, flex: 1 }}>Amount:</Text>
            <Text style={{ ...styles.text, flex: 1 }}>Status:</Text>
          </View>
          {props?.data?.courseData?.installmentDetails?.map(
            (installment: any) => (
              <View
                key={installment.id}
                style={{
                  flexDirection: "row",
                  marginBottom: 5,
                  paddingLeft: 5,
                  paddingRight: 5,
                }}
              >
                <Text style={{ ...styles.text, flex: 1 }}>
                  {installment.date}
                </Text>
                <Text style={{ ...styles.text, flex: 1 }}>
                  {installment.amount}
                </Text>
                {/* <Text style={{ ...styles.text, flex: 1 }}>
                  {installment.verifyPayment}
                </Text> */}
                {installment.verifyPayment === "Pending" ||
                installment.verifyPayment === "Process" ? (
                  <Text style={{ ...styles.text, flex: 1 }}>Unpaid</Text>
                ) : (
                  <Text style={{ ...styles.text, flex: 1 }}>Paid</Text>
                )}
              </View>
            )
          )}

          {/* Other Sentences */}
          <View style={{ marginTop: 20, marginBottom: 20 }}>
            <View style={{ padding: 3 }}>
              <Text style={styles.text}>
                1. For Above Program I Will Pay Total Fees Rs{" "}
                {props?.data?.courseData?.totalFee} In Lum-Sum Or In{" "}
                {props?.data?.courseData?.numberOfInstallment} Installment.
              </Text>
            </View>
            <View style={{ padding: 3 }}>
              {" "}
              <Text style={styles.text}>
                2. Above Fee Will Not Be Refund In Any Case.
              </Text>
            </View>
            <View style={{ padding: 3 }}>
              {" "}
              <Text style={styles.text}>
                3. Students Means {props?.data?.studentData?.firstName}
                {props?.data?.studentData?.lastName} Can Be Withdraw From Course
                At Any Time But Can’t Withdraw From Fees In Any Case. The Pay
                Fees will Not Be Refund In Any Condition
              </Text>
            </View>
            <View style={{ padding: 3 }}>
              <Text style={styles.text}>
                4. I Will Pay Installment By The Date Of 10 Pay Cash Instead Of
                Cheque By The Before Date Of Due That.
              </Text>
            </View>
            <View style={{ padding: 3 }}>
              {" "}
              <Text style={styles.text}>
                5. In Case If I Am Unable To Pay The Amount I Will Pay Rs.500
                Charge+ Installment Within 7 days. After 7 Days From 8 To 15
                Days The Penalty Will Be 1000 Rs Be Charged Will Pay
              </Text>
            </View>
            <View style={{ padding: 3 }}>
              {" "}
              <Text style={styles.text}>
                6. Without Completion Of My Payment I Will Not Be Eligible For
                Sitting In Any Exam And The All Responsibilities Will Be Myself.
              </Text>
            </View>
            <View style={{ padding: 3 }}>
              <Text style={styles.text}>
                7. In Case Of Any Dispute The Jurisdiction Area Will Be Indore
                Court Only.
              </Text>
            </View>
            <View style={{ padding: 3 }}>
              <Text style={styles.text}>
                8. You Will Get The Course Materials & Enrollment No. And
                Anything Belongs To University And Your Course, After Paying 50%
                Of Total Fees And Minimum Time Of 3 Months.
              </Text>
            </View>
            <View style={{ padding: 3 }}>
              {" "}
              <Text style={styles.text}>
                9. We Are Only Facilities Not Owner Of University, So Finally We
                Will Take The Decisions According To Respective Universities And
                UGC Rules & Regulations Which Can Be Change Time To Time.
              </Text>
            </View>
          </View>
          <View style={{ ...styles.personalDetails, marginTop: 100 }}>
            <View style={styles.signature1}>
              <Text style={styles.text}>Student Signature</Text>
            </View>
            <View style={styles.signature1}>
              <Text
                style={{ ...styles.text, textAlign: "right", marginRight: 3 }}
              >
                Authorised Signature
              </Text>
            </View>
          </View>
        </Page>
        )
      </Document>
    </>
  );
}
