import { Button, Container, Grid, InputLabel, Paper } from "@mui/material";

export default function DocumentsPreview(props: any) {
  const additionalFiles = props.Data?.fileInputs?.additionalFiles || [];

  return (
    <Container sx={{ mt: 3 }}>
      <Paper elevation={0}>
        <Grid
          container
          xs={12}
          rowSpacing={1}
          columnSpacing={{ xs: 1, sm: 2, md: 3 }}
          sx={{ m: 2 }}
        >
          {/* Photo */}
          <Grid item xs={12} sm={4}>
            <InputLabel
              htmlFor="standard-basic"
              sx={{ mt: 2, color: "#0288d1" }}
            >
              Photo :
            </InputLabel>
            {props.Data?.fileInputs?.photo?.name !== undefined && (
              <>
                <Button
                  variant="contained"
                  href={props.Data?.fileInputs?.photo?.path}
                  sx={{ mt: 2 }}
                >
                  Download
                </Button>
              </>
            )}
            <InputLabel htmlFor="standard-basic" sx={{ mt: 2, color: "black" }}>
              {props.Data?.fileInputs?.photo?.originalName}
            </InputLabel>
          </Grid>

          {/* Signature */}
          <Grid item xs={12} sm={4}>
            <InputLabel
              htmlFor="standard-basic"
              sx={{ mt: 2, color: "#0288d1" }}
            >
              Signature :
            </InputLabel>
            {props.Data?.fileInputs?.signature?.name !== undefined && (
              <>
                <Button
                  variant="contained"
                  href={props.Data?.fileInputs?.signature?.path}
                  sx={{ mt: 2 }}
                >
                  Download
                </Button>
              </>
            )}
            <InputLabel htmlFor="standard-basic" sx={{ mt: 2, color: "black" }}>
              {props.Data?.fileInputs?.signature?.originalName}
            </InputLabel>
          </Grid>
        </Grid>

        {/* Additional Files */}
        <InputLabel htmlFor="standard-basic" sx={{ mt: 2, color: "#0288d1" }}>
          Additional Files:
        </InputLabel>
        <Grid
          container
          xs={12}
          rowSpacing={1}
          columnSpacing={{ xs: 1, sm: 2, md: 3 }}
        >
          {additionalFiles.map((file: any, index: number) => (
            <Grid item key={index} xs={12} sm={4} sx={{ mt: 2 }}>
              {file.name !== undefined && (
                <>
                  <Button variant="contained" href={file.path} sx={{ mt: 2 }}>
                    Download
                  </Button>
                </>
              )}
              <InputLabel
                htmlFor={`additional-file-${index}`}
                sx={{ mt: 2, color: "black" }}
              >
                {file.originalname}
              </InputLabel>
            </Grid>
          ))}
        </Grid>
      </Paper>
    </Container>
  );
}
