import {
  Box,
  Button,
  Container,
  FormControl,
  Grid,
  InputLabel,
  LinearProgress,
  MenuItem,
  TextField,
  Typography,
} from "@mui/material/";
import Paper from "@mui/material/Paper";
import Select from "@mui/material/Select";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { useEffect, useRef, useState } from "react";
import CommonSnackBar from "../../Components/Common/CommonSnackbar";
import stateCity from "../../Components/Common/stateCity.json";
import useValidation from "../../Hooks/useTypescriptValidation";
import { getRequest, postRequestMethod } from "../../api/api";
import {
  CENTERHEAD_LIST,
  EXECUTIVE_LIST,
  POST_LEAD,
  TEAMLEAD_LIST,
} from "../../api/server";

export default function AddLead() {
  const referenceBy = ["Center Head", "Team Lead", "Executive", "Other"];
  const { eventHandler } = useValidation();

  const [statesCity] = useState<any>(stateCity);
  const [passingYear, setPassingYear] = useState<any>();
  const [openSnackBar, setOpenSnackBar] = useState<boolean>(false);
  const [snackMessage, setSnackMessage] = useState<string>("");
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [resetDatePickerKey, setResetDatePickerKey] = useState<number>(0);

  // for resetting the form to null after submit
  const formRef = useRef<HTMLFormElement>(null);

  // for Center Head List
  const [centerHeadList, setCenterHeadList] = useState<any[]>([]);

  // for team lead list
  const [teamLeadList, setTeamLeadList] = useState<any[]>([]);

  // for Executive list
  const [executiveList, setExecutiveList] = useState<any[]>([]);

  //for set selected dropdown of refby
  const [show, setShow] = useState<string>("");

  // state to maintain form data
  const [formData, setFormData] = useState<any>({
    fname: "",
    lname: "",
    email: "",
    phone: "",
    experience: "",
    city: "",
    state: "",
    qualification: "",
    passingYear: "",
    refBy: "",
    refName: "",
  });

  // state to maintain error
  const [errors, setErrors] = useState<any>({
    fname: "",
    lname: "",
    email: "",
    phone: "",
    experience: "",
    qualification: "",
    refName: "",
  });

  // handle snackbar
  const handleOpenSnackBar = () => {
    setOpenSnackBar(true);
    setTimeout(() => {
      setOpenSnackBar(false);
    }, 5000);
  };

  // Function to update the input data in a state
  const stateUpdater = (e: any) => {
    if (e.target.name === "refBy") {
      setFormData({
        ...formData,
        [e.target.name]: e.target.value,
        refName: "",
      });
    } else {
      setFormData({ ...formData, [e.target.name]: e.target.value });
    }
  };

  const clearPassingYear = () => {
    setResetDatePickerKey((prevKey) => prevKey + 1);
  };

  // Passing Year
  const handlePassingYear = (e: any) => {
    if (e && e.$y) {
      setPassingYear(e.$y);
    }
  };

  //post lead Data
  const onSubmit = async (e: any) => {
    e.preventDefault();
    try {
      let ob = { ...formData, passingYear };
      setIsLoading(true);
      postRequestMethod(POST_LEAD, "", ob).then((res) => {
        setIsLoading(false);
        if (res) {
          switch (true) {
            case res.data?.both:
              setSnackMessage("Email and Phone Number Already Present..!");
              break;
            case res.data?.email:
              setSnackMessage("Email Already Present..!");
              break;
            case res.data?.phone:
              setSnackMessage("Phone Number Already Present..!");
              break;
            default:
              setSnackMessage("Lead data added successfully");
              if (formRef.current) {
                formRef.current.reset();
                stateClearer();
                clearPassingYear();
              }
              break;
          }
          handleOpenSnackBar();
        }
      });
    } catch (error) {
      console.error("Error on adding lead form:", error);
    }
  };

  // Function to clear state
  let stateClearer = () => {
    setFormData({
      fname: "",
      lname: "",
      email: "",
      phone: "",
      experience: "",
      city: "",
      state: "",
      qualification: "",
      refBy: "",
      refName: "",
    });
  };

  // function to validate data
  const validationHandler = async (e: any, alterName?: any) => {
    const val = e.target.value;
    const id = alterName;
    if (id) {
      let prom = new Promise((resolve) => {
        if (true) {
          resolve(eventHandler(id, val));
        }
      });
      prom.then((res) => setErrors({ ...errors, [e.target.name]: res }));
    }
  };

  // fetching center head list
  const getCenterHeadList = async () => {
    let result: any = await getRequest(CENTERHEAD_LIST, "");
    setCenterHeadList(result?.data);
  };

  // fetching team lead list
  const getTeamLeadList = async () => {
    let result: any = await getRequest(TEAMLEAD_LIST, "");
    setTeamLeadList(result.data);
  };

  // fetching executive list
  const getExecutiveList = async () => {
    let result: any = await getRequest(EXECUTIVE_LIST, "");
    setExecutiveList(result?.data);
  };

  useEffect(() => {
    getCenterHeadList();
    getTeamLeadList();
    getExecutiveList();
  }, []);

  return (
    <Container maxWidth="lg">
      {isLoading && <LinearProgress />}
      <Paper elevation={20} sx={{ p: 4 }}>
        <Box sx={{ flexGrow: 1 }}>
          <Typography
            variant="h6"
            align="center"
            sx={{ m: "10px", color: "#0288d1" }}
          >
            ADD LEAD
          </Typography>
          <Grid container spacing={2}>
            <Grid
              item
              xs={6}
              sm={12}
              component="form"
              onSubmit={onSubmit}
              ref={formRef}
            >
              {/* First Name */}
              <TextField
                sx={{ mt: 2, background: "white" }}
                required
                fullWidth
                id="outlined-basic"
                label="Enter Your First Name"
                variant="outlined"
                name="fname"
                value={formData.fname}
                error={Boolean(errors.fname)}
                helperText={errors.fname}
                onChange={stateUpdater}
                onBlur={(e) => validationHandler(e, "alphabet")}
              />

              {/* Last Name */}
              <TextField
                sx={{ mt: 2, background: "white" }}
                fullWidth
                id="outlined-basic"
                label="Enter Your Last Name"
                variant="outlined"
                name="lname"
                value={formData.lname}
                required
                error={Boolean(errors.lname)}
                helperText={errors.lname}
                onChange={stateUpdater}
                onBlur={(e) => validationHandler(e, "alphabet")}
              />

              {/* Email */}
              <TextField
                sx={{ mt: 2, background: "white" }}
                fullWidth
                id="outlined-basic"
                label="Enter Your Email"
                variant="outlined"
                name="email"
                value={formData.email}
                required
                error={Boolean(errors.email)}
                helperText={errors.email}
                onChange={stateUpdater}
                onBlur={(e) => validationHandler(e, "email")}
              />

              {/* Phone */}
              <TextField
                sx={{ mt: 2, background: "white" }}
                fullWidth
                id="outlined-basic"
                label="Enter Your Phone no"
                variant="outlined"
                name="phone"
                value={formData.phone}
                error={Boolean(errors.phone)}
                helperText={errors.phone}
                onChange={stateUpdater}
                onBlur={(event: any) => validationHandler(event, "mobile")}
              />

              {/* Experience */}
              <TextField
                sx={{ mt: 2, background: "white" }}
                fullWidth
                id="outlined-basic"
                label="Enter Your Last Experience"
                variant="outlined"
                name="experience"
                value={formData.experience}
                error={Boolean(errors.experience)}
                helperText={errors.experience}
                onChange={stateUpdater}
                onBlur={(e) => validationHandler(e, "numeric")}
              />

              {/* State */}
              <Grid item xs={12}>
                <FormControl fullWidth sx={{ mt: 2 }}>
                  <InputLabel id="demo-simple-country-label">State</InputLabel>
                  <Select
                    required
                    labelId="demo-simple-country-label"
                    name="state"
                    className="form-control"
                    label="State"
                    value={formData.state}
                    onChange={stateUpdater}
                    fullWidth
                  >
                    <MenuItem>--Select State--</MenuItem>
                    {statesCity.map((state: any, index: number) => (
                      <MenuItem value={state.state_name} key={index}>
                        {state.state_name}
                      </MenuItem>
                    ))}
                  </Select>
                </FormControl>
              </Grid>

              {/* City */}
              <Grid item xs={12}>
                <FormControl fullWidth sx={{ mt: 2 }}>
                  <InputLabel id="demo-simple-country-label">City</InputLabel>
                  <Select
                    required
                    labelId="demo-simple-country-label"
                    name="city"
                    className="form-control"
                    label="State"
                    value={formData.city}
                    onChange={stateUpdater}
                    fullWidth
                  >
                    <MenuItem>--Select City--</MenuItem>
                    {formData.state &&
                      statesCity
                        .find(
                          (state: any) => state.state_name === formData.state
                        )
                        ?.cities.map((city: any, index: any) => (
                          <MenuItem value={city} key={index}>
                            {city}
                          </MenuItem>
                        ))}
                  </Select>
                </FormControl>
              </Grid>

              {/* Qualification */}
              <TextField
                sx={{ my: 2, background: "white" }}
                fullWidth
                id="outlined-basic"
                label="Enter Your Last Qualification"
                variant="outlined"
                name="qualification"
                value={formData.qualification}
                error={Boolean(errors.qualification)}
                helperText={errors.qualification}
                onChange={stateUpdater}
                onBlur={(e) => validationHandler(e, "alphabetsAndSpace")}
              />

              {/* Passing Year */}
              <LocalizationProvider dateAdapter={AdapterDayjs}>
                <DatePicker
                  key={resetDatePickerKey}
                  views={["year"]}
                  value={formData.passingYear || null}
                  onChange={handlePassingYear}
                  label="Passing Year"
                  slotProps={{ textField: { fullWidth: true } }}
                  sx={{ background: "white" }}
                />
              </LocalizationProvider>

              {/* Reference By */}
              <FormControl fullWidth sx={{ mt: 2 }}>
                <InputLabel id="demo-simple-select-label">
                  Reference By
                </InputLabel>
                <Select
                  labelId="demo-simple-select-label"
                  label="Reference By"
                  name="refBy"
                  value={formData.refBy}
                  onChange={(e: any) => {
                    stateUpdater(e);
                    setShow(e.target.value);
                  }}
                >
                  {referenceBy.map((name: string, index: number) => (
                    <MenuItem value={name} key={index}>
                      {name}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>

              {/* if reference by selected center head then display center head list */}
              {show === "Center Head" && (
                <>
                  <FormControl fullWidth sx={{ mt: 2 }}>
                    <InputLabel id="demo-simple-select-label">
                      Center Head
                    </InputLabel>
                    <Select
                      labelId="demo-simple-select-label"
                      label="Reference By"
                      name="refName"
                      value={formData.refName}
                      onChange={stateUpdater}
                    >
                      {centerHeadList?.map((Object: any, index: number) => (
                        <MenuItem
                          value={
                            Object.firstName +
                            " " +
                            Object.lastName +
                            " (" +
                            Object.empId +
                            ")"
                          }
                          key={index}
                        >
                          {Object.firstName} {Object.lastName} ({Object.empId})
                        </MenuItem>
                      ))}
                    </Select>
                  </FormControl>
                </>
              )}

              {/* if reference by selected Team Lead then display Team Lead list */}
              {show === "Team Lead" && (
                <>
                  <FormControl fullWidth sx={{ mt: 2 }}>
                    <InputLabel id="demo-simple-select-label">
                      Team Lead
                    </InputLabel>
                    <Select
                      labelId="demo-simple-select-label"
                      label="Reference By"
                      name="refName"
                      value={formData.refName}
                      onChange={stateUpdater}
                    >
                      {teamLeadList?.map((Object: any, index: number) => (
                        <MenuItem
                          value={
                            Object.firstName +
                            " " +
                            Object.lastName +
                            " (" +
                            Object.empId +
                            ")"
                          }
                        >
                          {Object.firstName} {Object.lastName} ({Object.empId})
                        </MenuItem>
                      ))}
                    </Select>
                  </FormControl>
                </>
              )}

              {/* if reference by selected Executive then display Executive list */}
              {show === "Executive" && (
                <>
                  <FormControl fullWidth sx={{ mt: 2 }}>
                    <InputLabel id="demo-simple-select-label">
                      Executive
                    </InputLabel>
                    <Select
                      labelId="demo-simple-select-label"
                      label="Reference By"
                      name="refName"
                      value={formData.refName}
                      onChange={stateUpdater}
                    >
                      {executiveList?.map((Object: any, index: number) => (
                        <MenuItem
                          value={
                            Object.firstName +
                            " " +
                            Object.lastName +
                            " (" +
                            Object.empId +
                            ")"
                          }
                          key={index}
                        >
                          {Object.firstName} {Object.lastName} ({Object.empId})
                        </MenuItem>
                      ))}
                    </Select>
                  </FormControl>
                </>
              )}

              {/* if reference by selected other then display textfield */}
              {show === "Other" && (
                <>
                  <TextField
                    sx={{ mt: 2, background: "white" }}
                    fullWidth
                    id="outlined-basic"
                    label="Student Name, Google, Just Dial, facebook..etc"
                    variant="outlined"
                    name="refName"
                    value={formData.refName}
                    error={Boolean(errors.refName)}
                    helperText={errors.refName}
                    onChange={stateUpdater}
                    onBlur={(e) => validationHandler(e, "alphabetsAndSpace")}
                  />
                </>
              )}
              <Button
                variant="contained"
                type="submit"
                sx={{ mt: 2, px: 4 }}
                onClick={() => {
                  window.scrollTo(0, 0);
                }}
              >
                Submit
              </Button>
              <CommonSnackBar message={snackMessage} open={openSnackBar} />
            </Grid>
          </Grid>
        </Box>
      </Paper>
    </Container>
  );
}
