import AccessTimeIcon from "@mui/icons-material/AccessTime";
import LocationOnIcon from "@mui/icons-material/LocationOn";
import MailIcon from "@mui/icons-material/Mail";
import PhoneIcon from "@mui/icons-material/Phone";
import { Typography } from "@mui/material";
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import CssBaseline from "@mui/material/CssBaseline";
import Grid from "@mui/material/Grid";
import TextField from "@mui/material/TextField";
import { ThemeProvider, createTheme } from "@mui/material/styles";
import { Container } from "@mui/system";
import { ChangeEvent, FocusEvent, FormEvent, useState } from "react";
import CommonSnackBar from "../../Components/Common/CommonSnackbar";
import useValidation from "../../Hooks/useTypescriptValidation";
import { postRequestMethod } from "../../api/api";
import { CONTACT_US } from "../../api/server";
const theme = createTheme();

export default function ContactUs() {
  let { eventHandler } = useValidation();

  const [data, setData] = useState({
    name: "",
    email: "",
    contact: "",
    message: "",
  });

  const [errors, setErrors] = useState({
    name: "",
    email: "",
    contact: "",
    message: "",
  });

  const [snackMessage, setSnackMessage] = useState<string>("");
  const [openSnackBar, setOpenSnackBar] = useState<boolean>(false);

  const handleOpenSnackBar = () => {
    setOpenSnackBar(true);
    setTimeout(() => {
      setOpenSnackBar(false);
    }, 3000);
  };

  const handleSubmit = async (event: FormEvent<HTMLFormElement>) => {
    event.preventDefault();
    if (validator()) {
      try {
        const response = await postRequestMethod(CONTACT_US, "", data);
        response
          ? setSnackMessage("Thank You for Contact Us...!")
          : setSnackMessage("Data not saved!");
        handleOpenSnackBar();
        setData({
          name: "",
          email: "",
          contact: "",
          message: "",
        });
      } catch (error) {
        console.error("Error submitting form:", error);
      }
    }
  };

  // to check fields are filled or not
  const validator = (): boolean => {
    for (let field in errors) {
      if (errors[field as keyof typeof errors] !== "") {
        return false;
      }
    }
    return true;
  };

  const validationHandler = async (
    e: ChangeEvent<HTMLInputElement>,
    alterName?: string
  ) => {
    const val = e.target.value;
    const id = alterName;
    if (id) {
      let prom = await new Promise((resolve) => {
        if (true) {
          resolve(eventHandler(id, val));
        }
      });
      setErrors({ ...errors, [e.target.name]: prom });
    }
  };

  const handleUserInput = (e: ChangeEvent<HTMLInputElement>) => {
    setData({ ...data, [e.target.name]: e.target.value });
  };

  return (
    <>
      <Container
        maxWidth="lg"
        sx={{ mt: { xs: "30%", sm: "20%", md: "12%", lg: "7%" }, mb: 5 }}
      >
        <Typography
          style={{
            textAlign: "center",
            fontWeight: 900,
            fontSize: 30,
            color: "#191970",
          }}
        >
          LET'S CONNECT
        </Typography>
        <Grid container spacing={3} rowSpacing={2} sx={{ mt: 2 }}>
          <Grid
            item
            xs={12}
            sm={6}
            md={3}
            sx={{ display: "flex", flexDirection: "column" }}
          >
            <Grid sx={{ mb: 2, textAlign: "center" }}>
              <LocationOnIcon sx={{ fontSize: "2rem" }} />
              <Typography style={{ fontWeight: 600 }}>
                OUR MAIN OFFICE
              </Typography>
            </Grid>
            <Typography sx={{ textAlign: "center" }}>
              Plot No. 383, 301 Kishori Kunj, Near Karnataka School, Kanadia
              Road, Alok Nagar Indore, Madhya Pradesh - 452016
            </Typography>
          </Grid>
          <Grid
            item
            xs={12}
            sm={6}
            md={3}
            sx={{ display: "flex", flexDirection: "column" }}
          >
            <Grid sx={{ mb: 2, textAlign: "center" }}>
              <PhoneIcon sx={{ fontSize: "2rem" }} />
              <Typography style={{ fontWeight: 600 }}>PHONE NUMBER</Typography>
            </Grid>
            <Typography sx={{ textAlign: "center" }}>
              Mo:- 8358899900<br></br>Ph:- 0731-4298599<br></br>Toll free:-
              1800120-224466
            </Typography>
          </Grid>
          <Grid
            item
            xs={12}
            sm={6}
            md={3}
            sx={{ display: "flex", flexDirection: "column" }}
          >
            <Grid sx={{ mb: 2, textAlign: "center" }}>
              <AccessTimeIcon sx={{ fontSize: "2rem" }} />
              <Typography style={{ fontWeight: 600 }}>TIME</Typography>
            </Grid>
            <Typography sx={{ textAlign: "center" }}>
              Monday To Saturday<br></br>10.30 am - 7.00 pm
            </Typography>
          </Grid>
          <Grid
            item
            xs={12}
            sm={6}
            md={3}
            sx={{ display: "flex", flexDirection: "column" }}
          >
            <Grid sx={{ mb: 2, textAlign: "center" }}>
              <MailIcon sx={{ fontSize: "2rem" }} />
              <Typography style={{ fontWeight: 600 }}>MAIL</Typography>
            </Grid>
            <Typography sx={{ textAlign: "center" }}>
              Info@prernaacademy.in
            </Typography>
          </Grid>

          <Grid item xs={12} sm={12} md={6}>
            <Box
              component="img"
              sx={{
                height: "100%",
                width: "100%",
              }}
              src="https://ik.imagekit.io/nwssoft/Contact_Us/Lady_writing.png?updatedAt=1680242992250"
              alt="ContactImage"
            />
          </Grid>

          <Grid item xs={12} sm={12} md={6}>
            <ThemeProvider theme={theme}>
              <Container component="main" maxWidth="xs">
                <CssBaseline />
                <Box
                  sx={{
                    marginTop: 3,
                    display: "flex",
                    flexDirection: "column",
                    alignItems: "center",
                  }}
                >
                  <Typography
                    component="h1"
                    variant="h5"
                    style={{ textAlign: "center" }}
                  >
                    GET A FREE CASE EVALUATION TODAY!
                  </Typography>
                  <Typography>Available 24 hours day</Typography>
                  <Box component="form" onSubmit={handleSubmit} sx={{ mt: 1 }}>
                    <TextField
                      required
                      fullWidth
                      margin="normal"
                      id="name"
                      label="Name"
                      name="name"
                      value={data.name}
                      error={Boolean(errors.name)}
                      helperText={errors.name}
                      onChange={handleUserInput}
                      onBlur={(e: FocusEvent<HTMLInputElement>) =>
                        validationHandler(e, "alphabetsAndSpace")
                      }
                    />
                    <TextField
                      required
                      fullWidth
                      margin="normal"
                      id="email"
                      label="Email"
                      name="email"
                      value={data.email}
                      error={Boolean(errors.email)}
                      helperText={errors.email}
                      onChange={handleUserInput}
                      onBlur={(e: FocusEvent<HTMLInputElement>) =>
                        validationHandler(e, "email")
                      }
                    />
                    <TextField
                      required
                      fullWidth
                      margin="normal"
                      id="technicalCall"
                      label="Phone no"
                      name="contact"
                      value={data.contact}
                      error={Boolean(errors.contact)}
                      helperText={errors.contact}
                      onChange={handleUserInput}
                      onBlur={(e: FocusEvent<HTMLInputElement>) =>
                        validationHandler(e, "mobile")
                      }
                    />
                    <TextField
                      fullWidth
                      margin="normal"
                      id="message"
                      label="Your Message"
                      name="message"
                      value={data.message}
                      minRows={3}
                      error={Boolean(errors.message)}
                      helperText={errors.message}
                      multiline
                      onChange={handleUserInput}
                      onBlur={(e: FocusEvent<HTMLInputElement>) =>
                        validationHandler(e, "message")
                      }
                    />
                    <Button
                      type="submit"
                      fullWidth
                      variant="contained"
                      sx={{ mt: 3, mb: 2 }}
                    >
                      Submit
                    </Button>
                    <CommonSnackBar
                      message={snackMessage}
                      open={openSnackBar}
                    />
                  </Box>
                </Box>
              </Container>
            </ThemeProvider>
          </Grid>
        </Grid>
      </Container>
    </>
  );
}
