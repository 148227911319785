import {
  Checkbox,
  FormControl,
  FormControlLabel,
  Grid,
  InputLabel,
  MenuItem,
  Select,
  TextField,
  Typography,
} from "@mui/material";
import Button from "@mui/material/Button";
import Paper from "@mui/material/Paper";
import { AxiosResponse } from "axios";
import { Fragment, useEffect, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import CommonSnackBar from "../../Components/Common/CommonSnackbar";
import { getRequest, updateRequest } from "../../api/api";
import { STUDENT_BY_ID, UPDATE_STUDENT_FEE } from "../../api/server";

export default function ManageUpdateFees(props: any) {
  const location = useLocation();
  const navigate = useNavigate();

  const [updateFees, setUpdateFees] = useState<any>();
  const [isChecked, setIsChecked] = useState<boolean>(false);
  const [snackMessage, setSnackMessage] = useState<string>("");
  const [openSnackBar, setOpenSnackBar] = useState<boolean>(false);

  const installments: number[] =
    updateFees?.mode === "Semester"
      ? [1, 2, 3, 4, 5, 6]
      : [1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12];

  const handleOpenSnackBar = () => {
    setOpenSnackBar(true);
    setTimeout(() => {
      setOpenSnackBar(false);
    }, 5000);
  };

  const handleCourseDetailsChange = (e: any) => {
    const { name, value } = e.target;
    setUpdateFees((prevData: any) => ({
      ...prevData,
      courseData: {
        ...prevData?.courseData,
        [name]: value,
      },
    }));
  };

  const handleUpdateFees = (e: any, index: number) => {
    const { name, value } = e.target;
    if (name === "numberOfInstallment") {
      setUpdateFees((prevData: any) => ({
        ...prevData,
        courseData: {
          ...prevData?.courseData,
          numberOfInstallment: value,
        },
      }));
    }
    const updatedInstallmentDetails = [
      ...updateFees.courseData.installmentDetails,
    ];
    updatedInstallmentDetails[index] = {
      ...updatedInstallmentDetails[index],
      [name]: value,
    };
    setUpdateFees((prevData: any) => ({
      ...prevData,
      courseData: {
        ...prevData?.courseData,
        installmentDetails: updatedInstallmentDetails,
      },
    }));
  };

  const updateData = async (e: any) => {
    e.preventDefault();
    try {
      const response = await updateRequest(
        UPDATE_STUDENT_FEE,
        "",
        { updateFees: updateFees, id: location?.state.id },
        ""
      );

      if (response) {
        response
          ? setSnackMessage("Data Updated Successfully..!")
          : setSnackMessage("Data not saved!");
        handleOpenSnackBar();
        setTimeout(() => {
          navigate("/dashboard/ManageStudent", { state: true });
        }, 3000);
      }
      console.log("updateData", response);
    } catch (error) {
      console.error("Error updating data:", error);
    }
  };

  useEffect(() => {
    async function fetchData() {
      try {
        let data = { studentId: location?.state.id };
        const res: void | AxiosResponse<any, any> = await getRequest(
          STUDENT_BY_ID,
          { params: data }
        );
        setUpdateFees(res?.data);
      } catch (error) {
        console.error("Error fetching data:", error);
      }
    }
    fetchData();
  }, []);

  useEffect(() => {
    function calculateInstallmentDetails() {
      if (
        updateFees?.courseData?.numberOfInstallment &&
        updateFees?.courseData?.installmentStartDate
      ) {
        const remainingInstallments = updateFees.courseData.numberOfInstallment;
        const remainingFees =
          updateFees?.courseData?.courseFees -
          updateFees?.courseData?.feesReceived;
        const installments: any[] = [];
        const installmentStartDate = new Date(
          updateFees.courseData.installmentStartDate
        );
        for (let i = 0; i < remainingInstallments; i++) {
          const futureDate = new Date(installmentStartDate);
          futureDate.setMonth(futureDate.getMonth() + i);

          const installmentAmount = Math.floor(
            remainingFees / remainingInstallments
          );
          installments.push({
            date: futureDate.toISOString().slice(0, 10),
            amount: installmentAmount,
          });
        }
        setUpdateFees((prevData: any) => ({
          ...prevData,
          courseData: {
            ...prevData?.courseData,
            installmentDetails: installments,
          },
        }));
      }
    }
    calculateInstallmentDetails();
  }, [
    updateFees?.courseData?.numberOfInstallment,
    updateFees?.courseData?.installmentStartDate,
    updateFees?.courseData?.courseFees,
    updateFees?.courseData?.feesReceived,
  ]);

  return (
    <>
      <Paper elevation={10} sx={{ m: "2%", p: "5%" }}>
        <Grid component="form" container spacing={2} onSubmit={updateData}>
          <Grid item xs={12} md={12}>
            <Typography
              variant="h6"
              align="center"
              color="#0288d1"
              textTransform="uppercase"
            >
              Update Fees
            </Typography>
          </Grid>

          <Grid item xs={12} md={6}>
            <FormControl fullWidth>
              <TextField
                variant="outlined"
                disabled
                InputLabelProps={{ shrink: true }}
                id="outlined-password-input"
                label="Course Type (Semester/ Yearly)"
                name="mode"
                type="text"
                sx={{ background: "white" }}
                value={updateFees?.courseData?.mode}
              />
            </FormControl>
          </Grid>

          <Grid item xs={12} md={6}>
            <FormControl fullWidth>
              <TextField
                variant="outlined"
                disabled
                InputLabelProps={{ shrink: true }}
                id="outlined-password-input"
                label="University Name"
                name="universityName"
                type="text"
                sx={{ background: "white" }}
                value={updateFees?.courseData?.universityName}
              />
            </FormControl>
          </Grid>

          <Grid item xs={12}>
            <FormControl fullWidth>
              <TextField
                variant="outlined"
                disabled
                InputLabelProps={{ shrink: true }}
                id="outlined-password-input"
                label="Course Name"
                name="courseName"
                type="text"
                sx={{ background: "white" }}
                value={updateFees?.courseData?.courseName}
              />
            </FormControl>
          </Grid>

          <Grid item xs={12} md={6}>
            <FormControl fullWidth>
              <TextField
                variant="outlined"
                disabled
                InputLabelProps={{ shrink: true }}
                id="outlined-password-input"
                label=" Admission Of (Semester or Year)"
                name="admissionSemesterYear"
                type="text"
                sx={{ background: "white" }}
                value={updateFees?.courseData?.admissionSemesterYear}
              />
            </FormControl>
          </Grid>

          <Grid item xs={12} md={6}>
            <FormControl fullWidth>
              <TextField
                variant="outlined"
                disabled
                InputLabelProps={{ shrink: true }}
                id="outlined-password-input"
                label=" Course Offered Year"
                name="noOfSemsOrYears"
                type="text"
                sx={{ background: "white" }}
                value={updateFees?.courseData?.noOfSemsOrYears}
              />
            </FormControl>
          </Grid>

          <Grid item xs={12} md={6}>
            <FormControl fullWidth>
              <TextField
                variant="outlined"
                disabled
                InputLabelProps={{ shrink: true }}
                id="outlined-month-input"
                label="Session From"
                type="month"
                autoComplete="current-date"
                name="sessionFrom"
                sx={{ background: "white" }}
                value={updateFees?.courseData?.sessionFrom}
                inputProps={{ min: new Date().toISOString().slice(0, 10) }}
              />
            </FormControl>
          </Grid>

          <Grid item xs={12} md={6}>
            <FormControl fullWidth>
              <TextField
                variant="outlined"
                disabled
                InputLabelProps={{ shrink: true }}
                id="outlined-month-input"
                label="Session To"
                type="month"
                autoComplete="current-date"
                sx={{ background: "white" }}
                value={updateFees?.courseData?.sessionTo}
                name="sessionTo"
              />
            </FormControl>
          </Grid>

          <Grid item xs={12} md={6}>
            <FormControl fullWidth>
              <TextField
                variant="outlined"
                required
                InputLabelProps={{ shrink: true }}
                id="outlined-password-input"
                label="Course Fees"
                name="courseFees"
                type="number"
                value={updateFees?.courseData?.courseFees}
                sx={{ background: "white" }}
                onChange={(e) => handleCourseDetailsChange(e)}
              />
            </FormControl>
          </Grid>

          <Grid item xs={12} md={6}>
            <FormControl fullWidth>
              <TextField
                variant="outlined"
                required
                InputLabelProps={{ shrink: true }}
                id="outlined-password-input"
                label="Fees Received"
                name="feesReceived"
                type="number"
                value={updateFees?.courseData?.feesReceived}
                sx={{ background: "white" }}
                onChange={(e) => handleCourseDetailsChange(e)}
              />
            </FormControl>
          </Grid>

          <Grid item xs={12}>
            <FormControlLabel
              control={
                <Checkbox
                  checked={isChecked}
                  onChange={(e) => setIsChecked(e.target.checked)}
                />
              }
              label="Installment"
            />
          </Grid>

          {isChecked && (
            <>
              <Grid item xs={12} sm={6}>
                <TextField
                  variant="outlined"
                  required
                  fullWidth
                  InputLabelProps={{ shrink: true }}
                  id="outlined-month-input"
                  label="Installment Start Date(MM/DD/YYYY)"
                  name="installmentStartDate"
                  type="date"
                  value={updateFees?.courseData?.installmentStartDate}
                  sx={{ background: "white" }}
                  onChange={(e) => handleCourseDetailsChange(e)}
                />
              </Grid>
              {console.log(
                "installmentStartDate",
                props?.detail?.installmentStartDate
              )}

              <Grid item xs={12} sm={6}>
                <FormControl fullWidth>
                  <InputLabel id="demo-simple-select-label">
                    Number Of Installments
                  </InputLabel>

                  <Select
                    required
                    labelId="demo-simple-select-label"
                    label="Number Of Installments"
                    name="numberOfInstallment"
                    value={updateFees?.courseData?.numberOfInstallment}
                    onChange={(e, index: any) => handleUpdateFees(e, index)}
                  >
                    {installments.map((item: number) => (
                      <MenuItem key={item} value={item}>
                        {item}
                      </MenuItem>
                    ))}
                  </Select>
                </FormControl>
              </Grid>

              {updateFees?.courseData?.installmentDetails.map(
                (item: any, index: number) => (
                  <Fragment key={index}>
                    <Grid item xs={12} sm={6}>
                      <TextField
                        variant="outlined"
                        fullWidth
                        InputLabelProps={{ shrink: true }}
                        id={`installmentDate-${index}`}
                        label="Date(MM/DD/YYYY)"
                        name="date"
                        type="date"
                        value={item.date}
                        sx={{ background: "white" }}
                        onChange={(e) => handleUpdateFees(e, index)}
                      />
                    </Grid>
                    <Grid item xs={12} sm={6}>
                      <TextField
                        variant="outlined"
                        label="Amount"
                        name="amount"
                        fullWidth
                        value={item.amount}
                        sx={{ background: "white" }}
                        onChange={(e) => handleUpdateFees(e, index)}
                      />
                    </Grid>
                  </Fragment>
                )
              )}
            </>
          )}

          <Grid item xs={12}>
            <Button
              variant="contained"
              type="submit"
              sx={{ mt: 3, mb: 2, px: 4 }}
            >
              Submit
            </Button>
          </Grid>
          <CommonSnackBar message={snackMessage} open={openSnackBar} />
        </Grid>
      </Paper>
    </>
  );
}
