import { useState } from "react";
import { Box, Dialog, DialogContent, Stack, Typography } from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import Tooltip from "@mui/material/Tooltip";
import QuestionAnswerIcon from "@mui/icons-material/QuestionAnswer";
import ChatBot from "./Chatbot";

const ChatbotIcon = () => {
  const [open, setOpen] = useState<boolean>(false);

  const handleOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  return (
    <>
      <Box
        sx={{ zIndex: 1 }}
        position="fixed"
        bottom={16}
        right={16}
        p={2}
        onClick={handleOpen}
        style={{ cursor: "pointer" }}
      >
        <Stack
          direction={"row"}
          spacing={2}
          justifyContent={"center"}
          alignItems={"center"}
        >
          {!open ? (
            <>
              <Box>
                <Tooltip
                  title={"Need help? chat with us!"}
                  placement="left-end"
                >
                  <QuestionAnswerIcon color="info" sx={{ fontSize: "60px" }} />
                </Tooltip>
              </Box>
            </>
          ) : (
            <CloseIcon sx={{ fontSize: "50px" }} onClick={handleClose} />
          )}
        </Stack>
      </Box>
      <Dialog open={open} onClose={handleClose}>
        <Stack
          direction={"row"}
          sx={{
            backgroundColor: "blue",
            p: "20px",
            color: "#fff",
          }}
        >
          <QuestionAnswerIcon sx={{ fontSize: "50px" }} />
          <Box>
            <Typography variant="h6">Start a Conversation</Typography>
            <Typography>
              Hi! Click one of our member below to chat on <b>Chatbot</b>
            </Typography>
          </Box>
        </Stack>
        <DialogContent
          sx={{
            "&::-webkit-scrollbar": {
              width: 10,
              height: 8,
            },
            "&::-webkit-scrollbar-track": {
              backgroundColor: " #90caf9",
            },
            "&::-webkit-scrollbar-thumb": {
              backgroundColor: "#0288d1",
              borderRadius: 2,
            },
          }}
        >
          <Stack
            direction={"column"}
            sx={{ backgroundColor: "white", p: "10px" }}
          >
            <ChatBot />
          </Stack>
        </DialogContent>
      </Dialog>
    </>
  );
};

export default ChatbotIcon;
