import {
  Container,
  Grid,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
} from "@mui/material";
import "../../Components/Home/Home.scss";
function createData(
  Class: string,
  Board: string,
  Year: string,
  Percentage: string,
  Marks: string,
  Grade: string
) {
  return { Class, Board, Year, Percentage, Marks, Grade };
}

export default function AcademicsPreview(props: any) {
  const rows = [
    createData(
      "10th",
      ` ${props.Data?.academicData?.sscBoardUniversity}`,
      ` ${props.Data?.academicData?.sscYear}`,
      ` ${props.Data?.academicData?.sscPercentageCGPA}`,
      ` ${props.Data?.academicData?.sscObtainedMaxTotalMax}`,
      ` ${props.Data?.academicData?.sscDivisionGrade}`
    ),
    props.Data?.academicData?.hscYear &&
    props.Data?.academicData?.hscBoardUniversity &&
    props.Data?.academicData?.hscPercentageCGPA &&
    props.Data?.academicData?.hscObtainedMaxTotalMax &&
    props.Data?.academicData?.hscDivisionGrade
      ? createData(
          "12th",
          ` ${props.Data?.academicData?.hscBoardUniversity}`,
          ` ${props.Data?.academicData?.hscYear}`,
          ` ${props.Data?.academicData?.hscPercentageCGPA}`,
          ` ${props.Data?.academicData?.hscObtainedMaxTotalMax}`,
          ` ${props.Data?.academicData?.hscDivisionGrade}`
        )
      : null,
    props.Data?.academicData?.graduationYear &&
    props.Data?.academicData?.graduationBoardUniversity &&
    props.Data?.academicData?.graduationPercentageCGPA &&
    props.Data?.academicData?.graduationObtainedMaxTotalMax &&
    props.Data?.academicData?.graduationDivisionGrade
      ? createData(
          "Graduation",
          ` ${props.Data?.academicData?.graduationBoardUniversity}`,
          ` ${props.Data?.academicData?.graduationYear}`,
          ` ${props.Data?.academicData?.graduationPercentageCGPA}`,
          ` ${props.Data?.academicData?.graduationObtainedMaxTotalMax}`,
          ` ${props.Data?.academicData?.graduationDivisionGrade}`
        )
      : null,
    props.Data?.academicData?.postGraduationYear &&
    props.Data?.academicData?.postGraduationBoardUniversity &&
    props.Data?.academicData?.postGraduationPercentageCGPA &&
    props.Data?.academicData?.postGraduationObtainedMaxTotalMax &&
    props.Data?.academicData?.postGraduationDivisionGrade
      ? createData(
          "PostGraduation",
          ` ${props.Data?.academicData?.postGraduationBoardUniversity}`,
          ` ${props.Data?.academicData?.postGraduationYear}`,
          ` ${props.Data?.academicData?.postGraduationPercentageCGPA}`,
          ` ${props.Data?.academicData?.postGraduationObtainedMaxTotalMax}`,
          ` ${props.Data?.academicData?.postGraduationDivisionGrade}`
        )
      : null,
    props.Data?.academicData?.itiYear &&
    props.Data?.academicData?.itiBoardUniversity &&
    props.Data?.academicData?.itiPercentageCGPA &&
    props.Data?.academicData?.itiObtainedMaxTotalMax &&
    props.Data?.academicData?.itiDivisionGrade
      ? createData(
          "Diploma/ITI",
          ` ${props.Data?.academicData?.itiBoardUniversity}`,
          ` ${props.Data?.academicData?.itiYear}`,
          ` ${props.Data?.academicData?.itiPercentageCGPA}`,
          ` ${props.Data?.academicData?.itiObtainedMaxTotalMax}`,
          ` ${props.Data?.academicData?.itiDivisionGrade}`
        )
      : null,
    props.Data?.academicData?.otherYear &&
    props.Data?.academicData?.otherBoardUniversity &&
    props.Data?.academicData?.otherPercentageCGPA &&
    props.Data?.academicData?.otherObtainedMaxTotalMax &&
    props.Data?.academicData?.otherDivisionGrade
      ? createData(
          "Other",
          ` ${props.Data?.academicData?.otherBoardUniversity}`,
          ` ${props.Data?.academicData?.otherYear}`,
          ` ${props.Data?.academicData?.otherPercentageCGPA}`,
          ` ${props.Data?.academicData?.otherObtainedMaxTotalMax}`,
          ` ${props.Data?.academicData?.otherDivisionGrade}`
        )
      : null,
  ].filter(Boolean);

  return (
    <Container sx={{ mt: 3 }}>
      <Paper sx={{ p: 2 }} elevation={0}>
        <TableContainer className="scrollBarCss">
          {/* Academic details */}
          <Grid item xs={12}>
            <Typography fontWeight="500">Academic Details:</Typography>
          </Grid>
          <Table
            sx={{ minWidth: 650, mt: 3, border: "1px solid grey" }}
            aria-label="simple table"
          >
            <TableHead>
              <TableRow sx={{ background: "#0288d1" }}>
                <TableCell sx={{ color: "white", border: "1px solid grey" }}>
                  Class
                </TableCell>
                <TableCell
                  sx={{ color: "white", border: "1px solid grey" }}
                  align="center"
                >
                  Board/University
                </TableCell>
                <TableCell
                  sx={{ color: "white", border: "1px solid grey" }}
                  align="center"
                >
                  Passing Year
                </TableCell>
                <TableCell
                  sx={{ color: "white", border: "1px solid grey" }}
                  align="center"
                >
                  Percentage/CGPA
                </TableCell>
                <TableCell
                  sx={{ color: "white", border: "1px solid grey" }}
                  align="center"
                >
                  Obtained Marks
                </TableCell>
                <TableCell
                  sx={{ color: "white", border: "1px solid grey" }}
                  align="center"
                >
                  Grade
                </TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {rows.map(
                (row) =>
                  row && ( // Check if row is not null
                    <TableRow key={row.Class}>
                      <TableCell
                        component="th"
                        scope="row"
                        sx={{ border: "1px solid grey", textAlign: "center" }}
                      >
                        {row.Class}
                      </TableCell>
                      <TableCell
                        align="right"
                        sx={{ border: "1px solid grey", textAlign: "center" }}
                      >
                        {row.Board}
                      </TableCell>
                      <TableCell
                        align="right"
                        sx={{ border: "1px solid grey", textAlign: "center" }}
                      >
                        {row.Year}
                      </TableCell>
                      <TableCell
                        align="right"
                        sx={{ border: "1px solid grey", textAlign: "center" }}
                      >
                        {row.Percentage}
                      </TableCell>
                      <TableCell
                        align="right"
                        sx={{ border: "1px solid grey", textAlign: "center" }}
                      >
                        {row.Marks}
                      </TableCell>
                      <TableCell
                        align="right"
                        sx={{ border: "1px solid grey", textAlign: "center" }}
                      >
                        {row.Grade}
                      </TableCell>
                    </TableRow>
                  )
              )}
            </TableBody>
          </Table>
        </TableContainer>
      </Paper>
    </Container>
  );
}
