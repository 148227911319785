import { useState, useEffect } from "react";
import * as FileSaver from "file-saver";
import * as XLSX from "xlsx";
import { Button } from "@mui/material";
import { getRequest } from "../../api/api";
import { REJECTED_LEADS } from "../../api/server";
import CommonSnackBar from "../../Components/Common/CommonSnackbar";

export default function RejectedLead(props: any) {
  const [userData, setUserData] = useState<[]>([]);
  const [snackMessage, setSnackMessage] = useState<string>("");
  const [openSnackBar, setOpenSnackBar] = useState<boolean>(false);

  const handleOpenSnackBar = (message: string) => {
    try {
      setSnackMessage(message);
      setOpenSnackBar(true);
    } catch (error) {
      console.error("Error in handleOpenSnackBar:", error);
    }
  };

  const handleCloseSnackBar = () => {
    try {
      setOpenSnackBar(false);
    } catch (error) {
      console.error("Error in handleCloseSnackBar:", error);
    }
  };

  useEffect(() => {
    fetchData();
  }, []);

  const fetchData = () => {
    getRequest(REJECTED_LEADS, "")
      .then((response: any) => {
        let finalData: any = [];
        response.data?.forEach((element: any) => {
          let object = {
            FirstName: element.fname,
            LastName: element.lname,
            Email: element.email,
            Phone: element.phone,
            Experience: element.experience,
            State: element.state,
            City: element.city,
            Qualification: element.qualification,
            PassingYear: element.passingYear,
            RefBy: element.refBy,
            RefName: element.refName,
          };
          finalData.push(object);
        });
        setUserData(finalData);
      })
      .catch((error: any) => {
        console.error("Error fetching data:", error);
      });
  };

  const exportToCSV = () => {
    if(userData.length <= 0){
      handleOpenSnackBar("No Rejected Leads!");
      return;
    }
    const fileType =
      "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8";
    const fileExtension = ".xlsx";
    const ws = XLSX.utils.json_to_sheet(userData);
    const wb: XLSX.WorkBook = { Sheets: { data: ws }, SheetNames: ["data"] };
    const excelBuffer: any = XLSX.write(wb, {
      bookType: "xlsx",
      type: "array",
    });
    const data: Blob = new Blob([excelBuffer], { type: fileType });
    FileSaver.saveAs(data, `Rejected_Leads_Detail${fileExtension}`);
  };

  return (
    <>
      <Button variant="contained" onClick={exportToCSV}>
        Download Excel File
      </Button>
      <CommonSnackBar
        open={openSnackBar}
        autoHideDuration={3000}
        onClose={handleCloseSnackBar}
        message={snackMessage}
      />
    </>
  );
}
