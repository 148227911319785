import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Avatar,
  Box,
  Button,
  Container,
  Grid,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableRow,
  Typography,
} from "@mui/material";
import { AxiosResponse } from "axios";
import { Fragment, useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import NoDataFound from "../../Components/Common/EmptyDataMessage";
import "../../Components/Home/Home.scss";
import { getRequestById } from "../../api/api";
import { GET_ACTIVITY } from "../../api/server";
import Activity from "./Activity";

export default function ViewActivity() {
  const { leadId } = useParams();
  let activityFormShow = 0;

  const [activity, setActivity] = useState([]);
  const [noData, setNoData] = useState(true);

  const getTableData = async () => {
    let result: void | AxiosResponse<any, any> = await getRequestById(
      GET_ACTIVITY,
      leadId,
      ""
    );
    if (result?.data.length > 0) {
      setActivity(result?.data?.reverse());
      setNoData(false);
    }
  };

  useEffect(() => {
    getTableData();
  }, []);

  return (
    <>
      {noData === true ? (
        <>
          <NoDataFound />
        </>
      ) : (
        <>
          <Container maxWidth="lg">
            <Grid container>
              {activity.map((item: any, index: any) => {
                activityFormShow++;
                if (activityFormShow <= 1) {
                  return (
                    <Fragment key={index}>
                      <Grid item xs={12}>
                        <Activity item={item} />
                      </Grid>
                    </Fragment>
                  );
                } else {
                  return (
                    <Fragment key={index}>
                      <Grid item xs={12} md={6} key={index}>
                        <Box sx={{ margin: 2 }}>
                          <Accordion>
                            <AccordionSummary expandIcon={<ExpandMoreIcon />}>
                              <Typography variant="subtitle1" fontWeight="bold">
                                {`${activity.length - index}. `}Activity{" :"}
                              </Typography>
                              <Typography variant="subtitle1">
                                {`- ${item.activity}`}
                              </Typography>
                            </AccordionSummary>
                            <AccordionDetails>
                              <TableContainer className="scrollBarCss">
                                <Table>
                                  <TableBody>
                                    {item.file && (
                                      <>
                                        <TableRow>
                                          <TableCell>
                                            <Typography
                                              variant="subtitle1"
                                              fontWeight="bold"
                                            >
                                              Upload-Received
                                            </Typography>
                                          </TableCell>
                                          <TableCell>
                                            <Avatar
                                              alt={item.file?.name}
                                              src={item.file?.path}
                                              sx={{
                                                mb: 2,
                                                width: "80%",
                                                height: "80%",
                                                borderRadius: 0,
                                              }}
                                            />
                                            <Button href={item.file?.path}>
                                              Download
                                            </Button>
                                          </TableCell>
                                        </TableRow>
                                      </>
                                    )}
                                    <TableRow>
                                      <TableCell>
                                        <Typography
                                          variant="subtitle1"
                                          fontWeight="bold"
                                        >
                                          Follow-Up Date
                                        </Typography>
                                      </TableCell>
                                      <TableCell>
                                        <Typography variant="subtitle1">
                                          {item.followUpDate}
                                        </Typography>
                                      </TableCell>
                                    </TableRow>
                                    <TableRow>
                                      <TableCell>
                                        <Typography
                                          variant="subtitle1"
                                          fontWeight="bold"
                                        >
                                          Follow-Up Time
                                        </Typography>
                                      </TableCell>
                                      <TableCell>
                                        <Typography variant="subtitle1">
                                          {item.followUpTime}
                                        </Typography>
                                      </TableCell>
                                    </TableRow>
                                    <TableRow>
                                      <TableCell>
                                        <Typography
                                          variant="subtitle1"
                                          fontWeight="bold"
                                        >
                                          Outcome
                                        </Typography>
                                      </TableCell>
                                      <TableCell>
                                        <Typography variant="subtitle1">
                                          {item.outcome}
                                        </Typography>
                                      </TableCell>
                                    </TableRow>
                                    <TableRow>
                                      <TableCell>
                                        <Typography
                                          variant="subtitle1"
                                          fontWeight="bold"
                                        >
                                          Subject
                                        </Typography>
                                      </TableCell>
                                      <TableCell>
                                        <Typography variant="subtitle1">
                                          {item.subject}
                                        </Typography>
                                      </TableCell>
                                    </TableRow>
                                    <TableRow>
                                      <TableCell>
                                        <Typography
                                          variant="subtitle1"
                                          fontWeight="bold"
                                        >
                                          Schedule Date
                                        </Typography>
                                      </TableCell>
                                      <TableCell>
                                        <Typography variant="subtitle1">
                                          {item.scheduleDate}
                                        </Typography>
                                      </TableCell>
                                    </TableRow>
                                    <TableRow>
                                      <TableCell>
                                        <Typography
                                          variant="subtitle1"
                                          fontWeight="bold"
                                        >
                                          Schedule Time
                                        </Typography>
                                      </TableCell>
                                      <TableCell>
                                        <Typography variant="subtitle1">
                                          {item.scheduleTime}
                                        </Typography>
                                      </TableCell>
                                    </TableRow>
                                    <TableRow>
                                      <TableCell>
                                        <Typography
                                          variant="subtitle1"
                                          fontWeight="bold"
                                        >
                                          Task Description
                                        </Typography>
                                      </TableCell>
                                      <TableCell>
                                        <Typography variant="subtitle1">
                                          {item.taskDescription}
                                        </Typography>
                                      </TableCell>
                                    </TableRow>
                                  </TableBody>
                                </Table>
                              </TableContainer>
                            </AccordionDetails>
                          </Accordion>
                        </Box>
                      </Grid>
                    </Fragment>
                  );
                }
              })}
            </Grid>
          </Container>
        </>
      )}
    </>
  );
}
