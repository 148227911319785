import {
  Button,
  Container,
  FormControl,
  Grid,
  InputLabel,
  LinearProgress,
  MenuItem,
  Paper,
  Select,
  TextField,
  Typography,
} from "@mui/material/";
import { AxiosResponse } from "axios";
import { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import CommonSnackBar from "../Components/Common/CommonSnackbar";
import stateCity from "../Components/Common/stateCity.json";
import useValidation from "../Hooks/useTypescriptValidation";
import { getRequestByParams, updateRequest } from "../api/api";
import { GET_PROFILE, UPDATE_PROFILE } from "../api/server";

interface ProfileData {
  firstName: string;
  lastName: string;
  emailId: string;
  phoneNumber: string;
  state: string;
  city: string;
  zipCode: string;
  address: string;
}

export default function Profile() {
  const { eventHandler } = useValidation();
  const { id, type } = useParams();
  const navigate = useNavigate();

  const [statesCity] = useState<any>(stateCity);
  const [previewImage, setPreviewImage] = useState<string | null>(null);
  const [openSnackBar, setOpenSnackBar] = useState<boolean>(false);
  const [photo, setPhoto] = useState<File | undefined>(undefined);
  const [snackMessage, setSnackMessage] = useState<string>("");
  const [isLoading, setIsLoading] = useState<boolean>(false);

  const [data, setData] = useState<ProfileData>({
    firstName: "",
    lastName: "",
    emailId: "",
    phoneNumber: "",
    state: "",
    city: "",
    zipCode: "",
    address: "",
  });

  const [errors, setErrors] = useState<{ [key: string]: string }>({
    firstName: "",
    lastName: "",
    emailId: "",
    phoneNumber: "",
    zipCode: "",
    address: "",
    photo: "",
  });

  const getProfileData = async () => {
    let response: void | AxiosResponse<any, any> = await getRequestByParams(
      GET_PROFILE,
      "",
      "",
      {
        type: type,
        lId: id,
      }
    );

    if (type === "Student" && response.data) {
      setData({
        firstName: response?.data.studentData.firstName,
        lastName: response?.data.studentData.lastName,
        emailId: response?.data.studentData.email,
        phoneNumber: response?.data.studentData.contactNumber,
        state: response?.data.studentData.presentState,
        city: response?.data.studentData.presentCity,
        zipCode: response?.data.studentData.presentZipCode,
        address: response?.data.studentData.presentAddress1,
      });
      setPreviewImage(response?.data.studentData.image.path);
    }
    if (type !== "Student" && response.data) {
      setData({
        firstName: response?.data.firstName,
        lastName: response?.data.lastName,
        emailId: response?.data.email,
        phoneNumber: response?.data.phone,
        state: response?.data.state,
        city: response?.data.city,
        zipCode: response?.data.zipCode,
        address: response?.data.address,
      });
      setPreviewImage(response?.data.image.path);
    }
  };

  const validationHandler = async (e: any, alterName?: any) => {
    const id = alterName;
    let val: File | string;

    if (e.target.name === "photo") {
      const file = e.target.files?.[0];
      file && setPreviewImage(URL.createObjectURL(file));
      file && setPhoto(file);
      val = e.target.files[0];
    } else {
      val = e.target.value;
    }
    if (id) {
      let prom = new Promise((resolve) => {
        if (true) {
          resolve(eventHandler(id, val));
        }
      });
      prom.then((res) => setErrors({ ...errors, [e.target.name]: res }));
    }
    setData({ ...data, [e.target.name]: val });
  };

  const handleStateCityChange = (e: any) => {
    setData({ ...data, [e.target.name]: e.target.value });
  };

  const handleOpenSnackBar = () => {
    setOpenSnackBar(true);
    setTimeout(() => {
      setOpenSnackBar(false);
    }, 5000);
  };

  const validator = (): boolean => {
    for (let field in errors) {
      if (errors[field] !== "") {
        return true;
      }
    }
    return false;
  };

  function handleSubmit(event: any) {
    event.preventDefault();

    if (validator()) {
      setSnackMessage("Clear Errors!");
      handleOpenSnackBar();
      return;
    }

    const form = new FormData();

    form.append("data", JSON.stringify({ ...data, type: type, lId: id }));
    photo && form.append("photo", photo);

    setIsLoading(true);
    const response = updateRequest(
      UPDATE_PROFILE,
      id,
      form,
      "multipart/form-data"
    );
    response
      .then((res) => {
        setIsLoading(false);
        if (res) {
          setSnackMessage("Profile updated Successfully");
          handleOpenSnackBar();
        } else {
          setSnackMessage("Something went wrong");
          handleOpenSnackBar();
        }
      })
      .catch((err) => console.error(err));
  }

  const handleCancel = () => {
    navigate("/dashboard");
  };

  useEffect(() => {
    getProfileData();
  }, []);

  return (
    <Container component="main" maxWidth="xl">
      {isLoading && <LinearProgress />}
      <Paper
        elevation={20}
        sx={{ p: 3 }}
        component="form"
        onSubmit={handleSubmit}
        encType="multipart/form-data"
      >
        <Grid container spacing={2}>
          <Grid item xs={12}>
            <Typography
              variant="h6"
              align="center"
              fontWeight="bold"
              color="#0288d1"
            >
              EDIT PROFILE
            </Typography>
          </Grid>
          <Grid item xs={12}>
            <TextField
              id="outlined-basic"
              label="First Name"
              type="text"
              name="firstName"
              fullWidth
              value={data.firstName}
              error={Boolean(errors.firstName)}
              helperText={errors.firstName}
              onChange={(e) => validationHandler(e, "alphabet")}
              required
            />
          </Grid>

          <Grid item xs={12}>
            <TextField
              id="filled-password-input"
              label="Last Name"
              type="text"
              required
              name="lastName"
              value={data.lastName}
              fullWidth
              error={Boolean(errors.lastName)}
              helperText={errors.lastName}
              onChange={(e) => validationHandler(e, "alphabet")}
            />
          </Grid>

          <Grid item xs={12}>
            <TextField
              id="filled-password-input"
              label="Email Id"
              type="text"
              required
              name="emailId"
              value={data.emailId}
              error={Boolean(errors.emailId)}
              helperText={errors.emailId}
              fullWidth
              onChange={(e) => validationHandler(e, "email")}
            />
          </Grid>

          <Grid item xs={12}>
            <TextField
              id="filled-password-input"
              label="Phone Number"
              type="text"
              required
              name="phoneNumber"
              value={data.phoneNumber}
              fullWidth
              error={Boolean(errors.phoneNumber)}
              helperText={errors.phoneNumber}
              onChange={(e) => validationHandler(e, "mobile")}
            />
          </Grid>

          <Grid item xs={12}>
            <FormControl fullWidth>
              <InputLabel id="demo-simple-country-label">State</InputLabel>
              <Select
                labelId="demo-simple-country-label"
                required
                fullWidth
                name="state"
                label="State"
                className="form-control"
                value={data.state}
                onChange={handleStateCityChange}
              >
                <MenuItem>--Select State--</MenuItem>
                {statesCity.map((state: any) => (
                  <MenuItem
                    value={state.state_name}
                    key={state.state_name}
                    id={state.state_id}
                  >
                    {state.state_name}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
          </Grid>
          <Grid item xs={12}>
            <FormControl fullWidth>
              <InputLabel id="demo-simple-country-label">City</InputLabel>
              <Select
                required
                labelId="demo-simple-country-label"
                name="city"
                className="form-control"
                label="State"
                value={data.city}
                onChange={handleStateCityChange}
                fullWidth
              >
                <MenuItem>--Select City--</MenuItem>
                {data.state &&
                  statesCity
                    .find((state: any) => state.state_name === data.state)
                    ?.cities.map((city: any, index: any) => (
                      <MenuItem value={city} key={index} id={city.state_id}>
                        {city}
                      </MenuItem>
                    ))}
              </Select>
            </FormControl>
          </Grid>

          <Grid item xs={12}>
            <TextField
              id="filled-password-input"
              label="Zip Code"
              type="number"
              required
              name="zipCode"
              value={data.zipCode}
              fullWidth
              error={Boolean(errors.zipCode)}
              helperText={errors.zipCode}
              onChange={(e) => validationHandler(e, "zipcode")}
            />
          </Grid>

          <Grid item xs={12}>
            <TextField
              required
              id="filled-multiline-static"
              label="Address"
              name="address"
              value={data.address}
              multiline
              rows={4}
              fullWidth
              error={Boolean(errors.address)}
              helperText={errors.address}
              onChange={(e) => validationHandler(e, "address")}
            />
          </Grid>

          <Grid item xs={12}>
            {previewImage && (
              <img
                src={previewImage}
                alt="Preview"
                style={{ height: 100, marginBottom: 10, width: "auto" }}
              />
            )}
            <TextField
              type="file"
              label="Upload Image"
              size="medium"
              name="photo"
              fullWidth
              error={Boolean(errors.photo)}
              helperText={errors.photo}
              onChange={(e) => validationHandler(e, "imageSize")}
              InputLabelProps={{ shrink: true }}
              inputProps={{ accept: "image/*" }}
            />
          </Grid>
          <Grid item xs={6} display="flex" justifyContent="flex-end">
            <Button type="submit" variant="contained">
              Submit
            </Button>
          </Grid>
          <Grid item xs={6}>
            <Button type="button" variant="contained" onClick={handleCancel}>
              Cancel
            </Button>
          </Grid>
        </Grid>
        <CommonSnackBar message={snackMessage} open={openSnackBar} />
      </Paper>
    </Container>
  );
}
