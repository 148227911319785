import React, { useState, useEffect } from "react";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableRow from "@mui/material/TableRow";
import Paper from "@mui/material/Paper";
import {
  Button,
  Checkbox,
  Collapse,
  Container,
  FormControl,
  Grid,
  IconButton,
  InputAdornment,
  InputLabel,
  LinearProgress,
  MenuItem,
  Select,
  TableHead,
  TablePagination,
  TextField,
  Typography,
} from "@mui/material";
import "./ManageUnAssignLead.scss";
import SearchIcon from "@mui/icons-material/Search";
import CommonSnackBar from "../../Components/Common/CommonSnackbar";
import { getRequestById, updateRequest } from "../../api/api";
import {
  GET_EX_LIST_BY_TL_ID,
  GET_TL_LED,
  PUT_TL_ASSIGN_LEAD,
} from "../../api/server";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import "../../Components/Home/Home.scss";

// Define form fields and table column name
const columns: any = [
  { id: 1, field: "fname", headerName: "First Name" },
  { id: 2, field: "lname", headerName: "Last Name" },
  { id: 3, field: "email", headerName: "Email" },
  { id: 4, field: "phone", headerName: "Phone" },
  { id: 5, field: "refBy", headerName: "Reference By" },
  { id: 6, field: "refName", headerName: "Reference Name" },
];

export default function ManageAssignedLeadTL(props: any) {
  //state to manage searched data
  const [searchApiData, setSearchApiData] = useState<any[]>([]);

  // for executive list
  const [executiveList, setExecutiveList] = useState<any[]>([]);

  //for clear selected executive after assign
  const [selectedExecutive, setSelectedExecutive] = useState<string>("");

  // for pagination
  const [page, setPage] = React.useState(0);
  const [rowsPerPage, setRowsPerPage] = React.useState(10);

  //state to manage search value
  const [filterVal, setFilterVal] = useState<string>("");

  //for progress bar
  const [isLoading] = useState(false);

  const [snackMessage, setSnackMessage] = useState<string>("");

  //for displaying snackbar message
  const [openSnackBar, setOpenSnackBar] = useState(false);

  //for lead list
  const [users, setUsers] = useState<any[]>([]);

  //for maintain executive list in dropdown
  const [executiveData, setExecutiveData] = useState({});

  //for expandalbe row functionality
  const [expandedRow, setExpandedRow] = useState<string | null>(null);

  //state to maintain checked/selected leads in state
  const [selectedLeads, setSelectedLeads] = useState<string[]>([]);

  const getExecutiveList = async () => {
    try {
      const userId = props?.types?.lId;
      const response: any = await getRequestById(
        GET_EX_LIST_BY_TL_ID,
        userId,
        ""
      );
      setExecutiveList(response.data);
    } catch (error) {
      console.error("Error getting executive list:", error);
    }
  };

  const getLeadData = async () => {
    try {
      const userId = props?.types?.lId;
      const response: any = await getRequestById(GET_TL_LED, userId, "");
      const users = response.data;
      setUsers(users);
      setSearchApiData(users);
    } catch (error) {
      console.error("Error getting lead list:", error);
    }
  };

  const handleChangePage = (event: any, newPage: any) => {
    setPage(newPage);
  };

  // snackbar display handler
  const handleOpenSnackBar = () => {
    setOpenSnackBar(true);
    setTimeout(() => {
      setOpenSnackBar(false);
    }, 3000);
  };

  const handleChangeRowsPerPage = (event: any) => {
    setRowsPerPage(+event.target.value);
    setPage(0);
  };

  const handleFilter = (e: any) => {
    if (e.target.value === "") {
      setUsers(searchApiData);
    } else {
      const filterResult: any = searchApiData.filter((item: any) => {
        const searchValue = e.target.value.toLowerCase();
        return Object.values(item).some((value) => {
          if (typeof value === "string" || typeof value === "number") {
            const itemValue = String(value).toLowerCase();
            return itemValue.includes(searchValue);
          }
          return false;
        });
      });
      setUsers(filterResult);
    }
    setFilterVal(e.target.value);
  };

  const handleDataAssign = async () => {
    if (Object.keys(executiveData).length === 0) {
      setSnackMessage("Select at least one employee");
      handleOpenSnackBar();

      return; // Stop execution if executiveData is empty
    }

    if (Object.keys(selectedLeads).length === 0) {
      setSnackMessage("Select at least one lead to assign");
      handleOpenSnackBar();
      return;
    }
    try {
      const userId = props?.types?.lId;
      const data1 = {
        executiveData: executiveData,
        selectedLeads: selectedLeads,
      };
      const result: any = await updateRequest(
        PUT_TL_ASSIGN_LEAD,
        userId,
        data1,
        ""
      );
      if (result) {
        setSnackMessage(result.data.message);
        handleOpenSnackBar();
      }
      setTimeout(() => {
        getLeadData();
        setSelectedExecutive("");
      }, 3000);
    } catch (error) {
      console.error(error);
      // Handle the error, for example, show an error message
      setSnackMessage("An error occurred. Please try again later.");
      handleOpenSnackBar();
    }
  };

  const handleExecutiveChange = (e: any) => {
    const { name, value } = e.target;
    setExecutiveData((prevState: any) => ({
      ...prevState,
      [name]: value,
    }));
  };

  // function for updating selected leads in state
  const handleCheckboxChange = (leadId: string) => {
    if (selectedLeads.includes(leadId)) {
      setSelectedLeads((prevSelected) =>
        prevSelected.filter((id) => id !== leadId)
      );
    } else {
      setSelectedLeads((prevSelected) => [...prevSelected, leadId]);
    }
  };
  const handleRowClick = (rowId: string) => {
    setExpandedRow((prevRowId) => (prevRowId === rowId ? null : rowId));
  };

  //handler for email click and open mail tab
  const handleEmailClick = (email: any) => {
    window.open(`mailto:${email}`, "_blank");
  };

  useEffect(() => {
    getExecutiveList();
    getLeadData();
  }, []);

  return (
    <>
      <Container>
        {isLoading && <LinearProgress />}
        <Paper elevation={20}>
          <Grid
            container
            sx={{
              p: 2,
              background: "#0288d1",
              color: "white",
            }}
          >
            <Grid item xs={6}>
              <Typography variant="h5" sx={{ mx: 3 }}>
                Manage Assigned Lead
              </Typography>
            </Grid>
            <Grid
              item
              xs={6}
              sx={{ display: "flex", justifyContent: "flex-end" }}
            >
              <FormControl>
                <TextField
                  label="Search "
                  size="small"
                  value={filterVal}
                  onInput={(e: any) => handleFilter(e)}
                  sx={{ mx: 3, background: "white" }}
                  InputProps={{
                    endAdornment: (
                      <InputAdornment position="end">
                        <IconButton edge="end">
                          <SearchIcon />
                        </IconButton>
                      </InputAdornment>
                    ),
                  }}
                />
              </FormControl>
            </Grid>
          </Grid>

          {/* second  */}
          <Grid xs={12} container rowSpacing={1} sx={{ mt: 1, p: 1 }}>
            <Grid
              item
              xs={6}
              sx={{
                justifyContent: "center",
                alignItems: "center",
                display: "flex",
              }}
            >
              <FormControl size="small" sx={{ width: "80%" }} required>
                <InputLabel id="demo-simple-select-required-label">
                  Executive
                </InputLabel>
                <Select
                  sx={{ textAlign: "center" }}
                  labelId="demo-simple-select-required-label"
                  label="executiveId"
                  name="executiveId"
                  value={selectedExecutive}
                  onChange={(e) => {
                    handleExecutiveChange(e);
                    setSelectedExecutive(e.target.value);
                  }}
                >
                  {executiveList?.map((Object: any) => (
                    <MenuItem
                      value={Object.exUniqueId}
                      key={Object._id}
                      id={Object.empId}
                    >
                      {Object.firstName} {Object.lastName} ({Object.empId})
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
            </Grid>
            <Grid
              item
              xs={3}
              sx={{
                justifyContent: "center",
                alignItems: "center",
                display: "flex",
              }}
            >
              <Button
                variant="contained"
                color="primary"
                type="submit"
                onClick={() => handleDataAssign()}
              >
                Submit
              </Button>
              <CommonSnackBar message={snackMessage} open={openSnackBar} />
            </Grid>
          </Grid>

          <TableContainer className="scrollBarCss">
            <Table aria-label="simple table" sx={{ mt: 2 }}>
              <TableHead>
                <TableRow>
                  {/* Add an empty cell for the expand icon */}
                  <TableCell
                    sx={{
                      textAlign: "center",
                      fontWeight: "bold",
                      border: "1px solid #ddd",
                    }}
                  ></TableCell>
                  <TableCell
                    sx={{
                      textAlign: "center",
                      fontWeight: "bold",
                      border: "1px solid #ddd",
                    }}
                  >
                    Sr.No
                  </TableCell>
                  {columns.map((user: any) => (
                    <React.Fragment key={user.id}>
                      <TableCell
                        className="border"
                        sx={{
                          textAlign: "center",
                          border: "1px solid #ddd",
                          fontSize: "15px",
                        }}
                      >
                        {user.headerName}
                      </TableCell>
                    </React.Fragment>
                  ))}
                  <TableCell
                    key="assignToHeader"
                    sx={{
                      textAlign: "center",
                      fontWeight: "bold",
                      border: "1px solid #ddd",
                    }}
                  >
                    Action
                  </TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {users
                  .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                  .map((item: any, index: number) => (
                    <React.Fragment key={item._id}>
                      <TableRow
                        className="onhover"
                        sx={{
                          textAlign: "center",
                          border: "1px solid #ddd",
                        }}
                      >
                        {/* Add the expand icon here */}
                        <TableCell
                          sx={{
                            textAlign: "center",

                            cursor: "pointer",
                            border: "1px solid #ddd",
                          }}
                        >
                          <IconButton
                            size="small"
                            aria-label="expand row"
                            sx={{
                              color:
                                expandedRow === item._id ? "#f50057" : "#000",
                            }}
                            onClick={(e) => {
                              e.stopPropagation();
                              handleRowClick(item._id);
                            }}
                          >
                            <ExpandMoreIcon />
                          </IconButton>
                        </TableCell>
                        <TableCell
                          sx={{
                            textAlign: "center",
                            borderRight: "1px solid #ddd",
                            borderLeft: "1px solid #ddd",
                          }}
                        >
                          {page * rowsPerPage + index + 1}
                        </TableCell>
                        {columns.map((column: any) => {
                          if (column.field === "email") {
                            return (
                              <>
                                <TableCell
                                  key={column.id}
                                  sx={{
                                    textAlign: "center",
                                    borderRight: "1px solid #ddd",
                                    borderLeft: "1px solid #ddd",
                                  }}
                                  onClick={() =>
                                    handleEmailClick(item[column.field])
                                  }
                                  style={{ cursor: "pointer", color: "blue" }}
                                >
                                  {item[column.field]}
                                </TableCell>
                              </>
                            );
                          } else {
                            return (
                              <>
                                <TableCell
                                  key={column.id}
                                  sx={{
                                    textAlign: "center",
                                    borderRight: "1px solid #ddd",
                                    borderLeft: "1px solid #ddd",
                                  }}
                                >
                                  {item[column.field]}
                                </TableCell>
                              </>
                            );
                          }
                        })}

                        <TableCell
                          key="assignTo"
                          sx={{
                            textAlign: "center",
                            borderRight: "1px solid #ddd",
                            borderLeft: "1px solid #ddd",
                          }}
                        >
                          <Checkbox
                            checked={selectedLeads.includes(item._id)}
                            onChange={() => handleCheckboxChange(item._id)}
                            color="primary"
                          />
                        </TableCell>
                      </TableRow>

                      <TableRow>
                        <TableCell colSpan={columns.length + 3}>
                          <Collapse
                            in={expandedRow === item._id}
                            timeout="auto"
                            unmountOnExit
                          >
                            <Grid container>
                              <Grid item xs={12} sm={4}>
                                <Typography
                                  sx={{
                                    fontWeight: "bold",
                                    display: "inline",
                                    mx: 2,
                                  }}
                                >
                                  Experience:
                                </Typography>
                                <Typography sx={{ display: "inline" }}>
                                  {item.experience}
                                </Typography>
                              </Grid>
                              <Grid item xs={12} sm={4}>
                                <Typography
                                  sx={{
                                    fontWeight: "bold",
                                    display: "inline",
                                    mx: 2,
                                  }}
                                >
                                  Qualification:
                                </Typography>
                                <Typography sx={{ display: "inline" }}>
                                  {item.qualification}
                                </Typography>
                              </Grid>
                              <Grid item xs={12} sm={4}>
                                <Typography
                                  sx={{
                                    fontWeight: "bold",
                                    display: "inline",
                                    mx: 2,
                                  }}
                                >
                                  State:
                                </Typography>
                                <Typography sx={{ display: "inline" }}>
                                  {item.state}
                                </Typography>
                              </Grid>
                              <Grid xs={12} sm={4}>
                                <Typography
                                  sx={{
                                    fontWeight: "bold",
                                    display: "inline",
                                    mx: 2,
                                  }}
                                >
                                  City:
                                </Typography>
                                <Typography sx={{ display: "inline" }}>
                                  {item.city}
                                </Typography>
                              </Grid>
                              <Grid xs={12} sm={4}>
                                <Typography
                                  sx={{
                                    fontWeight: "bold",
                                    display: "inline",
                                    mx: 2,
                                  }}
                                >
                                  Passing Year:
                                </Typography>
                                <Typography sx={{ display: "inline" }}>
                                  {item.passingYear}
                                </Typography>
                              </Grid>
                            </Grid>
                          </Collapse>
                        </TableCell>
                      </TableRow>
                    </React.Fragment>
                  ))}
              </TableBody>
            </Table>
          </TableContainer>

          <Grid sx={{ mt: 2 }}>
            <TablePagination
              rowsPerPageOptions={[10, 25, 50, 100]}
              component="div"
              count={users.length}
              rowsPerPage={rowsPerPage}
              page={page}
              onPageChange={handleChangePage}
              onRowsPerPageChange={handleChangeRowsPerPage}
            ></TablePagination>
          </Grid>
        </Paper>
      </Container>
    </>
  );
}
