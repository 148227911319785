import React from "react";
import { Box, Container, Grid, Stack, Typography } from "@mui/material";

export default function Terms() {
  const terms = [
    "We're glad you're here. The following terms and conditions of use are accepted if you use this website or view it in any other way.",
    "The following conditions of use govern use of this website:",
    "The material on these pages is solely for your general knowledge and use. Without prior notification, changes could be made.",
    "The accuracy, timeliness, performance, completeness, or suitability of the information and materials discovered or given on this website for any particular purpose is not warranted or guaranteed by us or by any third parties. You understand that these materials and information could have errors or inaccuracies, and we explicitly disclaim all responsibility for any such errors or inaccuracies to the fullest degree allowed by law.",
    "You use this website's information and materials completely at your own risk; we will not be held responsible. It is your obligation to guarantee that any goods, services, or information obtained through this website satisfy your particular needs.",
    "We hold the right to deny service to anyone at any moment.",
    "The content on this website is either our own or is used with our permission. Design, structure, look, appearance, and graphics are all included in this content. Reproduction is not permitted unless permitted by the copyright notice, which is a component of these terms and conditions.",
    "This website contains acknowledgements for all brands that appear here that are neither owned by, nor licensed to, the operator.",
    "Unauthorized use of this website could result in a lawsuit for damages or even legal action.",
    "This website occasionally may also have connections to other websites. For your convenience, we have included these links to additional material. They don't imply that we support the website in any way. The linked website's material is not under our control.",
    "Without our previous written consent, you may not establish a link to this website from another website or document.",
    "The laws of India apply to your use of this website, and any disputes that result from such use are subject to the authority of the courts in Madhya Pradesh, India.",
  ];

  return (
    <Container sx={{ mt: "80px" }}>
      <Box sx={{ pb: 4 }}>
        <Grid container spacing={2}>
          <Grid item xs={12}>
            <Typography
              variant="h4"
              sx={{
                justifyContent: "center",
                display: "flex",
                p: 2,
                textTransform: "uppercase",
                fontWeight: "bold",
              }}
            >
              Terms and Conditions
            </Typography>
            {terms.map((term, index) => (
              <div
                key={index}
                style={{ display: "flex", alignItems: "flex-start" }}
              >
                {index > 1 && (
                  <Typography
                    paragraph
                    sx={{
                      textAlign: "justify",
                      letterSpacing: "0px",
                      width: "30px",
                      flexShrink: 0,
                    }}
                  >
                    {`${index - 1}.`}
                  </Typography>
                )}
                <Typography
                  paragraph
                  sx={{
                    textAlign: "justify",
                    letterSpacing: "0px",
                    marginLeft: "10px",
                  }}
                >
                  {term}
                </Typography>
              </div>
            ))}
          </Grid>
        </Grid>
      </Box>
    </Container>
  );
}
