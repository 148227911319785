import { Button, Container, Grid, TextField, Typography } from "@mui/material";
import { AxiosResponse } from "axios";
import { FC, SyntheticEvent, useMemo } from "react";
import { postRequestMethod } from "../../../api/api";
import { PAYMENT } from "../../../api/server";

interface PaymentProps {
  amount: string;
  email: string;
}

const StudentInstallmentPayment: FC<PaymentProps> = ({ amount, email }) => {
  const modifiedAmount = useMemo(() => Math.round(Number(amount)), [amount]);

  function isDate(val: any): val is Date {
    return Object.prototype.toString.call(val) === "[object Date]";
  }

  function isObj(val: any): val is object {
    return typeof val === "object";
  }

  function stringifyValue(val: any): string {
    if (isObj(val) && !isDate(val)) {
      return JSON.stringify(val);
    } else {
      return val.toString();
    }
  }

  function buildForm({
    action,
    params,
  }: {
    action: string;
    params: Record<string, any>;
  }) {
    const form = document.createElement("form");
    form.setAttribute("method", "post");
    form.setAttribute("action", action);

    Object.keys(params)?.forEach((key) => {
      const input = document.createElement("input");
      input.setAttribute("type", "hidden");
      input.setAttribute("name", key);
      input.setAttribute("value", stringifyValue(params[key]));
      form.appendChild(input);
    });

    return form;
  }

  function post(details: { action: string; params: Record<string, any> }) {
    const form = buildForm(details);
    document.body.appendChild(form);
    form.submit();
    form.remove();
  }

  const getData = async (): Promise<any> => {
    try {
      const response: void | AxiosResponse<any, any> = await postRequestMethod(
        PAYMENT,
        "",
        { email, amount: modifiedAmount }
      );
      return response?.data;
    } catch (error) {
      console.error(error);
      throw error;
    }
  };

  const handlePayment = (e: SyntheticEvent) => {
    e.preventDefault();
    getData().then((response) => {
      const information = {
        action: "https://securegw.paytm.in/order/process",
        params: response,
      };
      post(information);
    });
  };

  return (
    <>
      <Container sx={{ my: 3 }}>
        <Grid
          container
          rowSpacing={2}
          columnSpacing={4}
          alignItems="center"
          justifyContent="center"
        >
          <Grid
            item
            xs={12}
            display="flex"
            justifyContent="center"
            alignItems="center"
          >
            <Typography variant="h6" color="#0288d1">
              WELCOME TO PRERNA ACADEMY PAYMENT
            </Typography>
          </Grid>
          <Grid item xs={6} display="flex" justifyContent="flex-end">
            <TextField
              placeholder="Amount"
              type="text"
              name="amount"
              value={modifiedAmount}
              size="small"
              sx={{ background: "white" }}
            />
          </Grid>
          <Grid item xs={6} display="flex" justifyContent="flex-start">
            <TextField
              placeholder="Email"
              type="email"
              name="email"
              value={email}
              size="small"
              sx={{ background: "white" }}
            />
          </Grid>
          <Grid
            item
            xs={12}
            display="flex"
            justifyContent="center"
            alignItems="center"
          >
            <Button variant="contained" onClick={handlePayment}>
              Initiate Payment
            </Button>
          </Grid>
        </Grid>
      </Container>
    </>
  );
};

export default StudentInstallmentPayment;
