import { FormLabel, InputLabel, Radio, RadioGroup, Stack } from "@mui/material";
import Button from "@mui/material/Button";
import Checkbox from "@mui/material/Checkbox";
import FormControl from "@mui/material/FormControl";
import FormControlLabel from "@mui/material/FormControlLabel";
import Grid from "@mui/material/Grid";
import MenuItem from "@mui/material/MenuItem";
import Select from "@mui/material/Select";
import TextField from "@mui/material/TextField";
import Typography from "@mui/material/Typography";
import { debounce } from "lodash";
import { Fragment, useEffect, useState } from "react";
import useValidation from "../../Hooks/useTypescriptValidation";

function CourseDetails(props: any) {
  const { eventHandler } = useValidation();
  const {
    isChecked,
    setIsChecked,
    uniDropData,
    dropDownData,
    display,
    setDisplay,
    handler,
    detail,
    setCourseData,
    setActiveStep,
    setShowSubmit,
  } = props;

  const installments: any =
    detail?.mode === "Semester"
      ? [1, 2, 3, 4, 5, 6]
      : [1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12];

  const [errors, setErrors] = useState<{ [key: string]: string }>({
    admissionType: "",
    admissionDate: "",
    courseType: "",
    universityName: "",
    courseName: "",
    admissionOfSemesterOrYear: "",
    courseOfferedSemesterOrYear: "",
    sessionFrom: "",
    sessionTo: "",
    courseFees: "",
    feesReceived: "",
    regFees: "",
    cautionMoney: "",
    otherFees: "",
    discount: "",
    totalFee: "",
    paymentMode: "",
    depositeDate: "",
    slipNo: "",
    bankName: "",
    chequeNo: "",
    accountNo: "",
    transferId: "",
    remark: "",
  });

  const validationHandler = debounce((e: any, alterName?: any) => {
    const val = e.target.value;
    const id = alterName;
    const err = eventHandler(id, val);
    setErrors((prevData: any) => ({
      ...prevData,
      [e.target.name]: err,
    }));
    if (val === "") {
      setErrors((prevData: any) => ({
        ...prevData,
        [e.target.name]: "",
      }));
    }
  }, 2000);

  const handlePaymentMode = (e: any) => {
    clearer();
    const { id } = e.target;
    handler(e);
    if (id === "cash") {
      setDisplay({
        cash: true,
        cheque: false,
        acTransfer: false,
      });
    } else if (id === "cheque") {
      setDisplay({
        cash: false,
        cheque: true,
        acTransfer: false,
      });
    } else {
      setDisplay({
        cash: false,
        cheque: false,
        acTransfer: true,
      });
    }
  };

  const clearer = () => {
    setCourseData((courseData: any) => ({
      ...courseData,
      depositDate: "",
      slipNo: undefined,
      bankName: undefined,
      chequeNo: undefined,
      accountNo: undefined,
      transferId: undefined,
    }));
  };

  const handleNext = (e: any) => {
    e.preventDefault();
    let isError: boolean = false;
    for (let key in errors) {
      if (errors[key] !== "") {
        isError = true;
        break;
      }
    }
    if (isError) window.alert("Please fill valid data");
    else if (detail.paymentMode === "")
      window.alert("Please fill all required fields");
    else {
      setActiveStep(4);
      window.scrollTo(0, 0);
      setShowSubmit((showSubmit: any) => ({
        ...showSubmit,
        courseDetail: true,
      }));
    }
  };

  useEffect(() => {
    if (
      detail.sessionFrom &&
      detail.sessionTo &&
      detail.sessionFrom !== "" &&
      detail.sessionTo !== ""
    ) {
      const sessionFromDate = new Date(detail.sessionFrom);
      const sessionToDate = new Date(detail.sessionTo);

      if (sessionFromDate >= sessionToDate) {
        setErrors((prevData: { [key: string]: string }) => ({
          ...prevData,
          sessionFrom: "Session From date must be less than Session To date",
          sessionTo: "Session To date must be greater than Session From date",
        }));
      } else {
        setErrors((prevData: { [key: string]: string }) => ({
          ...prevData,
          sessionFrom: "",
          sessionTo: "",
        }));
      }
    }
  }, [detail.sessionFrom, detail.sessionTo]);

  return (
    <>
      <Grid container component="form" spacing={2} onSubmit={handleNext}>
        <Grid item xs={12} md={12}>
          <Typography variant="h6">Course Details</Typography>
        </Grid>

        <Grid item xs={12} md={6}>
          <FormControl fullWidth required>
            <InputLabel id="demo-simple-select-label">
              Admission Type
            </InputLabel>
            <Select
              labelId="demo-simple-select-label"
              id="demo-simple-select"
              name="admissionType"
              label="Admission Type"
              required
              value={detail.admissionType}
              onChange={handler}
            >
              <MenuItem value={"Fresher"}>Fresher</MenuItem>
              <MenuItem value={"Lateral Entry"}>Lateral Entry</MenuItem>
              <MenuItem value={"Credit Transfer"}>Credit Transfer</MenuItem>
            </Select>
          </FormControl>
        </Grid>

        <Grid item xs={12} md={6}>
          <FormControl fullWidth>
            <InputLabel id="demo-simple-select-label">
              Course Category
            </InputLabel>
            <Select
              required
              labelId="demo-simple-select-label"
              id="demo-simple-select"
              label="Course Category"
              name="courseCategory"
              value={detail?.courseCategory}
              onChange={(e, children) => {
                dropDownData(e, children);
                handler(e);
              }}
            >
              {[
                "Regular",
                "Private",
                "F-TEL",
                "Distance",
                "Online",
                "Part-time",
              ].map((text) => (
                <MenuItem value={text} key={text}>
                  {text}
                </MenuItem>
              ))}
            </Select>
          </FormControl>
        </Grid>

        <Grid item xs={12} md={6}>
          <FormControl fullWidth>
            <InputLabel id="demo-simple-select-label">
              University Name
            </InputLabel>
            <Select
              required
              labelId="demo-simple-select-label"
              id="demo-simple-select"
              label="University Name"
              name="universityName"
              value={props?.detail?.universityName}
              onChange={(e, children) => {
                dropDownData(e, children);
                handler(e);
              }}
            >
              {uniDropData?.universityName.map((item: any) => (
                <MenuItem
                  value={item?.universityName}
                  key={item.uId}
                  id={item.uId}
                >
                  {item?.universityName}
                </MenuItem>
              ))}
            </Select>
          </FormControl>
        </Grid>

        <Grid item xs={12} md={6}>
          <FormControl fullWidth>
            <InputLabel id="demo-simple-select-label">
              Department Name
            </InputLabel>
            <Select
              required
              labelId="demo-simple-select-label"
              id="demo-simple-select"
              label="Department Name"
              name="departmentName"
              value={detail?.departmentName}
              onChange={(e, children) => {
                dropDownData(e, children);
                handler(e);
              }}
            >
              {uniDropData?.department.map((item: any) => (
                <MenuItem value={item.name} key={item.dId} id={item.dId}>
                  {item.name}
                </MenuItem>
              ))}
            </Select>
          </FormControl>
        </Grid>

        <Grid item xs={12} md={6}>
          <FormControl fullWidth>
            <InputLabel id="demo-simple-select-label">Course Name</InputLabel>
            <Select
              required
              labelId="demo-simple-select-label"
              id="demo-simple-select"
              label="courseName"
              name="courseName"
              value={detail?.courseName}
              onChange={(e, children) => {
                dropDownData(e, children);
                handler(e);
              }}
            >
              {uniDropData?.course.map((item: any) => (
                <MenuItem value={item.name} key={item.cId} id={item.cId}>
                  {item.name}
                </MenuItem>
              ))}
            </Select>
          </FormControl>
        </Grid>

        <Grid item xs={12} md={6}>
          <FormControl fullWidth>
            <InputLabel id="demo-simple-select-label">
              Specialization
            </InputLabel>
            <Select
              required
              labelId="demo-simple-select-label"
              id="demo-simple-select"
              label="Specialization"
              name="specialization"
              value={detail?.specialization}
              onChange={(e, children) => {
                dropDownData(e, children);
                handler(e);
              }}
            >
              {uniDropData?.specialization.map((item: any) => (
                <MenuItem value={item.name} key={item.sId} id={item.sId}>
                  {item?.name}
                </MenuItem>
              ))}
            </Select>
          </FormControl>
        </Grid>

        <Grid item xs={12} md={6}>
          <FormControl fullWidth>
            <TextField
              required
              disabled
              id="outlined-disabled"
              label="Mode"
              name="mode"
              value={detail?.mode}
              sx={{ background: "white" }}
            />
          </FormControl>
        </Grid>

        <Grid item xs={12} md={6}>
          <FormControl fullWidth>
            <TextField
              required
              disabled
              id="outlined-disabled"
              label="Admission of(Semester or year)"
              name="admissionOfSemesterOrYear"
              value={detail?.admissionSemesterYear}
              sx={{ background: "white" }}
            />
          </FormControl>
        </Grid>

        <Grid item xs={12} md={6}>
          <FormControl fullWidth>
            <TextField
              required
              disabled
              id="outlined-disabled"
              label="No of Semesters/Years"
              name="noOfSemsOrYears"
              value={detail?.noOfSemsOrYears}
              sx={{ background: "white" }}
            />
          </FormControl>
        </Grid>

        <Grid item xs={12} md={6}>
          <FormControl fullWidth>
            <TextField
              required
              InputLabelProps={{ shrink: true }}
              id="month-input"
              label="Admission Date"
              type="date"
              sx={{ background: "white" }}
              autoComplete="current-date"
              name="admissionDate"
              value={detail?.admissionDate}
              onChange={(e) => handler(e)}
              InputProps={{
                inputProps: { max: new Date().toISOString().slice(0, 10) },
              }}
            />
          </FormControl>
        </Grid>
        <Grid item xs={12} md={6}>
          <FormControl fullWidth>
            <TextField
              required
              InputLabelProps={{ shrink: true }}
              id="outlined-month-input"
              label="Session From"
              type="month"
              autoComplete="current-date"
              name="sessionFrom"
              sx={{ background: "white" }}
              value={detail?.sessionFrom}
              error={Boolean(errors.sessionFrom)}
              helperText={errors.sessionFrom}
              onChange={(e) => handler(e)}
              inputProps={{ min: new Date().toISOString().slice(0, 10) }}
            />
          </FormControl>
        </Grid>
        <Grid item xs={12} md={6}>
          <FormControl fullWidth>
            <TextField
              required
              InputLabelProps={{ shrink: true }}
              id="outlined-month-input"
              label="Session To"
              type="month"
              autoComplete="current-date"
              name="sessionTo"
              value={detail?.sessionTo}
              error={Boolean(errors.sessionTo)}
              helperText={errors.sessionTo}
              onChange={(e) => handler(e)}
              sx={{ background: "white" }}
              inputProps={{ min: new Date().toISOString().slice(0, 10) }}
            />
          </FormControl>
        </Grid>
        <Grid item xs={12} md={6}>
          <FormControl fullWidth>
            <TextField
              required
              disabled
              id="outlined-password-input"
              label="Course Fees"
              name="courseFees"
              value={detail?.courseFees}
              sx={{ background: "white" }}
            />
          </FormControl>
        </Grid>
        <Grid item xs={12} md={6}>
          <FormControl fullWidth>
            <TextField
              required
              id="outlined-password-input"
              label="Fees Received"
              name="feesReceived"
              type="number"
              value={detail?.feesReceived}
              sx={{ background: "white" }}
              error={Boolean(errors.feesReceived)}
              helperText={errors.feesReceived}
              onChange={(e) => {
                validationHandler(e, "number");
                handler(e);
              }}
            />
          </FormControl>
        </Grid>
        <Grid item xs={12} md={6}>
          <FormControl fullWidth>
            <TextField
              required
              disabled
              id="outlined-password-input"
              label="Other fees"
              name="otherFees"
              autoComplete="current-password"
              value={detail?.otherFees}
              sx={{ background: "white" }}
            />
          </FormControl>
        </Grid>

        <Grid item xs={12} md={6}>
          <FormControl fullWidth>
            <TextField
              required
              id="outlined-password-input"
              label="Registration Fees"
              name="regFees"
              type="number"
              value={detail?.regFees}
              error={Boolean(errors.regFees)}
              helperText={errors.regFees}
              sx={{ background: "white" }}
              onChange={(e) => {
                validationHandler(e, "number");
                handler(e);
              }}
            />
          </FormControl>
        </Grid>

        <Grid item xs={12} md={6}>
          <FormControl fullWidth>
            <TextField
              required
              id="outlined-password-input"
              label="Caution Money"
              name="cautionMoney"
              type="number"
              value={detail?.cautionMoney}
              error={Boolean(errors.cautionMoney)}
              helperText={errors.cautionMoney}
              sx={{ background: "white" }}
              onChange={(e) => {
                validationHandler(e, "number");
                handler(e);
              }}
            />
          </FormControl>
        </Grid>

        <Grid item xs={12} md={6}>
          <FormControl fullWidth>
            <TextField
              required
              id="outlined-password-input"
              label="Discount"
              name="discount"
              type="number"
              value={detail?.discount}
              error={Boolean(errors.discount)}
              helperText={errors.discount}
              sx={{ background: "white" }}
              onChange={(e) => {
                validationHandler(e, "number");
                handler(e);
              }}
            />
          </FormControl>
        </Grid>

        <Grid item xs={12} md={6}>
          <FormControl fullWidth>
            <TextField
              required
              id="outlined-password-input"
              label="Total Course Fee"
              name="totalFee"
              type="number"
              disabled
              value={detail?.totalFee}
              sx={{ background: "white" }}
            />
          </FormControl>
        </Grid>

        <Grid item xs={12} sm={12}>
          <FormControl required>
            <FormLabel id="payment-mode">Payment Mode:</FormLabel>
            <RadioGroup
              row
              aria-labelledby="payment-mode"
              name="paymentMode"
              value={detail?.paymentMode}
              onChange={handlePaymentMode}
            >
              <FormControlLabel
                value="cash"
                control={<Radio id="cash" />}
                label="cash"
              />
              <FormControlLabel
                value="cheque/dd"
                control={<Radio id="cheque" />}
                label="cheque/dd"
              />
              <FormControlLabel
                value="AC/Transfer"
                control={<Radio id="acTransfer" />}
                label="AC/Transfer"
              />
            </RadioGroup>
          </FormControl>
        </Grid>
        {display?.cash && (
          <>
            <Grid item xs={12} sm={6}>
              <TextField
                required
                InputLabelProps={{ shrink: true }}
                id="depositeDate"
                name="depositDate"
                label="Deposit Date"
                variant="outlined"
                type="date"
                value={detail?.depositDate}
                fullWidth
                sx={{ background: "white" }}
                onChange={(e) => {
                  handler(e);
                }}
                inputProps={{ max: new Date().toISOString().slice(0, 10) }}
              />
            </Grid>

            <Grid item xs={12} sm={6}>
              <TextField
                required
                id="slipNo"
                label="Slip No."
                name="slipNo"
                variant="outlined"
                fullWidth
                type="text"
                value={detail?.slipNo}
                error={Boolean(errors.slipNo)}
                helperText={errors.slipNo}
                sx={{ background: "white" }}
                onChange={(e) => {
                  validationHandler(e, "numeric");
                  handler(e);
                }}
              />
            </Grid>
          </>
        )}
        {display?.cheque && (
          <>
            <Grid item xs={12} sm={6}>
              <TextField
                required
                InputLabelProps={{ shrink: true }}
                id="depositeDate"
                name="depositDate"
                label="Deposit Date"
                variant="outlined"
                type="date"
                value={detail?.depositDate}
                fullWidth
                sx={{ background: "white" }}
                onChange={(e) => {
                  handler(e);
                }}
                inputProps={{ max: new Date().toISOString().slice(0, 10) }}
              />
            </Grid>

            <Grid item xs={12} sm={6}>
              <TextField
                required
                id="slipNo"
                label="Slip No."
                name="slipNo"
                variant="outlined"
                fullWidth
                type="text"
                value={detail?.slipNo}
                error={Boolean(errors.slipNo)}
                helperText={errors.slipNo}
                sx={{ background: "white" }}
                onChange={(e) => {
                  validationHandler(e, "numeric");
                  handler(e);
                }}
              />
            </Grid>

            <Grid item xs={12} sm={6}>
              <TextField
                required
                fullWidth
                label="Bank Name"
                type="text"
                id="bankName"
                name="bankName"
                value={detail?.bankName}
                error={Boolean(errors.bankName)}
                helperText={errors.bankName}
                sx={{ background: "white" }}
                onChange={(e) => {
                  validationHandler(e, "alphabetsAndSpace");
                  handler(e);
                }}
              />
            </Grid>

            <Grid item xs={12} sm={6}>
              <TextField
                required
                fullWidth
                name="chequeNo"
                label="Cheque No."
                type="text"
                id="chequeNo"
                value={detail?.chequeNo}
                error={Boolean(errors.chequeNo)}
                helperText={errors.chequeNo}
                sx={{ background: "white" }}
                onChange={(e) => {
                  validationHandler(e);
                  handler(e);
                }}
              />
            </Grid>
          </>
        )}

        {display?.acTransfer && (
          <>
            <Grid item xs={12} sm={6}>
              <TextField
                required
                InputLabelProps={{ shrink: true }}
                id="depositeDate"
                name="depositDate"
                label="Deposit Date"
                variant="outlined"
                type="date"
                value={detail?.depositDate}
                fullWidth
                sx={{ background: "white" }}
                onChange={(e) => {
                  handler(e);
                }}
                inputProps={{ max: new Date().toISOString().slice(0, 10) }}
              />
            </Grid>

            <Grid item xs={12} sm={6}>
              <TextField
                required
                id="slipNo"
                label="Slip No."
                name="slipNo"
                variant="outlined"
                fullWidth
                type="text"
                value={detail?.slipNo}
                error={Boolean(errors.slipNo)}
                helperText={errors.slipNo}
                sx={{ background: "white" }}
                onChange={(e) => {
                  validationHandler(e, "numeric");
                  handler(e);
                }}
              />
            </Grid>

            <Grid item xs={12} sm={6}>
              <TextField
                required
                fullWidth
                label="Bank Name"
                type="text"
                id="bankName"
                name="bankName"
                value={detail?.bankName}
                error={Boolean(errors.bankName)}
                helperText={errors.bankName}
                sx={{ background: "white" }}
                onChange={(e) => {
                  validationHandler(e, "alphabetsAndSpace");
                  handler(e);
                }}
              />
            </Grid>

            <Grid item xs={12} sm={6}>
              <TextField
                required
                fullWidth
                name="chequeNo"
                label="Cheque No."
                type="text"
                id="chequeNo"
                value={detail?.chequeNo}
                error={Boolean(errors.chequeNo)}
                helperText={errors.chequeNo}
                sx={{ background: "white" }}
                onChange={(e) => {
                  validationHandler(e);
                  handler(e);
                }}
              />
            </Grid>

            <Grid item xs={12} sm={6}>
              <TextField
                required
                fullWidth
                name="accountNo"
                label="Account No."
                id="accountNo"
                value={detail?.accountNo}
                error={Boolean(errors.accountNo)}
                helperText={errors.accountNo}
                sx={{ background: "white" }}
                onChange={(e) => {
                  validationHandler(e, "bankAccountNumber");
                  handler(e);
                }}
              />
            </Grid>

            <Grid item xs={12} sm={6}>
              <TextField
                required
                fullWidth
                name="transferId"
                label="Transfer Id"
                type="text"
                id="transferId"
                value={detail?.transferId}
                error={Boolean(errors.transferId)}
                helperText={errors.transferId}
                sx={{ background: "white" }}
                onChange={(e) => {
                  handler(e);
                }}
              />
            </Grid>
          </>
        )}
        <Grid item xs={12}>
          <FormControlLabel
            control={
              <Checkbox
                checked={isChecked}
                onChange={(e) => setIsChecked(e.target.checked)}
              />
            }
            label="Installment"
          />
        </Grid>

        {isChecked && (
          <>
            <Grid item xs={12} sm={6}>
              <TextField
                variant="outlined"
                required
                fullWidth
                InputLabelProps={{ shrink: true }}
                id="outlined-month-input"
                label="Installment Start Date(MM/DD/YYYY)"
                name="installmentStartDate"
                type="date"
                sx={{ background: "white" }}
                value={detail?.installmentStartDate}
                onChange={(e) => handler(e)}
              />
            </Grid>

            <Grid item xs={12} sm={6}>
              <FormControl fullWidth>
                <InputLabel id="demo-simple-select-label">
                  Number Of Installments
                </InputLabel>
                <Select
                  required
                  labelId="demo-simple-select-label"
                  label="Number Of Installments"
                  name="numberOfInstallment"
                  value={detail?.numberOfInstallment}
                  onChange={(e, index) => handler(e, index)}
                >
                  {installments.map((item: number) => (
                    <MenuItem key={item} value={item}>
                      {item}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
            </Grid>

            {detail.installmentDetails.map((item: any, index: number) => (
              <Fragment key={index}>
                <Grid item xs={12} sm={6}>
                  <TextField
                    variant="outlined"
                    fullWidth
                    InputLabelProps={{ shrink: true }}
                    id={`installmentDate-${index}`}
                    label="Date(MM/DD/YYYY)"
                    name="date"
                    type="date"
                    value={item.date}
                    sx={{ background: "white" }}
                    onChange={(e: any) => handler(e, index)}
                  />
                </Grid>
                <Grid item xs={12} sm={6}>
                  <TextField
                    variant="outlined"
                    label="Amount"
                    name="amount"
                    fullWidth
                    value={item.amount}
                    sx={{ background: "white" }}
                    onChange={(e) => handler(e, index)}
                  />
                </Grid>
              </Fragment>
            ))}
          </>
        )}

        <Grid item xs={12} sx={{ display: "flex", justifyContent: "center" }}>
          <Stack spacing={2} direction="row">
            <Button
              type="button"
              variant="contained"
              onClick={() => {
                setActiveStep(2);
                window.scrollTo(0, 0);
              }}
            >
              Back
            </Button>
            <Button variant="contained" type="submit">
              Next
            </Button>
          </Stack>
        </Grid>
      </Grid>
    </>
  );
}

export default CourseDetails;
