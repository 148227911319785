import DeleteIcon from "@mui/icons-material/Delete";
import {
  Button,
  Checkbox,
  FormControl,
  Grid,
  IconButton,
  InputLabel,
  MenuItem,
  Paper,
  Select,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  TextField,
  Typography,
  debounce,
} from "@mui/material";
import { AxiosResponse } from "axios";
import { ChangeEvent, Fragment, useEffect, useState } from "react";
import { useNavigate, useLocation } from "react-router-dom";
import CommonSnackBar from "../../Components/Common/CommonSnackbar";
import india from "../../Components/Common/india.json";
import useValidation from "../../Hooks/useTypescriptValidation";
import { getRequest, updateRequest } from "../../api/api";
import { STUDENT_BY_ID, UPDATE_STUDENT_INFO } from "../../api/server";

interface Files {
  id: number;
  file: File;
}

export default function ManageUpdateInfo() {
  const label = { inputProps: { "aria-label": "Checkbox demo" } };
  const location = useLocation();
  const navigate = useNavigate();
  const { eventHandler } = useValidation();

  const [detail, setDetail] = useState<any>({});
  const [presentCity, setPresentCity] = useState<any>([]);
  const [permanentCity, setPermanentCity] = useState<any>([]);
  const [showAdditionalFiles, setShowAdditionalFiles] = useState<any>();
  const [additionalFiles, setAdditionalFiles] = useState<Files[]>([]);
  const [isChecked1, setIsChecked1] = useState<boolean>(false);
  const [isChecked2, setIsChecked2] = useState<boolean>(false);
  const [errors, setErrors] = useState<any>();
  const [snackMessage, setSnackMessage] = useState<string>("");
  const [openSnackBar, setOpenSnackBar] = useState<boolean>(false);

  const [imageData, setImageData] = useState<any>({
    photo: "",
    signature: "",
  });

  const [image, setImage] = useState<any>({
    photo: "",
    signature: "",
  });

  const handleFile = (e: any) => {
    handleImageInputChange(e);
    setImage({ ...image, [e.target.name]: e.target.files[0] });
  };

  const handleDetails = (e: any) => {
    const { name, value } = e.target;
    setDetail((prevData: any) => ({
      ...prevData,
      [name]: value,
      studentData: {
        ...prevData.studentData,
        [name]: value,
      },
      academicData: {
        ...prevData.academicData,
        [name]: value,
      },
      courseData: {
        ...prevData.courseData,
        [name]: value,
      },
    }));
  };

  const handleOpenSnackBar = () => {
    setOpenSnackBar(true);
    setTimeout(() => {
      setOpenSnackBar(false);
    }, 3000);
  };

  const handleCheckboxChange1 = () => {
    setIsChecked1(!isChecked1);
  };

  const handleCheckboxChange2 = () => {
    setIsChecked2(!isChecked2);
  };

  const handleImageInputChange = async (event: any) => {
    const file = event.target.files[0];
    if (file) {
      const imageUrl = URL.createObjectURL(file);
      setImageData((prevImageData: any) => ({
        ...prevImageData,
        [event.target.name]: imageUrl,
      }));
    }
  };

  const filterCitiesByState = (state: any) => {
    const filteredCities = india.filter((item) => item.state_name === state);
    return filteredCities[0]?.cities;
  };

  const validationHandler = debounce(
    (e: any, alterName?: any, fileId?: string) => {
      const id = alterName;
      const val: string | File | undefined =
        alterName === "imageSize" || alterName === "pdfImageSize"
          ? e.target.files[0]
          : e.target.value;

      if (typeof val === "undefined") return;

      const err = eventHandler(id, val);

      if (fileId) {
        setErrors((prevData: any) => ({
          ...prevData,
          [fileId]: err,
        }));
      } else {
        setErrors((prevData: any) => ({
          ...prevData,
          [e.target.name]: err,
        }));
      }
    },
    2000
  );

  const fetchData = async () => {
    try {
      let data = { studentId: location.state.id };
      const res = await getRequest(STUDENT_BY_ID, { params: data });
      if (res) {
        setDetail(res.data);
        setImageData({
          photo: res.data.fileInputs?.photo?.path,
          signature: res.data.fileInputs?.signature?.path,
        });
      }
    } catch (error) {
      console.error("Error fetching data:", error);
    }
  };

  const validator = () => {
    for (let field in errors) {
      if (errors[field] !== "") {
        return true;
      }
    }
    return false;
  };

  const updateData = async (e: any) => {
    e.preventDefault();
    if (validator()) {
      return alert("Error in form!");
    }
    const data = { studentId: location.state.id };
    const formData = new FormData();

    formData.append("detail", JSON.stringify(detail));
    formData.append("id", data.studentId);
    formData.append("photo", image.photo);
    formData.append("signature", image.signature);
    additionalFiles?.forEach((file: Files) =>
      formData.append("additionalFiles", file?.file)
    );

    try {
      const response: void | AxiosResponse<any, any> = await updateRequest(
        UPDATE_STUDENT_INFO,
        "",
        formData,
        "multipart/form-data"
      );
      if (response && response.data && response.data.error) {
        setSnackMessage(response.data.error);
        handleOpenSnackBar();
      }
      if (response && response.data) {
        navigate("/dashboard/ManageStudent");
      }
    } catch (error) {
      console.error("Error updating data:", error);
    }
  };

  const handleAddButtonClick = () => {
    setAdditionalFiles((prevFiles: Files[]) => [
      ...prevFiles,
      {
        id: prevFiles.length === 0 ? 0 : prevFiles[prevFiles.length - 1].id + 1,
        file: new File([], ""),
      },
    ]);
  };

  const handleFileChange = (
    event: ChangeEvent<HTMLInputElement>,
    index: number
  ) => {
    const files: FileList | null = (event.target as HTMLInputElement).files;
    if (files?.[0] && index !== undefined && index !== null) {
      setAdditionalFiles((prevFiles: Files[]) => {
        return prevFiles.map((file: Files) => {
          if (file.id === index) {
            return { ...file, file: files[0] };
          }
          return file;
        });
      });
    }
  };

  const handleRemoveButtonClick = (index: number) => {
    setAdditionalFiles((prevFiles: Files[]) =>
      prevFiles.filter((item: Files) => item.id !== index)
    );
  };

  useEffect(() => {
    let additionalFiles = detail?.fileInputs?.additionalFiles?.map(
      (file: any, index: number) => (
        <Grid item xs={6} md={3} key={index}>
          <Typography gutterBottom>{file.originalname}</Typography>
          <Button variant="contained" href={file?.path} sx={{ mb: 2 }}>
            Download
          </Button>
        </Grid>
      )
    );
    setShowAdditionalFiles(additionalFiles);
  }, [imageData]);

  useEffect(() => {
    const presentCity = filterCitiesByState(detail?.studentData?.presentState);
    setPresentCity(presentCity);
  }, [detail?.studentData?.presentState]);

  useEffect(() => {
    const permanentCity = filterCitiesByState(
      detail?.studentData?.permanentState
    );
    setPermanentCity(permanentCity);
  }, [detail?.studentData?.permanentState]);

  useEffect(() => {
    fetchData();
  }, []);

  return (
    <Paper elevation={10}>
      <Grid
        container
        component="form"
        spacing={4}
        padding={3}
        marginTop={2}
        encType="multipart/form-data"
      >
        <Grid item xs={12} md={12}>
          <Typography variant="h6" color="#0288d1" textTransform="uppercase">
            Upload Documents
          </Typography>
        </Grid>

        <Grid item xs={12} md={6}>
          <img
            src={imageData.photo}
            alt={"photo"}
            style={{ height: 150, marginBottom: 2 }}
          />

          <TextField
            required
            fullWidth
            label="Photo"
            type="file"
            name="photo"
            variant="outlined"
            sx={{ background: "white" }}
            helperText={errors?.photo}
            error={Boolean(errors?.photo)}
            InputLabelProps={{ shrink: true }}
            inputProps={{ accept: "image/*" }}
            onChange={(e) => {
              validationHandler(e, "imageSize");
              handleFile(e);
            }}
          />
        </Grid>

        <Grid item xs={12} md={6}>
          <img
            src={imageData.signature}
            alt="signature"
            style={{ height: 150, marginBottom: 2 }}
          />

          <TextField
            required
            fullWidth
            label="Signature"
            type="file"
            name="signature"
            variant="outlined"
            sx={{ background: "white" }}
            helperText={errors?.signature}
            error={Boolean(errors?.signature)}
            InputLabelProps={{ shrink: true }}
            inputProps={{ accept: "image/*" }}
            onChange={(e) => {
              validationHandler(e, "imageSize");
              handleFile(e);
            }}
          />
        </Grid>

        <Grid item xs={12}>
          <Typography variant="h6" color="#0288d1" textTransform="uppercase">
            Additional Files
          </Typography>
        </Grid>

        <Grid item xs={12}>
          <Grid container>{showAdditionalFiles}</Grid>
        </Grid>

        <Grid item xs={12}>
          <Button variant="contained" onClick={handleAddButtonClick}>
            Add File
          </Button>
        </Grid>

        {additionalFiles?.map((data: Files) => (
          <Fragment key={data.id}>
            <Grid item xs={12} md={6}>
              <TextField
                required
                fullWidth
                label="Upload Files"
                type="file"
                name="additionalFiles"
                variant="outlined"
                sx={{ background: "white" }}
                InputLabelProps={{ shrink: true }}
                inputProps={{ accept: ".pdf, image/*" }}
                helperText={errors?.[`additionalFiles${data.id}`]}
                error={Boolean(errors?.[`additionalFiles${data.id}`])}
                onChange={(e: ChangeEvent<HTMLInputElement>) => {
                  handleFileChange(e, data.id);
                  validationHandler(
                    e,
                    "pdfImageSize",
                    `additionalFiles${data.id}`
                  );
                }}
              />
              <IconButton onClick={() => handleRemoveButtonClick(data.id)}>
                <DeleteIcon color="error" />
              </IconButton>
            </Grid>
          </Fragment>
        ))}

        {/*--------------------------- Student Details-------------------------------- */}

        <Grid item xs={12}>
          <Typography variant="h6" color="#0288d1" textTransform="uppercase">
            Student Details
          </Typography>
        </Grid>

        <Grid item xs={12} md={6}>
          <TextField
            InputLabelProps={{ shrink: true }}
            id="outlined-First-Name"
            label="First Name"
            name="firstName"
            type="text"
            value={detail?.studentData?.firstName}
            fullWidth
            error={Boolean(errors?.firstName)}
            helperText={errors?.firstName}
            sx={{ background: "white" }}
            onChange={(e) => {
              validationHandler(e, "alphabet");
              handleDetails(e);
            }}
          />
        </Grid>

        <Grid item xs={12} md={6}>
          <TextField
            InputLabelProps={{ shrink: true }}
            id="outlined-Last-Name"
            label="Last Name"
            name="lastName"
            type="text"
            value={detail?.studentData?.lastName}
            fullWidth
            error={Boolean(errors?.lastName)}
            helperText={errors?.lastName}
            sx={{ background: "white" }}
            onChange={(e) => {
              validationHandler(e, "alphabet");
              handleDetails(e);
            }}
          />
        </Grid>

        <Grid item xs={12} md={6}>
          <TextField
            required
            InputLabelProps={{ shrink: true }}
            id="outlined-Email-flexible"
            label="Email"
            type="email"
            name="email"
            value={detail?.studentData?.email}
            fullWidth
            error={Boolean(errors?.email)}
            helperText={errors?.email}
            sx={{ background: "white" }}
            onChange={(e) => {
              validationHandler(e, "email");
              handleDetails(e);
            }}
          />
        </Grid>

        <Grid item xs={12} md={6}>
          <TextField
            InputLabelProps={{ shrink: true }}
            id="outlined-Contact-flexible"
            label="Contact Number"
            name="contactNumber"
            type="number"
            value={detail?.studentData?.contactNumber}
            fullWidth
            required
            error={Boolean(errors?.contactNumber)}
            helperText={errors?.contactNumber}
            sx={{ background: "white" }}
            onChange={(e) => {
              validationHandler(e, "mobile");
              handleDetails(e);
            }}
          />
        </Grid>

        <Grid item xs={12} md={6}>
          <TextField
            InputLabelProps={{ shrink: true }}
            id="outlined-Date-flexible"
            label="Date Of Birth"
            type="date"
            name="dateOfBirth"
            value={detail?.studentData?.dateOfBirth}
            fullWidth
            onChange={handleDetails}
            sx={{ background: "white" }}
          />
        </Grid>

        <Grid item xs={12} md={6}>
          <FormControl fullWidth required>
            <InputLabel id="demo-simple-select-label">Gender</InputLabel>
            <Select
              labelId="demo-simple-select-required-label"
              label="Gender"
              required
              id="demo-simple-select"
              value={detail?.studentData?.gender || ""}
              name="gender"
              onChange={handleDetails}
            >
              <MenuItem value={"Male"}>Male</MenuItem>
              <MenuItem value={"Female"}>Female</MenuItem>
              <MenuItem value={"Other"}>Other</MenuItem>
            </Select>
          </FormControl>
        </Grid>

        {/* ----------------------------------Parent's Details--------------------- */}
        <Grid item xs={12}>
          <Typography variant="h6" color="#0288d1" textTransform="uppercase">
            Parent's Details
          </Typography>
        </Grid>

        <Grid item xs={12} md={6}>
          <TextField
            InputLabelProps={{ shrink: true }}
            id="outlined-Name-flexible"
            label="Father Name"
            type="text"
            name="fatherName"
            value={detail?.studentData?.fatherName}
            error={Boolean(errors?.fatherName)}
            helperText={errors?.fatherName}
            sx={{ background: "white" }}
            onChange={(e) => {
              validationHandler(e, "alphabetsAndSpace");
              handleDetails(e);
            }}
            fullWidth
          />
        </Grid>

        <Grid item xs={12} md={6}>
          <TextField
            InputLabelProps={{ shrink: true }}
            id="outlined-Name-flexible"
            label="Mother Name"
            type="text"
            name="motherName"
            value={detail?.studentData?.motherName}
            error={Boolean(errors?.motherName)}
            helperText={errors?.motherName}
            sx={{ background: "white" }}
            onChange={(e) => {
              validationHandler(e, "alphabetsAndSpace");
              handleDetails(e);
            }}
            fullWidth
          />
        </Grid>

        <Grid item xs={12} md={6}>
          <TextField
            InputLabelProps={{ shrink: true }}
            id="Annual_Income-flexible"
            label="Annual Income"
            name="annualIncome"
            value={detail?.studentData?.annualIncome}
            error={Boolean(errors?.annualIncome)}
            helperText={errors?.annualIncome}
            sx={{ background: "white" }}
            onChange={(e) => {
              validationHandler(e, "numeric");
              handleDetails(e);
            }}
            fullWidth
          />
        </Grid>

        <Grid item xs={12} md={6}>
          <TextField
            InputLabelProps={{ shrink: true }}
            id="outlined-Parent's_Mobile-flexible"
            label="Parent's Mobile"
            name="parentMobileNumber"
            fullWidth
            value={detail?.studentData?.parentMobileNumber}
            error={Boolean(errors?.parentMobileNumber)}
            helperText={errors?.parentMobileNumber}
            sx={{ background: "white" }}
            onChange={(e) => {
              validationHandler(e, "mobile");
              handleDetails(e);
            }}
          />
        </Grid>

        <Grid item xs={12} md={6}>
          <TextField
            InputLabelProps={{ shrink: true }}
            id="outlined-Parent's_Email-flexible"
            label="Parent's Email"
            type="Email"
            fullWidth
            name="parentEmail"
            value={detail?.studentData?.parentEmail}
            error={Boolean(errors?.parentEmail)}
            helperText={errors?.parentEmail}
            sx={{ background: "white" }}
            onChange={(e) => {
              validationHandler(e, "email");
              handleDetails(e);
            }}
          />
        </Grid>
        {/* ----------------------------Present Address-------------------------- */}
        <Grid item xs={12}>
          <Typography variant="h6" color="#0288d1" textTransform="uppercase">
            Present Address
          </Typography>
        </Grid>

        <Grid item xs={12} md={6}>
          <TextField
            InputLabelProps={{ shrink: true }}
            id="outlined-Address-flexible"
            label="Address 1"
            multiline
            fullWidth
            rows={2}
            type="text"
            name="presentAddress1"
            value={detail?.studentData?.presentAddress1}
            error={Boolean(errors?.presentAddress1)}
            helperText={errors?.presentAddress1}
            sx={{ background: "white" }}
            onChange={(e) => {
              validationHandler(e, "address");
              handleDetails(e);
            }}
          />
        </Grid>

        <Grid item xs={12} md={6}>
          <TextField
            InputLabelProps={{ shrink: true }}
            id="outlined-Address_Name-flexible"
            label="Address 2"
            fullWidth
            multiline
            rows={2}
            type="text"
            name="presentAddress2"
            value={detail?.studentData?.presentAddress2}
            error={Boolean(errors?.presentAddress2)}
            helperText={errors?.presentAddress2}
            sx={{ background: "white" }}
            onChange={(e) => {
              validationHandler(e, "address");
              handleDetails(e);
            }}
          />
        </Grid>

        <Grid item xs={12} md={6}>
          <FormControl fullWidth required>
            <InputLabel id="demo-simple-country-label">State</InputLabel>
            <Select
              labelId="demo-simple-country-label"
              name="presentState"
              value={detail?.studentData?.presentState || ""}
              label="State"
              onChange={handleDetails}
              fullWidth
            >
              {india.map((item: any) => (
                <MenuItem
                  value={item.state_name}
                  key={item.state_name}
                  id={item.state_id}
                >
                  {item.state_name}
                </MenuItem>
              ))}
            </Select>
          </FormControl>
        </Grid>

        <Grid item xs={12} md={6}>
          <FormControl fullWidth required>
            <InputLabel id="demo-simple-country-label">City</InputLabel>
            <Select
              labelId="demo-simple-country-label"
              name="presentCity"
              value={detail?.studentData?.presentCity || ""}
              onChange={handleDetails}
              className="form-control"
              label="State"
              fullWidth
            >
              {presentCity?.map((item: any) => (
                <MenuItem value={item.city} key={item.city} id={item.state_id}>
                  {item.city}
                </MenuItem>
              ))}
            </Select>
          </FormControl>
        </Grid>

        <Grid item xs={12} md={6}>
          <TextField
            InputLabelProps={{ shrink: true }}
            id="filled-password-input"
            label="Zip Code"
            fullWidth
            name="presentZipCode"
            value={detail?.studentData?.presentZipCode}
            error={Boolean(errors?.presentZipCode)}
            helperText={errors?.presentZipCode}
            sx={{ background: "white" }}
            onChange={(e) => {
              validationHandler(e, "zipcode");
              handleDetails(e);
            }}
          />
        </Grid>

        {/* --------------------- Permanent Address---------------------------------*/}
        <Grid item xs={12}>
          <Typography variant="h6" color="#0288d1" textTransform="uppercase">
            Permanent Address
          </Typography>
        </Grid>

        <Grid item xs={12} md={6}>
          <TextField
            InputLabelProps={{ shrink: true }}
            id="outlined-Address-flexible"
            label="Address 1"
            multiline
            fullWidth
            rows={2}
            type="text"
            name="permanentAddress1"
            value={detail?.studentData?.permanentAddress1}
            error={Boolean(errors?.permanentAddress1)}
            helperText={errors?.permanentAddress1}
            sx={{ background: "white" }}
            onChange={(e) => {
              validationHandler(e, "address");
              handleDetails(e);
            }}
          />
        </Grid>

        <Grid item xs={12} md={6}>
          <TextField
            InputLabelProps={{ shrink: true }}
            id="outlined-Address_Name-flexible"
            label="Address 2"
            fullWidth
            multiline
            rows={2}
            type="text"
            name="permanentAddress2"
            value={detail?.studentData?.permanentAddress2}
            error={Boolean(errors?.permanentAddress2)}
            helperText={errors?.permanentAddress2}
            sx={{ background: "white" }}
            onChange={(e) => {
              validationHandler(e, "address");
              handleDetails(e);
            }}
          />
        </Grid>

        <Grid item xs={12} md={6}>
          <FormControl fullWidth required>
            <InputLabel id="demo-simple-country-label">State</InputLabel>
            <Select
              labelId="demo-simple-country-label"
              name="permanentState"
              value={detail?.studentData?.permanentState || ""}
              label="State"
              onChange={handleDetails}
              fullWidth
            >
              {india.map((item: any) => (
                <MenuItem
                  value={item.state_name}
                  key={item.state_name}
                  id={item.state_id}
                >
                  {item.state_name}
                </MenuItem>
              ))}
            </Select>
          </FormControl>
        </Grid>

        <Grid item xs={12} md={6}>
          <FormControl fullWidth required>
            <InputLabel id="demo-simple-country-label">City</InputLabel>
            <Select
              labelId="demo-simple-country-label"
              fullWidth
              label="State"
              name="permanentCity"
              value={detail?.studentData?.permanentCity || ""}
              onChange={handleDetails}
              className="form-control"
            >
              {permanentCity?.map((item: any) => (
                <MenuItem value={item.city} key={item.city} id={item.state_id}>
                  {item.city}
                </MenuItem>
              ))}
            </Select>
          </FormControl>
        </Grid>

        <Grid item xs={12} md={6}>
          <TextField
            InputLabelProps={{ shrink: true }}
            id="filled-password-input"
            label="Zip Code"
            name="permanentZipCode"
            fullWidth
            value={detail?.studentData?.permanentZipCode}
            error={Boolean(errors?.permanentZipCode)}
            helperText={errors?.permanentZipCode}
            sx={{ background: "white" }}
            onChange={(e) => {
              validationHandler(e, "zipcode");
              handleDetails(e);
            }}
          />
        </Grid>

        {/*----------------Course Details---------------------*/}
        <Grid item xs={12}>
          <Typography variant="h6" color="#0288d1" textTransform="uppercase">
            Course Details
          </Typography>
        </Grid>

        <Grid item xs={12} md={6}>
          <TextField
            InputLabelProps={{ shrink: true }}
            id="outlined-Contact-flexible"
            label="University Enrollment Number"
            name="universityEnrNo"
            type="text"
            fullWidth
            value={detail?.courseData?.universityEnrNo}
            error={Boolean(errors?.universityEnrNo)}
            helperText={errors?.universityEnrNo}
            sx={{ background: "white" }}
            onChange={(e) => {
              validationHandler(e, "alphanumeric");
              handleDetails(e);
            }}
          />
        </Grid>

        <Grid item xs={12} md={6}>
          <TextField
            InputLabelProps={{ shrink: true }}
            id="outlined-Date-flexible"
            label="Admission Date"
            fullWidth
            type="date"
            name="admissionDate"
            value={detail?.courseData?.admissionDate}
            sx={{ background: "white" }}
            onChange={handleDetails}
          />
        </Grid>
        {/* ----------------------------- Academic Details Table---------------- */}
        <Grid item xs={12}>
          <TableContainer className="scrollBarCss">
            <Table>
              <TableHead>
                <TableRow>
                  <TableCell sx={{ border: "1px solid black" }}>
                    Name of Qualifying Degree
                  </TableCell>

                  <TableCell sx={{ border: "1px solid black" }}>
                    Year of Passing
                  </TableCell>

                  <TableCell sx={{ border: "1px solid black", minWidth: 300 }}>
                    Board/ University
                  </TableCell>

                  <TableCell sx={{ border: "1px solid black", minWidth: 150 }}>
                    Division / Grade
                  </TableCell>

                  <TableCell sx={{ border: "1px solid black", minWidth: 150 }}>
                    Percentage/ CGPA
                  </TableCell>

                  <TableCell sx={{ border: "1px solid black", minWidth: 150 }}>
                    Obtained Max/ Total Max
                  </TableCell>
                </TableRow>
              </TableHead>

              <TableBody>
                <TableRow sx={{ mb: "1" }}>
                  <TableCell sx={{ border: "1px solid black" }}>10TH</TableCell>

                  <TableCell sx={{ border: "1px solid black" }}>
                    <TextField
                      required
                      fullWidth
                      name="sscYear"
                      type="month"
                      value={detail?.academicData?.sscYear}
                      onChange={handleDetails}
                      sx={{ background: "white" }}
                    />
                  </TableCell>

                  <TableCell sx={{ border: "1px solid black" }}>
                    <TextField
                      required
                      fullWidth
                      name="sscBoardUniversity"
                      value={detail?.academicData?.sscBoardUniversity}
                      error={Boolean(errors?.sscBoardUniversity)}
                      helperText={errors?.sscBoardUniversity}
                      sx={{ background: "white" }}
                      onChange={(e) => {
                        validationHandler(e, "alphabet");
                        handleDetails(e);
                      }}
                    />
                  </TableCell>

                  <TableCell sx={{ border: "1px solid black" }}>
                    <TextField
                      required
                      fullWidth
                      name="sscDivisionGrade"
                      value={detail?.academicData?.sscDivisionGrade}
                      error={Boolean(errors?.sscDivisionGrade)}
                      helperText={errors?.sscDivisionGrade}
                      sx={{ background: "white" }}
                      onChange={(e) => {
                        validationHandler(e, "alphabet");
                        handleDetails(e);
                      }}
                    />
                  </TableCell>

                  <TableCell sx={{ border: "1px solid black" }}>
                    <TextField
                      required
                      fullWidth
                      name="sscPercentageCGPA"
                      type="number"
                      value={detail?.academicData?.sscPercentageCGPA}
                      error={Boolean(errors?.sscPercentageCGPA)}
                      helperText={errors?.sscPercentageCGPA}
                      sx={{ background: "white" }}
                      onChange={(e) => {
                        validationHandler(e, "number");
                        handleDetails(e);
                      }}
                    />
                  </TableCell>

                  <TableCell sx={{ border: "1px solid black" }}>
                    <TextField
                      required
                      fullWidth
                      type="number"
                      name="sscObtainedMaxTotalMax"
                      value={detail?.academicData?.sscObtainedMaxTotalMax}
                      error={Boolean(errors?.sscObtainedMaxTotalMax)}
                      helperText={errors?.sscObtainedMaxTotalMax}
                      sx={{ background: "white" }}
                      onChange={(e) => {
                        validationHandler(e, "number");
                        handleDetails(e);
                      }}
                    />
                  </TableCell>
                </TableRow>

                <TableRow sx={{ mb: "1" }}>
                  <TableCell sx={{ border: "1px solid black" }}>12TH</TableCell>

                  <TableCell sx={{ border: "1px solid black" }}>
                    <TextField
                      required
                      fullWidth
                      placeholder="Year"
                      type="month"
                      name="hscYear"
                      value={detail?.academicData?.hscYear}
                      sx={{ background: "white" }}
                      onChange={handleDetails}
                    />
                  </TableCell>

                  <TableCell sx={{ border: "1px solid black" }}>
                    <TextField
                      required
                      fullWidth
                      name="hscBoardUniversity"
                      value={detail?.academicData?.hscBoardUniversity}
                      error={Boolean(errors?.hscBoardUniversity)}
                      helperText={errors?.hscBoardUniversity}
                      sx={{ background: "white" }}
                      onChange={(e) => {
                        validationHandler(e, "alphabet");
                        handleDetails(e);
                      }}
                    />
                  </TableCell>

                  <TableCell sx={{ border: "1px solid black" }}>
                    <TextField
                      required
                      fullWidth
                      name="hscDivisionGrade"
                      value={detail?.academicData?.hscDivisionGrade}
                      error={Boolean(errors?.hscDivisionGrade)}
                      helperText={errors?.hscDivisionGrade}
                      sx={{ background: "white" }}
                      onChange={(e) => {
                        validationHandler(e, "alphabet");
                        handleDetails(e);
                      }}
                    />
                  </TableCell>

                  <TableCell sx={{ border: "1px solid black" }}>
                    <TextField
                      required
                      fullWidth
                      name="hscPercentageCGPA"
                      type="number"
                      value={detail?.academicData?.hscPercentageCGPA}
                      error={Boolean(errors?.hscPercentageCGPA)}
                      helperText={errors?.hscPercentageCGPA}
                      sx={{ background: "white" }}
                      onChange={(e) => {
                        validationHandler(e, "number");
                        handleDetails(e);
                      }}
                    />
                  </TableCell>

                  <TableCell sx={{ border: "1px solid black" }}>
                    <TextField
                      required
                      fullWidth
                      type="number"
                      name="hscObtainedMaxTotalMax"
                      value={detail?.academicData?.hscObtainedMaxTotalMax}
                      error={Boolean(errors?.hscObtainedMaxTotalMax)}
                      helperText={errors?.hscObtainedMaxTotalMax}
                      sx={{ background: "white" }}
                      onChange={(e) => {
                        validationHandler(e, "number");
                        handleDetails(e);
                      }}
                    />
                  </TableCell>
                </TableRow>
                <TableRow sx={{ mb: "1" }}>
                  <TableCell sx={{ border: "1px solid black" }}>
                    Graduation
                  </TableCell>

                  <TableCell sx={{ border: "1px solid black" }}>
                    <TextField
                      required
                      fullWidth
                      placeholder="Year"
                      type="month"
                      name="graduationYear"
                      value={detail?.academicData?.graduationYear}
                      sx={{ background: "white" }}
                      onChange={handleDetails}
                    />
                  </TableCell>

                  <TableCell sx={{ border: "1px solid black" }}>
                    <TextField
                      required
                      fullWidth
                      name="graduationBoardUniversity"
                      value={detail?.academicData?.graduationBoardUniversity}
                      error={Boolean(errors?.graduationBoardUniversity)}
                      helperText={errors?.graduationBoardUniversity}
                      sx={{ background: "white" }}
                      onChange={(e) => {
                        validationHandler(e, "alphabet");
                        handleDetails(e);
                      }}
                    />
                  </TableCell>

                  <TableCell sx={{ border: "1px solid black" }}>
                    <TextField
                      required
                      fullWidth
                      name="graduationDivisionGrade"
                      value={detail?.academicData?.graduationDivisionGrade}
                      error={Boolean(errors?.graduationDivisionGrade)}
                      helperText={errors?.graduationDivisionGrade}
                      sx={{ background: "white" }}
                      onChange={(e) => {
                        validationHandler(e, "alphabet");
                        handleDetails(e);
                      }}
                    />
                  </TableCell>

                  <TableCell sx={{ border: "1px solid black" }}>
                    <TextField
                      required
                      fullWidth
                      type="number"
                      name="graduationPercentageCGPA"
                      value={detail?.academicData?.graduationPercentageCGPA}
                      error={Boolean(errors?.graduationPercentageCGPA)}
                      helperText={errors?.graduationPercentageCGPA}
                      sx={{ background: "white" }}
                      onChange={(e) => {
                        validationHandler(e, "number");
                        handleDetails(e);
                      }}
                    />
                  </TableCell>

                  <TableCell sx={{ border: "1px solid black" }}>
                    <TextField
                      required
                      fullWidth
                      type="number"
                      name="graduationObtainedMaxTotalMax"
                      value={
                        detail?.academicData?.graduationObtainedMaxTotalMax
                      }
                      error={Boolean(errors?.graduationObtainedMaxTotalMax)}
                      helperText={errors?.graduationObtainedMaxTotalMax}
                      sx={{ background: "white" }}
                      onChange={(e) => {
                        validationHandler(e, "number");
                        handleDetails(e);
                      }}
                    />
                  </TableCell>
                </TableRow>
                <TableRow sx={{ mb: "1" }}>
                  <TableCell sx={{ border: "1px solid black" }}>
                    Post Graduation
                  </TableCell>

                  <TableCell sx={{ border: "1px solid black" }}>
                    <TextField
                      required
                      fullWidth
                      type="month"
                      placeholder="Year"
                      name="postGraduationYear"
                      value={detail?.academicData?.postGraduationYear}
                      sx={{ background: "white" }}
                      onChange={handleDetails}
                    />
                  </TableCell>

                  <TableCell sx={{ border: "1px solid black" }}>
                    <TextField
                      required
                      fullWidth
                      name="postGraduationBoardUniversity"
                      value={
                        detail?.academicData?.postGraduationBoardUniversity
                      }
                      error={Boolean(errors?.postGraduationBoardUniversity)}
                      helperText={errors?.postGraduationBoardUniversity}
                      sx={{ background: "white" }}
                      onChange={(e) => {
                        validationHandler(e, "alphabet");
                        handleDetails(e);
                      }}
                    />
                  </TableCell>

                  <TableCell sx={{ border: "1px solid black" }}>
                    <TextField
                      required
                      fullWidth
                      name="postGraduationDivisionGrade"
                      value={detail?.academicData?.postGraduationDivisionGrade}
                      error={Boolean(errors?.postGraduationDivisionGrade)}
                      helperText={errors?.postGraduationDivisionGrade}
                      sx={{ background: "white" }}
                      onChange={(e) => {
                        validationHandler(e, "alphabet");
                        handleDetails(e);
                      }}
                    />
                  </TableCell>

                  <TableCell sx={{ border: "1px solid black" }}>
                    <TextField
                      required
                      fullWidth
                      type="number"
                      name="postGraduationPercentageCGPA"
                      value={detail?.academicData?.postGraduationPercentageCGPA}
                      error={Boolean(errors?.postGraduationPercentageCGPA)}
                      helperText={errors?.postGraduationPercentageCGPA}
                      sx={{ background: "white" }}
                      onChange={(e) => {
                        validationHandler(e, "number");
                        handleDetails(e);
                      }}
                    />
                  </TableCell>

                  <TableCell sx={{ border: "1px solid black" }}>
                    <TextField
                      required
                      fullWidth
                      type="number"
                      name="postGraduationObtainedMaxTotalMax"
                      value={
                        detail?.academicData?.postGraduationObtainedMaxTotalMax
                      }
                      error={Boolean(errors?.postGraduationObtainedMaxTotalMax)}
                      helperText={errors?.postGraduationObtainedMaxTotalMax}
                      sx={{ background: "white" }}
                      onChange={(e) => {
                        validationHandler(e, "number");
                        handleDetails(e);
                      }}
                    />
                  </TableCell>
                </TableRow>
              </TableBody>
            </Table>
          </TableContainer>
        </Grid>
        {/* -------------------Dropdowns------------------------------------------ */}
        <Grid item xs={6} sm={3}>
          <FormControl fullWidth>
            <InputLabel id="demo-simple-select-label">Id Card</InputLabel>
            <Select
              labelId="demo-simple-select-label"
              id="demo-simple-select"
              label="Id Card"
              name="idCard"
              value={detail?.idCard || ""}
              onChange={handleDetails}
            >
              <MenuItem value={"No"}>No</MenuItem>
              <MenuItem value={"Yes"}>Yes</MenuItem>
            </Select>
          </FormControl>
        </Grid>

        <Grid item xs={6} sm={3}>
          <FormControl fullWidth>
            <InputLabel id="demo-simple-select-label">Books</InputLabel>
            <Select
              labelId="demo-simple-select-label"
              id="demo-simple-select"
              label="Books"
              name="books"
              value={detail?.books || ""}
              onChange={handleDetails}
            >
              <MenuItem value={"No"}>No</MenuItem>
              <MenuItem value={"Yes"}>Yes</MenuItem>
            </Select>
          </FormControl>
        </Grid>

        <Grid item xs={6} sm={3}>
          <FormControl fullWidth>
            <InputLabel id="demo-simple-select-label">
              Hall ticket/Admit Card
            </InputLabel>
            <Select
              labelId="demo-simple-select-label"
              id="demo-simple-select"
              label="Hall ticket/Admit Card"
              name="hallTicketOrAdmitCard"
              value={detail?.hallTicketOrAdmitCard || ""}
              onChange={handleDetails}
            >
              <MenuItem value={"No"}>No</MenuItem>
              <MenuItem value={"Yes"}>Yes</MenuItem>
            </Select>
          </FormControl>
        </Grid>

        <Grid item xs={6} sm={3}>
          <FormControl fullWidth>
            <InputLabel id="demo-simple-select-label">Result Online</InputLabel>
            <Select
              labelId="demo-simple-select-label"
              id="demo-simple-select"
              label="Result Online"
              name="resultOnline"
              value={detail?.resultOnline || ""}
              onChange={handleDetails}
            >
              <MenuItem value={"No"}>No</MenuItem>
              <MenuItem value={"Yes"}>Yes</MenuItem>
            </Select>
          </FormControl>
        </Grid>

        <Grid item xs={6} sm={3}>
          <TextField
            InputLabelProps={{ shrink: true }}
            type="date"
            sx={{ width: "100% !Important", background: "white" }}
            label="Id card Issue Date"
            name="iCardIssueDate"
            value={
              detail?.iCardIssueDate
                ? new Date(detail?.iCardIssueDate)?.toISOString().slice(0, 10)
                : ""
            }
            onChange={handleDetails}
          />
        </Grid>
        <Grid item xs={6} sm={3}>
          <TextField
            InputLabelProps={{ shrink: true }}
            type="date"
            sx={{ width: "100% !Important", background: "white" }}
            label="Book Issue Date"
            name="bookIssueDate"
            value={
              detail?.bookIssueDate
                ? new Date(detail?.bookIssueDate)?.toISOString().slice(0, 10)
                : ""
            }
            onChange={handleDetails}
          />
        </Grid>
        <Grid item xs={6} sm={3}>
          <TextField
            required
            InputLabelProps={{ shrink: true }}
            type="date"
            sx={{ width: "100% !Important", background: "white" }}
            label="Hall ticket Issue Date"
            name="hallTicketIssueDate"
            value={
              detail?.hallTicketIssueDate
                ? new Date(detail?.hallTicketIssueDate)
                    ?.toISOString()
                    .slice(0, 10)
                : ""
            }
            onChange={handleDetails}
          />
        </Grid>
        <Grid item xs={6} sm={3}>
          <TextField
            required
            InputLabelProps={{ shrink: true }}
            type="date"
            sx={{ width: "100% !Important", background: "white" }}
            label="Result Online Issue Date"
            name="resultOnlineIssueDate"
            value={
              detail?.resultOnlineIssueDate
                ? new Date(detail?.resultOnlineIssueDate)
                    ?.toISOString()
                    .slice(0, 10)
                : ""
            }
            onChange={handleDetails}
          />
        </Grid>

        <Grid item xs={6} sm={3}>
          <FormControl fullWidth>
            <InputLabel id="demo-simple-select-label">
              Result Offline
            </InputLabel>
            <Select
              labelId="demo-simple-select-label"
              id="demo-simple-select"
              label="Result Offline"
              name="resultOffline"
              value={detail?.resultOffline || ""}
              onChange={handleDetails}
            >
              <MenuItem value={"No"}>No</MenuItem>
              <MenuItem value={"Yes"}>Yes</MenuItem>
            </Select>
          </FormControl>
        </Grid>
        <Grid item xs={6} sm={3}>
          <FormControl fullWidth>
            <InputLabel id="demo-simple-select-label">Mark sheet</InputLabel>
            <Select
              labelId="demo-simple-select-label"
              id="demo-simple-select"
              label="Mark sheet"
              name="markSheet"
              value={detail?.markSheet || ""}
              onChange={handleDetails}
            >
              <MenuItem value={"No"}>No</MenuItem>
              <MenuItem value={"Yes"}>Yes</MenuItem>
            </Select>
          </FormControl>
        </Grid>
        <Grid item xs={6} sm={3}>
          <FormControl fullWidth>
            <InputLabel id="demo-simple-select-label">
              Provisional Certificate
            </InputLabel>
            <Select
              labelId="demo-simple-select-label"
              id="demo-simple-select"
              label="Provisional Certificate"
              name="provisionalCertificate"
              value={detail?.provisionalCertificate || ""}
              onChange={handleDetails}
            >
              <MenuItem value={"No"}>No</MenuItem>
              <MenuItem value={"Yes"}>Yes</MenuItem>
            </Select>
          </FormControl>
        </Grid>
        <Grid item xs={6} sm={3}>
          <FormControl fullWidth>
            <InputLabel id="demo-simple-select-label">
              Migration Certificate
            </InputLabel>
            <Select
              labelId="demo-simple-select-label"
              id="demo-simple-select"
              label="Migration Certificate"
              name="migrationCertificate"
              value={detail?.migrationCertificate || ""}
              onChange={handleDetails}
            >
              <MenuItem value={"No"}>No</MenuItem>
              <MenuItem value={"Yes"}>Yes</MenuItem>
            </Select>
          </FormControl>
        </Grid>

        <Grid item xs={6} sm={3}>
          <TextField
            required
            InputLabelProps={{ shrink: true }}
            type="date"
            sx={{ width: "100% !Important", background: "white" }}
            label="Result Offline Issue Date"
            name="resultOfflineIssueDate"
            value={
              detail?.resultOfflineIssueDate
                ? new Date(detail?.resultOfflineIssueDate)
                    ?.toISOString()
                    .slice(0, 10)
                : ""
            }
            onChange={handleDetails}
          />
        </Grid>
        <Grid item xs={6} sm={3}>
          <TextField
            required
            InputLabelProps={{ shrink: true }}
            type="date"
            sx={{ width: "100% !Important", background: "white" }}
            label="Mark sheet Issue Date"
            name="markSheetIssueDate"
            value={
              detail?.markSheetIssueDate
                ? new Date(detail?.markSheetIssueDate)
                    ?.toISOString()
                    .slice(0, 10)
                : ""
            }
            onChange={handleDetails}
          />
        </Grid>
        <Grid item xs={6} sm={3}>
          <TextField
            required
            InputLabelProps={{ shrink: true }}
            type="date"
            sx={{ width: "100% !Important", background: "white" }}
            label="Provisional certificate Issue Date"
            name="provisionalCertificateIssueDate"
            value={
              detail?.provisionalCertificateIssueDate
                ? new Date(detail?.provisionalCertificateIssueDate)
                    ?.toISOString()
                    .slice(0, 10)
                : ""
            }
            onChange={handleDetails}
          />
        </Grid>
        <Grid item xs={6} sm={3}>
          <TextField
            required
            InputLabelProps={{ shrink: true }}
            type="date"
            sx={{ width: "100% !Important", background: "white" }}
            label="Migration Certificate Issue Date"
            name="migrationCertificateIssueDate"
            value={
              detail?.migrationCertificateIssueDate
                ? new Date(detail?.migrationCertificateIssueDate)
                    ?.toISOString()
                    .slice(0, 10)
                : ""
            }
            onChange={handleDetails}
          />
        </Grid>

        <Grid item xs={6} sm={3}>
          <FormControl fullWidth>
            <InputLabel id="demo-simple-select-label">
              Final Degree/Diploma
            </InputLabel>
            <Select
              labelId="demo-simple-select-label"
              id="demo-simple-select"
              label="Final Degree/Diploma"
              name="finalDegreeOrDiploma"
              value={detail?.finalDegreeOrDiploma || ""}
              onChange={handleDetails}
            >
              <MenuItem value={"No"}>No</MenuItem>
              <MenuItem value={"Yes"}>Yes</MenuItem>
            </Select>
          </FormControl>
        </Grid>

        <Grid item xs={6} sm={3}>
          <TextField
            required
            InputLabelProps={{ shrink: true }}
            type="date"
            sx={{ width: "100% !Important", background: "white" }}
            label="Final Degree/Diploma Issue Date"
            name="finalDegreeOrDiplomaIssueDate"
            value={
              detail?.finalDegreeOrDiplomaIssueDate
                ? new Date(detail?.finalDegreeOrDiplomaIssueDate)
                    ?.toISOString()
                    .slice(0, 10)
                : ""
            }
            onChange={handleDetails}
          />
        </Grid>

        <Grid item xs={12}>
          <Typography>Declaration by the applicant-</Typography>

          <Typography>
            <Checkbox
              {...label}
              checked={isChecked1}
              onChange={handleCheckboxChange1}
            />
            I hereby declare that all particulars in this application are true
            to the best of my knowledge and belief.
          </Typography>
          <Typography>
            <Checkbox
              {...label}
              checked={isChecked2}
              onChange={handleCheckboxChange2}
            />
            I understand that the fees once paid will not be refunded or
            adjusted.
          </Typography>

          <Button
            variant="contained"
            type="submit"
            onClick={updateData}
            disabled={!isChecked1 || !isChecked2}
          >
            Submit
          </Button>
        </Grid>
        <CommonSnackBar message={snackMessage} open={openSnackBar} />
      </Grid>
    </Paper>
  );
}
