import React from "react";
import {
  CardContent,
  Card,
  Box,
  Container,
  ImageList,
  ImageListItem,
} from "@mui/material";
import Typography from "@mui/material/Typography";
import Grid from "@mui/material/Grid";
import { styled } from "@mui/material/styles";
import Paper from "@mui/material/Paper";
import "../Home/Home.scss";
import { makeStyles } from "@mui/styles";

const useStyles = makeStyles({
  root: {
    backgroundImage:
      'url("https://ik.imagekit.io/nwssoft/Explore_degree/blue-abstraction-background-with-transparency-lines-vector_162862-262.avif?updatedAt=1685436305096")',
    backgroundSize: "cover",
    backgroundRepeat: "no-repeat",
    backgroundPosition: "center",
  },
});

export default function BasicCard() {
  const classes = useStyles();
  return (
    <>
      <Container>
        <Box sx={{ flexGrow: 1, my: 3 }} className={classes.root}>
          <Grid container spacing={2}>
            <Grid item xs={12} sm={12} md={5} sx={{ mt: 20 }}>
              <Grid item xs={12}>
                <Typography
                  variant="h3"
                  sx={{
                    color: "#212121",
                    textAlign: "start",
                    ml: 3,
                    fontWeight: "bold",
                  }}
                >
                  Education is the best <br></br>key success in life
                </Typography>
              </Grid>
              <Grid
                item
                xs={12}
                sx={{ mb: 5, ml: 3, mt: 2, textAlign: "start" }}
              >
                <Typography sx={{ color: "#212121", mb: 2 }} variant="h6">
                  We Offer{" "}
                </Typography>
                <Typography variant="h4" sx={{ color: "#212121" }}>
                  Explore Our Degrees
                </Typography>
              </Grid>
            </Grid>
            <Grid item xs={12} sm={12} md={7}>
              <ImageList sx={{ width: "100%", height: "100%" }} cols={1}>
                <ImageListItem>
                  <img
                    src="https://ik.imagekit.io/nwssoft/Explore_degree/expdegree.png?updatedAt=1685600868605"
                    loading="lazy"
                    alt="Image 1"
                  />
                </ImageListItem>
              </ImageList>
            </Grid>
            <Grid item xs={12} container spacing={3} sx={{ mt: 3 }}>
              <Grid item xs={12} sm={12} md={3} sx={{ mt: 2 }}>
                <Box className="itemBox" sx={{ p: 5, flex: 1 }}>
                  <Typography variant="h5" justifyContent="center">
                    Post Graduate
                  </Typography>
                  <Typography justifyContent="center">
                    Based on the eligibility criteria we offer a range of long
                    distance courses of MSc, MCA, M.Com and MBA.
                  </Typography>
                </Box>
              </Grid>
              <Grid item xs={12} sm={12} md={3} sx={{ mt: 2 }}>
                <Box className="itemBox" sx={{ p: 5, flex: 1 }}>
                  <Typography variant="h5" justifyContent="center">
                    {" "}
                    Under Graduate
                  </Typography>
                  <Typography justifyContent="center">
                    Our institute specializes in providing a range of programs
                    that include BBA, BCA, BA as well as B.Com
                  </Typography>
                </Box>
              </Grid>
              <Grid item xs={12} sm={12} md={3} sx={{ mt: 2 }}>
                <Box className="itemBox" sx={{ p: 5, flex: 1 }}>
                  <Typography variant="h5" justifyContent="center">
                    Diploma
                  </Typography>
                  <Typography justifyContent="center">
                    We offer wide range of diploma programs for IT, French
                    Language, Forex Management & Business Management.
                  </Typography>
                </Box>
              </Grid>
              <Grid item xs={12} sm={12} md={3} sx={{ mt: 2 }}>
                <Box className="itemBox" sx={{ p: 5, flex: 1 }}>
                  <Typography variant="h5" justifyContent="center">
                    Certification
                  </Typography>
                  <Typography justifyContent="center">
                    We offer ways to enhance your resume via certifications like
                    Advertising, Big Data Analytic and more.
                  </Typography>
                </Box>
              </Grid>
            </Grid>
          </Grid>
        </Box>
      </Container>
    </>
  );
}
