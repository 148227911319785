import {
  Button,
  Container,
  FormControl,
  Grid,
  InputLabel,
  LinearProgress,
  MenuItem,
  Paper,
  Select,
  TextField,
  Typography,
} from "@mui/material/";
import { useEffect, useState } from "react";
import CommonSnackBar from "../Components/Common/CommonSnackbar";
import useValidation from "../Hooks/useTypescriptValidation";
import { getRequest, postRequestMethod } from "../api/api";
import { FEEDBACK_FORM_GET, FEEDBACK_FORM_POST } from "../api/server";

interface feedbackFormData {
  empId: string;
  studentId: String;
  empName: String;
  complain: string;
  feedback: string;
}

export default function FeedbackForm(props: any) {
  const { eventHandler } = useValidation();
  const { lId } = props.types;

  const [detail, setDetail] = useState<any>();
  const [openSnackBar, setOpenSnackBar] = useState(false);
  const [snackMessage, setSnackMessage] = useState<string>("");
  const [isLoading, setIsLoading] = useState(false);

  const [data, setData] = useState<feedbackFormData>({
    empId: "",
    studentId: lId,
    empName: "",
    complain: "",
    feedback: "",
  });

  const [errors, setErrors] = useState<{ [key: string]: string }>({
    complain: "",
    feedback: "",
  });

  const validationHandler = async (e: any, alterName?: any) => {
    const val = e.target.value;
    const id = alterName;
    if (id) {
      let prom = new Promise((resolve) => {
        if (true) {
          resolve(eventHandler(id, val));
        }
      });
      prom.then((res) => {
        setErrors({ ...errors, [e.target.name]: res });
      });
    }
    setData({ ...data, [e.target.name]: val });
  };

  const handleOpenSnackBar = () => {
    setOpenSnackBar(true);
    setTimeout(() => {
      setOpenSnackBar(false);
    }, 3000);
  };

  const validator = (errors: { [key: string]: string }) => {
    for (let field in errors) {
      if (errors[field] !== "") {
        return false;
      }
    }
    return true;
  };

  const handleSubmit = async (event: any) => {
    event.preventDefault();
    setIsLoading(true);
    if (validator(errors)) {
      await postRequestMethod(FEEDBACK_FORM_POST, "", data)
        .then((resp: any) => {
          if (resp) {
            setIsLoading(false);
            setSnackMessage(resp.data);
            handleOpenSnackBar();
            formClear();
          } else {
            setIsLoading(false);
            setSnackMessage("Something went wrong");
            handleOpenSnackBar();
          }
        })
        .catch((error) => {
          console.error("Error fetching data:", error);
        });
    }
  };

  // for getting employee dropdown list
  const getStudentData = async () => {
    await getRequest(FEEDBACK_FORM_GET, "")
      .then((resp: any) => {
        setDetail(resp.data);
      })
      .catch((error) => {
        console.error("Error fetching data:", error);
      });
  };

  const handleSelectionChange = (e: any) => {
    const selectedDetail = detail.find(
      (item: any) => item.empId === e.target.value
    );
    if (selectedDetail) {
      const { firstName, lastName, empId } = selectedDetail;
      setData({ ...data, empId, empName: firstName + " " + lastName });
    }
  };

  const formClear = () => {
    setData({
      empId: "",
      studentId: lId,
      empName: "",
      complain: "",
      feedback: "",
    });
    setErrors({
      complain: "",
      feedback: "",
    });
  };

  useEffect(() => {
    getStudentData();
  }, []);

  return (
    <Container component="main" maxWidth="xl">
      {isLoading && <LinearProgress />}
      <Paper elevation={20} component="form" onSubmit={handleSubmit}>
        <Grid container spacing={2} padding={3}>
          <Grid item xs={12}>
            <Typography
              component="h1"
              variant="h6"
              color="#0288d1"
              align="center"
              textTransform="uppercase"
            >
              Complain & Feedback
            </Typography>
          </Grid>

          <Grid item xs={12}>
            <Typography component="h1" variant="h6" color="#0288d1">
              Your Feedback Is Important to us
            </Typography>
          </Grid>

          <Grid item xs={12}>
            <Typography component="h1" variant="h6" color="#0288d1">
              We would like to understand more about your course behavior We
              would appreciate your candid and honest opinion which will help us
              improve our services in the future . please note that your
              responses will be kept completely confidential and will only be
              available is an aggregate form.
            </Typography>
          </Grid>

          <Grid item xs={12}>
            <FormControl fullWidth>
              <InputLabel id="demo-simple-country-label">
                Complain Against
              </InputLabel>
              <Select
                labelId="demo-simple-country-label"
                name="empId"
                className="form-control"
                label="Complain Against"
                defaultValue=""
                value={data.empId}
                onChange={handleSelectionChange}
                fullWidth
              >
                <MenuItem>--Select Employee--</MenuItem>
                {detail &&
                  detail?.map((detail: any) => (
                    <MenuItem
                      value={detail.empId}
                      key={detail._id}
                      id={detail._id}
                    >
                      {`${detail.firstName}(
                        ${detail.type})
                        ${detail.empId}`}
                    </MenuItem>
                  ))}
              </Select>
            </FormControl>
          </Grid>

          <Grid item xs={12}>
            <TextField
              id="filled-multiline-static"
              label="Your Complain"
              name="complain"
              placeholder="write your complain.."
              value={data.complain}
              multiline
              rows={4}
              fullWidth
              sx={{ background: "white" }}
              error={Boolean(errors.complain)}
              helperText={errors.complain}
              onChange={(e) => validationHandler(e, "message")}
            />
          </Grid>

          <Grid item xs={12}>
            <TextField
              required
              id="filled-multiline-static"
              label="Your Feedback"
              name="feedback"
              placeholder="write your feedback.."
              value={data.feedback}
              multiline
              rows={4}
              fullWidth
              sx={{ background: "white" }}
              error={Boolean(errors.feedback)}
              helperText={errors.feedback}
              onChange={(e) => validationHandler(e, "message")}
            />
          </Grid>

          <Grid item xs={12}>
            <Button
              type="submit"
              variant="contained"
              disabled={!validator(errors)}
            >
              Submit
            </Button>
            <CommonSnackBar message={snackMessage} open={openSnackBar} />
          </Grid>
        </Grid>
      </Paper>
    </Container>
  );
}
