import { Add, Close } from "@mui/icons-material";
import CloudDownloadIcon from "@mui/icons-material/CloudDownload";
import DeleteIcon from "@mui/icons-material/Delete";
import {
  Button,
  Checkbox,
  Container,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Grid,
  IconButton,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  TextField,
  Tooltip,
  Typography,
  tableCellClasses,
} from "@mui/material";
import { AxiosResponse } from "axios";
import moment from "moment";
import { ChangeEvent, Fragment, useEffect, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import "../../Components/Home/Home.scss";
import useTypescriptValidation from "../../Hooks/useTypescriptValidation";
import {
  deleteRequest,
  getRequestById,
  updateRequest
} from "../../api/api";
import {
  DELETE_EMPLOYEE_FILES,
  GET_EMPLOYEE_DETAILS,
  UPDATE_EMPLOYEE
} from "../../api/server";
import CommonSnackBar from "../Common/CommonSnackbar";
import "./EmployeeInfo.scss";

interface EducationalDetails {
  id: number;
  college: string;
  university: string;
  year: string;
  degree: string;
  specialization: string;
  percentage?: number;
}
interface Experience {
  id: number;
  organization: string;
  from: string;
  to: string;
  expYear: string;
  designation: string;
  duties: string;
  reason: string;
}
interface Languages {
  id: number;
  language: string;
  read: boolean;
  write: boolean;
  speak: boolean;
}

export default function EmployeeInfo() {
  const currentDate = moment().format("YYYY-MM-DD");
  const { eventHandler } = useTypescriptValidation();
  const navigate = useNavigate();
  const location = useLocation();

  // state to hold educational section list
  const [educationList, setEducationList] = useState<EducationalDetails[]>([
    {
      id: 1,
      college: "",
      university: "",
      year: "",
      degree: "",
      specialization: "",
      percentage: undefined,
    },
  ]);

  // state to hold experience section list
  const [experience, setExperience] = useState<Experience[]>([
    {
      id: 1,
      organization: "",
      from: "",
      to: "",
      expYear: "",
      designation: "",
      duties: "",
      reason: "",
    },
  ]);

  // state to hold language section list
  const [language, setLanguage] = useState<Languages[]>([
    {
      id: 1,
      language: "",
      read: false,
      write: false,
      speak: false,
    },
  ]);

  // to show selected & fetched photo & signature
  const [fetchPhoto, setFetchPhoto] = useState<string>("");
  const [fetchSign, setFetchSign] = useState<string>("");

  // state to hold fetched upload document list
  const [uploadDocumentList, setUploadDocumentList] = useState<any[]>([]);

  // to hold photo & signature object to send after form submission
  const [photo, setPhoto] = useState<File>(new File([], ""));
  const [signature, setSignature] = useState<File>(new File([], ""));

  // to hold upload documents & send after form submission
  const [fileInputs, setFileInputs] = useState<File[]>([new File([], "")]);

  // state for snack message & hide/show
  const [openSnackBar, setOpenSnackBar] = useState<boolean>(false);
  const [snackMessage, setSnackMessage] = useState<string>("");

  // state for declaration checkbox
  const [acceptChecked, setAcceptChecked] = useState<boolean>(false);

  // state for dialog open
  const [open, setOpen] = useState<boolean>(false);
  const [selectedFile, setSelectedFile] = useState<any>(null);

  // state for updating data fields
  const [data, setData] = useState<any>({
    fatherName: "",
    nationality: "",
    maritalStatus: "",
    permanentAddress: "",
    currentAddress: "",
    contact: "",
    skills: "",
    hobbies: "",
  });

  // state for errors and validation
  const [errors, setErrors] = useState<any>({
    fatherName: "",
    nationality: "",
    maritalStatus: "",
    permanentAddress: "",
    currentAddress: "",
    contact: "",
    skills: "",
    hobbies: "",
    college: "",
    university: "",
    year: "",
    degree: "",
    specialization: "",
    percentage: "",
    organization: "",
    from: "",
    to: "",
    expYear: "",
    designation: "",
    duties: "",
    reason: "",
    language: "",
  });

  // function for handling snackbar hide/show
  const handleOpenSnackBar = () => {
    setOpenSnackBar(true);
    setTimeout(() => {
      setOpenSnackBar(false);
    }, 5000);
  };

  // function to fetch Employee info from employee schema
  const getEmployeeInfo = async () => {
    let id = location.state?.chUniqueId
      ? location.state?.chUniqueId
      : location.state?.tlUniqueId
      ? location.state?.tlUniqueId
      : location.state?.exUniqueId;
    let response: void | AxiosResponse<any, any> = await getRequestById(
      GET_EMPLOYEE_DETAILS,
      id,
      ""
    );
    if (response?.data?.error) {
      setSnackMessage(response?.data?.error);
      handleOpenSnackBar();
    } else {
      showFetchedData(response?.data);
    }
  };

  // for showing the data that get from database
  const showFetchedData = (data: any) => {
    setData((preData: any) => ({
      ...preData,
      fatherName: data.fatherName,
      nationality: data.nationality,
      maritalStatus: data.maritalStatus,
      permanentAddress: data.permanentAddress,
      currentAddress: data.currentAddress,
      contact: data.contact,
      skills: data.skills,
      hobbies: data.hobbies,
    }));

    setEducationList(data.education);
    setExperience(data.experience);
    setLanguage(data.language);
    setUploadDocumentList(data.fileInputs);

    setFetchPhoto(data.photo.path);
    setFetchSign(data.signature.path);
  };

  // function for checking errors
  const validator = () => {
    for (let field in errors) {
      if (errors[field] !== "") {
        return true;
      }
    }
    return false;
  };

  // handle form submit
  const handleSubmit = async (e: any) => {
    e.preventDefault();
    if (validator()) {
      setSnackMessage("Fill correctly!");
      handleOpenSnackBar();
      return;
    }
    const formData = new FormData();

    for (const field in data) {
      formData.append(field, data[field]);
    }

    for (let i = 0; i < fileInputs.length; i++) {
      formData.append("additionalFiles", fileInputs[i]);
    }

    let stringEducationList = JSON.stringify(educationList);
    let stringExperienceList = JSON.stringify(experience);
    let stringLanguageList = JSON.stringify(language);

    formData.append("stringEducationList", stringEducationList);
    formData.append("stringExperienceList", stringExperienceList);
    formData.append("stringLanguageList", stringLanguageList);
    formData.append("photo", photo);
    formData.append("signature", signature);

    let lId = location?.state?.chUniqueId
      ? location?.state?.chUniqueId
      : location?.state?.tlUniqueId
      ? location?.state?.tlUniqueId
      : location?.state?.exUniqueId;

    let response: void | AxiosResponse<any, any> = await updateRequest(
      UPDATE_EMPLOYEE,
      lId,
      formData,
      "multipart/form-data"
    );

    if (response?.data?.message) {
      setSnackMessage(response?.data?.message);
      handleOpenSnackBar();
      getEmployeeInfo();
      setFileInputs([new File([], "")]);
    }

    if (response?.data?.error) {
      setSnackMessage(response?.data?.error);
      handleOpenSnackBar();
    }
  };

  const validationHandler = async (e: any, alterName?: any) => {
    try {
      const id = alterName;
      const val: string | File | undefined =
        alterName === "imageSize" || alterName === "pdfSize"
          ? e.target.files[0]
          : e.target.value;
      if (typeof val === "undefined") return;
      let prom = new Promise((resolve) => {
        if (true) {
          resolve(eventHandler(id, val));
        }
      });

      prom.then((res) => setErrors({ ...errors, [e.target.name]: res }));
    } catch (error) {
      console.error("An error occurred in validationHandler:", error);
    }
  };

  // function for handling photo & signature
  const handleImageChange = (e: any) => {
    try {
      let file = e.target.files[0];
      let extension = file?.name?.split(".").pop();
      if (
        extension === "jpg" ||
        extension === "jpeg" ||
        extension === "png" ||
        extension === "gif" ||
        extension === "bmp"
      ) {
        const imageUrl = URL.createObjectURL(file);
        if (e.target.name === "photo") {
          setFetchPhoto(imageUrl);
          setPhoto(file);
        } else {
          setFetchSign(imageUrl);
          setSignature(file);
        }
      } else {
        throw new Error(
          "Invalid file format. Please choose a valid image file."
        );
      }
    } catch (error) {
      console.error("Error handling image change:", error);
    }
  };

  // function for handling data change
  const handleDataChange = (e: any) => {
    setData((preData: any) => ({
      ...preData,
      [e.target.name]: e.target.value,
    }));
  };

  // function to add education row
  const addEducationRow = () => {
    const educationDetails: EducationalDetails = {
      id: educationList.length + 1,
      college: "",
      university: "",
      year: "",
      degree: "",
      specialization: "",
      percentage: undefined,
    };
    setEducationList([...educationList, educationDetails]);
  };

  // function to handle change education section
  const educationHandler = (e: any, id: number) => {
    const updatedRows = educationList.map((row) => {
      if (row.id === id) {
        return { ...row, [e.target.name]: e.target.value };
      }
      return row;
    });
    setEducationList(updatedRows);
  };

  // function to delete education row
  const deleteEducationRow = (id: number) => {
    const updatedRows = educationList.filter((row) => row.id !== id);
    setEducationList(updatedRows);
  };

  // function to add experience row
  const addExperienceRow = () => {
    const experienceDetails: Experience = {
      id: experience.length + 1,
      organization: "",
      from: "",
      to: "",
      expYear: "",
      designation: " ",
      duties: "",
      reason: "",
    };
    setExperience([...experience, experienceDetails]);
  };

  // function to handle change experience section
  const experienceHandler = (e: any, id: number) => {
    const updatedRows = experience.map((row) => {
      if (row.id === id) {
        return { ...row, [e.target.name]: e.target.value };
      }
      return row;
    });
    setExperience(updatedRows);
  };

  // function to delete experience row
  const deleteExperienceRow = (id: number) => {
    const updatedRows = experience.filter((row) => row.id !== id);
    setExperience(updatedRows);
  };

  // function to add language row
  const addLanguageRow = () => {
    const languageDetails: Languages = {
      id: language.length + 1,
      language: "",
      read: false,
      write: false,
      speak: false,
    };
    setLanguage([...language, languageDetails]);
  };

  // function to handle change language section
  const languageHandler = (e: any, id: number) => {
    let value =
      e.target.name === "language" ? e.target.value : e.target.checked;
    const updatedRows = language.map((row) => {
      if (row.id === id) {
        return { ...row, [e.target.name]: value };
      }
      return row;
    });
    setLanguage(updatedRows);
  };

  // function to delete language row
  const deleteLanguageRow = (id: number) => {
    const updatedRows = language.filter((row) => row.id !== id);
    setLanguage(updatedRows);
  };

  // function to add upload document field
  const addUploadField = () => {
    setFileInputs((prevFileInputs) => [...prevFileInputs, new File([], "")]);
  };

  // function to handle change upload document section
  const uploadDocumentHandler = (
    event: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>,
    index: number
  ) => {
    const files = (event.target as HTMLInputElement).files;
    if (files) {
      const arr: File[] = fileInputs;
      index <= arr.length ? (arr[index] = files[0]) : arr.push(files[0]);
      setFileInputs(arr);
    }
  };

  // function to delete upload document field
  const deleteUploadField = (index: number, name: string) => {
    setFileInputs((prevFileInputs) =>
      prevFileInputs.filter((_, i) => i !== index)
    );
    setErrors({ ...errors, [name]: "" });
  };

  // function to delete file
  const deleteRequestForFiles = async (fileId: string) => {
    let lId = location?.state?.chUniqueId
      ? location?.state?.chUniqueId
      : location?.state?.tlUniqueId
      ? location?.state?.tlUniqueId
      : location?.state?.exUniqueId;
    let ids = `${lId}/${fileId}`;
    await deleteRequest(DELETE_EMPLOYEE_FILES, ids, "application/json");
    handleClose();
    getEmployeeInfo();
  };

  // for open and close dialog
  const handleOpen = (file: any) => {
    setSelectedFile(file);
    setOpen(true);
  };

  const handleClose = () => {
    setSelectedFile(null);
    setOpen(false);
  };

  // function to handle change accept checkbox
  const handleAcceptCheckbox = (e: any) => {
    setAcceptChecked(e.target.checked);
  };

  useEffect(() => {
    getEmployeeInfo();
  }, []);

  return (
    <>
      <Container maxWidth="lg">
        <Grid
          item
          xs={12}
          component="form"
          encType="multipart/form-data"
          onSubmit={handleSubmit}
        >
          <Paper sx={{ padding: 5 }}>
            <Typography
              sx={{ textAlign: "center", mb: 5, color: "#0288d1" }}
              fontWeight="bold"
              variant="h5"
            >
              EMPLOYEE PROFILE
            </Typography>
            <Table
              sx={{
                [`& .${tableCellClasses.root}`]: {
                  borderBottom: "none",
                },
                border: "1px solid black",
              }}
            >
              <TableHead>
                <TableRow>
                  <TableCell
                    size="small"
                    colSpan={2}
                    sx={{ borderRight: "1px solid black" }}
                    align="center"
                  >
                    TO BE FILLED BY THE PANEL MEMBER
                  </TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                <TableRow>
                  <TableCell size="small" sx={{ border: "1px solid black" }}>
                    DATE OF INTERVIEW
                  </TableCell>
                  <TableCell size="small" sx={{ border: "1px solid black" }}>
                    {location.state.dateOfInterview
                      ? location.state.dateOfInterview
                      : "----"}
                  </TableCell>

                  <TableCell
                    size="small"
                    rowSpan={3}
                    sx={{ borderBottom: "none" }}
                  >
                    <TableCell size="small">
                      <Typography variant="body2" fontWeight="bold">
                        PHOTO
                      </Typography>
                    </TableCell>
                    <TableRow>
                      <TextField
                        size="small"
                        type="file"
                        name="photo"
                        id="photo"
                        error={Boolean(errors.photo)}
                        helperText={errors.photo}
                        inputProps={{ accept: "image/*" }}
                        sx={{ background: "white" }}
                        onChange={(e: any) => {
                          handleImageChange(e);
                          validationHandler(e, "imageSize");
                        }}
                      />
                    </TableRow>
                    <TableRow>
                      <img
                        src={fetchPhoto}
                        alt={"photo"}
                        style={{ height: 150, width: 350, marginTop: 10 }}
                      />
                    </TableRow>
                  </TableCell>
                </TableRow>
                <TableRow>
                  <TableCell size="small" sx={{ border: "1px solid black" }}>
                    JOB LOCATION
                  </TableCell>
                  <TableCell size="small" sx={{ border: "1px solid black" }}>
                    {location.state.jobLocation}
                  </TableCell>
                </TableRow>
                <TableRow>
                  <TableCell size="small" sx={{ border: "1px solid black" }}>
                    POST
                  </TableCell>
                  <TableCell size="small" sx={{ border: "1px solid black" }}>
                    {location.state.post}
                  </TableCell>
                </TableRow>
                <TableRow>
                  <TableCell size="small" sx={{ border: "1px solid black" }}>
                    EMPLOYEE CODE
                  </TableCell>
                  <TableCell size="small" sx={{ border: "1px solid black" }}>
                    {location.state.empId}
                  </TableCell>
                  <TableCell
                    size="small"
                    rowSpan={3}
                    sx={{ border: "1px solid black", alignSelf: "start" }}
                  >
                    <TableCell size="small">
                      <Typography variant="body2" fontWeight="bold">
                        SIGNATURE
                      </Typography>
                    </TableCell>

                    <TableRow>
                      <TextField
                        size="small"
                        type="file"
                        name="signature"
                        id="signature"
                        error={Boolean(errors.signature)}
                        helperText={errors.signature}
                        inputProps={{ accept: "image/*" }}
                        sx={{ background: "white" }}
                        onChange={(e: any) => {
                          handleImageChange(e);
                          validationHandler(e, "imageSize");
                        }}
                      />
                    </TableRow>
                    <TableRow>
                      <img
                        src={fetchSign}
                        alt={"sign"}
                        style={{ height: 150, width: 350, marginTop: 10 }}
                      />
                    </TableRow>
                  </TableCell>
                </TableRow>
                <TableRow>
                  <TableCell size="small" sx={{ border: "1px solid black" }}>
                    DATE OF JOINING
                  </TableCell>
                  <TableCell size="small" sx={{ border: "1px solid black" }}>
                    {location.state.dateOfJoining}
                  </TableCell>
                </TableRow>
                <TableRow>
                  <TableCell size="small" sx={{ border: "1px solid black" }}>
                    DATE OF TRAINING FROM
                  </TableCell>
                  <TableCell size="small" sx={{ border: "1px solid black" }}>
                    {location.state.trainingDateFrom
                      ? location.state.trainingDateFrom
                      : "----"}
                  </TableCell>
                </TableRow>
                <TableRow>
                  <TableCell size="small" sx={{ border: "1px solid black" }}>
                    DATE OF TRAINING TO
                  </TableCell>
                  <TableCell size="small" sx={{ border: "1px solid black" }}>
                    {location.state.trainingDateTo
                      ? location.state.trainingDateTo
                      : "----"}
                  </TableCell>
                </TableRow>
                <TableRow>
                  <TableCell size="small" sx={{ border: "1px solid black" }}>
                    OFFERED SALARY
                  </TableCell>
                  <TableCell size="small" sx={{ border: "1px solid black" }}>
                    {location.state.offeredSalary}
                  </TableCell>
                </TableRow>
              </TableBody>
            </Table>

            {/* Personal Details */}
            <Typography sx={{ mt: 3 }} variant="h5">
              PERSONAL DETAILS
            </Typography>
            <Grid container columnSpacing={{ xs: 5 }}>
              <Grid item xs={6} sx={{ mt: 2 }}>
                <TextField
                  sx={{ mt: 1, background: "white" }}
                  fullWidth
                  id="outlined-basic"
                  label="First Name"
                  variant="outlined"
                  name="firstName"
                  size="small"
                  disabled
                  value={location.state.firstName}
                />
              </Grid>
              <Grid item xs={6} sx={{ mt: 2 }}>
                <TextField
                  sx={{ mt: 1, background: "white" }}
                  fullWidth
                  id="outlined-basic"
                  label="Last Name"
                  variant="outlined"
                  name="lastName"
                  size="small"
                  disabled
                  value={location.state.lastName}
                />
              </Grid>
              <Grid item xs={6} sx={{ mt: 2 }}>
                <TextField
                  sx={{ mt: 1, background: "white" }}
                  required
                  fullWidth
                  id="outlined-basic"
                  label="Father Name"
                  variant="outlined"
                  name="fatherName"
                  size="small"
                  value={data.fatherName}
                  error={Boolean(errors.fatherName)}
                  helperText={errors.fatherName}
                  InputLabelProps={{ shrink: true }}
                  onChange={(e) => {
                    handleDataChange(e);
                    validationHandler(e, "alphabet");
                  }}
                />
              </Grid>
              <Grid item xs={6} sx={{ mt: 2 }}>
                <TextField
                  sx={{ mt: 1, background: "white" }}
                  fullWidth
                  id="DOB"
                  label="Date of Birth"
                  name="DOB"
                  type="date"
                  size="small"
                  disabled
                  value={location.state.DOB}
                />
              </Grid>
              <Grid item xs={6} sx={{ mt: 2 }}>
                <TextField
                  sx={{ mt: 1, background: "white" }}
                  required
                  fullWidth
                  id="outlined-basic"
                  label="Nationality"
                  variant="outlined"
                  name="nationality"
                  size="small"
                  value={data.nationality}
                  error={Boolean(errors.nationality)}
                  helperText={errors.nationality}
                  InputLabelProps={{ shrink: true }}
                  onChange={(e) => {
                    handleDataChange(e);
                    validationHandler(e, "alphabet");
                  }}
                />
              </Grid>
              <Grid item xs={6} sx={{ mt: 2 }}>
                <TextField
                  sx={{ mt: 1, background: "white" }}
                  required
                  fullWidth
                  id="outlined-basic"
                  label="Marital Status"
                  variant="outlined"
                  name="maritalStatus"
                  size="small"
                  value={data.maritalStatus}
                  error={Boolean(errors.maritalStatus)}
                  helperText={errors.maritalStatus}
                  InputLabelProps={{ shrink: true }}
                  onChange={(e) => {
                    handleDataChange(e);
                    validationHandler(e, "alphabet");
                  }}
                />
              </Grid>
              <Grid item xs={6} sx={{ mt: 2 }}>
                <TextField
                  sx={{ mt: 2, background: "white" }}
                  fullWidth
                  id="outlined-basic"
                  label="Address(Permanent):"
                  variant="outlined"
                  multiline
                  rows={3}
                  name="permanentAddress"
                  value={data.permanentAddress}
                  error={Boolean(errors.permanentAddress)}
                  helperText={errors.permanentAddress}
                  InputLabelProps={{ shrink: true }}
                  onChange={(e) => {
                    handleDataChange(e);
                    validationHandler(e, "address");
                  }}
                />
              </Grid>
              <Grid item xs={6} sx={{ mt: 2 }}>
                <TextField
                  sx={{ mt: 2, background: "white" }}
                  fullWidth
                  id="outlined-basic"
                  label="Address(Communication):"
                  variant="outlined"
                  multiline
                  rows={3}
                  name="currentAddress"
                  value={data.currentAddress}
                  error={Boolean(errors.currentAddress)}
                  helperText={errors.currentAddress}
                  InputLabelProps={{ shrink: true }}
                  onChange={(e) => {
                    handleDataChange(e);
                    validationHandler(e, "address");
                  }}
                />
              </Grid>
            </Grid>
            {/* Contact Details */}
            <Typography sx={{ mt: 3 }} variant="h5">
              CONTACT DETAILS
            </Typography>
            <Grid container columnSpacing={{ xs: 5 }}>
              <Grid item xs={6} sx={{ mt: 2 }}>
                <TextField
                  sx={{ mt: 1, background: "white" }}
                  fullWidth
                  id="outlined-basic"
                  label="Contact"
                  variant="outlined"
                  name="phone"
                  size="small"
                  disabled
                  value={location.state.phone}
                />
              </Grid>
              <Grid item xs={6} sx={{ mt: 2 }}>
                <TextField
                  sx={{ mt: 1, background: "white" }}
                  required
                  fullWidth
                  id="outlined-basic"
                  label="Other Contact"
                  variant="outlined"
                  name="contact"
                  size="small"
                  value={data.contact}
                  error={Boolean(errors.contact)}
                  helperText={errors.contact}
                  InputLabelProps={{ shrink: true }}
                  onChange={(e) => {
                    handleDataChange(e);
                    validationHandler(e, "phone");
                  }}
                />
              </Grid>
              <Grid item xs={12} sx={{ mt: 2 }}>
                <TextField
                  sx={{ mt: 1, background: "white" }}
                  fullWidth
                  id="outlined-basic"
                  label="Email Id"
                  variant="outlined"
                  name="email"
                  size="small"
                  disabled
                  value={location.state.email}
                />
              </Grid>
            </Grid>

            {/* Educational Details */}
            <Typography sx={{ mt: 3 }} variant="h5">
              EDUCATIONAL QUALIFICATION
            </Typography>

            <TableContainer sx={{ my: 3 }} className="scrollBarCss">
              <Table>
                <TableHead>
                  <TableRow>
                    <TableCell size="small" sx={{ border: "1px solid black" }}>
                      School/College
                    </TableCell>
                    <TableCell size="small" sx={{ border: "1px solid black" }}>
                      University/Board
                    </TableCell>
                    <TableCell size="small" sx={{ border: "1px solid black" }}>
                      Passing Year
                    </TableCell>
                    <TableCell size="small" sx={{ border: "1px solid black" }}>
                      Degree
                    </TableCell>
                    <TableCell size="small" sx={{ border: "1px solid black" }}>
                      Specialization
                    </TableCell>
                    <TableCell size="small" sx={{ border: "1px solid black" }}>
                      Percentage
                    </TableCell>
                    <TableCell
                      align="right"
                      size="small"
                      sx={{ border: "1px solid black" }}
                    >
                      <IconButton
                        aria-label="add row"
                        onClick={addEducationRow}
                      >
                        <Add />
                      </IconButton>
                    </TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {educationList?.map((row: any) => (
                    <TableRow key={row.id}>
                      <TableCell
                        size="small"
                        sx={{ border: "1px solid black" }}
                      >
                        <TextField
                          type="text"
                          size="small"
                          name="college"
                          value={row.college}
                          error={Boolean(errors.college)}
                          helperText={errors.college}
                          sx={{ background: "white" }}
                          onChange={(e: any) => {
                            educationHandler(e, row.id);
                            validationHandler(e, "alphabetsAndSpace");
                          }}
                        />
                      </TableCell>
                      <TableCell
                        size="small"
                        sx={{ border: "1px solid black" }}
                      >
                        <TextField
                          type="text"
                          size="small"
                          name="university"
                          value={row.university}
                          error={Boolean(errors.university)}
                          helperText={errors.university}
                          sx={{ background: "white" }}
                          onChange={(e: any) => {
                            educationHandler(e, row.id);
                            validationHandler(e, "alphabetsAndSpace");
                          }}
                        />
                      </TableCell>
                      <TableCell
                        size="small"
                        sx={{ border: "1px solid black" }}
                      >
                        <TextField
                          required
                          fullWidth
                          id="year"
                          name="year"
                          type="date"
                          size="small"
                          inputProps={{ max: currentDate }}
                          value={row.year}
                          error={Boolean(errors.year)}
                          helperText={errors.year}
                          sx={{ background: "white" }}
                          onChange={(e: any) => {
                            educationHandler(e, row.id);
                            validationHandler(e, "");
                          }}
                        />
                      </TableCell>
                      <TableCell
                        size="small"
                        sx={{ border: "1px solid black" }}
                      >
                        <TextField
                          type="text"
                          size="small"
                          name="degree"
                          value={row.degree}
                          error={Boolean(errors.degree)}
                          helperText={errors.degree}
                          sx={{ background: "white" }}
                          onChange={(e: any) => {
                            educationHandler(e, row.id);
                            validationHandler(e, "alphabetsAndSpace");
                          }}
                        />
                      </TableCell>
                      <TableCell
                        size="small"
                        sx={{ border: "1px solid black" }}
                      >
                        <TextField
                          type="text"
                          size="small"
                          name="specialization"
                          value={row.specialization}
                          error={Boolean(errors.specialization)}
                          helperText={errors.specialization}
                          sx={{ background: "white" }}
                          onChange={(e: any) => {
                            educationHandler(e, row.id);
                            validationHandler(e, "alphabetsAndSpace");
                          }}
                        />
                      </TableCell>
                      <TableCell
                        size="small"
                        sx={{ border: "1px solid black" }}
                      >
                        <TextField
                          type="text"
                          size="small"
                          name="percentage"
                          value={row.percentage}
                          error={Boolean(errors.percentage)}
                          helperText={errors.percentage}
                          sx={{ background: "white" }}
                          onChange={(e: any) => {
                            educationHandler(e, row.id);
                            validationHandler(e, "numeric");
                          }}
                        />
                      </TableCell>
                      <TableCell
                        align="right"
                        size="small"
                        sx={{ border: "1px solid black" }}
                      >
                        <IconButton
                          aria-label="delete row"
                          onClick={() => deleteEducationRow(row.id)}
                        >
                          <Close />
                        </IconButton>
                      </TableCell>
                    </TableRow>
                  ))}
                </TableBody>
              </Table>
            </TableContainer>

            {/* Experience Details */}
            <Typography sx={{ mt: 3 }} variant="h5">
              EXPERIENCE DETAILS
            </Typography>

            <TableContainer sx={{ my: 3 }} className="scrollBarCss">
              <Table>
                <TableHead>
                  <TableRow>
                    <TableCell size="small" sx={{ border: "1px solid black" }}>
                      Organization and Location
                    </TableCell>
                    <TableCell size="small" sx={{ border: "1px solid black" }}>
                      From
                    </TableCell>
                    <TableCell size="small" sx={{ border: "1px solid black" }}>
                      To
                    </TableCell>
                    <TableCell size="small" sx={{ border: "1px solid black" }}>
                      Year
                    </TableCell>
                    <TableCell size="small" sx={{ border: "1px solid black" }}>
                      Designation
                    </TableCell>
                    <TableCell size="small" sx={{ border: "1px solid black" }}>
                      Brief Description of duties & Responsibilities
                    </TableCell>
                    <TableCell size="small" sx={{ border: "1px solid black" }}>
                      Reasons For Change
                    </TableCell>
                    <TableCell
                      align="right"
                      size="small"
                      sx={{ border: "1px solid black" }}
                    >
                      <IconButton
                        aria-label="add row"
                        onClick={addExperienceRow}
                      >
                        <Add />
                      </IconButton>
                    </TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {experience?.map((row) => (
                    <TableRow key={row.id}>
                      <TableCell
                        size="small"
                        sx={{ border: "1px solid black" }}
                      >
                        <TextField
                          type="text"
                          size="small"
                          name="organization"
                          value={row.organization}
                          error={Boolean(errors.organization)}
                          helperText={errors.organization}
                          sx={{ background: "white" }}
                          onChange={(event: any) => {
                            experienceHandler(event, row.id);
                            validationHandler(event, "alphabetsAndSpace");
                          }}
                        />
                      </TableCell>
                      <TableCell
                        size="small"
                        sx={{ border: "1px solid black" }}
                      >
                        <TextField
                          required
                          fullWidth
                          id="from"
                          name="from"
                          type="date"
                          value={row.from}
                          size="small"
                          inputProps={{ max: currentDate }}
                          error={Boolean(errors.from)}
                          helperText={errors.from}
                          sx={{ background: "white" }}
                          onChange={(event: any) => {
                            experienceHandler(event, row.id);
                            validationHandler(event, "");
                          }}
                        />
                      </TableCell>
                      <TableCell
                        size="small"
                        sx={{ border: "1px solid black" }}
                      >
                        <TextField
                          required
                          fullWidth
                          id="to"
                          name="to"
                          type="date"
                          value={row.to}
                          size="small"
                          inputProps={{ max: currentDate }}
                          error={Boolean(errors.to)}
                          helperText={errors.to}
                          sx={{ background: "white" }}
                          onChange={(event: any) => {
                            experienceHandler(event, row.id);
                            validationHandler(event, "");
                          }}
                        />
                      </TableCell>
                      <TableCell
                        size="small"
                        sx={{ border: "1px solid black" }}
                      >
                        <TextField
                          type="text"
                          size="small"
                          name="expYear"
                          value={row.expYear}
                          error={Boolean(errors.expYear)}
                          helperText={errors.expYear}
                          sx={{ width: "100px", background: "white" }}
                          onChange={(event: any) => {
                            experienceHandler(event, row.id);
                            validationHandler(event, "numeric");
                          }}
                        />
                      </TableCell>
                      <TableCell
                        size="small"
                        sx={{ border: "1px solid black" }}
                      >
                        <TextField
                          type="text"
                          size="small"
                          name="designation"
                          value={row.designation}
                          error={Boolean(errors.designation)}
                          helperText={errors.designation}
                          sx={{ background: "white" }}
                          onChange={(event: any) => {
                            experienceHandler(event, row.id);
                            validationHandler(event, "alphabetsAndSpace");
                          }}
                        />
                      </TableCell>
                      <TableCell
                        size="small"
                        sx={{ border: "1px solid black" }}
                      >
                        <TextField
                          type="text"
                          size="small"
                          name="duties"
                          value={row.duties}
                          error={Boolean(errors.duties)}
                          helperText={errors.duties}
                          sx={{ background: "white" }}
                          onChange={(event: any) => {
                            experienceHandler(event, row.id);
                            validationHandler(event, "alphabetsAndSpace");
                          }}
                        />
                      </TableCell>
                      <TableCell
                        size="small"
                        sx={{ border: "1px solid black" }}
                      >
                        <TextField
                          type="text"
                          size="small"
                          name="reason"
                          value={row.reason}
                          error={Boolean(errors.reason)}
                          helperText={errors.reason}
                          sx={{ background: "white" }}
                          onChange={(event: any) => {
                            experienceHandler(event, row.id);
                            validationHandler(event, "alphabetsAndSpace");
                          }}
                        />
                      </TableCell>
                      <TableCell
                        align="right"
                        size="small"
                        sx={{ border: "1px solid black" }}
                      >
                        <IconButton
                          aria-label="delete row"
                          onClick={() => deleteExperienceRow(row.id)}
                        >
                          <Close />
                        </IconButton>
                      </TableCell>
                    </TableRow>
                  ))}
                </TableBody>
              </Table>
            </TableContainer>

            {/* Special Skills and Hobbies */}
            <Grid container>
              <Grid item xs={12}>
                <Typography sx={{ mt: 2 }} variant="body1">
                  SPECIAL SKILLS IF ANY, THAT YOU POSSESS:
                </Typography>
                <TextField
                  sx={{ mt: 1, background: "white" }}
                  required
                  fullWidth
                  id="outlined-basic"
                  variant="outlined"
                  multiline
                  rows={2}
                  name="skills"
                  size="small"
                  value={data.skills}
                  error={Boolean(errors.skills)}
                  helperText={errors.skills}
                  InputLabelProps={{ shrink: true }}
                  onChange={(e) => {
                    handleDataChange(e);
                    validationHandler(e, "message");
                  }}
                />
              </Grid>
              <Grid item xs={12}>
                <Typography sx={{ mt: 2 }} variant="body1">
                  HOBBIES:
                </Typography>
                <TextField
                  sx={{ mt: 1, background: "white" }}
                  required
                  fullWidth
                  id="outlined-basic"
                  variant="outlined"
                  multiline
                  rows={2}
                  name="hobbies"
                  size="small"
                  value={data.hobbies}
                  error={Boolean(errors.hobbies)}
                  helperText={errors.hobbies}
                  InputLabelProps={{ shrink: true }}
                  onChange={(e) => {
                    handleDataChange(e);
                    validationHandler(e, "message");
                  }}
                />
              </Grid>
            </Grid>

            {/* language Known */}
            <Typography sx={{ mt: 3 }} variant="h6">
              LANGUAGES KNOWN
            </Typography>

            <TableContainer sx={{ my: 3 }} className="scrollBarCss">
              <Table>
                <TableHead>
                  <TableRow>
                    <TableCell
                      align="center"
                      size="small"
                      sx={{ border: "1px solid black" }}
                    >
                      Languages
                    </TableCell>
                    <TableCell
                      align="center"
                      size="small"
                      sx={{ border: "1px solid black" }}
                    >
                      Read
                    </TableCell>
                    <TableCell
                      align="center"
                      size="small"
                      sx={{ border: "1px solid black" }}
                    >
                      Write
                    </TableCell>
                    <TableCell
                      align="center"
                      size="small"
                      sx={{ border: "1px solid black" }}
                    >
                      Speak
                    </TableCell>
                    <TableCell
                      align="center"
                      size="small"
                      sx={{ border: "1px solid black" }}
                    >
                      <IconButton aria-label="add row" onClick={addLanguageRow}>
                        <Add />
                      </IconButton>
                    </TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {language?.map((row) => (
                    <TableRow key={row.id}>
                      <TableCell
                        size="small"
                        align="center"
                        sx={{ border: "1px solid black" }}
                      >
                        <TextField
                          type="text"
                          size="small"
                          fullWidth
                          name="language"
                          value={row.language}
                          error={Boolean(errors.language)}
                          helperText={errors.language}
                          sx={{ background: "white" }}
                          onChange={(e: any) => {
                            languageHandler(e, row.id);
                            validationHandler(e, "alphabet");
                          }}
                        />
                      </TableCell>
                      <TableCell
                        size="small"
                        align="center"
                        sx={{ border: "1px solid black" }}
                      >
                        <Checkbox
                          value={row.read}
                          checked={row.read}
                          name="read"
                          onChange={(e: any) => languageHandler(e, row.id)}
                        />
                      </TableCell>
                      <TableCell
                        size="small"
                        align="center"
                        sx={{ border: "1px solid black" }}
                      >
                        <Checkbox
                          value={row.write}
                          checked={row.write}
                          name="write"
                          onChange={(e: any) => languageHandler(e, row.id)}
                        />
                      </TableCell>
                      <TableCell
                        size="small"
                        align="center"
                        sx={{ border: "1px solid black" }}
                      >
                        <Checkbox
                          value={row.speak}
                          checked={row.speak}
                          name="speak"
                          onChange={(e: any) => languageHandler(e, row.id)}
                        />
                      </TableCell>
                      <TableCell
                        size="small"
                        align="center"
                        sx={{ border: "1px solid black" }}
                      >
                        <IconButton
                          aria-label="delete row"
                          onClick={() => deleteLanguageRow(row.id)}
                        >
                          <Close />
                        </IconButton>
                      </TableCell>
                    </TableRow>
                  ))}
                </TableBody>
              </Table>
            </TableContainer>

            {/* upload file */}
            <TableRow>
              <TableCell sx={{ border: "InactiveBorder" }}>
                <Typography fontWeight="bold">UPLOAD DOCUMENT</Typography>
              </TableCell>
              <TableCell sx={{ border: "InactiveBorder" }}>
                <Button onClick={addUploadField} variant="contained">
                  Add
                </Button>
              </TableCell>
            </TableRow>
            <TableContainer sx={{ my: 3 }} className="scrollBarCss">
              <TableBody>
                <TableRow
                  sx={{
                    display: "flex",
                    flexWrap: "nowrap",
                    justifyContent: "space-between",
                  }}
                >
                  {fileInputs?.map((_, index) => (
                    <Fragment key={index}>
                      <TableCell
                        size="small"
                        sx={{
                          border: "1px solid black",
                          display: "flex",
                          justifyContent: "space-between",
                          alignItems: "baseline",
                          width: "300px",
                        }}
                      >
                        <TextField
                          size="small"
                          type="file"
                          name={`name${index}`}
                          id="files"
                          inputProps={{ accept: "application/pdf" }}
                          error={Boolean(errors[`name${index}`])}
                          helperText={errors[`name${index}`]}
                          sx={{ background: "white" }}
                          onChange={(e) => {
                            uploadDocumentHandler(e, index);
                            validationHandler(e, "pdfSize");
                          }}
                        />
                        <IconButton
                          aria-label="delete row"
                          onClick={() =>
                            deleteUploadField(index, `name${index}`)
                          }
                        >
                          <Close />
                        </IconButton>
                      </TableCell>
                    </Fragment>
                  ))}
                </TableRow>
              </TableBody>
            </TableContainer>

            <TableContainer sx={{ my: 3 }} className="scrollBarCss">
              <TableHead>
                <TableCell>
                  <TableRow>
                    <Typography fontWeight="bold">
                      UPLOADED DOCUMENTS
                    </Typography>
                  </TableRow>
                </TableCell>
              </TableHead>
              <TableBody>
                <TableRow
                  sx={{
                    display: "flex",
                    flexWrap: "nowrap",
                    justifyContent: "space-between",
                  }}
                >
                  {uploadDocumentList?.map((file: any) => (
                    <Fragment key={file.id}>
                      <TableCell
                        sx={{
                          border: "1px solid black",
                          display: "flex",
                          justifyContent: "space-between",
                          alignContent: "baseline",
                          width: "200px",
                        }}
                      >
                        <Typography fontSize="12px">
                          {file.originalname}
                        </Typography>
                        <Tooltip title="Download File">
                          <IconButton
                            href={file.path}
                            size="small"
                          >
                            <CloudDownloadIcon sx={{ color: "#0288d1" }} />
                          </IconButton>
                        </Tooltip>
                        <Tooltip title="Remove File">
                          <IconButton onClick={() => handleOpen(file)}>
                            <DeleteIcon
                              sx={{ fontSize: "25px", color: "red" }}
                            />
                          </IconButton>
                        </Tooltip>
                      </TableCell>
                      <Dialog open={open} onClose={handleClose}>
                        <DialogTitle>Confirm Delete</DialogTitle>
                        <DialogContent>
                          <Typography>
                            Are you sure you want to remove this file?
                          </Typography>
                        </DialogContent>
                        <DialogActions>
                          <Button
                            onClick={() =>
                              deleteRequestForFiles(selectedFile.id)
                            }
                          >
                            Yes
                          </Button>
                          <Button onClick={handleClose}>No</Button>
                        </DialogActions>
                      </Dialog>
                    </Fragment>
                  ))}
                </TableRow>
              </TableBody>
            </TableContainer>
            <Grid container>
              <Checkbox
                checked={acceptChecked}
                onChange={handleAcceptCheckbox}
              />
              <Typography sx={{ mt: 1 }}>
                I hereby declare that all particulars in this application are
                true to the best of my knowledge and belief.
              </Typography>
            </Grid>
            <Grid container sx={{ mt: 2 }} columnSpacing={2}>
              <Grid item>
                <Button
                  variant="contained"
                  type="submit"
                  disabled={!acceptChecked}
                >
                  Submit
                </Button>
              </Grid>
              <Grid item>
                <Button variant="contained" onClick={() => navigate(-1)}>
                  Cancel
                </Button>
              </Grid>
            </Grid>
            <CommonSnackBar message={snackMessage} open={openSnackBar} />
          </Paper>
        </Grid>
      </Container>
    </>
  );
}
