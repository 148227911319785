import {
  Page,
  Text,
  Image,
  Document,
  StyleSheet,
  View,
} from "@react-pdf/renderer";

const styles = StyleSheet.create({
  body: {
    paddingTop: 35,
    paddingBottom: 65,
    paddingHorizontal: 35,
  },
  para1: {
    flexDirection: "row",
    flexWrap: "wrap",
  },
  para2: {
    flexDirection: "row",
    flexWrap: "wrap",
    padding: 10,
  },
  para3: {
    flexDirection: "row",
    flexWrap: "wrap",
    paddingtop: "100px",
  },
  text2: {
    fontSize: 12,
    textAlign: "right",
  },
  text: {
    fontSize: 12,
    textAlign: "justify",
  },
  text1: {
    fontSize: 20,
    fontWeight: "bold",
  },
  column1: {
    width: "20%",
    padding: 20,
  },
  column2: {
    width: "80%",
    padding: 10,
  },
  column3: {
    width: "25%",
    padding: 10,
  },
  column4: {
    width: "33.33%",
    padding: 10,
  },
  column5: {
    width: "50%",
    padding: 10,
  },
  image: {
    height: 70,
    width: 70,
  },
  border: {
    borderBottom: " 1px solid black",
    margin: 5,
  },
});

const StudentInfoLetter = (props: any) => {
  let date = new Date();
  let day = date.getDate();
  let month = date.getMonth() + 1;
  let year = date.getFullYear();
  let currentDate = `${day}-${month}-${year}`;

  return (
    <>
      <Document>
        return(
        <Page size="A4">
          <View style={styles.para1}>
            <View style={styles.column1}>
              <Image
                style={styles.image}
                src="https://ik.imagekit.io/nwssoft/AboutUS-College-Logo/Logo/New_Logo_with_Crop/prena_acedemy_logo.jfif?updatedAt=1685344751380"
              ></Image>
            </View>
            <View style={styles.column2}>
              <Text style={styles.text}>PRERNA ACADEMY PVT. LTD., INDORE</Text>
              <Text style={styles.text}>
                Corporate Office : 82 Swami Vivekanand Nagar Telephone Square
                Kanadia Road Indore
              </Text>
              <Text style={styles.text}>(M.P) pin 452018</Text>
              <Text style={styles.text}>
                Ph. : (+91) 835 889 9900, (0731) 4298599
              </Text>
              <Text style={styles.text}>Toll Free No. : 1800120-224466</Text>
              <Text style={styles.text}>Email :info@prernaacademy.in</Text>
              <Text style={styles.text}>website: www.prernaacademy.in</Text>
            </View>
          </View>
          <View style={styles.border}></View>

          <View style={styles.para1}>
            <View style={styles.column2}>
              <Text style={styles.text}>Reg No: {props?.object.studentId}</Text>
              <Text style={styles.text}>
                Entrollment No: {props?.object.studentId}
              </Text>
            </View>

            <View style={styles.column3}>
              <Text style={styles.text}>First Name:</Text>
              <Text style={styles.text}>Father Name: </Text>
              <Text style={styles.text}>Email:</Text>
            </View>
            <View style={styles.column3}>
              <Text style={styles.text}>
                {props?.object.studentData.firstName}
              </Text>
              <Text style={styles.text}>
                {props?.object.studentData.fatherName}
              </Text>
              <Text style={styles.text}>{props?.object.studentData.email}</Text>
            </View>
            <View style={styles.column3}>
              <Text style={styles.text}>Last Name:</Text>
              <Text style={styles.text}>Mother Name: </Text>
              <Text style={styles.text}>Contact Number:</Text>
            </View>
            <View style={styles.column3}>
              <Text style={styles.text}>
                {props?.object.studentData.lastName}
              </Text>
              <Text style={styles.text}>
                {props?.object.studentData.motherName}
              </Text>
              <Text style={styles.text}>
                {props?.object.studentData.contactNumber}
              </Text>
            </View>
          </View>
          <View style={styles.para2}>
            <Text style={styles.text1}>Present Address</Text>
          </View>

          <View style={styles.para1}>
            <View style={styles.column2}>
              <Text style={styles.text}>
                Address1: {props?.object.studentData.presentAddress1}
              </Text>
              <Text style={styles.text}>
                Address2: {props?.object.studentData.presentAddress2}
              </Text>
            </View>
            <View style={styles.column3}>
              <Text style={styles.text}>City:</Text>
              <Text style={styles.text}>Country: </Text>
              <Text style={styles.text}>Phone Number: </Text>
            </View>
            <View style={styles.column3}>
              <Text style={styles.text}>
                {props?.object.studentData.presentCity}
              </Text>
              <Text style={styles.text}>Country: </Text>
              <Text style={styles.text}>
                {props?.object.studentData.contactNumber}
              </Text>
            </View>

            <View style={styles.column3}>
              <Text style={styles.text}>State: </Text>
              <Text style={styles.text}>Zipcode: </Text>
            </View>
            <View style={styles.column3}>
              <Text style={styles.text}>
                {props?.object.studentData.presentState}
              </Text>
              <Text style={styles.text}>
                {props?.object.studentData.presentZipCode}
              </Text>
            </View>
          </View>
          <View style={styles.para2}>
            {" "}
            <Text style={styles.text1}>Permanent Address</Text>
          </View>

          <View style={styles.para1}>
            <View style={styles.column2}>
              <Text style={styles.text}>
                Address1: {props?.object.studentData.permanentAddress1}
              </Text>
              <Text style={styles.text}>
                Address2: {props?.object.studentData.permanentAddress2}
              </Text>
            </View>
            <View style={styles.column3}>
              <Text style={styles.text}>City:</Text>
              <Text style={styles.text}>Country: </Text>
              <Text style={styles.text}>Phone Number: </Text>
            </View>
            <View style={styles.column3}>
              <Text style={styles.text}>
                {props?.object.studentData.permanentCity}
              </Text>
              <Text style={styles.text}>country</Text>
              <Text style={styles.text}>
                {props?.object.studentData.contactNumber}
              </Text>
            </View>

            <View style={styles.column3}>
              <Text style={styles.text}>State: </Text>
              <Text style={styles.text}>Zipcode: </Text>
            </View>
            <View style={styles.column3}>
              <Text style={styles.text}>
                {props?.object.studentData.permanentState}
              </Text>
              <Text style={styles.text}>
                {props?.object.studentData.permanentZipCode}
              </Text>
            </View>
          </View>
          <View style={styles.para2}>
            {" "}
            <Text style={styles.text1}>Course Details</Text>
          </View>

          <View style={styles.para1}>
            <View style={styles.column2}>
              <Text style={styles.text}>
                University: {props?.object.courseData.universityName}
              </Text>
              <Text style={styles.text}>
                Course Name: {props?.object.studentData.permanentAddress2}
              </Text>
            </View>
            <View style={styles.column3}>
              <Text style={styles.text}>Admission Date:</Text>
              <Text style={styles.text}>Registration Type:</Text>
              <Text style={styles.text}>Admission Sem/Year</Text>
            </View>
            <View style={styles.column3}>
              <Text style={styles.text}>
                {props?.object.courseData.admissionDate}
              </Text>

              <Text style={styles.text}>Registration Type:</Text>
              <Text style={styles.text}>
                {props?.object.courseData.admissionSemesterYear}
              </Text>
            </View>

            <View style={styles.column3}>
              <Text style={styles.text}>Course Type:</Text>
              <Text style={styles.text}>Session:</Text>
              <Text style={styles.text}>Total Course:</Text>
            </View>
            <View style={styles.column3}>
              <Text style={styles.text}>Course Type:</Text>
              <Text style={styles.text}>
                {props?.object.courseData.sessionFrom +
                  " " +
                  props?.object.courseData.sessionTo}
              </Text>
              <Text style={styles.text}>
                {props?.object.courseData.noOfSemsOrYears}
              </Text>
            </View>
          </View>
          <View style={styles.para2}>
            {" "}
            <Text style={styles.text1}>Fees Details</Text>
          </View>
          <View style={styles.para1}>
            <View style={styles.column3}>
              <Text style={styles.text}>Total Fees:</Text>
              <Text style={styles.text}>Registration Fees: </Text>
              <Text style={styles.text}>Other Fee: </Text>
            </View>
            <View style={styles.column3}>
              <Text style={styles.text}>Total Fees:</Text>
              <Text style={styles.text}>Registration Fees: </Text>
              <Text style={styles.text}>
                {props?.object.courseData.otherFees}
              </Text>
            </View>

            <View style={styles.column3}>
              <Text style={styles.text}>Fees Paid:</Text>
              <Text style={styles.text}>Caution Money:</Text>
            </View>
            <View style={styles.column3}>
              <Text style={styles.text}>Fees Paid:</Text>
              <Text style={styles.text}>Caution Money:</Text>
            </View>
          </View>
          <View style={styles.para2}>
            <Text style={styles.text1}>Installment</Text>
          </View>
          <View style={styles.para1}>
            <View style={styles.column4}>
              <Text style={styles.text}>Date</Text>
            </View>
            <View style={styles.column4}>
              <Text style={styles.text}>Amount </Text>
            </View>

            <View style={styles.column4}>
              <Text style={styles.text}>Status</Text>
            </View>
          </View>

          <View style={styles.para1}>
            {props?.object.courseData.installmentDetails.map(
              (item: any, index: any) => (
                <>
                  <View style={styles.column4}>
                    <Text style={styles.text}>{item.date}</Text>
                  </View>
                  <View style={styles.column4}>
                    <Text style={styles.text}>{item.amount} </Text>
                  </View>

                  <View style={styles.column4}>
                    <Text style={styles.text}>{item.verifyPayment}</Text>
                  </View>
                </>
              )
            )}
          </View>

          <View style={styles.para2}>
            <Text style={styles.text}>
              1. For Above Program I Will Pay Total Fees Rs 60000 In Lum-Sum Or
              In 2 Installment.
            </Text>
            <Text style={styles.text}>
              2. Above Fee Will Not Be Refund In Any Case.
            </Text>
            <Text style={styles.text}>
              3. Students Means ramesh Batreddi Can Be Withdraw From Course At
              Any Time But Can’t Withdraw From Fees In Any Case. The Pay
              FeesWill Not Be Refund In Any ConditionWill Not Be Refund In Any
              Condition
            </Text>
            <Text style={styles.text}>
              4. I Will Pay Installment By The Date Of 10 Pay Cash Instead Of
              Cheque By The Before Date Of Due That.
            </Text>
            <Text style={styles.text}>
              5. In Case If I Am Unable To Pay The Amount I Will Pay Rs.500
              Charge+ Installment Within 7days. After7 Days From 8 To 15 Days
              The Penalty WillBe 1000 Rs Be Charged Will Pay After 15 Days Of
              Due That I Will Pay After 15 Days Of Due That I Will Pay Rs.2000
              With Installment.
            </Text>
            <Text style={styles.text}>
              6. Without Completion Of My Payment I Will Not Be Eligible For
              Sitting In Any Exam And The AllResponsibilities WillBe Myself.
            </Text>
            <Text style={styles.text}>
              7. In Case Of Any Dispute The Jurisdiction Area WillBe Indore
              Court Only.
            </Text>
            <Text style={styles.text}>
              8. You Will Get The Course Materials & Enrollment No. And Anything
              Belongs To University And Your Course, After Paying 50% Of Total
              Fees And Minimum Time Of 3 Months.
            </Text>
            <Text style={styles.text}>
              9. We Are Only Facilities Not Owner Of University, So Finally We
              Will Take The Decisions According To Respective Universities And
              UGC Rules & Regulations Which Can Be Change Time To Time.
            </Text>
          </View>

          <View style={styles.para3}>
            <View style={styles.column5}>
              <Text style={styles.text}>Student Signature</Text>
            </View>
            <View style={styles.column5}>
              <Text style={styles.text2}>Authorised Signature</Text>
            </View>
          </View>
        </Page>
        )
      </Document>
    </>
  );
};

export default StudentInfoLetter;
