import {
  Button,
  Container,
  Grid,
  LinearProgress,
  Paper,
  TextField,
} from "@mui/material";
import { ChangeEvent, useState } from "react";
import CommonSnackBar from "../../Components/Common/CommonSnackbar";
import useTypescriptValidation from "../../Hooks/useTypescriptValidation";
import { postRequestMethod } from "../../api/api";
import { UPLOAD_UNIVERSITY } from "../../api/server";

export default function UploadUniversity() {
  const { eventHandler } = useTypescriptValidation();

  const [file, setFile] = useState<File | undefined>(undefined);
  const [errors, setErrors] = useState<string | undefined>(undefined);
  const [openSnackBar, setOpenSnackBar] = useState<boolean>(false);
  const [snackMessage, setSnackMessage] = useState<string>("");
  const [isLoading, setIsLoading] = useState<boolean>(false);

  const validationHandler = async (
    e: ChangeEvent<HTMLInputElement>,
    alterName: string
  ) => {
    const val: File | null = e.target.files?.[0] || null;
    const id = alterName;
    if (val) {
      let prom = await new Promise<string>((resolve) =>
        resolve(eventHandler(id, val))
      );
      if (prom === "") {
        setFile(val);
        setErrors("");
      } else {
        setFile(undefined);
        setErrors(prom);
      }
    }
  };

  const handleOpenSnackBar = () => {
    setOpenSnackBar(true);
    setTimeout(() => {
      setOpenSnackBar(false);
    }, 5000);
  };

  const onSubmit = (e: any) => {
    e.preventDefault();
    const formData = new FormData();
    if (file) formData.append("file", file);
    try {
      setIsLoading(true);
      postRequestMethod(
        UPLOAD_UNIVERSITY,
        "multipart/form-data",
        formData
      ).then((res: any) => {
        setIsLoading(false);
        if (res.data) {
          setSnackMessage("File Uploaded successfully");
          handleOpenSnackBar();
        }
        if (res.data.error) {
          setSnackMessage(`${res.data.error}`);
          handleOpenSnackBar();
        }
      });
    } catch (error) {
      console.error("Error on submitting form", error);
    }
  };

  return (
    <>
      <Container>
        {isLoading && <LinearProgress />}
        <Paper elevation={20}>
          <Grid container spacing={2} padding={2}>
            <Grid item xs={6}>
              <TextField
                fullWidth
                type="file"
                size="small"
                error={Boolean(errors)}
                helperText={errors}
                sx={{ background: "white" }}
                inputProps={{ accept: ".xlsx,.csv" }}
                onChange={(e: ChangeEvent<HTMLInputElement>) =>
                  validationHandler(e, "xlsxCsv")
                }
              />
            </Grid>
            <Grid item xs={6}>
              <Button variant="contained" onClick={onSubmit}>
                Upload
              </Button>
            </Grid>
          </Grid>
          <CommonSnackBar message={snackMessage} open={openSnackBar} />
        </Paper>
      </Container>
    </>
  );
}
