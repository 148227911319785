import React, { useEffect, useState } from "react";
import Button from "@mui/material/Button";
import EditIcon from "@mui/icons-material/Edit";
import {
  Container,
  Paper,
  TableContainer,
  Table,
  TableHead,
  TableBody,
  TableRow,
  TableCell,
  Grid,
  Typography,
  TablePagination,
  Stack,
  FormControl,
  InputAdornment,
  IconButton,
  TextField,
  Tooltip,
} from "@mui/material";
import { getRequest } from "../../api/api";
import { COURIER_DETAILS_GET } from "../../api/server";
import { useNavigate } from "react-router-dom";
import SearchIcon from "@mui/icons-material/Search";
import TableToExcel from "../../Components/Common/TableToExcel";

function ManageCourier(props: any) {
  const navigate = useNavigate();
  const paramsData = { id: props.types.lId, type: props.types.type };

  const [courierDetails, setCourierDetails] = useState<any[]>([]);
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [searchApiData, setSearchApiData] = useState<any[]>([]);
  const [filterVal, setFilterVal] = useState("");

  const handleChangePage = (event: any, newPage: number) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event: any) => {
    setRowsPerPage(+event.target.value);
    setPage(0);
  };

  const getCourier = async () => {
    try {
      const res = await getRequest(COURIER_DETAILS_GET, { params: paramsData });
      if (res) {
        setCourierDetails(res.data);
        setSearchApiData(res.data);
      }
    } catch (error) {
      console.error("Error in loadUser:", error);
    }
  };

  const handleFilter = (e: any) => {
    if (e.target.value === "") {
      setCourierDetails(searchApiData);
    } else {
      const filterResult = searchApiData.filter((item: any) => {
        const searchValue = e.target.value.toLowerCase();
        return Object.values(item).some((value) => {
          if (typeof value === "string" || typeof value === "number") {
            const itemValue = String(value).toLowerCase();
            return itemValue.includes(searchValue);
          }
          return false;
        });
      });
      setCourierDetails(filterResult);
    }
    setFilterVal(e.target.value);
  };

  useEffect(() => {
    getCourier();
  }, []);

  return (
    <Container>
      <Paper elevation={20}>
        <Grid
          container
          sx={{
            p: 2,
            background: "#0288d1",
            color: "white",
          }}
        >
          <Grid item xs={6}>
            <Typography variant="h5" sx={{ mx: 3 }}>
              Manage Courier
            </Typography>
          </Grid>
          {props.types.type === "Administrator" && (
            <Grid item xs={3}>
              <TableToExcel
                tableData={courierDetails}
                fileName="Courier_Detail"
                headerMapping={{
                  "Courier Company": "courierCompany",
                  "Consignment No": "consignmentNo",
                  "Booking Date": "bookingDate",
                  "Booking Center": "bookingCenter",
                  "To Center": "toCenter",
                  "Contact Number": "contactNumber",
                  Address: "presentAddress",
                  Status: "status",
                  Remark: "remark",
                }}
              />
            </Grid>
          )}
          <Grid
            item
            xs={props.types.type !== "Administrator" ? 6 : 3}
            sx={{
              display: "flex",
              justifyContent: "flex-end",
            }}
          >
            <FormControl>
              <TextField
                label="Search "
                size="small"
                value={filterVal}
                onInput={(e: any) => handleFilter(e)}
                sx={{ mx: 3, background: "white" }}
                InputProps={{
                  endAdornment: (
                    <InputAdornment position="end">
                      <IconButton edge="end">
                        <SearchIcon />
                      </IconButton>
                    </InputAdornment>
                  ),
                }}
              />
            </FormControl>
          </Grid>
        </Grid>
        <TableContainer className="scrollBarCss">
          <Table>
            <TableHead>
              <TableRow>
                <TableCell
                  sx={{
                    textAlign: "center",
                    border: "1px solid #ddd",
                    fontSize: "15px",
                  }}
                >
                  Sr.No
                </TableCell>
                <TableCell
                  sx={{
                    textAlign: "center",
                    border: "1px solid #ddd",
                    fontSize: "15px",
                  }}
                >
                  Courier Company
                </TableCell>
                <TableCell
                  sx={{
                    textAlign: "center",
                    border: "1px solid #ddd",
                    fontSize: "15px",
                  }}
                >
                  Consignment No
                </TableCell>
                <TableCell
                  sx={{
                    textAlign: "center",
                    border: "1px solid #ddd",
                    fontSize: "15px",
                  }}
                >
                  Booking Date
                </TableCell>
                <TableCell
                  sx={{
                    textAlign: "center",
                    border: "1px solid #ddd",
                    fontSize: "15px",
                  }}
                >
                  Booking Center
                </TableCell>
                <TableCell
                  sx={{
                    textAlign: "center",
                    border: "1px solid #ddd",
                    fontSize: "15px",
                  }}
                >
                  To Center
                </TableCell>

                <TableCell
                  sx={{
                    textAlign: "center",
                    border: "1px solid #ddd",
                    fontSize: "15px",
                  }}
                >
                  Contact Number
                </TableCell>
                <TableCell
                  sx={{
                    textAlign: "center",
                    border: "1px solid #ddd",
                    fontSize: "15px",
                  }}
                >
                  Address
                </TableCell>
                <TableCell
                  sx={{
                    textAlign: "center",
                    border: "1px solid #ddd",
                    fontSize: "15px",
                  }}
                >
                  Status
                </TableCell>

                <TableCell
                  sx={{
                    textAlign: "center",
                    border: "1px solid #ddd",
                    fontSize: "15px",
                  }}
                >
                  Remark
                </TableCell>
                {paramsData?.type === "Student" ? null : (
                  <TableCell
                    sx={{
                      textAlign: "center",
                      border: "1px solid #ddd",
                      fontSize: "15px",
                    }}
                  >
                    Action
                  </TableCell>
                )}
              </TableRow>
            </TableHead>

            <TableBody>
              {courierDetails
                .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                .map((item: any, index: any) => (
                  <React.Fragment key={item._id}>
                    <TableRow key={item._id}>
                      <TableCell
                        sx={{
                          textAlign: "center",
                          border: "1px solid #ddd",
                        }}
                      >
                        {page * rowsPerPage + index + 1}
                      </TableCell>
                      <TableCell
                        sx={{
                          textAlign: "center",
                          border: "1px solid #ddd",
                        }}
                      >
                        {item.courierCompany}
                      </TableCell>
                      <TableCell
                        sx={{
                          textAlign: "center",
                          border: "1px solid #ddd",
                        }}
                      >
                        {item.consignmentNo}
                      </TableCell>
                      <TableCell
                        sx={{
                          textAlign: "center",
                          border: "1px solid #ddd",
                        }}
                      >
                        {item.bookingDate}
                      </TableCell>

                      <TableCell
                        sx={{
                          textAlign: "center",
                          border: "1px solid #ddd",
                        }}
                      >
                        {item.bookingCenter}
                      </TableCell>

                      <TableCell
                        sx={{
                          textAlign: "center",
                          border: "1px solid #ddd",
                        }}
                      >
                        {item.toCenter}
                      </TableCell>
                      <TableCell
                        sx={{
                          textAlign: "center",
                          border: "1px solid #ddd",
                        }}
                      >
                        {item.contactNumber}
                      </TableCell>
                      <TableCell
                        sx={{
                          textAlign: "center",
                          border: "1px solid #ddd",
                        }}
                      >
                        {item.presentAddress}
                      </TableCell>
                      <TableCell
                        sx={{
                          textAlign: "center",
                          border: "1px solid #ddd",
                        }}
                      >
                        {item.status}
                      </TableCell>

                      <TableCell
                        sx={{
                          textAlign: "center",
                          border: "1px solid #ddd",
                        }}
                      >
                        {item.remark}
                      </TableCell>
                      {paramsData?.type === "Student" ? null : (
                        <TableCell
                          sx={{
                            textAlign: "center",
                            border: "1px solid #ddd",
                            fontSize: "15px",
                          }}
                        >
                          <Stack
                            direction="row"
                            justifyContent="space-evenly"
                            alignItems="center"
                            spacing={2}
                          >
                            <Tooltip title="Update Courier Status" arrow>
                              <IconButton>
                                <EditIcon
                                  onClick={() =>
                                    navigate("/dashboard/Courier/EditCourier", {
                                      state: item,
                                    })
                                  }
                                  sx={{ color: "#0288d1" }}
                                />
                              </IconButton>
                            </Tooltip>
                          </Stack>
                        </TableCell>
                      )}
                    </TableRow>
                  </React.Fragment>
                ))}
            </TableBody>
          </Table>
        </TableContainer>
        <TablePagination
          rowsPerPageOptions={[2, 25, 50, 100]}
          component="div"
          count={courierDetails.length}
          rowsPerPage={rowsPerPage}
          page={page}
          onPageChange={handleChangePage}
          onRowsPerPageChange={handleChangeRowsPerPage}
        />
      </Paper>
    </Container>
  );
}

export default ManageCourier;
