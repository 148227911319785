import DiamondIcon from "@mui/icons-material/Diamond";
import ModeOutlinedIcon from "@mui/icons-material/ModeOutlined";
import PushPinIcon from "@mui/icons-material/PushPin";
import ShowChartOutlinedIcon from "@mui/icons-material/ShowChartOutlined";
import {
  Divider,
  ImageList,
  ImageListItem,
  List,
  ListItem,
  Typography,
} from "@mui/material";
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import Grid from "@mui/material/Grid";
import { Container } from "@mui/system";
import { useNavigate } from "react-router-dom";
import "../Home/Home.scss";
export default function AboutUs() {
  const navigate = useNavigate();
  return (
    <>
      <Container>
        {/* about company part */}
        <Box
          sx={{
            width: "100%",
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            gap: "2.5rem",
            marginTop: {
              xs: "20%",
              sm: "12%",
              md: "12%",
              lg: "7%",
              xl: "5%",
            },
          }}
        >
          <Grid container rowSpacing={2}>
            <Grid item xs={12} sm={12} md={6}>
              <Box>
                <Typography
                  sx={{
                    textAlign: "start",
                    fontWeight: "500",
                    color: "#191970",
                    mt: 5,
                  }}
                  variant="h4"
                  component="h2"
                >
                  ABOUT US
                </Typography>
              </Box>
              <Typography
                sx={{ mt: 2, alignItems: "center", textAlign: "justify" }}
              >
                Prerna Academy is a Distance Learning Institute, helps you to
                grow individually. MBA, UG courses, PG courses, Diploma courses,
                etc. are being offered by us which intensifies a kick start to
                your career growth. Experts guide at every step and help you
                cross every hurdle you face.
                <br /> Online Education has grown in popularity as a type of
                remote learning in higher education for a variety of reasons. As
                a Distance Learning Centre, Prerna Academy presents previously
                unheard-of possibilities for those whose access to education
                would otherwise be severely restricted, as well as a new
                paradigm for educators in which dynamic courses of the highest
                calibre can be created.
                <br />
                Prerna Academy focuses on improving every element that enables
                one to stand tall in society.  Some of these include student
                involvement, cognitive engagement, behavioural engagement,
                student confidence, and the delivery of precise instructions.
                Prerna Academy places the highest emphasis on education.
                Programs that motivate children to pursue education and raise
                the bar of education have the potential to change the state of
                development and prosperity.
              </Typography>
            </Grid>
            <Grid
              item
              xs={12}
              sm={12}
              md={6}
              sx={{ display: { xs: "none", sm: "block" } }}
            >
              <ImageList
                sx={{
                  width: { xs: "auto", sm: "auto" },
                  height: { xs: "auto", sm: "auto" },
                }}
                cols={1}
                rowHeight="auto"
              >
                <ImageListItem>
                  <img
                    src="https://ik.imagekit.io/nwssoft/AboutUS-College-Logo/Aboutus-image5.png?updatedAt=1680074409031"
                    loading="lazy"
                    alt="Image 1"
                  />
                </ImageListItem>
              </ImageList>
            </Grid>
            <Grid item xs={12}>
              <Box textAlign="start">
                <Button
                  variant="contained"
                  sx={{ px: 2, py: 1 }}
                  onClick={() => {
                    navigate("/contactus");
                    window.scroll(0, 0);
                  }}
                >
                  Connect To Us
                </Button>
              </Box>
            </Grid>
          </Grid>
        </Box>
        <Grid container spacing={4}>
          <Grid item xs={12} sx={{ textAlign: "center" }}>
            <Typography
              sx={{
                textAlign: "start",
                fontWeight: "500",
                color: "#191970",
                mt: 7,
                ml: 3,
              }}
              variant="h4"
            >
              Our Mission & Vision
            </Typography>
          </Grid>
          <Grid item xs={12} sm={12} md={6}>
            <Box
              component="img"
              sx={{ width: "100%", height: "auto" }}
              src="https://ik.imagekit.io/nwssoft/AboutUS-College-Logo/Aboutus-image4.png?updatedAt=1680074186489"
            ></Box>
            <Typography sx={{ alignItems: "center", textAlign: "justify" }}>
              Nations all over the globe, the highest priority is on education.
              The situation of development and prosperity can be altered by
              programs that encourage kids to get education and enhance the
              standard of education. Prerna Academy is anticipating to reform
              all possible aspects:
            </Typography>
            <List
              sx={{
                listStyleType: "disc",
                listStylePosition: "inside",
              }}
              className="listFont"
            >
              <ListItem
                sx={{
                  display: "list-item",
                  fontFamily: "'Nunito Sans', sans-serif",
                }}
              >
                Flexibility
              </ListItem>
              <ListItem
                sx={{
                  display: "list-item",
                  fontFamily: "'Nunito Sans', sans-serif",
                }}
              >
                Easy access
              </ListItem>
              <ListItem
                sx={{
                  display: "list-item",
                  fontFamily: "'Nunito Sans', sans-serif",
                }}
              >
                Money and time savings
              </ListItem>
              <ListItem
                sx={{
                  display: "list-item",
                  fontFamily: "'Nunito Sans', sans-serif",
                }}
              >
                Adaptability and Freedom
              </ListItem>
              <ListItem
                sx={{
                  display: "list-item",
                  fontFamily: "'Nunito Sans', sans-serif",
                }}
              >
                Abundance of trained teachers
              </ListItem>
              <ListItem
                sx={{
                  display: "list-item",
                  fontFamily: "'Nunito Sans', sans-serif",
                }}
              >
                Upgraded technology
              </ListItem>
            </List>
            <Typography sx={{ alignItems: "center", textAlign: "justify" }}>
              Prerna Academy strives to provide students with a simple method,
              clear activities and lessons, and obstacle-free course
              completion. 
            </Typography>
          </Grid>
          <Grid item xs={12} sm={6} md={3}>
            <Box
              component="img"
              sx={{ width: "100%", height: "auto" }}
              src="https://ik.imagekit.io/nwssoft/AboutUS-College-Logo/Aboutus-image1.png?updatedAt=1680072948562"
              className="image1"
            ></Box>
            <Typography
              sx={{
                textAlign: "start",
                fontWeight: "500",
                color: "#191970",
                mt: 5,
              }}
              variant="h5"
            >
              Familiar with distance learning
            </Typography>
            <Typography
              sx={{
                alignItems: "center",
                textAlign: "justify",
              }}
            >
              Investigating the intrinsic learning motivations of pupils with
              various attitudes towards distance learning is the study's goal.
              The following are the study's primary goals:
            </Typography>
            <List
              sx={{
                listStyleType: "disc",
                listStylePosition: "inside",
              }}
              className="listFont"
            >
              <ListItem
                sx={{
                  display: "list-item",
                  fontFamily: "'Nunito Sans', sans-serif",
                }}
              >
                Students at higher education institutions should be examined for
                their attitudes towards various forms of distance learning, 
              </ListItem>
              <ListItem
                sx={{
                  display: "list-item",
                  fontFamily: "'Nunito Sans', sans-serif",
                }}
              >
                As well as their primary sources of scientific inspiration. 
              </ListItem>
              <ListItem
                sx={{
                  display: "list-item",
                  fontFamily: "'Nunito Sans', sans-serif",
                }}
              >
                Students should also be compared for differences in their
                sources of scientific inspiration.
              </ListItem>
            </List>
          </Grid>
          <Grid item xs={12} sm={6} md={3}>
            <Box
              component="img"
              sx={{ width: "100%", height: "auto" }}
              src="https://ik.imagekit.io/nwssoft/AboutUS-College-Logo/Aboutus-image2.png?updatedAt=1680073351688"
              className="image1"
            ></Box>
            <Typography
              sx={{
                textAlign: "start",
                fontWeight: "500",
                color: "#191970",
                mt: 5,
              }}
              variant="h5"
            >
              Career counselling
            </Typography>
            <Typography
              sx={{
                alignItems: "center",
                textAlign: "justify",
              }}
            >
              We assure you to give the best career counselling sessions. It
              will help you become aware of your strengths and flaws so that we
              can determine what profession would be best for you. It will also
              talk about any challenges you might encounter as your career
              develops.
              <br></br>  Assessing your aptitude, personality, interests, and
              other parts of you is a specialty of career counsellors. They make
              recommendations for the most suitable careers out of all the
              available and pertinent choices using this evaluation.
            </Typography>
          </Grid>
        </Grid>
        {/* core value of company */}
        <Box sx={{ width: "100%" }}>
          <Grid
            container
            rowSpacing={5}
            sx={{ display: "flex", justifyContent: "center" }}
          >
            <Grid
              item
              xs={12}
              md={7}
              sx={{
                marginTop: "40px",
                alignContent: "center",
                justifyContent: "center",
              }}
            >
              <Grid item xs={12}>
                <Divider
                  color="cornflowerblue"
                  sx={{
                    width: "15rem",
                    textAlign: "right",
                    height: 2,
                    marginTop: "0",
                    padding: "0",
                    mb: 2,
                  }}
                />
                <Typography
                  variant="h4"
                  style={{
                    paddingBottom: "0px",
                    marginTop: "0px",
                    color: "#191970",
                  }}
                >
                  COMPANY <span className="core">CORE VALUES</span>
                </Typography>
                <Typography
                  variant="h6"
                  sx={{ marginTop: "30px", marginBottom: "18px" }}
                >
                  <PushPinIcon></PushPinIcon>
                  <i className="fa-sharp fa-solid fa-thumbtack">
                    Our company culture defined by our core values
                  </i>
                </Typography>
                <Typography variant="body1">
                  Organizational core values outline how we should behave
                  towards others and provide a framework for our fundamental
                  work ethics. A methodical strategy to developing ideal
                  organizational values aids in developing a rich and productive
                  workplace culture that affects how people behave.
                </Typography>
              </Grid>
              <Grid container sx={{ mt: 5, gap: "14px" }}>
                <Grid
                  item
                  xs={12}
                  md={3}
                  sx={{
                    backgroundColor: "aliceblue",
                    borderRadius: "5px",
                    height: "250px",
                    width: "100%",
                    padding: "10px",
                  }}
                >
                  <Typography
                    variant="h5"
                    sx={{
                      marginBottom: "5px",
                      textAlign: "right",
                      marginRight: "10px",
                    }}
                  >
                    01
                  </Typography>
                  <DiamondIcon />
                  <Typography
                    variant="h5"
                    sx={{ marginTop: "14px", color: "#1a237e" }}
                  >
                    Stimulator
                  </Typography>

                  <Typography variant="body1" sx={{ marginTop: "26px" }}>
                    Supplying motivation to get you going
                  </Typography>
                </Grid>
                <Grid
                  item
                  xs={12}
                  md={3}
                  sx={{
                    backgroundColor: "aliceblue",
                    borderRadius: "5px",
                    height: "250px",
                    width: "100%",
                    padding: "10px",
                  }}
                >
                  <Typography
                    variant="h5"
                    sx={{
                      marginBottom: "5px",
                      textAlign: "right",
                      marginRight: "10px",
                    }}
                  >
                    02
                  </Typography>
                  <ShowChartOutlinedIcon color="secondary" />
                  <Typography
                    variant="h5"
                    sx={{ marginTop: "14px", color: "#1976d2" }}
                  >
                    Advise
                  </Typography>
                  <Typography variant="body1" sx={{ marginTop: "26px" }}>
                    Assisting you in all of your endeavours
                  </Typography>
                </Grid>
                <Grid
                  item
                  xs={12}
                  md={3}
                  sx={{
                    backgroundColor: "aliceblue",
                    borderRadius: "5px",
                    height: "250px",
                    width: "100%",
                    padding: "10px",
                  }}
                >
                  <Typography
                    variant="h5"
                    sx={{
                      marginBottom: "5px",
                      textAlign: "right",
                      marginRight: "10px",
                    }}
                  >
                    03
                  </Typography>
                  <ModeOutlinedIcon sx={{ color: "pink" }} />
                  <Typography
                    variant="h5"
                    sx={{ marginTop: "14px", color: "#9e9e9e" }}
                  >
                    Measures
                  </Typography>
                  <Typography variant="body1" sx={{ marginTop: "26px" }}>
                    We favour action over speculation.
                  </Typography>
                </Grid>
              </Grid>
            </Grid>
            <Grid
              item
              xs={12}
              md={5}
              sx={{ display: "flex", justifyContent: "center" }}
            >
              <Grid
                component="img"
                src="https://ik.imagekit.io/nwssoft/AboutUS-College-Logo/PieChart/MicrosoftTeams-image.png?updatedAt=1680259838919"
                alignSelf="center"
                style={{ width: "100%" }}
              />
            </Grid>
          </Grid>
        </Box>
        <Box sx={{ width: "100%" }}>
          <Grid container sx={{ justifyContent: "space-between" }}>
            <Grid
              item
              xs={12}
              sx={{
                display: "flex",
                justifyContent: "center",
                marginTop: "80px",
              }}
            >
              <Typography
                variant="h5"
                style={{ marginBottom: "0px", color: "#191970" }}
              >
                Our Partners
                <Grid sx={{ display: "flex", justifyContent: "center" }}>
                  <Divider
                    color="orange"
                    sx={{
                      width: "9rem",
                      height: 2,
                      marginTop: "0",
                      padding: "0",
                    }}
                  />
                </Grid>
              </Typography>
            </Grid>
            <Grid container sx={{ justifyContent: "space-around" }}>
              <Box sx={{ flexGrow: 1, my: 2, mx: 2 }}>
                <Grid container spacing={4}>
                  <Grid
                    item
                    sx={{ height: "160px" }}
                    xs={4}
                    sm={4}
                    md={3}
                    component="img"
                    src="https://ik.imagekit.io/nwssoft/AboutUS-College-Logo/Logo/MPOnline.png?updatedAt=1710324877436"
                    alignItems="center"
                    padding={3}
                  />
                  <Grid
                    item
                    sx={{ height: "140px" }}
                    xs={4}
                    sm={4}
                    md={3}
                    component="img"
                    src="https://ik.imagekit.io/nwssoft/AboutUS-College-Logo/Logo/SGVU.png?updatedAt=1710324513287"
                    alignItems="center"
                    padding={3}
                  />
                  <Grid
                    item
                    sx={{ height: "160px" }}
                    xs={4}
                    sm={4}
                    md={3}
                    component="img"
                    src="https://ik.imagekit.io/nwssoft/AboutUS-College-Logo/Logo/GDR.png?updatedAt=1710324129498"
                    alignItems="center"
                    padding={3}
                  />
                  <Grid
                    item
                    sx={{ height: "160px" }}
                    xs={4}
                    sm={4}
                    md={3}
                    component="img"
                    src="https://ik.imagekit.io/nwssoft/AboutUS-College-Logo/Logo/AISECT%20GROUP.png?updatedAt=1710324973457"
                    alignItems="center"
                    padding={3}
                  />
                  <Grid
                    item
                    xs={4}
                    sm={4}
                    md={3}
                    component="img"
                    src="https://ik.imagekit.io/nwssoft/AboutUS-College-Logo/Logo/New_Logo_with_Crop/CU.png?updatedAt=1680174562788"
                    alignItems="center"
                    padding={3}
                  />
                  <Grid
                    item
                    xs={4}
                    sm={4}
                    md={3}
                    component="img"
                    src="https://ik.imagekit.io/nwssoft/AboutUS-College-Logo/Logo/subharti.png?updatedAt=1689938835311"
                    alignItems="center"
                    padding={3}
                  />
                  <Grid
                    item
                    xs={4}
                    sm={4}
                    md={3}
                    component="img"
                    src="https://ik.imagekit.io/nwssoft/AboutUS-College-Logo/Logo/CSC.png?updatedAt=1710323823440"
                    alignItems="center"
                    padding={3}
                  />
                  <Grid
                    item
                    xs={4}
                    sm={4}
                    md={3}
                    component="img"
                    src="https://ik.imagekit.io/nwssoft/AboutUS-College-Logo/Logo/New_Logo_with_Crop/SCDL.png?updatedAt=1680174562763"
                    alignItems="center"
                    padding={3}
                  />
                  <Grid
                    item
                    xs={4}
                    sm={4}
                    md={3}
                    component="img"
                    src="https://ik.imagekit.io/nwssoft/AboutUS-College-Logo/Logo/New_Logo_with_Crop/SGVU__1_.png?updatedAt=1680174566463"
                    alignItems="center"
                    padding={3}
                  />
                  <Grid
                    item
                    xs={4}
                    sm={4}
                    md={3}
                    component="img"
                    src="https://ik.imagekit.io/nwssoft/AboutUS-College-Logo/Logo/New_Logo_with_Crop/mu-01.png?updatedAt=1680174562764"
                    alignItems="center"
                    padding={3}
                  />
                  <Grid
                    item
                    xs={4}
                    sm={4}
                    md={3}
                    component="img"
                    src="https://ik.imagekit.io/nwssoft/AboutUS-College-Logo/Logo/New_Logo_with_Crop/NMIMS-Online.png?updatedAt=1680174562742"
                    alignItems="center"
                    padding={3}
                  />
                  <Grid
                    item
                    xs={4}
                    sm={4}
                    md={3}
                    component="img"
                    src="https://ik.imagekit.io/nwssoft/AboutUS-College-Logo/Logo/board.png?updatedAt=1690175474818"
                    alignItems="center"
                    padding={3}
                  />
                  <Grid
                    item
                    xs={4}
                    sm={4}
                    md={3}
                    component="img"
                    src="https://ik.imagekit.io/nwssoft/AboutUS-College-Logo/Logo/New_Logo_with_Crop/jain.png?updatedAt=1680174562669"
                    alignItems="center"
                    padding={3}
                  />
                  <Grid
                    item
                    xs={4}
                    sm={4}
                    md={3}
                    component="img"
                    src="https://ik.imagekit.io/nwssoft/AboutUS-College-Logo/Logo/school.png?updatedAt=1690175474751"
                    alignItems="center"
                    padding={3}
                  />
                  <Grid
                    item
                    xs={4}
                    sm={4}
                    md={3}
                    component="img"
                    src="https://ik.imagekit.io/nwssoft/AboutUS-College-Logo/Logo/tagore.png?updatedAt=1689943945929"
                    alignItems="center"
                    padding={3}
                  />
                  <Grid
                    item
                    xs={4}
                    sm={4}
                    md={3}
                    component="img"
                    src="https://ik.imagekit.io/nwssoft/AboutUS-College-Logo/Logo/kalinga.png?updatedAt=1690175474418"
                    alignItems="center"
                    paddingBottom={5}
                  />
                  <Grid
                    item
                    xs={4}
                    sm={4}
                    md={3}
                    component="img"
                    src="https://ik.imagekit.io/nwssoft/AboutUS-College-Logo/Logo/cvramanbihar.png?updatedAt=1690175474713"
                    paddingBottom={5}
                  />
                  <Grid
                    item
                    xs={4}
                    sm={4}
                    md={3}
                    component="img"
                    src="https://ik.imagekit.io/nwssoft/AboutUS-College-Logo/Logo/nielit.png?updatedAt=1690175474696"
                    alignItems="center"
                    padding={3}
                  />
                  <Grid
                    item
                    xs={4}
                    sm={4}
                    md={3}
                    component="img"
                    src="https://ik.imagekit.io/nwssoft/AboutUS-College-Logo/Logo/teamlease.png?updatedAt=1690175474730"
                    alignItems="center"
                    padding={3}
                  />
                  <Grid
                    item
                    xs={4}
                    sm={4}
                    md={3}
                    component="img"
                    src="https://ik.imagekit.io/nwssoft/AboutUS-College-Logo/Logo/New_Logo_with_Crop/Aisect_logo.png?updatedAt=1680174562461"
                    alignItems="center"
                    padding={3}
                  />
                </Grid>
              </Box>
            </Grid>
          </Grid>
        </Box>
      </Container>
    </>
  );
}
