import React, { useEffect, useState } from "react";
import Box from "@mui/material/Box";
import InputLabel from "@mui/material/InputLabel";
import MenuItem from "@mui/material/MenuItem";
import FormControl from "@mui/material/FormControl";
import Select from "@mui/material/Select";
import Grid from "@mui/material/Grid";
import Container from "@mui/material/Container";
import TableContainer from "@mui/material/TableContainer";
import TableRow from "@mui/material/TableRow";
import TableCell from "@mui/material/TableCell";
import TableHead from "@mui/material/TableHead";
import SearchIcon from "@mui/icons-material/Search";
import {
  IconButton,
  InputAdornment,
  Paper,
  Table,
  TableBody,
  TablePagination,
  TextField,
  Tooltip,
  Typography,
} from "@mui/material";
import { getRequest } from "../../api/api";
import { GET_UNIVERSITY } from "../../api/server";
import "../../Components/Home/Home.scss";

export default function UniversityCourse() {
  const [data, setData] = useState<any>({});
  const [page, setPage] = React.useState(0);
  const [rowsPerPage, setRowsPerPage] = React.useState(10);
  const [specialization, setSpecialization] = React.useState<any>([]);

  // dropdown for year/semester
  const [selectedValue, setSelectedValue] = useState("Semester/Year");

  const handleChange = (event: any) => {
    setSelectedValue(event.target.value);
  };

  //to store fetched universities data from backend
  const [universities, setUniversities] = React.useState<any[]>([]);
  const [currentUniversity, setCurrentUniversity] = React.useState<any>({});

  //to display the dropdownsdata dynamically
  const [course, setCourse] = React.useState<any>([]);

  async function getDropdownData() {
    try {
      const response2 = await getRequest(GET_UNIVERSITY, "");
      if (response2) {
        setUniversities(response2.data);
        setCurrentUniversity(currentUniversity);
      }
    } catch (error) {
      console.error("Error fetching dropdown data:", error);
    }
  }

  const filteredData = (e: any, cId: string): void => {
    if (e.target.value === "" || cId === undefined) {
      setSpecialization(searchApiData);
    } else {
      const filteredMode = specialization?.filter((item: any) => {
        return (
          currentUniversity.mode[item.sId - 1].type === e.target.value &&
          item?.cId === cId
        );
      });
      setSpecialization(filteredMode);
    }
  };

  //search by keyword function
  const [searchApiData, setSearchApiData] = useState<any>(specialization);
  const [filterVal, setFilterVal] = useState<any>("");

  const handleFilter = (e: any) => {
    if (e.target.value === "") {
      setSpecialization(searchApiData);
    } else {
      const filterResult = specialization?.filter((item: any) => {
        const searchValue = e.target.value.toLowerCase();
        return Object.values(item).some((value) => {
          if (typeof value === "string" || typeof value === "number") {
            const itemValue = String(value).toLowerCase();
            return itemValue.includes(searchValue);
          }
          return false;
        });
      });
      setSpecialization(filterResult);
    }
    setFilterVal(e.target.value);
  };

  const HandleChange = async (e: any, children: any) => {
    try {
      const { value, name } = e.target;
      const { id } = children.props;
      switch (name) {
        case "university":
          setData({ ...data, [name]: value });
          const university = universities?.find((item: any) => item.uId === id);
          setCurrentUniversity(university);
          setSpecialization([]);
          setCourse([]);
          break;
        case "department":
          setData({ ...data, [name]: value });
          setSpecialization([]);
          const courseList = currentUniversity?.course?.filter(
            (item: any) => item.dId === id
          );
          setCourse(courseList);
          break;
        case "course":
          setData({ ...data, [name]: value });
          const specializationList = currentUniversity?.specialization?.filter(
            (item: any) => item.cId === id
          );
          setSpecialization(specializationList);
          setSearchApiData(specializationList);
          break;
        default:
      }
    } catch (error) {
      console.error("Error in HandleChange:", error);
    }
  };

  const handleChangePage = (event: any, newPage: any) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event: any) => {
    setRowsPerPage(+event.target.value);
    setPage(0);
  };

  useEffect(() => {
    getDropdownData();
  }, []);

  return (
    <Container sx={{ mt: 3 }}>
      <Paper elevation={20}>
        <Box sx={{ minWidth: 120 }}>
          <Grid
            container
            sx={{
              display: "flex",
              alignItems: "center",
              background: "#0288d1",
              color: "white",
              p: 2,
            }}
          >
            <Grid item xs={4}>
              <Typography variant="h5" sx={{ mx: 3 }}>
                University Courses
              </Typography>
            </Grid>

            {/* select year/semester  */}
            <Grid item xs={4}>
              <FormControl fullWidth>
                <InputLabel id="demo-simple-country-label">
                  Course Type
                </InputLabel>
                <Select
                  labelId="demo-simple-select-required-label"
                  className="form-control"
                  label="University"
                  type="text"
                  required
                  style={{ backgroundColor: "white" }}
                  value={selectedValue}
                  onChange={(e) => {
                    handleChange(e);
                    if (specialization.length === 0) {
                      // Handle error when array of objects is empty
                      setSpecialization(searchApiData);
                      filteredData(e, specialization[0]?.cId);
                    } else {
                      filteredData(e, specialization[0]?.cId);
                    }
                  }}
                >
                  <MenuItem value="">--select--</MenuItem>
                  <MenuItem value="Yearly">Yearly</MenuItem>
                  <MenuItem value="Semester">Semester</MenuItem>
                </Select>
              </FormControl>
            </Grid>

            {/* search button */}
            <Grid
              item
              xs={4}
              sx={{ display: "flex", justifyContent: "flex-end" }}
            >
              <FormControl>
                <TextField
                  label="Search By Specialization"
                  size="medium"
                  value={filterVal}
                  onInput={(e: any) => handleFilter(e)}
                  sx={{ mx: 3, background: "white" }}
                  InputLabelProps={{
                    style: { color: "#333333" },
                  }}
                  InputProps={{
                    endAdornment: (
                      <InputAdornment position="end">
                        <IconButton edge="end">
                          <SearchIcon />
                        </IconButton>
                      </InputAdornment>
                    ),
                  }}
                />
              </FormControl>
            </Grid>
          </Grid>

          <Grid
            container
            rowSpacing={1}
            columnSpacing={{ xs: 1, sm: 2, md: 3 }}
            sx={{ mt: 1, p: 1 }}
          >
            <Grid item xs={4}>
              <FormControl fullWidth required>
                <InputLabel id="demo-simple-select-required-label">
                  University
                </InputLabel>
                <Select
                  name="university"
                  labelId="demo-simple-select-required-label"
                  className="form-control"
                  label="University"
                  type="text"
                  required
                  onChange={HandleChange}
                >
                  {universities?.map((Object: any) => (
                    <MenuItem
                      value={Object.universityName}
                      key={Object._id}
                      id={Object.uId}
                    >
                      {Object.universityName}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
            </Grid>
            <Grid item xs={4}>
              <FormControl fullWidth required>
                <InputLabel id="demo-simple-select-required-label">
                  Department
                </InputLabel>
                <Select
                  labelId="demo-simple-select-required-label"
                  className="form-control"
                  label="Department"
                  type="text"
                  name="department"
                  required
                  onChange={HandleChange}
                >
                  {currentUniversity?.department?.map((Object: any) => (
                    <MenuItem
                      value={Object.name}
                      key={Object._id}
                      id={Object.dId}
                    >
                      {Object.name}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
            </Grid>
            <Grid item xs={4}>
              <FormControl fullWidth required>
                <InputLabel id="demo-simple-select-required-label">
                  Course
                </InputLabel>
                <Select
                  labelId="demo-simple-select-required-label"
                  label="Course"
                  value={data.course}
                  name="course"
                  onChange={HandleChange}
                >
                  {course?.map((Object: any) => (
                    <MenuItem
                      key={Object._id}
                      value={Object.name}
                      id={Object.cId}
                    >
                      {Object.name}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
            </Grid>

            <Grid item xs={12}>
              <TableContainer className="scrollBarCss">
                <Table sx={{ minWidth: 650 }} aria-label="simple table">
                  <TableHead>
                    <TableRow>
                      <TableCell
                        sx={{
                          textAlign: "center",
                          border: "1px solid #ddd",
                          fontSize: "15px",
                        }}
                      >
                        Sr.No
                      </TableCell>
                      <TableCell
                        sx={{
                          textAlign: "center",
                          border: "1px solid #ddd",
                          fontSize: "15px",
                        }}
                      >
                        specialization
                      </TableCell>
                      <TableCell
                        sx={{
                          textAlign: "center",
                          border: "1px solid #ddd",
                          fontSize: "15px",
                        }}
                      >
                        Courses Categeory
                      </TableCell>
                      <TableCell
                        sx={{
                          textAlign: "center",
                          border: "1px solid #ddd",
                          fontSize: "15px",
                        }}
                      >
                        Mode
                      </TableCell>
                      <TableCell
                        sx={{
                          textAlign: "center",
                          border: "1px solid #ddd",
                          fontSize: "15px",
                        }}
                      >
                        No. Of {selectedValue ? selectedValue : "Both"}
                      </TableCell>
                      <TableCell
                        sx={{
                          textAlign: "center",
                          border: "1px solid #ddd",
                          fontSize: "15px",
                        }}
                      >
                        Eligibility
                      </TableCell>
                      <TableCell
                        sx={{
                          textAlign: "center",
                          border: "1px solid #ddd",
                          fontSize: "15px",
                        }}
                      >
                        Total Fee
                      </TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {specialization
                      .slice(
                        page * rowsPerPage,
                        page * rowsPerPage + rowsPerPage
                      )
                      .map((item: any, index: any) => {
                        const courseTypeData =
                          currentUniversity?.courseType?.filter(
                            (course: any) =>
                              course.ccId ===
                              currentUniversity.specialization.sId
                          );
                        return (
                          <TableRow
                            key={index}
                            sx={{
                              textAlign: "center",
                              borderRight: "1px solid #ddd",
                            }}
                          >
                            <TableCell
                              component="th"
                              scope="row "
                              sx={{
                                textAlign: "center",
                                borderRight: "1px solid #ddd",
                                borderLeft: "1px solid #ddd",
                              }}
                            >
                              {page * rowsPerPage + index + 1}
                            </TableCell>
                            <TableCell
                              sx={{
                                textAlign: "center",
                                borderRight: "1px solid #ddd",
                              }}
                            >
                              {item.name}
                            </TableCell>
                            <TableCell
                              sx={{
                                textAlign: "center",
                                borderRight: "1px solid #ddd",
                              }}
                            >
                              {
                                currentUniversity.courseCategory[item.sId - 1]
                                  ?.type
                              }
                            </TableCell>
                            <TableCell
                              sx={{
                                textAlign: "center",
                                borderRight: "1px solid #ddd",
                              }}
                            >
                              {currentUniversity.mode[item.sId - 1]?.type}
                            </TableCell>
                            <TableCell
                              sx={{
                                textAlign: "center",
                                borderRight: "1px solid #ddd",
                              }}
                            >
                              {
                                currentUniversity.noOfSemsOrNoOfYears[
                                  item.sId - 1
                                ]?.value
                              }
                            </TableCell>
                            <TableCell
                              sx={{
                                textAlign: "center",
                                borderRight: "1px solid #ddd",
                              }}
                            >
                              {
                                currentUniversity.eligibility[item.sId - 1]
                                  ?.details
                              }
                            </TableCell>
                            <Tooltip
                              title={`Tuition Fee: ${
                                currentUniversity.tuitionFee[item.sId - 1]
                                  ?.value
                              } | Other Fee: ${
                                currentUniversity.otherFee[item.sId - 1]?.value
                              }`}
                              placement="right-start"
                            >
                              <TableCell
                                sx={{
                                  textAlign: "center",
                                  borderRight: "1px solid #ddd",
                                }}
                              >
                                {
                                  currentUniversity.totalFee[item.sId - 1]
                                    ?.value
                                }
                              </TableCell>
                            </Tooltip>
                          </TableRow>
                        );
                      })}
                  </TableBody>
                </Table>
              </TableContainer>
              <TablePagination
                rowsPerPageOptions={[10, 25, 50, 100]}
                component="div"
                count={specialization.length}
                rowsPerPage={rowsPerPage}
                page={page}
                onPageChange={handleChangePage}
                onRowsPerPageChange={handleChangeRowsPerPage}
              ></TablePagination>
            </Grid>
          </Grid>
        </Box>
      </Paper>
    </Container>
  );
}
