import {
  Box,
  Button,
  Container,
  FormControl,
  Grid,
  InputLabel,
  LinearProgress,
  MenuItem,
  Paper,
  Select,
  TextField,
  Typography,
} from "@mui/material";
import { useEffect, useRef, useState } from "react";
import useValidation from "../../Hooks/useTypescriptValidation";
import { getRequestById, postRequestMethod } from "../../api/api";
import { POST_REF_LEAD, STUDENT_DATA } from "../../api/server";
import CommonSnackBar from "../../Components/Common/CommonSnackbar";
import stateCity from "../../Components/Common/stateCity.json";

export default function ReferenceStudent(props: any) {
  const { lId } = props?.types;
  const formRef = useRef<HTMLFormElement>(null);
  const { eventHandler } = useValidation();
  const [openSnackBar, setOpenSnackBar] = useState(false);
  const [snackMessage, setSnackMessage] = useState<string>("");

  //state for state and city dropdown
  const [statesCity] = useState<any>(stateCity);

  //for progress bar
  const [isLoading, setIsLoading] = useState(false);

  // form data state
  const [formData, setFormData] = useState<any>({
    refName: "",
    referedExecutiveName: "",
    fname: "",
    lname: "",
    hideName: "",
    email: "",
    phone: "",
    qualification: "",
    interestedCourse: "",
    state: "",
    city: "",
    referedExecutiveId: "",
    studentReference: lId,
  });

  // state to store error
  const [errors, setErrors] = useState<any>({
    fname: "",
    lname: "",
    email: "",
    phone: "",
    qualification: "",
    interestedCourse: "",
  });

  // Function to update the input data in a state
  const stateUpdater = (e: any) => {
    setFormData({ ...formData, [e.target.name]: e.target.value });
  };

  // handle snackbar
  const handleOpenSnackBar = () => {
    setOpenSnackBar(true);
    setTimeout(() => {
      setOpenSnackBar(false);
    }, 5000);
  };

  // to check fields are filled or not
  const validator = () => {
    for (let field in errors) {
      if (errors[field] !== "") {
        return false;
      }
    }
    return true;
  };

  const stateClearer = () => {
    setFormData({
      ...formData,
      fname: "",
      lname: "",
      hideName: "",
      email: "",
      phone: "",
      qualification: "",
      interestedCourse: "",
      state: "",
      city: "",
    });
  };

  const onSubmit = async (e: any) => {
    e.preventDefault();
    if (validator()) {
      try {
        setIsLoading(true);
        postRequestMethod(POST_REF_LEAD, "", formData).then((res) => {
          setIsLoading(false);
          if (res) {
            switch (true) {
              case res.data?.both:
                setSnackMessage("Email and Phone Number Already Present..!");
                break;
              case res.data?.email:
                setSnackMessage("Email Already Present..!");
                break;
              case res.data?.phone:
                setSnackMessage("Phone Number Already Present..!");
                break;
              default:
                setSnackMessage("Lead data added successfully");
                if (formRef.current) {
                  formRef.current.reset();
                  stateClearer();
                }
            }
            handleOpenSnackBar();
          }
        });
      } catch (error) {
        console.error("Error on adding reference user:", error);
      }
    } else {
      setSnackMessage("Please fill all the fields");
      handleOpenSnackBar();
    }
  };

  const ValidationHandler = async (e: any, alterName?: any) => {
    const val = e.target.value;
    const id = alterName;
    if (id) {
      let prom = new Promise((resolve) => {
        if (true) {
          resolve(eventHandler(id, val));
        }
      });
      prom.then((res) => setErrors({ ...errors, [e.target.name]: res }));
    }
  };

  const getStudentDetailed = async () => {
    try {
      let res = await getRequestById(STUDENT_DATA, lId, "");
      setFormData((previousData: any) => ({
        ...previousData,
        ...res?.data,
      }));
    } catch (error) {
      console.error(error);
    }
  };

  useEffect(() => {
    if (lId) {
      getStudentDetailed();
    }
  }, [props]);

  return (
    <>
      <Container maxWidth="lg">
        {isLoading && <LinearProgress />}
        <Paper elevation={20} sx={{ p: 4 }}>
          <Box
            component="form"
            ref={formRef}
            onSubmit={onSubmit}
            sx={{ flexGrow: 1 }}
          >
            <Typography
              variant="h6"
              align="center"
              sx={{ m: "10px", color: "#0288d1" }}
            >
              Reference Student
            </Typography>
            <Grid
              container
              rowSpacing={1}
              columnSpacing={{ xs: 1, sm: 2, md: 3 }}
            >
              <Grid item xs={12} sm={12}>
                {/* executive Name */}
                <TextField
                  sx={{ mt: 2, background: "white" }}
                  disabled
                  fullWidth
                  id="outlined-basic"
                  label="Executive Name"
                  variant="outlined"
                  name="referedExecutiveName"
                  value={formData.referedExecutiveName}
                />
              </Grid>

              <Grid item xs={12} sm={12}>
                {/* student Name */}
                <TextField
                  sx={{ mt: 2, background: "white" }}
                  disabled
                  fullWidth
                  id="outlined-basic"
                  label="Student Name"
                  variant="outlined"
                  name="refName"
                  value={formData.refName}
                />
              </Grid>
              <Grid item xs={12} sm={12}>
                {/* hide name */}
                <FormControl fullWidth sx={{ mt: 2 }}>
                  <InputLabel id="demo-simple-country-label">
                    Do you want to hide your name for reference?
                  </InputLabel>
                  <Select
                    required
                    labelId="demo-simple-country-label"
                    defaultValue=""
                    name="hideName"
                    className="form-control"
                    label="Do you want to hide your name for reference?"
                    fullWidth
                    value={formData.hideName}
                    onChange={stateUpdater}
                    >
                    <MenuItem value={""}>--Select--</MenuItem>
                    <MenuItem value={"Yes"}>Yes</MenuItem>
                    <MenuItem value={"No"}>No</MenuItem>
                  </Select>
                </FormControl>
              </Grid>
              <Grid item xs={12} sm={6}>
                {/* First Name */}
                <TextField
                  sx={{ mt: 2, background: "white" }}
                  required
                  fullWidth
                  id="outlined-basic"
                  label="Enter  First Name"
                  variant="outlined"
                  name="fname"
                  error={Boolean(errors.fname)}
                  helperText={errors.fname}
                  onChange={stateUpdater}
                  onBlur={(e) => {
                    ValidationHandler(e, "alphabet");
                  }}
                />
              </Grid>
              <Grid item xs={12} sm={6}>
                {/* last Name */}
                <TextField
                  sx={{ mt: 2, background: "white" }}
                  fullWidth
                  id="outlined-basic"
                  label="Enter Last Name"
                  variant="outlined"
                  name="lname"
                  required
                  error={Boolean(errors.lname)}
                  helperText={errors.lname}
                  onChange={stateUpdater}
                  onBlur={(e) => {
                    ValidationHandler(e, "alphabet");
                  }}
                />
              </Grid>
              <Grid item xs={12} sm={6}>
                {/* Email */}
                <TextField
                  sx={{ mt: 2, background: "white" }}
                  fullWidth
                  id="outlined-basic"
                  label="Enter Email"
                  variant="outlined"
                  name="email"
                  required
                  error={Boolean(errors.email)}
                  helperText={errors.email}
                  onChange={stateUpdater}
                  onBlur={(e) => {
                    ValidationHandler(e, "email");
                  }}
                />
              </Grid>
              <Grid item xs={12} sm={6}>
                {/* Phone */}
                <TextField
                  sx={{ mt: 2, background: "white" }}
                  fullWidth
                  required
                  id="outlined-basic"
                  label="Enter Phone no"
                  variant="outlined"
                  name="phone"
                  error={Boolean(errors.phone)}
                  helperText={errors.phone}
                  onChange={stateUpdater}
                  onBlur={(e) => {
                    ValidationHandler(e, "mobile");
                  }}
                />
              </Grid>
              <Grid item xs={12} sm={6}>
                {/* Qualification */}
                <TextField
                  sx={{ mt: 2, background: "white" }}
                  fullWidth
                  required
                  id="outlined-basic"
                  label="Qualification"
                  variant="outlined"
                  name="qualification"
                  error={Boolean(errors.qualification)}
                  helperText={errors.qualification}
                  onChange={stateUpdater}
                  onBlur={(e) => {
                    ValidationHandler(e, "alphabet");
                  }}
                />
              </Grid>
              <Grid item xs={12} sm={6}>
                {/* Interested Course */}
                <TextField
                  sx={{ mt: 2, background: "white" }}
                  fullWidth
                  required
                  id="outlined-basic"
                  label="Interested Course"
                  variant="outlined"
                  name="interestedCourse"
                  error={Boolean(errors.interestedCourse)}
                  helperText={errors.interestedCourse}
                  onChange={stateUpdater}
                  onBlur={(e) => {
                    ValidationHandler(e, "alphabet");
                  }}
                />
              </Grid>
              <Grid item xs={12} sm={6}>
                {/* State */}
                <FormControl fullWidth sx={{ mt: 2 }}>
                  <InputLabel id="demo-simple-country-label">State</InputLabel>
                  <Select
                    required
                    labelId="demo-simple-country-label"
                    name="state"
                    className="form-control"
                    label="State"
                    value={formData.state}
                    onChange={stateUpdater}
                    fullWidth
                  >
                    <MenuItem>--Select State--</MenuItem>
                    {statesCity.map((state: any) => (
                      <MenuItem value={state.state_name} key={state.state_name}>
                        {state.state_name}
                      </MenuItem>
                    ))}
                  </Select>
                </FormControl>
              </Grid>
              <Grid item xs={12} sm={6}>
                {/* City */}
                <FormControl fullWidth sx={{ mt: 2 }}>
                  <InputLabel id="demo-simple-country-label">City</InputLabel>
                  <Select
                    required
                    labelId="demo-simple-country-label"
                    name="city"
                    className="form-control"
                    label="State"
                    value={formData.city}
                    onChange={stateUpdater}
                    fullWidth
                    sx={{ mb: "12px" }}
                  >
                    <MenuItem>--Select City--</MenuItem>
                    {formData.state &&
                      statesCity
                        .find(
                          (state: any) => state.state_name === formData.state
                        )
                        ?.cities.map((city: any, index: any) => (
                          <MenuItem value={city} key={index}>
                            {city}
                          </MenuItem>
                        ))}
                  </Select>
                </FormControl>
              </Grid>
            </Grid>
            <Button
              variant="contained"
              type="submit"
              onClick={() => {
                window.scrollTo(0, 0);
              }}
            >
              Submit
            </Button>
            <CommonSnackBar message={snackMessage} open={openSnackBar} />
          </Box>
        </Paper>
      </Container>
    </>
  );
}
