import {
  Page,
  Text,
  Image,
  Document,
  StyleSheet,
  View,
} from "@react-pdf/renderer";

const styles = StyleSheet.create({
  body: {
    paddingTop: 35,
    paddingBottom: 65,
    paddingHorizontal: 35,
  },
  para1: {
    flexDirection: "row",
    flexWrap: "wrap",
  },
  para2: {
    flexDirection: "row",
    flexWrap: "wrap",
    marginTop: 15,
    padding: 10,
  },
  column1: {
    width: "20%",
    padding: 20,
  },
  column2: {
    width: "80%",
    padding: 10,
  },
  column3: {
    width: "50%",
  },
  column4: {
    width: "50%",
    alignItems: "flex-end",
  },
  text: {
    fontSize: 12,
    textAlign: "justify",
  },
  text1: {
    fontSize: 15,
    textAlign: "center",
    marginBottom: 10,
  },
  text3: {
    fontSize: 12,
    textAlign: "justify",
    padding: 30,
    marginTop: 5,
    textIndent: "50px",
    letterSpacing: "0px",
    lineHeight: "2px",
  },
  image: {
    height: 70,
    width: 70,
  },
  pageNumber: {
    position: "absolute",
    fontSize: 12,
    bottom: 30,
    left: 0,
    right: 0,
    textAlign: "center",
    color: "grey",
    fontFamily: "AntonFamily",
  },
  border: {
    borderBottom: " 1px solid black",
    margin: 5,
  },
  wish: {
    margin: 30,
  },
  sign: {
    margin: 30,
  },
});

const ExperienceLetter = (props: any) => {
  let date = new Date();
  let day = date.getDate();
  let month = date.getMonth() + 1;
  let year = date.getFullYear();
  let currentDate = `${day}-${month}-${year}`;

  return (
    <>
      <Document>
        return(
        <Page size="A4">
          <View style={styles.para1}>
            <View style={styles.column1}>
              <Image
                style={styles.image}
                src="https://ik.imagekit.io/nwssoft/AboutUS-College-Logo/Logo/New_Logo_with_Crop/prena_acedemy_logo.jfif?updatedAt=1685344751380"
              ></Image>
            </View>
            <View style={styles.column2}>
              <Text style={styles.text}>PRERNA ACADEMY PVT. LTD., INDORE</Text>
              <Text style={styles.text}>
                Corporate Office : 82 Swami Vivekanand Nagar Telephone Square
                Kanadia Road Indore
              </Text>
              <Text style={styles.text}>(M.P) pin 452018</Text>
              <Text style={styles.text}>
                Ph. : (+91) 835 889 9900, (0731) 4298599
              </Text>
              <Text style={styles.text}>Toll Free No. : 1800120-224466</Text>
              <Text style={styles.text}>Email :info@prernaacademy.in</Text>
              <Text style={styles.text}>website: www.prernaacademy.in</Text>
            </View>
          </View>
          <View style={styles.border}></View>
          <View style={styles.para2}>
            <View style={styles.column3}>
              <Text style={styles.text}>No.:{props?.item?.empId}</Text>
            </View>
            <View style={styles.column4}>
              <Text style={styles.text}>Date:{currentDate}</Text>
            </View>
          </View>
          <View>
            <Text style={styles.text1}>TO WHOM IT MAY CONCERN</Text>
          </View>
          <View>
            <Text style={styles.text3}>
              It is to certify that Mr./Mrs./Miss.{" "}
              {`${props?.item?.firstName}` + " " + `${props?.item?.lastName}`}{" "}
              has served our company as{" "}
              {`${props?.item?.post}` + " " + `${props?.item?.type}` + " "}
              from {`${props?.item?.dateOfJoining}`} to{" "}
              {`${props?.item?.dateOfResignation}`}. During his stay with
              us he performed his duties in a professional manner. His efforts
              to train junior staff of marketing and coping with the latest
              marketing requirement are appreciable. We found him an asset for
              growth of our company and business expansion. He developed such
              marketing and promotional schemes those worked as revenue
              generator for our company. We are also sure that he can prove his
              abilities in any kind of working environment because of his
              flexibility, expertise, communication skills, pleasant
              personality, hardworking attitude and latest marking knowledge.
              His way of thinking about the market and their trends can be a
              role model for others. We wish him good luck in his future
              endeavor.
            </Text>
          </View>

          <View style={styles.wish}>
            <Text style={styles.text}>With Best Wish</Text>
          </View>
          <View style={styles.sign}>
            <Text style={styles.text}>Signatory Authority</Text>
          </View>
        </Page>
        )
      </Document>
    </>
  );
};

export default ExperienceLetter;
