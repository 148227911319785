import * as React from "react";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Paper from "@mui/material/Paper";
import { useEffect, useState } from "react";
import {
  Container,
  FormControl,
  Grid,
  IconButton,
  InputAdornment,
  TablePagination,
  Typography,
} from "@mui/material";
import SearchIcon from "@mui/icons-material/Search";
import TextField from "@mui/material/TextField";
import "../../Components/Home/Home.scss";
import { GET_PAID_COMMISSION_EXECUTIVE } from "../../api/server";
import { getRequest } from "../../api/api";
import CommonSnackBar from "../../Components/Common/CommonSnackbar";

export default function ExecutivePaid() {
  const [page, setPage] = React.useState(0);
  const [rowsPerPage, setRowsPerPage] = React.useState(10);
  const [searchApiData, setSearchApiData] = useState<any>([]);
  const [filterVal, setFilterVal] = useState<string>("");
  const [users, setUsers] = useState<any[]>([]);
  const [snackMessage, setSnackMessage] = useState<string>("");
  const [openSnackBar, setOpenSnackBar] = useState(false);

  const handleChangePage = (event: any, newPage: any) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event: any) => {
    setRowsPerPage(+event.target.value);
    setPage(0);
  };

  const handleFilter = (e: any) => {
    if (e.target.value === "") {
      setUsers(searchApiData);
    } else {
      const filterResult = searchApiData.filter((item: any) => {
        const searchValue = e.target.value.toLowerCase();
        return Object.values(item).some((value) => {
          if (typeof value === "string" || typeof value === "number") {
            const itemValue = String(value).toLowerCase();
            return itemValue.includes(searchValue);
          }
          return false;
        });
      });
      setUsers(filterResult);
    }
    setFilterVal(e.target.value);
  };

  const handleOpenSnackBar = () => {
    setOpenSnackBar(true);
    setTimeout(() => {
      setOpenSnackBar(false);
    }, 3000);
  };

  const fetchData = async () => {
    try {
      const resultPaid: any = await getRequest(
        GET_PAID_COMMISSION_EXECUTIVE,
        ""
      );
      if (resultPaid.data?.error) {
        setSnackMessage(resultPaid.data?.error);
        handleOpenSnackBar();
      } else {
        setUsers(resultPaid.data);
        setSearchApiData(resultPaid.data);
      }
    } catch (error) {
      console.error("Error loading users:", error);
    }
  };

  useEffect(() => {
    fetchData();
  }, []);

  return (
    <>
      <Container>
        <Paper elevation={20}>
          <Grid container sx={{ p: 2, background: "#0288d1", color: "white" }}>
            <Grid item xs={8}>
              <Typography variant="h5" sx={{ mx: 3 }}>
                Manage Executive Paid Commission
              </Typography>
            </Grid>
            <Grid
              item
              xs={4}
              sx={{ display: "flex", justifyContent: "flex-end" }}
            >
              <FormControl>
                <TextField
                  label="Search"
                  size="small"
                  value={filterVal}
                  onInput={(e: any) => handleFilter(e)}
                  sx={{ mx: 3, background: "white" }}
                  InputProps={{
                    endAdornment: (
                      <InputAdornment position="end">
                        <IconButton edge="end">
                          <SearchIcon />
                        </IconButton>
                      </InputAdornment>
                    ),
                  }}
                />
              </FormControl>
            </Grid>
          </Grid>

          <TableContainer component={Paper} className="scrollBarCss">
            <Table sx={{ minWidth: 650 }} aria-label="simple table">
              <TableHead>
                <TableRow>
                  <TableCell
                    sx={{
                      textAlign: "center",
                      border: "1px solid #ddd",
                    }}
                  >
                    Sr.No
                  </TableCell>
                  <TableCell
                    sx={{
                      textAlign: "center",
                      border: "1px solid #ddd",
                    }}
                  >
                    Employee Id
                  </TableCell>
                  <TableCell
                    sx={{
                      textAlign: "center",
                      border: "1px solid #ddd",
                    }}
                  >
                    Name
                  </TableCell>
                  <TableCell
                    sx={{
                      textAlign: "center",
                      border: "1px solid #ddd",
                    }}
                  >
                    Email
                  </TableCell>
                  <TableCell
                    sx={{
                      textAlign: "center",
                      border: "1px solid #ddd",
                    }}
                  >
                    Phone No.
                  </TableCell>
                  <TableCell
                    sx={{
                      textAlign: "center",
                      border: "1px solid #ddd",
                    }}
                  >
                    Commission
                  </TableCell>
                  <TableCell
                    sx={{
                      textAlign: "center",
                      border: "1px solid #ddd",
                    }}
                  >
                    Commission By
                  </TableCell>
                  <TableCell
                    sx={{
                      textAlign: "center",
                      border: "1px solid #ddd",
                    }}
                  >
                    Status
                  </TableCell>
                  <TableCell
                    sx={{
                      textAlign: "center",
                      border: "1px solid #ddd",
                    }}
                  >
                    Deposit Date.
                  </TableCell>
                  <TableCell
                    sx={{
                      textAlign: "center",
                      border: "1px solid #ddd",
                    }}
                  >
                    Bank Name
                  </TableCell>
                  <TableCell
                    sx={{
                      textAlign: "center",
                      border: "1px solid #ddd",
                    }}
                  >
                    Cheque No.
                  </TableCell>
                  <TableCell
                    sx={{
                      textAlign: "center",
                      border: "1px solid #ddd",
                    }}
                  >
                    Account No.
                  </TableCell>
                  <TableCell
                    sx={{
                      textAlign: "center",
                      border: "1px solid #ddd",
                    }}
                  >
                    Transfer Id
                  </TableCell>
                  <TableCell
                    sx={{
                      textAlign: "center",
                      border: "1px solid #ddd",
                    }}
                  >
                    Payment Mode
                  </TableCell>
                  <TableCell
                    sx={{
                      textAlign: "center",
                      border: "1px solid #ddd",
                    }}
                  >
                    Remark
                  </TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {users
                  ?.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                  .map((item, index: number) => (
                    <TableRow key={item._id}>
                      <TableCell
                        sx={{
                          textAlign: "center",
                          border: "1px solid #ddd",
                        }}
                      >
                        {page * rowsPerPage + index + 1}{" "}
                      </TableCell>
                      <TableCell
                        sx={{
                          textAlign: "center",
                          border: "1px solid #ddd",
                        }}
                      >
                        {item.empId}
                      </TableCell>
                      <TableCell
                        sx={{
                          textAlign: "center",
                          border: "1px solid #ddd",
                        }}
                      >
                        {item.name}
                      </TableCell>
                      <TableCell
                        sx={{
                          textAlign: "center",
                          border: "1px solid #ddd",
                        }}
                      >
                        {item.email}
                      </TableCell>
                      <TableCell
                        sx={{
                          textAlign: "center",
                          border: "1px solid #ddd",
                        }}
                      >
                        {item.phone}
                      </TableCell>
                      <TableCell
                        sx={{
                          textAlign: "center",
                          border: "1px solid #ddd",
                        }}
                      >
                        {item.commission}
                      </TableCell>
                      <TableCell
                        sx={{
                          textAlign: "center",
                          border: "1px solid #ddd",
                        }}
                      >
                        {item.commissionBy}
                      </TableCell>
                      <TableCell
                        sx={{
                          textAlign: "center",
                          border: "1px solid #ddd",
                        }}
                      >
                        {item.status}
                      </TableCell>
                      <TableCell
                        sx={{
                          textAlign: "center",
                          border: "1px solid #ddd",
                        }}
                      >
                        {item.depositDate}
                      </TableCell>
                      <TableCell
                        sx={{
                          textAlign: "center",
                          border: "1px solid #ddd",
                        }}
                      >
                        {item.bankName}
                      </TableCell>
                      <TableCell
                        sx={{
                          textAlign: "center",
                          border: "1px solid #ddd",
                        }}
                      >
                        {item.chequeNo}
                      </TableCell>
                      <TableCell
                        sx={{
                          textAlign: "center",
                          border: "1px solid #ddd",
                        }}
                      >
                        {item.accountNo}
                      </TableCell>
                      <TableCell
                        sx={{
                          textAlign: "center",
                          border: "1px solid #ddd",
                        }}
                      >
                        {item.transferId}
                      </TableCell>
                      <TableCell
                        sx={{
                          textAlign: "center",
                          border: "1px solid #ddd",
                        }}
                      >
                        {item.paymentMode}
                      </TableCell>
                      <TableCell
                        sx={{
                          textAlign: "center",
                          border: "1px solid #ddd",
                        }}
                      >
                        {item.remark}
                      </TableCell>
                    </TableRow>
                  ))}
              </TableBody>
            </Table>
          </TableContainer>
          <TablePagination
            rowsPerPageOptions={[10, 25, 50, 100]}
            component="div"
            count={users?.length}
            rowsPerPage={rowsPerPage}
            page={page}
            onPageChange={handleChangePage}
            onRowsPerPageChange={handleChangeRowsPerPage}
          ></TablePagination>
        </Paper>
      </Container>
      <CommonSnackBar message={snackMessage} open={openSnackBar} />
    </>
  );
}
