import {
  Container,
  Paper,
  Typography,
  TableContainer,
  Table,
  TableHead,
  TableRow,
  TableCell,
  Grid,
  TableBody,
  TablePagination,
} from "@mui/material";
import { getRequestById } from "../../api/api";
import { GET_LEAVE_BY_ID } from "../../api/server";
import { Fragment, useEffect, useState } from "react";

export default function ViewLeaveStatus(props: any) {
  const [tableData, setTableData] = useState<any[]>([]);
  // for pagination
  const [page, setPage] = useState<number>(0);
  const [rowsPerPage, setRowsPerPage] = useState<number>(10);

  // for table pagination
  const handleChangePage = (event: any, newPage: any) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event: any) => {
    setRowsPerPage(+event.target.value);
    setPage(0);
  };

  const getTableData = async () => {
    try {
      let id = props.types.lId;
      let result = await getRequestById(GET_LEAVE_BY_ID, id, "");
      if (result) {
        setTableData(result.data);
      }
    } catch (error) {
      console.error("Error getting leave data", error);
    }
  };

  useEffect(() => {
    getTableData();
  }, []);

  return (
    <Container>
      <Paper elevation={20}>
        <Grid
          container
          sx={{
            p: 2,
            background: "#0288d1",
            color: "white",
          }}
        >
          <Grid item xs={6}>
            <Typography variant="h5" sx={{ mx: 3 }}>
              Leave Detail
            </Typography>
          </Grid>
        </Grid>
        <TableContainer component="form" className="scrollBarCss">
          <Table>
            <TableHead>
              <TableRow>
                <TableCell
                  sx={{
                    textAlign: "center",
                    border: "1px solid #ddd",
                    fontSize: "15px",
                  }}
                >
                  Sr. No.
                </TableCell>
                <TableCell
                  sx={{
                    textAlign: "center",
                    border: "1px solid #ddd",
                    fontSize: "15px",
                  }}
                >
                  Reason For Leave
                </TableCell>
                <TableCell
                  sx={{
                    textAlign: "center",
                    border: "1px solid #ddd",
                    fontSize: "15px",
                  }}
                >
                  From Date
                </TableCell>
                <TableCell
                  sx={{
                    textAlign: "center",
                    border: "1px solid #ddd",
                    fontSize: "15px",
                  }}
                >
                  To Date
                </TableCell>
                <TableCell
                  sx={{
                    textAlign: "center",
                    border: "1px solid #ddd",
                    fontSize: "15px",
                  }}
                >
                  Apply Date
                </TableCell>
                <TableCell
                  sx={{
                    textAlign: "center",
                    border: "1px solid #ddd",
                    fontSize: "15px",
                  }}
                >
                  Remark
                </TableCell>
                <TableCell
                  sx={{
                    textAlign: "center",
                    border: "1px solid #ddd",
                    fontSize: "15px",
                  }}
                >
                  Action
                </TableCell>
              </TableRow>
            </TableHead>

            <TableBody>
              {tableData
                ?.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                .map((record: any, index: number) => (
                  <Fragment key={record._id}>
                    <TableRow>
                      <TableCell
                        sx={{
                          textAlign: "center",
                          border: "1px solid #ddd",
                          fontSize: "15px",
                        }}
                      >
                        {page * rowsPerPage + index + 1}
                      </TableCell>
                      <TableCell
                        sx={{
                          textAlign: "center",
                          border: "1px solid #ddd",
                          fontSize: "15px",
                        }}
                      >
                        {record?.reasonForLeave}
                      </TableCell>
                      <TableCell
                        sx={{
                          textAlign: "center",
                          border: "1px solid #ddd",
                          fontSize: "15px",
                        }}
                      >
                        {record?.fromDate}
                      </TableCell>
                      <TableCell
                        sx={{
                          textAlign: "center",
                          border: "1px solid #ddd",
                          fontSize: "15px",
                        }}
                      >
                        {record?.toDate}
                      </TableCell>
                      <TableCell
                        sx={{
                          textAlign: "center",
                          border: "1px solid #ddd",
                          fontSize: "15px",
                        }}
                      >
                        {record?.createdAt}
                      </TableCell>
                      <TableCell
                        sx={{
                          textAlign: "center",
                          border: "1px solid #ddd",
                          fontSize: "15px",
                        }}
                      >
                        {record?.remark}
                      </TableCell>
                      <TableCell
                        sx={{
                          textAlign: "center",
                          border: "1px solid #ddd",
                          fontSize: "15px",
                        }}
                      >
                        {record?.action}
                      </TableCell>
                    </TableRow>
                  </Fragment>
                ))}
            </TableBody>
          </Table>
        </TableContainer>
        <TablePagination
          rowsPerPageOptions={[10, 25, 50, 100]}
          component="div"
          count={tableData?.length}
          rowsPerPage={rowsPerPage}
          page={page}
          onPageChange={handleChangePage}
          onRowsPerPageChange={handleChangeRowsPerPage}
        />
      </Paper>
    </Container>
  );
}
