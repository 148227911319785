import React from "react";
import { Snackbar, SnackbarProps } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";

const useStyles = makeStyles({
  snackbar: (messageLength: any) => ({
    width: `${9 * messageLength}px`, // Adjust the multiplier as needed
    minWidth: "unset", // Allow the Snackbar to shrink to the message width
    maxWidth: "100%", // Ensure the Snackbar doesn't exceed the viewport width
  }),
});

interface SnackBarProps extends SnackbarProps {
  message: string;
}

const CommonSnackBar: React.FC<SnackBarProps> = ({ message, ...props }) => {
  const autoHideDuration = message.length * 1; // Adjust the multiplier as needed
  const messageLength = message.length;
  const classes = useStyles(messageLength);
  return (
    <>
      <Snackbar
        anchorOrigin={{
          vertical: "top",
          horizontal: "center",
        }}
        ContentProps={{ className: classes.snackbar }}
        autoHideDuration={autoHideDuration}
        {...props}
        message={message}
      />
    </>
  );
};

export default CommonSnackBar;