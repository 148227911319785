import { useEffect, useState } from "react";
import BusinessCenterIcon from "@mui/icons-material/BusinessCenter";
import Container from "@mui/material/Container";
import Grid from "@mui/material/Grid";
import Box from "@mui/material/Box";
import { Typography } from "@mui/material";
import Stack from "@mui/material/Stack";
import FormDialog from "./ApplyCareer";
import { getRequest } from "../../api/api";
import { GET_JOB } from "../../api/server";

function Career() {
  const [users, setUsers] = useState<any>([]);

  const loadUsers = async () => {
    try {
      const res = await getRequest(GET_JOB, "");
      if (res) {
        setUsers(res.data);
      }
    } catch (error) {
      console.error("Error fetching data:", error);
    }
  };

  useEffect(() => {
    loadUsers();
  }, []);

  return (
    <Container
      maxWidth="sm"
      sx={{
        mt: { xs: "34%", sm: "16%", md: "14%", lg: "8%" },
        mb: { xs: "8%", lg: "3%" },
      }}
    >
      {users?.map((item: any) => (
        <Box
          key={item._id}
          style={{ display: "flex", justifyContent: "center !important" }}
          sx={{
            height: "auto",
            boxShadow: 7,
            padding: 2,
            marginBottom: 5,
          }}
        >
          <Grid
            container
            justifyContent="center"
            alignItems="center"
            spacing={2}
          >
            <Grid item xs={12} lg={6}>
              <Stack direction="row" spacing={2} alignItems="center">
                <BusinessCenterIcon sx={{ fontSize: 40 }} />
                <Typography variant="body1" sx={{ marginTop: 1 }}>
                  {item.title}
                </Typography>
              </Stack>
            </Grid>

            <Grid item xs={12} lg={6}>
              <Stack direction="row" spacing={2}>
                <FormDialog jobId={item._id} />
              </Stack>
            </Grid>

            <Grid item xs={3} lg={3}>
              <Typography>Description: </Typography>
            </Grid>

            <Grid item xs={9} lg={9}>
              <Typography
                dangerouslySetInnerHTML={{ __html: item.content }}
              ></Typography>
            </Grid>
          </Grid>
        </Box>
      ))}
    </Container>
  );
}

export default Career;
