import FileUploadIcon from "@mui/icons-material/FileUpload";
import FilterAltSharpIcon from "@mui/icons-material/FilterAltSharp";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import KeyboardArrowUpIcon from "@mui/icons-material/KeyboardArrowUp";
import PrintIcon from "@mui/icons-material/Print";
import SearchIcon from "@mui/icons-material/Search";
import {
  Collapse,
  Container,
  Grid,
  IconButton,
  InputAdornment,
  LinearProgress,
  TablePagination,
  Tooltip,
  Typography,
} from "@mui/material";
import Paper from "@mui/material/Paper";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import TextField from "@mui/material/TextField";
import { PDFDownloadLink } from "@react-pdf/renderer";
import {
  ChangeEvent,
  ChangeEventHandler,
  Fragment,
  useCallback,
  useState,
} from "react";
import CommonSnackBar from "../../Components/Common/CommonSnackbar";
import TableToExcel from "../../Components/Common/TableToExcel";
import "../../Components/Home/Home.scss";
import useValidation from "../../Hooks/useTypescriptValidation";
import { getRequestByParams, postRequestMethod } from "../../api/api";
import {
  GET_SALARY_BY_ID,
  MANAGE_SALARY_SYSTEM,
  UPLOAD_CSV_EXCEL,
} from "../../api/server";
import SalarySlip from "./SalarySlip";

export default function ManageSalary(props: any) {
  const { regId, type } = props.types;
  const { eventHandler } = useValidation();

  const [page, setPage] = useState<number>(0);
  const [rowsPerPage, setRowsPerPage] = useState<number>(10);
  const [salaryDetail, setSalaryDetail] = useState<any[]>([]);
  const [searchApiData, setSearchApiData] = useState<any[]>([]);
  const [filterVal, setFilterVal] = useState<string>("");
  const [open, setOpen] = useState(new Array(salaryDetail?.length).fill(false));
  const [file, setFile] = useState<File | undefined>(undefined);
  const [openSnackBar, setOpenSnackBar] = useState<boolean>(false);
  const [snackMessage, setSnackMessage] = useState<string>("");
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [forDate, setForDates] = useState<string>("");
  const [errors, setErrors] = useState<{ [key: string]: string }>({
    uploadFile: "",
  });

  const fetchSalary = useCallback(() => {
    if (type === "Administrator") return getSalaryForAdmin();
    return getSalaryById();
  }, [type, regId, forDate]);

  const handleOpenSnackBar = () => {
    setOpenSnackBar(true);
    setTimeout(() => {
      setOpenSnackBar(false);
    }, 5000);
  };

  const handleChangePage = (event: any, newPage: any) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event: any) => {
    setRowsPerPage(+event.target.value);
    setPage(0);
  };

  const handleFilter = (e: any): any => {
    if (e.target.value === "") {
      setSalaryDetail(searchApiData);
    } else {
      const filterResult = searchApiData.filter((item: any) => {
        const searchValue = String(e.target.value)?.toLowerCase();
        return Object.values(item).some((value) => {
          if (typeof value === "string" || typeof value === "number") {
            const itemValue = String(value)?.toLowerCase();
            return itemValue.includes(searchValue);
          }
          return false;
        });
      });
      setSalaryDetail(filterResult);
    }
    setFilterVal(e.target.value);
  };

  const validationHandler = async (
    e: ChangeEvent<HTMLInputElement>,
    alterName: string
  ) => {
    const value = e.target.files?.[0];
    const id = alterName;
    if (value) {
      let promise = await new Promise<string>((resolve) =>
        resolve(eventHandler(id, value))
      );
      setErrors((preErrors: { [key: string]: string }) => ({
        ...preErrors,
        [e.target.name]: promise,
      }));
      promise === "" && setFile(value);
    }
  };

  const getSalaryForAdmin = async () => {
    try {
      if (forDate === "") {
        setSnackMessage("Please Select Month");
        handleOpenSnackBar();
        return;
      }
      setIsLoading(true);
      let result = await getRequestByParams(MANAGE_SALARY_SYSTEM, "", "", {
        forDate,
      });
      setIsLoading(false);
      if (result?.data.error) {
        setSnackMessage(result?.data.error);
        handleOpenSnackBar();
      } else {
        if (result.data.length === 0) {
          setSnackMessage("No data found");
          handleOpenSnackBar();
        }
        setSalaryDetail(result?.data);
        setSearchApiData(result?.data);
      }
    } catch (error) {
      console.error("Error fetching data:", error);
    }
  };

  const getSalaryById = async () => {
    try {
      if (forDate === "") {
        setSnackMessage("Please Select Month");
        handleOpenSnackBar();
        return;
      }
      setIsLoading(true);
      let result = await getRequestByParams(GET_SALARY_BY_ID, "", "", {
        regId,
        type,
        forDate,
      });
      setIsLoading(false);
      if (result?.data.error) {
        setSnackMessage(result?.data.error);
        handleOpenSnackBar();
      } else {
        if (result.data.length === 0) {
          setSnackMessage("No data found");
          handleOpenSnackBar();
        }
        setSalaryDetail(result?.data);
        setSearchApiData(result?.data);
      }
    } catch (error) {
      console.error("Error fetching data:", error);
    }
  };

  const handleSubmit = async (e: any) => {
    e.preventDefault();
    const formData = new FormData();
    if (file && errors.uploadFile === "") {
      formData.append("file", file);
      try {
        setIsLoading(true);
        const response = await postRequestMethod(
          UPLOAD_CSV_EXCEL,
          "multipart/form-data",
          formData
        );
        setIsLoading(false);
        if (response?.data.error) {
          setSnackMessage(`${response?.data?.error}`);
          handleOpenSnackBar();
        } else {
          setSnackMessage("CSV/xlsx uploaded successfully");
          handleOpenSnackBar();
        }
      } catch (error) {
        console.error("Error on submitting form", error);
      }
    } else {
      setSnackMessage("Select proper file format");
      handleOpenSnackBar();
    }
  };

  const handleDateFilter: ChangeEventHandler<HTMLInputElement> = (
    e: ChangeEvent<HTMLInputElement>
  ) => {
    setForDates(e.target.value);
  };

  return (
    <>
      <Container>
        <Paper elevation={20}>
          <Grid container>
            <Grid item xs={12} sx={{ backgroundColor: "#0288d1" }}>
              <Grid container spacing={2} padding={2}>
                <Grid item xs={5}>
                  {type === "Administrator" ? (
                    <Typography variant="h5" color="white">
                      Manage Salary System
                    </Typography>
                  ) : (
                    <Typography variant="h5" color="white">
                      Download Salary Slip
                    </Typography>
                  )}
                </Grid>
                <Grid item xs={4}>
                  {type === "Administrator" && (
                    <TableToExcel
                      tableData={salaryDetail}
                      fileName="Salary_data"
                      headerMapping={{
                        Name: "name",
                        EmployeeId: "empId",
                        DayOfPay: "day_of_pay",
                        BasicSalary: "basicSalary",
                        HRA: "hra",
                        Conveyance: "conveyance",
                        Allowance: "allowances",
                        Commission: "commission",
                        Incentive: "incentive",
                        ProfessionalTax: "pTax",
                        MobileCharge: "mobileCharge",
                        TotalDeduction: "totalDeduction",
                        OfferedSalary: "offeredSalary",
                      }}
                    />
                  )}
                </Grid>
                <Grid item xs={3}>
                  <TextField
                    label="Search "
                    size="small"
                    value={filterVal}
                    onInput={(e: any) => handleFilter(e)}
                    sx={{ mx: 3, background: "white" }}
                    InputProps={{
                      endAdornment: (
                        <InputAdornment position="end">
                          <IconButton edge="end">
                            <SearchIcon />
                          </IconButton>
                        </InputAdornment>
                      ),
                    }}
                  />
                </Grid>
              </Grid>
            </Grid>

            <Grid item xs={12}>
              <Grid container spacing={2} padding={3}>
                {type === "Administrator" && (
                  <Fragment>
                    <Grid item xs={5}>
                      <TextField
                        fullWidth
                        label="Attendance CSV File"
                        type="file"
                        name="uploadFile"
                        helperText={errors.uploadFile}
                        error={Boolean(errors.uploadFile)}
                        InputLabelProps={{ shrink: true }}
                        inputProps={{ accept: ".xlsx,.csv" }}
                        sx={{ background: "white" }}
                        size="small"
                        onChange={(e: ChangeEvent<HTMLInputElement>) =>
                          validationHandler(e, "xlsxCsv")
                        }
                      />
                    </Grid>

                    <Grid item xs={1}>
                      <Tooltip title="Upload File" arrow>
                        <IconButton onClick={handleSubmit}>
                          <FileUploadIcon color="primary"></FileUploadIcon>
                        </IconButton>
                      </Tooltip>
                    </Grid>
                  </Fragment>
                )}

                <Grid item xs={5}>
                  <TextField
                    fullWidth
                    label="For Month"
                    type="month"
                    name="forDate"
                    value={forDate}
                    sx={{ background: "white" }}
                    size="small"
                    InputLabelProps={{ shrink: true }}
                    onChange={handleDateFilter}
                  />
                </Grid>

                <Grid item xs={1}>
                  <Tooltip title="Filter" arrow>
                    <IconButton onClick={fetchSalary}>
                      <FilterAltSharpIcon color="primary"></FilterAltSharpIcon>
                    </IconButton>
                  </Tooltip>
                </Grid>
              </Grid>
            </Grid>
            <CommonSnackBar message={snackMessage} open={openSnackBar} />
          </Grid>
          {isLoading && <LinearProgress />}
          <TableContainer className="scrollBarCss">
            <Table>
              <TableHead>
                <TableRow>
                  <TableCell
                    sx={{
                      textAlign: "center",
                      border: "1px solid #ddd",
                      fontWeight: "bold",
                    }}
                  ></TableCell>
                  <TableCell
                    sx={{
                      textAlign: "center",
                      border: "1px solid #ddd",
                      fontWeight: "bold",
                    }}
                  >
                    Sr.No
                  </TableCell>
                  <TableCell
                    sx={{
                      textAlign: "center",
                      border: "1px solid #ddd",

                      fontWeight: "bold",
                    }}
                  >
                    Name
                  </TableCell>
                  <TableCell
                    sx={{
                      textAlign: "center",
                      border: "1px solid #ddd",

                      fontWeight: "bold",
                    }}
                  >
                    Employee Id
                  </TableCell>
                  <TableCell
                    sx={{
                      textAlign: "center",
                      border: "1px solid #ddd",

                      fontWeight: "bold",
                    }}
                  >
                    Day of Pay
                  </TableCell>
                  <TableCell
                    sx={{
                      textAlign: "center",
                      border: "1px solid #ddd",
                      fontWeight: "bold",
                    }}
                  >
                    Basic Salary
                  </TableCell>
                  <TableCell
                    sx={{
                      textAlign: "center",
                      border: "1px solid #ddd",
                      fontWeight: "bold",
                    }}
                  >
                    HRA
                  </TableCell>
                  <TableCell
                    sx={{
                      textAlign: "center",
                      border: "1px solid #ddd",
                      fontWeight: "bold",
                    }}
                  >
                    Conveyance
                  </TableCell>
                  <TableCell
                    sx={{
                      textAlign: "center",
                      border: "1px solid #ddd",
                      fontWeight: "bold",
                    }}
                  >
                    Allowance
                  </TableCell>
                  <TableCell
                    sx={{
                      textAlign: "center",
                      border: "1px solid #ddd",
                      fontWeight: "bold",
                    }}
                  >
                    Action
                  </TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {salaryDetail
                  ?.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                  .map((item: any, index: number) => (
                    <Fragment key={item._id}>
                      <TableRow
                        className="onhover"
                        key={item._id}
                        sx={{
                          "&:last-child td, &:last-child th": { border: 0 },
                        }}
                      >
                        <TableCell
                          sx={{
                            textAlign: "center",
                            border: "1px solid #ddd",
                          }}
                        >
                          <IconButton
                            aria-label="expand row"
                            size="small"
                            onClick={() => {
                              const newOpen = [...open];
                              newOpen[index] = !newOpen[index];
                              setOpen(newOpen);
                            }}
                          >
                            {open[index] ? (
                              <KeyboardArrowUpIcon />
                            ) : (
                              <KeyboardArrowDownIcon />
                            )}
                          </IconButton>
                        </TableCell>
                        <TableCell
                          sx={{
                            textAlign: "center",
                            border: "1px solid #ddd",
                          }}
                        >
                          {page * rowsPerPage + index + 1}
                        </TableCell>
                        <TableCell
                          component="th"
                          scope="row"
                          sx={{
                            "&:last-child td, &:last-child th": { border: 0 },
                            textAlign: "center",
                          }}
                        >
                          {item.name}
                        </TableCell>
                        <TableCell
                          sx={{
                            textAlign: "center",
                            border: "1px solid #ddd",
                          }}
                        >
                          {item.empId}
                        </TableCell>
                        <TableCell
                          sx={{
                            textAlign: "center",
                            border: "1px solid #ddd",
                          }}
                        >
                          {item.day_of_pay}
                        </TableCell>
                        <TableCell
                          sx={{
                            textAlign: "center",
                            border: "1px solid #ddd",
                          }}
                        >
                          {item.basicSalary}
                        </TableCell>
                        <TableCell
                          sx={{
                            textAlign: "center",
                            border: "1px solid #ddd",
                          }}
                        >
                          {item.hra}
                        </TableCell>
                        <TableCell
                          sx={{
                            textAlign: "center",
                            border: "1px solid #ddd",
                          }}
                        >
                          {item.conveyance}
                        </TableCell>
                        <TableCell
                          sx={{
                            textAlign: "center",
                            border: "1px solid #ddd",
                          }}
                        >
                          {item.allowances}
                        </TableCell>
                        <TableCell
                          sx={{
                            textAlign: "center",
                            "&:last-child td, &:last-child th": { border: 0 },
                          }}
                        >
                          <PDFDownloadLink
                            document={<SalarySlip item={item} />}
                          >
                            <Tooltip title="Print Receipt" arrow>
                              <PrintIcon color="primary" />
                            </Tooltip>
                          </PDFDownloadLink>
                        </TableCell>
                      </TableRow>

                      {/* Collapsible Row */}
                      <TableRow>
                        <TableCell colSpan={15}>
                          <Collapse
                            in={open[index]}
                            timeout="auto"
                            unmountOnExit
                          >
                            <Grid container rowSpacing={1}>
                              <Grid item xs={12} sm={3}>
                                <Typography
                                  sx={{
                                    fontWeight: "bold",
                                    display: "inline",
                                    mx: 2,
                                  }}
                                >
                                  Commission:
                                </Typography>
                                <Typography sx={{ display: "inline" }}>
                                  {item.commission}
                                </Typography>
                              </Grid>
                              <Grid item xs={12} sm={3}>
                                <Typography
                                  sx={{
                                    fontWeight: "bold",
                                    display: "inline",
                                    mx: 2,
                                  }}
                                >
                                  Incentive:
                                </Typography>
                                <Typography sx={{ display: "inline" }}>
                                  {item.incentive}
                                </Typography>
                              </Grid>
                              <Grid item xs={12} sm={3}>
                                <Typography
                                  sx={{
                                    fontWeight: "bold",
                                    display: "inline",
                                    mx: 2,
                                  }}
                                >
                                  Professional Tax:
                                </Typography>
                                <Typography sx={{ display: "inline" }}>
                                  {item.pTax}
                                </Typography>
                              </Grid>
                              <Grid item xs={12} sm={3}>
                                <Typography
                                  sx={{
                                    fontWeight: "bold",
                                    display: "inline",
                                    mx: 2,
                                  }}
                                >
                                  Mobile Charge:
                                </Typography>
                                <Typography sx={{ display: "inline" }}>
                                  {item.mobileCharge}
                                </Typography>
                              </Grid>
                              <Grid item xs={12} sm={3}>
                                <Typography
                                  sx={{
                                    fontWeight: "bold",
                                    display: "inline",
                                    mx: 2,
                                  }}
                                >
                                  Total Deduction:
                                </Typography>
                                <Typography sx={{ display: "inline" }}>
                                  {item.totalDeduction}
                                </Typography>
                              </Grid>
                              <Grid item xs={12} sm={3}>
                                <Typography
                                  sx={{
                                    fontWeight: "bold",
                                    display: "inline",
                                    mx: 2,
                                  }}
                                >
                                  Offered Salary:
                                </Typography>
                                <Typography sx={{ display: "inline" }}>
                                  {item.offeredSalary}
                                </Typography>
                              </Grid>
                            </Grid>
                          </Collapse>
                        </TableCell>
                      </TableRow>
                    </Fragment>
                  ))}
              </TableBody>
            </Table>
          </TableContainer>

          <TablePagination
            rowsPerPageOptions={[10, 25, 50, 100]}
            component="div"
            count={salaryDetail?.length}
            rowsPerPage={rowsPerPage}
            page={page}
            onPageChange={handleChangePage}
            onRowsPerPageChange={handleChangeRowsPerPage}
          ></TablePagination>
        </Paper>
      </Container>
    </>
  );
}
