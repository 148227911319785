import "../Home/FirstLoadingForm/FirstLoadingForm";
import "./Home.scss";
import About from "./AboutSummary";
import Counselling from "./Counselling";
import Regsteps from "./AcademicProcess";
import ExploreDegree from "./ExploreDegree";
import Feedbackmarquee from "./Feedbackmarquee";
import PopupModel from "./FirstLoadingForm/PopupModel";
import FirstLoadingForm from "../Home/FirstLoadingForm/FirstLoadingForm";
import LandingPage from "./LandingPage";
import ChatbotIcon from "../../Views/ChatbotIcon";

export default function Home() {
  return (
    <>
      <PopupModel>
        <FirstLoadingForm />
      </PopupModel>
      <LandingPage />
      <Regsteps />
      <Counselling />
      <ExploreDegree />
      <Feedbackmarquee />
      <About />
      <ChatbotIcon />
    </>
  );
}
