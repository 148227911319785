import {
  Container,
  Grid,
  InputLabel,
  Paper,
  TextField,
  Typography,
} from "@mui/material";

export default function JobOrSalaryInformationPreview(props: any) {
  return (
    <Container sx={{ mt: 3 }}>
      <Paper sx={{ p: 2 }} elevation={0}>
        <Grid
          container
          rowSpacing={1}
          columnSpacing={{ xs: 1, sm: 2, md: 3 }}
          sx={{ mb: 5 }}
        >
          {/* Job Details */}
          <Grid container xs={12}>
            <Grid item xs={12}>
              <Typography fontWeight="500">Job Details:</Typography>
            </Grid>
            <Grid item xs={12} sm={6} md={3}>
              <InputLabel
                htmlFor="standard-basic"
                sx={{ mt: 3, color: "#0288d1" }}
              >
                Job Post:
              </InputLabel>
              <TextField
                id="standard-basic"
                variant="standard"
                sx={{ backgroundColor: "white" }}
                value={props.Data?.post}
              />
            </Grid>
            <Grid item xs={12} sm={6} md={3}>
              <InputLabel
                htmlFor="standard-basic"
                sx={{ mt: 3, color: "#0288d1" }}
              >
                Date Of Joining :
              </InputLabel>
              <TextField
                id="standard-basic"
                variant="standard"
                sx={{ backgroundColor: "white" }}
                value={props.Data?.dateOfJoining}
              />
            </Grid>
            <Grid item xs={12} sm={6} md={3}>
              <InputLabel
                htmlFor="standard-basic"
                sx={{ mt: 3, color: "#0288d1" }}
              >
                Date Of Resignation:
              </InputLabel>
              <TextField
                id="standard-basic"
                variant="standard"
                sx={{ backgroundColor: "white" }}
                value={props.Data?.dateOfResignation}
              />
            </Grid>
            <Grid item xs={12} sm={6} md={3}>
              <InputLabel
                htmlFor="standard-basic"
                sx={{ mt: 3, color: "#0288d1" }}
              >
                Department:
              </InputLabel>
              <TextField
                id="standard-basic"
                variant="standard"
                sx={{ backgroundColor: "white" }}
                value={props.Data?.department}
              />
            </Grid>
            <Grid item xs={12} sm={6} md={3}>
              <InputLabel
                htmlFor="standard-basic"
                sx={{ mt: 3, color: "#0288d1" }}
              >
                Unit :
              </InputLabel>
              <TextField
                id="standard-basic"
                variant="standard"
                sx={{ backgroundColor: "white" }}
                value={props.Data?.unit}
              />
            </Grid>
            <Grid item xs={12} sm={6} md={3}>
              <InputLabel
                htmlFor="standard-basic"
                sx={{ mt: 3, color: "#0288d1" }}
              >
                Job Location:
              </InputLabel>
              <TextField
                id="standard-basic"
                variant="standard"
                sx={{ backgroundColor: "white" }}
                value={props.Data?.jobLocation}
              />
            </Grid>
          </Grid>

          {/* Salary Details */}
          <Grid container xs={12}>
            <Grid item xs={12}>
              <Typography fontWeight="500" sx={{ mt: 3 }}>
                Salary Details:
              </Typography>
            </Grid>
            <Grid item xs={12} sm={6} md={3}>
              <InputLabel
                htmlFor="standard-basic"
                sx={{ mt: 3, color: "#0288d1" }}
              >
                Mobile Charge:
              </InputLabel>
              <TextField
                id="standard-basic"
                variant="standard"
                sx={{ backgroundColor: "white" }}
                value={props.Data?.mobileCharge}
              />
            </Grid>
            <Grid item xs={12} sm={6} md={3}>
              <InputLabel
                htmlFor="standard-basic"
                sx={{ mt: 3, color: "#0288d1" }}
              >
                PTax :
              </InputLabel>
              <TextField
                id="standard-basic"
                variant="standard"
                sx={{ backgroundColor: "white" }}
                value={props.Data?.pTax}
              />
            </Grid>
            <Grid item xs={12} sm={6} md={3}>
              <InputLabel
                htmlFor="standard-basic"
                sx={{ mt: 3, color: "#0288d1" }}
              >
                Basic Salary:
              </InputLabel>
              <TextField
                id="standard-basic"
                variant="standard"
                sx={{ backgroundColor: "white" }}
                value={props.Data?.basicSalary}
              />
            </Grid>
            <Grid item xs={12} sm={6} md={3}>
              <InputLabel
                htmlFor="standard-basic"
                sx={{ mt: 3, color: "#0288d1" }}
              >
                HRA:
              </InputLabel>
              <TextField
                id="standard-basic"
                variant="standard"
                sx={{ backgroundColor: "white" }}
                value={props.Data?.hra}
              />
            </Grid>
            <Grid item xs={12} sm={6} md={3}>
              <InputLabel
                htmlFor="standard-basic"
                sx={{ mt: 3, color: "#0288d1" }}
              >
                Convenience :
              </InputLabel>
              <TextField
                id="standard-basic"
                variant="standard"
                sx={{ backgroundColor: "white" }}
                value={props.Data?.conveyance}
              />
            </Grid>
            <Grid item xs={12} sm={6} md={3}>
              <InputLabel
                htmlFor="standard-basic"
                sx={{ mt: 3, color: "#0288d1" }}
              >
                Allowence :
              </InputLabel>
              <TextField
                id="standard-basic"
                variant="standard"
                sx={{ backgroundColor: "white" }}
                value={props.Data?.allowances}
              />
            </Grid>
            <Grid item xs={12} sm={6} md={3}>
              <InputLabel
                htmlFor="standard-basic"
                sx={{ mt: 3, color: "#0288d1" }}
              >
                Offered Salary:
              </InputLabel>
              <TextField
                id="standard-basic"
                variant="standard"
                sx={{ backgroundColor: "white" }}
                value={props.Data?.offeredSalary}
              />
            </Grid>

            <Grid item xs={12} sm={6} md={3}>
              <InputLabel
                htmlFor="standard-basic"
                sx={{ mt: 3, color: "#0288d1" }}
              >
                Share Percent:
              </InputLabel>
              <TextField
                id="standard-basic"
                variant="standard"
                sx={{ backgroundColor: "white" }}
                value={props.Data?.sharePercent}
              />
            </Grid>
            <Grid item xs={12} sm={6} md={3}>
              <InputLabel
                htmlFor="standard-basic"
                sx={{ mt: 3, color: "#0288d1" }}
              >
                Status :
              </InputLabel>
              <TextField
                id="standard-basic"
                variant="standard"
                sx={{ backgroundColor: "white" }}
                value={props.Data?.status}
              />
            </Grid>
          </Grid>
        </Grid>
      </Paper>
    </Container>
  );
}
