import Box from "@mui/material/Box";
import Stepper from "@mui/material/Stepper";
import StepButton from "@mui/material/StepButton";
import Button from "@mui/material/Button";
import Typography from "@mui/material/Typography";
import { Fragment, useEffect, useState } from "react";
import { GET_PROFILE } from "../../api/server";
import { getRequestByParams } from "../../api/api";
import { Container, Grid, Paper, Stack, Tooltip } from "@mui/material";
import AccountCircleIcon from "@mui/icons-material/AccountCircle";
import SchoolIcon from "@mui/icons-material/School";
import InfoIcon from "@mui/icons-material/Info";
import DownloadIcon from "@mui/icons-material/Download";
import ArticleIcon from "@mui/icons-material/Article";
import PersonalPreview from "./PersonalPreview";
import AcademicsPreview from "./AcademicsPreview";
import DocumentsPreview from "./DocumentsPreview";
import UniversityOrCoursePreview from "./UniversityOrCoursePreview";
import { PDFDownloadLink } from "@react-pdf/renderer";
import DownloadStudentProfileForm from "../../Components/Common/DownloadStudentProfileForm";

const steps = ["Personal", "Academics", "Course Details", "Documents"];

const stepIcons = [
  <AccountCircleIcon />,
  <SchoolIcon />,
  <InfoIcon />,
  <ArticleIcon />,
];

export default function RegistrationDetails(props: any) {
  const [activeStep, setActiveStep] = useState(0);
  const [detail, setDetail] = useState<any>();
  const [completed, setCompleted] = useState<{
    [k: number]: boolean;
  }>({});

  const getStudentData = () => {
    const myData = {
      type: props.types.type,
      lId: props.types.lId,
    };
    getRequestByParams(GET_PROFILE, "", "", myData)
      .then((response: any) => {
        setDetail(response.data);
      })

      .catch((error) => {
        console.error("Error fetching profile data:", error);
      });
  };

  const totalSteps = () => {
    return steps.length;
  };

  const completedSteps = () => {
    return Object.keys(completed).length;
  };

  const isLastStep = () => {
    return activeStep === totalSteps() - 1;
  };

  const allStepsCompleted = () => {
    return completedSteps() === totalSteps();
  };

  const handleNext = () => {
    const newActiveStep =
      isLastStep() && !allStepsCompleted()
        ? steps.findIndex((step, i) => !(i in completed))
        : activeStep + 1;
    setActiveStep(newActiveStep);
  };

  const handleBack = () => {
    setActiveStep((prevActiveStep) => prevActiveStep - 1);
  };

  const handleStep = (step: any) => () => {
    setActiveStep(step);
    sessionStorage.setItem("activeStep", step);
  };

  const handleComplete = () => {
    const newCompleted = completed;
    newCompleted[activeStep] = true;
    setCompleted(newCompleted);
    handleNext();
  };

  const handleReset = () => {
    setActiveStep(0);
    setCompleted({});
    sessionStorage.removeItem("activeStep");
  };

  useEffect(() => {
    const storedActiveStep = sessionStorage.getItem("activeStep");
    setActiveStep(storedActiveStep ? parseInt(storedActiveStep) : 0);
    getStudentData();
  }, []);

  return (
    <Container>
      <Paper>
        <Box sx={{ width: "100%", p: 3 }}>
          <Grid container>
            <Grid xs={12} sm={12} md={10}>
              <Stepper nonLinear activeStep={activeStep}>
                {steps.map((label, index) => (
                  <StepButton
                    color="inherit"
                    onClick={handleStep(index)}
                    sx={{
                      color: activeStep === index ? "#0288d1" : "inherit",
                      borderBottom:
                        activeStep === index
                          ? "2px solid #0288d1"
                          : "2px solid transparent",
                      ml: 1,
                      mt: 1,
                      display: "flex",
                      flexDirection: "column",
                      alignItems: "center",
                      marginBottom: 0,
                      padding: 1,
                    }}
                  >
                    <Stack direction={{ xs: "column", md: "row" }} spacing={2}>
                      <span
                        style={{
                          marginLeft: "2",
                          marginRight: "2",
                          color: activeStep === index ? "#0288d1" : "inherit",
                        }}
                      >
                        {stepIcons[index]}
                      </span>
                      <Typography
                        sx={{
                          mx: 3,
                          color: activeStep === index ? "#0288d1" : "inherit",
                        }}
                      >
                        {label}
                      </Typography>
                    </Stack>
                  </StepButton>
                ))}
              </Stepper>
            </Grid>
            <Grid
              xs={12}
              sm={12}
              md={2}
              display="flex"
              justifyContent="flex-end"
            >
              <PDFDownloadLink
                document={<DownloadStudentProfileForm data={detail} />}
              >
                {({ loading }) =>
                  loading ? (
                    <Button sx={{ px: 3 }}>Loading Document...</Button>
                  ) : (
                    <Button variant="contained">
                      <Tooltip title="Download Form">
                        <DownloadIcon />
                      </Tooltip>
                    </Button>
                  )
                }
              </PDFDownloadLink>
            </Grid>
          </Grid>
          <div>
            {allStepsCompleted() ? (
              <Fragment>
                <Box sx={{ display: "flex", flexDirection: "row", pt: 2 }}>
                  <Box sx={{ flex: "1 1 auto" }} />
                  <Button onClick={handleReset}>Reset</Button>
                </Box>
              </Fragment>
            ) : (
              <Fragment>
                {activeStep === 0 && <PersonalPreview Data={detail} />}
                {activeStep === 1 && <AcademicsPreview Data={detail} />}
                {activeStep === 2 && (
                  <UniversityOrCoursePreview Data={detail} />
                )}
                {activeStep === 3 && <DocumentsPreview Data={detail} />}
                <Box sx={{ display: "flex", flexDirection: "row", pt: 2 }}>
                  <Button
                    disabled={activeStep === 0}
                    onClick={handleBack}
                    sx={{ mr: 1 }}
                  >
                    Back
                  </Button>
                  <Box sx={{ flex: "1 1 auto" }} />
                  <Button onClick={handleNext} sx={{ mr: 1 }}>
                    Next
                  </Button>
                  {activeStep !== steps.length &&
                    (completed[activeStep] ? null : (
                      <Button onClick={handleComplete}>
                        {completedSteps() === totalSteps() - 1
                          ? "Finish"
                          : null}
                      </Button>
                    ))}
                </Box>
              </Fragment>
            )}
          </div>
        </Box>
      </Paper>
    </Container>
  );
}
