import { Box, Button, Container, Paper, Typography } from "@mui/material";
import { FC } from "react";
import { useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";

const Failure: FC = () => {
  const navigate = useNavigate();
  const message = new URLSearchParams(window.location.search).get("message");
  const { login } = useSelector((state: any) => state.LoginReducer);

  const navigator = () => {
    login ? navigate("/dashboard") : navigate("/login");
  };

  return (
    <Container
      maxWidth="sm"
      sx={{ mt: { xs: "30%", sm: "10%", md: "10%", lg: "10%" }, mb: 5 }}
    >
      <Paper elevation={4} sx={{ padding: 4 }}>
        <Box textAlign="center">
          <Typography variant="h3" color="error">
            Oops, something went wrong!
          </Typography>
          <Typography variant="h5" color="error" mt={2}>
            {message}
          </Typography>
          <Button
            variant="contained"
            color="primary"
            sx={{ my: 2 }}
            onClick={navigator}
          >
            Back to login
          </Button>
        </Box>
      </Paper>
    </Container>
  );
};

export default Failure;
