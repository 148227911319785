import {
  Button,
  Stack,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  TextField,
} from "@mui/material";
import Grid from "@mui/material/Grid";
import { debounce } from "lodash";
import { useState } from "react";
import useValidation from "../../Hooks/useTypescriptValidation";

export default function Practice(props: any) {
  let { eventHandler } = useValidation();

  const currentDate = new Date();
  const currentYear = currentDate.getFullYear();
  const currentMonth = currentDate.getMonth() + 1; // Months are 0-based

  // Format the current month and year to "yyyy-MM" format
  const currentMonthString = `${currentYear}-${currentMonth
    .toString()
    .padStart(2, "0")}`;

  const [errors, setErrors] = useState<{ [key: string]: string }>({
    sscYear: "",
    sscBoardUniversity: "",
    sscDivisionGrade: "",
    sscPercentageCGPA: "",
    sscObtainedMaxTotalMax: "",

    hscYear: "",
    hscBoardUniversity: "",
    hscDivisionGrade: "",
    hscPercentageCGPA: "",
    hscObtainedMaxTotalMax: "",

    itiYear: "",
    itiBoardUniversity: "",
    itiDivisionGrade: "",
    itiPercentageCGPA: "",
    itiObtainedMaxTotalMax: "",

    graduationYear: "",
    graduationBoardUniversity: "",
    graduationDivisionGrade: "",
    graduationPercentageCGPA: "",
    graduationObtainedMaxTotalMax: "",

    postGraduationYear: "",
    postGraduationBoardUniversity: "",
    postGraduationDivisionGrade: "",
    postGraduationPercentageCGPA: "",
    postGraduationObtainedMaxTotalMax: "",

    otherYear: "",
    otherBoardUniversity: "",
    otherDivisionGrade: "",
    otherPercentageCGPA: "",
    otherObtainedMaxTotalMax: "",
  });

  const validationHandler = debounce((e: any, alterName?: any) => {
    const val = e.target.value;
    const id = alterName;
    const err = eventHandler(id, val);
    setErrors((prevData: any) => ({
      ...prevData,
      [e.target.name]: err,
    }));
    if (e.target.value.length === 0) {
      setErrors((prevData: any) => ({
        ...prevData,
        [e.target.name]: "",
      }));
    }
  }, 2000);

  const handleNext = (e: any) => {
    e.preventDefault();
    let isError: boolean = false;
    for (let key in errors) {
      if (errors[key] !== "") {
        isError = true;
        break;
      }
    }
    if (isError) window.alert("Please fill valid data");
    else {
      props.setActiveStep(3);
      window.scrollTo(0, 0);
      props.setShowSubmit((showSubmit: any) => ({
        ...showSubmit,
        academicDetail: true,
      }));
    }
  };

  return (
    <>
      <Grid
        container
        component="form"
        spacing={2}
        direction="row"
        display="flex"
        justifyContent="ceneter"
        alignItems="center"
        onSubmit={handleNext}
      >
        <Grid item xs={12}>
          <TableContainer className="scrollBarCss">
            <Table>
              <TableHead>
                <TableRow>
                  <TableCell sx={{ border: "1px solid black" }}>
                    Name of Qualifying Degree
                  </TableCell>

                  <TableCell sx={{ border: "1px solid black" }}>
                    Year of Passing
                  </TableCell>

                  <TableCell sx={{ border: "1px solid black", minWidth: 300 }}>
                    Board/ University
                  </TableCell>

                  <TableCell sx={{ border: "1px solid black", minWidth: 150 }}>
                    Division / Grade
                  </TableCell>

                  <TableCell sx={{ border: "1px solid black", minWidth: 150 }}>
                    Percentage/ CGPA
                  </TableCell>

                  <TableCell sx={{ border: "1px solid black", minWidth: 150 }}>
                    Obtained Max/ Total Max
                  </TableCell>
                </TableRow>
              </TableHead>

              <TableBody>
                <TableRow sx={{ mb: "1" }}>
                  <TableCell sx={{ border: "1px solid black" }}>10TH</TableCell>
                  <TableCell sx={{ border: "1px solid black" }}>
                    <TextField
                      required
                      fullWidth
                      name="sscYear"
                      type="month"
                      inputProps={{ max: currentMonthString }}
                      value={props.detail?.sscYear}
                      sx={{ background: "white" }}
                      error={Boolean(errors.sscYear)}
                      helperText={errors.sscYear}
                      onChange={(e) => {
                        validationHandler(e);
                        props.handler(e);
                      }}
                    />
                  </TableCell>

                  <TableCell sx={{ border: "1px solid black" }}>
                    <TextField
                      required
                      fullWidth
                      name="sscBoardUniversity"
                      value={props.detail?.sscBoardUniversity}
                      sx={{ background: "white" }}
                      error={Boolean(errors.sscBoardUniversity)}
                      helperText={errors.sscBoardUniversity}
                      onChange={(e) => {
                        validationHandler(e, "alphabetsAndSpace");
                        props.handler(e);
                      }}
                    />
                  </TableCell>

                  <TableCell sx={{ border: "1px solid black" }}>
                    <TextField
                      required
                      fullWidth
                      sx={{ background: "white" }}
                      name="sscDivisionGrade"
                      value={props.detail?.sscDivisionGrade}
                      error={Boolean(errors.sscDivisionGrade)}
                      helperText={errors.sscDivisionGrade}
                      onChange={(e) => {
                        validationHandler(e, "grade");
                        props.handler(e);
                      }}
                    />
                  </TableCell>

                  <TableCell sx={{ border: "1px solid black" }}>
                    <TextField
                      required
                      fullWidth
                      name="sscPercentageCGPA"
                      type="number"
                      sx={{ background: "white" }}
                      value={props.detail?.sscPercentageCGPA}
                      error={Boolean(errors.sscPercentageCGPA)}
                      helperText={errors.sscPercentageCGPA}
                      onChange={(e) => {
                        validationHandler(e, "number");
                        props.handler(e);
                      }}
                    />
                  </TableCell>

                  <TableCell sx={{ border: "1px solid black" }}>
                    <TextField
                      required
                      fullWidth
                      type="number"
                      sx={{ background: "white" }}
                      name="sscObtainedMaxTotalMax"
                      value={props.detail?.sscObtainedMaxTotalMax}
                      error={Boolean(errors.sscObtainedMaxTotalMax)}
                      helperText={errors.sscObtainedMaxTotalMax}
                      onChange={(e) => {
                        validationHandler(e, "number");
                        props.handler(e);
                      }}
                    />
                  </TableCell>
                </TableRow>

                <TableRow sx={{ mb: "1" }}>
                  <TableCell sx={{ border: "1px solid black" }}>12TH</TableCell>

                  <TableCell sx={{ border: "1px solid black" }}>
                    <TextField
                      fullWidth
                      placeholder="Year"
                      type="month"
                      name="hscYear"
                      inputProps={{ max: currentMonthString }}
                      sx={{ background: "white" }}
                      value={props.detail?.hscYear}
                      error={Boolean(errors.hscYear)}
                      helperText={errors.hscYear}
                      onChange={(e) => {
                        validationHandler(e);
                        props.handler(e);
                      }}
                    />
                  </TableCell>

                  <TableCell sx={{ border: "1px solid black" }}>
                    <TextField
                      fullWidth
                      name="hscBoardUniversity"
                      sx={{ background: "white" }}
                      value={props.detail?.hscBoardUniversity}
                      error={Boolean(errors.hscBoardUniversity)}
                      helperText={errors.hscBoardUniversity}
                      onChange={(e) => {
                        validationHandler(e, "alphabetsAndSpace");
                        props.handler(e);
                      }}
                    />
                  </TableCell>

                  <TableCell sx={{ border: "1px solid black" }}>
                    <TextField
                      fullWidth
                      name="hscDivisionGrade"
                      sx={{ background: "white" }}
                      value={props.detail?.hscDivisionGrade}
                      error={Boolean(errors.hscDivisionGrade)}
                      helperText={errors.hscDivisionGrade}
                      onChange={(e) => {
                        validationHandler(e, "grade");
                        props.handler(e);
                      }}
                    />
                  </TableCell>

                  <TableCell sx={{ border: "1px solid black" }}>
                    <TextField
                      fullWidth
                      name="hscPercentageCGPA"
                      type="number"
                      sx={{ background: "white" }}
                      value={props.detail?.hscPercentageCGPA}
                      error={Boolean(errors.hscPercentageCGPA)}
                      helperText={errors.hscPercentageCGPA}
                      onChange={(e) => {
                        validationHandler(e, "number");
                        props.handler(e);
                      }}
                    />
                  </TableCell>

                  <TableCell sx={{ border: "1px solid black" }}>
                    <TextField
                      fullWidth
                      type="number"
                      sx={{ background: "white" }}
                      name="hscObtainedMaxTotalMax"
                      value={props.detail?.hscObtainedMaxTotalMax}
                      error={Boolean(errors.hscObtainedMaxTotalMax)}
                      helperText={errors.hscObtainedMaxTotalMax}
                      onChange={(e) => {
                        validationHandler(e, "number");
                        props.handler(e);
                      }}
                    />
                  </TableCell>
                </TableRow>
                <TableRow sx={{ mb: "1" }}>
                  <TableCell sx={{ border: "1px solid black" }}>
                    ITI/Diploma
                  </TableCell>

                  <TableCell sx={{ border: "1px solid black" }}>
                    <TextField
                      fullWidth
                      placeholder="Year"
                      type="month"
                      name="itiYear"
                      inputProps={{ max: currentMonthString }}
                      sx={{ background: "white" }}
                      value={props.detail.itiYear}
                      error={Boolean(errors.itiYear)}
                      helperText={errors.itiYear}
                      onChange={(e) => {
                        validationHandler(e);
                        props.handler(e);
                      }}
                    />
                  </TableCell>

                  <TableCell sx={{ border: "1px solid black" }}>
                    <TextField
                      fullWidth
                      name="itiBoardUniversity"
                      value={props.detail.itiBoardUniversity}
                      error={Boolean(errors.itiBoardUniversity)}
                      helperText={errors.itiBoardUniversity}
                      sx={{ background: "white" }}
                      onChange={(e) => {
                        validationHandler(e, "alphabetsAndSpace");
                        props.handler(e);
                      }}
                    />
                  </TableCell>

                  <TableCell sx={{ border: "1px solid black" }}>
                    <TextField
                      fullWidth
                      name="itiDivisionGrade"
                      value={props.detail.itiDivisionGrade}
                      error={Boolean(errors.itiDivisionGrade)}
                      helperText={errors.itiDivisionGrade}
                      sx={{ background: "white" }}
                      onChange={(e) => {
                        validationHandler(e, "grade");
                        props.handler(e);
                      }}
                    />
                  </TableCell>

                  <TableCell sx={{ border: "1px solid black" }}>
                    <TextField
                      fullWidth
                      name="itiPercentageCGPA"
                      type="number"
                      value={props.detail.itiPercentageCGPA}
                      error={Boolean(errors.itiPercentageCGPA)}
                      helperText={errors.itiPercentageCGPA}
                      sx={{ background: "white" }}
                      onChange={(e) => {
                        validationHandler(e, "number");
                        props.handler(e);
                      }}
                    />
                  </TableCell>

                  <TableCell sx={{ border: "1px solid black" }}>
                    <TextField
                      fullWidth
                      type="number"
                      name="itiObtainedMaxTotalMax"
                      value={props.detail.itiObtainedMaxTotalMax}
                      error={Boolean(errors.itiObtainedMaxTotalMax)}
                      helperText={errors.itiObtainedMaxTotalMax}
                      sx={{ background: "white" }}
                      onChange={(e) => {
                        validationHandler(e, "number");
                        props.handler(e);
                      }}
                    />
                  </TableCell>
                </TableRow>
                <TableRow sx={{ mb: "1" }}>
                  <TableCell sx={{ border: "1px solid black" }}>
                    Graduation
                  </TableCell>

                  <TableCell sx={{ border: "1px solid black" }}>
                    <TextField
                      fullWidth
                      placeholder="Year"
                      type="month"
                      name="graduationYear"
                      inputProps={{ max: currentMonthString }}
                      sx={{ background: "white" }}
                      value={props.detail?.graduationYear}
                      error={Boolean(errors.graduationYear)}
                      helperText={errors.graduationYear}
                      onChange={(e) => {
                        validationHandler(e);
                        props.handler(e);
                      }}
                    />
                  </TableCell>

                  <TableCell sx={{ border: "1px solid black" }}>
                    <TextField
                      fullWidth
                      name="graduationBoardUniversity"
                      sx={{ background: "white" }}
                      value={props.detail?.graduationBoardUniversity}
                      error={Boolean(errors.graduationBoardUniversity)}
                      helperText={errors.graduationBoardUniversity}
                      onChange={(e) => {
                        validationHandler(e, "alphabetsAndSpace");
                        props.handler(e);
                      }}
                    />
                  </TableCell>

                  <TableCell sx={{ border: "1px solid black" }}>
                    <TextField
                      fullWidth
                      name="graduationDivisionGrade"
                      sx={{ background: "white" }}
                      value={props.detail?.graduationDivisionGrade}
                      error={Boolean(errors.graduationDivisionGrade)}
                      helperText={errors.graduationDivisionGrade}
                      onChange={(e) => {
                        validationHandler(e, "grade");
                        props.handler(e);
                      }}
                    />
                  </TableCell>

                  <TableCell sx={{ border: "1px solid black" }}>
                    <TextField
                      fullWidth
                      type="number"
                      name="graduationPercentageCGPA"
                      sx={{ background: "white" }}
                      value={props.detail?.graduationPercentageCGPA}
                      error={Boolean(errors.graduationPercentageCGPA)}
                      helperText={errors.graduationPercentageCGPA}
                      onChange={(e) => {
                        validationHandler(e, "number");
                        props.handler(e);
                      }}
                    />
                  </TableCell>

                  <TableCell sx={{ border: "1px solid black" }}>
                    <TextField
                      fullWidth
                      type="number"
                      name="graduationObtainedMaxTotalMax"
                      sx={{ background: "white" }}
                      value={props.detail?.graduationObtainedMaxTotalMax}
                      error={Boolean(errors.graduationObtainedMaxTotalMax)}
                      helperText={errors.graduationObtainedMaxTotalMax}
                      onChange={(e) => {
                        validationHandler(e, "number");
                        props.handler(e);
                      }}
                    />
                  </TableCell>
                </TableRow>
                <TableRow sx={{ mb: "1" }}>
                  <TableCell sx={{ border: "1px solid black" }}>
                    Post Graduation
                  </TableCell>

                  <TableCell sx={{ border: "1px solid black" }}>
                    <TextField
                      fullWidth
                      type="month"
                      placeholder="Year"
                      name="postGraduationYear"
                      inputProps={{ max: currentMonthString }}
                      sx={{ background: "white" }}
                      value={props.detail?.postGraduationYear}
                      error={Boolean(errors.postGraduationYear)}
                      helperText={errors.postGraduationYear}
                      onChange={(e) => {
                        validationHandler(e);
                        props.handler(e);
                      }}
                    />
                  </TableCell>

                  <TableCell sx={{ border: "1px solid black" }}>
                    <TextField
                      fullWidth
                      name="postGraduationBoardUniversity"
                      sx={{ background: "white" }}
                      value={props.detail?.postGraduationBoardUniversity}
                      error={Boolean(errors.postGraduationBoardUniversity)}
                      helperText={errors.postGraduationBoardUniversity}
                      onChange={(e) => {
                        validationHandler(e, "alphabetsAndSpace");
                        props.handler(e);
                      }}
                    />
                  </TableCell>

                  <TableCell sx={{ border: "1px solid black" }}>
                    <TextField
                      fullWidth
                      name="postGraduationDivisionGrade"
                      sx={{ background: "white" }}
                      value={props.detail?.postGraduationDivisionGrade}
                      error={Boolean(errors.postGraduationDivisionGrade)}
                      helperText={errors.postGraduationDivisionGrade}
                      onChange={(e) => {
                        validationHandler(e, "grade");
                        props.handler(e);
                      }}
                    />
                  </TableCell>

                  <TableCell sx={{ border: "1px solid black" }}>
                    <TextField
                      fullWidth
                      type="number"
                      name="postGraduationPercentageCGPA"
                      sx={{ background: "white" }}
                      value={props.detail?.postGraduationPercentageCGPA}
                      error={Boolean(errors.postGraduationPercentageCGPA)}
                      helperText={errors.postGraduationPercentageCGPA}
                      onChange={(e) => {
                        validationHandler(e, "number");
                        props.handler(e);
                      }}
                    />
                  </TableCell>

                  <TableCell sx={{ border: "1px solid black" }}>
                    <TextField
                      fullWidth
                      type="number"
                      name="postGraduationObtainedMaxTotalMax"
                      sx={{ background: "white" }}
                      value={props.detail?.postGraduationObtainedMaxTotalMax}
                      error={Boolean(errors.postGraduationObtainedMaxTotalMax)}
                      helperText={errors.postGraduationObtainedMaxTotalMax}
                      onChange={(e) => {
                        validationHandler(e, "number");
                        props.handler(e);
                      }}
                    />
                  </TableCell>
                </TableRow>

                <TableRow sx={{ mb: "1" }}>
                  <TableCell sx={{ border: "1px solid black" }}>
                    Other
                  </TableCell>

                  <TableCell sx={{ border: "1px solid black" }}>
                    <TextField
                      fullWidth
                      placeholder="Year"
                      type="month"
                      name="otherYear"
                      inputProps={{ max: currentMonthString }}
                      sx={{ background: "white" }}
                      value={props.detail.otherYear}
                      error={Boolean(errors.otherYear)}
                      helperText={errors.otherYear}
                      onChange={(e) => {
                        validationHandler(e);
                        props.handler(e);
                      }}
                    />
                  </TableCell>

                  <TableCell sx={{ border: "1px solid black" }}>
                    <TextField
                      fullWidth
                      name="otherBoardUniversity"
                      sx={{ background: "white" }}
                      value={props.detail.otherBoardUniversity}
                      error={Boolean(errors.otherBoardUniversity)}
                      helperText={errors.otherBoardUniversity}
                      onChange={(e) => {
                        validationHandler(e, "alphabetsAndSpace");
                        props.handler(e);
                      }}
                    />
                  </TableCell>

                  <TableCell sx={{ border: "1px solid black" }}>
                    <TextField
                      fullWidth
                      name="otherDivisionGrade"
                      sx={{ background: "white" }}
                      value={props.detail.otherDivisionGrade}
                      error={Boolean(errors.otherDivisionGrade)}
                      helperText={errors.otherDivisionGrade}
                      onChange={(e) => {
                        validationHandler(e, "grade");
                        props.handler(e);
                      }}
                    />
                  </TableCell>

                  <TableCell sx={{ border: "1px solid black" }}>
                    <TextField
                      fullWidth
                      name="otherPercentageCGPA"
                      type="number"
                      sx={{ background: "white" }}
                      value={props.detail.otherPercentageCGPA}
                      error={Boolean(errors.otherPercentageCGPA)}
                      helperText={errors.otherPercentageCGPA}
                      onChange={(e) => {
                        validationHandler(e, "number");
                        props.handler(e);
                      }}
                    />
                  </TableCell>

                  <TableCell sx={{ border: "1px solid black" }}>
                    <TextField
                      fullWidth
                      type="number"
                      name="otherObtainedMaxTotalMax"
                      sx={{ background: "white" }}
                      value={props.detail.otherObtainedMaxTotalMax}
                      error={Boolean(errors.otherObtainedMaxTotalMax)}
                      helperText={errors.otherObtainedMaxTotalMax}
                      onChange={(e) => {
                        validationHandler(e, "number");
                        props.handler(e);
                      }}
                    />
                  </TableCell>
                </TableRow>
              </TableBody>
            </Table>
          </TableContainer>
        </Grid>
        <Grid
          item
          xs={12}
          sm={12}
          sx={{ display: "flex", justifyContent: "center", m: "20px" }}
        >
          <Stack spacing={2} direction="row">
            <Button
              type="button"
              variant="contained"
              onClick={() => {
                props.setActiveStep(1);
                window.scrollTo(0, 0);
              }}
            >
              Back
            </Button>
            <Button variant="contained" type="submit">
              Next
            </Button>
          </Stack>
        </Grid>
      </Grid>
    </>
  );
}
