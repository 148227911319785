interface ValidationResponse {
  eventHandler: (id: string, val: string | File) => string;
}

const useTypescriptValidation = (): ValidationResponse => {
  const allowedExtensionsRegexImage = /^(jpg|jpeg|png|gif|bmp)$/i;
  const allowedExtensionsXlsxCsvRegex = /\.(xlsx|csv)$/i;
  const allowedExtensionsRegexPdf = /^.*pdf$/i;
  const maxSizeInBytes = 300 * 1024; // 300 KB   using for pdf,xlsx,csv
  const imageMaxSizeInBytes = 100 * 1024; // 100 KB  using for images

  const eventHandler = (id: string, val: string | File): string => {
    switch (id) {
      // Accept only letters and a length of character up to 30
      case "alphabet":
        if (!/^[a-zA-Z]{1,30}$/.test(val as string))
          return "Enter alphabets only";
        else {
          return "";
        }

      // Accept only letters and spaces in between characters
      case "alphabetsAndSpace":
        if (!/^[a-zA-Z]+(?: [a-zA-Z]+)*$/.test(val as string))
          return "Input should contain alphabets with spaces only in between";
        else {
          return "";
        }

      // Accept letters, numbers, and special characters
      case "email":
        if (
          !/^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zAZ0-9]+.)+[a-zA-Z]{2,}))$/.test(
            val as string
          )
        )
          return "Enter a valid email address";
        else {
          return "";
        }

      // Accept a string length between 8 and 12 characters, at least one lowercase and uppercase letter, a digit, and a special character.
      case "password":
        if (
          !/^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[!@#$%^&*()\-_=+{}[\]\\|:;"'<>,.?/~]).{8,12}$/.test(
            val as string
          )
        )
          return "Invalid password. Password must be 8 to 12 characters long and include at least one uppercase letter, one lowercase letter, one digit, and one special character.";
        else {
          return "";
        }

      // Pattern to check for Indian phone numbers
      case "mobile":
        if (!/^[6-9]\d{9}$/.test(val as string)) return "Invalid mobile number";
        else {
          return "";
        }

      // For the first loading form phone number
      case "phone":
        if (!/^(\+91)?[0]?(91)?[789]\d{9}$/.test(val as string))
          return "Invalid mobile number";
        else {
          return "";
        }

      // Accept only 6-digit number
      case "zipcode":
        if (!/^[1-9][0-9]{5}$/.test(val as string)) return "Invalid Code";
        else {
          return "";
        }

      // Accept only numbers without starting from zero
      case "numeric":
        if (!/^[1-9][0-9]*$/.test(val as string))
          return "Enter numbers only not starting with zero";
        else {
          return "";
        }

      // Accept only numbers with zero
      case "number":
        if (!/^[0-9]*$/.test(val as string)) return "Enter numbers only";
        else {
          return "";
        }

      // Accept both letters and numeric values
      case "alphanumeric":
        if (!/^[0-9a-zA-Z,-]+$/.test(val as string))
          return "Enter characters and numbers only   ";
        else {
          return "";
        }

      // Accept both letters and numeric values, spaces, and + symbol for grade
      case "grade":
        if (!/^[0-9a-zA-Z,+-]+$/.test(val as string))
          return "Enter characters and numbers only   ";
        else {
          return "";
        }

      // Accept letters, numbers, whitespace, punctuation marks, comma, and special characters
      case "address":
        if (!/^[a-zA-Z0-9\s,-]+$/.test(val as string))
          return "Enter a valid address";
        else {
          return "";
        }

      // Start from www/http
      case "url":
        if (
          !/^(http[s]?:\/\/(www\.)?|ftp:\/\/(www\.)?|www\.){1}([0-9A-Za-z-\.@:%_\+~#=]+)+((\.[a-zA-Z]{2,3})+)(\/(.)*)?(\?(.)*)?/g.test(
            val as string
          )
        )
          return "Invalid URL";
        else {
          return "";
        }

      // for checking xlsx/csv with size
      case "xlsxCsv":
        const { name: xlsxCsvName, size: xlsxCsvNameSize } = val as File;
        const dotIndex = xlsxCsvName.indexOf(".");
        const extractedSubstring =
          dotIndex !== -1 ? xlsxCsvName.substring(dotIndex) : "";

        if (
          !allowedExtensionsXlsxCsvRegex.test(extractedSubstring) ||
          xlsxCsvNameSize > maxSizeInBytes
        ) {
          return "Upload only xlsx/csv & size must be less than 300 KB";
        } else {
          return "";
        }

      // for checking pdf extension and size of file
      case "pdfSize":
        const pdfFileName = (val as File).name.split(".")[1];
        const pdfFileSize = (val as File).size;
        if (
          !allowedExtensionsRegexPdf.test(pdfFileName) ||
          pdfFileSize > maxSizeInBytes
        ) {
          return "Upload file in PDF & size less than 300KB";
        } else {
          return "";
        }

      //for checking image extension and size of file
      case "imageSize":
        const imageFileName = (val as File).name.split(".")[1];
        const imageFileSize = (val as File).size;
        if (
          !allowedExtensionsRegexImage.test(imageFileName) ||
          imageFileSize > imageMaxSizeInBytes
        ) {
          return "Upload file in image & size less than 100KB";
        } else {
          return "";
        }

      //for checking image extension pdf extension and different sizes
      case "pdfImageSize":
        const name = (val as File).name;
        const size = (val as File).size;
        const extension = name.split(".")[1];

        if (
          (!allowedExtensionsRegexImage.test(extension) &&
            !allowedExtensionsRegexPdf.test(extension)) ||
          (allowedExtensionsRegexImage.test(extension) &&
            size > imageMaxSizeInBytes) ||
          (allowedExtensionsRegexPdf.test(extension) && size > maxSizeInBytes)
        ) {
          if (allowedExtensionsRegexPdf.test(extension)) {
            return "pdf size should be less than 300 KB";
          }
          if (allowedExtensionsRegexImage.test(extension)) {
            return "image size should be less than 100 KB";
          }
          return "Upload image/pdf only";
        } else {
          return "";
        }

      // Indian Bank account number
      case "bankAccountNumber":
        if (!/^[0-9]{9,18}$/.test(val as string))
          return "Invalid bank account number";
        else return "";

      // Matches any alphabetic character (upper or lowercase), digit, space, comma, period, exclamation mark, question mark, single or double quotation marks, or hyphen.
      case "message":
        if (!/^[a-zA-Z0-9\s.,!?'"-]*$/.test(val as string))
          return "Invalid message";
        else return "";

      // // Validation accepts only image format
      // case "photo":
      //   if (!/(jpg|jpeg|png|gif|bmp)$/i.test(val as string))
      //     return "Upload image files only";
      //   else return "";

      // // Validation accepts only image and pdf
      // case "photoPdf":
      //   if (!/(jpg|jpeg|png|gif|bmp|pdf)$/i.test(val as string))
      //     return "Upload image and pdf files only";
      //   else return "";

      // // Validation accepts pdf file only
      // case "Pdf":
      //   if (!/(pdf)$/i.test(val as string)) return "Upload pdf files only";
      //   else return "";

      // Validation which only accepts alphabets & commas
      case "alphabetsCommaSpace":
        if (!/^[A-Za-z, ]+$/.test(val as string))
          return "Input should contain alphabets with commas & spaces only";
        else {
          return "";
        }

      // Validation for Aadhaar number
      case "aadhaar":
        if (!/^[1-9][0-9]{11}$/.test(val as string))
          return "Provide a 12-digit Aadhaar number";
        else {
          return "";
        }

      default:
        return "";
    }
  };

  return { eventHandler };
};

export default useTypescriptValidation;
