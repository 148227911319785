import * as React from "react";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Paper from "@mui/material/Paper";
import { Fragment, useEffect, useState } from "react";
import {
  Container,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  FormControl,
  Grid,
  IconButton,
  InputAdornment,
  LinearProgress,
  MenuItem,
  Select,
  Stack,
  TablePagination,
  Typography,
} from "@mui/material";
import { Button } from "@mui/material";
import TextField from "@mui/material/TextField";
import SearchIcon from "@mui/icons-material/Search";
import NocLetter from "./NocLetter";
import { PDFDownloadLink } from "@react-pdf/renderer";
import CommonSnackBar from "../../Components/Common/CommonSnackbar";
import {
  GET_STUDENT_MANAGE_NOC,
  UPDATE_STUDENT_MANAGE_NOC,
} from "../../api/server";
import { getRequest, updateRequest } from "../../api/api";

export default function ManageNoc(props: any) {
  //props from parent
  const paramsData = { id: props.types.lId, type: props.types.type };

  const [page, setPage] = React.useState(0);
  const [rowsPerPage, setRowsPerPage] = React.useState(10);
  const [detail, setDetail] = useState<any[]>([]);

  //search function
  const [searchApiData, setSearchApiData] = useState<any[]>([]);
  const [filterVal, setFilterVal] = useState("");

  // SnackBar
  const [snackMessage, setSnackMessage] = useState<string>("");
  const [openSnackBar, setOpenSnackBar] = useState(false);

  //review comment submit
  const [reviewSelection, setReviewSelection] = useState();
  const [reviewComment, setReviewComment] = useState();

  //Acknowledge accept noc
  const [openApproveNoc, setOpenApproveNoc] = useState({
    open: false,
    passId: undefined,
  });

  //dialog box  for reject noc
  const [openRejectNoc, setOpenRejectNoc] = useState({
    open: false,
    passId: undefined,
  });

  //reject comment submit
  const [rejectComment, setRejectComment] = useState<string>("");
  
  //loader state
  const [isLoading, setIsLoading] = useState(false);

  const handleOpenSnackBar = (message: string) => {
    try {
      setSnackMessage(message);
      setOpenSnackBar(true);
    } catch (error) {
      console.error("Error in handleOpenSnackBar:", error);
    }
  };

  const handleCloseSnackBar = () => {
    try {
      setOpenSnackBar(false);
    } catch (error) {
      console.error("Error in handleCloseSnackBar:", error);
    }
  };

  const viewDetails = async () => {
    try {
      let result = await getRequest(GET_STUDENT_MANAGE_NOC, {
        params: paramsData,
      });
      setDetail(result?.data);
      setSearchApiData(result?.data);
    } catch (error) {
      console.error("Error fetching data:", error);
    }
  };

  const acceptNoc = async (id: any) => {
    setIsLoading(true);
    await updateRequest(
      UPDATE_STUDENT_MANAGE_NOC,
      id,
      { statusNoc: "Approve" },
      ""
    );
    setOpenApproveNoc({
      ...openApproveNoc,
      open: false,
    });
    setIsLoading(false);
    handleOpenSnackBar("Noc Status Approved...!");
  };

  const rejectNoc = async (id: any) => {
    setIsLoading(true);
    if (rejectComment) {
      await updateRequest(
        UPDATE_STUDENT_MANAGE_NOC,
        id,
        {
          statusNoc: "Reject",
          rejectCommentNoc: rejectComment,
        },
        ""
      );
      setOpenRejectNoc({
        ...openRejectNoc,
        open: false,
      });
      setIsLoading(false);
      handleOpenSnackBar("Noc Status rejected...!");
    }
  };

  const reviewCommentSubmit = async (e: any) => {
    e.preventDefault();
    setIsLoading(true);
    if (reviewComment) {
      await updateRequest(
        UPDATE_STUDENT_MANAGE_NOC,
        reviewSelection,
        {
          reviewCommentNoc: reviewComment,
          statusNoc: "Review",
          rejectCommentNoc: "",
        },
        ""
      );
      setIsLoading(false);
      handleOpenSnackBar("Noc Status reviewed...!");
      viewDetails();
    }
  };

  const handleChangePage = (event: any, newPage: any) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event: any) => {
    setRowsPerPage(+event.target.value);
    setPage(0);
  };

  const handleFilter = (e: any) => {
    if (e.target.value === "") {
      setDetail(searchApiData);
    } else {
      const filterResult: any = searchApiData.filter((item: any) => {
        const searchValue = e.target.value.toLowerCase();
        return Object.values(item).some((value) => {
          if (typeof value === "string" || typeof value === "number") {
            const itemValue = String(value).toLowerCase();
            return itemValue.includes(searchValue);
          }
          return false;
        });
      });
      setDetail(filterResult);
    }
    setFilterVal(e.target.value);
  };

  useEffect(() => {
    viewDetails();
  }, [openRejectNoc]);

  return (
    <>
      <Container>
        {isLoading && <LinearProgress />}
        <Paper elevation={20}>
          {props.types?.type === "Center Head" ||
          props.types?.type === "Team Lead" ||
          props.types?.type === "Executive" ? (
            <>
              <Stack spacing={2}>
                <Grid
                  component={"form"}
                  container
                  sx={{
                    display: "flex",
                    justifyContent: "start",
                    gap: "10px",
                    m: "20px",
                  }}
                >
                  <Grid item>
                    <Typography>Student Name: </Typography>
                  </Grid>
                  <Grid item sm={4}>
                    <FormControl size="small" sx={{ width: "80%" }} required>
                      <Select
                        sx={{ textAlign: "center" }}
                        className="form-control"
                        type="text"
                        required
                        onChange={(e: any) =>
                          setReviewSelection(e.target.value)
                        }
                      >
                        {detail?.map((item: any) => (
                          <MenuItem value={item._id} key={item._id}>
                            {item.firstName + " " + item.lastName} (
                            {item.studentId})
                          </MenuItem>
                        ))}
                      </Select>
                    </FormControl>
                  </Grid>

                  <Grid item>
                    <TextField
                      required={true}
                      sx={{ background: "white" }}
                      type="text"
                      placeholder="Review Comment"
                      size="small"
                      onChange={(e: any) => setReviewComment(e.target.value)}
                    />
                  </Grid>
                  <Grid item>
                    <Button
                      type="submit"
                      variant="contained"
                      onClick={reviewCommentSubmit}
                    >
                      Submit NOC
                    </Button>
                  </Grid>
                </Grid>
              </Stack>
            </>
          ) : null}

          <Grid container></Grid>
          <Grid
            container
            sx={{
              p: 2,
              background: "#0288d1",
              color: "white",
            }}
          >
            <Grid item xs={6}>
              <Typography variant="h5" sx={{ mx: 3 }}>
                Manage NOC
              </Typography>
            </Grid>

            <Grid
              item
              xs={6}
              sx={{ display: "flex", justifyContent: "flex-end" }}
            >
              <FormControl>
                <TextField
                  label="Search by name and id"
                  size="small"
                  id="outlined-basic"
                  variant="outlined"
                  value={filterVal}
                  onInput={(e) => handleFilter(e)}
                  sx={{ mx: 3, background: "white" }}
                  InputProps={{
                    endAdornment: (
                      <InputAdornment position="end">
                        <IconButton edge="end">
                          <SearchIcon />
                        </IconButton>
                      </InputAdornment>
                    ),
                  }}
                />
              </FormControl>
            </Grid>
          </Grid>
          <TableContainer className="scrollBarCss">
            <Table sx={{ minWidth: 650 }} aria-label="simple table">
              <TableHead>
                <TableRow>
                  <TableCell
                    sx={{
                      textAlign: "center",
                      borderRight: "1px solid #ddd",
                      fontSize: "15px",
                    }}
                  >
                    Sr.No
                  </TableCell>
                  <TableCell
                    sx={{
                      textAlign: "center",
                      borderRight: "1px solid #ddd",
                      fontSize: "15px",
                    }}
                  >
                    Student Id
                  </TableCell>
                  <TableCell
                    sx={{
                      textAlign: "center",
                      borderRight: "1px solid #ddd",
                      fontSize: "15px",
                    }}
                  >
                    Student Name
                  </TableCell>
                  <TableCell
                    sx={{
                      textAlign: "center",
                      borderRight: "1px solid #ddd",
                      fontSize: "15px",
                    }}
                  >
                    NOC Status
                  </TableCell>
                  {props.types?.type === "Administrator" ||
                  props.types?.type === "Center Head" ||
                  props.types?.type === "Team Lead" ||
                  props.types?.type === "Executive" ? (
                    <>
                      <TableCell
                        sx={{
                          textAlign: "center",
                          borderRight: "1px solid #ddd",
                          fontSize: "15px",
                        }}
                      >
                        Review Comment
                      </TableCell>
                      <TableCell
                        sx={{
                          textAlign: "center",
                          borderRight: "1px solid #ddd",
                          fontSize: "15px",
                        }}
                      >
                        Reject Comment
                      </TableCell>
                    </>
                  ) : null}
                  {props.types?.type === "Administrator" ||
                  props.types?.type === "Student" ? (
                    <TableCell
                      sx={{
                        textAlign: "center",
                        borderRight: "1px solid #ddd",
                      }}
                    >
                      Action
                    </TableCell>
                  ) : null}
                </TableRow>
              </TableHead>
              <TableBody>
                {detail
                  ?.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                  .map((item: any, index: any) => (
                    <Fragment key={item._id}>
                      <TableRow
                        key={item._id}
                        sx={{
                          textAlign: "center",
                          borderRight: "1px solid #ddd",
                        }}
                      >
                        <TableCell
                          sx={{
                            textAlign: "center",
                            borderRight: "1px solid #ddd",
                          }}
                        >
                          {page * rowsPerPage + index + 1}
                        </TableCell>
                        <TableCell
                          component="th"
                          scope="row"
                          sx={{
                            textAlign: "center",
                            borderRight: "1px solid #ddd",
                          }}
                        >
                          {item.studentId}
                        </TableCell>

                        <TableCell
                          sx={{
                            textAlign: "center",
                            borderRight: "1px solid #ddd",
                          }}
                        >
                          {item.firstName + " " + item.lastName}
                        </TableCell>
                        <TableCell
                          sx={{
                            textAlign: "center",
                            borderRight: "1px solid #ddd",
                          }}
                        >
                          {item.statusNoc}
                        </TableCell>
                        {props.types?.type === "Administrator" ||
                        props.types?.type === "Center Head" ||
                        props.types?.type === "Team Lead" ||
                        props.types?.type === "Executive" ? (
                          <>
                            <TableCell
                              sx={{
                                textAlign: "center",
                                borderRight: "1px solid #ddd",
                              }}
                            >
                              {item.reviewCommentNoc}
                            </TableCell>
                            <TableCell
                              sx={{
                                textAlign: "center",
                                borderRight: "1px solid #ddd",
                              }}
                            >
                              {item.rejectCommentNoc}
                            </TableCell>
                          </>
                        ) : null}
                        {props.types?.type === "Administrator" ||
                        props.types?.type === "Student" ? (
                          <TableCell
                            sx={{
                              borderRight: "1px solid #ddd",
                            }}
                          >
                            <Stack
                              direction="row"
                              justifyContent="space-evenly"
                              alignItems="center"
                              spacing={2}
                            >
                              <PDFDownloadLink
                                document={<NocLetter object={item} />}
                              >
                                <Button variant="contained" size="small">
                                  Pdf
                                </Button>
                              </PDFDownloadLink>
                              {props.types?.type === "Administrator" ? (
                                <>
                                  <Button
                                    variant="contained"
                                    size="small"
                                    onClick={() =>
                                      setOpenApproveNoc({
                                        ...openApproveNoc,
                                        open: true,
                                        passId: item._id,
                                      })
                                    }
                                  >
                                    Approve
                                  </Button>

                                  <Dialog open={openApproveNoc.open}>
                                    <DialogTitle>
                                      Accept Noc Acknowledgement
                                    </DialogTitle>
                                    <DialogContent>
                                      <Typography>
                                        Are you sure want to accept NOC
                                      </Typography>
                                    </DialogContent>
                                    <DialogActions>
                                      <Button
                                        onClick={() =>
                                          acceptNoc(openApproveNoc.passId)
                                        }
                                      >
                                        Yes
                                      </Button>
                                      <Button
                                        onClick={() =>
                                          setOpenApproveNoc({
                                            ...openApproveNoc,
                                            open: false,
                                          })
                                        }
                                      >
                                        No
                                      </Button>
                                    </DialogActions>
                                  </Dialog>

                                  <Button
                                    variant="contained"
                                    color={"error"}
                                    size="small"
                                    onClick={() =>
                                      setOpenRejectNoc({
                                        ...openRejectNoc,
                                        open: true,
                                        passId: item._id,
                                      })
                                    }
                                  >
                                    Reject
                                  </Button>

                                  <Dialog open={openRejectNoc.open}>
                                    <DialogTitle>
                                      Reject NOC Acknowledgement
                                    </DialogTitle>

                                    <DialogContent>
                                      <TextField
                                        autoFocus
                                        margin="dense"
                                        label="comment"
                                        type="text"
                                        sx={{ width: "300px" }}
                                        variant="standard"
                                        required={true}
                                        onChange={(e: any) =>
                                          setRejectComment(e.target.value)
                                        }
                                      />
                                    </DialogContent>
                                    <DialogActions>
                                      <Button
                                        type="submit"
                                        onClick={() =>
                                          rejectNoc(openRejectNoc.passId)
                                        }
                                      >
                                        Submit
                                      </Button>
                                      <Button
                                        onClick={() =>
                                          setOpenRejectNoc({
                                            ...openRejectNoc,
                                            open: false,
                                          })
                                        }
                                      >
                                        Cancel
                                      </Button>
                                    </DialogActions>
                                  </Dialog>
                                </>
                              ) : null}
                            </Stack>
                          </TableCell>
                        ) : null}
                      </TableRow>
                    </Fragment>
                  ))}
              </TableBody>
            </Table>
          </TableContainer>
          <TablePagination
            rowsPerPageOptions={[10, 25, 50, 100]}
            component="div"
            count={detail?.length}
            rowsPerPage={rowsPerPage}
            page={page}
            onPageChange={handleChangePage}
            onRowsPerPageChange={handleChangeRowsPerPage}
          ></TablePagination>
          <CommonSnackBar
            open={openSnackBar}
            autoHideDuration={5000}
            onClose={handleCloseSnackBar}
            message={snackMessage}
          />
        </Paper>
      </Container>
    </>
  );
}
