import PlayArrowIcon from "@mui/icons-material/PlayArrow";
import Box from "@mui/material/Box";
import Container from "@mui/material/Container";
import Divider from "@mui/material/Divider";
import Grid from "@mui/material/Grid";
import Paper from "@mui/material/Paper";
import Typography from "@mui/material/Typography";
import { styled } from "@mui/material/styles";

const Item = styled(Paper)(({ theme }) => ({
  backgroundColor: theme.palette.mode === "dark" ? "#1A2027" : "#fff",
  ...theme.typography.body2,
  padding: theme.spacing(1),
  textAlign: "center",

  height: "100%",
}));

export default function Regsteps() {
  return (
    <>
      <Container
        sx={{ mt: { xs: "4rem", sm: "12rem", md: "1rem", lg: "2rem" } }}
      >
        <Typography
          variant="h4"
          sx={{
            textAlign: "start",
            fontWeight: "500",
            color: "#191970",
            mb: "30px",
          }}
        >
          Admission Process
        </Typography>
        <Box sx={{ flexGrow: 1 }}>
          <Grid container>
            <Grid item xs={12} sm={6} md>
              <Item>
                <Box>
                  <Typography
                    variant="h6"
                    fontWeight="bold"
                    sx={{ marginBottom: "20px" }}
                  >
                    Enquiry
                  </Typography>

                  <Typography
                    component="img"
                    src="https://ik.imagekit.io/nwssoft/Registration_process/MicrosoftTeams-image.png?updatedAt=1683717322973"
                    alt="step1"
                    sx={{
                      width: { xs: "20%", md: "50%" },
                      height: "30%",
                      border: 1,
                    }}
                  />

                  <Typography variant="body1" gutterBottom>
                    Enquiry about university through contact us
                  </Typography>
                </Box>
              </Item>
            </Grid>

            <Divider
              variant="middle"
              orientation="vertical"
              flexItem
              sx={{ display: { xs: "flex", sm: "none", md: "flex" } }}
            >
              <PlayArrowIcon
                orientation="vertical"
                sx={{ display: "flex", alignItems: "center", color: "orange" }}
              />
            </Divider>

            <Grid item xs={12} sm={6} md>
              <Item>
                <Box>
                  <Typography
                    variant="h6"
                    fontWeight="bold"
                    sx={{ marginBottom: "20px" }}
                  >
                    Registration
                  </Typography>

                  <Typography
                    component="img"
                    src="https://ik.imagekit.io/nwssoft/Registration_process/MicrosoftTeams-image__3_.png?updatedAt=1683717322936"
                    alt="step1"
                    sx={{
                      width: { xs: "20%", md: "50%" },
                      height: "30%",
                      border: 1,
                    }}
                  />

                  <Typography variant="body1" gutterBottom>
                    Fill and submit your application form online
                  </Typography>
                </Box>
              </Item>
            </Grid>

            <Divider
              variant="middle"
              orientation="vertical"
              flexItem
              sx={{ display: { xs: "flex", sm: "none", md: "flex" } }}
            >
              <PlayArrowIcon
                orientation="vertical"
                sx={{ display: "flex", alignItems: "center", color: "orange" }}
              />
            </Divider>
            <Grid item xs={12} sm={6} md>
              <Item>
                <Box>
                  <Typography
                    variant="h6"
                    fontWeight="bold"
                    sx={{ marginBottom: "20px" }}
                  >
                    Upload
                  </Typography>

                  <Typography
                    component="img"
                    src="https://ik.imagekit.io/nwssoft/Registration_process/MicrosoftTeams-image__2_.png?updatedAt=1683717323009"
                    alt="step1"
                    sx={{
                      width: { xs: "20%", md: "50%" },
                      height: "30%",
                      border: 1,
                    }}
                  />

                  <Typography variant="body1" gutterBottom>
                    Upload it on to our online platform
                  </Typography>
                </Box>
              </Item>
            </Grid>

            <Divider
              variant="middle"
              orientation="vertical"
              flexItem
              sx={{ display: { xs: "flex", sm: "none", md: "flex" } }}
            >
              <PlayArrowIcon
                orientation="vertical"
                sx={{ display: "flex", alignItems: "center", color: "orange" }}
              />
            </Divider>
            <Grid item xs={12} sm={6} md>
              <Item>
                <Box>
                  <Typography
                    variant="h6"
                    fontWeight="bold"
                    sx={{ marginBottom: "20px" }}
                  >
                    Payment
                  </Typography>

                  <Typography
                    component="img"
                    src="https://ik.imagekit.io/nwssoft/Registration_process/MicrosoftTeams-image__1_.png?updatedAt=1683717322637"
                    alt="step1"
                    sx={{
                      width: { xs: "20%", md: "50%" },
                      height: "30%",
                      border: 1,
                    }}
                  />

                  <Typography variant="body1" gutterBottom>
                    Pay the fees in online process
                  </Typography>
                </Box>
              </Item>
            </Grid>

            <Divider
              variant="middle"
              orientation="vertical"
              flexItem
              sx={{ display: { xs: "flex", sm: "none", md: "flex" } }}
            >
              <PlayArrowIcon
                orientation="vertical"
                sx={{ display: "flex", alignItems: "center", color: "orange" }}
              />
            </Divider>
            <Grid item xs={12} sm={6} md>
              <Item>
                <Box>
                  <Typography
                    variant="h6"
                    fontWeight="bold"
                    sx={{ marginBottom: "20px" }}
                  >
                    Confirm
                  </Typography>

                  <Typography
                    component="img"
                    src="https://ik.imagekit.io/nwssoft/Registration_process/MicrosoftTeams-image__4_.png?updatedAt=1683717323024"
                    alt="step1"
                    sx={{
                      width: { xs: "20%", md: "50%" },
                      height: "30%",
                      border: 1,
                    }}
                  />

                  <Typography variant="body1" gutterBottom>
                    Confirm your course details
                  </Typography>
                </Box>
              </Item>
            </Grid>
          </Grid>
        </Box>
      </Container>
    </>
  );
}
