import {
  Container,
  Grid,
  InputLabel,
  Paper,
  TextField,
  Typography,
} from "@mui/material";
import React, { useEffect } from "react";

export default function UniversityOrCoursePreview(props: any) {
  useEffect(() => {
    console.log("props of OtherDetails  is", props.Data);
  }, [props]);

  return (
    <Container  sx={{ mt: 3 }}>
      <Paper sx={{ p: 2 }} elevation={0}>
        <Grid container xs={12}>
          <Grid item xs={12}>
            <Typography fontWeight="500">University/Course Details:</Typography>
          </Grid>
          <Grid xs={12} sm={6} md={4}>
            <InputLabel
              htmlFor="standard-basic"
              sx={{ mt: 3, color: "#0288d1" }}
            >
              PA Enrollment No :
            </InputLabel>
            <TextField
              id="standard-basic"
              variant="standard"
              sx={{ backgroundColor: "white" }}
              value={props.Data?.studentId}
            />
          </Grid>
          <Grid xs={12} sm={6} md={4}>
            <InputLabel
              htmlFor="standard-basic"
              sx={{ mt: 3, color: "#0288d1" }}
            >
              University Enrollment No :
            </InputLabel>
            <TextField
              id="standard-basic"
              variant="standard"
              sx={{ backgroundColor: "white" }}
              value={props.Data?.enrollmentNo}
            />
          </Grid>
          <Grid xs={12} sm={6} md={4}>
            <InputLabel
              htmlFor="standard-basic"
              sx={{ mt: 3, color: "#0288d1" }}
            >
              Registration Type:
            </InputLabel>
            <TextField
              id="standard-basic"
              variant="standard"
              sx={{ backgroundColor: "white" }}
              value={props.Data?.regType}
            />
          </Grid>
          <Grid xs={12} sm={6} md={4}>
            <InputLabel
              htmlFor="standard-basic"
              sx={{ mt: 3, color: "#0288d1" }}
            >
              ID Card :
            </InputLabel>
            <TextField
              id="standard-basic"
              variant="standard"
              sx={{ backgroundColor: "white" }}
              value={props.Data?.idCard}
            />
          </Grid>
          {/* </Grid> */}
          {/* <Grid container xs={12}> */}
          <Grid xs={12} sm={6} md={4}>
            <InputLabel
              htmlFor="standard-basic"
              sx={{ mt: 3, color: "#0288d1" }}
            >
              Admission Date :
            </InputLabel>
            <TextField
              id="standard-basic"
              variant="standard"
              sx={{ backgroundColor: "white" }}
              value={props.Data?.courseData?.admissionDate}
            />
          </Grid>
          <Grid xs={12} sm={6} md={4}>
            <InputLabel
              htmlFor="standard-basic"
              sx={{ mt: 3, color: "#0288d1" }}
            >
              Course Year/Sem:
            </InputLabel>
            <TextField
              id="standard-basic"
              variant="standard"
              sx={{ backgroundColor: "white" }}
              value={props.Data?.courseData?.admissionSemesterYear}
            />
          </Grid>
          <Grid xs={12} sm={6} md={4}>
            <InputLabel
              htmlFor="standard-basic"
              sx={{ mt: 3, color: "#0288d1" }}
            >
              Course Catageory :
            </InputLabel>
            <TextField
              id="standard-basic"
              variant="standard"
              sx={{ backgroundColor: "white" }}
              value={props.Data?.courseData?.courseCategory}
            />
          </Grid>
          {/* </Grid> */}
          {/* <Grid container xs={12}> */}
          <Grid xs={12} sm={6} md={4}>
            <InputLabel
              htmlFor="standard-basic"
              sx={{ mt: 3, color: "#0288d1" }}
            >
              Course Name :
            </InputLabel>
            <TextField
              id="standard-basic"
              variant="standard"
              sx={{ backgroundColor: "white" }}
              value={props.Data?.courseData?.courseName}
            />
          </Grid>
          <Grid xs={12} sm={6} md={4}>
            <InputLabel
              htmlFor="standard-basic"
              sx={{ mt: 3, color: "#0288d1" }}
            >
              Department:
            </InputLabel>
            <TextField
              id="standard-basic"
              variant="standard"
              sx={{ backgroundColor: "white" }}
              value={props.Data?.courseData?.departmentName}
            />
          </Grid>
          <Grid xs={12} sm={6} md={4}>
            <InputLabel
              htmlFor="standard-basic"
              sx={{ mt: 3, color: "#0288d1" }}
            >
              Total Course Fee:
            </InputLabel>
            <TextField
              id="standard-basic"
              variant="standard"
              sx={{ backgroundColor: "white" }}
              value={props.Data?.courseData?.courseFees}
            />
          </Grid>

          {/* <Grid container xs={12}> */}
          <Grid xs={12} sm={6} md={4}>
            <InputLabel
              htmlFor="standard-basic"
              sx={{ mt: 3, color: "#0288d1" }}
            >
              Mode :
            </InputLabel>
            <TextField
              id="standard-basic"
              variant="standard"
              sx={{ backgroundColor: "white" }}
              value={props.Data?.courseData?.mode}
            />
          </Grid>
          <Grid xs={12} sm={6} md={4}>
            <InputLabel
              htmlFor="standard-basic"
              sx={{ mt: 3, color: "#0288d1" }}
            >
              Total No of Semester/Year:
            </InputLabel>
            <TextField
              id="standard-basic"
              variant="standard"
              sx={{ backgroundColor: "white" }}
              value={props.Data?.courseData?.noOfSemsOrYears}
            />
          </Grid>

          <Grid xs={12} sm={6} md={4}>
            <InputLabel
              htmlFor="standard-basic"
              sx={{ mt: 3, color: "#0288d1" }}
            >
              Specialization :
            </InputLabel>
            <TextField
              id="standard-basic"
              variant="standard"
              sx={{ backgroundColor: "white" }}
              value={props.Data?.courseData?.specialization}
            />
          </Grid>

          {/* </Grid> */}
          {/* <Grid container xs={12}> */}
          <Grid xs={12} sm={6} md={4}>
            <InputLabel
              htmlFor="standard-basic"
              sx={{ mt: 3, color: "#0288d1" }}
            >
              University Name:
            </InputLabel>
            <TextField
              id="standard-basic"
              variant="standard"
              sx={{ backgroundColor: "white" }}
              value={props.Data?.courseData?.universityName}
            />
          </Grid>
          <Grid xs={12} sm={6} md={4}>
            <InputLabel
              htmlFor="standard-basic"
              sx={{ mt: 3, color: "#0288d1" }}
            >
              Hall Tiket/Admit Card :
            </InputLabel>
            <TextField
              id="standard-basic"
              variant="standard"
              sx={{ backgroundColor: "white" }}
              value={props.Data?.hallTicketOrAdmitCard}
            />
          </Grid>
          <Grid xs={12} sm={6} md={4}>
            <InputLabel
              htmlFor="standard-basic"
              sx={{ mt: 3, color: "#0288d1" }}
            >
              Final Degree/Diploma :
            </InputLabel>
            <TextField
              id="standard-basic"
              variant="standard"
              sx={{ backgroundColor: "white" }}
              value={props.Data?.finalDegreeOrDiploma}
            />
          </Grid>
          {/* </Grid>
        <Grid container xs={12}> */}
          <Grid xs={12} sm={6} md={4}>
            <InputLabel
              htmlFor="standard-basic"
              sx={{ mt: 3, color: "#0288d1" }}
            >
              Marksheet :
            </InputLabel>
            <TextField
              id="standard-basic"
              variant="standard"
              sx={{ backgroundColor: "white" }}
              value={props.Data?.markSheet}
            />
          </Grid>
          <Grid xs={12} sm={6} md={4}>
            <InputLabel
              htmlFor="standard-basic"
              sx={{ mt: 3, color: "#0288d1" }}
            >
              Migration Certificate :
            </InputLabel>
            <TextField
              id="standard-basic"
              variant="standard"
              sx={{ backgroundColor: "white" }}
              value={props.Data?.migrationCertificate}
            />
          </Grid>
          <Grid xs={12} sm={6} md={4}>
            <InputLabel
              htmlFor="standard-basic"
              sx={{ mt: 3, color: "#0288d1" }}
            >
              Provisional Certificate :
            </InputLabel>
            <TextField
              id="standard-basic"
              variant="standard"
              sx={{ backgroundColor: "white" }}
              value={props.Data?.provisionalCertificate}
            />
          </Grid>
          {/* </Grid>
        <Grid container xs={12}> */}
          <Grid xs={12} sm={6} md={4}>
            <InputLabel
              htmlFor="standard-basic"
              sx={{ mt: 3, color: "#0288d1" }}
            >
              Offline Result :
            </InputLabel>
            <TextField
              id="standard-basic"
              variant="standard"
              sx={{ backgroundColor: "white" }}
              value={props.Data?.resultOffline}
            />
          </Grid>
          <Grid xs={12} sm={6} md={4}>
            <InputLabel
              htmlFor="standard-basic"
              sx={{ mt: 3, color: "#0288d1" }}
            >
              Online Result :
            </InputLabel>
            <TextField
              id="standard-basic"
              variant="standard"
              sx={{ backgroundColor: "white" }}
              value={props.Data?.resultOnline}
            />
          </Grid>
          <Grid xs={12} sm={6} md={4}>
            <InputLabel
              htmlFor="standard-basic"
              sx={{ mt: 3, color: "#0288d1" }}
            >
              Status :
            </InputLabel>
            <TextField
              id="standard-basic"
              variant="standard"
              sx={{ backgroundColor: "white" }}
              value={props.Data?.status}
            />
          </Grid>
          {/* </Grid>
        <Grid container xs={12}> */}
          <Grid xs={12} sm={6} md={4}>
            <InputLabel
              htmlFor="standard-basic"
              sx={{ mt: 3, color: "#0288d1" }}
            >
              NOC Status :
            </InputLabel>
            <TextField
              id="standard-basic"
              variant="standard"
              sx={{ backgroundColor: "white" }}
              value={props.Data?.status}
            />
          </Grid>
        </Grid>
      </Paper>
    </Container>
  );
}
