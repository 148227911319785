import React, { Fragment } from "react";
import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import {
  Collapse,
  Container,
  FormControl,
  Grid,
  IconButton,
  InputAdornment,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TablePagination,
  TableRow,
  TextField,
  Tooltip,
  Typography,
} from "@mui/material";
import SearchIcon from "@mui/icons-material/Search";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import { getRequest } from "../../api/api";
import { GET_LEAD_ADMIN } from "../../api/server";
import PlaylistAddCheckOutlinedIcon from "@mui/icons-material/PlaylistAddCheckOutlined";
import "../../Components/Home/Home.scss";
import TableToExcel from "../../Components/Common/TableToExcel";

const column: any = [
  { id: 1, headerName: "Phone", field: "phone" },
  { id: 2, headerName: "Email", field: "email" },
];

export default function ManageAssignedLeadAdmin() {
  const navigate = useNavigate();

  // state to maintain table data
  const [tableData, setTableData] = useState<any[]>([]);

  //state for expandible row management
  const [expandedRow, setExpandedRow] = useState<string | null>(null);

  // for pagination
  const [page, setPage] = React.useState(0);
  const [rowsPerPage, setRowsPerPage] = React.useState(10);

  // state for managing search bar
  const [searchApiData, setSearchApiData] = useState<any[]>([]);

  //state for filtering data, to store input condition
  const [filterVal, setFilterVal] = useState<string>("");

  async function getTableData() {
    try {
      const response: any = await getRequest(GET_LEAD_ADMIN, "");
      setTableData(response.data);
      setSearchApiData(response.data);
    } catch (error) {
      console.error(error);
    }
  }

  const handleChangePage = (event: any, newPage: any) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event: any) => {
    setRowsPerPage(+event.target.value);
    setPage(0);
  };

  // function to handle filter search
  const handleFilter = (e: any) => {
    if (e.target.value === "") {
      setTableData(searchApiData);
    } else {
      const filterResult = searchApiData.filter((item: any) => {
        const searchValue = e.target.value.toLowerCase();
        return Object.values(item).some((value) => {
          if (typeof value === "string" || typeof value === "number") {
            const itemValue = String(value).toLowerCase();
            return itemValue.includes(searchValue);
          }
          return false;
        });
      });
      setTableData(filterResult);
    }
    setFilterVal(e.target.value);
  };

  //handler for email click and open mail tab
  const handleEmailClick = (email: any) => {
    window.open(`mailto:${email}`, "_blank");
  };

  //function to handle open row
  const handleRowClick = (rowId: string) => {
    setExpandedRow((prevRowId) => (prevRowId === rowId ? null : rowId));
  };

  // Modify the tableData to include the concatenated values
  const modifiedTableData = tableData.map((row) => {
    const assignedCenterHead = `${row.chFirstName || ""} ${
      row.chLastName || ""
    } ${row.chEmpId || ""}`;
    const assignedTeamLead = `${row.tlFirstName || ""} ${
      row.tlLastName || ""
    } ${row.tlEmpId || ""}`;
    const assignedExecutive = `${row.exFirstName || ""} ${
      row.exLastName || ""
    } ${row.exEmpId || ""}`;
    return {
      ...row,
      Name: `${row.fname} ${row.lname}`,
      "Assigned Center Head": assignedCenterHead,
      "Assigned TeamLead": assignedTeamLead,
      "Assigned Executive": assignedExecutive,
    };
  });

  useEffect(() => {
    getTableData();
  }, []);

  return (
    <>
      <Container>
        <Paper elevation={20}>
          <Grid
            container
            sx={{
              p: 2,
              background: "#0288d1",
              color: "white",
            }}
          >
            <Grid item xs={6}>
              <Typography variant="h5" sx={{ mx: 3 }}>
                Manage Assigned Lead
              </Typography>
            </Grid>

            <Grid item xs={3}>
              <TableToExcel
                tableData={modifiedTableData}
                fileName="Assigned_Lead_data"
                headerMapping={{
                  Name: "Name",
                  Phone: "phone",
                  Email: "email",
                  "Assigned Center Head": "Assigned Center Head",
                  "Assigned TeamLead": "Assigned TeamLead",
                  "Assigned Executive": "Assigned Executive",
                  Experience: "experience",
                  Qualification: "qualification",
                  State: "state",
                  City: "city",
                  "Passing Year": "passingYear",
                  "Reference By": "refBy",
                  "Reference Name": "refName",
                  "Interested Course": "interestedCourse",
                }}
              />
            </Grid>
            <Grid
              item
              xs={3}
              sx={{ display: "flex", justifyContent: "flex-end" }}
            >
              <FormControl>
                <TextField
                  label="Search "
                  size="small"
                  value={filterVal}
                  onInput={(e: any) => handleFilter(e)}
                  sx={{ mx: 3, background: "white" }}
                  InputProps={{
                    endAdornment: (
                      <InputAdornment position="end">
                        <IconButton edge="end">
                          <SearchIcon />
                        </IconButton>
                      </InputAdornment>
                    ),
                  }}
                />
              </FormControl>
            </Grid>
          </Grid>
          <TableContainer className="scrollBarCss">
            {/* Table Headings */}
            <Table>
              <TableHead>
                <TableRow>
                  {/* Add an empty cell for the expand icon */}
                  <TableCell
                    sx={{
                      textAlign: "center",
                      fontWeight: "bold",
                      border: "1px solid #ddd",
                    }}
                  ></TableCell>
                  <TableCell
                    sx={{
                      textAlign: "center",
                      fontWeight: "bold",
                      border: "1px solid #ddd",
                    }}
                  >
                    Sr.No
                  </TableCell>
                  <TableCell
                    sx={{
                      textAlign: "center",
                      fontWeight: "bold",
                      border: "1px solid #ddd",
                    }}
                  >
                    Name
                  </TableCell>
                  {column.map((column: any, index: number) => (
                    <TableCell
                      key={index}
                      sx={{
                        textAlign: "center",
                        fontWeight: "bold",
                        border: "1px solid #ddd",
                      }}
                    >
                      {column.headerName}
                    </TableCell>
                  ))}
                  <TableCell
                    sx={{
                      textAlign: "center",
                      fontWeight: "bold",
                      border: "1px solid #ddd",
                    }}
                  >
                    Assigned CenterHead
                  </TableCell>
                  <TableCell
                    sx={{
                      textAlign: "center",
                      fontWeight: "bold",
                      border: "1px solid #ddd",
                    }}
                  >
                    Assigned TeamLead
                  </TableCell>
                  <TableCell
                    sx={{
                      textAlign: "center",
                      fontWeight: "bold",
                      border: "1px solid #ddd",
                    }}
                  >
                    Assigned Executive
                  </TableCell>
                  <TableCell
                    sx={{
                      textAlign: "center",
                      fontWeight: "bold",
                      border: "1px solid #ddd",
                    }}
                  >
                    Follow Up
                  </TableCell>
                </TableRow>
              </TableHead>

              {/* Table Data Rows */}
              <TableBody>
                {tableData.map((item: any, index: number) => (
                  <Fragment key={index}>
                    {/* Data Row */}
                    <TableRow
                      className="onhover"
                      sx={{
                        textAlign: "center",
                        border: "1px solid #ddd",
                      }}
                    >
                      {/* Add the expand icon here */}
                      <TableCell
                        sx={{
                          textAlign: "center",
                          cursor: "pointer",
                          border: "1px solid #ddd",
                        }}
                        onClick={(e) => {
                          e.stopPropagation();
                          handleRowClick(item._id);
                        }}
                      >
                        <IconButton
                          size="small"
                          aria-label="expand row"
                          sx={{
                            color:
                              expandedRow === item._id ? "#0288d1" : "#000",
                          }}
                        >
                          <ExpandMoreIcon />
                        </IconButton>
                      </TableCell>
                      <TableCell
                        sx={{
                          textAlign: "center",
                          borderRight: "1px solid #ddd",
                          borderLeft: "1px solid #ddd",
                        }}
                      >
                        {page * rowsPerPage + index + 1}
                      </TableCell>
                      <TableCell
                        sx={{
                          textAlign: "center",
                          borderRight: "1px solid #ddd",
                          borderLeft: "1px solid #ddd",
                        }}
                      >
                        {`${item.fname} ${item.lname}`}
                      </TableCell>
                      {column.map((column: any, ind: number) =>
                        column.field === "email" ? (
                          <Fragment key={ind}>
                            <TableCell
                              sx={{
                                textAlign: "center",
                                borderRight: "1px solid #ddd",
                                borderLeft: "1px solid #ddd",
                                cursor: "pointer",
                                color: "blue",
                              }}
                              onClick={() =>
                                handleEmailClick(item[column.field])
                              }
                            >
                              {item[column.field]}
                            </TableCell>
                          </Fragment>
                        ) : (
                          <Fragment key={ind}>
                            <TableCell
                              sx={{
                                textAlign: "center",
                                borderRight: "1px solid #ddd",
                                borderLeft: "1px solid #ddd",
                              }}
                            >
                              {item[column.field]}
                            </TableCell>
                          </Fragment>
                        )
                      )}
                      <TableCell
                        sx={{
                          textAlign: "center",
                          borderRight: "1px solid #ddd",
                          borderLeft: "1px solid #ddd",
                        }}
                      >
                        {item.chFirstName
                          ? `${item?.chFirstName} ${item?.chLastName} (${item?.chEmpId})`
                          : "Not yet assigned!"}
                      </TableCell>
                      <TableCell
                        sx={{
                          textAlign: "center",
                          borderRight: "1px solid #ddd",
                          borderLeft: "1px solid #ddd",
                        }}
                      >
                        {item.tlFirstName
                          ? `${item?.tlFirstName} ${item?.tlLastName} (${item?.tlEmpId})`
                          : "Not yet assigned!"}
                      </TableCell>
                      <TableCell
                        sx={{
                          textAlign: "center",
                          borderRight: "1px solid #ddd",
                          borderLeft: "1px solid #ddd",
                        }}
                      >
                        {item.exFirstName
                          ? `${item?.exFirstName} ${item?.exLastName} (${item?.exEmpId})`
                          : "Not yet assigned!"}
                      </TableCell>
                      <TableCell>
                        <Grid
                          container
                          sx={{ display: "flex", justifyContent: "center" }}
                        >
                          <Tooltip title="View Follow-Ups">
                            <IconButton
                              component={"span"}
                              aria-label="edit"
                              color="primary"
                              onClick={() => {
                                navigate(`ViewActivity/${item._id}`);
                              }}
                            >
                              <PlaylistAddCheckOutlinedIcon />
                            </IconButton>
                          </Tooltip>
                        </Grid>
                      </TableCell>
                    </TableRow>

                    {/* Collapsible Row */}
                    <TableRow>
                      <TableCell colSpan={column.length + 7}>
                        <Collapse
                          in={expandedRow === item._id}
                          timeout="auto"
                          unmountOnExit
                        >
                          <Grid container rowSpacing={1}>
                            <Grid item xs={12} sm={3}>
                              <Typography
                                sx={{
                                  fontWeight: "bold",
                                  display: "inline",
                                  mx: 2,
                                }}
                              >
                                Experience:
                              </Typography>
                              <Typography sx={{ display: "inline" }}>
                                {item.experience}
                              </Typography>
                            </Grid>
                            <Grid item xs={12} sm={3}>
                              <Typography
                                sx={{
                                  fontWeight: "bold",
                                  display: "inline",
                                  mx: 2,
                                }}
                              >
                                Qualification:
                              </Typography>
                              <Typography sx={{ display: "inline" }}>
                                {item.qualification}
                              </Typography>
                            </Grid>
                            <Grid item xs={12} sm={3}>
                              <Typography
                                sx={{
                                  fontWeight: "bold",
                                  display: "inline",
                                  mx: 2,
                                }}
                              >
                                State:
                              </Typography>
                              <Typography sx={{ display: "inline" }}>
                                {item.state}
                              </Typography>
                            </Grid>
                            <Grid item xs={12} sm={3}>
                              <Typography
                                sx={{
                                  fontWeight: "bold",
                                  display: "inline",
                                  mx: 2,
                                }}
                              >
                                City:
                              </Typography>
                              <Typography sx={{ display: "inline" }}>
                                {item.city}
                              </Typography>
                            </Grid>
                            <Grid item xs={12} sm={3}>
                              <Typography
                                sx={{
                                  fontWeight: "bold",
                                  display: "inline",
                                  mx: 2,
                                }}
                              >
                                Passing Year:
                              </Typography>
                              <Typography sx={{ display: "inline" }}>
                                {item.passingYear}
                              </Typography>
                            </Grid>
                            <Grid item xs={12} sm={3}>
                              <Typography
                                sx={{
                                  fontWeight: "bold",
                                  display: "inline",
                                  mx: 2,
                                }}
                              >
                                Reference By:
                              </Typography>
                              <Typography sx={{ display: "inline" }}>
                                {item.refBy}
                              </Typography>
                            </Grid>
                            <Grid item xs={12} sm={6}>
                              <Typography
                                sx={{
                                  fontWeight: "bold",
                                  display: "inline",
                                  mx: 2,
                                }}
                              >
                                Reference Name:
                              </Typography>
                              <Typography sx={{ display: "inline" }}>
                                {item.refName}
                              </Typography>
                            </Grid>
                          </Grid>
                        </Collapse>
                      </TableCell>
                    </TableRow>
                  </Fragment>
                ))}
              </TableBody>
            </Table>
          </TableContainer>

          <Grid sx={{ mt: 2 }}>
            <TablePagination
              rowsPerPageOptions={[10, 25, 50, 100]}
              component="div"
              count={tableData.length}
              rowsPerPage={rowsPerPage}
              page={page}
              onPageChange={handleChangePage}
              onRowsPerPageChange={handleChangeRowsPerPage}
            ></TablePagination>
          </Grid>
        </Paper>
      </Container>
    </>
  );
}
