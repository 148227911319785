import {
  Button,
  Container,
  Grid,
  Paper,
  TextField,
  Typography,
} from "@mui/material";
import { AxiosResponse } from "axios";
import { debounce } from "lodash";
import { ChangeEvent, FC, SyntheticEvent, useState } from "react";
import useValidation from "../../../Hooks/useTypescriptValidation";
import { postRequestMethod } from "../../../api/api";
import { PAYMENT } from "../../../api/server";
import CommonSnackBar from "../CommonSnackbar";

interface PaymentProps {}

interface PaymentDetails {
  amount: string;
  email: string;
}

const WebsitePayment: FC<PaymentProps> = () => {
  let { eventHandler } = useValidation();
  const [data, setData] = useState<PaymentDetails>({
    amount: "",
    email: "",
  });

  const [errors, setErrors] = useState<{ [key: string]: string }>({});
  const [snackMessage, setSnackMessage] = useState<string>("");
  const [openSnackBar, setOpenSnackBar] = useState<boolean>(false);

  const handleOpenSnackBar = () => {
    setOpenSnackBar(true);
    setTimeout(() => {
      setOpenSnackBar(false);
    }, 3000);
  };

  function isDate(val: any): val is Date {
    return Object.prototype.toString.call(val) === "[object Date]";
  }

  function isObj(val: any): val is object {
    return typeof val === "object";
  }

  function stringifyValue(val: any): string {
    if (isObj(val) && !isDate(val)) {
      return JSON.stringify(val);
    } else {
      return val.toString();
    }
  }

  function buildForm({
    action,
    params,
  }: {
    action: string;
    params: Record<string, any>;
  }) {
    const form = document.createElement("form");
    form.setAttribute("method", "post");
    form.setAttribute("action", action);

    Object.keys(params)?.forEach((key) => {
      const input = document.createElement("input");
      input.setAttribute("type", "hidden");
      input.setAttribute("name", key);
      input.setAttribute("value", stringifyValue(params[key]));
      form.appendChild(input);
    });

    return form;
  }

  function post(details: { action: string; params: Record<string, any> }) {
    const form = buildForm(details);
    document.body.appendChild(form);
    form.submit();
    form.remove();
  }

  const validationHandler = debounce(
    (e: ChangeEvent<HTMLInputElement>, alterName?: any) => {
      const val = e.target.value;
      const id = alterName;
      const err = eventHandler(id, val);

      setErrors((prevData: { [key: string]: string }) => ({
        ...prevData,
        [e.target.name]: err,
      }));

      if (e.target.value.length === 0) {
        setErrors((prevData: { [key: string]: string }) => ({
          ...prevData,
          [e.target.name]: "",
        }));
      }
    },
    2000
  );

  const getData = async (data: PaymentDetails): Promise<any> => {
    try {
      const { amount, email } = data;
      const modifiedAmount = Number(amount);
      const response: void | AxiosResponse<any, any> = await postRequestMethod(
        PAYMENT,
        "",
        { email, amount: modifiedAmount }
      );
      return response?.data;
    } catch (error) {
      console.error(error);
      throw error;
    }
  };

  const handlePayment = (e: SyntheticEvent) => {
    e.preventDefault();
    if (validator()) {
      setSnackMessage("Fill valid information");
      return handleOpenSnackBar();
    }
    getData(data).then((response) => {
      const information = {
        action: "https://securegw.paytm.in/order/process",
        params: response,
      };
      post(information);
    });
  };

  const handleInputChange = (e: ChangeEvent<HTMLInputElement>) => {
    setData((preData: PaymentDetails) => ({
      ...preData,
      [e.target.name]: e.target.value,
    }));
  };

  const validator = (): boolean => {
    for (let field in errors) {
      if (errors[field] !== "") {
        return true;
      }
    }
    return false;
  };

  return (
    <>
      <Container
        maxWidth="md"
        sx={{ mt: 15, mb: 5 }}
        component="form"
        onSubmit={handlePayment}
      >
        <Paper elevation={4} sx={{ padding: 2 }}>
          <Grid
            container
            rowSpacing={2}
            columnSpacing={4}
            alignItems="center"
            justifyContent="center"
          >
            <Grid
              item
              xs={12}
              display="flex"
              justifyContent="center"
              alignItems="center"
            >
              <Typography variant="h6" color="#0288d1">
                WELCOME TO PRERNA ACADEMY PAYMENT
              </Typography>
            </Grid>
            <Grid item xs={6} display="flex" justifyContent="flex-end">
              <TextField
                required
                placeholder="Amount"
                type="text"
                name="amount"
                sx={{ background: "white" }}
                value={data.amount}
                size="small"
                error={Boolean(errors.amount)}
                helperText={errors.amount}
                onChange={(e: ChangeEvent<HTMLInputElement>) => {
                  handleInputChange(e);
                  validationHandler(e, "numeric");
                }}
              />
            </Grid>
            <Grid item xs={6} display="flex" justifyContent="flex-start">
              <TextField
                required
                placeholder="Email"
                type="email"
                name="email"
                sx={{ background: "white" }}
                value={data.email}
                size="small"
                error={Boolean(errors.email)}
                helperText={errors.email}
                onChange={(e: ChangeEvent<HTMLInputElement>) => {
                  handleInputChange(e);
                  validationHandler(e, "email");
                }}
              />
            </Grid>
            <Grid
              item
              xs={12}
              display="flex"
              justifyContent="center"
              alignItems="center"
            >
              <Button type="submit" variant="contained">
                Pay Now
              </Button>
              <CommonSnackBar message={snackMessage} open={openSnackBar} />
            </Grid>
          </Grid>
        </Paper>
      </Container>
    </>
  );
};

export default WebsitePayment;
