import {
  FormControl,
  InputLabel,
  LinearProgress,
  MenuItem,
  Paper,
  Select,
} from "@mui/material";
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import Container from "@mui/material/Container";
import Grid from "@mui/material/Grid";
import TextField from "@mui/material/TextField";
import Typography from "@mui/material/Typography";
import { useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import CommonSnackBar from "../../Components/Common/CommonSnackbar";
import useValidation from "../../Hooks/useTypescriptValidation";
import { updateRequest } from "../../api/api";
import { STUDENT_COURIER_PUT } from "../../api/server";

export default function EditCourier() {
  const navigate = useNavigate();
  const location = useLocation();
  let { eventHandler } = useValidation();

  const [openSnackBar, setOpenSnackBar] = useState(false);
  const [snackMessage, setSnackMessage] = useState<string>("");
  const [isLoading, setIsLoading] = useState(false);

  const [sendCourier, setSendCourier] = useState({
    status: location?.state?.status,
    remark: "",
  });

  const [errors, setErrors] = useState({
    status: "",
    remark: "",
  });

  const ValidationHandler = async (e: any, alterName?: any) => {
    const val = e.target.value;
    const id = alterName;
    if (id) {
      let prom = new Promise((resolve, reject) => {
        if (true) {
          resolve(eventHandler(id, val));
        }
      });
      prom
        .then((res) => setErrors({ ...errors, [e.target.name]: res }))
        .then(() => setSendCourier({ ...sendCourier, [e.target.name]: val }));
    } else {
      setSendCourier({ ...sendCourier, [e.target.name]: val });
    }
  };

  const validator = (errors: { [key: string]: string }) => {
    for (let field in errors) {
      if (errors[field] !== "") {
        return false;
      }
    }
    return true;
  };

  const onSubmit = async (event: any) => {
    event.preventDefault();
    setIsLoading(true);
    if (validator(errors)) {
      const result = await updateRequest(
        STUDENT_COURIER_PUT,
        location?.state?._id,
        sendCourier,
        ""
      )
        .then((resp: any) => {
          if (resp) {
            setIsLoading(false);
            setSnackMessage(resp.data);
            handleOpenSnackBar();
            setTimeout(() => navigate("/dashboard/Courier"), 3000);
          } else {
            setIsLoading(false);
            setSnackMessage("Something went wrong");
            handleOpenSnackBar();
          }
        })
        .catch((error) => {
          console.error("Error fetching data:", error);
        });
    }
  };

  const handleOpenSnackBar = () => {
    setOpenSnackBar(true);
    setTimeout(() => {
      setOpenSnackBar(false);
    }, 5000);
  };

  return (
    <Container>
      {isLoading && <LinearProgress />}
      <Paper elevation={20} sx={{ p: 3 }}>
        <Box sx={{ p: "10px" }} component="form" onSubmit={onSubmit}>
          <Typography
            variant="h6"
            align="center"
            sx={{ alignItems: "center", color: "#0288d1" }}
          >
            Edit Courier
          </Typography>
          <Grid item xs={12}>
            <TextField
              disabled
              InputLabelProps={{ shrink: true }}
              value={location?.state?.courierCompany}
              fullWidth
              id="filled-password-input"
              label="Courier Company"
              type="text"
              name="courierCompany"
              required
              sx={{ mt: 3, background: "white" }}
            />
          </Grid>

          <Grid item xs={12}>
            <TextField
              disabled
              value={location?.state?.consignmentNo}
              fullWidth
              id="filled-password-input"
              label="Consignment No"
              type="text"
              name="consignmentNo"
              required
              InputLabelProps={{ shrink: true }}
              sx={{ mt: 3, background: "white" }}
            />
          </Grid>

          <Grid item xs={12}>
            <TextField
              disabled
              value={location?.state?.bookingDate}
              fullWidth
              InputLabelProps={{ shrink: true }}
              id="filled-password-input"
              name="bookingDate"
              label="Booking Date"
              type="date"
              required
              InputProps={{
                inputProps: { min: new Date().toISOString().slice(0, 10) },
              }}
              sx={{ mt: 3, background: "white" }}
            />
          </Grid>

          <Grid item xs={12}>
            <TextField
              disabled
              value={location?.state?.bookingCenter}
              InputLabelProps={{ shrink: true }}
              fullWidth
              id="filled-password-input"
              label="Booking Center"
              type="text"
              required
              name="bookingCenter"
              sx={{ mt: 3, background: "white" }}
            />
          </Grid>

          <Grid item xs={12}>
            <TextField
              disabled
              value={location?.state?.toCenter}
              InputLabelProps={{ shrink: true }}
              fullWidth
              id="filled-password-input"
              label="To Center"
              type="text"
              required
              name="toCenter"
              sx={{ mt: 3, background: "white" }}
            />
          </Grid>

          <Grid item xs={12}>
            <TextField
              disabled
              value={location?.state?.contactNumber}
              InputLabelProps={{ shrink: true }}
              fullWidth
              id="filled-password-input"
              label="Contact Number"
              type="number"
              required
              name="contactNumber"
              sx={{ mt: 3, background: "white" }}
            />
          </Grid>

          <Grid item xs={12}>
            <TextField
              disabled
              InputLabelProps={{ shrink: true }}
              value={location?.state?.presentAddress}
              fullWidth
              id="filled-multiline-static"
              label="Address"
              name="presentAddress"
              required
              multiline
              rows={4}
              sx={{ mt: 3, mb: 3, background: "white" }}
            />
          </Grid>

          <Grid item xs={12}>
            <FormControl fullWidth>
              <InputLabel id="demo-simple-select-label">Status</InputLabel>
              <Select
                labelId="demo-simple-select-label"
                name="status"
                id="demo-simple-select"
                label="Status"
                value={sendCourier?.status}
                type="text"
                required
                onChange={(e) => ValidationHandler(e)}
              >
                <MenuItem value={"Complete"}>Complete</MenuItem>
                <MenuItem value={"Return"}>Return</MenuItem>
                <MenuItem value={"Pending"}>Pending</MenuItem>
                <MenuItem value={"Process"}>Process</MenuItem>
              </Select>
            </FormControl>
          </Grid>

          <Grid item xs={12}>
            <TextField
              autoComplete="given-name"
              name="remark"
              required
              fullWidth
              multiline
              minRows={2}
              id="Remark"
              label="Remark"
              placeholder="comment"
              sx={{ mt: 3, background: "white" }}
              value={sendCourier.remark}
              error={Boolean(errors.remark)}
              helperText={errors.remark}
              onChange={(e: any) => {
                ValidationHandler(e, "message");
              }}
            />
          </Grid>

          <Grid item xs={12}>
            <Button type="submit" variant="contained" sx={{ mt: 3, mb: 2 }}>
              Submit
            </Button>
          </Grid>
        </Box>
        <CommonSnackBar message={snackMessage} open={openSnackBar} />
      </Paper>
    </Container>
  );
}
