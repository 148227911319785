import * as React from "react";
import Button from "@mui/material/Button";
import TextField from "@mui/material/TextField";
import Grid from "@mui/material/Grid";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import Container from "@mui/material/Container";
import Dropdown from "../../Components/Common/Dropdown";
import { useEffect, useState } from "react";
import useValidation from "../../Hooks/useTypescriptValidation";
import CommonSnackBar from "../../Components/Common/CommonSnackbar";
import moment from "moment";
import {
  FormControl,
  IconButton,
  InputAdornment,
  InputLabel,
  MenuItem,
  Paper,
  Select,
} from "@mui/material";
import { useLocation } from "react-router";
import { useNavigate } from "react-router";
import { LinearProgress } from "@mui/material";
import { Visibility, VisibilityOff } from "@mui/icons-material";
import { getRequest, postRequestMethod, updateRequest } from "../../api/api";
import {
  GET_EMPLOYEE_STATIC_DATA,
  GET_CH_TL_AGG,
  POST_EXECUTIVE,
  UPDATE_EXECUTIVE,
} from "../../api/server";

export default function AddExecutive() {
  //custom hook for validation
  let { eventHandler } = useValidation();

  //to get data for edit form
  const location = useLocation();
  const navigate = useNavigate();

  //get current date
  let currentDate = moment().format("DD-MM-YYYY");

  //to identify form type
  const isEditRoute = location.pathname === "/dashboard/EditExecutive";

  //dropdown data
  const [staticData, setStaticData] = useState<any[]>([]);
  const [centerHead, setCenterHead] = useState<any[]>([]);
  const [snackMessage, setSnackMessage] = useState<string>("");

  //state to maintain teamlead list dynamically
  const [teamLeadList, setTeamLeadList] = useState<any[]>([]);

  //for displaying snackbar message
  const [openSnackBar, setOpenSnackBar] = useState<boolean>(false);

  //for loading or loader
  const [isLoading, setIsLoading] = useState<boolean>(false);

  //for password show
  const [showPassword, setShowPassword] = useState<boolean>(false);

  //for fetching dropdown options from backend
  const getData: any = () => {
    try {
      //get static data
      getRequest(GET_EMPLOYEE_STATIC_DATA, "")
        .then((res: any) => setStaticData(res.data))
        .catch((error) => console.log(error));

      //get dropdown list for centerhead and teamlead
      getRequest(GET_CH_TL_AGG, "")
        .then((res: any) => setCenterHead(res.data))
        .catch((error) => console.log(error));
    } catch (error) {
      console.error("Error on getting data", error);
    }
  };

  //for applying validation from common component
  const [errors, setErrors] = useState<any>({
    firstName: "",
    lastName: "",
    email: "",
    password: "",
    phone: "",
    DOB: "",
    dateOfInterview: "",
    dateOfJoining: "",
    dateOfResignation: "",
    trainingDateFrom: "",
    trainingDateTo: "",
    mobileCharge: "",
    pTax: "",
    basicSalary: "",
    hra: "",
    conveyance: "",
    allowances: "",
    offeredSalary: "",
    sharePercent: "",
    unit: "",
    jobLocation: "",
  });

  //form data
  const [executiveList, setExecutiveList] = useState<any>({
    centerHead: "",
    teamLead: "",
    firstName: "",
    lastName: "",
    email: "",
    password: "",
    phone: "",
    DOB: "",
    dateOfInterview: "",
    dateOfJoining: "",
    dateOfResignation: "",
    trainingDateFrom: "",
    trainingDateTo: "",
    mobileCharge: "",
    pTax: "",
    basicSalary: "",
    hra: "",
    conveyance: "",
    allowances: "",
    offeredSalary: "",
    sharePercent: "",
    jobLocation: "",
    post: "",
    department: "",
    unit: "",
    status: "",
  });

  //function to set details in state
  const setDetails = (e: any) => {
    setExecutiveList({ ...executiveList, [e.target.name]: e.target.value });
    if (e.target.name === "centerHead") {
      let arr: any[] = [];
      centerHead?.map((item: any) => {
        if (item.chUniqueId === e.target.value) {
          arr = item.teamleads.filter(() => true);
        }
      });
      if (arr.length) {
        setTeamLeadList(arr);
      } else {
        setTeamLeadList([]);
      }
    }
  };

  // function to handle validations
  const ValidationHandler = async (e: any, alterName?: any) => {
    const val = e.target.value;
    const id = alterName;
    if (id) {
      let prom = new Promise((resolve) => {
        if (true) {
          resolve(eventHandler(id, val));
        }
      });
      prom.then((res) => setErrors({ ...errors, [e.target.name]: res }));
    }
  };

  // function to handle form submit
  const handleSubmit = async (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault();
    if (validator()) {
      if (location.state === null) {
        setIsLoading(true);
        try {
          postRequestMethod(POST_EXECUTIVE, "", executiveList).then(
            (res: any) => {
              setIsLoading(false);
              if (res.data?.both) {
                setSnackMessage("Email and Phone Number Already Present..!");
                handleOpenSnackBar();
              } else if (res.data?.email) {
                setSnackMessage("Email Already Present..!");
                handleOpenSnackBar();
              } else if (res.data?.phone) {
                setSnackMessage("Phone Number Already Present..!");
                handleOpenSnackBar();
              } else {
                setSnackMessage("Executive Added Successfully...!");
                handleOpenSnackBar();
                setTimeout(() => {
                  navigate("/dashboard/ManageExecutive");
                }, 4000);
              }
            }
          );
        } catch (error) {
          console.error("Error on adding execuive data", error);
        }
      } else {
        let id = location.state._id;
        try {
          updateRequest(UPDATE_EXECUTIVE, id, undefined, "").then(
            (res: any) => {
              if (res.data.message === "Successfully updated!") {
                setSnackMessage("Successfully updated!");
                handleOpenSnackBar();
                setTimeout(() => {
                  navigate("/dashboard/ManageExecutive");
                }, 4000);
              } else {
                setSnackMessage("Failed to update!");
                handleOpenSnackBar();
              }
            }
          );
        } catch (error) {
          console.error("Error on updating executive", error);
        }
      }
    }
  };

  //function to handle snackbar displaying
  const handleOpenSnackBar = () => {
    setOpenSnackBar(true);
    setTimeout(() => {
      setOpenSnackBar(false);
    }, 3000);
  };

  // to check fields are filled or not
  const validator = () => {
    for (let field in errors) {
      if (errors[field] !== "") {
        return false;
      }
    }
    return true;
  };

  // function to calculate offered salary
  const offerdSalaryCalculator = (event: React.FormEvent) => {
    event.preventDefault();
    const { hra, conveyance, basicSalary, allowances } = executiveList;
    const totalSalary =
      Number(hra) +
      Number(conveyance) +
      Number(basicSalary) +
      Number(allowances);
    setExecutiveList({ ...executiveList, offeredSalary: totalSalary });
  };

  //for password show
  const toggleShowPassword = () => {
    setShowPassword(!showPassword);
  };

  useEffect(() => {
    if (!isEditRoute) {
      // Reset all fields to their initial values
      setExecutiveList({
        centerHead: "",
        teamLead: "",
        firstName: "",
        lastName: "",
        email: "",
        password: "",
        phone: "",
        DOB: "",
        dateOfInterview: "",
        dateOfJoining: "",
        dateOfResignation: "",
        trainingDateFrom: "",
        trainingDateTo: "",
        mobileCharge: "",
        pTax: "",
        basicSalary: "",
        hra: "",
        conveyance: "",
        allowances: "",
        offeredSalary: "",
        sharePercent: "",
        jobLocation: "",
        post: "",
        department: "",
        unit: "",
        status: "",
      });
    } else {
      // Set fields based on the location state or any other data source
      setExecutiveList({
        centerHead: location.state.centerHead,
        teamLead: location.state.teamLead,
        firstName: location.state.firstName,
        lastName: location.state.lastName,
        email: location.state.email,
        password: location.state.password,
        phone: location.state.phone,
        DOB: location.state.DOB,
        dateOfInterview: location.state.dateOfInterview,
        dateOfJoining: location.state.dateOfJoining,
        dateOfResignation: location.state.dateOfResignation,
        trainingDateFrom: location.state.trainingDateFrom,
        trainingDateTo: location.state.trainingDateTo,
        mobileCharge: location.state.mobileCharge,
        pTax: location.state.pTax,
        basicSalary: location.state.basicSalary,
        hra: location.state.hra,
        conveyance: location.state.conveyance,
        allowances: location.state.allowances,
        offeredSalary: location.state.offeredSalary,
        sharePercent: location.state.sharePercent,
        jobLocation: location.state.jobLocation,
        post: location.state.post,
        department: location.state.department,
        unit: location.state.unit,
        status: location.state.status,
      });
    }
  }, [isEditRoute, location]);

  useEffect(() => {
    if (
      executiveList.trainingDateFrom &&
      executiveList.trainingDateTo &&
      executiveList.trainingDateFrom !== "" &&
      executiveList.trainingDateTo !== ""
    ) {
      const trainingFromDate = new Date(executiveList.trainingDateFrom);
      const trainingToDate = new Date(executiveList.trainingDateTo);

      if (trainingFromDate >= trainingToDate) {
        setErrors((prevData: { [key: string]: string }) => ({
          ...prevData,
          trainingDateFrom:
            "training From date must be less than training To date",
          trainingDateTo:
            "training To date must be greater than training From date",
        }));
      } else {
        setErrors((prevData: { [key: string]: string }) => ({
          ...prevData,
          trainingDateFrom: "",
          trainingDateTo: "",
        }));
      }
    }
  }, [executiveList.trainingDateFrom, executiveList.trainingDateTo]);

  //calling getData while loading the form
  useEffect(() => {
    getData();
  }, []);

  //for edit form data fetching in teamlead dropdown
  useEffect(() => {
    let arr: any[] = [];
    centerHead?.map((item: any) => {
      if (item.chUniqueId === location?.state?.centerHead) {
        arr = item.teamleads.filter(() => true);
      }
    });
    if (arr.length) {
      setTeamLeadList(arr);
    }
  }, [centerHead, location]);

  return (
    <Container component="main" maxWidth="lg">
      {isLoading && <LinearProgress />}
      <Paper elevation={20} sx={{ p: 3 }}>
        <Typography
          variant="h6"
          align="center"
          sx={{ alignItems: "center", color: "#0288d1" }}
        >
          {isEditRoute ? "EDIT EXECUTIVE" : "ADD EXECUTIVE"}
        </Typography>
        <Box component="form" onSubmit={handleSubmit}>
          <Grid container spacing={2}>
            <Grid item xs={12} sm={12}>
              <FormControl fullWidth sx={{ mt: 2 }}>
                <InputLabel id="demo-simple-country-label">
                  Center Head
                </InputLabel>
                <Select
                  labelId="demo-simple-country-label"
                  name="centerHead"
                  required
                  className="form-control"
                  label="Center Head"
                  value={executiveList.centerHead}
                  defaultValue=""
                  onChange={setDetails}
                  fullWidth
                  size="medium"
                >
                  {centerHead?.map((item: any) => (
                    <MenuItem value={item.chUniqueId} key={item._id}>
                      {`${item.firstName} ${item.lastName} (${item.empId})`}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
            </Grid>
            <Grid item xs={12} sm={12}>
              <FormControl fullWidth>
                <InputLabel id="demo-simple-country-label">
                  Team Lead
                </InputLabel>
                <Select
                  labelId="demo-simple-country-label"
                  name="teamLead"
                  required
                  className="form-control"
                  label="Team Lead"
                  value={executiveList.teamLead}
                  defaultValue=""
                  onChange={setDetails}
                  fullWidth
                  size="medium"
                >
                  {teamLeadList?.map((item: any) => (
                    <MenuItem value={item.tlUniqueId} key={item._id}>
                      {`${item.firstName} ${item.lastName} (${item.empId})`}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
            </Grid>
            <Grid item xs={12} sm={6}>
              <TextField
                sx={{ background: "white" }}
                autoComplete="given-name"
                name="firstName"
                required
                fullWidth
                id="firstName"
                value={executiveList.firstName}
                label="First Name"
                error={Boolean(errors.firstName)}
                helperText={errors.firstName}
                onChange={setDetails}
                onBlur={(e: any) => ValidationHandler(e, "alphabet")}
              />
            </Grid>
            <Grid item xs={12} sm={6}>
              <TextField
                sx={{ background: "white" }}
                required
                fullWidth
                id="lastName"
                value={executiveList.lastName}
                label="Last Name"
                name="lastName"
                autoComplete="family-name"
                error={Boolean(errors.lastName)}
                helperText={errors.lastName}
                onChange={setDetails}
                onBlur={(e: any) => ValidationHandler(e, "alphabet")}
              />
            </Grid>

            <Grid item xs={12} sm={6}>
              <TextField
                sx={{ background: "white" }}
                required
                fullWidth
                id="email"
                value={executiveList.email}
                label="Email ID"
                disabled={location?.state ? true : false}
                name="email"
                autoComplete="email"
                error={Boolean(errors.email)}
                helperText={errors.email}
                onChange={setDetails}
                onBlur={(e: any) => ValidationHandler(e, "email")}
              />
            </Grid>

            <Grid item xs={12} sm={6}>
              <TextField
                sx={{ background: "white" }}
                type={showPassword ? "text" : "password"}
                value={executiveList.password}
                name="password"
                fullWidth
                required
                error={Boolean(errors.password)}
                helperText={errors.password}
                onChange={setDetails}
                onBlur={(e: any) => ValidationHandler(e, "password")}
                label="Password"
                InputProps={{
                  endAdornment: (
                    <InputAdornment position="end">
                      <IconButton onClick={toggleShowPassword} edge="end">
                        {showPassword ? <VisibilityOff /> : <Visibility />}
                      </IconButton>
                    </InputAdornment>
                  ),
                }}
              />
            </Grid>

            <Grid item xs={12} sm={6}>
              <TextField
                sx={{ background: "white" }}
                required
                fullWidth
                id="phone"
                value={executiveList.phone}
                label="Phone No"
                name="phone"
                disabled={location?.state ? true : false}
                autoComplete="phone"
                error={Boolean(errors.phone)}
                helperText={errors.phone}
                onChange={setDetails}
                onBlur={(e: any) => ValidationHandler(e, "mobile")}
              />
            </Grid>

            <Grid item xs={12} sm={6}>
              <TextField
                sx={{ background: "white" }}
                InputLabelProps={{ shrink: true }}
                required
                fullWidth
                id="DOB"
                value={executiveList.DOB}
                label="Date of Birth"
                name="DOB"
                type="date"
                InputProps={{
                  inputProps: {
                    max: new Date().toISOString().split("T")[0],
                  },
                }}
                onChange={setDetails}
              />
            </Grid>

            <Grid item xs={12} sm={6}>
              <TextField
                sx={{ background: "white" }}
                InputLabelProps={{ shrink: true }}
                required
                fullWidth
                id="dateOfInterview"
                value={executiveList.dateOfInterview}
                label="Date Of Interview"
                name="dateOfInterview"
                type="date"
                inputProps={{ max: currentDate }}
                onChange={setDetails}
              />
            </Grid>

            <Grid item xs={12} sm={6}>
              <TextField
                sx={{ background: "white" }}
                InputLabelProps={{ shrink: true }}
                required
                fullWidth
                id="dateOfJoining"
                value={executiveList.dateOfJoining}
                label="Date Of Joining"
                name="dateOfJoining"
                type="date"
                inputProps={{ max: currentDate }}
                onChange={setDetails}
              />
            </Grid>

            <Grid item xs={12} sm={6}>
              <TextField
                sx={{ background: "white" }}
                InputLabelProps={{ shrink: true }}
                fullWidth
                id="dateOfResignation"
                disabled={!isEditRoute}
                value={executiveList.dateOfResignation}
                label="Date Of Resignation"
                name="dateOfResignation"
                type="date"
                inputProps={{ max: currentDate }}
                onChange={setDetails}
              />
            </Grid>

            <Grid item xs={12} sm={6}>
              <TextField
                sx={{ background: "white" }}
                InputLabelProps={{ shrink: true }}
                required
                fullWidth
                id="trainingDateFrom"
                value={executiveList.trainingDateFrom}
                label="Date Of Training From"
                name="trainingDateFrom"
                type="date"
                error={Boolean(errors.trainingDateFrom)}
                helperText={errors.trainingDateFrom}
                inputProps={{ max: currentDate }}
                onChange={setDetails}
              />
            </Grid>

            <Grid item xs={12} sm={6}>
              <TextField
                sx={{ background: "white" }}
                InputLabelProps={{ shrink: true }}
                required
                fullWidth
                id="trainingDateTo"
                value={executiveList.trainingDateTo}
                label="Date Of Training To"
                name="trainingDateTo"
                type="date"
                error={Boolean(errors.trainingDateFrom)}
                helperText={errors.trainingDateFrom}
                inputProps={{ max: currentDate }}
                onChange={setDetails}
              />
            </Grid>

            <Grid item xs={12} sm={6}>
              <Dropdown
                label={"Post"}
                handleChange={setDetails}
                data={staticData[0]?.post}
                name={"post"}
                currentState={executiveList.post}
              />
            </Grid>

            <Grid item xs={12} sm={6}>
              <Dropdown
                label={"Department"}
                handleChange={setDetails}
                data={staticData[0]?.department}
                name={"department"}
                currentState={executiveList.department}
              />
            </Grid>

            <Grid item xs={12} sm={6}>
              <Dropdown
                label={"unit"}
                handleChange={setDetails}
                data={staticData[0]?.unit}
                name={"unit"}
                currentState={executiveList.unit}
              />
            </Grid>

            {/* Deduction part */}
            <Grid item xs={12} sm={12}>
              <Typography component="h1" variant="h5">
                Deduction
              </Typography>
            </Grid>

            <Grid item xs={12} sm={6}>
              <TextField
                sx={{ background: "white" }}
                required
                fullWidth
                type="number"
                inputProps={{ min: 0 }}
                id="mobileCharge"
                value={executiveList.mobileCharge}
                label="Mobile Charge"
                name="mobileCharge"
                autoComplete="charge"
                error={Boolean(errors.mobileCharge)}
                helperText={errors.mobileCharge}
                onChange={setDetails}
              />
            </Grid>

            <Grid item xs={12} sm={6}>
              <TextField
                sx={{ background: "white" }}
                required
                fullWidth
                type="number"
                inputProps={{ min: 0 }}
                id="pTax"
                value={executiveList.pTax}
                label="Professional Tax"
                name="pTax"
                autoComplete="tax"
                error={Boolean(errors.pTax)}
                helperText={errors.pTax}
                onChange={setDetails}
              />
            </Grid>
            {/* Earnings part */}

            <Grid item xs={12} sm={12}>
              <Typography component="h1" variant="h5">
                Earnings
              </Typography>
            </Grid>

            <Grid item xs={12} sm={4}>
              <TextField
                sx={{ background: "white" }}
                required
                fullWidth
                type="number"
                inputProps={{ min: 0 }}
                id="basicSalary"
                value={executiveList.basicSalary}
                label="Basic Salary"
                name="basicSalary"
                autoComplete="salary"
                error={Boolean(errors.basicSalary)}
                helperText={errors.basicSalary}
                onChange={setDetails}
                onBlur={offerdSalaryCalculator}
              />
            </Grid>

            <Grid item xs={12} sm={4}>
              <TextField
                sx={{ background: "white" }}
                required
                fullWidth
                type="number"
                inputProps={{ min: 0 }}
                id="hra"
                value={executiveList.hra}
                label="HRA"
                name="hra"
                autoComplete="har"
                error={Boolean(errors.hra)}
                helperText={errors.hra}
                onChange={setDetails}
                onBlur={offerdSalaryCalculator}
              />
            </Grid>

            <Grid item xs={12} sm={4}>
              <TextField
                sx={{ background: "white" }}
                required
                fullWidth
                type="number"
                inputProps={{ min: 0 }}
                id="conveyance"
                value={executiveList.conveyance}
                label="Conveyance"
                name="conveyance"
                autoComplete="conveyance"
                error={Boolean(errors.conveyance)}
                helperText={errors.conveyance}
                onChange={setDetails}
                onBlur={offerdSalaryCalculator}
              />
            </Grid>

            <Grid item xs={12} sm={4}>
              <TextField
                sx={{ background: "white" }}
                required
                fullWidth
                type="number"
                inputProps={{ min: 0 }}
                id="allowances"
                value={executiveList.allowances}
                label="Allowances"
                name="allowances"
                autoComplete="allowences"
                error={Boolean(errors.allowances)}
                helperText={errors.allowances}
                onChange={setDetails}
                onBlur={offerdSalaryCalculator}
              />
            </Grid>

            <Grid item xs={12} sm={4}>
              <TextField
                sx={{ background: "white" }}
                required
                fullWidth
                disabled
                id="offeredSalary"
                value={executiveList.offeredSalary}
                label="Offered Salary"
                name="offeredSalary"
                autoComplete="offeredsal"
              />
            </Grid>

            <Grid item xs={12} sm={4}>
              <TextField
                sx={{ background: "white" }}
                required
                fullWidth
                id="jobLocation"
                value={executiveList.jobLocation}
                label="Job Location"
                name="jobLocation"
                autoComplete="location"
                error={Boolean(errors.jobLocation)}
                helperText={errors.jobLocation}
                onChange={setDetails}
                onBlur={(e: any) => ValidationHandler(e, "alphabetsAndSpace")}
              />
            </Grid>

            <Grid item xs={12} sm={4}>
              <TextField
                sx={{ background: "white" }}
                required
                fullWidth
                type="number"
                inputProps={{ min: 0 }}
                id="sharePercent"
                value={executiveList.sharePercent}
                label="Share Percentage"
                name="sharePercent"
                autoComplete="percentage"
                error={Boolean(errors.sharePercent)}
                helperText={errors.sharePercent}
                onChange={setDetails}
              />
            </Grid>

            <Grid item xs={12} sm={4}>
              <Dropdown
                label={"Status"}
                handleChange={setDetails}
                data={staticData[0]?.status}
                name={"status"}
                currentState={executiveList.status}
              />
            </Grid>
          </Grid>
          <Button
            variant="contained"
            sx={{ mt: 3, mb: 2, px: 4 }}
            type="submit"
            onClick={() => {
              window.scrollTo(0, 0);
            }}
          >
            Submit
          </Button>
          <CommonSnackBar message={snackMessage} open={openSnackBar} />
        </Box>
      </Paper>
    </Container>
  );
}
