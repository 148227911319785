import * as React from "react";
import Button from "@mui/material/Button";
import TextField from "@mui/material/TextField";
import Grid from "@mui/material/Grid";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import Container from "@mui/material/Container";
import Dropdown from "../../Components/Common/Dropdown";
import { useEffect, useState } from "react";
import useValidation from "../../Hooks/useTypescriptValidation";
import moment from "moment";
import Paper from "@mui/material/Paper";
import CommonSnackBar from "../../Components/Common/CommonSnackbar";
import { useLocation } from "react-router";
import { useNavigate } from "react-router";
import { IconButton, InputAdornment, LinearProgress } from "@mui/material";
import { Visibility, VisibilityOff } from "@mui/icons-material";
import { getRequest, postRequestMethod, updateRequest } from "../../api/api";
import {
  GET_EMPLOYEE_STATIC_DATA,
  POST_CENTER_HEAD,
  UPDATE_CENTER_HEAD,
} from "../../api/server";

export default function AddCenterHead() {
  let { eventHandler } = useValidation();
  const navigate = useNavigate();
  const location = useLocation();

  //for identifying form type
  const isEditRoute = location.pathname === "/dashboard/EditCenterHead";

  //get current date
  let currentDate = moment().format("DD-MM-YYYY");

  //for progress bar
  const [isLoading, setIsLoading] = useState(false);

  //state for drop down fetched data
  const [staticData, setStaticData] = useState<any>([]);
  
  //for displaying snackbar message
  const [snackMessage, setSnackMessage] = useState<string>("");
  const [openSnackBar, setOpenSnackBar] = useState(false);

  //for password show
  const [showPassword, setShowPassword] = useState(false);

  //for setting errors
  const [errors, setErrors] = useState<any>({
    firstName: "",
    lastName: "",
    email: "",
    password: "",
    DOB: "",
    dateOfJoining: "",
    dateOfResignation: "",
    phone: "",
    mobileCharge: "",
    pTax: "",
    basicSalary: "",
    hra: "",
    conveyance: "",
    allowances: "",
    offeredSalary: "",
    sharePercent: "",
    jobLocation: "",
  });

  //for updating fields details
  const [agentDetails, setAgentDetails] = useState<any>({
    firstName: "",
    lastName: "",
    email: "",
    password: "",
    phone: "",
    DOB: "",
    dateOfJoining: "",
    dateOfResignation: "",
    mobileCharge: "",
    pTax: "",
    basicSalary: "",
    hra: "",
    conveyance: "",
    allowances: "",
    offeredSalary: "",
    sharePercent: "",
    jobLocation: "",
    post: "",
    department: "",
    unit: "",
    status: "",
  });

  //for fetching dropdown options from backend
  const getStaticData = async () => {
    try {
      const res = await getRequest(GET_EMPLOYEE_STATIC_DATA, "");
      if (res) {
        setStaticData(res.data);
      }
    } catch (error) {
      console.error("Error  on get static data", error);
    }
  };

  //function to set details in state
  const setDetails = async (e: any) => {
    setAgentDetails({ ...agentDetails, [e.target.name]: e.target.value });
  };

  // function to handle validations on field
  const ValidationHandler = async (e: any, alterName?: any) => {
    const val = e.target.value;
    const id = alterName;
    if (id) {
      let prom = new Promise((resolve) => {
        if (true) {
          resolve(eventHandler(id, val));
        }
      });
      prom.then((res) =>
        setErrors((errors: any) => ({ ...errors, [e.target.name]: res }))
      );
    }
  };

  //form submitting and posting handler
  const handleSubmit = (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault();
    if (validator()) {
      if (location.state === null) {
        setIsLoading(true);
        try {
          postRequestMethod(POST_CENTER_HEAD, "", agentDetails).then(
            (res: any) => {
              setIsLoading(false);
              if (res.data?.both) {
                setSnackMessage("Email and Phone Number Already Present..!");
                handleOpenSnackBar();
              } else if (res.data?.email) {
                setSnackMessage("Email Already Present..!");
                handleOpenSnackBar();
              } else if (res.data?.phone) {
                setSnackMessage("Phone Number Already Present..!");
                handleOpenSnackBar();
              } else {
                setSnackMessage("Center Head Added Successfully..!");
                handleOpenSnackBar();
                setTimeout(() => {
                  navigate("/dashboard/ManageCenterHead");
                }, 4000);
              }
            }
          );
        } catch (error) {
          console.error("Error on submitting center head data", error);
        }
      } else {
        let id = location.state._id;
        try {
          updateRequest(UPDATE_CENTER_HEAD, id, agentDetails, "").then(
            (res: any) => {
              if (res.data.message === "Successfully updated!") {
                setSnackMessage("Successfully updated!");
                handleOpenSnackBar();
                setTimeout(() => {
                  navigate("/dashboard/ManageCenterHead");
                }, 4000);
              } else {
                setSnackMessage("Failed to update!");
                handleOpenSnackBar();
              }
            }
          );
        } catch (error) {
          console.error("Error on updating centerhead", error);
        }
      }
    }
  };

  // snackbar display handler
  const handleOpenSnackBar = () => {
    setOpenSnackBar(true);
    setTimeout(() => {
      setOpenSnackBar(false);
    }, 3000);
  };

  // to check fields are filled or not
  const validator = () => {
    for (let field in errors) {
      if (errors[field] !== "") {
        return false;
      }
    }
    return true;
  };

  // handler to calculate offered salary field
  const offeredSalaryCalculator = (event: React.FormEvent) => {
    event.preventDefault();
    const { hra, conveyance, basicSalary, allowances } = agentDetails;
    const totalSalary =
      Number(hra) +
      Number(conveyance) +
      Number(basicSalary) +
      Number(allowances);
    setAgentDetails({ ...agentDetails, offeredSalary: totalSalary });
  };

  //handler for password show
  const toggleShowPassword = () => {
    setShowPassword(!showPassword);
  };

  useEffect(() => {
    if (!isEditRoute) {
      // Reset all fields to their initial values
      setAgentDetails({
        firstName: "",
        lastName: "",
        email: "",
        password: "",
        phone: "",
        DOB: "",
        dateOfJoining: "",
        dateOfResignation: "",
        mobileCharge: "",
        pTax: "",
        basicSalary: "",
        hra: "",
        conveyance: "",
        allowances: "",
        offeredSalary: "",
        sharePercent: "",
        jobLocation: "",
        post: "",
        department: "",
        unit: "",
        status: "",
      });
    } else {
      // Set fields based on the location state or any other data source
      setAgentDetails({
        firstName: location.state.firstName,
        lastName: location.state.lastName,
        email: location.state.email,
        password: location.state.password,
        phone: location.state.phone,
        DOB: location.state.DOB,
        dateOfInterview: location.state.dateOfInterview,
        dateOfJoining: location.state.dateOfJoining,
        mobileCharge: location.state.mobileCharge,
        pTax: location.state.pTax,
        basicSalary: location.state.basicSalary,
        hra: location.state.hra,
        conveyance: location.state.conveyance,
        allowances: location.state.allowances,
        offeredSalary: location.state.offeredSalary,
        sharePercent: location.state.sharePercent,
        jobLocation: location.state.jobLocation,
        post: location.state.post,
        department: location.state.department,
        unit: location.state.unit,
        status: location.state.status,
      });
      // Set other fields as needed
    }
  }, [isEditRoute, location]);

  //to get static data while loading the form
  useEffect(() => {
    getStaticData();
  }, []);

  return (
    <Container component="main" maxWidth="lg">
      {isLoading && <LinearProgress />}
      <Paper elevation={20} sx={{ p: 3 }}>
        <Typography
          variant="h6"
          align="center"
          sx={{ alignItems: "center", color: "#0288d1" }}
        >
          {isEditRoute ? "EDIT CENTER HEAD" : "ADD CENTER HEAD"}
        </Typography>

        <Box component="form" onSubmit={handleSubmit} sx={{ mt: 2 }}>
          <Grid container spacing={2}>
            <Grid item xs={12} sm={6}>
              <TextField
                sx={{ background: "white" }}
                required
                fullWidth
                id="firstName"
                label="First Name"
                name="firstName"
                value={agentDetails.firstName}
                error={Boolean(errors.firstName)}
                helperText={errors.firstName}
                onChange={setDetails}
                onBlur={(e: any) => ValidationHandler(e, "alphabet")}
              />
            </Grid>

            <Grid item xs={12} sm={6}>
              <TextField
                sx={{ background: "white" }}
                required
                fullWidth
                id="lastName"
                value={agentDetails.lastName}
                label="Last Name"
                name="lastName"
                error={Boolean(errors.lastName)}
                helperText={errors.lastName}
                onChange={setDetails}
                onBlur={(e: any) => ValidationHandler(e, "alphabet")}
              />
            </Grid>

            <Grid item xs={12} sm={6}>
              <TextField
                sx={{ background: "white" }}
                required
                fullWidth
                id="email"
                disabled={location?.state ? true : false}
                value={agentDetails.email}
                label="Email ID"
                name="email"
                autoComplete="family-name"
                error={Boolean(errors.email)}
                helperText={errors.email}
                onChange={setDetails}
                onBlur={(e: any) => ValidationHandler(e, "email")}
              />
            </Grid>

            <Grid item xs={12} sm={6}>
              <TextField
                sx={{ background: "white" }}
                type={showPassword ? "text" : "password"}
                value={agentDetails.password}
                name="password"
                fullWidth
                required
                error={Boolean(errors.password)}
                helperText={errors.password}
                onChange={setDetails}
                onBlur={(e: any) => ValidationHandler(e, "password")}
                label="Password"
                InputProps={{
                  endAdornment: (
                    <InputAdornment position="end">
                      <IconButton onClick={toggleShowPassword} edge="end">
                        {showPassword ? <VisibilityOff /> : <Visibility />}
                      </IconButton>
                    </InputAdornment>
                  ),
                }}
              />
            </Grid>

            <Grid item xs={12} sm={6}>
              <TextField
                sx={{ background: "white" }}
                required
                fullWidth
                id="phone"
                value={agentDetails.phone}
                disabled={location?.state ? true : false}
                label="Phone No"
                name="phone"
                autoComplete="phone"
                error={Boolean(errors.phone)}
                helperText={errors.phone}
                onChange={setDetails}
                onBlur={(e: any) => ValidationHandler(e, "mobile")}
              />
            </Grid>

            <Grid item xs={12} sm={6}>
              <TextField
                sx={{ background: "white" }}
                InputLabelProps={{ shrink: true }}
                required
                fullWidth
                id="DOB"
                value={agentDetails.DOB}
                label="Date of Birth"
                name="DOB"
                type="date"
                InputProps={{
                  inputProps: {
                    max: new Date().toISOString().split("T")[0],
                  },
                }}
                onChange={setDetails}
              />
            </Grid>
            <Grid item xs={12} sm={6}>
              <TextField
                sx={{ background: "white" }}
                InputLabelProps={{ shrink: true }}
                required
                fullWidth
                id="dateOfJoining"
                value={agentDetails.dateOfJoining}
                label="Date Of Joining"
                name="dateOfJoining"
                type="date"
                inputProps={{ max: currentDate }}
                onChange={setDetails}
              />
            </Grid>

            <Grid item xs={12} sm={6}>
              <TextField
                sx={{ background: "white" }}
                InputLabelProps={{ shrink: true }}
                fullWidth
                id="dateOfResignation"
                disabled={!isEditRoute}
                value={agentDetails.dateOfResignation}
                label="Date Of Resignation"
                name="dateOfResignation"
                type="date"
                inputProps={{ max: currentDate }}
                onChange={setDetails}
              />
            </Grid>

            <Grid item xs={12} sm={6}>
              <Dropdown
                label={"Post"}
                handleChange={setDetails}
                data={staticData[0]?.post}
                name={"post"}
                currentState={agentDetails.post}
              />
            </Grid>

            <Grid item xs={12} sm={6}>
              <Dropdown
                label={"Department"}
                handleChange={setDetails}
                data={staticData[0]?.department}
                name={"department"}
                currentState={agentDetails.department}
              />
            </Grid>

            <Grid item xs={12} sm={6}>
              <Dropdown
                label={"Unit"}
                handleChange={setDetails}
                data={staticData[0]?.unit}
                name={"unit"}
                currentState={agentDetails.unit}
              />
            </Grid>

            {/* Deduction part */}
            <Grid item xs={12} sm={12}>
              <Typography component="h1" variant="h5">
                Deduction
              </Typography>
            </Grid>

            <Grid item xs={12} sm={6}>
              <TextField
                sx={{ background: "white" }}
                required
                fullWidth
                type="number"
                inputProps={{ min: 0 }}
                id="mobileCharge"
                value={agentDetails.mobileCharge}
                label="Mobile Charge"
                name="mobileCharge"
                autoComplete="mobileCharge"
                error={Boolean(errors.mobileCharge)}
                helperText={errors.mobileCharge}
                onChange={setDetails}
              />
            </Grid>

            <Grid item xs={12} sm={6}>
              <TextField
                sx={{ background: "white" }}
                required
                fullWidth
                type="number"
                inputProps={{ min: 0 }}
                id="tax"
                value={agentDetails.pTax}
                label="Professional Tax"
                name="pTax"
                autoComplete="tax"
                error={Boolean(errors.pTax)}
                helperText={errors.pTax}
                onChange={setDetails}
              />
            </Grid>

            {/* Earnings part */}
            <Grid item xs={12} sm={12}>
              <Typography component="h1" variant="h5">
                Earnings
              </Typography>
            </Grid>

            <Grid item xs={12} sm={4}>
              <TextField
                sx={{ background: "white" }}
                required
                fullWidth
                type="number"
                inputProps={{ min: 0 }}
                id="basicSalary"
                value={agentDetails.basicSalary}
                label="Basic Salary"
                name="basicSalary"
                autoComplete="basicSalary"
                error={Boolean(errors.basicSalary)}
                helperText={errors.basicSalary}
                onChange={setDetails}
                onBlur={offeredSalaryCalculator}
              />
            </Grid>

            <Grid item xs={12} sm={4}>
              <TextField
                sx={{ background: "white" }}
                required
                fullWidth
                id="hra"
                type="number"
                inputProps={{ min: 0 }}
                value={agentDetails.hra}
                label="HRA"
                name="hra"
                autoComplete="har"
                error={Boolean(errors.hra)}
                helperText={errors.hra}
                onChange={setDetails}
                onBlur={offeredSalaryCalculator}
              />
            </Grid>

            <Grid item xs={12} sm={4}>
              <TextField
                sx={{ background: "white" }}
                required
                fullWidth
                type="number"
                inputProps={{ min: 0 }}
                id="conveyance"
                value={agentDetails.conveyance}
                label="Conveyance"
                name="conveyance"
                autoComplete="conveyance"
                error={Boolean(errors.conveyance)}
                helperText={errors.conveyance}
                onChange={setDetails}
                onBlur={offeredSalaryCalculator}
              />
            </Grid>

            <Grid item xs={12} sm={4}>
              <TextField
                sx={{ background: "white" }}
                required
                fullWidth
                id="allowances"
                type="number"
                inputProps={{ min: 0 }}
                value={agentDetails.allowances}
                label="Allowances"
                name="allowances"
                autoComplete="allowances"
                error={Boolean(errors.allowances)}
                helperText={errors.allowances}
                onChange={setDetails}
                onBlur={offeredSalaryCalculator}
              />
            </Grid>

            <Grid item xs={12} sm={4}>
              <TextField
                sx={{ background: "white" }}
                required
                fullWidth
                disabled
                id="offeredSalary"
                value={agentDetails.offeredSalary}
                label="Offered Salary"
                name="offeredSalary"
                autoComplete="offeredSalary"
              />
            </Grid>

            <Grid item xs={12} sm={4}>
              <TextField
                sx={{ background: "white" }}
                required
                fullWidth
                id="jobLocation"
                value={agentDetails.jobLocation}
                label="Job Location"
                name="jobLocation"
                autoComplete="jobLocation"
                error={Boolean(errors.jobLocation)}
                helperText={errors.jobLocation}
                onChange={setDetails}
                onBlur={(e: any) => ValidationHandler(e, "alphabetsAndSpace")}
              />
            </Grid>

            <Grid item xs={12} sm={4}>
              <TextField
                required
                sx={{ background: "white" }}
                fullWidth
                type="number"
                inputProps={{ min: 0 }}
                id="sharePercent"
                value={agentDetails.sharePercent}
                label="Share Percentage"
                name="sharePercent"
                autoComplete="sharePercent"
                error={Boolean(errors.sharePercent)}
                helperText={errors.sharePercent}
                onChange={setDetails}
              />
            </Grid>

            <Grid item xs={12} sm={4}>
              <Dropdown
                label={"Status"}
                handleChange={setDetails}
                data={staticData[0]?.status}
                name={"status"}
                currentState={agentDetails.status}
              />
            </Grid>
          </Grid>
          <Button
            variant="contained"
            sx={{ mt: 3, mb: 2, px: 4 }}
            type="submit"
            onClick={() => {
              window.scrollTo(0, 0);
            }}
          >
            Submit
          </Button>
          <CommonSnackBar message={snackMessage} open={openSnackBar} />
        </Box>
      </Paper>
    </Container>
  );
}
