import {
  Box,
  Button,
  Container,
  Grid,
  Paper,
  TextField,
  Typography,
} from "@mui/material";
import { useEffect, useState } from "react";
import { getRequest, getRequestByParams } from "../../api/api";
import { MANAGE_FEE_STATIC } from "../../api/server";
import CommonSnackBar from "../../Components/Common/CommonSnackbar";

interface FilterData {
  fromDate: string;
  toDate: string;
}

export const ManageFeeStatic = function () {
  const [feeDetail, setFeeDetail] = useState<any>({});
  // for date
  const [data, setData] = useState<FilterData>({
    fromDate: "",
    toDate: "",
  });
  // for snack-bar msg
  const [snackMessage, setSnackMessage] = useState("");
  const [openSnackBar, setOpenSnackBar] = useState(false);

  // snack-bar function
  const handleOpenSnackBar = () => {
    setOpenSnackBar(true);
    setTimeout(() => {
      setOpenSnackBar(false);
    }, 3000);
  };

  // date filter function
  const handleFilterChange = (e: any) => {
    const { name, value } = e.target;
    setData((prevValues) => ({
      ...prevValues,
      [name]: value,
    }));
  };

  // fetch fee detail
  const viewFeeDetail = async () => {
    try {
      let response = await getRequest(MANAGE_FEE_STATIC, "");
      setFeeDetail(response?.data);
    } catch (error) {
      console.error("Error fetching data:", error);
    }
  };

  // fetch fee detail according to date filtration
  const fetchFilterData = async () => {
    try {
      if (data.fromDate === "" || data.toDate === "") {
        setSnackMessage("Select date");
        handleOpenSnackBar();
      }
      let result = await getRequestByParams(MANAGE_FEE_STATIC, "", "", data);
      if (Object.keys(result?.data).length === 0) {
        setFeeDetail({});
        setSnackMessage("no data found");
        handleOpenSnackBar();
      } else {
        setFeeDetail(result?.data);
        handleOpenSnackBar();
      }
    } catch (error: any) {
      console.error("Error fetching data:", error.message);
    }
  };

  useEffect(() => {
    viewFeeDetail();
  }, []);

  return (
    <Container>
      <Paper elevation={20} sx={{ p: 3 }}>
        <Typography
          variant="h6"
          align="center"
          sx={{ alignItems: "center", color: "#0288d1" }}
        >
          MANAGE FEE STATICS
        </Typography>

        <Grid
          container
          sx={{
            display: "flex",
            justifyContent: "start",
            gap: "10px",
            m: "20px",
          }}
        >
          <Grid
            item
            xs={8}
            display="flex"
            justifyContent="space-evenly"
            alignItems="center"
          >
            <TextField
              InputLabelProps={{ shrink: true }}
              label="From"
              id="outlined-date-input"
              size="small"
              autoComplete="current-date"
              type="date"
              name="fromDate"
              value={data.fromDate}
              onChange={handleFilterChange}
              sx={{ background: "white" }}
            />
            <TextField
              InputLabelProps={{ shrink: true }}
              label="To"
              id="outlined-date-input"
              type="date"
              size="small"
              autoComplete="current-date"
              name="toDate"
              value={data.toDate}
              onChange={handleFilterChange}
              sx={{ background: "white" }}
            />
          </Grid>
          <Grid item>
            <Button variant="contained" onClick={fetchFilterData}>
              Filter
            </Button>
            <CommonSnackBar message={snackMessage} open={openSnackBar} />
          </Grid>
        </Grid>

        <Box sx={{ width: "100%", mt: 1 }}>
          <Grid
            container
            rowSpacing={1}
            columnSpacing={{ xs: 1, sm: 1, md: 1 }}
          >
            <Grid item xs={3}>
              <TextField
                id="outlined-basic"
                variant="outlined"
                size="small"
                defaultValue="Total Student"
                InputProps={{
                  readOnly: true,
                }}
                fullWidth
              />
            </Grid>
            <Grid item xs={9}>
              <TextField
                id="outlined-basic"
                variant="outlined"
                size="small"
                value={feeDetail?.count || 0}
                InputProps={{
                  readOnly: true,
                }}
                fullWidth
              />
            </Grid>
            <Grid item xs={3}>
              <TextField
                id="filled-basic"
                variant="filled"
                size="small"
                defaultValue="Active Student"
                InputProps={{
                  readOnly: true,
                }}
                fullWidth
              />
            </Grid>
            <Grid item xs={9}>
              <TextField
                id="filled-basic"
                variant="filled"
                size="small"
                value={feeDetail?.activeStudent || 0}
                InputProps={{
                  readOnly: true,
                }}
                fullWidth
              />
            </Grid>
            <Grid item xs={3}>
              <TextField
                id="Outlined-basic"
                variant="outlined"
                size="small"
                defaultValue="Reject Student"
                InputProps={{
                  readOnly: true,
                }}
                fullWidth
              />
            </Grid>
            <Grid item xs={9}>
              <TextField
                id="Outlined-basic"
                variant="outlined"
                size="small"
                value={feeDetail?.rejectStudent || 0}
                InputProps={{
                  readOnly: true,
                }}
                fullWidth
              />
            </Grid>
            <Grid item xs={3}>
              <TextField
                id="filled-basic"
                variant="filled"
                size="small"
                defaultValue="Pending Student"
                InputProps={{
                  readOnly: true,
                }}
                fullWidth
              />
            </Grid>
            <Grid item xs={9}>
              <TextField
                id="filled-basic"
                variant="filled"
                size="small"
                value={feeDetail?.pendingStudent || 0}
                InputProps={{
                  readOnly: true,
                }}
                fullWidth
              />
            </Grid>
            <Grid item xs={3}>
              <TextField
                id="outlined-basic"
                variant="outlined"
                size="small"
                defaultValue="New Student"
                InputProps={{
                  readOnly: true,
                }}
                fullWidth
              />
            </Grid>
            <Grid item xs={9}>
              <TextField
                id="outlined-basic"
                variant="outlined"
                size="small"
                value={feeDetail?.newStudent || 0}
                InputProps={{
                  readOnly: true,
                }}
                fullWidth
              />
            </Grid>
            <Grid item xs={3}>
              <TextField
                id="filled-basic"
                variant="filled"
                size="small"
                defaultValue="Re-Registration Student"
                InputProps={{
                  readOnly: true,
                }}
                fullWidth
              />
            </Grid>
            <Grid item xs={9}>
              <TextField
                id="filled-basic"
                variant="filled"
                size="small"
                value={feeDetail?.backRegistration || 0}
                InputProps={{
                  readOnly: true,
                }}
                fullWidth
              />
            </Grid>

            <Grid item xs={3}>
              <TextField
                id="filled-basic"
                variant="filled"
                size="small"
                defaultValue="Total Fees Earn"
                InputProps={{
                  readOnly: true,
                }}
                fullWidth
              />
            </Grid>
            <Grid item xs={9}>
              <TextField
                id="filled-basic"
                variant="filled"
                size="small"
                value={feeDetail?.totalFees || 0}
                InputProps={{
                  readOnly: true,
                }}
                fullWidth
              />
            </Grid>

            <Grid item xs={3}>
              <TextField
                id="outlined-basic"
                variant="outlined"
                size="small"
                defaultValue="Received Fees Earn"
                InputProps={{
                  readOnly: true,
                }}
                fullWidth
              />
            </Grid>
            <Grid item xs={9}>
              <TextField
                id="outlined-basic"
                variant="outlined"
                size="small"
                value={feeDetail?.feesReceived || 0}
                InputProps={{
                  readOnly: true,
                }}
                fullWidth
              />
            </Grid>
            <Grid item xs={3}>
              <TextField
                id="filled-basic"
                variant="filled"
                size="small"
                defaultValue="Pending Fees Earn"
                InputProps={{
                  readOnly: true,
                }}
                fullWidth
              />
            </Grid>
            <Grid item xs={9}>
              <TextField
                id="filled-basic"
                variant="filled"
                size="small"
                value={feeDetail?.pendingFees || 0}
                InputProps={{
                  readOnly: true,
                }}
                fullWidth
              />
            </Grid>

            <Grid item xs={3}>
              <TextField
                id="outlined-basic"
                variant="outlined"
                size="small"
                defaultValue="Registration Fee Earn"
                InputProps={{
                  readOnly: true,
                }}
                fullWidth
              />
            </Grid>
            <Grid item xs={9}>
              <TextField
                id="outlined-basic"
                variant="outlined"
                size="small"
                value={feeDetail?.regFees || 0}
                InputProps={{
                  readOnly: true,
                }}
                fullWidth
              />
            </Grid>
            <Grid item xs={3}>
              <TextField
                id="filled-basic"
                variant="filled"
                size="small"
                defaultValue="Caution Money Fees Earn"
                InputProps={{
                  readOnly: true,
                }}
                fullWidth
              />
            </Grid>
            <Grid item xs={9}>
              <TextField
                id="filled-basic"
                variant="filled"
                size="small"
                value={feeDetail?.cautionMoney || 0}
                InputProps={{
                  readOnly: true,
                }}
                fullWidth
              />
            </Grid>

            <Grid item xs={3}>
              <TextField
                id="outlined-basic"
                variant="outlined"
                size="small"
                defaultValue="Other Fee Earn"
                InputProps={{
                  readOnly: true,
                }}
                fullWidth
              />
            </Grid>
            <Grid item xs={9}>
              <TextField
                id="outlined-basic"
                variant="outlined"
                size="small"
                value={feeDetail?.otherFees || 0}
                InputProps={{
                  readOnly: true,
                }}
                fullWidth
              />
            </Grid>

            <Grid item xs={3}>
              <TextField
                id="filled-basic"
                variant="filled"
                size="small"
                defaultValue="Center Head Unpaid Earn"
                InputProps={{
                  readOnly: true,
                }}
                fullWidth
              />
            </Grid>
            <Grid item xs={9}>
              <TextField
                id="filled-basic"
                variant="filled"
                size="small"
                value={feeDetail?.centerHeadunPaidCommission || 0}
                InputProps={{
                  readOnly: true,
                }}
                fullWidth
              />
            </Grid>

            <Grid item xs={3}>
              <TextField
                id="outlined-basic"
                variant="outlined"
                size="small"
                defaultValue="Center Head Paid Earn"
                InputProps={{
                  readOnly: true,
                }}
                fullWidth
              />
            </Grid>
            <Grid item xs={9}>
              <TextField
                id="outlined-basic"
                variant="outlined"
                size="small"
                value={feeDetail?.centerHeadPaidCommission || 0}
                InputProps={{
                  readOnly: true,
                }}
                fullWidth
              />
            </Grid>
            <Grid item xs={3}>
              <TextField
                id="filled-basic"
                variant="filled"
                size="small"
                defaultValue="Team Lead Unpaid Earn"
                InputProps={{
                  readOnly: true,
                }}
                fullWidth
              />
            </Grid>
            <Grid item xs={9}>
              <TextField
                id="filled-basic"
                variant="filled"
                size="small"
                value={feeDetail?.teamLeadunPaidCommission || 0}
                InputProps={{
                  readOnly: true,
                }}
                fullWidth
              />
            </Grid>

            <Grid item xs={3}>
              <TextField
                id="outlined-basic"
                variant="outlined"
                size="small"
                defaultValue="Team Lead Paid Earn"
                InputProps={{
                  readOnly: true,
                }}
                fullWidth
              />
            </Grid>
            <Grid item xs={9}>
              <TextField
                id="outlined-basic"
                variant="outlined"
                size="small"
                value={feeDetail?.teamLeadPaidCommission || 0}
                InputProps={{
                  readOnly: true,
                }}
                fullWidth
              />
            </Grid>
            <Grid item xs={3}>
              <TextField
                id="filled-basic"
                variant="filled"
                size="small"
                defaultValue="Executive Unpaid Earn"
                InputProps={{
                  readOnly: true,
                }}
                fullWidth
              />
            </Grid>
            <Grid item xs={9}>
              <TextField
                id="filled-basic"
                variant="filled"
                size="small"
                value={feeDetail?.executiveunPaidCommission || 0}
                InputProps={{
                  readOnly: true,
                }}
                fullWidth
              />
            </Grid>
            <Grid item xs={3}>
              <TextField
                id="outlined-basic"
                variant="outlined"
                size="small"
                defaultValue="Executive Paid Earn"
                InputProps={{
                  readOnly: true,
                }}
                fullWidth
              />
            </Grid>
            <Grid item xs={9}>
              <TextField
                id="outlined-basic"
                variant="outlined"
                size="small"
                value={feeDetail?.executivePaidCommission || 0}
                InputProps={{
                  readOnly: true,
                }}
                fullWidth
              />
            </Grid>

            <Grid item xs={3}>
              <TextField
                id="filled-basic"
                variant="filled"
                size="small"
                defaultValue="Student Unpaid Earn"
                InputProps={{
                  readOnly: true,
                }}
                fullWidth
              />
            </Grid>
            <Grid item xs={9}>
              <TextField
                id="filled-basic"
                variant="filled"
                size="small"
                value={feeDetail?.studentunPaidCommission || 0}
                InputProps={{
                  readOnly: true,
                }}
                fullWidth
              />
            </Grid>

            <Grid item xs={3}>
              <TextField
                id="outlined-basic"
                variant="outlined"
                size="small"
                defaultValue="Student Paid Earn"
                InputProps={{
                  readOnly: true,
                }}
                fullWidth
              />
            </Grid>
            <Grid item xs={9}>
              <TextField
                id="outlined-basic"
                variant="outlined"
                size="small"
                value={feeDetail?.studentPaidCommission || 0}
                InputProps={{
                  readOnly: true,
                }}
                fullWidth
              />
            </Grid>
          </Grid>
        </Box>
      </Paper>
    </Container>
  );
};
