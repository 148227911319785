import { useEffect, useState } from "react";
import BusinessCenterIcon from "@mui/icons-material/BusinessCenter";
import Container from "@mui/material/Container";
import Grid from "@mui/material/Grid";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import LocationOnIcon from "@mui/icons-material/LocationOn";
import Button from "@mui/material/Button";
import SchoolIcon from "@mui/icons-material/School";
import HowToRegIcon from "@mui/icons-material/HowToReg";
import {
  Divider,
  FormControl,
  IconButton,
  InputAdornment,
  TextField,
  Tooltip,
} from "@mui/material";
import PlaylistAddCheckOutlinedIcon from "@mui/icons-material/PlaylistAddCheckOutlined";
import AddTaskOutlinedIcon from "@mui/icons-material/AddTaskOutlined";
import Stack from "@mui/material/Stack";
import EmailIcon from "@mui/icons-material/Email";
import PhoneIcon from "@mui/icons-material/Phone";
import DescriptionIcon from "@mui/icons-material/Description";
import PeopleAltOutlinedIcon from "@mui/icons-material/PeopleAltOutlined";
import CalendarMonthIcon from "@mui/icons-material/CalendarMonth";
import Whatsapp from "../../Components/Common/Whatsapp";
import { useNavigate, useLocation } from "react-router-dom";
import { getRequestById, updateRequest } from "../../api/api";
import { GET_EX_LED, GET_REF_LED, REJECT_LED } from "../../api/server";
import SearchIcon from "@mui/icons-material/Search";
import PermIdentityIcon from "@mui/icons-material/PermIdentity";

function ManageAssignLeadEx(props: any) {
  const navigate = useNavigate();
  const location = useLocation();

  //for identifying form type
  const isReferenceUserRoute = location.pathname === "/dashboard/ReferenceUser";

  //for lead list
  const [users, setUsers] = useState<any>([]);

  //for search bar
  const [searchApiData, setSearchApiData] = useState<any[]>([]);
  const [filterVal, setFilterVal] = useState("");

  const loadUsers = async () => {
    if (isReferenceUserRoute) {
      try {
        let userId = props.types?.lId;
        const res: any = await getRequestById(GET_REF_LED, userId, "");
        setUsers(res.data);
      } catch (error) {
        console.error("Error loading users:", error);
      }
    } else {
      try {
        let userId = props.types?.lId;
        const res: any = await getRequestById(GET_EX_LED, userId, "");
        setUsers(res.data);
        setSearchApiData(res.data);
      } catch (error) {
        console.error("Error loading users:", error);
      }
    }
  };

  const RejectUsers = async (e: any) => {
    let userId = e;
    const res: any = await updateRequest(REJECT_LED, userId, undefined, "");
    loadUsers();
  };

  const handleFilter = (e: any) => {
    if (e.target.value === "") {
      setUsers(searchApiData);
    } else {
      const filterResult = searchApiData.filter((item: any) => {
        const searchValue = String(e.target.value)?.toLowerCase();

        return Object.values(item).some((value) => {
          if (typeof value === "string" || typeof value === "number") {
            const itemValue = String(value)?.toLowerCase();
            return itemValue.includes(searchValue);
          }
          return false;
        });
      });
      setUsers(filterResult);
    }
    setFilterVal(e.target.value);
  };

  useEffect(() => {
    loadUsers();
  }, [location]);

  return (
    <Container maxWidth="lg">
      <Grid
        container
        sx={{
          p: 2,
          mb: 2,
          background: "#0288d1",
          color: "white",
        }}
      >
        <Grid item xs={6}>
          <Typography variant="h5" sx={{ mx: 3 }}>
            Manage Assigned Lead
          </Typography>
        </Grid>
        <Grid item xs={6} sx={{ display: "flex", justifyContent: "flex-end" }}>
          <FormControl>
            <TextField
              label="Search "
              size="small"
              value={filterVal}
              onInput={(e: any) => handleFilter(e)}
              sx={{ mx: 3, background: "white" }}
              InputProps={{
                endAdornment: (
                  <InputAdornment position="end">
                    <IconButton edge="end">
                      <SearchIcon />
                    </IconButton>
                  </InputAdornment>
                ),
              }}
            />
          </FormControl>
        </Grid>
      </Grid>
      {users?.map((item: any) => (
        <Box
          key={item._id}
          sx={{
            display: "flex",
            justifyContent: "center",
            boxShadow: 7,
            padding: 4,
            marginBottom: 2,
            background: "white",
          }}
        >
          <Grid container spacing={2} sx={{ background: "white" }}>
            <Grid item alignItems="center" xs={12} md={6}>
              <Stack direction="row" spacing={2} sx={{ mb: 2 }}>
                <Typography
                  variant="h6"
                  fontWeight="bold"
                  style={{ textTransform: "uppercase" }}
                >
                  {item.fname} {item.lname}
                </Typography>
              </Stack>
              <Box
                sx={{
                  display: "flex",
                  alignItems: "center",
                  flexWrap: "wrap",
                }}
              >
                <Box
                  sx={{
                    display: "flex",
                    alignItems: "center",
                    marginRight: { xs: 1, md: 2 },
                    marginTop: { xs: 1, md: 0 },
                  }}
                >
                  <Tooltip title="Lead ID">
                    <PermIdentityIcon sx={{ marginRight: 1 }} />
                  </Tooltip>
                  <Typography
                    variant="body2"
                    sx={{ display: "inline-block" }}
                    style={{ textTransform: "uppercase" }}
                  >
                    {item.ldUniqueId}
                  </Typography>
                </Box>
                {!isReferenceUserRoute && (
                  <>
                    <Box
                      sx={{
                        display: "flex",
                        alignItems: "center",
                        marginRight: { xs: 1, md: 2 },
                        marginBottom: { xs: 1, md: 0 },
                      }}
                    >
                      <Tooltip title="Experience">
                        <BusinessCenterIcon sx={{ marginRight: 1 }} />
                      </Tooltip>
                      <Typography
                        variant="body2"
                        sx={{ display: "inline-block" }}
                      >
                        {item.experience}
                      </Typography>
                    </Box>
                  </>
                )}

                <Box
                  sx={{
                    display: "flex",
                    alignItems: "center",
                    marginRight: { xs: 1, md: 2 },
                    marginBottom: { xs: 1, md: 0 },
                  }}
                >
                  <Tooltip title="Email">
                    <EmailIcon sx={{ marginRight: 1 }} />
                  </Tooltip>
                  <Typography variant="body2" sx={{ display: "inline-block" }}>
                    {item.email}
                  </Typography>
                </Box>
                <Box
                  sx={{
                    display: "flex",
                    alignItems: "center",
                    marginRight: { xs: 1, md: 2 },
                    marginTop: { xs: 1, md: 2 },
                  }}
                >
                  <Tooltip title="Contact">
                    <PhoneIcon sx={{ marginRight: 1 }} />
                  </Tooltip>
                  <Typography variant="body2" sx={{ display: "inline-block" }}>
                    {item.phone}
                  </Typography>
                </Box>

                <Box
                  sx={{
                    display: "flex",
                    alignItems: "center",
                    marginRight: { xs: 1, md: 2 },
                    marginTop: { xs: 1, md: 2 },
                  }}
                >
                  <Tooltip title="Address">
                    <LocationOnIcon sx={{ marginRight: 1 }} />
                  </Tooltip>
                  <Typography
                    variant="body2"
                    sx={{ display: "inline-block" }}
                    style={{ textTransform: "uppercase" }}
                  >
                    {item.state}, {item.city}
                  </Typography>
                </Box>

                <Box
                  sx={{
                    display: "flex",
                    alignItems: "center",
                    marginRight: { xs: 1, md: 2 },
                    marginTop: { xs: 1, md: 2 },
                  }}
                >
                  <Tooltip title="Qualification">
                    <SchoolIcon sx={{ marginRight: 1 }} />
                  </Tooltip>
                  <Typography
                    variant="body2"
                    sx={{ display: "inline-block" }}
                    style={{ textTransform: "uppercase" }}
                  >
                    {item.qualification}
                  </Typography>
                </Box>
                {isReferenceUserRoute && (
                  <Box
                    sx={{
                      display: "flex",
                      alignItems: "center",
                      marginRight: { xs: 1, md: 2 },
                      marginTop: { xs: 1, md: 2 },
                    }}
                  >
                    <Tooltip title="Hide reference">
                      <HowToRegIcon sx={{ marginRight: 1 }} />
                    </Tooltip>
                    <Typography
                      variant="body2"
                      sx={{ display: "inline-block" }}
                      style={{ textTransform: "uppercase" }}
                    >
                      {item.hideName}
                    </Typography>
                  </Box>
                )}

                {!isReferenceUserRoute && (
                  <Box
                    sx={{
                      display: "flex",
                      alignItems: "center",
                      marginRight: { xs: 1, md: 2 },
                      marginTop: { xs: 1, md: 2 },
                    }}
                  >
                    <Tooltip title="Reference By">
                      <DescriptionIcon sx={{ marginRight: 1 }} />
                    </Tooltip>
                    <Typography
                      variant="body2"
                      sx={{
                        display: "inline-block",
                        textTransform: "uppercase",
                      }}
                    >
                      {item.refBy}
                    </Typography>
                  </Box>
                )}

                {isReferenceUserRoute && (
                  <Box
                    sx={{
                      display: "flex",
                      alignItems: "center",
                      marginRight: { xs: 1, md: 2 },
                      marginTop: { xs: 1, md: 2 },
                    }}
                  >
                    <Tooltip title="Reference Name">
                      <PeopleAltOutlinedIcon sx={{ marginRight: 1 }} />
                    </Tooltip>
                    <Typography
                      variant="body2"
                      sx={{
                        display: "inline-block",
                        textTransform: "uppercase",
                        whiteSpace: "nowrap",
                        overflow: "hidden",
                        textOverflow: "ellipsis",
                      }}
                    >
                      {item.refName}
                    </Typography>
                  </Box>
                )}
                {!isReferenceUserRoute && (
                  <Box
                    sx={{
                      display: "flex",
                      alignItems: "center",
                      marginRight: { xs: 1, md: 2 },
                      marginTop: { xs: 1, md: 2 },
                    }}
                  >
                    <Tooltip title="Passing Year">
                      <CalendarMonthIcon sx={{ marginRight: 1 }} />
                    </Tooltip>
                    <Typography
                      variant="body2"
                      sx={{ display: "inline-block" }}
                    >
                      {item.passingYear}
                    </Typography>
                  </Box>
                )}
                {!isReferenceUserRoute && (
                  <Box
                    sx={{
                      display: "flex",
                      alignItems: "center",
                      marginRight: { xs: 1, md: 2 },
                      marginTop: { xs: 1, md: 2 },
                    }}
                  >
                    <Tooltip title="Reference Name">
                      <PeopleAltOutlinedIcon sx={{ marginRight: 1 }} />
                    </Tooltip>
                    <Typography
                      variant="body2"
                      sx={{
                        display: "inline-block",
                        textTransform: "uppercase",
                        whiteSpace: "nowrap",
                        overflow: "hidden",
                        textOverflow: "ellipsis",
                      }}
                    >
                      {item.refName}
                    </Typography>
                  </Box>
                )}
              </Box>
            </Grid>

            <Grid item xs={12} md={6}>
              <Box
                sx={{
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "baseline",
                  height: "100%",
                  flexDirection: "column",
                  textAlign: "start",
                }}
              >
                <Grid container sx={{ mb: 5 }}>
                  <Grid item xs={12} sm={12} sx={{ justifyContent: "end" }}>
                    <Typography variant="body1" fontWeight="bold">
                      UPDATE CANDIDATE STATUS
                    </Typography>
                  </Grid>

                  <Grid item xs={12} sm={12} sx={{ justifyContent: "end" }}>
                    <Grid
                      container
                      spacing={2}
                      sx={{ justifyContent: "flex-end", pt: 1 }}
                    >
                      <Grid item xs={12} sm={6} md={3}>
                        <Whatsapp chatNumber={item.phone} />
                      </Grid>

                      <Grid item xs={12} sm={6} md={3}>
                        <Tooltip title="View Follow-Ups">
                          <Button
                            variant="outlined"
                            sx={{ width: "100%", height: "100%" }}
                          >
                            <PlaylistAddCheckOutlinedIcon
                              onClick={() =>
                                navigate(`ViewActivity/${item._id}`)
                              }
                            />
                          </Button>
                        </Tooltip>
                      </Grid>

                      <Grid item xs={12} sm={6} md={3}>
                        <Tooltip title="Add New Follow-Up">
                          <Button
                            variant="outlined"
                            sx={{ width: "100%", height: "100%" }}
                          >
                            <AddTaskOutlinedIcon
                              onClick={() =>
                                navigate("Activity", {
                                  state: { item, lId: props?.types?.lId },
                                })
                              }
                            />
                          </Button>
                        </Tooltip>
                      </Grid>

                      <Grid item xs={12} sm={6} md={3}>
                        <Button
                          variant="outlined"
                          size="large"
                          sx={{
                            width: "100%",
                            height: "100%",
                            color: "#7B3F00",
                          }}
                          onClick={(e) => RejectUsers(item._id)}
                        >
                          Reject
                        </Button>
                      </Grid>
                    </Grid>
                  </Grid>

                  <Grid item xs={12} sm={12} sx={{ justifyContent: "end" }}>
                    <Divider
                      sx={{
                        bgcolor: "gray !important",
                        height: 1.3,
                        margin: "20px 0 ",
                      }}
                    />
                  </Grid>
                </Grid>
              </Box>
            </Grid>
          </Grid>
        </Box>
      ))}
    </Container>
  );
}

export default ManageAssignLeadEx;
