import HomeIcon from "@mui/icons-material/Home";
import {
  Box,
  Divider,
  Grid,
  Paper,
  Stack,
  Typography,
  Zoom,
} from "@mui/material";
import { styled } from "@mui/material/styles";
import { useEffect, useState } from "react";
import { getRequest } from "../api/api";
import { GET_SETTING } from "../api/server";

const Item = styled(Paper)(({ theme }) => ({
  backgroundColor: theme.palette.mode === "dark" ? "#1A2027" : "#fff",
  ...theme.typography.body2,
  padding: theme.spacing(1),
  textAlign: "center",
  border: "2px solid black",
  color: theme.palette.text.secondary,
  "&:hover": {
    backgroundColor: "#0288d1",
    color: "white",
    transform: "scale3d(1.09, 1.09, 1)",
    transition: "transform 2s",
  },
  boxShadow: "10px 10px 10px 10px",
}));

function DashboardData(props: any) {
  const { types, studentDetails } = props;

  const [checked, setChecked] = useState<boolean>(false);
  const [detail, setDetail] = useState<any | null>(null);

  const handleChange = () => {
    setChecked((prev) => !prev);
  };

  const getStudentData = async () => {
    await getRequest(GET_SETTING, "")
      .then((resp: any) => {
        setDetail(resp.data[0]);
      })
      .catch((error) => {
        console.error("Error fetching data:", error);
      });
  };

  useEffect(() => {
    types?.type !== "Administrator" && getStudentData();
    handleChange();
    return () => {
      setDetail(null);
    };
  }, []);

  return (
    <>
      <Box
        sx={{
          flexGrow: 1,
          border: "2px solid black",
          borderRadius: "12px",
          padding: "5px",
          mb: "10px",
          backgroundColor: "#f2f2f2",
          zIndex: 0,
        }}
      >
        <Stack direction={"row"}>
          <HomeIcon />
          <Typography variant="h6">Home</Typography>
        </Stack>
        <Divider sx={{ mt: "10px" }} />
        <Box
          sx={{
            flexGrow: 1,
            border: "2px solid black",
            borderRadius: "12px",
            m: "20px",
            padding: "5px",
          }}
        >
          <Stack direction={"row"}>
            <HomeIcon />
            <Typography variant="h6">Quick Updates</Typography>
          </Stack>
          <Divider sx={{ mt: "10px" }} />
          <Box sx={{ flexGrow: 1, m: "20px", padding: "5px" }}>
            <Grid container spacing={6}>
              {types?.type === "Student" && (
                <Zoom
                  in={checked}
                  style={{ transitionDelay: checked ? "1000ms" : "0ms" }}
                >
                  <Grid item xs={12} lg={12}>
                    <Box>
                      <Item sx={{ backgroundColor: "#0288d1" }}>
                        <Typography variant="h6">Student Details </Typography>
                        <Typography variant="subtitle1">
                          Prerna Registration id : {studentDetails.studentId}
                        </Typography>
                        <Typography variant="subtitle1">
                          Enrollment No : {studentDetails.enrollmentNo}
                        </Typography>
                        <Typography variant="subtitle1">SID</Typography>
                      </Item>
                    </Box>
                  </Grid>
                </Zoom>
              )}
              <Zoom in={checked}>
                <Grid item xs={12} md={6}>
                  <Box>
                    <Item sx={{ height: "160px" }}>
                      <Typography variant="h6">For any query/issue</Typography>
                      <Typography variant="subtitle1">
                        For any query/issue Please Email at :
                        {detail?.queryEmail}
                      </Typography>
                      <Typography variant="subtitle1">
                        For Admission Please Email at : {detail?.admissionEmail}
                      </Typography>
                    </Item>
                  </Box>
                </Grid>
              </Zoom>
              <Zoom
                in={checked}
                style={{ transitionDelay: checked ? "500ms" : "0ms" }}
              >
                <Grid item xs={12} md={6}>
                  <Box>
                    <Item sx={{ height: "160px" }}>
                      <Typography variant="h6">
                        For Technical Issue, Call : {detail?.technicalCall}
                      </Typography>
                      <Typography variant="subtitle1">
                        Office Phone :{detail?.officePhone}
                      </Typography>
                      <Typography variant="subtitle1">
                        Office Email : {detail?.officeEmail}
                      </Typography>
                    </Item>
                  </Box>
                </Grid>
              </Zoom>
              <Zoom
                in={checked}
                style={{ transitionDelay: checked ? "750ms" : "0ms" }}
              >
                <Grid item xs={12} md={6}>
                  <Box>
                    <Item>
                      <Typography variant="h6"> BANK INFORMATION</Typography>
                      <Typography variant="subtitle1">
                        <div
                          dangerouslySetInnerHTML={{
                            __html: detail?.accountDetails,
                          }}
                        ></div>
                      </Typography>
                    </Item>
                  </Box>
                </Grid>
              </Zoom>
              <Zoom
                in={checked}
                style={{ transitionDelay: checked ? "1000ms" : "0ms" }}
              >
                <Grid item xs={12} md={6}>
                  <Box>
                    <Item>
                      <Typography variant="h6">COURIER ADDRESS</Typography>
                      <Typography variant="subtitle1">
                        <div
                          dangerouslySetInnerHTML={{ __html: detail?.homePage }}
                        ></div>
                      </Typography>
                    </Item>
                  </Box>
                </Grid>
              </Zoom>
            </Grid>
          </Box>
        </Box>
      </Box>
    </>
  );
}

export default DashboardData;
