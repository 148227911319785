import styled from "@emotion/styled";
import AddBoxIcon from "@mui/icons-material/AddBox";
import DeleteIcon from "@mui/icons-material/Delete";
import SchoolTwoToneIcon from "@mui/icons-material/SchoolTwoTone";
import {
  Alert,
  Box,
  Button,
  Container,
  FormControl,
  Grid,
  InputAdornment,
  InputLabel,
  MenuItem,
  OutlinedInput,
  Paper,
  Snackbar,
  Stack,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
} from "@mui/material";
import { AlertColor } from "@mui/material/Alert";
import Select, { SelectChangeEvent } from "@mui/material/Select";
import Slide, { SlideProps } from "@mui/material/Slide";
import { pink } from "@mui/material/colors";
import React, { ChangeEvent, useEffect, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import "../../Components/Home/Home.scss";
import { postRequestMethod } from "../../api/api";
import { POST_UNIVERSITY } from "../../api/server";
const StyledDeleteIcon = styled(DeleteIcon)`
  color: pink[500];
  transition: transform 0.3s ease-out;

  &:hover {
    transform: scale(1.2);
  }
`;

//for SEMESTER COURSES DETAILS
interface SemesterCourses {
  id: number;
  status: string;
  department: string;
  course: string;
  specialization: string;
  courseCategory: string;
  noOfSemesters: number | undefined;
  eligibility: string;
  tuitionFee: number | undefined;
  otherFee: number | undefined;
  totalFee: number | undefined;
}

// For YEARLY COURSES DETAILS
interface YearlyCourses {
  id: number;
  status: string;
  department: string;
  course: string;
  specialization: string;
  courseCategory: string;
  noOfYears: number | undefined;
  eligibility: string;
  tuitionFee: number | undefined;
  otherFee: number | undefined;
  totalFee: number | undefined;
}

const iniSC: SemesterCourses = {
  id: 1,
  department: "",
  course: "",
  specialization: "",
  courseCategory: "",
  noOfSemesters: undefined,
  eligibility: "",
  tuitionFee: undefined,
  otherFee: undefined,
  totalFee: undefined,
  status: "Active",
};

const iniYC: YearlyCourses = {
  id: 1,
  department: "",
  course: "",
  specialization: "",
  courseCategory: "",
  noOfYears: undefined,
  eligibility: "",
  tuitionFee: undefined,
  otherFee: undefined,
  totalFee: undefined,
  status: "Active",
};

const AddUniversity: React.FC = () => {
  const navigate = useNavigate();
  const location = useLocation();

  const [semesterCourses, setSemesterCourses] = useState<SemesterCourses[]>([
    iniSC,
  ]);
  const [yearlyCourses, setYearlyCourses] = useState<YearlyCourses[]>([iniYC]);
  const [universityName, setUniversityName] = useState<String>("");
  const [universityStatus, setuniversityStatus] = useState<string>("Active");
  const [open, setOpen] = useState(false);
  const [severity, setSeverity] = useState<AlertColor | undefined>(undefined);
  const [message, setMessage] = useState("");

  useEffect(() => {
    if (location.state) {
      console.log(location.state);
      setUniversityName(location.state.universityName);
      setuniversityStatus(location.state.universityStatus);
      let mode = location.state.mode.filter(
        (item: any) => item.type === "Semester"
      );
      location.state.specialization.filter(
        (item: any) => item.sId === mode.map((id: any) => id.sId)
      );
    }
  }, []);

  const combinedData: any = () => {
    const arr = [];
    for (let i = 0; i < semesterCourses.length; i++) {
      const obj = semesterCourses[i];
      arr.push({
        department: obj.department,
        course: obj.course,
        specialization: obj.specialization,
        courseCategory: obj.courseCategory,
        mode: "Semester",
        noOfSemsOrNoOfYears: obj.noOfSemesters,
        eligibility: obj.eligibility,
        tuitionFee: obj.tuitionFee,
        otherFee: obj.otherFee,
        totalFee: obj.totalFee,
        status: obj.status,
      });
    }

    for (let i = 0; i < yearlyCourses.length; i++) {
      const obj = yearlyCourses[i];
      arr.push({
        department: obj.department,
        course: obj.course,
        specialization: obj.specialization,
        courseCategory: obj.courseCategory,
        mode: "Yearly",
        noOfSemsOrNoOfYears: obj.noOfYears,
        eligibility: obj.eligibility,
        tuitionFee: obj.tuitionFee,
        otherFee: obj.otherFee,
        totalFee: obj.totalFee,
        status: obj.status,
      });
    }
    return {
      universityName: universityName,
      courseDetails: arr,
      universityStatus: universityStatus,
    };
  };

  //to handle add rows button to include rows in the form
  const handleAddRow = (type: string) => {
    const newId =
      type === "semester"
        ? semesterCourses.length + 1
        : yearlyCourses.length + 1;
    if (type === "semester") {
      setSemesterCourses([
        ...semesterCourses,
        {
          ...iniSC,
          id: newId,
        },
      ]);
    } else if (type === "yearly") {
      setYearlyCourses([
        ...yearlyCourses,
        {
          ...iniYC,
          id: newId,
        },
      ]);
    }
  };

  //to handle delete rows in the form
  const handleDeleteRow = (id: number, type: string) => {
    const confirmation = window.confirm("Are you sure you want to delete?");
    if (id === 1) {
      return; // Don't delete the first row
    }
    if (confirmation) {
      if (type === "semester") {
        const newRows = semesterCourses.filter((row) => row.id !== id);
        setSemesterCourses(newRows);
      } else if (type === "yearly") {
        const newRows = yearlyCourses.filter((row) => row.id !== id);
        setYearlyCourses(newRows);
      }
    }
  };

  //to handle input change
  const handleInputChange = (
    e:
      | ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
      | SelectChangeEvent<string>,
    id: number,
    type: string
  ) => {
    let newRows: any;
    if (type === "semester") {
      newRows = semesterCourses.map((row) => {
        if (row.id === id) {
          return { ...row, [e.target.name]: e.target.value };
        }
        return row;
      });
      setSemesterCourses(newRows);
    } else if (type === "yearly") {
      newRows = yearlyCourses.map((row2) => {
        if (row2.id === id) {
          return { ...row2, [e.target.name]: e.target.value };
        }
        return row2;
      });
      setYearlyCourses(newRows);
    }
  };

  const calculateTotalFee = (
    e:
      | ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
      | SelectChangeEvent<string>,
    id: number,
    type: string
  ) => {
    if (type === "semester") {
      let newRows: any;
      newRows = semesterCourses.map((row: any) => {
        const { tuitionFee, otherFee } = row;
        if (tuitionFee !== undefined && otherFee !== undefined) {
          if (row.id === id) {
            let total: number = Number(tuitionFee) + Number(otherFee);
            return { ...row, totalFee: total };
          }
          return row;
        }
        return row;
      });
      setSemesterCourses(newRows);
    }
    if (type === "yearly") {
      let newRows: any;
      newRows = yearlyCourses?.map((row2: any) => {
        const { tuitionFee, otherFee } = row2;
        if (tuitionFee !== undefined && otherFee !== undefined) {
          if (row2.id === id) {
            let total: number = Number(tuitionFee) + Number(otherFee);
            return { ...row2, totalFee: total };
          }
          return row2;
        }
        return row2;
      });
      setYearlyCourses(newRows);
    }
  };

  //form submissin to post data to backend
  const handleSubmit = async (
    e: React.FormEvent<HTMLFormElement>
  ): Promise<void> => {
    e.preventDefault();

    // to include the submitted Date & Time
    const timestamp = new Date();
    const dateStr = timestamp.toLocaleDateString("en-US", {
      month: "2-digit",
      day: "2-digit",
      year: "numeric",
      timeZone: "Asia/Kolkata", // add time zone
    });
    const timeStr = timestamp.toLocaleTimeString("en-US", {
      hour: "numeric",
      minute: "2-digit",
      timeZone: "Asia/Kolkata", // add time zone
      hour12: true,
    });
    const data = combinedData();
    try {
      const response = await postRequestMethod(POST_UNIVERSITY, "", data);
      if (response) {
        console.log("request: ", data);
        console.log("response from back", response.data);
        setOpen(true);
        setSeverity("success");
        setMessage("University added successfully");
      }
    } catch (error: any) {
      console.log(error);
      setOpen(true);
      if (
        error.response &&
        error.response.data &&
        error.response.data.message
      ) {
        setSeverity("error");
        setMessage(error.response.data.message);
      } else {
        setSeverity("error");
        setMessage("Failed to add university");
      }
    }
  };

  //to handle cloe button in the submiited message popup
  const handleClose = () => {
    setOpen(false);
    setMessage("");
    setSeverity(undefined);
  };

  //cancel button
  function cancel() {
    navigate(`/manageuniversity`);
  }

  return (
    <Container
      component={Paper}
      sx={{ mt: 2, mb: 2, p: 2, boxShadow: 5 }}
      maxWidth="md"
    >
      {/* //successul submisson message */}
      <Snackbar
        open={open}
        autoHideDuration={3000}
        onClose={handleClose}
        anchorOrigin={{ vertical: "top", horizontal: "right" }}
        TransitionComponent={Slide}
        TransitionProps={{ direction: "up" } as SlideProps}
      >
        <Alert onClose={handleClose} severity={severity} sx={{ width: "100%" }}>
          {message}
        </Alert>
      </Snackbar>

      <Grid component="form" onSubmit={handleSubmit} sx={{ p: 1 }}>
        {/* add university header part   */}
        <Typography
          variant="h5"
          color="primary"
          sx={{ fontWeight: 400, textAlign: "center" }}
        >
          ADD UNIVERSITY
        </Typography>

        {/* University Name */}
        <Box sx={{ width: "100%" }}>
          <FormControl variant="outlined" size="small" fullWidth sx={{ mt: 2 }}>
            <InputLabel htmlFor="University-name-input">
              University Name
            </InputLabel>
            <OutlinedInput
              id="University-name-input"
              value={universityName}
              required
              startAdornment={
                <InputAdornment position="start">
                  <SchoolTwoToneIcon color="primary" />
                </InputAdornment>
              }
              onChange={(e) => setUniversityName(e.target.value)}
              label="University Name"
            />
          </FormControl>
        </Box>

        {/* SEMESTER COURSES DETAILS */}
        <Box sx={{ width: "100%" }} marginTop={2}>
          <Grid
            marginBottom={2}
            container
            justifyContent="space-between"
            alignItems="center"
          >
            <Grid item>
              <Typography variant="button" textAlign="left" color="primary">
                Semester Courses Details
              </Typography>
            </Grid>

            <Grid item>
              <Button
                variant="outlined"
                onClick={() => handleAddRow("semester")}
                startIcon={<AddBoxIcon />}
                size="small"
              >
                Add Row
              </Button>
            </Grid>
          </Grid>

          <TableContainer className="scrollBarCss">
            <Table sx={{ width: "2000px" }}>
              <TableHead>
                <TableRow>
                  <TableCell
                    sx={{
                      borderBottom: "none",
                      alignItems: "center",
                      justifyContent: "center",
                    }}
                  ></TableCell>
                  <TableCell size="small" sx={{ border: "1px solid black" }}>
                    Status
                  </TableCell>
                  <TableCell size="small" sx={{ border: "1px solid black" }}>
                    Department Name
                  </TableCell>
                  <TableCell size="small" sx={{ border: "1px solid black" }}>
                    Course Name
                  </TableCell>
                  <TableCell size="small" sx={{ border: "1px solid black" }}>
                    Specialization
                  </TableCell>

                  <TableCell size="small" sx={{ border: "1px solid black" }}>
                    Course Category
                  </TableCell>

                  <TableCell size="small" sx={{ border: "1px solid black" }}>
                    No.Of Semesters
                  </TableCell>
                  <TableCell size="small" sx={{ border: "1px solid black" }}>
                    Eligibility Criteria
                  </TableCell>
                  <TableCell size="small" sx={{ border: "1px solid black" }}>
                    Tuition Fee Per Sem
                  </TableCell>

                  <TableCell size="small" sx={{ border: "1px solid black" }}>
                    Other Fee
                  </TableCell>
                  <TableCell size="small" sx={{ border: "1px solid black" }}>
                    Total Fee
                  </TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {semesterCourses.map((row) => (
                  <TableRow key={row.id} sx={{ mb: "1" }}>
                    {row.id > 1 ? (
                      <TableCell
                        sx={{
                          borderBottom: "none",
                          display: "flex",
                          alignItems: "center",
                          justifyContent: "center",
                        }}
                      >
                        <StyledDeleteIcon
                          sx={{ color: pink[500] }}
                          onClick={() => handleDeleteRow(row.id, "semester")}
                        />
                      </TableCell>
                    ) : (
                      <TableCell
                        sx={{
                          borderBottom: "none",
                          alignItems: "center",
                          justifyContent: "center",
                        }}
                      ></TableCell>
                    )}

                    {/* Course Status */}
                    <TableCell size="small" sx={{ border: "1px solid black" }}>
                      <FormControl variant="outlined" size="small" fullWidth>
                        <Select
                          required
                          id="status"
                          name="status"
                          value={row.status}
                          onChange={(e) =>
                            handleInputChange(e, row.id, "semester")
                          }
                        >
                          <MenuItem value="Active">Active</MenuItem>
                          <MenuItem value="Inactive">Inactive</MenuItem>
                        </Select>
                      </FormControl>
                    </TableCell>

                    {/* Department Name */}
                    <TableCell size="small" sx={{ border: "1px solid black" }}>
                      <FormControl variant="outlined" size="small" fullWidth>
                        <OutlinedInput
                          id="department"
                          name="department"
                          value={row.department}
                          required
                          type="text"
                          onChange={(e) =>
                            handleInputChange(e, row.id, "semester")
                          }
                        />
                      </FormControl>
                    </TableCell>

                    {/* Course Name */}
                    <TableCell size="small" sx={{ border: "1px solid black" }}>
                      <FormControl variant="outlined" size="small" fullWidth>
                        <OutlinedInput
                          required
                          id="course"
                          value={row.course}
                          name="course"
                          type="text"
                          onChange={(e) =>
                            handleInputChange(e, row.id, "semester")
                          }
                        />
                      </FormControl>
                    </TableCell>

                    {/* Specialization */}
                    <TableCell size="small" sx={{ border: "1px solid black" }}>
                      <FormControl variant="outlined" size="small" fullWidth>
                        <OutlinedInput
                          required
                          id="specialization"
                          name="specialization"
                          value={row.specialization}
                          type="text"
                          onChange={(e) =>
                            handleInputChange(e, row.id, "semester")
                          }
                        />
                      </FormControl>
                    </TableCell>

                    {/* Course Category */}
                    <TableCell size="small" sx={{ border: "1px solid black" }}>
                      <FormControl variant="outlined" size="small" fullWidth>
                        <Select
                          required
                          id="courseCategory"
                          name="courseCategory"
                          value={row.courseCategory}
                          onChange={(e) =>
                            handleInputChange(e, row.id, "semester")
                          }
                        >
                          <MenuItem value="Regular">Regular</MenuItem>
                          <MenuItem value="Private">Private</MenuItem>
                          <MenuItem value="F-TEL">F-Tel</MenuItem>
                          <MenuItem value="Distance">Distance</MenuItem>
                          <MenuItem value="Online">Online</MenuItem>
                          <MenuItem value="Part-Time">Part Time</MenuItem>
                        </Select>
                      </FormControl>
                    </TableCell>

                    {/* No Of Semesters */}
                    <TableCell size="small" sx={{ border: "1px solid black" }}>
                      <FormControl variant="outlined" size="small" fullWidth>
                        <OutlinedInput
                          required
                          id="noOfSemesters"
                          name="noOfSemesters"
                          value={row.noOfSemesters}
                          type="number"
                          onChange={(e) =>
                            handleInputChange(e, row.id, "semester")
                          }
                        />
                      </FormControl>
                    </TableCell>

                    {/* Eligibility Criteria */}
                    <TableCell size="small" sx={{ border: "1px solid black" }}>
                      <FormControl variant="outlined" size="small" fullWidth>
                        <OutlinedInput
                          required
                          multiline
                          id="eligibility"
                          name="eligibility"
                          value={row.eligibility}
                          onChange={(e) =>
                            handleInputChange(e, row.id, "semester")
                          }
                        />
                      </FormControl>
                    </TableCell>

                    {/* Tuition Fee per semester */}
                    <TableCell size="small" sx={{ border: "1px solid black" }}>
                      <FormControl variant="outlined" size="small" fullWidth>
                        <OutlinedInput
                          required
                          id="tuitionFee"
                          name="tuitionFee"
                          value={row.tuitionFee}
                          startAdornment={
                            <InputAdornment position="start">₹</InputAdornment>
                          }
                          type="number"
                          onChange={(e) =>
                            handleInputChange(e, row.id, "semester")
                          }
                          onBlur={(e) =>
                            calculateTotalFee(e, row.id, "semester")
                          }
                        />
                      </FormControl>
                    </TableCell>

                    {/* Other Fee */}
                    <TableCell size="small" sx={{ border: "1px solid black" }}>
                      <FormControl variant="outlined" size="small" fullWidth>
                        <OutlinedInput
                          required
                          id="otherFee"
                          name="otherFee"
                          value={row.otherFee}
                          startAdornment={
                            <InputAdornment position="start">₹</InputAdornment>
                          }
                          type="number"
                          onChange={(e) =>
                            handleInputChange(e, row.id, "semester")
                          }
                          onBlur={(e) =>
                            calculateTotalFee(e, row.id, "semester")
                          }
                        />
                      </FormControl>
                    </TableCell>

                    {/* Total Fee */}
                    <TableCell size="small" sx={{ border: "1px solid black" }}>
                      <FormControl variant="outlined" size="small" fullWidth>
                        <OutlinedInput
                          required
                          id="totalFee"
                          name="totalFee"
                          disabled
                          startAdornment={
                            <InputAdornment position="start">₹</InputAdornment>
                          }
                          type="number"
                          value={semesterCourses[row.id - 1]?.totalFee}
                        />
                      </FormControl>
                    </TableCell>
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          </TableContainer>
        </Box>

        {/* YEARLY COURSES DETAILS */}
        <Box sx={{ width: "100%" }} marginTop={2}>
          <Grid
            marginBottom={2}
            container
            justifyContent="space-between"
            alignItems="center"
          >
            <Grid item>
              <Typography variant="button" textAlign="left" color="primary">
                Yearly Courses Details
              </Typography>
            </Grid>
            <Grid item>
              <Button
                variant="outlined"
                onClick={() => handleAddRow("yearly")}
                startIcon={<AddBoxIcon />}
                size="small"
              >
                Add Row
              </Button>
            </Grid>
          </Grid>

          <TableContainer
            className="scrollBarCss"
            // component={Paper}
          >
            <Table sx={{ width: "2000px" }}>
              <TableHead>
                <TableRow>
                  <TableCell
                    sx={{
                      borderBottom: "none",
                      alignItems: "center",
                      justifyContent: "center",
                    }}
                  ></TableCell>
                  <TableCell size="small" sx={{ border: "1px solid black" }}>
                    Status
                  </TableCell>
                  <TableCell size="small" sx={{ border: "1px solid black" }}>
                    Department Name
                  </TableCell>
                  <TableCell size="small" sx={{ border: "1px solid black" }}>
                    Course Name
                  </TableCell>
                  <TableCell size="small" sx={{ border: "1px solid black" }}>
                    Specialization
                  </TableCell>
                  <TableCell size="small" sx={{ border: "1px solid black" }}>
                    Course Category
                  </TableCell>
                  <TableCell size="small" sx={{ border: "1px solid black" }}>
                    No.Of Years
                  </TableCell>
                  <TableCell size="small" sx={{ border: "1px solid black" }}>
                    Eligibility Criteria
                  </TableCell>
                  <TableCell size="small" sx={{ border: "1px solid black" }}>
                    Tuition Fee Per Sem
                  </TableCell>
                  <TableCell size="small" sx={{ border: "1px solid black" }}>
                    Other Fee
                  </TableCell>
                  <TableCell size="small" sx={{ border: "1px solid black" }}>
                    Total Fee Per Sem
                  </TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {yearlyCourses.map((row2) => (
                  <TableRow key={row2.id}>
                    {row2.id > 1 ? (
                      <TableCell
                        sx={{
                          borderBottom: "none",
                          display: "flex",
                          alignItems: "center",
                          justifyContent: "center",
                        }}
                      >
                        <StyledDeleteIcon
                          sx={{ color: pink[500] }}
                          onClick={() => handleDeleteRow(row2.id, "yearly")}
                        />
                      </TableCell>
                    ) : (
                      <TableCell
                        sx={{
                          borderBottom: "none",
                          alignItems: "center",
                          justifyContent: "center",
                        }}
                      ></TableCell>
                    )}

                    {/* Course Status */}
                    <TableCell size="small" sx={{ border: "1px solid black" }}>
                      <FormControl variant="outlined" size="small" fullWidth>
                        <Select
                          required
                          id="status"
                          name="status"
                          value={row2.status}
                          onChange={(e) =>
                            handleInputChange(e, row2.id, "yearly")
                          }
                        >
                          <MenuItem value="Active">Active</MenuItem>
                          <MenuItem value="Inactive">Inactive</MenuItem>
                        </Select>
                      </FormControl>
                    </TableCell>

                    {/* Department Name */}
                    <TableCell size="small" sx={{ border: "1px solid black" }}>
                      <FormControl variant="outlined" size="small" fullWidth>
                        <OutlinedInput
                          id="department"
                          name="department"
                          value={row2.department}
                          required
                          type="text"
                          onChange={(e) =>
                            handleInputChange(e, row2.id, "yearly")
                          }
                        />
                      </FormControl>
                    </TableCell>

                    {/* Course Name */}
                    <TableCell size="small" sx={{ border: "1px solid black" }}>
                      <FormControl variant="outlined" size="small" fullWidth>
                        <OutlinedInput
                          required
                          value={row2.course}
                          id="course"
                          name="course"
                          type="text"
                          onChange={(e) =>
                            handleInputChange(e, row2.id, "yearly")
                          }
                        />
                      </FormControl>
                    </TableCell>

                    {/* Specialization */}
                    <TableCell size="small" sx={{ border: "1px solid black" }}>
                      <FormControl variant="outlined" size="small" fullWidth>
                        <OutlinedInput
                          required
                          id="specialization"
                          name="specialization"
                          value={row2.specialization}
                          type="text"
                          onChange={(e) =>
                            handleInputChange(e, row2.id, "yearly")
                          }
                        />
                      </FormControl>
                    </TableCell>

                    {/* Course Category */}
                    <TableCell size="small" sx={{ border: "1px solid black" }}>
                      <FormControl variant="outlined" size="small" fullWidth>
                        <Select
                          required
                          id="courseCategory"
                          name="courseCategory"
                          value={row2.courseCategory}
                          onChange={(e) =>
                            handleInputChange(e, row2.id, "yearly")
                          }
                        >
                          <MenuItem value="Regular">Regular</MenuItem>
                          <MenuItem value="Private">Private</MenuItem>
                          <MenuItem value="F-Tel">F-Tel</MenuItem>
                          <MenuItem value="Open & Distance (ODL)">
                            Open & Distance (ODL)
                          </MenuItem>
                          <MenuItem value="Online">Online</MenuItem>
                          <MenuItem value="Part Time">Part Time</MenuItem>
                        </Select>
                      </FormControl>
                    </TableCell>

                    {/* No Of Years */}
                    <TableCell size="small" sx={{ border: "1px solid black" }}>
                      <FormControl variant="outlined" size="small" fullWidth>
                        <OutlinedInput
                          required
                          id="noOfYears"
                          name="noOfYears"
                          value={row2.noOfYears}
                          type="number"
                          onChange={(e) =>
                            handleInputChange(e, row2.id, "yearly")
                          }
                        />
                      </FormControl>
                    </TableCell>

                    {/* Eligibility Criteria */}
                    <TableCell size="small" sx={{ border: "1px solid black" }}>
                      <FormControl variant="outlined" size="small" fullWidth>
                        <OutlinedInput
                          required
                          id="eligibility"
                          name="eligibility"
                          value={row2.noOfYears}
                          multiline
                          onChange={(e) =>
                            handleInputChange(e, row2.id, "yearly")
                          }
                        />
                      </FormControl>
                    </TableCell>

                    {/* Tuition Fee per semester */}
                    <TableCell size="small" sx={{ border: "1px solid black" }}>
                      <FormControl variant="outlined" size="small" fullWidth>
                        <OutlinedInput
                          required
                          value={row2.tuitionFee}
                          id="tuitionFee"
                          name="tuitionFee"
                          startAdornment={
                            <InputAdornment position="start">₹</InputAdornment>
                          }
                          type="number"
                          onChange={(e) =>
                            handleInputChange(e, row2.id, "yearly")
                          }
                          onBlur={(e) =>
                            calculateTotalFee(e, row2.id, "yearly")
                          }
                        />
                      </FormControl>
                    </TableCell>

                    {/* Other Fee */}
                    <TableCell size="small" sx={{ border: "1px solid black" }}>
                      <FormControl variant="outlined" size="small" fullWidth>
                        <OutlinedInput
                          required
                          id="otherFee"
                          name="otherFee"
                          value={row2.otherFee}
                          startAdornment={
                            <InputAdornment position="start">₹</InputAdornment>
                          }
                          type="number"
                          onChange={(e) =>
                            handleInputChange(e, row2.id, "yearly")
                          }
                          onBlur={(e) =>
                            calculateTotalFee(e, row2.id, "yearly")
                          }
                        />
                      </FormControl>
                    </TableCell>

                    {/* Total Fee */}
                    <TableCell size="small" sx={{ border: "1px solid black" }}>
                      <FormControl variant="outlined" size="small" fullWidth>
                        <OutlinedInput
                          required
                          id="totalFee"
                          name="totalFee"
                          disabled
                          startAdornment={
                            <InputAdornment position="start">₹</InputAdornment>
                          }
                          type="number"
                          value={yearlyCourses[row2.id - 1]?.totalFee}
                        />
                      </FormControl>
                    </TableCell>
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          </TableContainer>
        </Box>

        {/* University Status */}
        <Box sx={{ width: "100%" }} marginTop={3}>
          <FormControl variant="outlined" size="small" fullWidth sx={{ mb: 2 }}>
            <InputLabel htmlFor="UniversityStatus-fee-input">
              University Status
            </InputLabel>
            <Select
              required
              id="UniversityStatus-fee-input"
              startAdornment={
                <InputAdornment position="start"></InputAdornment>
              }
              value={universityStatus}
              onChange={(e) => setuniversityStatus(e.target.value)}
              label="University Status"
              style={{ textAlign: "left" }}
            >
              <MenuItem value="Active">Active</MenuItem>
              <MenuItem value="Inactive">Inactive</MenuItem>
            </Select>
          </FormControl>
        </Box>

        {/* submit button */}

        <Box display="flex" justifyContent="center">
          <Stack gap={2} direction={"row"}>
            <Button variant="contained" color="primary" type="submit">
              Submit
            </Button>
            <Button variant="outlined" color="primary" onClick={() => cancel()}>
              Back
            </Button>
          </Stack>
        </Box>
      </Grid>
    </Container>
  );
};

export default AddUniversity;
