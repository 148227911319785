import {
  Document,
  Image,
  Page,
  StyleSheet,
  Text,
  View,
} from "@react-pdf/renderer";
import { FC, useMemo } from "react";

const styles = StyleSheet.create({
  body: {
    paddingTop: 35,
    paddingBottom: 65,
    paddingHorizontal: 35,
  },
  para1: {
    flexDirection: "row",
    flexWrap: "wrap",
  },
  column1: {
    width: "20%",
    padding: 20,
  },
  column2: {
    width: "40%",
    padding: 20,
  },
  column7: {
    width: "100%",
    padding: 20,
    alignSelf: "center",
    textAlign: "center",
  },
  textHeading: {
    fontSize: 14,
    fontWeight: "ultrabold",
  },
  text: {
    fontSize: 12,
    textAlign: "justify",
    lineHeight: 1.5,
  },
  image: {
    height: 70,
    width: 70,
  },
  pageNumber: {
    position: "absolute",
    fontSize: 12,
    bottom: 30,
    left: 0,
    right: 0,
    textAlign: "center",
    color: "grey",
    fontFamily: "AntonFamily",
  },
  border: {
    borderBottom: "1px solid black",
    margin: 5,
  },
  text1: {
    fontSize: 15,
    textAlign: "center",
    marginBottom: 10,
    marginTop: 10,
  },
  text2: {
    fontSize: 12,
    padding: 5,
    lineHeight: "1px",
  },
  text3: {
    fontSize: 12,
    textAlign: "justify",
    padding: 30,
    marginTop: 5,
    textIndent: "50px",
    letterSpacing: "0px",
    lineHeight: "2px",
  },
  para2: {
    flexDirection: "row",
    flexWrap: "wrap",
    marginTop: 5,
    padding: 10,
  },
  column3: {
    width: "100%",
  },
  column4: {
    width: "50%",
    alignItems: "flex-end",
  },
  column5: {
    width: "50%",
  },
  columnsig: {
    width: "50%",
    alignItems: "center",
  },
  table: {
    display: "flex",
    width: "auto",
    borderStyle: "solid",
    borderWidth: 1,
    borderRightWidth: 0,
    borderBottomWidth: 0,
    margin: 15,
  },
  tableRow: {
    margin: "auto",
    flexDirection: "row",
  },
  tableCol: {
    width: "25%",
    borderStyle: "solid",
    borderWidth: 1,
    borderLeftWidth: 0,
    borderTopWidth: 0,
  },
  tableCell: {
    marginLeft: 5,
    fontSize: 10,
    textAlign: "center",
  },
  tableCelle: {
    marginLeft: 5,
    padding: "6px",
    fontSize: 10,
  },
  tableCellh: {
    fontweight: "180%",
    textAlign: "center",
    fontSize: 12,
    textTransform: "uppercase",
  },
  line: {
    borderBottom: "1px solid black",
    margin: 5,
  },
});

interface SalaryCalculations {
  absentDaysDeduction: number | string;
  finalTotalDeduction: number | string;
  totalEarnings: number | string;
  netPay: number | string;
}

interface SalaryProps {
  item: Record<string, any>;
}

const SalarySlip: FC<SalaryProps> = ({ item }) => {
  const {
    offeredSalary,
    day_of_pay,
    daysInMonth,
    totalDeduction,
    commission,
    incentive,
    year,
    month,
    name,
    empId,
    designation,
    basicSalary,
    pTax,
    hra,
    mobileCharge,
    conveyance,
    allowances,
  } = item;

  let date = new Date();
  let day = date.getDate();
  let currentMonth = date.getMonth() + 1;
  let currentYear = date.getFullYear();
  let currentDate = `${day}-${currentMonth}-${currentYear}`;

  const calculatedValues: SalaryCalculations = useMemo(
    () => salaryCalculations(),
    [item]
  );

  function convertToLetters(number: any) {
    const units = [
      "",
      "One",
      "Two",
      "Three",
      "Four",
      "Five",
      "Six",
      "Seven",
      "Eight",
      "Nine",
    ];
    const teens = [
      "Ten",
      "Eleven",
      "Twelve",
      "Thirteen",
      "Fourteen",
      "Fifteen",
      "Sixteen",
      "Seventeen",
      "Eighteen",
      "Nineteen",
    ];
    const tens = [
      "",
      "",
      "Twenty",
      "Thirty",
      "Forty",
      "Fifty",
      "Sixty",
      "Seventy",
      "Eighty",
      "Ninety",
    ];

    if (number === 0) {
      return "Zero";
    }

    let result = "";

    if (number >= 1000) {
      result += convertToLetters(Math.floor(number / 1000)) + " Thousand ";
      number %= 1000;
    }

    if (number >= 100) {
      result += convertToLetters(Math.floor(number / 100)) + " Hundred ";
      number %= 100;
    }

    if (number >= 20) {
      result += tens[Math.floor(number / 10)] + " ";
      number %= 10;
    } else if (number >= 10) {
      result += teens[number - 10] + " ";
      number = 0;
    }

    if (number > 0) {
      result += units[number] + " ";
    }

    return result.trim();
  }

  function salaryCalculations(): SalaryCalculations {
    let absentDaysDeduction = (
      ((Number(daysInMonth) - Number(day_of_pay)) * Number(offeredSalary)) /
      Number(daysInMonth)
    ).toFixed(2);

    let finalTotalDeduction = (
      Number(totalDeduction) + Number(absentDaysDeduction)
    ).toFixed(2);

    let totalEarnings = (
      Number(commission) +
      Number(incentive) +
      Number(offeredSalary)
    ).toFixed(2);

    let netPay = (Number(totalEarnings) - Number(finalTotalDeduction)).toFixed(
      2
    );

    const calculationObject: SalaryCalculations = {
      absentDaysDeduction,
      finalTotalDeduction,
      totalEarnings,
      netPay,
    };

    return calculationObject;
  }

  const number = Number(Number(calculatedValues.netPay).toFixed(0));
  const result = convertToLetters(number);

  return (
    <Document>
      <Page size="A4">
        <View style={styles.para1}>
          <View style={styles.column7}>
            <Text style={styles.textHeading}>
              PRERNA ACADEMY PVT. LTD, INDORE
            </Text>
          </View>

          <View style={styles.column2}>
            <Text style={styles.text}>
              Corporate Office :- 82 Swami Vivekanand Nagar Telephone Square
              Kanadia Road Indore
            </Text>
            <Text style={styles.text}>(M.P) pin 452018</Text>
          </View>

          <View style={styles.column2}>
            <Text style={styles.text}>
              Phone Number :- (+91) 835 889 9900, (0731) 4298599
            </Text>
            <Text style={styles.text}>Toll Free No : 1800120-224466</Text>
            <Text style={styles.text}>Email:- info@prernaacademy.in</Text>
            <Text style={styles.text}>Website:- www.prernaacademy.in</Text>
          </View>
          <View style={styles.column1}>
            <Image
              style={styles.image}
              src="https://ik.imagekit.io/nwssoft/AboutUS-College-Logo/Logo/New_Logo_with_Crop/prena_acedemy_logo.jfif?updatedAt=1685344751380"
            />
          </View>
        </View>
        <View style={styles.line} />
        <View>
          <Text style={styles.text1}>
            Pay Slip For {year} - {month}
          </Text>
        </View>
        <View style={styles.para2}>
          <View style={styles.column5}>
            <Text style={styles.text2}>Employee Name:{name}</Text>
          </View>
          <View style={styles.column3}>
            <Text style={styles.text2}>Date:{currentDate}</Text>
          </View>

          <View style={styles.column3}>
            <Text style={styles.text2}>Employee Id: {empId}</Text>
          </View>

          <View style={styles.column3}>
            <Text style={styles.text2}>Designation: {designation}</Text>
          </View>
        </View>

        <View style={styles.table}>
          <View style={styles.tableRow}>
            <View style={styles.tableCol}>
              <Text style={styles.tableCellh}>Earnings</Text>
            </View>
            <View style={styles.tableCol}>
              <Text style={styles.tableCellh}>Amount</Text>
            </View>
            <View style={styles.tableCol}>
              <Text style={styles.tableCellh}>Deductions</Text>
            </View>
            <View style={styles.tableCol}>
              <Text style={styles.tableCellh}>Amount</Text>
            </View>
          </View>
          <View style={styles.tableRow}>
            <View style={styles.tableCol}>
              <Text style={styles.tableCell}>Basic Salary</Text>
            </View>
            <View style={styles.tableCol}>
              <Text style={styles.tableCell}>{basicSalary}</Text>
            </View>
            <View style={styles.tableCol}>
              <Text style={styles.tableCell}>Professional Tax</Text>
            </View>
            <View style={styles.tableCol}>
              <Text style={styles.tableCell}>{pTax}</Text>
            </View>
          </View>
          <View style={styles.tableRow}>
            <View style={styles.tableCol}>
              <Text style={styles.tableCell}>HRA</Text>
            </View>
            <View style={styles.tableCol}>
              <Text style={styles.tableCell}>{hra}</Text>
            </View>
            <View style={styles.tableCol}>
              <Text style={styles.tableCell}>Mobile Charge</Text>
            </View>
            <View style={styles.tableCol}>
              <Text style={styles.tableCell}>{mobileCharge}</Text>
            </View>
          </View>

          <View style={styles.tableRow}>
            <View style={styles.tableCol}>
              <Text style={styles.tableCell}>Conveyance</Text>
            </View>
            <View style={styles.tableCol}>
              <Text style={styles.tableCell}>{conveyance}</Text>
            </View>
            <View style={styles.tableCol}>
              <Text style={styles.tableCell}>Deducted Salary</Text>
            </View>
            <View style={styles.tableCol}>
              <Text style={styles.tableCell}>
                {calculatedValues.absentDaysDeduction}
              </Text>
            </View>
          </View>

          <View style={styles.tableRow}>
            <View style={styles.tableCol}>
              <Text style={styles.tableCell}>Allowance</Text>
            </View>
            <View style={styles.tableCol}>
              <Text style={styles.tableCell}>{allowances}</Text>
            </View>
            <View style={styles.tableCol}>
              <Text style={styles.tableCell}></Text>
            </View>
            <View style={styles.tableCol}>
              <Text style={styles.tableCell}></Text>
            </View>
          </View>

          <View style={styles.tableRow}>
            <View style={styles.tableCol}>
              <Text style={styles.tableCell}>Offered Salary</Text>
            </View>
            <View style={styles.tableCol}>
              <Text style={styles.tableCell}>{offeredSalary}</Text>
            </View>
            <View style={styles.tableCol}>
              <Text style={styles.tableCell}>Total Deduction</Text>
            </View>
            <View style={styles.tableCol}>
              <Text style={styles.tableCell}>
                {calculatedValues.finalTotalDeduction}
              </Text>
            </View>
          </View>
        </View>
        <View style={styles.table}>
          <View style={styles.tableRow}>
            <View style={styles.tableCol}>
              <Text style={styles.tableCell}>Commission</Text>
            </View>
            <View style={styles.tableCol}>
              <Text style={styles.tableCell}>{commission}</Text>
            </View>
            <View style={styles.tableCol}>
              <Text style={styles.tableCell}></Text>
            </View>
            <View style={styles.tableCol}>
              <Text style={styles.tableCell}></Text>
            </View>
          </View>

          <View style={styles.tableRow}>
            <View style={styles.tableCol}>
              <Text style={styles.tableCell}>Incentive</Text>
            </View>
            <View style={styles.tableCol}>
              <Text style={styles.tableCell}>{incentive}</Text>
            </View>
            <View style={styles.tableCol}>
              <Text style={styles.tableCell}></Text>
            </View>
            <View style={styles.tableCol}>
              <Text style={styles.tableCell}></Text>
            </View>
          </View>

          <View style={styles.tableRow}>
            <View style={styles.tableCol}>
              <Text style={styles.tableCell}>Total Earning</Text>
            </View>
            <View style={styles.tableCol}>
              <Text style={styles.tableCell}>
                {calculatedValues.totalEarnings}
              </Text>
            </View>
            <View style={styles.tableCol}>
              <Text style={styles.tableCell}></Text>
            </View>
            <View style={styles.tableCol}>
              <Text style={styles.tableCell}></Text>
            </View>
          </View>

          <View style={styles.tableRow}>
            <View style={styles.tableCol}>
              <Text style={styles.tableCell}>Net Pay</Text>
            </View>
            <View style={styles.tableCol}>
              <Text style={styles.tableCell}>{calculatedValues.netPay}</Text>
            </View>
            <View style={styles.tableCol}>
              <Text style={styles.tableCell}>Days Of Pay</Text>
            </View>
            <View style={styles.tableCol}>
              <Text style={styles.tableCell}>{day_of_pay}</Text>
            </View>
          </View>
        </View>

        <View style={styles.para2}>
          <Text style={styles.text}>Net Pay :- {result + " " + "Only."}</Text>
        </View>
        <View style={styles.para2}>
          <View style={styles.column3}>
            <Text style={styles.text}>
              Note :- This is system generated payslip does not required seal
              and signature
            </Text>
          </View>
        </View>
      </Page>
    </Document>
  );
};

export default SalarySlip;
