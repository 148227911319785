import React, { useEffect, useState } from "react";
import Button from "@mui/material/Button";
import {
  Container,
  Paper,
  TableContainer,
  Table,
  TableHead,
  TableBody,
  TableRow,
  TableCell,
  Grid,
  Typography,
  TablePagination,
  Stack,
  Tooltip,
  IconButton,
} from "@mui/material";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";
import DeleteIcon from "@mui/icons-material/Delete";
import CloudDownloadIcon from "@mui/icons-material/CloudDownload";
import { deleteRequest, getRequest } from "../../api/api";
import { DELETE_RESULT, GET_RESULT } from "../../api/server";

function ResultTable(props: any) {
  const { types } = props;

  const [users, setUsers] = useState<any[]>([]);
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [users1, setUsers1] = useState<any[]>([]);
  const [open, setOpen] = React.useState(false);

  const handleChangePage = (event: any, newPage: number) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event: any) => {
    setRowsPerPage(+event.target.value);
    setPage(0);
  };

  const handleClickOpen = (id: any) => {
    try {
      setOpen(true);
      setUsers1(id);
    } catch (error) {
      console.error("Error in handleClickOpen:", error);
    }
  };

  const handleClose = () => {
    try {
      setOpen(false);
    } catch (error) {
      console.error("Error in handleClose:", error);
    }
  };

  const loadUser = async () => {
    try {
      const res = await getRequest(GET_RESULT, "");
      if (res) {
        setUsers(res.data);
      }
    } catch (error) {
      console.error("Error in loadUser:", error);
    }
  };

  const deleteData = async (id: any) => {
    try {
      const res = await deleteRequest(DELETE_RESULT, id, "");
      if (res) {
        setUsers(res.data);
        setOpen(false);
      }
    } catch (error) {
      console.error("Error in deleteData:", error);
    }
  };

  useEffect(() => {
    loadUser();
  }, []);

  return (
    <Container>
      <Paper elevation={20}>
        <Grid
          container
          sx={{
            p: 2,
            background: "#0288d1",
            color: "white",
          }}
        >
          <Grid>
            <Typography variant="h5" sx={{ mx: 3 }}>
              Manage Result
            </Typography>
          </Grid>
        </Grid>
        <TableContainer className="scrollBarCss">
          <Table>
            <TableHead>
              <TableRow>
                <TableCell
                  sx={{
                    textAlign: "center",
                    border: "1px solid #ddd",
                    fontSize: "15px",
                  }}
                >
                  Sr.No
                </TableCell>
                <TableCell
                  sx={{
                    textAlign: "center",
                    border: "1px solid #ddd",
                    fontSize: "15px",
                  }}
                >
                  Title
                </TableCell>
                <TableCell
                  sx={{
                    textAlign: "center",
                    border: "1px solid #ddd",
                    fontSize: "15px",
                  }}
                >
                  University
                </TableCell>
                <TableCell
                  sx={{
                    textAlign: "center",
                    border: "1px solid #ddd",
                    fontSize: "15px",
                  }}
                >
                  Courses
                </TableCell>
                <TableCell
                  sx={{
                    textAlign: "center",
                    border: "1px solid #ddd",
                    fontSize: "15px",
                  }}
                >
                  Examination Date
                </TableCell>
                <TableCell
                  sx={{
                    textAlign: "center",
                    border: "1px solid #ddd",
                    fontSize: "15px",
                  }}
                >
                  Examination Sem
                </TableCell>

                <TableCell
                  sx={{
                    textAlign: "center",
                    border: "1px solid #ddd",
                    fontSize: "15px",
                  }}
                >
                  Course Type
                </TableCell>
                <TableCell
                  sx={{
                    textAlign: "center",
                    border: "1px solid #ddd",
                    fontSize: "15px",
                  }}
                >
                  Departments
                </TableCell>
                <TableCell
                  sx={{
                    textAlign: "center",
                    border: "1px solid #ddd",
                    fontSize: "15px",
                  }}
                >
                  Specification
                </TableCell>

                <TableCell
                  sx={{
                    textAlign: "center",
                    border: "1px solid #ddd",
                    fontSize: "15px",
                  }}
                >
                  Paper Time
                </TableCell>
                <TableCell
                  sx={{
                    textAlign: "center",
                    border: "1px solid #ddd",
                    fontSize: "15px",
                  }}
                >
                  Paper Type
                </TableCell>
                <TableCell
                  sx={{
                    textAlign: "center",
                    border: "1px solid #ddd",
                    fontSize: "15px",
                  }}
                >
                  Start Date
                </TableCell>
                <TableCell
                  sx={{
                    textAlign: "center",
                    border: "1px solid #ddd",
                    fontSize: "15px",
                  }}
                >
                  End Date
                </TableCell>
                <TableCell
                  sx={{
                    textAlign: "center",
                    border: "1px solid #ddd",
                    fontSize: "15px",
                  }}
                >
                  Action
                </TableCell>
              </TableRow>
            </TableHead>

            <TableBody>
              {users
                .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                .map((item: any, index: any) => (
                  <React.Fragment key={item._id}>
                    <TableRow key={item._id}>
                      <TableCell
                        sx={{
                          textAlign: "center",
                          border: "1px solid #ddd",
                        }}
                      >
                        {page * rowsPerPage + index + 1}
                      </TableCell>
                      <TableCell
                        sx={{
                          textAlign: "center",
                          border: "1px solid #ddd",
                        }}
                      >
                        {item.resultTitle}
                      </TableCell>
                      <TableCell
                        sx={{
                          textAlign: "center",
                          border: "1px solid #ddd",
                        }}
                      >
                        {item.university}
                      </TableCell>
                      <TableCell
                        sx={{
                          textAlign: "center",
                          border: "1px solid #ddd",
                        }}
                      >
                        {item.course}
                      </TableCell>

                      <TableCell
                        sx={{
                          textAlign: "center",
                          border: "1px solid #ddd",
                        }}
                      >
                        {item.examinationDate}
                      </TableCell>

                      <TableCell
                        sx={{
                          textAlign: "center",
                          border: "1px solid #ddd",
                        }}
                      >
                        {item.noOfYearorSems}
                      </TableCell>
                      <TableCell
                        sx={{
                          textAlign: "center",
                          border: "1px solid #ddd",
                        }}
                      >
                        {item.course}
                      </TableCell>
                      <TableCell
                        sx={{
                          textAlign: "center",
                          border: "1px solid #ddd",
                        }}
                      >
                        {item.department}
                      </TableCell>
                      <TableCell
                        sx={{
                          textAlign: "center",
                          border: "1px solid #ddd",
                        }}
                      >
                        {item.specialization}
                      </TableCell>

                      <TableCell
                        sx={{
                          textAlign: "center",
                          border: "1px solid #ddd",
                        }}
                      >
                        {item.paperTime}
                      </TableCell>
                      <TableCell
                        sx={{
                          textAlign: "center",
                          border: "1px solid #ddd",
                        }}
                      >
                        {item.paperType}
                      </TableCell>
                      <TableCell
                        sx={{
                          textAlign: "center",
                          border: "1px solid #ddd",
                        }}
                      >
                        {item.viewStartDate}
                      </TableCell>
                      <TableCell
                        sx={{
                          textAlign: "center",
                          border: "1px solid #ddd",
                        }}
                      >
                        {item.viewEndDate}
                      </TableCell>
                      <TableCell
                        sx={{
                          textAlign: "center",
                          border: "1px solid #ddd",
                        }}
                      >
                        <Stack direction="row">
                          <Tooltip title="Download Result" arrow>
                            <IconButton
                              href={item?.resultDoc?.path}
                              aria-label="download"
                            >
                              <CloudDownloadIcon sx={{ color: "#0288d1" }} />
                            </IconButton>
                          </Tooltip>
                          {types.type === "Administrator" && (
                            <>
                              <Tooltip title="Delete Result" arrow>
                                <IconButton
                                  onClick={() => handleClickOpen(item._id)}
                                >
                                  <DeleteIcon
                                    sx={{ fontSize: "25px", color: "red" }}
                                  />
                                </IconButton>
                              </Tooltip>
                              <Dialog
                                open={open}
                                onClose={handleClose}
                                aria-describedby="alert-dialog-slide-description"
                              >
                                <DialogTitle>{"DELETE"}</DialogTitle>
                                <DialogContent>
                                  <DialogContentText id="alert-dialog-slide-description">
                                    Are you sure you want to delete this?
                                  </DialogContentText>
                                </DialogContent>
                                <DialogActions>
                                  <Button onClick={() => deleteData(users1)}>
                                    Yes
                                  </Button>
                                  <Button onClick={handleClose}>No</Button>
                                </DialogActions>
                              </Dialog>
                            </>
                          )}
                        </Stack>
                      </TableCell>
                    </TableRow>
                  </React.Fragment>
                ))}
            </TableBody>
          </Table>
        </TableContainer>
        <TablePagination
          rowsPerPageOptions={[10, 25, 50, 100]}
          component="div"
          count={users.length}
          rowsPerPage={rowsPerPage}
          page={page}
          onPageChange={handleChangePage}
          onRowsPerPageChange={handleChangeRowsPerPage}
        />
      </Paper>
    </Container>
  );
}

export default ResultTable;
