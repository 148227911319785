import { LOGIN, LOGOUT } from "../action";

export const loginInitialState = {
  login: false,
  isAuthenticated: false,
  userToken: "",
};

const LoginReducer = (state = loginInitialState, action: any) => {
  switch (action.type) {
    case LOGIN:
      return {
        ...state,
        login: action.login,
        isAuthenticated: action.login,
        userToken: action.userToken,
      };

    case LOGOUT:
      return {
        ...state,
        login: action.login,
        isAuthenticated: action.login,
        userToken: "",
      };

    default:
      return { ...state };
  }
};

export default LoginReducer;
