import CloudDownloadIcon from "@mui/icons-material/CloudDownload";
import {
  Box,
  Container,
  Grid,
  IconButton,
  MenuItem,
  Paper,
  Select,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TablePagination,
  TableRow,
  TextField,
  Tooltip,
  Typography,
} from "@mui/material";
import Button from "@mui/material/Button";
import Checkbox from "@mui/material/Checkbox";
import React, { useEffect, useState } from "react";
import CommonSnackBar from "../../Components/Common/CommonSnackbar";
import "../../Components/Home/Home.scss";
import { getRequest, updateRequest } from "../../api/api";
import { GET_LEAVE_DATA, UPDATE_LEAVE } from "../../api/server";

interface UserState {
  _id: string;
  empID: string;
  name: string;
  fromDate: string;
  toDate: string;
  reasonForLeave: string;
  createdAt: string;
  action: string;
  remark: string;
  uploadFile: object;
  isDisabledRemark: boolean;
  isDisabledAction: boolean;
  isChecked: boolean;
}

function ManageLeave() {
  const [snackMessage, setSnackMessage] = useState("");
  const [openSnackBar, setOpenSnackBar] = useState(false);
  const [page, setPage] = React.useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [users, setUsers] = useState<any[]>([]);
  const [selectedUsers, setSelectedUsers] = useState<any[]>([]);

  const defaultUserState: UserState = {
    _id: "",
    empID: "",
    name: "",
    fromDate: "",
    toDate: "",
    reasonForLeave: "",
    createdAt: "",
    action: "",
    remark: "",
    uploadFile: {},
    isDisabledRemark: true,
    isDisabledAction: true,
    isChecked: false,
  };

  // for default user state maintaining
  const [originalUsers, setOriginalUsers] = useState<UserState[]>([
    defaultUserState,
  ]);

  //function for getting leave data
  const loadUser = async () => {
    const res = await getRequest(GET_LEAVE_DATA, "");
    if (res) {
      const userData = res.data.map((user: any) => ({
        ...user,
        isDisabledRemark: true,
        isDisabledAction: true,
        isChecked: false,
      }));
      setUsers(userData);
      setOriginalUsers(userData);
    }
  };

  const handleOpenSnackBar = (message: string) => {
    setSnackMessage(message);
    setOpenSnackBar(true);
  };

  const handleCloseSnackBar = () => {
    try {
      setOpenSnackBar(false);
    } catch (error) {
      console.error("Error in handleCloseSnackBar:", error);
    }
  };

  const filterAction = (e: any) => {
    if (e === "All") {
      setUsers(originalUsers);
    } else {
      const filterActionData = originalUsers.filter(
        (item) => item.action === e
      );
      setUsers(filterActionData);
    }
  };

  //function for updating leave status data inside map
  const actionDone = (e: any, id?: any) => {
    e.preventDefault();
    if (e.target.name === "check") {
      // Checkbox is checked or unchecked
      const isChecked = e.target.checked;

      setSelectedUsers((prevSelectedUsers) => {
        // Update the selected users based on the checkbox state
        if (isChecked) {
          return [...prevSelectedUsers, { _id: id }];
        } else {
          return prevSelectedUsers.filter((user) => user._id !== id);
        }
      });

      // Update the disabled state for the specific record
      setUsers((prevUsers) =>
        prevUsers.map((user) => {
          if (user._id === id) {
            return {
              ...user,
              isChecked: !user.isChecked,
              isDisabledRemark: !isChecked,
              isDisabledAction: !isChecked,
            };
          }
          return user;
        })
      );
    } else {
      // Handle changes to Remark and Action fields
      setSelectedUsers((prevSelectedUsers) =>
        prevSelectedUsers.map((user) => {
          if (user._id === id) {
            return {
              ...user,
              [e.target.name]: e.target.value,
            };
          }
          return user;
        })
      );
    }
  };

  const handleChangePage = (event: any, newPage: any) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event: any) => {
    setRowsPerPage(+event.target.value);
    setPage(0);
  };

  //function to update data after submit
  const SendDataToAPI = async () => {
    if (selectedUsers.length > 0) {
      updateRequest(UPDATE_LEAVE, "", selectedUsers, "").then(
        async (res: any) => {
          let message = "All data is updated!";
          res.data?.some((obj: any) => {
            if (obj.modifiedCount === 0) {
              message = "All data is not updated!";
              return true;
            }
          });
          handleOpenSnackBar(message);
          await loadUser();
        }
      );
    } else {
      handleOpenSnackBar("No User Updated!");
    }
  };

  useEffect(() => {
    loadUser();
  }, []);

  return (
    <Container>
      <Paper elevation={20}>
        <Grid
          container
          sx={{
            p: 2,
            background: "#0288d1",
            color: "white",
          }}
        >
          <Grid item xs={8}>
            <Typography variant="h5" sx={{ mx: 3 }}>
              Leave Detail
            </Typography>
          </Grid>
          <Grid
            item
            xs={4}
            sx={{
              display: "flex",
              justifyContent: "space-evenly",
              alignItems: "center",
            }}
          >
            <Typography variant="h6">Filter By:</Typography>
            <Select
              id="demo-select-small"
              name="action"
              size="small"
              sx={{
                width: "60%",
                textAlign: "start",
                background: "white",
              }}
              onChange={(event: any) => {
                const selectedValue = event.target.value;
                filterAction(selectedValue);
              }}
            >
              <MenuItem value={"All"}>All</MenuItem>
              <MenuItem value={"Pending"}>Pending</MenuItem>
              <MenuItem value={"Approve"}>Approve</MenuItem>
              <MenuItem value={"Reject"}>Reject</MenuItem>
            </Select>
          </Grid>
        </Grid>
        <TableContainer component="form" className="scrollBarCss">
          <Table>
            <TableHead>
              <TableRow>
                <TableCell
                  sx={{
                    textAlign: "center",
                    border: "1px solid #ddd",
                    fontSize: "15px",
                  }}
                >
                  Sr.No
                </TableCell>
                <TableCell
                  sx={{
                    textAlign: "center",
                    border: "1px solid #ddd",
                    fontSize: "15px",
                  }}
                >
                  Select
                </TableCell>
                <TableCell
                  sx={{
                    textAlign: "center",
                    border: "1px solid #ddd",
                    fontSize: "15px",
                  }}
                >
                  Emp ID
                </TableCell>
                <TableCell
                  sx={{
                    textAlign: "center",
                    border: "1px solid #ddd",
                    fontSize: "15px",
                  }}
                >
                  Name
                </TableCell>
                <TableCell
                  sx={{
                    textAlign: "center",
                    border: "1px solid #ddd",
                    fontSize: "15px",
                  }}
                >
                  From
                </TableCell>
                <TableCell
                  sx={{
                    textAlign: "center",
                    border: "1px solid #ddd",
                    fontSize: "15px",
                  }}
                >
                  To
                </TableCell>
                <TableCell
                  sx={{
                    textAlign: "center",
                    border: "1px solid #ddd",
                    fontSize: "15px",
                  }}
                >
                  Reason
                </TableCell>
                <TableCell
                  sx={{
                    textAlign: "center",
                    border: "1px solid #ddd",
                    fontSize: "15px",
                  }}
                >
                  Applied
                </TableCell>
                <TableCell
                  sx={{
                    textAlign: "center",
                    border: "1px solid #ddd",
                    fontSize: "15px",
                  }}
                >
                  Comment/Remark
                </TableCell>
                <TableCell
                  sx={{
                    textAlign: "center",
                    border: "1px solid #ddd",
                    fontSize: "15px",
                  }}
                >
                  Action
                </TableCell>
                <TableCell
                  sx={{
                    textAlign: "center",
                    border: "1px solid #ddd",
                    fontSize: "15px",
                  }}
                >
                  File
                </TableCell>
              </TableRow>
            </TableHead>

            <TableBody>
              {users
                .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                .reverse()
                .map((item: any, index: number) => (
                  <TableRow key={item._id}>
                    <TableCell
                      sx={{
                        textAlign: "center",
                        border: "1px solid #ddd",
                      }}
                    >
                      {page * rowsPerPage + index + 1}
                    </TableCell>
                    <TableCell
                      sx={{
                        textAlign: "center",
                        border: "1px solid #ddd",
                      }}
                    >
                      <Checkbox
                        name="check"
                        checked={item.isChecked}
                        onChange={(e) => actionDone(e, item._id)}
                      />
                    </TableCell>
                    <TableCell
                      sx={{
                        textAlign: "center",
                        border: "1px solid #ddd",
                      }}
                    >
                      {item.exEmpId
                        ? item.exEmpId
                        : item.tlEmpId
                        ? item.tlEmpId
                        : item.chEmpId}
                    </TableCell>
                    <TableCell
                      sx={{
                        textAlign: "center",
                        border: "1px solid #ddd",
                      }}
                    >
                      {item.exFirstName
                        ? `${item.exFirstName} ${item.exLastName}`
                        : item.tlFirstName
                        ? `${item.tlFirstName} ${item.tlLastName}`
                        : `${item.chFirstName} ${item.chLastName}`}
                    </TableCell>
                    <TableCell
                      sx={{
                        textAlign: "center",
                        border: "1px solid #ddd",
                      }}
                    >
                      {item.fromDate}
                    </TableCell>
                    <TableCell
                      sx={{
                        textAlign: "center",
                        border: "1px solid #ddd",
                      }}
                    >
                      {item.toDate}
                    </TableCell>
                    <TableCell
                      sx={{
                        textAlign: "center",
                        border: "1px solid #ddd",
                      }}
                    >
                      {item.reasonForLeave}
                    </TableCell>
                    <TableCell
                      sx={{
                        textAlign: "center",
                        border: "1px solid #ddd",
                      }}
                    >
                      {item.createdAt}
                    </TableCell>
                    <TableCell
                      sx={{
                        textAlign: "center",
                        border: "1px solid #ddd",
                      }}
                    >
                      <TextField
                        name="remark"
                        disabled={item.isDisabledRemark}
                        onChange={(e) => actionDone(e, item._id)}
                        sx={{ background: "white" }}
                        defaultValue={item.remark}
                      ></TextField>
                    </TableCell>
                    <TableCell
                      sx={{
                        textAlign: "center",
                        border: "1px solid #ddd",
                      }}
                    >
                      <Select
                        labelId="demo-select-small"
                        id="demo-select-small"
                        name="action"
                        disabled={item.isDisabledAction}
                        defaultValue={item.action}
                        onChange={(e) => actionDone(e, item._id)}
                      >
                        <MenuItem value={"Reject"}>Reject</MenuItem>
                        <MenuItem value={"Approve"}>Approve</MenuItem>
                        <MenuItem value={"Pending"}>Pending</MenuItem>
                      </Select>
                    </TableCell>
                    <TableCell
                      sx={{
                        textAlign: "center",
                        border: "1px solid #ddd",
                      }}
                    >
                      <Tooltip title="Download File" arrow>
                        <IconButton
                          href={item?.uploadFile?.path}
                          aria-label="download"
                        >
                          <CloudDownloadIcon sx={{ color: "#0288d1" }} />
                        </IconButton>
                      </Tooltip>
                    </TableCell>
                  </TableRow>
                ))}
            </TableBody>
          </Table>
        </TableContainer>
        <TablePagination
          rowsPerPageOptions={[10, 25, 50, 100]}
          component="div"
          count={users.length}
          rowsPerPage={rowsPerPage}
          page={page}
          onPageChange={handleChangePage}
          onRowsPerPageChange={handleChangeRowsPerPage}
        ></TablePagination>
        <Box display="flex" justifyContent="center">
          <Button
            variant="contained"
            sx={{ marginBottom: "10px" }}
            onClick={SendDataToAPI}
          >
            Submit
          </Button>
          <CommonSnackBar
            open={openSnackBar}
            autoHideDuration={3000}
            onClose={handleCloseSnackBar}
            message={snackMessage}
          />
        </Box>
      </Paper>
    </Container>
  );
}

export default ManageLeave;
