import Grid from "@mui/material/Grid";
import Box from "@mui/material/Box";
import {
  Stack,
  Typography,
  Container,
  ImageList,
  ImageListItem,
} from "@mui/material";
import Button from "@mui/material/Button";
import ArrowForwardIcon from "@mui/icons-material/ArrowForward";
import SchoolIcon from "@mui/icons-material/School";
import { useNavigate } from "react-router-dom";

export default function About() {
  const navigate = useNavigate();
  return (
    <>
      <Container maxWidth="lg" sx={{ my: 2 }}>
        <Box>
          <Stack direction="row" spacing={2} sx={{ textAlign: "start" }}>
            <SchoolIcon sx={{ color: "blue" }} />
            <Typography
              variant="h6"
              sx={{ color: "#191970", fontWeight: "bold" }}
            >
              About Our University
            </Typography>
          </Stack>
          <Typography
            variant="h4"
            sx={{
              color: "#191970",
              fontWeight: "500",
              marginTop: 1,
              textAlign: "start",
            }}
          >
            A Few Words About the University
          </Typography>

          <Grid container columnSpacing={10}>
            <Grid item xs={12} sm={12} md={6}>
              <ImageList sx={{ width: "100%", height: "100%" }} cols={1}>
                <ImageListItem>
                  <img
                    src="https://ik.imagekit.io/nwssoft/prernaacademy_project/7618724.jpg?updatedAt=1678362904094"
                    loading="lazy"
                    alt="About university"
                  />
                </ImageListItem>
              </ImageList>
            </Grid>
            <Grid item xs={12} sm={12} md={6} marginTop={5}>
              <Typography
                variant="subtitle2"
                sx={{ color: "#666", textAlign: "justify", mt: 1 }}
              >
                Prerna Academy focuses on improving every element that enables
                one to stand tall in society.  Some of these include student
                involvement, cognitive engagement, behavioural engagement,
                student confidence, and the delivery of precise instructions.
                Prerna Academy places the highest emphasis on education.
                Programs that motivate children to pursue education and raise
                the bar of education have the potential to change the state of
                development and prosperity. Prerna Academy makes an effort to
                give students a straightforward approach, transparent activities
                and classes, and course completion without difficulties. The
                purpose of the research is to examine the intrinsic learning
                motivations of students with various attitudes towards distance
                learning. It will give you a positive perspective on life and
                serve as the foundation of social and economic growth as an
                educational system and provider. It exhibits individual's
                eagerness to learn new abilities and to keep evolving and
                expanding.
              </Typography>

              <Grid item xs={12} md={6} sx={{ textAlign: "left", mt: 2 }}>
                <Button
                  variant="contained"
                  sx={{
                    textTransform: "capitalize",
                    fontSize: "1rem",
                  }}
                  onClick={()=>{
                    navigate("/aboutus");
                    window.scroll(0,0);
                  }}
                >
                  Read More <ArrowForwardIcon />
                </Button>
              </Grid>
            </Grid>
          </Grid>
        </Box>
      </Container>
    </>
  );
}
