
import React from 'react' ;
import Box from "@mui/material/Box";
import InputLabel from "@mui/material/InputLabel";
import MenuItem from "@mui/material/MenuItem";
import FormControl from "@mui/material/FormControl";
import Select from "@mui/material/Select";

export default function Dropdown(props: any) {

return (
    <Box sx={{ minWidth: 120 }}>
      <FormControl fullWidth>
        <InputLabel id="demo-simple-select-label">{props.label}</InputLabel>
        <Select
          labelId="demo-simple-select-label"
          name={props.name}
          id="demo-simple-select"
          label={props.label}
          value={props.currentState}
          onChange={props.handleChange}
          required
        >
          {props.data?.map((item: any) => (
            <MenuItem value={item.id} key={item._id}>
              {item.id}
            </MenuItem>
          ))}
        </Select>
      </FormControl>
    </Box>
  );
}
