import React, { useState, useEffect } from "react";
import CommonSnackBar from "../../Components/Common/CommonSnackbar";
import {
  Button,
  Container,
  FormControl,
  Grid,
  IconButton,
  InputAdornment,
  InputLabel,
  LinearProgress,
  MenuItem,
  Select,
  Stack,
  TablePagination,
  TextField,
  Typography,
  Collapse,
  Paper,
  TableContainer,
  TableRow,
  TableCell,
  TableBody,
  Table,
  TableHead,
  Checkbox,
} from "@mui/material";
import SearchIcon from "@mui/icons-material/Search";
import "./ManageUnAssignLead.scss";
import { getRequest, updateRequest } from "../../api/api";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import {
  ADMIN_ASSIGN_LEAD,
  CENTERHEAD_LIST,
  EXECUTIVE_LIST,
  GET_LEAD,
  TEAMLEAD_LIST,
} from "../../api/server";
import "../../Components/Home/Home.scss";
import TableToExcel from "../../Components/Common/TableToExcel";

const columns: any = [
  { id: 1, field: "fname", headerName: "First Name" },
  { id: 2, field: "lname", headerName: "Last Name" },
  { id: 3, field: "email", headerName: "Email" },
  { id: 4, field: "phone", headerName: "Phone" },
  { id: 5, field: "refBy", headerName: "Reference By" },
  { id: 6, field: "refName", headerName: "Reference Name" },
];

export default function ManageUnAssignLead() {
  // for lead data
  const [snackMessage, setSnackMessage] = useState("");
  const [openSnackBar, setOpenSnackBar] = useState(false);

  // state to maintain lead data
  const [LeadData, setLeadData] = useState<any[]>([]);

  // state to manage searched data
  const [searchApiData, setSearchApiData] = useState<any[]>([]);

  // for Center Head List
  const [centerHeadList, setCenterHeadList] = useState<any[]>([]);
  const [centerHead, setCenterHead] = useState("");

  // for Team Lead List
  const [teamLeadList, setTeamLeadList] = useState<any[]>([]);
  const [teamLead, setTeamLead] = useState("");

  // for executive list
  const [executiveList, setExecutiveList] = useState<any[]>([]);
  const [executiveSetList, setExecutiveSetList] = useState<any[]>([]);
  const [executive, setExecutive] = useState("");

  // filter data
  const [filterTeamLead, setFilterTeamLead] = useState<any[]>([]);

  // for pagination
  const [page, setPage] = React.useState(0);

  const [rowsPerPage, setRowsPerPage] = React.useState(10);

  // state to manage search value
  const [filterVal, setFilterVal] = useState<string>("");

  // for progress bar
  const [isLoading, setIsLoading] = useState(false);

  // state to store maintain selected employees from dropdown
  const [executiveData, setExecutiveData] = useState({});

  const [expandedRow, setExpandedRow] = useState<string | null>(null);

  //state to maintain checked/selected leads in state
  const [selectedLeads, setSelectedLeads] = useState<string[]>([]);

  const handleOpenSnackBar = (message: string) => {
    setSnackMessage(message);
    setOpenSnackBar(true);
  };

  const handleCloseSnackBar = () => {
    setOpenSnackBar(false);
  };

  // function to handle dropdown change
  const handleExecutiveChange = (e: any) => {
    const { name, value } = e.target;
    setExecutiveData((prevState: any) => ({
      ...prevState,
      [name]: value,
    }));
  };

  const handleDataAssign = async () => {
    if (Object.keys(executiveData).length === 0) {
      handleOpenSnackBar("Select at least one employee");
      return;
    }

    if (Object.keys(selectedLeads).length === 0) {
      handleOpenSnackBar("Select at least one lead to assign");
      return;
    }

    try {
      const updateData = {
        executiveData: executiveData,
        selectedLeads: selectedLeads,
      };
      const response: any = await updateRequest(
        ADMIN_ASSIGN_LEAD,
        "",
        updateData,
        ""
      );
      if (response) {
        // Handle the response accordingly, for example, show a success message
        handleOpenSnackBar(response.data.message);
        setTeamLeadList(filterTeamLead);
        setExecutiveList(executiveSetList);
      }
      setTimeout(() => {
        getCustomers();
        setCenterHead("");
        setTeamLead("");
        setExecutive("");
      }, 3000);
    } catch (error) {
      console.error(error);
      handleOpenSnackBar("An error occurred. Please try again later.");
    }
  };

  // fetching center head list
  const displayCenterHeadList = async () => {
    let result: any = await getRequest(CENTERHEAD_LIST, "");
    setCenterHeadList(result?.data);
  };

  // fetching team lead list
  const getTeamLeadList = async () => {
    let result: any = await getRequest(TEAMLEAD_LIST, "");
    setTeamLeadList(result.data);
    setFilterTeamLead(result.data);
  };

  // fetching executive list
  const getExecutiveList = async () => {
    let result: any = await getRequest(EXECUTIVE_LIST, "");
    setExecutiveList(result?.data);
    setExecutiveSetList(result?.data);
  };

  const getCustomers = async () => {
    try {
      setIsLoading(true);
      let result: any = await getRequest(GET_LEAD, "");
      if (result) {
        setLeadData(result["data"]);
        setSearchApiData(result["data"]);
      }
      setIsLoading(false);
    } catch (error) {
      console.log("Error fetching customers: ", error);
    }
  };

  const handleChangePage = (event: any, newPage: any) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event: any) => {
    setRowsPerPage(+event.target.value);
    setPage(0);
  };

  const handleFilter = (e: any) => {
    if (e.target.value === "") {
      setLeadData(searchApiData);
    } else {
      const filterResult: any = searchApiData.filter((item: any) => {
        const searchValue = e.target.value.toLowerCase();
        return Object.values(item).some((value) => {
          if (typeof value === "string" || typeof value === "number") {
            const itemValue = String(value).toLowerCase();
            return itemValue.includes(searchValue);
          }
          return false;
        });
      });
      setLeadData(filterResult);
    }
    setFilterVal(e.target.value);
  };

  const handleCenterHeadChange = (event: any) => {
    const selectedCenterHeadId = event.target.value as string;
    setCenterHead(selectedCenterHeadId);
    const filteredTeamLeads = filterTeamLead.filter(
      (item: any) => item.centerHead === selectedCenterHeadId
    );
    setTeamLeadList(filteredTeamLeads);
    setExecutiveList([]);
  };

  const handleTeamLeadChange = (event: any) => {
    setExecutiveList(executiveSetList);
    const selectedTeamLeadName = event.target.value as string;
    setTeamLead(selectedTeamLeadName);
    const filteredExecutives = executiveSetList.filter(
      (item: any) => item.teamLead === selectedTeamLeadName
    );
    setExecutiveList(filteredExecutives);
  };

  const setSelectedExecutiveData = (event: any) => {
    const selectedExecutiveName = event.target.value as string;
    setExecutive(selectedExecutiveName);
  };

  // function for updating selected leads in state
  const handleCheckboxChange = (leadId: string) => {
    if (selectedLeads.includes(leadId)) {
      setSelectedLeads((prevSelected) =>
        prevSelected.filter((id) => id !== leadId)
      );
    } else {
      setSelectedLeads((prevSelected) => [...prevSelected, leadId]);
    }
  };

  const handleRowClick = (rowId: string) => {
    setExpandedRow((prevRowId) => (prevRowId === rowId ? null : rowId));
  };

  // Modify the tableData to include the concatenated value
  const modifiedTableData = LeadData.map((row) => ({
    ...row,
    Name: `${row.fname} ${row.lname}`,
  }));

  useEffect(() => {
    getCustomers();
    displayCenterHeadList();
    getTeamLeadList();
    getExecutiveList();
  }, []);

  return (
    <>
      <Container>
        {isLoading && <LinearProgress />}
        <Paper elevation={20}>
          <Grid container sx={{ p: 2, background: "#0288d1", color: "white" }}>
            <Grid item xs={6}>
              <Typography variant="h5" sx={{ mx: 3 }}>
                Manage Unassigned Lead
              </Typography>
            </Grid>
            <Grid item xs={3}>
              <TableToExcel
                tableData={modifiedTableData}
                fileName="Unassigned_Lead_data"
                headerMapping={{
                  Name: "Name",
                  Phone: "phone",
                  Email: "email",
                  "Reference By": "refBy",
                  "Reference Name": "refName",
                  Experience: "experience",
                  Qualification: "qualification",
                  State: "state",
                  City: "city",
                  passingYear: "passingYear",
                  "Interested Course": "interestedCourse",
                }}
              />
            </Grid>
            <Grid
              item
              xs={3}
              sx={{ display: "flex", justifyContent: "flex-end" }}
            >
              <FormControl>
                <TextField
                  label="Search"
                  size="small"
                  value={filterVal}
                  onInput={(e: any) => handleFilter(e)}
                  sx={{ mx: 3, background: "white" }}
                  InputProps={{
                    endAdornment: (
                      <InputAdornment position="end">
                        <IconButton edge="end">
                          <SearchIcon />
                        </IconButton>
                      </InputAdornment>
                    ),
                  }}
                />
              </FormControl>
            </Grid>
          </Grid>

          <Grid container rowSpacing={1} sx={{ mt: 2, mb: 3, p: 1 }}>
            <Grid
              item
              xs={6}
              sx={{
                justifyContent: "center",
                alignItems: "center",
                display: "flex",
                mb: 3,
              }}
            >
              <FormControl size="small" sx={{ width: "80%" }} required>
                <InputLabel id="demo-simple-select-required-label">
                  Center Head
                </InputLabel>
                <Select
                  labelId="demo-simple-select-required-label"
                  className="form-control"
                  name="centerHeadId"
                  type="text"
                  value={centerHead}
                  label="Department"
                  required
                  onChange={(e) => {
                    handleCenterHeadChange(e);
                    handleExecutiveChange(e);
                  }}
                  sx={{ textAlign: "center" }}
                >
                  {centerHeadList?.map((Object: any) => (
                    <MenuItem value={Object.chUniqueId} key={Object._id}>
                      {Object.firstName} {Object.lastName} ({Object.empId})
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
            </Grid>
            <Grid
              item
              xs={6}
              sx={{
                mb: 3,
              }}
            >
              <FormControl size="small" sx={{ width: "80%" }} required>
                <InputLabel id="demo-simple-select-required-label">
                  Team Lead
                </InputLabel>
                <Select
                  sx={{ textAlign: "center" }}
                  labelId="demo-simple-select-required-label"
                  className="form-control"
                  label="Department"
                  type="text"
                  value={teamLead}
                  name="teamLeadId"
                  required
                  onChange={(e) => {
                    handleTeamLeadChange(e);
                    handleExecutiveChange(e);
                  }}
                >
                  {teamLeadList?.map((Object: any) => (
                    <MenuItem
                      value={Object.tlUniqueId}
                      key={Object._id}
                      id={Object.empId}
                    >
                      {Object.firstName} {Object.lastName} ({Object.empId})
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
            </Grid>

            <Grid
              item
              xs={6}
              sx={{
                justifyContent: "center",
                alignItems: "center",
                display: "flex",
              }}
            >
              <FormControl size="small" sx={{ width: "80%" }} required>
                <InputLabel id="demo-simple-select-required-label">
                  Executive
                </InputLabel>
                <Select
                  sx={{ textAlign: "center" }}
                  labelId="demo-simple-select-required-label"
                  label="Department"
                  value={executive}
                  name="executiveId"
                  onChange={(e) => {
                    setSelectedExecutiveData(e);
                    handleExecutiveChange(e);
                  }}
                >
                  {executiveList?.map((Object: any) => (
                    <MenuItem
                      value={Object.exUniqueId}
                      key={Object._id}
                      id={Object.empId}
                    >
                      {Object.firstName} {Object.lastName} ({Object.empId})
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
            </Grid>
            <Grid item xs={6}>
              <Stack direction={"row"} spacing={11}>
                <Button
                  variant="contained"
                  color="primary"
                  type="submit"
                  onClick={() => handleDataAssign()}
                >
                  Submit
                </Button>
                <CommonSnackBar
                  open={openSnackBar}
                  autoHideDuration={5000}
                  onClose={handleCloseSnackBar}
                  message={snackMessage}
                />
              </Stack>
            </Grid>
          </Grid>

          <TableContainer className="scrollBarCss">
            {/* Table Headings */}
            <Table>
              <TableHead>
                <TableRow>
                  {/* Add an empty cell for the expand icon */}
                  <TableCell
                    sx={{
                      textAlign: "center",
                      fontWeight: "bold",
                      border: "1px solid #ddd",
                    }}
                  ></TableCell>
                  <TableCell
                    sx={{
                      textAlign: "center",
                      fontWeight: "bold",
                      border: "1px solid #ddd",
                    }}
                  >
                    Sr.No
                  </TableCell>
                  {columns.map((column: any) => (
                    <TableCell
                      key={column.id}
                      sx={{
                        textAlign: "center",
                        fontWeight: "bold",
                        border: "1px solid #ddd",
                      }}
                    >
                      {column.headerName}
                    </TableCell>
                  ))}

                  <TableCell
                    key="assignToHeader"
                    sx={{
                      textAlign: "center",
                      fontWeight: "bold",
                      border: "1px solid #ddd",
                    }}
                  >
                    Action
                  </TableCell>
                </TableRow>
              </TableHead>

              {/* Table Data Rows */}
              <TableBody>
                {LeadData.map((item: any, index) => (
                  <React.Fragment key={item._id}>
                    {/* Data Row */}
                    <TableRow
                      className="onhover"
                      sx={{
                        textAlign: "center",
                        border: "1px solid #ddd",
                      }}
                    >
                      {/* Add the expand icon here */}
                      <TableCell
                        sx={{
                          textAlign: "center",

                          cursor: "pointer",
                          border: "1px solid #ddd",
                        }}
                        onClick={(e) => {
                          e.stopPropagation();
                          handleRowClick(item._id);
                        }}
                      >
                        <IconButton
                          size="small"
                          aria-label="expand row"
                          sx={{
                            color:
                              expandedRow === item._id ? "#0288d1" : "#000",
                          }}
                        >
                          <ExpandMoreIcon />
                        </IconButton>
                      </TableCell>
                      <TableCell
                        sx={{
                          textAlign: "center",
                          borderRight: "1px solid #ddd",
                          borderLeft: "1px solid #ddd",
                        }}
                      >
                        {page * rowsPerPage + index + 1}
                      </TableCell>
                      {columns.map((column: any) => (
                        <TableCell
                          key={column.id}
                          sx={{
                            textAlign: "center",
                            borderRight: "1px solid #ddd",
                            borderLeft: "1px solid #ddd",
                          }}
                        >
                          {item[column.field]}
                        </TableCell>
                      ))}

                      <TableCell
                        key="assignTo"
                        sx={{
                          textAlign: "center",
                          borderRight: "1px solid #ddd",
                          borderLeft: "1px solid #ddd",
                        }}
                      >
                        <Checkbox
                          checked={selectedLeads.includes(item._id)}
                          onChange={() => handleCheckboxChange(item._id)}
                          color="primary"
                        />
                      </TableCell>
                    </TableRow>

                    {/* Collapsible Row */}
                    <TableRow>
                      <TableCell colSpan={columns.length + 3}>
                        <Collapse
                          in={expandedRow === item._id}
                          timeout="auto"
                          unmountOnExit
                        >
                          <Grid container>
                            <Grid item xs={12} sm={4}>
                              <Typography
                                sx={{
                                  fontWeight: "bold",
                                  display: "inline",
                                  mx: 2,
                                }}
                              >
                                Experience:
                              </Typography>
                              <Typography sx={{ display: "inline" }}>
                                {item.experience}
                              </Typography>
                            </Grid>
                            <Grid item xs={12} sm={4}>
                              <Typography
                                sx={{
                                  fontWeight: "bold",
                                  display: "inline",
                                  mx: 2,
                                }}
                              >
                                Qualification:
                              </Typography>
                              <Typography sx={{ display: "inline" }}>
                                {item.qualification}
                              </Typography>
                            </Grid>
                            <Grid item xs={12} sm={4}>
                              <Typography
                                sx={{
                                  fontWeight: "bold",
                                  display: "inline",
                                  mx: 2,
                                }}
                              >
                                State:
                              </Typography>
                              <Typography sx={{ display: "inline" }}>
                                {item.state}
                              </Typography>
                            </Grid>
                            <Grid item xs={12} sm={4}>
                              <Typography
                                sx={{
                                  fontWeight: "bold",
                                  display: "inline",
                                  mx: 2,
                                }}
                              >
                                City:
                              </Typography>
                              <Typography sx={{ display: "inline" }}>
                                {item.city}
                              </Typography>
                            </Grid>
                            <Grid item xs={12} sm={4}>
                              <Typography
                                sx={{
                                  fontWeight: "bold",
                                  display: "inline",
                                  mx: 2,
                                }}
                              >
                                Passing Year:
                              </Typography>
                              <Typography sx={{ display: "inline" }}>
                                {item.passingYear}
                              </Typography>
                            </Grid>
                            <Grid item xs={12} sm={4}>
                              <Typography
                                sx={{
                                  fontWeight: "bold",
                                  display: "inline",
                                  mx: 2,
                                }}
                              >
                                Intrested Course:
                              </Typography>
                              <Typography sx={{ display: "inline" }}>
                                {item.interestedCourse}
                              </Typography>
                            </Grid>
                          </Grid>
                        </Collapse>
                      </TableCell>
                    </TableRow>
                  </React.Fragment>
                ))}
              </TableBody>
            </Table>
          </TableContainer>

          <Grid sx={{ mt: 2 }}>
            <TablePagination
              rowsPerPageOptions={[10, 25, 50, 100]}
              component="div"
              count={LeadData.length}
              rowsPerPage={rowsPerPage}
              page={page}
              onPageChange={handleChangePage}
              onRowsPerPageChange={handleChangeRowsPerPage}
            />
          </Grid>
        </Paper>
      </Container>
    </>
  );
}
