import {
  FormControl,
  InputLabel,
  LinearProgress,
  MenuItem,
  Paper,
  Select,
} from "@mui/material";
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import Container from "@mui/material/Container";
import Grid from "@mui/material/Grid";
import TextField from "@mui/material/TextField";
import Typography from "@mui/material/Typography";
import { useEffect, useState } from "react";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import CommonSnackBar from "../../Components/Common/CommonSnackbar";
import useValidation from "../../Hooks/useTypescriptValidation";
import { getRequest, postRequestMethod, updateRequest } from "../../api/api";
import {
  STUDENT_COURIER_POST,
  STUDENT_COURIER_PUT,
  STUDENT_DATA_GET,
} from "../../api/server";

export default function CourierForm() {
  const navigate = useNavigate();
  const { stUniqueId } = useParams();
  const location = useLocation();
  const { eventHandler } = useValidation();
  const isEditRoute =
    location.pathname ===
    `/dashboard/ManageStudent/Courier/${stUniqueId}/EditCourier/`;

  const [sendCourier, setSendCourier] = useState({
    courierCompany: "",
    consignmentNo: "",
    bookingDate: "",
    bookingCenter: "",
    toCenter: "",
    contactNumber: "",
    presentAddress: "",
    status: "",
    remark: "",
  });

  const [openSnackBar, setOpenSnackBar] = useState(false);
  const [snackMessage, setSnackMessage] = useState<string>("");
  const [isLoading, setIsLoading] = useState(false);

  const [errors, setErrors] = useState({
    courierCompany: "",
    consignmentNo: "",
    bookingDate: "",
    bookingCenter: "",
    toCenter: "",
    contactNumber: "",
    presentAddress: "",
    status: "",
    remark: "",
  });

  const setCourierDetails = async (e: any) => {
    setSendCourier({ ...sendCourier, [e.target.name]: e.target.value });
  };

  const validationHandler = async (e: any, alterName?: any) => {
    const val = e.target.value;
    const id = alterName;
    let prom = new Promise((resolve) => {
      if (true) {
        resolve(eventHandler(id, val));
      }
    });
    prom.then((res) => setErrors({ ...errors, [e.target.name]: res }));
  };

  const onSubmit = async (event: any) => {
    event.preventDefault();
    setIsLoading(true);

    if (validator(errors)) {
      if (!isEditRoute) {
        await postRequestMethod(STUDENT_COURIER_POST, "", {
          ...sendCourier,
          stUniqueId,
        })
          .then((resp: any) => {
            if (resp) {
              setIsLoading(false);
              setSnackMessage(resp.data);
              handleOpenSnackBar();
              setTimeout(
                () =>
                  navigate(`/dashboard/ManageStudent/Courier/${stUniqueId}`),
                3000
              );
            } else {
              setIsLoading(false);
              setSnackMessage("Something went wrong");
              handleOpenSnackBar();
            }
          })
          .catch((error) => {
            console.error("Error fetching data:", error);
          });
      } else {
        const result = await updateRequest(
          STUDENT_COURIER_PUT,
          location?.state?._id,
          sendCourier,
          ""
        )
          .then((resp: any) => {
            if (resp) {
              setIsLoading(false);
              setSnackMessage(resp.data);
              handleOpenSnackBar();
              setTimeout(
                () =>
                  navigate(`/dashboard/ManageStudent/Courier/${stUniqueId}`),
                3000
              );
            } else {
              setIsLoading(false);
              setSnackMessage("Something went wrong");
              handleOpenSnackBar();
            }
          })
          .catch((error) => {
            console.error("Error fetching data:", error);
          });
      }
    }
  };

  const validator = (errors: { [key: string]: string }) => {
    for (let field in errors) {
      if (errors[field] !== "") {
        return false;
      }
    }
    return true;
  };

  // handle snackbar
  const handleOpenSnackBar = () => {
    setOpenSnackBar(true);
    setTimeout(() => {
      setOpenSnackBar(false);
    }, 5000);
  };

  const getStudentData = async () => {
    if (!isEditRoute) {
      await getRequest(STUDENT_DATA_GET, {
        params: {
          stUniqueId: stUniqueId,
        },
      })
        .then((resp: any) => {
          setSendCourier({
            ...sendCourier,
            contactNumber: resp.data.contactNumber,
            presentAddress: resp.data.presentAddress,
          });
        })
        .catch((error) => {
          console.error("Error fetching data:", error);
        });
    } else {
      setSendCourier({
        courierCompany: location?.state?.courierCompany,
        consignmentNo: location?.state?.consignmentNo,
        bookingDate: location.state.bookingDate,
        bookingCenter: location.state.bookingCenter,
        toCenter: location.state.toCenter,
        contactNumber: location.state.contactNumber,
        presentAddress: location.state.presentAddress,
        status: location.state.status,
        remark: location.state.remark,
      });
    }
  };

  useEffect(() => {
    getStudentData();
  }, [isEditRoute, location]);

  return (
    <Container>
      {isLoading && <LinearProgress />}
      <Paper elevation={20} sx={{ p: 3 }}>
        <Box sx={{ p: "10px" }} component="form" onSubmit={onSubmit}>
          {!isEditRoute ? (
            <Typography
              variant="h6"
              align="center"
              sx={{ alignItems: "center", color: "#0288d1" }}
            >
              Add Courier
            </Typography>
          ) : (
            <Typography
              variant="h6"
              align="center"
              sx={{ alignItems: "center", color: "#0288d1" }}
            >
              Edit Courier
            </Typography>
          )}
          <Grid item xs={12}>
            <TextField
              InputLabelProps={{ shrink: true }}
              value={sendCourier?.courierCompany}
              fullWidth
              id="filled-password-input"
              label="Courier Company"
              type="text"
              required
              sx={{ mt: 3, background: "white" }}
              name="courierCompany"
              error={Boolean(errors.courierCompany)}
              helperText={errors.courierCompany}
              onChange={setCourierDetails}
              onBlur={(e: any) => validationHandler(e, "alphabetsAndSpace")}
            />
          </Grid>

          <Grid item xs={12}>
            <TextField
              value={sendCourier?.consignmentNo}
              fullWidth
              id="filled-password-input"
              label="Consignment No"
              type="text"
              required
              InputLabelProps={{ shrink: true }}
              sx={{ mt: 3, background: "white" }}
              name="consignmentNo"
              error={Boolean(errors.consignmentNo)}
              helperText={errors.consignmentNo}
              onChange={setCourierDetails}
              onBlur={(e: any) => validationHandler(e, "alphanumeric")}
            />
          </Grid>

          <Grid item xs={12}>
            <TextField
              value={sendCourier?.bookingDate}
              fullWidth
              InputLabelProps={{ shrink: true }}
              id="filled-password-input"
              label="Booking Date"
              type="date"
              required
              InputProps={{
                inputProps: { min: new Date().toISOString().slice(0, 10) },
              }}
              sx={{ mt: 3, background: "white" }}
              name="bookingDate"
              error={Boolean(errors.bookingDate)}
              helperText={errors.bookingDate}
              onChange={setCourierDetails}
            />
          </Grid>

          <Grid item xs={12}>
            <TextField
              value={sendCourier?.bookingCenter}
              InputLabelProps={{ shrink: true }}
              fullWidth
              id="filled-password-input"
              label="Booking Center"
              type="text"
              required
              name="bookingCenter"
              sx={{ mt: 3, background: "white" }}
              error={Boolean(errors.bookingCenter)}
              helperText={errors.bookingCenter}
              onChange={setCourierDetails}
              onBlur={(e: any) => validationHandler(e, "alphabetsAndSpace")}
            />
          </Grid>

          <Grid item xs={12}>
            <TextField
              value={sendCourier?.toCenter}
              InputLabelProps={{ shrink: true }}
              fullWidth
              id="filled-password-input"
              label="To Center"
              type="text"
              required
              name="toCenter"
              sx={{ mt: 3, background: "white" }}
              error={Boolean(errors.toCenter)}
              helperText={errors.toCenter}
              onChange={setCourierDetails}
              onBlur={(e: any) => validationHandler(e, "alphabetsAndSpace")}
            />
          </Grid>

          <Grid item xs={12}>
            <TextField
              value={sendCourier?.contactNumber}
              InputLabelProps={{ shrink: true }}
              fullWidth
              id="filled-password-input"
              label="Contact Number"
              required
              name="contactNumber"
              sx={{ mt: 3, background: "white" }}
              error={Boolean(errors.contactNumber)}
              helperText={errors.contactNumber}
              onChange={setCourierDetails}
              onBlur={(e: any) => validationHandler(e, "phone")}
            />
          </Grid>

          <Grid item xs={12}>
            <TextField
              InputLabelProps={{ shrink: true }}
              value={sendCourier?.presentAddress}
              fullWidth
              id="filled-multiline-static"
              label="Address"
              name="presentAddress"
              required
              multiline
              rows={4}
              sx={{ mt: 3, mb: 3, background: "white" }}
              error={Boolean(errors.presentAddress)}
              helperText={errors.presentAddress}
              onChange={setCourierDetails}
              onBlur={(e: any) => validationHandler(e, "address")}
            />
          </Grid>

          <Grid item xs={12}>
            <FormControl fullWidth>
              <InputLabel id="demo-simple-select-label">Status</InputLabel>
              <Select
                labelId="demo-simple-select-label"
                name="status"
                id="demo-simple-select"
                label="Status"
                value={sendCourier.status}
                type="text"
                required
                onChange={setCourierDetails}
              >
                <MenuItem value={"Complete"}>Complete</MenuItem>
                <MenuItem value={"Return"}>Return</MenuItem>
                <MenuItem value={"Pending"}>Pending</MenuItem>
                <MenuItem value={"Process"}>Process</MenuItem>
              </Select>
            </FormControl>
          </Grid>

          <Grid item xs={12}>
            <TextField
              autoComplete="given-name"
              required
              fullWidth
              id="Remark"
              label="Remark"
              sx={{ mt: 3, background: "white" }}
              minRows={2}
              placeholder="comment"
              multiline
              name="remark"
              value={sendCourier.remark}
              error={Boolean(errors.remark)}
              helperText={errors.remark}
              onChange={setCourierDetails}
              onBlur={(e: any) => validationHandler(e, "message")}
            />
          </Grid>
          <Grid item xs={12}>
            <Button type="submit" variant="contained" sx={{ mt: 3, mb: 2 }}>
              Submit
            </Button>
          </Grid>
        </Box>
        <CommonSnackBar message={snackMessage} open={openSnackBar} />
      </Paper>
    </Container>
  );
}
