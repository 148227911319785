import DownloadIcon from "@mui/icons-material/Download";
import {
  Button,
  Container,
  Dialog,
  DialogActions,
  DialogTitle,
  Grid,
  Paper,
  Stack,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TablePagination,
  TableRow,
  Tooltip,
  Typography,
} from "@mui/material";
import { PDFDownloadLink } from "@react-pdf/renderer";
import * as React from "react";
import { useEffect, useState } from "react";
import FirstPaymentReceiptLetter from "../../Components/Common/FirstPaymentReceiptLetter";
import ReceiptLetter from "../../Components/Common/ReceiptLetter";
import "../../Components/Home/Home.scss";
import { getRequestById, getRequestByParams } from "../../api/api";
import {
  GET_PROFILE,
  STUDENT_FIRST_PAID_INSTALLMENT_RECEIPT,
  STUDENT_PAID_INSTALLMENT_RECEIPT,
} from "../../api/server";

export default function AccountDetails(props: any) {
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [Detail, setDetail] = useState<any[]>([]);
  const [receipt, setReceipt] = useState<any[]>([]);
  const [totalCount, setTotalCount] = useState<number>();
  const [showPDF, setShowPDF] = useState(false);
  const [showFirstPDF, setShowFirstPDF] = useState(false);
  const [firstPayment, setFirstPayment] = useState<any>({});
  const [courseDataId, setCourseDataId] = useState("");
  const [firstPaymentReceipt, setFirstPaymentReceipt] = useState<any[]>([]);

  const handleCloseRejectDialog = () => {
    setShowPDF(false);
    setShowFirstPDF(false);
  };

  const getStudentData = () => {
    const myData = {
      type: props.types.type,
      lId: props.types.lId,
    };
    getRequestByParams(GET_PROFILE, "", "", myData)
      .then((response: any) => {
        setFirstPayment(response.data.courseData);
        setCourseDataId(response.data._id);
        if (response.data.courseData.installmentDetails) {
          const Success = response.data.courseData.installmentDetails.filter(
            (item: any) => item.verifyPayment === "Accept"
          );
          const TotalAmount = Success.reduce((total: any, installment: any) => {
            return total + parseFloat(installment.amount);
          }, 0);
          setTotalCount(TotalAmount);
          setDetail(Success);
        }
      })
      .catch((error) => {
        console.error("Error fetching profile data:", error);
      });
  };

  const InstallmentReceipt = async (id: any) => {
    try {
      let result = await getRequestById(
        STUDENT_PAID_INSTALLMENT_RECEIPT,
        id,
        ""
      );
      if (result) {
        setReceipt(result.data[0]);
        setShowPDF(true);
      }
    } catch (error) {
      console.error("Error fetching data:", error);
    }
  };

  const FirstPaymentInstallmentReceipt = async (id: any) => {
    try {
      let result = await getRequestById(
        STUDENT_FIRST_PAID_INSTALLMENT_RECEIPT,
        id,
        ""
      );
      if (result) {
        setFirstPaymentReceipt(result.data[0]);
        setShowFirstPDF(true);
      }
    } catch (error) {
      console.error("Error fetching data:", error);
    }
  };

  const handleChangePage = (event: any, newPage: any) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event: any) => {
    setRowsPerPage(+event.target.value);
    setPage(0);
  };

  useEffect(() => {
    getStudentData();
  }, []);

  return (
    <>
      <Container>
        <Paper elevation={20}>
          <Grid
            container
            sx={{
              p: 2,
              background: "#0288d1",
              color: "white",
            }}
          >
            <Grid item xs={12}>
              <Typography variant="h5" sx={{ mx: 3 }}>
                Paid Installments
              </Typography>
            </Grid>
          </Grid>
          <TableContainer sx={{ p: 1 }} className="scrollBarCss">
            <Table sx={{ minWidth: 650 }} aria-label="simple table">
              <TableHead>
                <TableRow>
                  <TableCell
                    sx={{
                      textAlign: "center",
                      border: "1px solid #ddd",
                      fontSize: "15px",
                    }}
                  >
                    Installments
                  </TableCell>
                  <TableCell
                    sx={{
                      textAlign: "center",
                      border: "1px solid #ddd",
                      fontSize: "15px",
                    }}
                  >
                    Installment Date
                  </TableCell>
                  <TableCell
                    sx={{
                      textAlign: "center",
                      border: "1px solid #ddd",
                      fontSize: "15px",
                    }}
                  >
                    Installment Paid Date
                  </TableCell>
                  <TableCell
                    sx={{
                      textAlign: "center",
                      border: "1px solid #ddd",
                      fontSize: "15px",
                    }}
                  >
                    Installment Amount
                  </TableCell>
                  <TableCell
                    sx={{
                      textAlign: "center",
                      border: "1px solid #ddd",
                      fontSize: "15px",
                    }}
                  >
                    Payment Mode
                  </TableCell>
                  <TableCell
                    sx={{
                      textAlign: "center",
                      border: "1px solid #ddd",
                      fontSize: "15px",
                    }}
                  >
                    Slip No.
                  </TableCell>
                  <TableCell
                    sx={{
                      textAlign: "center",
                      border: "1px solid #ddd",
                      fontSize: "15px",
                    }}
                  >
                    Bank Name
                  </TableCell>
                  <TableCell
                    sx={{
                      textAlign: "center",
                      border: "1px solid #ddd",
                      fontSize: "15px",
                    }}
                  >
                    Cheque Number
                  </TableCell>
                  <TableCell
                    sx={{
                      textAlign: "center",
                      border: "1px solid #ddd",
                      fontSize: "15px",
                    }}
                  >
                    Account No
                  </TableCell>
                  <TableCell
                    sx={{
                      textAlign: "center",
                      border: "1px solid #ddd",
                      fontSize: "15px",
                    }}
                  >
                    Transfer id
                  </TableCell>
                  <TableCell
                    sx={{
                      textAlign: "center",
                      border: "1px solid #ddd",
                      fontSize: "15px",
                    }}
                  >
                    Action
                  </TableCell>
                </TableRow>
              </TableHead>

              <TableBody>
                <TableRow>
                  <TableCell
                    sx={{
                      textAlign: "center",
                      border: "1px solid #ddd",
                      fontSize: "15px",
                    }}
                  >
                    {"First Payment"}
                  </TableCell>
                  <TableCell
                    sx={{
                      textAlign: "center",
                      border: "1px solid #ddd",
                      fontSize: "15px",
                    }}
                  >
                    {"----"}
                  </TableCell>
                  <TableCell
                    sx={{
                      textAlign: "center",
                      border: "1px solid #ddd",
                      fontSize: "15px",
                    }}
                  >
                    {firstPayment.depositDate}
                  </TableCell>
                  <TableCell
                    sx={{
                      textAlign: "center",
                      border: "1px solid #ddd",
                      fontSize: "15px",
                    }}
                  >
                    {firstPayment.feesReceived}
                  </TableCell>
                  <TableCell
                    sx={{
                      textAlign: "center",
                      border: "1px solid #ddd",
                      fontSize: "15px",
                    }}
                  >
                    {firstPayment.paymentMode}
                  </TableCell>
                  <TableCell
                    sx={{
                      textAlign: "center",
                      border: "1px solid #ddd",
                      fontSize: "15px",
                    }}
                  >
                    {firstPayment.slipNo ? firstPayment.slipNo : "----"}
                  </TableCell>
                  <TableCell
                    sx={{
                      textAlign: "center",
                      border: "1px solid #ddd",
                      fontSize: "15px",
                    }}
                  >
                    {firstPayment.bankName ? firstPayment.bankName : "----"}
                  </TableCell>
                  <TableCell
                    sx={{
                      textAlign: "center",
                      border: "1px solid #ddd",
                      fontSize: "15px",
                    }}
                  >
                    {firstPayment.chequeNo ? firstPayment.chequeNo : "----"}
                  </TableCell>
                  <TableCell
                    sx={{
                      textAlign: "center",
                      border: "1px solid #ddd",
                      fontSize: "15px",
                    }}
                  >
                    {firstPayment.accountNo ? firstPayment.accountNo : "----"}
                  </TableCell>
                  <TableCell
                    sx={{
                      textAlign: "center",
                      border: "1px solid #ddd",
                      fontSize: "15px",
                    }}
                  >
                    {firstPayment.transferId ? firstPayment.transferId : "----"}
                  </TableCell>
                  <TableCell
                    sx={{
                      textAlign: "center",
                      borderRight: "1px solid #ddd",
                    }}
                  >
                    <Stack direction="row" spacing={2}>
                      <Tooltip title="Download Receipt">
                        <Button
                          size="small"
                          variant="contained"
                          onClick={() =>
                            FirstPaymentInstallmentReceipt(courseDataId)
                          }
                        >
                          <DownloadIcon />
                        </Button>
                      </Tooltip>
                      <Dialog
                        open={showFirstPDF}
                        onClose={handleCloseRejectDialog}
                      >
                        <DialogTitle>Download Receipt</DialogTitle>
                        <DialogActions>
                          <PDFDownloadLink
                            document={
                              <FirstPaymentReceiptLetter
                                props={firstPaymentReceipt}
                              />
                            }
                            fileName="receipt.pdf"
                          >
                            {({ loading }) =>
                              loading ? "Generating PDF..." : "Download PDF"
                            }
                            <Button onClick={handleCloseRejectDialog}>
                              Yes
                            </Button>
                          </PDFDownloadLink>

                          <Button onClick={handleCloseRejectDialog}>No</Button>
                        </DialogActions>
                      </Dialog>
                    </Stack>
                  </TableCell>
                </TableRow>
                {Detail.slice(
                  page * rowsPerPage,
                  page * rowsPerPage + rowsPerPage
                ).map((item, index) => (
                  <React.Fragment key={index}>
                    <TableRow
                      key={item._id}
                      sx={{
                        textAlign: "center",
                        borderRight: "1px solid #ddd",
                      }}
                    >
                      <TableCell
                        sx={{
                          textAlign: "center",
                          border: "1px solid #ddd",
                          fontSize: "15px",
                        }}
                      >
                        {page * rowsPerPage + index + 1}
                      </TableCell>
                      <TableCell
                        component="th"
                        scope="row"
                        sx={{
                          textAlign: "center",
                          borderRight: "1px solid #ddd",
                        }}
                      >
                        {item.date}
                      </TableCell>
                      <TableCell
                        sx={{
                          textAlign: "center",
                          borderRight: "1px solid #ddd",
                        }}
                      >
                        {item.createdAt}
                      </TableCell>
                      <TableCell
                        sx={{
                          textAlign: "center",
                          borderRight: "1px solid #ddd",
                        }}
                      >
                        {item.amount}
                      </TableCell>

                      <TableCell
                        sx={{
                          textAlign: "center",
                          borderRight: "1px solid #ddd",
                        }}
                      >
                        {item.paymentMode}
                      </TableCell>
                      <TableCell
                        sx={{
                          textAlign: "center",
                          borderRight: "1px solid #ddd",
                        }}
                      >
                        {item.slipNo ? item.slipNo : "----"}
                      </TableCell>
                      <TableCell
                        sx={{
                          textAlign: "center",
                          borderRight: "1px solid #ddd",
                        }}
                      >
                        {item.bankName ? item.bankName : "----"}
                      </TableCell>
                      <TableCell
                        sx={{
                          textAlign: "center",
                          borderRight: "1px solid #ddd",
                        }}
                      >
                        {item.chequeNo ? item.chequeNo : "----"}
                      </TableCell>
                      <TableCell
                        sx={{
                          textAlign: "center",
                          borderRight: "1px solid #ddd",
                        }}
                      >
                        {item.accountNo ? item.accountNo : "----"}
                      </TableCell>
                      <TableCell
                        sx={{
                          textAlign: "center",
                          borderRight: "1px solid #ddd",
                        }}
                      >
                        {item.transferId ? item.transferId : "----"}
                      </TableCell>
                      <TableCell
                        sx={{
                          textAlign: "center",
                          borderRight: "1px solid #ddd",
                        }}
                      >
                        <Stack direction="row" spacing={2}>
                          <Tooltip title="Download Receipt">
                            <Button
                              size="small"
                              variant="contained"
                              onClick={() => InstallmentReceipt(item._id)}
                            >
                              <DownloadIcon />
                            </Button>
                          </Tooltip>
                          <Dialog
                            open={showPDF}
                            onClose={handleCloseRejectDialog}
                          >
                            <DialogTitle>Download Receipt</DialogTitle>
                            <DialogActions>
                              <PDFDownloadLink
                                document={
                                  <ReceiptLetter
                                    props={receipt}
                                    TotalAmount={totalCount}
                                  />
                                }
                                fileName="receipt.pdf"
                              >
                                {({ loading }) =>
                                  loading ? "Generating PDF..." : "Download PDF"
                                }
                                <Button onClick={handleCloseRejectDialog}>
                                  Yes
                                </Button>
                              </PDFDownloadLink>

                              <Button onClick={handleCloseRejectDialog}>
                                No
                              </Button>
                            </DialogActions>
                          </Dialog>
                        </Stack>
                      </TableCell>
                    </TableRow>
                  </React.Fragment>
                ))}
              </TableBody>
            </Table>
          </TableContainer>

          <TablePagination
            rowsPerPageOptions={[5, 10, 25, 50]}
            component="div"
            count={Detail.length}
            rowsPerPage={rowsPerPage}
            page={page}
            onPageChange={handleChangePage}
            onRowsPerPageChange={handleChangeRowsPerPage}
          ></TablePagination>
        </Paper>
      </Container>
    </>
  );
}
