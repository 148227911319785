import * as React from "react";
import Button from "@mui/material/Button";
import { styled } from "@mui/material/styles";
import Dialog from "@mui/material/Dialog";
import DialogTitle from "@mui/material/DialogTitle";
import DialogContent from "@mui/material/DialogContent";
import DialogActions from "@mui/material/DialogActions";
import IconButton from "@mui/material/IconButton";
import CloseIcon from "@mui/icons-material/Close";
import CommonSnackBar from "../../Common/CommonSnackbar";
import * as FileSaver from "file-saver";
import * as XLSX from "xlsx";
import { useEffect, useState } from "react";
import { ButtonGroup } from "@mui/material";
import axios from "axios";
import "../../Home/Home.scss";
import { GET_ACTIVITY_TYPE } from "../../../api/server";

const BootstrapDialog = styled(Dialog)(({ theme }) => ({
  "& .MuiDialogContent-root": {
    padding: theme.spacing(2),
  },
  "& .MuiDialogActions-root": {
    padding: theme.spacing(1),
  },
}));

export interface DialogTitleProps {
  id: string;
  children?: React.ReactNode;
  onClose: () => void;
}

function BootstrapDialogTitle(props: DialogTitleProps) {
  const { children, onClose, ...other } = props;
  return (
    <DialogTitle sx={{ m: 0, p: 2 }} {...other}>
      {children}
      {onClose ? (
        <IconButton
          aria-label="close"
          onClick={onClose}
          sx={{
            position: "absolute",
            right: 8,
            top: 8,
            color: (theme) => theme.palette.grey[500],
          }}
        >
          <CloseIcon />
        </IconButton>
      ) : null}
    </DialogTitle>
  );
}

export default function FollowupList(props: any) {
  const [open, setOpen] = React.useState(false);
  const [url, setUrl] = useState<string>("");
  const [openSnackBar, setOpenSnackBar] = useState<boolean>(false);
  const [snackMessage, setSnackMessage] = useState<string>("");

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const handleOpenSnackBar = () => {
    setOpenSnackBar(true);
    setTimeout(() => {
      setOpenSnackBar(false);
    }, 3000);
  };

  const fetchData = (activityType: string) => {
    axios
      .get<[]>(url)
      .then((response: any) => {
        let finalData: any = [];
        finalData = response?.data?.filter(
          (object: any) => object.activity === activityType
        );
        if (finalData.length) {
          exportToCSV(finalData);
        }
        if (finalData.length == 0) {
          setSnackMessage("No Activity Data Found!");
          handleOpenSnackBar();
        }
      })
      .catch((error) => {
        console.error("Error fetching data in table to excel:", error);
      });
  };

  const exportToCSV = (sheetData: []) => {
    const fileType =
      "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8";
    const fileExtension = ".xlsx";
    const ws = XLSX.utils.json_to_sheet(sheetData);
    const wb: XLSX.WorkBook = { Sheets: { data: ws }, SheetNames: ["data"] };
    const excelBuffer: any = XLSX.write(wb, {
      bookType: "xlsx",
      type: "array",
    });
    const data: Blob = new Blob([excelBuffer], { type: fileType });
    FileSaver.saveAs(data, `myFile${fileExtension}`);
  };

  function getData() {
    let url: string = `${GET_ACTIVITY_TYPE}/${props?.item?.exUniqueId}`;
    if (props) setUrl(url);
  }

  useEffect(() => {
    if (props) {
      getData();
    }
  }, [props]);

  return (
    <>
      <Button variant="contained" size="small" onClick={handleClickOpen}>
        Follow Up List
      </Button>
      <BootstrapDialog
        onClose={handleClose}
        aria-labelledby="customized-dialog-title"
        open={open}
      >
        <BootstrapDialogTitle
          id="customized-dialog-title"
          onClose={handleClose}
        >
          Download Lead Document
        </BootstrapDialogTitle>
        <DialogContent dividers className="scrollBarCss">
          <ButtonGroup
            variant="text"
            aria-colspan={10}
            aria-label="text button group"
          >
            <Button
              onClick={() => fetchData("Capture Payment")}
              style={{ padding: "10px", fontSize: "14px", fontWeight: "bold" }}
            >
              Capture Payment
            </Button>
            <Button
              onClick={() => fetchData("Counselling Session")}
              style={{ fontSize: "14px", fontWeight: "bold", padding: "10px" }}
            >
              Counselling Session
            </Button>
            <Button
              onClick={() => fetchData("Document Received")}
              style={{ fontSize: "14px", fontWeight: "bold", padding: "10px" }}
            >
              Document Received
            </Button>
            <Button
              onClick={() => fetchData("Had a Phone Conversation")}
              style={{ fontSize: "14px", fontWeight: "bold", padding: "20px" }}
            >
              Had a Phone Conversation
            </Button>
            <Button
              onClick={() => fetchData("Invalid")}
              style={{ fontSize: "14px", fontWeight: "bold", padding: "20px" }}
            >
              Invalid
            </Button>
            <Button
              onClick={() => fetchData("Uncontactable")}
              style={{ fontSize: "14px", fontWeight: "bold", padding: "30px" }}
            >
              Uncontactable
            </Button>
            <Button
              onClick={() => fetchData("Walkin")}
              style={{ fontSize: "14px", fontWeight: "bold", padding: "20px" }}
            >
              Walkin
            </Button>
          </ButtonGroup>
          <CommonSnackBar message={snackMessage} open={openSnackBar} />
        </DialogContent>
        <DialogActions>
          <Button autoFocus onClick={handleClose}>
            Close
          </Button>
        </DialogActions>
      </BootstrapDialog>
    </>
  );
}
