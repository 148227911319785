import {
  Container,
  Grid,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
} from "@mui/material";
import "../../Home/Home.scss";

export default function AcademicInformationPreview(props: any) {
  const Education = props?.Data?.employeeData?.education || [];
  const Experience = props?.Data?.employeeData?.experience || [];
  return (
    <>
      <Container sx={{ mt: 3 }}>
        <Paper sx={{ p: 2 }} elevation={0}>
          <TableContainer className="scrollBarCss">
            {/* Academic Details */}
            <Grid item xs={12}>
              <Typography fontWeight="500">Academic Details:</Typography>
            </Grid>
            <Table
              sx={{ minWidth: 650, mt: 3, border: "1px solid grey" }}
              aria-label="simple table"
            >
              <TableHead>
                <TableRow sx={{ background: "#0288d1" }}>
                  <TableCell sx={{ color: "white", border: "1px solid grey" }}>
                    College
                  </TableCell>
                  <TableCell
                    sx={{ color: "white", border: "1px solid grey" }}
                    align="center"
                  >
                    Degree
                  </TableCell>
                  <TableCell
                    sx={{ color: "white", border: "1px solid grey" }}
                    align="center"
                  >
                    University
                  </TableCell>
                  <TableCell
                    sx={{ color: "white", border: "1px solid grey" }}
                    align="center"
                  >
                    Passing Year
                  </TableCell>
                  <TableCell
                    sx={{ color: "white", border: "1px solid grey" }}
                    align="center"
                  >
                    Specialization
                  </TableCell>
                  <TableCell
                    sx={{ color: "white", border: "1px solid grey" }}
                    align="center"
                  >
                    Percentage
                  </TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {Education.map(
                  (row: any) =>
                    row && ( // Check if row is not null
                      <TableRow key={row.Class}>
                        <TableCell
                          align="center"
                          component="th"
                          scope="row"
                          sx={{ border: "1px solid grey" }}
                        >
                          {row.college}
                        </TableCell>
                        <TableCell
                          align="center"
                          sx={{ border: "1px solid grey" }}
                        >
                          {row.degree}
                        </TableCell>
                        <TableCell
                          align="center"
                          sx={{ border: "1px solid grey" }}
                        >
                          {row.university}
                        </TableCell>
                        <TableCell
                          align="center"
                          sx={{ border: "1px solid grey" }}
                        >
                          {row.year}
                        </TableCell>
                        <TableCell
                          align="center"
                          sx={{ border: "1px solid grey" }}
                        >
                          {row.specialization}
                        </TableCell>
                        <TableCell
                          align="center"
                          sx={{ border: "1px solid grey" }}
                        >
                          {row.percentage}
                        </TableCell>
                      </TableRow>
                    )
                )}
              </TableBody>
            </Table>
            {/* Experience Details */}
            <Grid item xs={12} sx={{ mt: 5 }}>
              <Typography fontWeight="500">Experience Details:</Typography>
            </Grid>
            <Table
              sx={{ minWidth: 650, mt: 3, border: "1px solid grey" }}
              aria-label="simple table"
            >
              <TableHead>
                <TableRow sx={{ background: "#0288d1" }}>
                  <TableCell sx={{ color: "white", border: "1px solid grey" }}>
                    Designation
                  </TableCell>
                  <TableCell
                    sx={{ color: "white", border: "1px solid grey" }}
                    align="center"
                  >
                    Organization
                  </TableCell>
                  <TableCell
                    sx={{ color: "white", border: "1px solid grey" }}
                    align="center"
                  >
                    Duties
                  </TableCell>
                  <TableCell
                    sx={{ color: "white", border: "1px solid grey" }}
                    align="center"
                  >
                    From
                  </TableCell>
                  <TableCell
                    sx={{ color: "white", border: "1px solid grey" }}
                    align="center"
                  >
                    To
                  </TableCell>
                  <TableCell
                    sx={{ color: "white", border: "1px solid grey" }}
                    align="center"
                  >
                    Reason for Change
                  </TableCell>
                  <TableCell
                    sx={{ color: "white", border: "1px solid grey" }}
                    align="center"
                  >
                    Since When
                  </TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {Experience.map(
                  (row: any) =>
                    row && ( // Check if row is not null
                      <TableRow key={row.Class}>
                        <TableCell
                          align="center"
                          component="th"
                          scope="row"
                          sx={{ border: "1px solid grey" }}
                        >
                          {row.designation}
                        </TableCell>
                        <TableCell
                          align="center"
                          sx={{ border: "1px solid grey" }}
                        >
                          {row.organization}
                        </TableCell>
                        <TableCell
                          align="center"
                          sx={{ border: "1px solid grey" }}
                        >
                          {row.duties}
                        </TableCell>
                        <TableCell
                          align="center"
                          sx={{ border: "1px solid grey" }}
                        >
                          {row.from}
                        </TableCell>
                        <TableCell
                          align="center"
                          sx={{ border: "1px solid grey" }}
                        >
                          {row.to}
                        </TableCell>
                        <TableCell
                          align="center"
                          sx={{ border: "1px solid grey" }}
                        >
                          {row.reason}
                        </TableCell>
                        <TableCell
                          align="center"
                          sx={{ border: "1px solid grey" }}
                        >
                          {row.expYear}
                        </TableCell>
                      </TableRow>
                    )
                )}
              </TableBody>
            </Table>
          </TableContainer>
        </Paper>
      </Container>
    </>
  );
}
