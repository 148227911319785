import {
  Button,
  Container,
  FormControl,
  Grid,
  InputLabel,
  MenuItem,
  Paper,
  Select,
  TextField,
  Typography,
} from "@mui/material";
import { useEffect, useState } from "react";
import CommonSnackBar from "../../Components/Common/CommonSnackbar";
import { getRequest, getRequestByParams } from "../../api/api";
import {
  STUDENT_MANAGE_FEE_REPORT,
  UNIVERSITY_MANAGE_FEE_REPORT,
} from "../../api/server";

interface FilterData {
  regType: String;
  selectUniversity: String;
  fromDate: String;
  toDate: String;
}

export default function ManageFeeReport() {
  const [feeDetails, setFeeDetails] = useState<any>();
  const [receivedAmount, setReceivedAmount] = useState<any>();
  // for search
  const [searchApiData, setSearchApiData] = useState<any[]>([]);
  // for snack-bar msg
  const [snackMessage, setSnackMessage] = useState<string>("");
  const [openSnackBar, setOpenSnackBar] = useState<boolean>(false);
  // for date
  const [data, setData] = useState<FilterData>({
    regType: "",
    selectUniversity: "",
    fromDate: "",
    toDate: "",
  });

  // filter function
  const handleFilterChange = (e: any) => {
    const { name, value } = e.target;
    setData((prevValues) => ({
      ...prevValues,
      [name]: value,
    }));
  };

  // snack-bar function
  const handleOpenSnackBar = () => {
    setOpenSnackBar(true);
    setTimeout(() => {
      setOpenSnackBar(false);
    }, 3000);
  };

  //  fetch fee details
  async function fetchData() {
    try {
      let result = await getRequest(STUDENT_MANAGE_FEE_REPORT, "");
      let university = await getRequest(UNIVERSITY_MANAGE_FEE_REPORT, "");
      setFeeDetails(result?.data);
      setSearchApiData(university?.data);
    } catch (error) {
      console.error("Error fetching data:", error);
    }
  }

  // fetch fees details according to filtration
  const fetchFilterData = async () => {
    let result = await getRequestByParams(
      STUDENT_MANAGE_FEE_REPORT,
      "",
      "",
      data
    );
    if (Object.keys(result?.data).length === 0) {
      setFeeDetails({});
      setSnackMessage("no data found");
    } else if (Object.values(data).every((value) => !value)) {
      setSnackMessage("Select atleast one filter!");
    } else {
      setFeeDetails(result?.data);
    }
    handleOpenSnackBar();
  };

  useEffect(() => {
    fetchData();
  }, []);

  useEffect(() => {
    let receivedAmountTotal: number = Number(
      feeDetails?.regFees +
        feeDetails?.cautionMoney +
        feeDetails?.otherFees +
        feeDetails?.paidFees +
        feeDetails?.feesReceived
    );
    setReceivedAmount(receivedAmountTotal);
  }, [feeDetails]);

  return (
    <>
      <Container>
        <Paper elevation={20} sx={{ p: 3 }}>
          <Typography
            variant="h6"
            align="center"
            sx={{ alignItems: "center", color: "#0288d1" }}
          >
            MANAGE FEE REPORT
          </Typography>

          <Grid container spacing={2} padding={2}>
            {/* By Registration Filter */}
            <Grid item xs={6}>
              <FormControl sx={{ m: 1, minWidth: 120 }} size="small" fullWidth>
                <InputLabel id="demo-select-small-label">
                  By Registration
                </InputLabel>
                <Select
                  labelId="demo-select-small-label"
                  label="By Registration "
                  id="demo-simple-select"
                  value={data?.regType}
                  defaultValue={""}
                  name="regType"
                  onChange={handleFilterChange}
                >
                  <MenuItem value={""}>--select--</MenuItem>
                  <MenuItem value="New">New</MenuItem>
                  <MenuItem value="Registration Renewal">
                    Registration Renewal
                  </MenuItem>
                  <MenuItem value="Back Registration">
                    Back Registration
                  </MenuItem>
                </Select>
              </FormControl>
            </Grid>
            {/* By University Filter*/}
            <Grid item xs={6}>
              <FormControl sx={{ m: 1, minWidth: 120 }} size="small" fullWidth>
                <InputLabel id="demo-select-small-label">
                  By University
                </InputLabel>
                <Select
                  name="selectUniversity"
                  labelId="demo-select-small-label"
                  className="form-control"
                  label="By University"
                  type="text"
                  size="small"
                  onChange={handleFilterChange}
                >
                  {searchApiData?.map((university: any) => {
                    const object = university.universityName;
                    return (
                      <MenuItem value={object} key={university?._id}>
                        {object}
                      </MenuItem>
                    );
                  })}
                </Select>
              </FormControl>
            </Grid>
            {/* Date Filter */}
            <Grid
              item
              xs={8}
              display="flex"
              justifyContent="space-evenly"
              alignItems="center"
            >
              <TextField
                InputLabelProps={{ shrink: true }}
                label="From"
                id="outlined-date-input"
                type="date"
                size="small"
                name="fromDate"
                autoComplete="current-date"
                value={data.fromDate}
                onChange={handleFilterChange}
                sx={{ background: "white" }}
              />
              <TextField
                InputLabelProps={{ shrink: true }}
                label="To"
                id="outlined-date-input"
                type="date"
                size="small"
                autoComplete="current-date"
                name="toDate"
                value={data.toDate}
                onChange={handleFilterChange}
                sx={{ background: "white" }}
              />
            </Grid>
            {/* Filter Button */}
            <Grid item xs={4}>
              <Button variant="contained" onClick={fetchFilterData}>
                Filter
              </Button>
              <CommonSnackBar message={snackMessage} open={openSnackBar} />
            </Grid>
          </Grid>

          <Grid
            container
            rowSpacing={1}
            columnSpacing={{ xs: 1, sm: 1, md: 1 }}
          >
            {/* No. of Form */}
            <Grid item xs={6}>
              <TextField
                id="outlined-basic"
                variant="outlined"
                size="small"
                defaultValue="No. of Form"
                fullWidth
                InputProps={{
                  readOnly: true,
                }}
              />
            </Grid>
            <Grid item xs={6}>
              <TextField
                id="outlined-basic"
                variant="outlined"
                value={feeDetails?.count || 0}
                fullWidth
                size="small"
                InputProps={{
                  readOnly: true,
                }}
              />
            </Grid>
            {/* University Course Fee */}
            <Grid item xs={6}>
              <TextField
                id="filled-basic"
                variant="filled"
                defaultValue="University Course Fee"
                size="small"
                fullWidth
                InputProps={{
                  readOnly: true,
                }}
              />
            </Grid>
            <Grid item xs={6}>
              <TextField
                id="filled-basic"
                variant="filled"
                value={feeDetails?.courseFees || 0}
                size="small"
                fullWidth
                InputProps={{
                  readOnly: true,
                }}
              />
            </Grid>
            {/* University Paid Fees */}
            <Grid item xs={6}>
              <TextField
                id="outlined-basic"
                variant="outlined"
                defaultValue="University Paid Fees"
                size="small"
                fullWidth
                InputProps={{
                  readOnly: true,
                }}
              />
            </Grid>
            <Grid item xs={6}>
              <TextField
                id="outlined-basic"
                variant="outlined"
                value={feeDetails?.paidFees || 0}
                size="small"
                fullWidth
                InputProps={{
                  readOnly: true,
                }}
              />
            </Grid>
            {/* Registration Fee */}
            <Grid item xs={6}>
              <TextField
                id="filled-basic"
                variant="filled"
                defaultValue="Registration Fee"
                size="small"
                fullWidth
                InputProps={{
                  readOnly: true,
                }}
              />
            </Grid>
            <Grid item xs={6}>
              <TextField
                id="filled-basic"
                variant="filled"
                value={feeDetails?.regFees || 0}
                size="small"
                fullWidth
                InputProps={{
                  readOnly: true,
                }}
              />
            </Grid>
            {/* Caution Money */}
            <Grid item xs={6}>
              <TextField
                id="outlined-basic"
                variant="outlined"
                defaultValue="Caution Money"
                size="small"
                fullWidth
                InputProps={{
                  readOnly: true,
                }}
              />
            </Grid>
            <Grid item xs={6}>
              <TextField
                id="outlined-basic"
                variant="outlined"
                value={feeDetails?.cautionMoney || 0}
                size="small"
                fullWidth
                InputProps={{
                  readOnly: true,
                }}
              />
            </Grid>
            {/* Other Fee */}
            <Grid item xs={6}>
              <TextField
                id="filled-basic"
                variant="filled"
                defaultValue="Other Fee"
                size="small"
                fullWidth
                InputProps={{
                  readOnly: true,
                }}
              />
            </Grid>
            <Grid item xs={6}>
              <TextField
                id="filled-basic"
                variant="filled"
                value={feeDetails?.otherFees || 0}
                size="small"
                fullWidth
                InputProps={{
                  readOnly: true,
                }}
              />
            </Grid>
            {/* Received Amount */}
            <Grid item xs={6}>
              <TextField
                id="outlined-basic"
                variant="outlined"
                defaultValue="Received Amount"
                size="small"
                fullWidth
                InputProps={{
                  readOnly: true,
                }}
              />
            </Grid>
            <Grid item xs={6}>
              <TextField
                id="outlined-basic"
                variant="outlined"
                value={receivedAmount || 0}
                size="small"
                fullWidth
                InputProps={{
                  readOnly: true,
                }}
              />
            </Grid>
            {/* Balance Amount */}
            <Grid item xs={6}>
              <TextField
                id="filled-basic"
                variant="filled"
                defaultValue="Balance Amount"
                size="small"
                fullWidth
                InputProps={{
                  readOnly: true,
                }}
              />
            </Grid>
            <Grid item xs={6}>
              <TextField
                id="filled-basic"
                variant="filled"
                size="small"
                value={feeDetails?.pendingFees || 0}
                fullWidth
                InputProps={{
                  readOnly: true,
                }}
              />
            </Grid>
          </Grid>
        </Paper>
      </Container>
    </>
  );
}
