import EditIcon from "@mui/icons-material/Edit";
import {
  Button,
  Container,
  Grid,
  IconButton,
  Stack,
  TablePagination,
  Tooltip,
  Typography,
} from "@mui/material";
import Paper from "@mui/material/Paper";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import { Fragment, useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { getRequest } from "../../api/api";
import { STUDENT_COURIER_GET } from "../../api/server";

export default function ManageStudentCourier() {
  let { stUniqueId } = useParams();
  const navigate = useNavigate();

  const [studentCourier, setStudentCourier] = useState<any>();

  // for pagination
  const [page, setPage] = useState<number>(0);
  const [rowsPerPage, setRowsPerPage] = useState<number>(10);

  // for table pagination
  const handleChangePage = (event: any, newPage: any) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event: any) => {
    setRowsPerPage(+event.target.value);
    setPage(0);
  };

  const getCourierData = async () => {
    await getRequest(STUDENT_COURIER_GET, {
      params: {
        stUniqueId: stUniqueId,
      },
    })
      .then((resp: any) => {
        setStudentCourier(resp.data);
      })
      .catch((error) => {
        console.error("Error fetching data:", error);
      });
  };

  useEffect(() => {
    getCourierData();
  }, []);

  return (
    <>
      <Container>
        <Paper elevation={20}>
          <Grid
            container
            sx={{
              p: 2,
              background: "#0288d1",
              color: "white",
              justifyContent: "space-between",
            }}
          >
            <Grid item xs={8}>
              <Typography variant="h5" sx={{ mx: 3 }}>
                Manage Courier
              </Typography>
            </Grid>
            <Grid item xs={4}>
              <Grid
                container
                sx={{
                  color: "white",
                  justifyContent: "center",
                }}
              >
                <Button
                  size="small"
                  sx={{
                    background: "white",
                    color: "black",
                    "&:hover": {
                      background: "white",
                    },
                  }}
                  onClick={() => navigate(`AddCourier/${stUniqueId}`)}
                >
                  Add Courier
                </Button>
              </Grid>
            </Grid>
          </Grid>

          <TableContainer className="scrollBarCss">
            <Table sx={{ minWidth: 650 }} aria-label="simple table">
              <TableHead>
                <TableRow>
                  <TableCell
                    sx={{
                      textAlign: "center",
                      borderRight: "1px solid #ddd",
                      fontSize: "15px",
                    }}
                  >
                    Sr. No.
                  </TableCell>

                  <TableCell
                    sx={{
                      textAlign: "center",
                      borderRight: "1px solid #ddd",
                      fontSize: "15px",
                    }}
                  >
                    Courier Company
                  </TableCell>

                  <TableCell
                    sx={{
                      textAlign: "center",
                      borderRight: "1px solid #ddd",
                      fontSize: "15px",
                    }}
                  >
                    Consignment No
                  </TableCell>

                  <TableCell
                    sx={{
                      textAlign: "center",
                      borderRight: "1px solid #ddd",
                    }}
                  >
                    Booking Date
                  </TableCell>
                  <TableCell
                    sx={{
                      textAlign: "center",
                      borderRight: "1px solid #ddd",
                    }}
                  >
                    Booking Center
                  </TableCell>
                  <TableCell
                    sx={{
                      textAlign: "center",
                      borderRight: "1px solid #ddd",
                    }}
                  >
                    To Center
                  </TableCell>
                  <TableCell
                    sx={{
                      textAlign: "center",
                      borderRight: "1px solid #ddd",
                    }}
                  >
                    Contact Number
                  </TableCell>
                  <TableCell
                    sx={{
                      textAlign: "center",
                      borderRight: "1px solid #ddd",
                    }}
                  >
                    Address
                  </TableCell>
                  <TableCell
                    sx={{
                      textAlign: "center",
                      borderRight: "1px solid #ddd",
                    }}
                  >
                    Status
                  </TableCell>
                  <TableCell
                    sx={{
                      textAlign: "center",
                      borderRight: "1px solid #ddd",
                    }}
                  >
                    Remark
                  </TableCell>

                  <TableCell
                    sx={{
                      textAlign: "center",
                      borderRight: "1px solid #ddd",
                    }}
                  >
                    Action
                  </TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {studentCourier
                  ?.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                  .map((item: any, index: number) => (
                    <Fragment key={item._id}>
                      <TableRow
                        sx={{
                          textAlign: "center",
                          borderRight: "1px solid #ddd",
                        }}
                      >
                        <TableCell
                          sx={{
                            textAlign: "center",
                            borderRight: "1px solid #ddd",
                          }}
                        >
                          {page * rowsPerPage + index + 1}
                        </TableCell>
                        <TableCell
                          sx={{
                            textAlign: "center",
                            borderRight: "1px solid #ddd",
                          }}
                        >
                          {item?.courierCompany}
                        </TableCell>
                        <TableCell
                          sx={{
                            textAlign: "center",
                            borderRight: "1px solid #ddd",
                          }}
                        >
                          {item?.consignmentNo}
                        </TableCell>
                        <TableCell
                          sx={{
                            textAlign: "center",
                            borderRight: "1px solid #ddd",
                          }}
                        >
                          {item?.bookingDate}
                        </TableCell>
                        <TableCell
                          sx={{
                            textAlign: "center",
                            borderRight: "1px solid #ddd",
                          }}
                        >
                          {item?.bookingCenter}
                        </TableCell>
                        <TableCell
                          sx={{
                            textAlign: "center",
                            borderRight: "1px solid #ddd",
                          }}
                        >
                          {item?.toCenter}
                        </TableCell>
                        <TableCell
                          sx={{
                            textAlign: "center",
                            borderRight: "1px solid #ddd",
                          }}
                        >
                          {item?.contactNumber}
                        </TableCell>
                        <TableCell
                          sx={{
                            textAlign: "center",
                            borderRight: "1px solid #ddd",
                          }}
                        >
                          {item?.presentAddress}
                        </TableCell>
                        <TableCell
                          sx={{
                            textAlign: "center",
                            borderRight: "1px solid #ddd",
                          }}
                        >
                          {item?.status}
                        </TableCell>
                        <TableCell
                          sx={{
                            textAlign: "center",
                            borderRight: "1px solid #ddd",
                          }}
                        >
                          {item?.remark}
                        </TableCell>

                        <TableCell
                          sx={{
                            borderRight: "1px solid #ddd",
                          }}
                        >
                          <Stack
                            direction="row"
                            justifyContent="space-evenly"
                            alignItems="center"
                            spacing={2}
                          >
                            <Tooltip title="Edit Courier details" arrow>
                              <IconButton>
                                <EditIcon
                                  sx={{ color: "#0288d1" }}
                                  onClick={() =>
                                    navigate(`EditCourier/`, {
                                      state: item,
                                    })
                                  }
                                />
                              </IconButton>
                            </Tooltip>
                          </Stack>
                        </TableCell>
                      </TableRow>
                    </Fragment>
                  ))}
              </TableBody>
            </Table>
          </TableContainer>
          <TablePagination
            rowsPerPageOptions={[10, 25, 50, 100]}
            component="div"
            count={studentCourier?.length}
            rowsPerPage={rowsPerPage}
            page={page}
            onPageChange={handleChangePage}
            onRowsPerPageChange={handleChangeRowsPerPage}
          />
        </Paper>
      </Container>
    </>
  );
}
