import { RadioGroup, Stack } from "@mui/material";
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import Container from "@mui/material/Container";
import FormControl from "@mui/material/FormControl";
import FormControlLabel from "@mui/material/FormControlLabel";
import Grid from "@mui/material/Grid";
import Radio from "@mui/material/Radio";
import TextField from "@mui/material/TextField";
import Typography from "@mui/material/Typography";
import { useEffect, useRef, useState } from "react";
import useValidation from "../../Hooks/useTypescriptValidation";
import { updateRequest } from "../../api/api";
import { UPDATE_STUDENT_PAYMENT_FEES } from "../../api/server";

export default function PaymentForm(props: any) {
  const { fee, handleClose } = props;
  let { eventHandler } = useValidation();
  const dateRef = useRef<HTMLInputElement>(null);

  const [show, setShow] = useState(false);
  const [show1, setShow1] = useState(false);
  const [show2, setShow2] = useState(false);
  const [show3, setShow3] = useState(false);

  const [com, setCom] = useState<any>({
    paymentMode: "",
    depositeDate: "",
    slipNo: "",
    bankName: "",
    chequeNo: "",
    accountNo: "",
    transferId: "",
    transactionId: "",
  });

  const [errors, setErrors] = useState({
    depositeDate: "",
    slipNo: "",
    bankName: "",
    chequeNo: "",
    accountNo: "",
    transferId: "",
    transactionId: "",
  });

  const handleClick1 = () => {
    setShow(true);
    setShow1(false);
    setShow2(false);
    setShow3(false);
  };

  const handleClick2 = () => {
    setShow(false);
    setShow1(true);
    setShow2(false);
    setShow3(false);
  };

  const handleClick3 = () => {
    setShow(false);
    setShow1(false);
    setShow2(true);
    setShow3(false);
  };

  const handleClick4 = () => {
    setShow(false);
    setShow1(false);
    setShow2(false);
    setShow3(true);
  };

  const ValidationHandler = async (e: any, alterName?: any) => {
    const val = e.target.value;
    const id = alterName;

    if (id) {
      let prom = new Promise((resolve) => {
        if (true) {
          resolve(eventHandler(id, val));
        }
      });
      prom
        .then((res) => setErrors({ ...errors, [e.target.name]: res }))
        .then(() => setCom({ ...com, [e.target.name]: val }));
    } else {
      setCom({ ...com, [e.target.name]: val });
    }
  };

  const handlePaymentModeChange = (event: any) => {
    setCom({ paymentMode: event.target.value });
    setErrors({
      depositeDate: "",
      slipNo: "",
      bankName: "",
      chequeNo: "",
      accountNo: "",
      transferId: "",
      transactionId: "",
    });
    dateRef.current!.value = "";
  };

  const onSubmit = async (e: any) => {
    e.preventDefault();
    try {
      if (!validator()) {
        return;
      }
      let response = await updateRequest(
        UPDATE_STUDENT_PAYMENT_FEES,
        fee.id,
        com,
        ""
      );
      if (response?.data?.modifiedCount === 1) {
        handleClose();
      }
    } catch (error) {
      console.error(error);
    }
  };

  const validator = () => {
    try {
      for (let field in errors) {
        if (errors[field as keyof typeof errors] !== "") {
          return false;
        }
      }
      return true;
    } catch (error) {
      console.error("Error in validator:", error);
      return false;
    }
  };

  useEffect(() => {
    console.log("com: ", com);
  }, [com]);

  return (
    <>
      <Container component="main" maxWidth="lg">
        <Box
          component="form"
          onSubmit={onSubmit}
          sx={{
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
            p: 1,
          }}
        >
          <Box sx={{ mt: 3 }}>
            <Grid container spacing={2}>
              <Grid item xs={12} sm={12}>
                <FormControl>
                  <Stack direction="row" spacing={3}>
                    <Typography
                      sx={{
                        display: "flex",
                        alignItems: "center !important",
                      }}
                    >
                      Payment Mode :
                    </Typography>
                    <RadioGroup
                      row
                      aria-labelledby="demo-row-radio-buttons-group-label"
                      value={com.paymentMode}
                      onChange={handlePaymentModeChange}
                    >
                      <FormControlLabel
                        value="cash"
                        name="paymentMode"
                        control={<Radio />}
                        label="Cash"
                        onChange={handleClick1}
                      />
                      <FormControlLabel
                        value="cheque/dd"
                        name="paymentMode"
                        control={<Radio />}
                        label="Cheque/DD"
                        onChange={handleClick2}
                      />

                      <FormControlLabel
                        value="AC/Transfer"
                        name="paymentMode"
                        control={<Radio />}
                        label="AC/Transfer"
                        onChange={handleClick3}
                      />

                      <FormControlLabel
                        value="Online"
                        name="onlineMode"
                        control={<Radio />}
                        label="Online"
                        onChange={handleClick4}
                      />
                    </RadioGroup>
                  </Stack>
                </FormControl>
              </Grid>
              <Grid item xs={12} sm={12}>
                {com.paymentMode ? null : (
                  <span style={{ color: "red" }}>
                    Please select a payment mode
                  </span>
                )}
              </Grid>
              <Grid item xs={12} sm={6}>
                <TextField
                  disabled
                  required
                  InputLabelProps={{ shrink: true }}
                  id="FeesReceivedRS"
                  name="FeesReceivedRS"
                  label="Fees Received RS"
                  value={fee.fee}
                  variant="outlined"
                  type="text"
                  fullWidth
                  sx={{ background: "white" }}
                />
              </Grid>
              <Grid item xs={12} sm={6}>
                <TextField
                  inputRef={dateRef}
                  required
                  InputLabelProps={{ shrink: true }}
                  id="depositeDate"
                  name="depositeDate"
                  label="Deposite Date"
                  value={com.depositeDate}
                  variant="outlined"
                  type="date"
                  fullWidth
                  sx={{ background: "white" }}
                  onChange={(e) => ValidationHandler(e, "")}
                />
              </Grid>
              {show ? (
                <>
                  <Grid item xs={12} sm={12}>
                    <TextField
                      required
                      id="slipNo"
                      label="Slip No."
                      name="slipNo"
                      value={com.slipNo}
                      variant="outlined"
                      fullWidth
                      sx={{ background: "white" }}
                      error={Boolean(errors.slipNo)}
                      helperText={errors.slipNo}
                      onChange={(e) => ValidationHandler(e, "numeric")}
                    />
                  </Grid>
                </>
              ) : null}
              {show1 ? (
                <>
                  <Grid item xs={12} sm={6}>
                    <TextField
                      required
                      fullWidth
                      label="Bank Name"
                      type="text"
                      id="bankName"
                      name="bankName"
                      defaultValue=""
                      sx={{ background: "white" }}
                      value={com.bankName}
                      error={Boolean(errors.bankName)}
                      helperText={errors.bankName}
                      onChange={(e) =>
                        ValidationHandler(e, "alphabetsAndSpace")
                      }
                    />
                  </Grid>

                  <Grid item xs={12} sm={6}>
                    <TextField
                      required
                      fullWidth
                      name="chequeNo"
                      label="Cheque No."
                      type="text"
                      id="chequeNo"
                      sx={{ background: "white" }}
                      value={com.chequeNo}
                      error={Boolean(errors.chequeNo)}
                      helperText={errors.chequeNo}
                      onChange={(e) => ValidationHandler(e, "numeric")}
                      autoComplete="chequeNo"
                    />
                  </Grid>
                </>
              ) : null}

              {show2 ? (
                <>
                  <Grid item xs={12} sm={12}>
                    <TextField
                      required
                      fullWidth
                      label="Bank Name"
                      type="text"
                      id="bankName"
                      name="bankName"
                      defaultValue=""
                      sx={{ background: "white" }}
                      value={com.bankName}
                      error={Boolean(errors.bankName)}
                      helperText={errors.bankName}
                      onChange={(e) =>
                        ValidationHandler(e, "alphabetsAndSpace")
                      }
                    />
                  </Grid>
                  <Grid item xs={12} sm={6}>
                    <TextField
                      required
                      fullWidth
                      name="accountNo"
                      label="Account No."
                      id="accountNo"
                      sx={{ background: "white" }}
                      value={com.accountNo}
                      error={Boolean(errors.accountNo)}
                      helperText={errors.accountNo}
                      onChange={(e) => ValidationHandler(e, "numeric")}
                      autoComplete="accountNo"
                    />
                  </Grid>

                  <Grid item xs={12} sm={6}>
                    <TextField
                      required
                      fullWidth
                      name="transferId"
                      label="Transfer Id"
                      type="text"
                      id="transferId"
                      sx={{ background: "white" }}
                      value={com.transferId}
                      error={Boolean(errors.transferId)}
                      helperText={errors.transferId}
                      onChange={(e) => ValidationHandler(e, "numeric")}
                      autoComplete="transferId"
                    />
                  </Grid>
                </>
              ) : null}

              {show3 ? (
                <>
                  <Grid item xs={12} sm={12}>
                    <TextField
                      required
                      fullWidth
                      name="transactionId"
                      label="Transaction Id"
                      type="text"
                      id="transactionId"
                      sx={{ background: "white" }}
                      value={com.transactionId}
                      error={Boolean(errors.transactionId)}
                      helperText={errors.transactionId}
                      onChange={(e) => ValidationHandler(e, "numeric")}
                      autoComplete="transactionId"
                    />
                  </Grid>
                </>
              ) : null}

              <Grid
                item
                xs={6}
                sm={6}
                sx={{ justifyContent: "end", display: "flex" }}
              >
                <Button
                  type="submit"
                  variant="contained"
                  disabled={!com.paymentMode}
                >
                  Save Payment Info
                </Button>
              </Grid>

              <Grid item xs={6} sm={6}>
                <Button variant="outlined" onClick={handleClose}>
                  Close
                </Button>
              </Grid>
            </Grid>
          </Box>
        </Box>
      </Container>
    </>
  );
}
