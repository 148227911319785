import {
  Button,
  Container,
  LinearProgress,
  Paper,
  TextField,
} from "@mui/material";
import { useState } from "react";
import CommonSnackBar from "../../Components/Common/CommonSnackbar";
import useValidation from "../../Hooks/useTypescriptValidation";
import { postRequestMethod } from "../../api/api";
import { UPLOAD_LEAD } from "../../api/server";

export default function Upload() {
  const { eventHandler } = useValidation();

  const [file, setFile] = useState<string>("");
  const [errors, setErrors] = useState<string>("");
  const [openSnackBar, setOpenSnackBar] = useState<boolean>(false);
  const [snackMessage, setSnackMessage] = useState<string>("");
  const [isLoading, setIsLoading] = useState<boolean>(false);

  const onFileChange = (e: any) => {
    setFile(e.target.files[0]);
  };

  const validationHandler = async (e: any, alterName?: any) => {
    const val = e.target.files[0];
    const id = alterName;
    let prom = await new Promise<string>((resolve) =>
      resolve(eventHandler(id, val))
    );
    setErrors(prom);
    setFile(val);
  };

  const handleOpenSnackBar = () => {
    setOpenSnackBar(true);
    setTimeout(() => {
      setOpenSnackBar(false);
    }, 5000);
  };

  const onSubmit = (e: any) => {
    e.preventDefault();
    const formData = new FormData();
    formData.append("file", file);
    try {
      setIsLoading(true);
      postRequestMethod(UPLOAD_LEAD, "multipart/form-data", formData).then(
        (res: any) => {
          setIsLoading(false);
          if (res.data.duplicates?.length) {
            setSnackMessage(
              `Duplicate row number found: ${res.data?.duplicates.map(
                (index: any) => index
              )}`
            );
            handleOpenSnackBar();
          } else {
            setSnackMessage("CSV/xlsx uploded successfully");
            handleOpenSnackBar();
          }
          if (res.data.error) {
            setSnackMessage(`${res.data.error}`);
            handleOpenSnackBar();
          }
        }
      );
    } catch (error) {
      console.error("Error on submitting form", error);
    }
  };

  return (
    <>
      <Container>
        {isLoading && <LinearProgress />}
        <Paper elevation={20} sx={{ p: 5 }}>
          <TextField
            type="file"
            error={Boolean(errors)}
            helperText={errors}
            onChange={(e) => {
              onFileChange(e);
              validationHandler(e, "xlsxCsv");
            }}
            inputProps={{ accept: ".xlsx,.csv" }}
            sx={{ background: "white", mx: 4 }}
          ></TextField>
          <Button
            sx={{ mt: 1, px: 4, mx: 4 }}
            variant="contained"
            onClick={onSubmit}
          >
            Upload
          </Button>
          <CommonSnackBar message={snackMessage} open={openSnackBar} />
        </Paper>
      </Container>
    </>
  );
}
