import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Grid,
  List,
  ListItem,
  ListItemText,
  styled,
  Typography,
} from "@mui/material";
import { useTheme } from "@mui/material/styles";
import { Container } from "@mui/system";
import React, { useState } from "react";

const styles = {
  accordion: {
    marginBottom: "10px",
    boxShadow: "rgba(0, 0, 0, 0.24) 0px 3px 8px",
    borderRadius: "5px",
  },
  accordionSummary: {
    borderRadius: "10px",
  },
};

const ParentConatainer = styled(Grid)(({ theme }) => ({
  backgroundImage: `url(https://ik.imagekit.io/nwssoft/NWS_Prerna_Project/faq/stacked-waves-haikei.svg?updatedAt=1680504711586)`,
  padding: "50px",
  backgroundSize: "cover",
  backgroundRepeat: "no-repeat",
  backgroundAttachment: "fixed",
  zIndex: 0,
}));

const Image = styled("img")(({ theme }) => ({
  background: "none",
}));

function FaqTab() {
  const [expanded, setExpanded] = useState<string | false>(false);

  const handleChange =
    (panel: string) => (event: React.ChangeEvent<{}>, isExpanded: boolean) => {
      setExpanded(isExpanded ? panel : false);
    };

  const theme = useTheme();

  return (
    <ParentConatainer
      sx={{
        marginTop: {
          xs: "20%",
          sm: "2%",
          md: "1%",
          lg: "8%",
          xl: "8",
        },
        paddingTop: {
          sx: "15%",
          xs: "20%",
          lg: "8%",
          xl: "8",
          md: "10",
        },
      }}
    >
      <Container maxWidth="xl">
        <Grid
          container
          spacing={2}
          sx={{
            backgroundColor: "rgba(255, 255, 255, 0.14)",
            borderRadius: "10px",
            padding: "15px",
            backdropFilter: "blur(15px)",
            boxShadow:
              "rgba(32, 3, 3, 0.448) 0 3px 5px -1px, rgba(0, 0, 0, .14) 0 6px 10px 0, rgba(0, 0, 0, .12) 0 1px 18px 0",
            textAlign: "left",
          }}
        >
          <Grid
            item
            xs={12}
            display="flex"
            justifyContent="center"
            alignItems="center"
            flexDirection="row"
          >
            <Image
              width="20%"
              src="https://ik.imagekit.io/nwssoft/NWS_Prerna_Project/faq/undraw_Questions_re_1fy7-removebg-preview__1_.png?updatedAt=1680175264377"
            />
            <Typography
              variant="h2"
              color="white"
              sx={{
                textShadow: "3px 3px 2px rgba(8, 0, 0, 0.35)",
                fontSize: {
                  xs: theme.typography.h6.fontSize,
                  md: theme.typography.h4.fontSize,
                },
              }}
            >
              Frequently Asked Questions
            </Typography>
          </Grid>

          {/* generic  */}
          <Grid container item xs={12} rowSpacing={2} columnSpacing={2}>
            <Grid item lg={12}>
              <Typography
                variant="h5"
                textAlign="left"
                color="white"
                fontWeight="600"
                sx={{ textShadow: "3px 3px 2px rgba(8, 0, 0, 0.35)" }}
              >
                Generic
              </Typography>
            </Grid>

            <Grid item xs={12} lg={6} p={2}>
              <Accordion
                sx={styles.accordion}
                expanded={expanded === "panel1"}
                onChange={handleChange("panel1")}
              >
                <AccordionSummary
                  expandIcon={<ExpandMoreIcon />}
                  aria-controls="panel1a-content"
                  id="panel1a-header"
                  style={styles.accordionSummary}
                >
                  <Typography>
                    What services does Prerna Academy offer?
                  </Typography>
                </AccordionSummary>
                <AccordionDetails>
                  <Typography textAlign="left">
                    We offer end to end solutions that help you get accepted to
                    an colleges or other top ranked Universities. We also help
                    with career related services like the career test, linkedin,
                    naukri, times jobs,shine profile,review and resume editing.
                    All our services are explicitly listed here .
                  </Typography>
                </AccordionDetails>
              </Accordion>

              <Accordion
                sx={styles.accordion}
                expanded={expanded === "panel2"}
                onChange={handleChange("panel2")}
              >
                <AccordionSummary
                  expandIcon={<ExpandMoreIcon />}
                  aria-controls="panel1a-content"
                  id="panel1a-header"
                  style={styles.accordionSummary}
                >
                  <Typography>Do you have any branches?</Typography>
                </AccordionSummary>
                <AccordionDetails>
                  <Typography textAlign="left">
                    No. We do not have any branches. Our corporate office is in
                    Indore, India. We have worked with students across 5
                    countries & 20 states and our counselors too are based
                    national or globally. All our sessions are conducted
                    online/virtually. We collaborate with students on our online
                    platform. Our virtual presence allows us to guide students
                    irrespective of their location.
                  </Typography>
                </AccordionDetails>
              </Accordion>
            </Grid>

            <Grid item xs={12} lg={6}>
              <Accordion
                sx={styles.accordion}
                expanded={expanded === "panel3"}
                onChange={handleChange("panel3")}
              >
                <AccordionSummary
                  expandIcon={<ExpandMoreIcon />}
                  aria-controls="panel1a-content"
                  id="panel1a-header"
                  style={styles.accordionSummary}
                >
                  <Typography>Do you offer any free consultations?</Typography>
                </AccordionSummary>
                <AccordionDetails>
                  <Typography textAlign="left">
                    We do not offer any free consultations. We firmly believe
                    that to offer adequate guidance and assist you, our
                    counselor needs a dedicated hour long session to fully
                    understand your profile and ONLY then provide
                    recommendations.
                  </Typography>
                </AccordionDetails>
              </Accordion>

              <Accordion
                sx={styles.accordion}
                expanded={expanded === "panel4"}
                onChange={handleChange("panel4")}
              >
                <AccordionSummary
                  expandIcon={<ExpandMoreIcon />}
                  aria-controls="panel1a-content"
                  id="panel1a-header"
                  style={styles.accordionSummary}
                >
                  <Typography>How can I reach you?</Typography>
                </AccordionSummary>
                <AccordionDetails>
                  <Typography textAlign="left">
                    Please register on our website and submit your query. We
                    will revert to you in at most 48 hours (2 business days).
                    Emails are preferred as a means of communication over phone,
                    as we have a record of your requests and can respond
                    appropriately.
                  </Typography>
                </AccordionDetails>
              </Accordion>
            </Grid>
          </Grid>

          {/* Study Applications */}
          <Grid container item xs={12} rowSpacing={2} columnSpacing={2}>
            <Grid item lg={12}>
              <Typography
                variant="h5"
                textAlign="left"
                color="white"
                fontWeight="600"
                sx={{ textShadow: "3px 3px 2px rgba(8, 0, 0, 0.35)" }}
              >
                Study Applications
              </Typography>
            </Grid>

            <Grid item xs={12} lg={6} p={2}>
              <Accordion
                sx={styles.accordion}
                expanded={expanded === "panel5"}
                onChange={handleChange("panel5")}
              >
                <AccordionSummary
                  expandIcon={<ExpandMoreIcon />}
                  aria-controls="panel1a-content"
                  id="panel1a-header"
                  style={styles.accordionSummary}
                >
                  <Typography>
                    What happens once I sign up for a full application?
                  </Typography>
                </AccordionSummary>
                <AccordionDetails>
                  <Typography textAlign="left">
                    We work with students across the nation on online platform
                    Exp:- Email, Whatsup etc. You will be given access to your
                    folder, several manuals and documents on Prerna Academy
                    Portal; this will assist in building your application.
                  </Typography>
                </AccordionDetails>
              </Accordion>

              <Accordion
                sx={styles.accordion}
                expanded={expanded === "panel6"}
                onChange={handleChange("panel6")}
              >
                <AccordionSummary
                  expandIcon={<ExpandMoreIcon />}
                  aria-controls="panel1a-content"
                  id="panel1a-header"
                  style={styles.accordionSummary}
                >
                  <Typography>
                    What is the difference between counselling and college
                    selection?
                  </Typography>
                </AccordionSummary>
                <AccordionDetails>
                  <Typography textAlign="left">
                    <strong> Counseling:</strong> This service is recommended
                    prior to application till 1 month before starting your
                    applications.
                    <br />
                    <strong> College selection:</strong> You will be asked to
                    fill in a detailed form prior to your session. Your
                    counselor will do an in-depth analysis of your profile and
                    goals and end this session with a finite list of colleges.
                  </Typography>
                </AccordionDetails>
              </Accordion>

              <Accordion
                sx={styles.accordion}
                expanded={expanded === "panel8"}
                onChange={handleChange("panel8")}
              >
                <AccordionSummary
                  expandIcon={<ExpandMoreIcon />}
                  aria-controls="panel1a-content"
                  id="panel1a-header"
                  style={styles.accordionSummary}
                >
                  <Typography>
                    What are the standard application requirements?
                  </Typography>
                </AccordionSummary>
                <AccordionDetails>
                  <Typography textAlign="left" paddingLeft="5px">
                    The standard application requirements include:
                  </Typography>
                  <List>
                    <ListItem>
                      {" "}
                      <ListItemText primary="1.Application Form" />
                    </ListItem>
                    <ListItem>
                      <ListItemText primary="2.Application Fees" />{" "}
                    </ListItem>
                    <ListItem>
                      <ListItemText primary="3.Letter of Recommendation(If Required, Not Mandatory)" />
                    </ListItem>
                    <ListItem>
                      <ListItemText primary="4.All Required Qualification Documents" />
                    </ListItem>
                    <ListItem>
                      <ListItemText
                        primary="5.Resume & Experience Certificate ( if Required, not
                        mandatory )"
                      />
                    </ListItem>
                    <ListItem>
                      <ListItemText primary="6.Affidavit Form ( If Required, not mandatory )" />
                    </ListItem>
                    <ListItem>
                      <ListItemText primary="7.Migration/SLC/NOC Certificate" />
                    </ListItem>
                    <ListItem>
                      <ListItemText primary="8.Transcripts ( If Required, not mandatory )" />
                    </ListItem>
                  </List>
                </AccordionDetails>
              </Accordion>

              <Accordion
                sx={styles.accordion}
                expanded={expanded === "panel9"}
                onChange={handleChange("panel9")}
              >
                <AccordionSummary
                  expandIcon={<ExpandMoreIcon />}
                  aria-controls="panel1a-content"
                  id="panel1a-header"
                  style={styles.accordionSummary}
                >
                  <Typography>What is rolling deadlines?</Typography>
                </AccordionSummary>
                <AccordionDetails>
                  <Typography textAlign="left">
                    admissions offer a large application window for students to
                    apply. They have an ‘applications open’ and an ‘applications
                    end’ date. Students can apply on a “rolling” basis anytime
                    between these dates. Colleges respond to applicants as the
                    applications come in instead of waiting until after a
                    particular deadline. Students who apply earlier in the cycle
                    may secure an admit earlier and have a decision in hand well
                    before the admissions decisions of other schools or colleges
                    are announced.
                  </Typography>
                </AccordionDetails>
              </Accordion>
              <Accordion
                sx={styles.accordion}
                expanded={expanded === "panel7"}
                onChange={handleChange("panel7")}
              >
                <AccordionSummary
                  expandIcon={<ExpandMoreIcon />}
                  aria-controls="panel1a-content"
                  id="panel1a-header"
                  style={styles.accordionSummary}
                >
                  <Typography>
                    Do you have a package that includes both counseling sessions
                    and college selection?
                  </Typography>
                </AccordionSummary>
                <AccordionDetails>
                  <Typography textAlign="left">
                    Counselling session and college selection are two different
                    services and are therefore conducted separately. We
                    recommend you begin with a counselling session so that we
                    can take stock of your profile and determine next steps to
                    enhance it.linkedin, naukri, times jobs,shine profile,review
                    and resume editing. All our services are explicitly listed
                    here .<br />
                    <br />
                    College selection will be conducted closer to applications
                    with your strengthened profile and after you have
                    incorporated the suggestions made by the counselor.
                  </Typography>
                </AccordionDetails>
              </Accordion>
            </Grid>

            <Grid item xs={12} lg={6}>
              <Accordion
                sx={styles.accordion}
                expanded={expanded === "panel10"}
                onChange={handleChange("panel10")}
              >
                <AccordionSummary
                  expandIcon={<ExpandMoreIcon />}
                  aria-controls="panel1a-content"
                  id="panel1a-header"
                  style={styles.accordionSummary}
                >
                  <Typography>When should I apply for scholarships?</Typography>
                </AccordionSummary>
                <AccordionDetails>
                  <Typography textAlign="left">
                    Each college offers distinct scholarship programs. We
                    suggest you check on the individual college website to
                    understand what they offer and the rules and deadlines for
                    the same.
                    <br />
                    Some colleges will automatically consider you for a
                    scholarship based on your college application and some will
                    require a separate scholarship application to be completed.
                    Please check the respective college websites for information
                    on how to apply for their scholarships
                  </Typography>
                </AccordionDetails>
              </Accordion>

              <Accordion
                sx={styles.accordion}
                expanded={expanded === "panel11"}
                onChange={handleChange("panel11")}
              >
                <AccordionSummary
                  expandIcon={<ExpandMoreIcon />}
                  aria-controls="panel1a-content"
                  id="panel1a-header"
                  style={styles.accordionSummary}
                >
                  <Typography>
                    Should I upload my documents in word or pdf?
                  </Typography>
                </AccordionSummary>
                <AccordionDetails>
                  <Typography textAlign="left">
                    Each college maintains distinct process guidelines. Please
                    check the respective college websites for instructions on
                    uploading documents. Unless otherwise specified, please
                    upload the document in pdf.
                  </Typography>
                </AccordionDetails>
              </Accordion>

              <Accordion
                sx={styles.accordion}
                expanded={expanded === "panel12"}
                onChange={handleChange("panel12")}
              >
                <AccordionSummary
                  expandIcon={<ExpandMoreIcon />}
                  aria-controls="panel1a-content"
                  id="panel1a-header"
                  style={styles.accordionSummary}
                >
                  <Typography>
                    What if my selection and your suggestion of colleges do not
                    match?
                  </Typography>
                </AccordionSummary>
                <AccordionDetails>
                  <Typography textAlign="left">
                    We will draw out a list of colleges that based on your
                    profile will be the best fit. If you however feel you would
                    like to apply to a particular college we will support you a
                    100%.
                  </Typography>
                </AccordionDetails>
              </Accordion>

              <Accordion
                sx={styles.accordion}
                expanded={expanded === "panel13"}
                onChange={handleChange("panel13")}
              >
                <AccordionSummary
                  expandIcon={<ExpandMoreIcon />}
                  aria-controls="panel1a-content"
                  id="panel1a-header"
                  style={styles.accordionSummary}
                >
                  <Typography>Do you guarantee admission?</Typography>
                </AccordionSummary>
                <AccordionDetails>
                  <Typography textAlign="left">
                    Given that we are a 100% non- agency business and that our
                    forte lies in helping students leverage their profile to
                    reach top tier schools, we cannot guarantee admission.
                    <br />
                    However, given our expertise and consistent rate of
                    successful admits through all our years of operation, we are
                    confident of delivering a world class service and guiding
                    you holistically to get that admit!
                  </Typography>
                </AccordionDetails>
              </Accordion>
            </Grid>
          </Grid>

          {/* Pricing */}
          <Grid container item xs={12} rowSpacing={2} columnSpacing={2}>
            <Grid item lg={12}>
              <Typography
                variant="h5"
                textAlign="left"
                color="white"
                fontWeight="600"
                sx={{ textShadow: "3px 3px 2px rgba(8, 0, 0, 0.35)" }}
              >
                Pricing
              </Typography>
            </Grid>

            <Grid item xs={12} lg={6} p={2}>
              <Accordion
                sx={styles.accordion}
                expanded={expanded === "panel14"}
                onChange={handleChange("panel14")}
              >
                <AccordionSummary
                  expandIcon={<ExpandMoreIcon />}
                  aria-controls="panel1a-content"
                  id="panel1a-header"
                  style={styles.accordionSummary}
                >
                  <Typography>Can I re-schedule my session?</Typography>
                </AccordionSummary>
                <AccordionDetails>
                  <Typography textAlign="left">
                    Rescheduling of sessions is not permitted. Full amount of
                    the session will be deducted in case of cancellations and no
                    show for sessions. Please pick a slot with due
                    consideration.
                  </Typography>
                </AccordionDetails>
              </Accordion>

              <Accordion
                sx={styles.accordion}
                expanded={expanded === "panel15"}
                onChange={handleChange("panel15")}
              >
                <AccordionSummary
                  expandIcon={<ExpandMoreIcon />}
                  aria-controls="panel1a-content"
                  id="panel1a-header"
                  style={styles.accordionSummary}
                >
                  <Typography>What is your refund policy?</Typography>
                </AccordionSummary>
                <AccordionDetails>
                  <Typography textAlign="left">
                    We do not make any refunds.
                  </Typography>
                </AccordionDetails>
              </Accordion>
            </Grid>

            <Grid item xs={12} lg={6}>
              <Accordion
                sx={styles.accordion}
                expanded={expanded === "panel17"}
                onChange={handleChange("panel17")}
              >
                <AccordionSummary
                  expandIcon={<ExpandMoreIcon />}
                  aria-controls="panel1a-content"
                  id="panel1a-header"
                  style={styles.accordionSummary}
                >
                  <Typography>How do I make the payment?</Typography>
                </AccordionSummary>
                <AccordionDetails>
                  <Typography textAlign="left">
                    You can pay online, cash, cheque or bank transfer. Please
                    register now or send us an email on info@prernaacademy.in.
                    We will email you the link to our payment gateway upon
                    receiving your email. You can click on the link to pick the
                    services you wish to avail, make the payment and get started
                    to Prerna Academy!
                    <br />
                    The country in which you are currently earning will be the
                    country you are domicile in. Those domicile in India will be
                    charged in INR (GST @ 18% will be additional). Those outside
                    India will be charged in USD, no GST will be applicable.
                  </Typography>
                </AccordionDetails>
              </Accordion>
              <Accordion
                sx={styles.accordion}
                expanded={expanded === "panel16"}
                onChange={handleChange("panel16")}
              >
                <AccordionSummary
                  expandIcon={<ExpandMoreIcon />}
                  aria-controls="panel1a-content"
                  id="panel1a-header"
                  style={styles.accordionSummary}
                >
                  <Typography>
                    Can you give an indication of costs involved for the entire
                    application package?
                  </Typography>
                </AccordionSummary>
                <AccordionDetails>
                  <Typography textAlign="left">
                    We review our application prices every year. Please register
                    now or send us an email on info@prernaacademy.in for
                    individual service costs and package details.
                  </Typography>
                </AccordionDetails>
              </Accordion>
            </Grid>
          </Grid>

          {/* Study India */}
          <Grid container item xs={12} rowSpacing={2} columnSpacing={2}>
            <Grid item lg={12}>
              <Typography
                variant="h5"
                textAlign="left"
                color="white"
                fontWeight="600"
                sx={{ textShadow: "3px 3px 2px rgba(8, 0, 0, 0.35)" }}
              >
                Study India
              </Typography>
            </Grid>

            <Grid item xs={12} lg={6} p={2}>
              <Accordion
                sx={styles.accordion}
                expanded={expanded === "panel18"}
                onChange={handleChange("panel18")}
              >
                <AccordionSummary
                  expandIcon={<ExpandMoreIcon />}
                  aria-controls="panel1a-content"
                  id="panel1a-header"
                  style={styles.accordionSummary}
                >
                  <Typography>
                    What is the best time to get in touch with Prerna Academy?
                  </Typography>
                </AccordionSummary>
                <AccordionDetails>
                  <Typography textAlign="left">
                    To put your best foot forward, and build a stellar profile,
                    you should get in touch anytime between 10:30AM to 7:00PM
                    before you apply. Simply put – the earlier, the better.
                  </Typography>
                </AccordionDetails>
              </Accordion>

              <Accordion
                sx={styles.accordion}
                expanded={expanded === "panel19"}
                onChange={handleChange("panel19")}
              >
                <AccordionSummary
                  expandIcon={<ExpandMoreIcon />}
                  aria-controls="panel1a-content"
                  id="panel1a-header"
                  style={styles.accordionSummary}
                >
                  <Typography>Are my documents confidential?</Typography>
                </AccordionSummary>
                <AccordionDetails>
                  <Typography textAlign="left">
                    All documents and interactions between Prerna Academy and
                    our student/professional clients are strictly confidential.
                    We strongly suggest not sharing details of your
                    sessions/documents with any friends.
                  </Typography>
                </AccordionDetails>
              </Accordion>
            </Grid>

            <Grid item xs={12} lg={6}>
              <Accordion
                sx={styles.accordion}
                expanded={expanded === "panel20"}
                onChange={handleChange("panel20")}
              >
                <AccordionSummary
                  expandIcon={<ExpandMoreIcon />}
                  aria-controls="panel1a-content"
                  id="panel1a-header"
                  style={styles.accordionSummary}
                >
                  <Typography>
                    If I have a gap year can I still apply to top colleges or
                    universities?
                  </Typography>
                </AccordionSummary>
                <AccordionDetails>
                  <Typography textAlign="left">
                    All documents and interactions between Prerna Academy and
                    our student/professional clients are strictly confidential.
                    We strongly suggest not sharing details of your
                    sessions/documents with any friends.
                  </Typography>
                </AccordionDetails>
              </Accordion>

              <Accordion
                sx={styles.accordion}
                expanded={expanded === "panel21"}
                onChange={handleChange("panel21")}
              >
                <AccordionSummary
                  expandIcon={<ExpandMoreIcon />}
                  aria-controls="panel1a-content"
                  id="panel1a-header"
                  style={styles.accordionSummary}
                >
                  <Typography>
                    Can a degree obtained through distance education be
                    considered valid after completion?
                  </Typography>
                </AccordionSummary>
                <AccordionDetails>
                  <Typography textAlign="left">
                    Yes, if you have completed your graduation from an
                    accredited university, it will be considered as a valid
                    degree. However, it is best to confirm directly with the
                    college you wish to apply to if the accreditation is valid
                    and if you qualify.
                  </Typography>
                </AccordionDetails>
              </Accordion>
            </Grid>
          </Grid>

          {/* Team */}
          <Grid container item xs={12} rowSpacing={2} columnSpacing={2}>
            <Grid item lg={12}>
              <Typography
                variant="h5"
                textAlign="left"
                color="white"
                fontWeight="600"
                sx={{ textShadow: "3px 3px 2px rgba(8, 0, 0, 0.35)" }}
              >
                Team
              </Typography>
            </Grid>

            <Grid item xs={12} lg={6} p={2}>
              <Accordion
                sx={styles.accordion}
                expanded={expanded === "panel22"}
                onChange={handleChange("panel22")}
              >
                <AccordionSummary
                  expandIcon={<ExpandMoreIcon />}
                  aria-controls="panel1a-content"
                  id="panel1a-header"
                  style={styles.accordionSummary}
                >
                  <Typography>Who are your counselors?</Typography>
                </AccordionSummary>
                <AccordionDetails>
                  <Typography textAlign="left">
                    Our counselors have all studied at top schools and are based
                    across the nation. We are able to bring their expertise to
                    you via Skype in the comfort of your home.
                  </Typography>
                </AccordionDetails>
              </Accordion>

              <Accordion
                sx={styles.accordion}
                expanded={expanded === "panel23"}
                onChange={handleChange("panel23")}
              >
                <AccordionSummary
                  expandIcon={<ExpandMoreIcon />}
                  aria-controls="panel1a-content"
                  id="panel1a-header"
                  style={styles.accordionSummary}
                >
                  <Typography>Do I get to select my counselor?</Typography>
                </AccordionSummary>
                <AccordionDetails>
                  <Typography textAlign="left">
                    We have domain experts for each degree; we will connect you
                    to a counsel or that suits your particular need after
                    reviewing your profile! Your profile is personally discussed
                    with the core team including our Founder who is a Best
                    college or University alumnus.{" "}
                  </Typography>
                </AccordionDetails>
              </Accordion>
            </Grid>

            <Grid item xs={12} lg={6}>
              <Accordion
                sx={styles.accordion}
                expanded={expanded === "panel24"}
                onChange={handleChange("panel24")}
              >
                <AccordionSummary
                  expandIcon={<ExpandMoreIcon />}
                  aria-controls="panel1a-content"
                  id="panel1a-header"
                  style={styles.accordionSummary}
                >
                  <Typography>
                    How will the counselor be matched to me?
                  </Typography>
                </AccordionSummary>
                <AccordionDetails>
                  <Typography textAlign="left">
                    We have domain experts. The counselor who is best equipped
                    to assist you will be matched to you. The counselor’s
                    profile will be shared with you prior to the session.
                  </Typography>
                </AccordionDetails>
              </Accordion>
            </Grid>
          </Grid>

          {/* Career Guidence */}
          <Grid container item xs={12} rowSpacing={2} columnSpacing={2}>
            <Grid item lg={12}>
              <Typography
                variant="h5"
                textAlign="left"
                color="white"
                fontWeight="600"
                sx={{ textShadow: "3px 3px 2px rgba(8, 0, 0, 0.35)" }}
              >
                Career Guidence
              </Typography>
            </Grid>

            <Grid item xs={12} lg={12} p={2}>
              <Accordion
                sx={styles.accordion}
                expanded={expanded === "panel25"}
                onChange={handleChange("panel25")}
              >
                <AccordionSummary
                  expandIcon={<ExpandMoreIcon />}
                  aria-controls="panel1a-content"
                  id="panel1a-header"
                  style={styles.accordionSummary}
                >
                  <Typography>
                    Do you offer a package for career counseling?
                  </Typography>
                </AccordionSummary>
                <AccordionDetails>
                  <Typography textAlign="left">
                    Yes, we do offer a package of 2 sessions for career
                    counseling. When you sign up for 2 sessions, you get a
                    discount of 10%. In case of a price increase during the
                    year, you do not have to pay anything additional.
                  </Typography>
                </AccordionDetails>
              </Accordion>
            </Grid>
          </Grid>

          {/* Technical */}
          <Grid container item xs={12} rowSpacing={2} columnSpacing={2}>
            <Grid item lg={12}>
              <Typography
                variant="h5"
                textAlign="left"
                color="white"
                fontWeight="600"
                sx={{ textShadow: "3px 3px 2px rgba(8, 0, 0, 0.35)" }}
              >
                Technical
              </Typography>
            </Grid>

            <Grid item xs={12} lg={6} p={2}>
              <Accordion
                sx={styles.accordion}
                expanded={expanded === "panel26"}
                onChange={handleChange("panel26")}
              >
                <AccordionSummary
                  expandIcon={<ExpandMoreIcon />}
                  aria-controls="panel1a-content"
                  id="panel1a-header"
                  style={styles.accordionSummary}
                >
                  <Typography>
                    Why do you conduct your sessions on Skype?
                  </Typography>
                </AccordionSummary>
                <AccordionDetails>
                  <Typography textAlign="left">
                    This format has been developed after getting feedback from
                    our students. We are able to connect our students and
                    counselors virtually, irrespective of their locations. We
                    have been working with students from all across India and
                    the world on Skype and on our online collaborative platform
                    for years, and it works seamlessly. We are also able to hire
                    top global talent (see our Counsellor profiles), without
                    being confined by geography, so that you get the best expert
                    guidance right in the comfort of your home.
                  </Typography>
                </AccordionDetails>
              </Accordion>
            </Grid>

            <Grid item xs={12} lg={6}>
              <Accordion
                sx={styles.accordion}
                expanded={expanded === "panel27"}
                onChange={handleChange("panel27")}
              >
                <AccordionSummary
                  expandIcon={<ExpandMoreIcon />}
                  aria-controls="panel1a-content"
                  id="panel1a-header"
                  style={styles.accordionSummary}
                >
                  <Typography>
                    I have slow internet connection/We have a lot of power cuts.
                    How can that be helped?
                  </Typography>
                </AccordionSummary>
                <AccordionDetails>
                  <Typography textAlign="left">
                    You can have the session over phone,Email or skype.
                  </Typography>
                </AccordionDetails>
              </Accordion>
            </Grid>
          </Grid>
        </Grid>
      </Container>
    </ParentConatainer>
  );
}

export default FaqTab;
