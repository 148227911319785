import React, { useState, useEffect } from "react";
import {
  Button,
  Container,
  FormControl,
  Grid,
  IconButton,
  InputAdornment,
  LinearProgress,
  TablePagination,
  TextField,
  Typography,
  Collapse,
  Paper,
  TableContainer,
  TableRow,
  TableCell,
  TableBody,
  Table,
  TableHead,
} from "@mui/material";
import SearchIcon from "@mui/icons-material/Search";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import "./ManageUnAssignLead.scss";
import { getRequest, updateRequest } from "../../api/api";
import { GET_REFERENCE_USER, ASSIGN_REF_LEAD } from "../../api/server";
import "../../Components/Home/Home.scss";
import CommonSnackBar from "../../Components/Common/CommonSnackbar";
import TableToExcel from "../../Components/Common/TableToExcel";

const columns: any = [
  { id: 1, field: "fname", headerName: "First Name" },
  { id: 2, field: "lname", headerName: "Last Name" },
  { id: 3, field: "email", headerName: "Email" },
  { id: 4, field: "phone", headerName: "Phone" },
  { id: 5, field: "refName", headerName: "Reference Name" },
  { id: 6, field: "referedExecutiveName", headerName: "Assign To" },
];

export default function ReferenceUser() {
  // state to store table data
  const [leadData, setLeadData] = useState<any[]>([]);

  // state to manage searched data
  const [searchApiData, setSearchApiData] = useState<any[]>([]);

  //states for snack bar message
  const [openSnackBar, setOpenSnackBar] = useState(false);
  const [snackMessage, setSnackMessage] = useState<string>("");

  // for pagination
  const [page, setPage] = React.useState(0);
  const [rowsPerPage, setRowsPerPage] = React.useState(10);

  // state to manage search value
  const [filterVal, setFilterVal] = useState<string>("");

  // for progress bar
  const [isLoading, setIsLoading] = useState(false);
  const [expandedRow, setExpandedRow] = useState<string | null>(null);

  const getCustomers = async () => {
    try {
      let result: any = await getRequest(GET_REFERENCE_USER, "");
      if (result) {
        setLeadData(result["data"]);
        setSearchApiData(result["data"]);
      }
    } catch (error) {
      console.error("Error fetching customers: ", error);
    }
  };

  const handleChangePage = (event: any, newPage: any) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event: any) => {
    setRowsPerPage(+event.target.value);
    setPage(0);
  };

  const handleFilter = (e: any) => {
    if (e.target.value === "") {
      setLeadData(searchApiData);
    } else {
      const filterResult: any = searchApiData.filter((item: any) => {
        const searchValue = e.target.value.toLowerCase();
        return Object.values(item).some((value) => {
          if (typeof value === "string" || typeof value === "number") {
            const itemValue = String(value).toLowerCase();
            return itemValue.includes(searchValue);
          }
          return false;
        });
      });
      setLeadData(filterResult);
    }
    setFilterVal(e.target.value);
  };

  const handleRowClick = (rowId: string) => {
    setExpandedRow((prevRowId) => (prevRowId === rowId ? null : rowId));
  };

  // handle snackbar
  const handleOpenSnackBar = () => {
    setOpenSnackBar(true);
    setTimeout(() => {
      setOpenSnackBar(false);
    }, 3000);
  };

  const assignLead = async (id: string) => {
    try {
      setIsLoading(true);
      let result: any = await updateRequest(ASSIGN_REF_LEAD, id, undefined, "");
      if (result.data) {
        setSnackMessage(result.data.res);
        handleOpenSnackBar();
      }
      setTimeout(() => {
        getCustomers();
      }, 3000);
      setIsLoading(false);
    } catch (error) {
      console.error("Error fetching customers: ", error);
    }
  };

  // Modify the tableData to include the concatenated value
  const modifiedTableData = leadData.map((row) => ({
    ...row,
    Name: `${row.fname} ${row.lname}`,
  }));

  useEffect(() => {
    getCustomers();
  }, []);

  return (
    <>
      <Container>
        {isLoading && <LinearProgress variant="determinate" color="inherit" />}
        <Paper elevation={20}>
          <Grid container sx={{ p: 2, background: "#0288d1", color: "white" }}>
            <Grid item xs={6}>
              <Typography variant="h5" sx={{ mx: 3 }}>
                Manage Reference User
              </Typography>
            </Grid>
            <Grid item xs={3}>
              <TableToExcel
                tableData={modifiedTableData}
                fileName="Student_Referred_Lead_data"
                headerMapping={{
                  Name: "Name",
                  Phone: "phone",
                  Email: "email",
                  "Executive To Assign": "referedExecutiveName",
                  "Reference By": "refBy",
                  "Reference Student": "refName",
                  Qualification: "qualification",
                  State: "state",
                  City: "city",
                  "Interested Course": "interestedCourse",
                }}
              />
            </Grid>
            <Grid
              item
              xs={3}
              sx={{ display: "flex", justifyContent: "flex-end" }}
            >
              <FormControl>
                <TextField
                  label="Search"
                  size="small"
                  value={filterVal}
                  onInput={(e: any) => handleFilter(e)}
                  sx={{ mx: 3, background: "white" }}
                  InputProps={{
                    endAdornment: (
                      <InputAdornment position="end">
                        <IconButton edge="end">
                          <SearchIcon />
                        </IconButton>
                      </InputAdornment>
                    ),
                  }}
                />
              </FormControl>
            </Grid>
          </Grid>

          {/* Table */}
          <TableContainer className="scrollBarCss">
            <Table>
              {/* Table Headings */}
              <TableHead>
                {/* Table Heading Row */}
                <TableRow>
                  {/* Empty cell for heading row */}
                  <TableCell
                    sx={{
                      textAlign: "center",
                      fontWeight: "bold",
                      border: "1px solid #ddd",
                    }}
                  ></TableCell>
                  <TableCell
                    sx={{
                      textAlign: "center",
                      fontWeight: "bold",
                      border: "1px solid #ddd",
                    }}
                  >
                    Sr.No
                  </TableCell>
                  {/* Map for table headings */}
                  {columns.map((column: any) => (
                    <TableCell
                      key={column.id}
                      sx={{
                        textAlign: "center",
                        fontWeight: "bold",
                        border: "1px solid #ddd",
                      }}
                    >
                      {column.headerName}
                    </TableCell>
                  ))}

                  {/* Assign to heading static cell */}
                  <TableCell
                    key="assignToHeader"
                    sx={{
                      textAlign: "center",
                      fontWeight: "bold",
                      border: "1px solid #ddd",
                    }}
                  >
                    Action
                  </TableCell>
                </TableRow>
              </TableHead>

              {/* Table Content */}
              <TableBody>
                {/* Map for table content */}
                {leadData?.map((item: any, index: number) => (
                  <React.Fragment key={item._id}>
                    <TableRow
                      className="onhover"
                      sx={{
                        textAlign: "center",
                        border: "1px solid #ddd",
                      }}
                    >
                      {/* Cell for expand icon */}
                      <TableCell
                        sx={{
                          textAlign: "center",
                          cursor: "pointer",
                          border: "1px solid #ddd",
                        }}
                      >
                        <IconButton
                          size="small"
                          aria-label="expand row"
                          sx={{
                            color:
                              expandedRow === item._id ? "#0288d1" : "#000",
                          }}
                          onClick={(e) => {
                            e.stopPropagation();
                            handleRowClick(item._id);
                          }}
                        >
                          <ExpandMoreIcon />
                        </IconButton>
                      </TableCell>
                      <TableCell
                        sx={{
                          textAlign: "center",
                          borderRight: "1px solid #ddd",
                          borderLeft: "1px solid #ddd",
                        }}
                      >
                        {page * rowsPerPage + index + 1}
                      </TableCell>
                      {/* map for Collapsible data */}
                      {columns.map((column: any) => (
                        <TableCell
                          key={column.id}
                          sx={{
                            textAlign: "center",
                            borderRight: "1px solid #ddd",
                            borderLeft: "1px solid #ddd",
                          }}
                        >
                          {item[column.field]}
                        </TableCell>
                      ))}

                      {/* table cell for assign button */}
                      <TableCell
                        key="assignTo"
                        sx={{
                          textAlign: "center",
                          borderRight: "1px solid #ddd",
                          borderLeft: "1px solid #ddd",
                        }}
                      >
                        <Button
                          variant="contained"
                          color="primary"
                          onClick={() => assignLead(item._id)}
                        >
                          Assign
                        </Button>
                        <CommonSnackBar
                          message={snackMessage}
                          open={openSnackBar}
                        />
                      </TableCell>
                    </TableRow>

                    {/* for collapsiable row data  */}
                    {expandedRow === item._id && (
                      <TableRow>
                        <TableCell colSpan={columns.length + 2}>
                          <Collapse
                            in={expandedRow === item._id}
                            timeout="auto"
                            unmountOnExit
                          >
                            <Grid container>
                              <Grid item xs={12} sm={3}>
                                <Typography
                                  sx={{
                                    fontWeight: "bold",
                                    display: "inline",
                                    mx: 2,
                                  }}
                                >
                                  Interested Course:
                                </Typography>
                                <Typography sx={{ display: "inline" }}>
                                  {item.interestedCourse}
                                </Typography>
                              </Grid>

                              <Grid item xs={12} sm={2}>
                                <Typography
                                  sx={{
                                    fontWeight: "bold",
                                    display: "inline",
                                    mx: 2,
                                  }}
                                >
                                  Qualification:
                                </Typography>
                                <Typography sx={{ display: "inline" }}>
                                  {item.qualification}
                                </Typography>
                              </Grid>

                              <Grid item xs={12} sm={2}>
                                <Typography
                                  sx={{
                                    fontWeight: "bold",
                                    display: "inline",
                                    mx: 2,
                                  }}
                                >
                                  State:
                                </Typography>
                                <Typography sx={{ display: "inline" }}>
                                  {item.state}
                                </Typography>
                              </Grid>

                              <Grid xs={12} sm={2}>
                                <Typography
                                  sx={{
                                    fontWeight: "bold",
                                    display: "inline",
                                    mx: 2,
                                  }}
                                >
                                  City:
                                </Typography>
                                <Typography sx={{ display: "inline" }}>
                                  {item.city}
                                </Typography>
                              </Grid>
                              <Grid xs={12} sm={3}>
                                <Typography
                                  sx={{
                                    fontWeight: "bold",
                                    display: "inline",
                                    mx: 2,
                                  }}
                                >
                                  Hidden Reference
                                </Typography>
                                <Typography sx={{ display: "inline" }}>
                                  {item.hideName}
                                </Typography>
                              </Grid>
                            </Grid>
                          </Collapse>
                        </TableCell>
                      </TableRow>
                    )}
                  </React.Fragment>
                ))}
              </TableBody>
            </Table>
          </TableContainer>

          <Grid sx={{ mt: 2 }}>
            <TablePagination
              rowsPerPageOptions={[10, 25, 50, 100]}
              component="div"
              count={leadData?.length}
              rowsPerPage={rowsPerPage}
              page={page}
              onPageChange={handleChangePage}
              onRowsPerPageChange={handleChangeRowsPerPage}
            />
          </Grid>
        </Paper>
      </Container>
    </>
  );
}
