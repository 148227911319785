import { Divider, RadioGroup } from "@mui/material";
import Button from "@mui/material/Button";
import Container from "@mui/material/Container";
import FormControl from "@mui/material/FormControl";
import FormControlLabel from "@mui/material/FormControlLabel";
import Grid from "@mui/material/Grid";
import Radio from "@mui/material/Radio";
import TextField from "@mui/material/TextField";
import Typography from "@mui/material/Typography";
import { FormEvent, useState } from "react";
import CommonSnackBar from "../../Components/Common/CommonSnackbar";
import useValidation from "../../Hooks/useTypescriptValidation";
import { updateRequest } from "../../api/api";
import { UPDATE_COMMISSION_PAYMENT } from "../../api/server";

interface MyField {
  status: String;
  paymentMode: String;
  depositDate: String;
  slipNo: String;
  bankName: String;
  chequeNo: String;
  accountNo: String;
  transferId: String;
  remark: String;
}

export default function CommissionPayment(props: any) {
  const { eventHandler } = useValidation();
  const { id, closeDialog } = props;

  const [cashShow, setCashShow] = useState<boolean>(true);
  const [chequeShow, setChequeShow] = useState<boolean>(false);
  const [accountShow, setAccountShow] = useState<boolean>(false);
  const [snackMessage, setSnackMessage] = useState<string>("");
  const [openSnackBar, setOpenSnackBar] = useState<boolean>(false);

  const [paymentInfo, setPaymentInfo] = useState<MyField>({
    status: "Paid",
    paymentMode: "",
    depositDate: "",
    slipNo: "",
    bankName: "",
    chequeNo: "",
    accountNo: "",
    transferId: "",
    remark: "",
  });

  const [errors, setErrors] = useState<MyField>({
    status: "",
    paymentMode: "",
    depositDate: "",
    slipNo: "",
    bankName: "",
    chequeNo: "",
    accountNo: "",
    transferId: "",
    remark: "",
  });

  const handleCash = () => {
    setCashShow(true);
    setChequeShow(false);
    setAccountShow(false);
  };

  const handleCheque = () => {
    setCashShow(false);
    setChequeShow(true);
    setAccountShow(false);
  };

  const handleAccount = () => {
    setCashShow(false);
    setChequeShow(false);
    setAccountShow(true);
  };

  const handleOpenSnackBar = () => {
    setOpenSnackBar(true);
    setTimeout(() => {
      setOpenSnackBar(false);
    }, 3000);
  };

  function errorValidator() {
    let flag = true;
    for (let field in errors) {
      if (errors[field as keyof MyField] !== "") {
        flag = false;
      }
    }
    for (let field in paymentInfo) {
      if (paymentInfo[field as keyof MyField] === "") {
        flag = true;
      }
    }
    return flag;
  }

  const ValidationHandler = async (e: any, alterName?: any) => {
    const val = e.target.value;
    const id = alterName;
    if (id) {
      let prom = new Promise((resolve) => {
        if (true) {
          resolve(eventHandler(id, val));
        }
      });
      prom
        .then((res) => setErrors({ ...errors, [e.target.name]: res }))
        .then(() => setPaymentInfo({ ...paymentInfo, [e.target.name]: val }));
    } else {
      setPaymentInfo({ ...paymentInfo, [e.target.name]: val });
    }
  };

  const updateData = async (e: FormEvent) => {
    e.preventDefault();
    if (errorValidator()) {
      try {
        const response = await updateRequest(
          UPDATE_COMMISSION_PAYMENT,
          id,
          paymentInfo,
          ""
        );
        if (response?.data.message === "Successfully updated!") {
          setSnackMessage("Successfully updated!");
          handleOpenSnackBar();
          setTimeout(() => {
            closeDialog();
          }, 1000);
        } else {
          setSnackMessage("Failed to update!");
          handleOpenSnackBar();
        }
      } catch (error) {
        console.error("Error on updating commission payment", error);
      }
    }
  };

  return (
    <>
      <Container>
        <Grid
          container
          spacing={2}
          padding={2}
          component="form"
          onSubmit={updateData}
        >
          <Grid item xs={12}>
            <Typography variant="h6">Payment Mode</Typography>
            <Divider variant="fullWidth" />
          </Grid>

          <Grid item xs={12}>
            <FormControl>
              <RadioGroup
                row
                aria-labelledby="demo-row-radio-buttons-group-label"
                name="paymentMode"
                onChange={ValidationHandler}
              >
                <FormControlLabel
                  required
                  defaultChecked
                  value="cash"
                  control={<Radio />}
                  label="Cash"
                  onChange={handleCash}
                />
                <FormControlLabel
                  required
                  value="cheque/dd"
                  control={<Radio />}
                  label="Cheque/DD"
                  onChange={handleCheque}
                />
                <FormControlLabel
                  required
                  value="AC/Transfer"
                  control={<Radio />}
                  label="AC/Transfer"
                  onChange={handleAccount}
                />
              </RadioGroup>
            </FormControl>
          </Grid>

          <Grid item xs={6}>
            <TextField
              required
              InputLabelProps={{ shrink: true }}
              inputProps={{ max: new Date().toISOString().slice(0, 10) }}
              id="depositDate"
              name="depositDate"
              label="Deposit Date"
              variant="outlined"
              type="date"
              fullWidth
              sx={{ background: "white" }}
              onChange={ValidationHandler}
            />
          </Grid>

          {cashShow ? (
            <>
              <Grid item xs={6}>
                <TextField
                  required
                  id="slipNo"
                  variant="outlined"
                  fullWidth
                  label="Slip No."
                  name="slipNo"
                  type="number"
                  value={paymentInfo.slipNo}
                  sx={{ background: "white" }}
                  onChange={ValidationHandler}
                />
              </Grid>
            </>
          ) : (
            ""
          )}

          {chequeShow ? (
            <>
              <Grid item xs={12} sm={6}>
                <TextField
                  required
                  fullWidth
                  label="Bank Name"
                  type="text"
                  id="bankName"
                  name="bankName"
                  value={paymentInfo.bankName}
                  error={Boolean(errors.bankName)}
                  helperText={errors.bankName}
                  sx={{ background: "white" }}
                  onChange={(e) => ValidationHandler(e, "alphabet")}
                />
              </Grid>
              <Grid item xs={12}>
                <TextField
                  required
                  fullWidth
                  name="chequeNo"
                  label="Cheque No."
                  type="number"
                  id="chequeNo"
                  autoComplete="chequeNo"
                  value={paymentInfo.chequeNo}
                  error={Boolean(errors.chequeNo)}
                  helperText={errors.chequeNo}
                  sx={{ background: "white" }}
                  onChange={(e) => ValidationHandler(e, "zipcode")}
                />
              </Grid>
            </>
          ) : (
            ""
          )}
          {accountShow ? (
            <>
              <Grid item xs={12} sm={6}>
                <TextField
                  required
                  fullWidth
                  label="Bank Name"
                  type="text"
                  id="bankName"
                  name="bankName"
                  value={paymentInfo.bankName}
                  error={Boolean(errors.bankName)}
                  helperText={errors.bankName}
                  sx={{ background: "white" }}
                  onChange={(e) => ValidationHandler(e, "alphabet")}
                />
              </Grid>
              <Grid item xs={12} sm={6}>
                <TextField
                  required
                  fullWidth
                  name="accountNo"
                  label="Account No."
                  id="accountNo"
                  type="number"
                  autoComplete="accountNo"
                  value={paymentInfo.accountNo}
                  error={Boolean(errors.accountNo)}
                  helperText={errors.accountNo}
                  sx={{ background: "white" }}
                  onChange={(e) => ValidationHandler(e, "bankAccountNumber")}
                />
              </Grid>

              <Grid item xs={12} sm={6}>
                <TextField
                  required
                  fullWidth
                  name="transferId"
                  label="Transfer Id"
                  type="text"
                  id="transferId"
                  autoComplete="transferId"
                  sx={{ background: "white" }}
                  value={paymentInfo.transferId}
                  error={Boolean(errors.transferId)}
                  helperText={errors.transferId}
                  onChange={(e) => ValidationHandler(e, "alphanumeric")}
                />
              </Grid>
            </>
          ) : (
            ""
          )}

          <Grid item xs={12} sm={12}>
            <TextField
              required
              fullWidth
              name="remark"
              label="Remark"
              type="text"
              id="remark"
              value={paymentInfo.remark}
              error={Boolean(errors.remark)}
              helperText={errors.remark}
              sx={{ background: "white" }}
              onChange={(e) => ValidationHandler(e, "alphabetsAndSpace")}
            />
          </Grid>

          <Grid item xs={6} display="flex" justifyContent="flex-end">
            <Button type="submit" variant="contained">
              Pay Now
            </Button>
          </Grid>
          <Grid item xs={6}>
            <Button variant="outlined" onClick={closeDialog}>
              Close
            </Button>
          </Grid>
          <CommonSnackBar message={snackMessage} open={openSnackBar} />
        </Grid>
      </Container>
    </>
  );
}
