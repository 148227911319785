import { AccountCircle, Cancel } from "@mui/icons-material";
import { Avatar, IconButton } from "@mui/material";
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import Fade from "@mui/material/Fade";
import Grid from "@mui/material/Grid";
import Paper from "@mui/material/Paper";
import Popper, { PopperPlacementType } from "@mui/material/Popper";
import Stack from "@mui/material/Stack";
import Typography from "@mui/material/Typography";
import { useEffect, useRef, useState } from "react";
import { useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";
import { getRequestByParams, userLogout } from "../api/api";
import { GET_PROFILE } from "../api/server";
import { LOGOUT } from "../redux/action";

interface ProfileData {
  image: {
    name: string;
    path: string;
    originalname: string;
  };
}

export default function AccountProfile(props: any) {
  const navigate = useNavigate();
  const dispatchLogout = useDispatch();
  const popperRef = useRef<HTMLDivElement | null>(null);

  const [open, setOpen] = useState<boolean>(false);
  const [placement, setPlacement] = useState<PopperPlacementType>();
  const [open1, setOpen1] = useState<boolean>(false);
  const [anchorEl, setAnchorEl] = useState<HTMLButtonElement | null>(null);
  const [data, setData] = useState<ProfileData | undefined>(undefined);

  const handleClick =
    (newPlacement: PopperPlacementType) =>
    (event: React.MouseEvent<HTMLButtonElement>) => {
      setAnchorEl(event.currentTarget);
      setOpen((prev) => placement !== newPlacement || !prev);
      setPlacement(newPlacement);
    };

  const closePopper = () => {
    setOpen(false);
  };

  const profile = () => {
    setOpen(false);
    setOpen1(true);
    if (open1) {
      navigate("/dashboard");
      setOpen1(false);
    } else {
      navigate(`Profile/${props.types.lId}/${props.types.type}`);
    }
  };

  const logout = () => {
    userLogout();
    sessionStorage.removeItem("user");
    sessionStorage.removeItem("token");
    sessionStorage.removeItem("exptime");
    dispatchLogout({
      type: LOGOUT,
      login: false,
      userToken: "",
    });
    navigate("/login");
  };

  useEffect(() => {
    const getProfileData = async () => {
      const myData = {
        type: props.types.type,
        lId: props.types.lId,
      };
      let response = await getRequestByParams(GET_PROFILE, "", "", myData);
      if (response.data) {
        const imageData =
          props.types.type === "Student"
            ? {
                name: response.data?.studentData?.image?.name,
                path: response.data?.studentData?.image?.path,
                originalname: response.data?.studentData?.image?.originalname,
              }
            : {
                name: response.data?.image?.name,
                path: response.data?.image?.path,
                originalname: response.data?.image?.originalname,
              };
        setData({ image: imageData });
      }
    };

    const handleClickOutside = (event: MouseEvent) => {
      if (
        popperRef.current &&
        !popperRef.current.contains(event.target as Node)
      ) {
        closePopper();
      }
    };

    document.addEventListener("mousedown", handleClickOutside);
    getProfileData();
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, []);

  return (
    <Box sx={{ width: 500 }}>
      <Popper
        open={open}
        anchorEl={anchorEl}
        placement={placement}
        transition
        ref={popperRef}
      >
        {({ TransitionProps }) => (
          <Fade {...TransitionProps} timeout={350}>
            <Paper>
              <Box
                sx={{
                  backgroundColor: "#0288d1",
                  width: 300,
                  justifyContent: "center",
                  display: "flex",
                  color: "white",
                }}
              >
                <Grid
                  item
                  container
                  justifyContent="flex-end"
                  alignItems="flex-start"
                  sx={{
                    position: "absolute",
                    top: 0,
                    right: 0,
                    zIndex: 1,
                  }}
                >
                  <IconButton
                    size="large"
                    aria-label="cancel"
                    onClick={() => setOpen(false)}
                    color="inherit"
                  >
                    <Cancel />
                  </IconButton>
                </Grid>
                <Grid item xs={12} sx={{ justifyContent: "center", p: 3 }}>
                  <Grid item xs={12}>
                    <Stack
                      direction="row"
                      justifyContent="space-between"
                      spacing={5}
                    >
                      <Avatar
                        alt={data?.image?.name}
                        src={data?.image?.path}
                        sx={{ width: 65, height: 65 }}
                      />
                      <Typography variant="body1">
                        <Typography variant="h6">{props.types.name}</Typography>
                        <Typography sx={{ fontSize: "20px" }}>
                          {props.types.type}
                        </Typography>
                      </Typography>
                    </Stack>
                  </Grid>
                </Grid>
              </Box>
              <Typography
                sx={{
                  justifyContent: "space-between",
                  display: "flex",
                  p: "10px",
                }}
              >
                <Button
                  variant="outlined"
                  onClick={() => {
                    profile();
                  }}
                >
                  Profile
                </Button>
                <Button variant="outlined" onClick={logout}>
                  Logout
                </Button>
              </Typography>
            </Paper>
          </Fade>
        )}
      </Popper>

      <Grid container justifyContent="end">
        <Grid item>
          {props.types.name}
          <IconButton
            size="large"
            edge="end"
            aria-label="account of current user"
            aria-haspopup="true"
            onClick={handleClick("bottom-end")}
            color="inherit"
          >
            <AccountCircle />
          </IconButton>
        </Grid>
      </Grid>
    </Box>
  );
}
