import Paper from "@mui/material/Paper";
import DeleteIcon from "@mui/icons-material/Delete";
import { ChangeEvent, FormEvent, Fragment, useEffect, useState } from "react";
import {
  Box,
  Button,
  FormControl,
  FormControlLabel,
  FormLabel,
  Grid,
  IconButton,
  InputLabel,
  LinearProgress,
  MenuItem,
  Radio,
  RadioGroup,
  Select,
  SelectChangeEvent,
  Stack,
  TextField,
  Typography,
} from "@mui/material";
import { debounce } from "lodash";
import { getRequestByParams, patchRequest } from "../../api/api";
import { FIND_STUDENT_BY_ID, RE_REGISTRATION } from "../../api/server";
import CircularProgress from "@mui/material/CircularProgress";
import useTypescriptValidation from "../../Hooks/useTypescriptValidation";
import CommonSnackBar from "../../Components/Common/CommonSnackbar";

interface Files {
  id: number;
  file: File;
}

interface Student {
  admissionSemesterYear: number;
  courseName: string;
  departmentName: string;
  email: string;
  fatherName: string;
  mode: string;
  name: string;
  phone: number;
  sessionFrom: string;
  sessionTo: string;
  specialization: string;
  universityName: string;
}

interface StudentRegistration {
  studentId: string;
  enrollmentNo: string;
  courseData: {
    sessionFrom: string;
    sessionTo: string;
    admissionSemesterYear: string;
  };
  regType: string;
}

export default function ReRegistration() {
  const { eventHandler } = useTypescriptValidation();

  const [inputValue, setInputValue] = useState<{ [key: string]: string }>({});
  const [loading, setLoading] = useState<boolean>(false);
  const [showInput, setShowInput] = useState<string>("");
  const [additionalFiles, setAdditionalFiles] = useState<Files[]>([]);
  const [checkBox, setCheckBox] = useState<string | null>(null);
  const [student, setStudent] = useState<Student | undefined>(undefined);
  const [openSnackBar, setOpenSnackBar] = useState<boolean>(false);
  const [snackMessage, setSnackMessage] = useState<string>("");
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [errors, setErrors] = useState<{ [key: string]: string }>({});

  const [studentDetails, setStudentDetails] = useState<StudentRegistration>({
    studentId: "",
    enrollmentNo: "",
    courseData: {
      sessionFrom: "",
      sessionTo: "",
      admissionSemesterYear: "",
    },
    regType: "",
  });

  const handleOpenSnackBar = () => {
    setOpenSnackBar(true);
    setTimeout(() => {
      setOpenSnackBar(false);
    }, 3000);
  };

  const handleAddButtonClick = () => {
    setAdditionalFiles((prevFiles: Files[]) => [
      ...prevFiles,
      {
        id: prevFiles.length === 0 ? 0 : prevFiles[prevFiles.length - 1].id + 1,
        file: new File([], ""),
      },
    ]);
  };

  const handleFileChange = (
    e: ChangeEvent<HTMLInputElement>,
    index: number
  ) => {
    const files: FileList | null = e.target.files;
    if (files?.[0] && index !== undefined && index !== null) {
      setAdditionalFiles((prevFiles: Files[]) => {
        return prevFiles.map((file: Files) => {
          if (file.id === index) {
            return { ...file, file: files[0] };
          }
          return file;
        });
      });
    }
  };

  const handleRemoveButtonClick = (index: number, errorName: string) => {
    setAdditionalFiles((prevFiles: Files[]) =>
      prevFiles.filter((item: Files) => item.id !== index)
    );
    setErrors((preErrors: { [key: string]: string }) => ({
      ...preErrors,
      [errorName]: "",
    }));
  };

  const validationHandler = debounce(
    (e: ChangeEvent<HTMLInputElement>, alterName: string) => {
      let val = e.target.value;
      let id = alterName;
      let err = eventHandler(id, val);
      setErrors((prevData: { [key: string]: string }) => ({
        ...prevData,
        [e.target.name]: err,
      }));
      if (val === "") {
        setErrors((prevData: { [key: string]: string }) => ({
          ...prevData,
          [e.target.name]: "",
        }));
      }
    },
    2000
  );

  const handler = (
    e: ChangeEvent<HTMLInputElement> | SelectChangeEvent<string>
  ) => {
    const { name, value } = e.target;
    setStudentDetails((prevData: StudentRegistration) => {
      if (name.startsWith("courseData.")) {
        const dataName = name.replace("courseData.", "");
        return {
          ...prevData,
          courseData: {
            ...prevData.courseData,
            [dataName]: value,
          },
        };
      } else {
        return {
          ...prevData,
          [name]: value,
        };
      }
    });
  };

  const setInputField = (e: ChangeEvent<HTMLInputElement>) => {
    setCheckBox(e.target.value);
    setShowInput(e.target.value);
    setInputValue({});
    setStudent(undefined);
    setErrors((preError: { [key: string]: string }) => ({
      ...preError,
      studentId: "",
      enrollmentNo: "",
    }));
  };

  const handleInputChange = (e: ChangeEvent<HTMLInputElement>) => {
    setInputValue({ [e.target.name]: e.target.value });
  };

  const fileValidationHandler = async (
    e: ChangeEvent<HTMLInputElement>,
    alterName?: string
  ) => {
    const selectedFile: File | null = e.target.files ? e.target.files[0] : null;

    if (selectedFile) {
      const id = alterName;
      if (id) {
        let prom = new Promise<string>((resolve) => {
          if (true) {
            resolve(eventHandler(id, selectedFile));
          }
        });

        prom.then((res: string) =>
          setErrors((preErrors: { [key: string]: string }) => ({
            ...preErrors,
            [e.target.name]: res,
          }))
        );
      }
    }
  };

  const findStudent = async () => {
    setStudent(undefined);
    setLoading(true);
    let response = await getRequestByParams(
      FIND_STUDENT_BY_ID,
      "",
      "",
      inputValue
    );
    setLoading(false);
    if (response?.data.message === "Student found!") {
      setStudent(response.data.student);
      setErrors((preError: { [key: string]: string }) => ({
        ...preError,
        [response.data.key]: "",
      }));
    } else {
      setErrors((preError: { [key: string]: string }) => ({
        ...preError,
        [response.data.key]: "Student not found!",
      }));
    }
  };

  const validator = () => {
    for (let field in errors) {
      // this if is to avoid error set with undefined as key and value !== ""
      if (field !== "undefined") {
        if (errors[field] !== "") {
          return false;
        }
      }
    }
    return true;
  };

  const formClear = () => {
    setInputValue({});
    setCheckBox(null);
    setShowInput("");
    setAdditionalFiles([]);
    setStudent(undefined);
    setErrors({});
    setStudentDetails({
      studentId: "",
      enrollmentNo: "",
      courseData: {
        sessionFrom: "",
        sessionTo: "",
        admissionSemesterYear: "",
      },
      regType: "",
    });
  };

  const updateStudentInformation = async (e: FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    try {
      if (!validator()) {
        setSnackMessage("Clear all errors!");
        handleOpenSnackBar();
        return;
      }

      const formData = new FormData();
      formData.append("studentDetails", JSON.stringify(studentDetails));
      additionalFiles.forEach((file) => {
        formData.append("additionalFiles", file.file);
      });

      setIsLoading(true);
      const response = await patchRequest(
        RE_REGISTRATION,
        "",
        formData,
        "multipart/form-data"
      );
      setIsLoading(false);

      if (response && response.data && response.data.error) {
        setSnackMessage(response.data.error);
        handleOpenSnackBar();
      }

      if (response && response.data && response.data.message) {
        setSnackMessage(response.data.message);
        handleOpenSnackBar();
        formClear();
      }
    } catch (error) {
      console.error(error);
    }
  };

  useEffect(() => {
    if (
      studentDetails?.courseData.sessionFrom &&
      studentDetails?.courseData.sessionTo &&
      studentDetails?.courseData.sessionFrom !== undefined &&
      studentDetails?.courseData.sessionTo !== undefined
    ) {
      const sessionFromDate = new Date(studentDetails?.courseData.sessionFrom);
      const sessionToDate = new Date(studentDetails?.courseData.sessionTo);

      if (sessionFromDate >= sessionToDate) {
        setErrors((prevData: { [key: string]: string }) => ({
          ...prevData,
          sessionFrom: "Session From date must be less than Session To date",
          sessionTo: "Session To date must be greater than Session From date",
        }));
      } else {
        setErrors((prevData: { [key: string]: string }) => ({
          ...prevData,
          sessionFrom: "",
          sessionTo: "",
        }));
      }
    }
  }, [
    studentDetails?.courseData.sessionFrom,
    studentDetails?.courseData.sessionTo,
  ]);

  return (
    <>
      {isLoading && <LinearProgress />}
      <Paper>
        <Grid
          container
          component="form"
          padding={2}
          spacing={2}
          encType="multipart/form-data"
          onSubmit={updateStudentInformation}
        >
          <Grid item xs={12}>
            <FormControl required>
              <FormLabel id="demo-radio-buttons-group-label">
                FIND STUDENT BY
              </FormLabel>
              <RadioGroup
                aria-labelledby="demo-radio-buttons-group-label"
                name="radio-buttons-group"
                sx={{ flexDirection: "row" }}
                value={checkBox}
                onChange={setInputField}
              >
                <FormControlLabel
                  value="studentId"
                  control={<Radio />}
                  label="Registration ID"
                />
                <FormControlLabel
                  value="enrollmentNo"
                  control={<Radio />}
                  label="Enrollment No"
                />
              </RadioGroup>
            </FormControl>
          </Grid>
          {showInput === "studentId" && (
            <>
              <Grid item xs={12}>
                <TextField
                  fullWidth
                  required
                  type="text"
                  id="outlined-required"
                  label="Prerna Registration ID"
                  name="studentId"
                  value={studentDetails.studentId}
                  error={Boolean(errors.studentId)}
                  helperText={
                    loading ? <CircularProgress size={15} /> : errors.studentId
                  }
                  onBlur={findStudent}
                  onChange={(e: ChangeEvent<HTMLInputElement>) => {
                    handler(e);
                    handleInputChange(e);
                  }}
                />
              </Grid>
            </>
          )}
          {showInput === "enrollmentNo" && (
            <>
              <Grid item xs={12}>
                <TextField
                  fullWidth
                  required
                  type="text"
                  id="outlined-required"
                  label="Enrollment Number"
                  name="enrollmentNo"
                  value={studentDetails.enrollmentNo}
                  error={Boolean(errors.enrollmentNo)}
                  helperText={
                    loading ? (
                      <CircularProgress size={15} />
                    ) : (
                      errors.enrollmentNo
                    )
                  }
                  onBlur={findStudent}
                  onChange={(e: ChangeEvent<HTMLInputElement>) => {
                    handler(e);
                    handleInputChange(e);
                  }}
                />
              </Grid>
            </>
          )}
          {student !== undefined && (
            <>
              <Grid
                item
                xs={12}
                sx={{ alignItems: "center", justifyContent: "center" }}
              >
                <Box
                  sx={{
                    display: "flex",
                    boxShadow: 4,
                    padding: 4,
                    background: "white",
                  }}
                >
                  <Grid container spacing={2}>
                    <Grid item xs={12} sm={6}>
                      <Stack spacing={2}>
                        <Typography
                          fontWeight="bold"
                          style={{ textTransform: "uppercase" }}
                        >
                          {`Name: ${student?.name}`}
                        </Typography>
                        <Typography>{`Email: ${student?.email}`}</Typography>
                        <Typography>{`Phone: ${student?.phone}`}</Typography>
                        <Typography>{`Father: ${student?.fatherName}`}</Typography>
                        <Typography>{`University: ${student?.universityName}`}</Typography>
                        <Typography>{`Department: ${student?.departmentName}`}</Typography>
                      </Stack>
                    </Grid>
                    <Grid item xs={12} sm={6}>
                      <Stack spacing={2}>
                        <Typography>{`Course: ${student?.courseName}`}</Typography>
                        <Typography>{`Specialization: ${student?.specialization}`}</Typography>
                        <Typography>{`Mode: ${student?.mode}`}</Typography>
                        <Typography>{`Current Sem/Year: ${student?.admissionSemesterYear}`}</Typography>
                        <Typography>{`Session From: ${student?.sessionFrom}`}</Typography>
                        <Typography>{`Session To: ${student?.sessionTo}`}</Typography>
                      </Stack>
                    </Grid>
                  </Grid>
                </Box>
              </Grid>
            </>
          )}
          <Grid item xs={12} sm={6}>
            <FormControl fullWidth required>
              <InputLabel id="demo-simple-select-label">
                Registration Type
              </InputLabel>
              <Select
                labelId="demo-simple-select-label"
                id="demo-simple-select"
                name="regType"
                value={studentDetails.regType}
                label="Registration Type"
                required
                onChange={(e: SelectChangeEvent<string>) => handler(e)}
              >
                <MenuItem value={""}>--Select--</MenuItem>
                <MenuItem value={"Registration Renewal"}>
                  Registration Renewal
                </MenuItem>
                <MenuItem value={"Back Registration"}>
                  Back Registration
                </MenuItem>
              </Select>
            </FormControl>
          </Grid>
          <Grid item xs={12} sm={6}>
            <FormControl fullWidth>
              <TextField
                required
                id="outlined-password-input"
                label="Semester/Year"
                type="number"
                name="courseData.admissionSemesterYear"
                value={studentDetails.courseData.admissionSemesterYear}
                error={Boolean(errors.admissionSemesterYear)}
                helperText={errors.admissionSemesterYear}
                onChange={(e: ChangeEvent<HTMLInputElement>) => {
                  validationHandler(e, "number");
                  handler(e);
                }}
              />
            </FormControl>
          </Grid>
          <Grid item xs={12} sm={6}>
            <FormControl fullWidth>
              <TextField
                fullWidth
                required
                type="month"
                id="outlined-required"
                label="Session From"
                name="courseData.sessionFrom"
                value={studentDetails.courseData.sessionFrom}
                error={Boolean(errors.sessionFrom)}
                helperText={errors.sessionFrom}
                InputLabelProps={{ shrink: true }}
                onChange={(e: ChangeEvent<HTMLInputElement>) => handler(e)}
              />
            </FormControl>
          </Grid>
          <Grid item xs={12} sm={6}>
            <FormControl fullWidth>
              <TextField
                fullWidth
                required
                type="month"
                id="outlined-required"
                label="Session To"
                name="courseData.sessionTo"
                value={studentDetails.courseData.sessionTo}
                error={Boolean(errors.sessionTo)}
                helperText={errors.sessionTo}
                InputLabelProps={{ shrink: true }}
                onChange={(e: ChangeEvent<HTMLInputElement>) => handler(e)}
              />
            </FormControl>
          </Grid>

          <Grid item xs={12}>
            <Button variant="contained" onClick={handleAddButtonClick}>
              Upload Files
            </Button>
          </Grid>
          {additionalFiles?.map((data: Files) => (
            <Fragment key={data.id}>
              <Grid item xs={12} md={6}>
                <Stack direction={"row"}>
                  <TextField
                    required
                    fullWidth
                    label="Upload Files"
                    type="file"
                    name={`additionalFiles${data.id}`}
                    variant="outlined"
                    error={Boolean(errors[`additionalFiles${data.id}`])}
                    helperText={errors[`additionalFiles${data.id}`]}
                    onChange={(e: ChangeEvent<HTMLInputElement>) => {
                      handleFileChange(e, data.id);
                      fileValidationHandler(e, "pdfImageSize");
                    }}
                    InputLabelProps={{ shrink: true }}
                  />
                  <IconButton
                    onClick={() =>
                      handleRemoveButtonClick(
                        data.id,
                        `additionalFiles${data.id}`
                      )
                    }
                  >
                    <DeleteIcon color="error" />
                  </IconButton>
                </Stack>
              </Grid>
            </Fragment>
          ))}
          <Grid item xs={12} sx={{ display: "flex", justifyContent: "center" }}>
            <Stack spacing={2} direction="row">
              <Button variant="contained" type="submit">
                Submit
              </Button>
            </Stack>
            <CommonSnackBar message={snackMessage} open={openSnackBar} />
          </Grid>
        </Grid>
      </Paper>
    </>
  );
}
