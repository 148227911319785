import {
  Avatar,
  Container,
  Grid,
  InputLabel,
  Paper,
  TextField,
  Typography,
} from "@mui/material";
import { makeStyles } from "@mui/styles";

const useStyles = makeStyles(() => ({
  avatar: {
    width: 100,
    height: 100,
    borderRadius: "50%",
    border: "1px solid black",
  },
}));

export default function PersonalInformationPreview(props: any) {
  const classes = useStyles();
  const Language = props.Data?.employeeData?.language || [];
  return (
    <Container sx={{ mt: 3 }}>
      <Paper sx={{ p: 2 }} elevation={0}>
        <Grid
          container
          rowSpacing={1}
          columnSpacing={{ xs: 1, sm: 2, md: 3 }}
          sx={{ m: 2 }}
        >
          <Grid container xs={12} sm={6} md={12}>
            <Grid item xs={12}>
              <Typography fontWeight="500">Personal Details:</Typography>
            </Grid>
            <Grid item xs={12} sm={6} md={3}>
              <InputLabel
                htmlFor="standard-basic"
                sx={{ mt: 3, color: "#0288d1" }}
              >
                First Name :
              </InputLabel>
              <TextField
                id="standard-basic"
                variant="standard"
                sx={{ backgroundColor: "white" }}
                value={props.Data?.firstName}
              />
              <InputLabel
                htmlFor="standard-basic"
                sx={{ mt: 3, color: "#0288d1" }}
              >
                Date Of Birth:
              </InputLabel>
              <TextField
                id="standard-basic"
                variant="standard"
                sx={{ backgroundColor: "white" }}
                value={props.Data?.DOB}
              />
            </Grid>
            <Grid item xs={12} sm={6} md={3}>
              <InputLabel
                htmlFor="standard-basic"
                sx={{ mt: 3, color: "#0288d1" }}
              >
                Last Name:
              </InputLabel>
              <TextField
                id="standard-basic"
                variant="standard"
                sx={{ backgroundColor: "white" }}
                value={props.Data?.lastName}
              />
              <InputLabel
                htmlFor="standard-basic"
                sx={{ mt: 3, color: "#0288d1" }}
              >
                Mobile No :
              </InputLabel>
              <TextField
                id="standard-basic"
                variant="standard"
                sx={{ backgroundColor: "white" }}
                value={props.Data?.phone}
              />
            </Grid>
            <Grid item xs={12} sm={6} md={3}>
              <InputLabel
                htmlFor="standard-basic"
                sx={{ mt: 3, color: "#0288d1" }}
              >
                Email :
              </InputLabel>
              <TextField
                id="standard-basic"
                variant="standard"
                sx={{ backgroundColor: "white" }}
                value={props.Data?.email}
              />
              <InputLabel
                htmlFor="standard-basic"
                sx={{ mt: 3, color: "#0288d1" }}
              >
                Whatsapp No :
              </InputLabel>
              <TextField
                id="standard-basic"
                variant="standard"
                sx={{ backgroundColor: "white" }}
                value={props.Data?.employeeData?.contact}
              />
            </Grid>
            <Grid
              item
              xs={12}
              sm={6}
              md={3}
              display="flex"
              justifyContent={{ sm: "flex-start", md: "center" }}
            >
              <InputLabel
                htmlFor="standard-basic"
                sx={{ mt: 2, color: "#0288d1" }}
              >
                Photo :
              </InputLabel>
              <Avatar
                alt={props.Data?.image?.originalname}
                src={props.Data?.image?.path}
                sx={{ mt: 5 }}
                className={classes.avatar}
              />
            </Grid>
            <Grid container xs={12}>
              <Grid xs={12} sm={6} md={3}>
                <InputLabel
                  htmlFor="standard-basic"
                  sx={{ mt: 3, color: "#0288d1" }}
                >
                  Password :
                </InputLabel>
                <TextField
                  id="standard-basic"
                  variant="standard"
                  sx={{ backgroundColor: "white" }}
                  value={props.Data?.password}
                />
              </Grid>
              <Grid xs={12} sm={6} md={3}>
                <InputLabel
                  htmlFor="standard-basic"
                  sx={{ mt: 3, color: "#0288d1" }}
                >
                  Father Name:
                </InputLabel>
                <TextField
                  id="standard-basic"
                  variant="standard"
                  sx={{ backgroundColor: "white" }}
                  value={props.Data?.employeeData?.fatherName}
                />
              </Grid>
              <Grid xs={12} sm={6} md={3}>
                <InputLabel
                  htmlFor="standard-basic"
                  sx={{ mt: 3, color: "#0288d1" }}
                >
                  Nationality :
                </InputLabel>
                <TextField
                  id="standard-basic"
                  variant="standard"
                  sx={{ backgroundColor: "white" }}
                  value={props.Data?.employeeData?.nationality}
                />
              </Grid>
              <Grid xs={12} sm={6} md={3}>
                <InputLabel
                  htmlFor="standard-basic"
                  sx={{ mt: 3, color: "#0288d1" }}
                >
                  Marital Status :
                </InputLabel>
                <TextField
                  id="standard-basic"
                  variant="standard"
                  sx={{ backgroundColor: "white" }}
                  value={props.Data?.employeeData?.maritalStatus}
                />
              </Grid>
            </Grid>
            <Grid container xs={12}>
              <Grid xs={12} sm={6} md={3}>
                <InputLabel
                  htmlFor="standard-basic"
                  sx={{ mt: 3, color: "#0288d1" }}
                >
                  Skills :
                </InputLabel>
                <TextField
                  id="standard-basic"
                  variant="standard"
                  sx={{ backgroundColor: "white" }}
                  value={props.Data?.employeeData?.skills}
                />
              </Grid>
              <Grid xs={12} sm={6} md={3}>
                <InputLabel
                  htmlFor="standard-basic"
                  sx={{ mt: 3, color: "#0288d1" }}
                >
                  Hobbies :
                </InputLabel>
                <TextField
                  id="standard-basic"
                  variant="standard"
                  sx={{ backgroundColor: "white" }}
                  value={props.Data?.employeeData?.hobbies}
                />
              </Grid>
            </Grid>
            <Grid item xs={12}>
              <InputLabel
                htmlFor="standard-basic"
                sx={{ mt: 2, color: "#0288d1", mb: 2 }}
              >
                Language Known:
              </InputLabel>
            </Grid>
            <Grid container xs={12} sx={{ mb: 5 }}>
              {Language.map((language: any, index: number) => (
                <>
                  {language !== null ? (
                    <Grid xs={12} sm={6} md={3}>
                      <TextField
                        id="standard-basic"
                        variant="standard"
                        sx={{ backgroundColor: "white" }}
                        value={language.language}
                      />
                    </Grid>
                  ) : null}
                </>
              ))}
            </Grid>
          </Grid>

          {/* Address Details */}
          <Grid
            container
            rowSpacing={1}
            columnSpacing={{ xs: 1, sm: 2, md: 3 }}
            sx={{ mb: 5 }}
          >
            {/* Permanent Address */}
            <Grid item xs={12}>
              <Typography fontWeight="450" sx={{ mt: 2 }}>
                Address Details
              </Typography>
            </Grid>
            <Grid item xs={12} sm={6} md={4}>
              <InputLabel
                htmlFor="standard-basic"
                sx={{ mt: 2, color: "#0288d1" }}
              >
                Permanent Address:
              </InputLabel>
              <TextField
                id="standard-basic"
                variant="standard"
                sx={{ backgroundColor: "white" }}
                value={props.Data?.employeeData?.permanentAddress}
              />
            </Grid>

            <Grid item xs={12} sm={6} md={4}>
              <InputLabel
                htmlFor="standard-basic"
                sx={{ mt: 2, color: "#0288d1" }}
              >
                Present Address:
              </InputLabel>
              <TextField
                id="standard-basic"
                variant="standard"
                sx={{ backgroundColor: "white" }}
                value={props.Data?.employeeData?.currentAddress}
              />
            </Grid>
            <Grid item xs={12} sm={6} md={4}>
              <InputLabel
                htmlFor="standard-basic"
                sx={{ mt: 2, color: "#0288d1" }}
              >
                State:
              </InputLabel>
              <TextField
                id="standard-basic"
                variant="standard"
                sx={{ backgroundColor: "white" }}
                value={props.Data?.state}
              />
            </Grid>
            <Grid item xs={12} sm={6} md={4}>
              <InputLabel
                htmlFor="standard-basic"
                sx={{ mt: 2, color: "#0288d1" }}
              >
                City:
              </InputLabel>
              <TextField
                id="standard-basic"
                variant="standard"
                sx={{ backgroundColor: "white" }}
                value={props.Data?.city}
              />
            </Grid>
            <Grid item xs={12} sm={6} md={4}>
              <InputLabel
                htmlFor="standard-basic"
                sx={{ mt: 2, color: "#0288d1" }}
              >
                Zipcode:
              </InputLabel>
              <TextField
                id="standard-basic"
                variant="standard"
                sx={{ backgroundColor: "white" }}
                value={props.Data?.zipCode}
              />
            </Grid>
          </Grid>
        </Grid>
      </Paper>
    </Container>
  );
}
