import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import KeyboardArrowUpIcon from "@mui/icons-material/KeyboardArrowUp";
import SearchIcon from "@mui/icons-material/Search";
import {
  Button,
  Container,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  FormControl,
  Grid,
  IconButton,
  InputAdornment,
  InputLabel,
  MenuItem,
  Select,
  TablePagination,
  Typography
} from "@mui/material";
import Paper from "@mui/material/Paper";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import TextField from "@mui/material/TextField";
import { PDFDownloadLink } from "@react-pdf/renderer";
import { Fragment, useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import CommonSnackBar from "../../Components/Common/CommonSnackbar";
import TableToExcel from "../../Components/Common/TableToExcel";
import stateCity from "../../Components/Common/stateCity.json";
import { getRequestByParams, updateRequest } from "../../api/api";
import { GET_STUDENT_DETAILS, UPDATE_STUDENT_DETAILS } from "../../api/server";
import StudentInfoLetter from "./StudentInfoLetter";

interface FilterData {
  regType: string;
  permanentState: string;
  admissionDate: string;
  fromDate: string;
  toDate: string;
}

export default function ManageStudent(props: any) {
  const navigate = useNavigate();
  const { types } = props;

  const [page, setPage] = useState<number>(0);
  const [rowsPerPage, setRowsPerPage] = useState<number>(10);
  const [backendData, setBackendData] = useState<any[]>([]);
  const [mappingData, setMappingData] = useState<any[]>([]);
  const [open, setOpen] = useState(new Array(mappingData?.length).fill(false));
  const [statescity] = useState<any>(stateCity);
  const [openInstallment, setOpenInstallment] = useState<boolean>(false);
  const [searchApiData, setSearchApiData] = useState<any[]>([]);
  const [filterVal, setFilterVal] = useState<string>("");
  const [openStatus, setOpenStatus] = useState<boolean>(false);
  const [studentDetails, setStudentDetails] = useState<any>();
  const [snackMessage, setSnackMessage] = useState<string>("");
  const [openSnackBar, setOpenSnackBar] = useState<boolean>(false);

  const [filterData, setFilterData] = useState<FilterData>({
    regType: "",
    permanentState: "",
    admissionDate: "",
    fromDate: "",
    toDate: "",
  });

  const [status, setStatus] = useState({
    status: undefined,
    statusRemark: undefined,
  });

  const fetchData = async () => {
    try {
      let result = await getRequestByParams(
        GET_STUDENT_DETAILS,
        types.lId,
        "",
        types
      );
      setBackendData(result?.data);
      setMappingData(result?.data);
      setSearchApiData(result?.data);
    } catch (error) {
      console.error("An error occurred:", error);
    }
  };

  const handleDropdownFilter = (e: any) => {
    setFilterData((preFilter: FilterData) => ({
      ...preFilter,
      [e.target.name]: e.target.value,
    }));
  };

  const handleChangePage = (event: any, newPage: any) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event: any) => {
    setRowsPerPage(+event.target.value);
    setPage(0);
  };

  const handleFilter = (e: any) => {
    if (e.target.value === "") {
      setMappingData(searchApiData);
    } else {
      const searchValue = String(e.target.value).toLowerCase().trim();
      const filterResult = searchApiData.filter((item: any) => {
        return Object.keys(item).some((key) => {
          const value = item[key];
          if (typeof value === "string" || typeof value === "number") {
            const itemValue = String(value).toLowerCase();
            return itemValue.includes(searchValue);
          } else if (typeof value === "object") {
            return deepSearch(value, searchValue);
          }
          return false;
        });
      });
      setMappingData(filterResult);
    }
    setFilterVal(e.target.value);
  };

  const deepSearch = (obj: any, searchValue: string): boolean => {
    for (const key in obj) {
      if (obj.hasOwnProperty(key)) {
        const value = obj[key];
        if (typeof value === "string" || typeof value === "number") {
          const itemValue = String(value).toLowerCase();
          if (itemValue.includes(searchValue)) {
            return true;
          }
        } else if (typeof value === "object") {
          if (deepSearch(value, searchValue)) {
            return true;
          }
        }
      }
    }
    return false;
  };

  const handleOpenSnackBar = (message: string) => {
    try {
      setSnackMessage(message);
      setOpenSnackBar(true);
    } catch (error) {
      console.error("Error in handleOpenSnackBar:", error);
    }
  };

  const handleCloseSnackBar = () => {
    try {
      setOpenSnackBar(false);
    } catch (error) {
      console.error("Error in handleCloseSnackBar:", error);
    }
  };

  const handleChangeStudent = async (e: any) => {
    setStudentDetails({ ...studentDetails, [e.target.name]: e.target.value });
    await updateRequest(
      UPDATE_STUDENT_DETAILS,
      studentDetails._id,
      { [e.target.name]: e.target.value },
      ""
    );
    handleOpenSnackBar("Updated details Successfully...!");
  };

  const statusSubmit = async () => {
    if (status.status && status.statusRemark) {
      await updateRequest(
        UPDATE_STUDENT_DETAILS,
        studentDetails._id,
        status,
        ""
      );
      setOpenStatus(false);
      handleOpenSnackBar("Status Updated Successfully...!");
      setStatus({ status: undefined, statusRemark: undefined });
      fetchData();
    }
  };

  const handleDialogueBoxClose = (index: number) => {
    const newOpen = [...open];
    newOpen[index] = !newOpen[index];
    setOpen(newOpen);
    fetchData();
  };

  const modifiedTableData = mappingData.map((row: any) => {
    const assignedCenterHead = `${row.chFirstName || ""} ${
      row.chLastName || ""
    } ${row.chEmpId || ""}`;
    const assignedTeamLead = `${row.tlFirstName || ""} ${
      row.tlLastName || ""
    } ${row.tlEmpId || ""}`;
    const assignedExecutive = `${row.exFirstName || ""} ${
      row.exLastName || ""
    } ${row.exEmpId || ""}`;
    return {
      ...row,
      Name: `${row.studentData.firstName} ${row.studentData.lastName}`,
      "Center Head": assignedCenterHead,
      TeamLead: assignedTeamLead,
      Executive: assignedExecutive,
    };
  });

  useEffect(() => {
    const filteredData = backendData?.filter((item: any) => {
      const matchesRegistrationType =
        item.regType.toLowerCase() === filterData.regType?.toLowerCase();
      return matchesRegistrationType;
    });
    setMappingData(filteredData);
  }, [filterData.regType]);

  useEffect(() => {
    const filteredData = backendData?.filter((item: any) => {
      const matchesState = item.studentData.permanentState
        ?.toLowerCase()
        .includes(filterData.permanentState.toLowerCase());
      return matchesState;
    });
    setMappingData(filteredData);
  }, [filterData.permanentState]);

  useEffect(() => {
    const filteredData = backendData?.filter((item: any) => {
      const admissionDate = item.courseData?.admissionDate
        ? item.courseData?.admissionDate
        : "";
      const today: Date = new Date();
      const backendDate: Date = new Date(admissionDate);
      const timeDiff = Math.abs(today.getTime() - backendDate.getTime());
      const diffInMonths = Math.floor(timeDiff / (1000 * 3600 * 24 * 30.4375));
      return diffInMonths <= Number(filterData.admissionDate);
    });
    setMappingData(filteredData);
  }, [filterData.admissionDate]);

  useEffect(() => {
    const filteredData = backendData?.filter((item) => {
      const admissionDate = item.courseData?.admissionDate
        ? new Date(item.courseData.admissionDate)
        : "";
      const fromDate = new Date(filterData.fromDate);
      const toDate = new Date(filterData.toDate);
      return admissionDate >= fromDate && admissionDate <= toDate;
    });
    setMappingData(filteredData);
  }, [filterData.fromDate, filterData.toDate]);

  useEffect(() => {
    fetchData();
  }, []);

  return (
    <>
      <Container>
        <Paper elevation={20}>
          <Grid container padding={1} spacing={1}>
            <Grid
              item
              xs={6}
              sx={{
                display: "flex",
                justifyContent: "space-evenly",
                alignItems: "center",
              }}
            >
              <Typography>Registration :</Typography>
              <Select
                name="regType"
                value={filterData.regType}
                style={{ width: "50%", height: "60%" }}
                size="medium"
                onChange={handleDropdownFilter}
              >
                <MenuItem value="">--Select--</MenuItem>
                <MenuItem value="New">New</MenuItem>
                <MenuItem value="Registration Renewal">
                  Registration Renewal
                </MenuItem>
                <MenuItem value="Back Registration">Back Registration</MenuItem>
              </Select>
            </Grid>
            <Grid
              item
              xs={6}
              sx={{
                display: "flex",
                justifyContent: "space-evenly",
                alignItems: "center",
              }}
            >
              <Typography>Month Wise :</Typography>
              <Select
                name="admissionDate"
                value={filterData.admissionDate}
                style={{ width: "50%", height: "60%" }}
                size="medium"
                onChange={handleDropdownFilter}
              >
                <MenuItem value="">--Select--</MenuItem>
                <MenuItem value="1">Last Month</MenuItem>
                <MenuItem value="3">Last 3 Month</MenuItem>
                <MenuItem value="6">Last 6 Month</MenuItem>
                <MenuItem value="12">Last Year</MenuItem>
              </Select>
            </Grid>
            <Grid
              item
              xs={6}
              sx={{
                display: "flex",
                justifyContent: "space-evenly",
                alignItems: "center",
              }}
            >
              <Typography>State Wise :</Typography>
              <Select
                style={{ width: "50%", height: "60%" }}
                size="medium"
                labelId="demo-simple-select-label"
                id="demo-simple-select"
                name="permanentState"
                defaultValue=""
                value={filterData.permanentState}
                onChange={handleDropdownFilter}
              >
                {statescity.map((item: any) => (
                  <MenuItem key={item.state_name} value={item.state_name}>
                    {item.state_name}
                  </MenuItem>
                ))}
              </Select>
            </Grid>
            <Grid
              item
              xs={6}
              sx={{
                display: "flex",
                justifyContent: "space-evenly",
                alignItems: "center",
              }}
            >
              <Typography>Date Wise :</Typography>
              <TextField
                name="fromDate"
                value={filterData.fromDate}
                sx={{
                  width: "30%",
                  background: "white",
                  "& .MuiOutlinedInput-input": {
                    height: 2,
                  },
                }}
                type="month"
                onChange={handleDropdownFilter}
              ></TextField>
              <TextField
                name="toDate"
                value={filterData.toDate}
                sx={{
                  width: "30%",
                  background: "white",
                  "& .MuiOutlinedInput-input": {
                    height: 2,
                  },
                }}
                type="month"
                onChange={handleDropdownFilter}
              ></TextField>
            </Grid>
          </Grid>
          <Grid
            container
            sx={{
              p: 2,
              background: "#0288d1",
              color: "white",
            }}
          >
            <Grid item xs={6}>
              <Typography variant="h5" sx={{ mx: 3 }}>
                Manage Student
              </Typography>
            </Grid>
            <Grid item xs={3}>
              <TableToExcel
                tableData={modifiedTableData}
                fileName="Student_data"
                headerMapping={{
                  "PA Enrollment": "studentId",
                  Status: "status",
                  "Registration Type": "regType",
                  Name: "Name",
                  "Father Name": "studentData.fatherName",
                  "Email Id": "studentData.email",
                  "Phone Number": "studentData.contactNumber",
                  "Center Head": "Center Head",
                  "Team Lead": "TeamLead",
                  Executive: "Executive",
                  "User Name": "userName",
                  Password: "password",
                }}
              />
            </Grid>
            <Grid
              item
              xs={3}
              sx={{ display: "flex", justifyContent: "flex-end" }}
            >
              <FormControl>
                <TextField
                  label="Search by name and id"
                  size="small"
                  value={filterVal}
                  onInput={(e: any) => handleFilter(e)}
                  sx={{ mx: 3, background: "white" }}
                  InputProps={{
                    endAdornment: (
                      <InputAdornment position="end">
                        <IconButton edge="end">
                          <SearchIcon />
                        </IconButton>
                      </InputAdornment>
                    ),
                  }}
                />
              </FormControl>
            </Grid>
          </Grid>
          <TableContainer sx={{ p: 1 }} className="scrollBarCss">
            <Table sx={{ minWidth: 650 }} aria-label="simple table">
              <TableHead>
                <TableRow>
                  <TableCell
                    sx={{
                      textAlign: "center",
                      border: "1px solid #ddd",
                      fontSize: "15px",
                    }}
                  ></TableCell>
                  <TableCell
                    sx={{
                      textAlign: "center",
                      border: "1px solid #ddd",
                      fontSize: "15px",
                    }}
                  >
                    Sr.No
                  </TableCell>
                  <TableCell
                    sx={{
                      textAlign: "center",
                      border: "1px solid #ddd",
                      fontSize: "15px",
                    }}
                  >
                    PA Enrollment
                  </TableCell>
                  <TableCell
                    sx={{
                      textAlign: "center",
                      border: "1px solid #ddd",
                      fontSize: "15px",
                    }}
                  >
                    Status
                  </TableCell>
                  <TableCell
                    sx={{
                      textAlign: "center",
                      border: "1px solid #ddd",
                      fontSize: "15px",
                    }}
                  >
                    Registration Type
                  </TableCell>
                  <TableCell
                    sx={{
                      textAlign: "center",
                      border: "1px solid #ddd",
                      fontSize: "15px",
                    }}
                  >
                    Student Name
                  </TableCell>
                  <TableCell
                    sx={{
                      textAlign: "center",
                      border: "1px solid #ddd",
                      fontSize: "15px",
                    }}
                  >
                    Father Name
                  </TableCell>
                  <TableCell
                    sx={{
                      textAlign: "center",
                      border: "1px solid #ddd",
                      fontSize: "15px",
                    }}
                  >
                    Center Head
                  </TableCell>
                  <TableCell
                    sx={{
                      textAlign: "center",
                      border: "1px solid #ddd",
                      fontSize: "15px",
                    }}
                  >
                    Team Lead
                  </TableCell>
                  <TableCell
                    sx={{
                      textAlign: "center",
                      border: "1px solid #ddd",
                      fontSize: "15px",
                    }}
                  >
                    Executive
                  </TableCell>
                  <TableCell
                    sx={{
                      textAlign: "center",
                      border: "1px solid #ddd",
                      fontSize: "15px",
                    }}
                  >
                    Email Id
                  </TableCell>
                  <TableCell
                    sx={{
                      textAlign: "center",
                      border: "1px solid #ddd",
                      fontSize: "15px",
                    }}
                  >
                    User Name
                  </TableCell>
                  {types.type === "Administrator" && (
                    <TableCell
                      sx={{
                        textAlign: "center",
                        border: "1px solid #ddd",
                        fontSize: "15px",
                      }}
                    >
                      Password
                    </TableCell>
                  )}
                  <TableCell
                    sx={{
                      textAlign: "center",
                      border: "1px solid #ddd",
                      fontSize: "15px",
                    }}
                  >
                    Phone Number
                  </TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {mappingData
                  ?.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                  .map((item: any, index: number) => (
                    <Fragment key={item._id}>
                      <TableRow key={item._id}>
                        <TableCell
                          sx={{
                            textAlign: "center",
                            border: "1px solid #ddd",
                          }}
                        >
                          <IconButton
                            aria-label="expand row"
                            size="small"
                            onClick={() => {
                              const newOpen = [...open];
                              newOpen[index] = !newOpen[index];
                              setOpen(newOpen);
                              setStudentDetails(item);
                            }}
                          >
                            {open[index] ? (
                              <KeyboardArrowUpIcon />
                            ) : (
                              <KeyboardArrowDownIcon />
                            )}
                          </IconButton>
                        </TableCell>

                        <TableCell
                          sx={{
                            textAlign: "center",
                            border: "1px solid #ddd",
                          }}
                        >
                          {page * rowsPerPage + index + 1}
                        </TableCell>
                        <TableCell
                          sx={{
                            textAlign: "center",
                            border: "1px solid #ddd",
                          }}
                        >
                          {item.studentId}
                        </TableCell>
                        <TableCell
                          sx={{
                            textAlign: "center",
                            border: "1px solid #ddd",
                          }}
                        >
                          {item.status}
                        </TableCell>
                        <TableCell
                          sx={{
                            textAlign: "center",
                            border: "1px solid #ddd",
                          }}
                        >
                          {item.regType}
                        </TableCell>
                        <TableCell
                          sx={{
                            textAlign: "center",
                            border: "1px solid #ddd",
                          }}
                        >
                          {item.studentData.firstName +
                            " " +
                            item.studentData.lastName}
                        </TableCell>
                        <TableCell
                          sx={{
                            textAlign: "center",
                            border: "1px solid #ddd",
                          }}
                        >
                          {item.studentData.fatherName}
                        </TableCell>
                        <TableCell
                          sx={{
                            textAlign: "center",
                            border: "1px solid #ddd",
                          }}
                        >
                          {`${item?.chFirstName} ${item?.chLastName} (${item?.chEmpId})`}
                        </TableCell>
                        <TableCell
                          sx={{
                            textAlign: "center",
                            border: "1px solid #ddd",
                          }}
                        >
                          {`${item?.tlFirstName} ${item?.tlLastName} (${item?.tlEmpId})`}
                        </TableCell>
                        <TableCell
                          sx={{
                            textAlign: "center",
                            border: "1px solid #ddd",
                          }}
                        >
                          {`${item?.exFirstName} ${item?.exLastName} (${item?.exEmpId})`}
                        </TableCell>
                        <TableCell
                          sx={{
                            textAlign: "center",
                            border: "1px solid #ddd",
                          }}
                        >
                          {item.studentData.email}
                        </TableCell>
                        <TableCell
                          sx={{
                            textAlign: "center",
                            border: "1px solid #ddd",
                          }}
                        >
                          {item.userName}
                        </TableCell>
                        {types.type === "Administrator" && (
                          <TableCell
                            sx={{
                              textAlign: "center",
                              border: "1px solid #ddd",
                            }}
                          >
                            {item.password}
                          </TableCell>
                        )}
                        <TableCell
                          sx={{
                            textAlign: "center",
                            border: "1px solid #ddd",
                          }}
                        >
                          {item.studentData.contactNumber}
                        </TableCell>
                      </TableRow>

                      <Dialog
                        open={open[index]}
                        maxWidth={"xl"}
                        onClose={() => handleDialogueBoxClose(index)}
                      >
                        <TableContainer
                          className="scrollBarCss"
                          sx={{
                            border: "2px solid #ddd",
                            padding: "10px",
                            fontSize: "15px",
                          }}
                        >
                          <TableHead>
                            <TableRow>
                              <TableCell
                                sx={{
                                  textAlign: "center",
                                  border: "2px solid #ddd",
                                  borderRight: "none",
                                  fontSize: "15px",
                                }}
                              >
                                Name:
                              </TableCell>
                              <TableCell
                                sx={{
                                  textAlign: "center",
                                  border: "2px solid #ddd",
                                  borderLeft: "none",
                                  fontSize: "15px",
                                }}
                              >
                                {studentDetails?.studentData?.firstName}
                              </TableCell>
                              <TableCell
                                sx={{
                                  textAlign: "center",
                                  border: "2px solid #ddd",
                                  borderRight: "none",
                                  fontSize: "15px",
                                }}
                              >
                                PA Enrollment :
                              </TableCell>
                              <TableCell
                                sx={{
                                  textAlign: "center",
                                  border: "2px solid #ddd",
                                  borderLeft: "none",
                                  fontSize: "15px",
                                }}
                              >
                                {studentDetails?.enrollmentNo}
                              </TableCell>
                            </TableRow>

                            <TableRow>
                              <TableCell
                                sx={{
                                  textAlign: "center",
                                  border: "2px solid #ddd",
                                  borderRight: "none",
                                  fontSize: "15px",
                                }}
                              >
                                State :
                              </TableCell>
                              <TableCell
                                sx={{
                                  textAlign: "center",
                                  border: "2px solid #ddd",
                                  borderLeft: "none",
                                  fontSize: "15px",
                                }}
                              >
                                {studentDetails?.studentData?.presentState}
                              </TableCell>
                              <TableCell
                                sx={{
                                  textAlign: "center",
                                  border: "2px solid #ddd",
                                  borderRight: "none",
                                  fontSize: "15px",
                                }}
                              >
                                University Enrollment :
                              </TableCell>
                              <TableCell
                                sx={{
                                  textAlign: "center",
                                  border: "2px solid #ddd",
                                  borderLeft: "none",
                                  fontSize: "15px",
                                }}
                              >
                                {studentDetails?.enrollmentNo}
                              </TableCell>
                            </TableRow>
                            <TableRow>
                              <TableCell
                                sx={{
                                  textAlign: "center",
                                  border: "2px solid #ddd",
                                  borderRight: "none",
                                  fontSize: "15px",
                                }}
                              >
                                Course :
                              </TableCell>
                              <TableCell
                                sx={{
                                  textAlign: "center",
                                  border: "2px solid #ddd",
                                  borderLeft: "none",
                                  fontSize: "15px",
                                }}
                              >
                                {studentDetails?.courseData?.courseName}
                              </TableCell>
                              <TableCell
                                sx={{
                                  textAlign: "center",
                                  border: "2px solid #ddd",
                                  borderRight: "none",
                                  fontSize: "15px",
                                }}
                              >
                                Course Type :
                              </TableCell>
                              <TableCell
                                sx={{
                                  textAlign: "center",
                                  border: "2px solid #ddd",
                                  borderLeft: "none",
                                  fontSize: "15px",
                                }}
                              >
                                {studentDetails?.courseData?.courseCategory}
                              </TableCell>
                            </TableRow>
                            <TableRow>
                              <TableCell
                                sx={{
                                  textAlign: "center",
                                  border: "2px solid #ddd",
                                  borderRight: "none",
                                  fontSize: "15px",
                                }}
                              >
                                Total year/sem :
                              </TableCell>
                              <TableCell
                                sx={{
                                  textAlign: "center",
                                  border: "2px solid #ddd",
                                  borderLeft: "none",
                                  fontSize: "15px",
                                }}
                              >
                                {studentDetails?.courseData?.noOfSemsOrYears}
                              </TableCell>
                              <TableCell
                                sx={{
                                  textAlign: "center",
                                  border: "2px solid #ddd",
                                  borderRight: "none",
                                  fontSize: "15px",
                                }}
                              >
                                Courses year/sem :
                              </TableCell>
                              <TableCell
                                sx={{
                                  textAlign: "center",
                                  border: "2px solid #ddd",
                                  borderLeft: "none",
                                  fontSize: "15px",
                                }}
                              >
                                {
                                  studentDetails?.courseData
                                    ?.admissionSemesterYear
                                }
                              </TableCell>
                            </TableRow>
                            <TableRow>
                              <TableCell
                                sx={{
                                  textAlign: "center",
                                  border: "2px solid #ddd",
                                  borderRight: "none",
                                  fontSize: "15px",
                                }}
                              >
                                Course Fees :
                              </TableCell>
                              <TableCell
                                sx={{
                                  textAlign: "center",
                                  border: "2px solid #ddd",
                                  borderLeft: "none",
                                  fontSize: "15px",
                                }}
                              >
                                {studentDetails?.courseData?.courseFees}
                              </TableCell>
                              <TableCell
                                sx={{
                                  textAlign: "center",
                                  border: "2px solid #ddd",
                                  borderRight: "none",
                                  fontSize: "15px",
                                }}
                              >
                                Admission Date :
                              </TableCell>
                              <TableCell
                                sx={{
                                  textAlign: "center",
                                  border: "2px solid #ddd",
                                  borderLeft: "none",
                                  fontSize: "15px",
                                }}
                              >
                                {studentDetails?.courseData?.admissionDate}
                              </TableCell>
                            </TableRow>

                            <TableRow>
                              <TableCell
                                sx={{
                                  textAlign: "center",
                                  border: "2px solid #ddd",
                                  borderRight: "none",
                                  fontSize: "15px",
                                }}
                              >
                                Fees Received :
                              </TableCell>
                              <TableCell
                                sx={{
                                  textAlign: "center",
                                  border: "2px solid #ddd",
                                  borderLeft: "none",
                                  fontSize: "15px",
                                }}
                              >
                                {studentDetails?.courseData?.feesReceived}
                              </TableCell>
                              <TableCell
                                sx={{
                                  textAlign: "center",
                                  border: "2px solid #ddd",
                                  borderRight: "none",
                                  fontSize: "15px",
                                }}
                              >
                                Registration Fees :
                              </TableCell>
                              <TableCell
                                sx={{
                                  textAlign: "center",
                                  border: "2px solid #ddd",
                                  borderLeft: "none",
                                  fontSize: "15px",
                                }}
                              >
                                {studentDetails?.courseData?.regFees}
                              </TableCell>
                            </TableRow>

                            <TableRow>
                              <TableCell
                                sx={{
                                  textAlign: "center",
                                  border: "2px solid #ddd",
                                  borderRight: "none",
                                  fontSize: "15px",
                                }}
                              >
                                Caution Money :
                              </TableCell>
                              <TableCell
                                sx={{
                                  textAlign: "center",
                                  border: "2px solid #ddd",
                                  borderLeft: "none",
                                  fontSize: "15px",
                                }}
                              >
                                {studentDetails?.courseData?.cautionMoney}
                              </TableCell>
                              <TableCell
                                sx={{
                                  textAlign: "center",
                                  border: "2px solid #ddd",
                                  borderRight: "none",
                                  fontSize: "15px",
                                }}
                              >
                                Other Fees :
                              </TableCell>
                              <TableCell
                                sx={{
                                  textAlign: "center",
                                  border: "2px solid #ddd",
                                  borderLeft: "none",
                                  fontSize: "15px",
                                }}
                              >
                                {studentDetails?.courseData?.otherFees}
                              </TableCell>
                            </TableRow>

                            <TableRow>
                              <TableCell
                                sx={{
                                  textAlign: "center",
                                  border: "2px solid #ddd",
                                  borderRight: "none",
                                  fontSize: "15px",
                                }}
                              >
                                Total Discounted Fee :
                              </TableCell>
                              <TableCell
                                sx={{
                                  textAlign: "center",
                                  border: "2px solid #ddd",
                                  borderLeft: "none",
                                  fontSize: "15px",
                                }}
                              >
                                {studentDetails?.courseData?.totalFee}
                              </TableCell>
                              <TableCell
                                sx={{
                                  textAlign: "center",
                                  border: "2px solid #ddd",
                                  borderRight: "none",
                                  fontSize: "15px",
                                }}
                              >
                                Discount(%) :
                              </TableCell>
                              <TableCell
                                sx={{
                                  textAlign: "center",
                                  border: "2px solid #ddd",
                                  borderLeft: "none",
                                  fontSize: "15px",
                                }}
                              >
                                {studentDetails?.courseData?.discount}
                              </TableCell>
                            </TableRow>

                            <TableRow>
                              <TableCell
                                sx={{
                                  textAlign: "center",
                                  border: "2px solid #ddd",
                                  borderRight: "none",
                                  fontSize: "15px",
                                }}
                              >
                                Enrollment No.:
                              </TableCell>
                              <TableCell
                                sx={{
                                  textAlign: "center",
                                  border: "2px solid #ddd",
                                  borderLeft: "none",
                                  fontSize: "15px",
                                }}
                              >
                                <FormControl
                                  size="small"
                                  sx={{ width: "80%" }}
                                  required
                                >
                                  <Select
                                    sx={{ textAlign: "center" }}
                                    className="form-control"
                                    type="text"
                                    value={studentDetails?.enrollmentNo}
                                    required
                                    name="enrollmentNo"
                                    onChange={handleChangeStudent}
                                  >
                                    <MenuItem value={"NA"}>NA</MenuItem>
                                    <MenuItem value={"Yes"}>Yes</MenuItem>
                                    <MenuItem value={"No"}>No</MenuItem>
                                  </Select>
                                </FormControl>
                              </TableCell>
                              <TableCell
                                sx={{
                                  textAlign: "center",
                                  border: "2px solid #ddd",
                                  borderRight: "none",
                                  fontSize: "15px",
                                }}
                              >
                                Id Card :
                              </TableCell>
                              <TableCell
                                sx={{
                                  textAlign: "center",
                                  border: "2px solid #ddd",
                                  borderLeft: "none",
                                  fontSize: "15px",
                                }}
                              >
                                <FormControl
                                  size="small"
                                  sx={{ width: "80%" }}
                                  required
                                >
                                  <Select
                                    sx={{ textAlign: "center" }}
                                    className="form-control"
                                    type="text"
                                    value={studentDetails?.idCard}
                                    required
                                    name="idCard"
                                    onChange={handleChangeStudent}
                                  >
                                    <MenuItem value={"NA"}>NA</MenuItem>
                                    <MenuItem value={"Yes"}>Yes</MenuItem>
                                    <MenuItem value={"No"}>No</MenuItem>
                                  </Select>
                                </FormControl>
                              </TableCell>
                            </TableRow>

                            <TableRow>
                              <TableCell
                                sx={{
                                  textAlign: "center",
                                  border: "2px solid #ddd",
                                  borderRight: "none",
                                  fontSize: "15px",
                                }}
                              >
                                Books :
                              </TableCell>
                              <TableCell
                                sx={{
                                  textAlign: "center",
                                  border: "2px solid #ddd",
                                  borderLeft: "none",
                                  fontSize: "15px",
                                }}
                              >
                                <FormControl
                                  size="small"
                                  sx={{ width: "80%" }}
                                  required
                                >
                                  <Select
                                    sx={{ textAlign: "center" }}
                                    className="form-control"
                                    type="text"
                                    value={studentDetails?.books}
                                    required
                                    name="books"
                                    onChange={handleChangeStudent}
                                  >
                                    <MenuItem value={"NA"}>NA</MenuItem>
                                    <MenuItem value={"Yes"}>Yes</MenuItem>
                                    <MenuItem value={"No"}>No</MenuItem>
                                  </Select>
                                </FormControl>
                              </TableCell>
                              <TableCell
                                sx={{
                                  textAlign: "center",
                                  border: "2px solid #ddd",
                                  borderRight: "none",
                                  fontSize: "15px",
                                }}
                              >
                                Hall ticket/Admit Card :
                              </TableCell>
                              <TableCell
                                sx={{
                                  textAlign: "center",
                                  border: "2px solid #ddd",
                                  borderLeft: "none",
                                  fontSize: "15px",
                                }}
                              >
                                <FormControl
                                  size="small"
                                  sx={{ width: "80%" }}
                                  required
                                >
                                  <Select
                                    sx={{ textAlign: "center" }}
                                    className="form-control"
                                    type="text"
                                    value={
                                      studentDetails?.hallTicketOrAdmitCard
                                    }
                                    required
                                    name="hallTicketOrAdmitCard"
                                    onChange={handleChangeStudent}
                                  >
                                    <MenuItem value={"NA"}>NA</MenuItem>
                                    <MenuItem value={"Yes"}>Yes</MenuItem>
                                    <MenuItem value={"No"}>No</MenuItem>
                                  </Select>
                                </FormControl>
                              </TableCell>
                            </TableRow>

                            <TableRow>
                              <TableCell
                                sx={{
                                  textAlign: "center",
                                  border: "2px solid #ddd",
                                  borderRight: "none",
                                  fontSize: "15px",
                                }}
                              >
                                Result Online :
                              </TableCell>
                              <TableCell
                                sx={{
                                  textAlign: "center",
                                  border: "2px solid #ddd",
                                  borderLeft: "none",
                                  fontSize: "15px",
                                }}
                              >
                                <FormControl
                                  size="small"
                                  sx={{ width: "80%" }}
                                  required
                                >
                                  <Select
                                    sx={{ textAlign: "center" }}
                                    className="form-control"
                                    type="text"
                                    value={studentDetails?.resultOnline}
                                    required
                                    name="resultOnline"
                                    onChange={handleChangeStudent}
                                  >
                                    <MenuItem value={"NA"}>NA</MenuItem>
                                    <MenuItem value={"Yes"}>Yes</MenuItem>
                                    <MenuItem value={"No"}>No</MenuItem>
                                  </Select>
                                </FormControl>
                              </TableCell>
                              <TableCell
                                sx={{
                                  textAlign: "center",
                                  border: "2px solid #ddd",
                                  borderRight: "none",
                                  fontSize: "15px",
                                }}
                              >
                                Result Offiline :
                              </TableCell>
                              <TableCell
                                sx={{
                                  textAlign: "center",
                                  border: "2px solid #ddd",
                                  borderLeft: "none",
                                  fontSize: "15px",
                                }}
                              >
                                <FormControl
                                  size="small"
                                  sx={{ width: "80%" }}
                                  required
                                >
                                  <Select
                                    sx={{ textAlign: "center" }}
                                    className="form-control"
                                    type="text"
                                    value={studentDetails?.resultOffline}
                                    required
                                    name="resultOffline"
                                    onChange={handleChangeStudent}
                                  >
                                    <MenuItem value={"NA"}>NA</MenuItem>
                                    <MenuItem value={"Yes"}>Yes</MenuItem>
                                    <MenuItem value={"No"}>No</MenuItem>
                                  </Select>
                                </FormControl>
                              </TableCell>
                            </TableRow>

                            <TableRow>
                              <TableCell
                                sx={{
                                  textAlign: "center",
                                  border: "2px solid #ddd",
                                  borderRight: "none",
                                  fontSize: "15px",
                                }}
                              >
                                Mark sheet :
                              </TableCell>
                              <TableCell
                                sx={{
                                  textAlign: "center",
                                  border: "2px solid #ddd",
                                  borderLeft: "none",
                                  fontSize: "15px",
                                }}
                              >
                                <FormControl
                                  size="small"
                                  sx={{ width: "80%" }}
                                  required
                                >
                                  <Select
                                    sx={{ textAlign: "center" }}
                                    className="form-control"
                                    type="text"
                                    value={studentDetails?.markSheet}
                                    required
                                    name="markSheet"
                                    onChange={handleChangeStudent}
                                  >
                                    <MenuItem value={"NA"}>NA</MenuItem>
                                    <MenuItem value={"Yes"}>Yes</MenuItem>
                                    <MenuItem value={"No"}>No</MenuItem>
                                  </Select>
                                </FormControl>
                              </TableCell>
                              <TableCell
                                sx={{
                                  textAlign: "center",
                                  border: "2px solid #ddd",
                                  borderRight: "none",
                                  fontSize: "15px",
                                }}
                              >
                                Provisional Certificate :
                              </TableCell>
                              <TableCell
                                sx={{
                                  textAlign: "center",
                                  border: "2px solid #ddd",
                                  borderLeft: "none",
                                  fontSize: "15px",
                                }}
                              >
                                <FormControl
                                  size="small"
                                  sx={{ width: "80%" }}
                                  required
                                >
                                  <Select
                                    sx={{ textAlign: "center" }}
                                    className="form-control"
                                    type="text"
                                    value={
                                      studentDetails?.provisionalCertificate
                                    }
                                    required
                                    name="provisionalCertificate"
                                    onChange={handleChangeStudent}
                                  >
                                    <MenuItem value={"NA"}>NA</MenuItem>
                                    <MenuItem value={"Yes"}>Yes</MenuItem>
                                    <MenuItem value={"No"}>No</MenuItem>
                                  </Select>
                                </FormControl>
                              </TableCell>
                            </TableRow>

                            <TableRow>
                              <TableCell
                                sx={{
                                  textAlign: "center",
                                  border: "2px solid #ddd",
                                  borderRight: "none",
                                  fontSize: "15px",
                                }}
                              >
                                Migration Certificate
                              </TableCell>
                              <TableCell
                                sx={{
                                  textAlign: "center",
                                  border: "2px solid #ddd",
                                  borderLeft: "none",
                                  fontSize: "15px",
                                }}
                              >
                                <FormControl
                                  size="small"
                                  sx={{ width: "80%" }}
                                  required
                                >
                                  <Select
                                    sx={{ textAlign: "center" }}
                                    className="form-control"
                                    type="text"
                                    value={studentDetails?.migrationCertificate}
                                    required
                                    name="migrationCertificate"
                                    onChange={handleChangeStudent}
                                  >
                                    <MenuItem value={"NA"}>NA</MenuItem>
                                    <MenuItem value={"Yes"}>Yes</MenuItem>
                                    <MenuItem value={"No"}>No</MenuItem>
                                  </Select>
                                </FormControl>
                              </TableCell>
                              <TableCell
                                sx={{
                                  textAlign: "center",
                                  border: "2px solid #ddd",
                                  borderRight: "none",
                                  fontSize: "15px",
                                }}
                              >
                                Final Degree/Diploma :
                              </TableCell>
                              <TableCell
                                sx={{
                                  textAlign: "center",
                                  border: "2px solid #ddd",
                                  borderLeft: "none",
                                  fontSize: "15px",
                                }}
                              >
                                <FormControl
                                  size="small"
                                  sx={{ width: "80%" }}
                                  required
                                >
                                  <Select
                                    sx={{ textAlign: "center" }}
                                    className="form-control"
                                    type="text"
                                    value={studentDetails?.finalDegreeOrDiploma}
                                    required
                                    name="finalDegreeOrDiploma"
                                    onChange={handleChangeStudent}
                                  >
                                    <MenuItem value={"NA"}>NA</MenuItem>
                                    <MenuItem value={"Yes"}>Yes</MenuItem>
                                    <MenuItem value={"No"}>No</MenuItem>
                                  </Select>
                                </FormControl>
                              </TableCell>
                            </TableRow>

                            <TableRow>
                              <TableCell
                                sx={{
                                  textAlign: "center",
                                  border: "2px solid #ddd",
                                  fontSize: "15px",
                                }}
                              >
                                <PDFDownloadLink
                                  document={
                                    <StudentInfoLetter
                                      object={studentDetails}
                                    />
                                  }
                                >
                                  <Button variant="contained">PDF</Button>
                                </PDFDownloadLink>
                              </TableCell>
                              <TableCell
                                sx={{
                                  textAlign: "center",
                                  border: "2px solid #ddd",
                                  fontSize: "15px",
                                }}
                              >
                                <Button
                                  variant="contained"
                                  onClick={() =>
                                    navigate(
                                      `PaidFees/${studentDetails.stUniqueId}`
                                    )
                                  }
                                >
                                  Paid Fees
                                </Button>
                              </TableCell>
                              <TableCell
                                sx={{
                                  textAlign: "center",
                                  border: "2px solid #ddd",
                                  fontSize: "15px",
                                }}
                              >
                                <Button
                                  variant="contained"
                                  color={"info"}
                                  onClick={() => setOpenInstallment(true)}
                                >
                                  Installment
                                </Button>
                                <Dialog
                                  open={openInstallment}
                                  maxWidth={"xl"}
                                  onClose={() => setOpenInstallment(false)}
                                >
                                  <TableContainer>
                                    <Grid
                                      container
                                      sx={{
                                        p: 2,
                                        background: "#0288d1",
                                        color: "white",
                                      }}
                                    >
                                      <Grid item xs={12}>
                                        <Typography variant="h5" sx={{ mx: 3 }}>
                                          {"Manage Installment For" +
                                            " " +
                                            studentDetails?.studentData
                                              ?.firstName +
                                            " " +
                                            studentDetails?.studentData
                                              ?.lastName}
                                        </Typography>
                                      </Grid>
                                    </Grid>
                                    <Table>
                                      <TableHead
                                        sx={{
                                          border: "2px solid black",
                                          padding: "10px",
                                          fontSize: "15px",
                                        }}
                                      >
                                        <TableRow>
                                          <TableCell
                                            sx={{
                                              border: "2px solid black",
                                              fontSize: "15px",
                                            }}
                                          >
                                            #
                                          </TableCell>

                                          <TableCell
                                            sx={{
                                              border: "2px solid black",
                                              fontSize: "15px",
                                            }}
                                          >
                                            Installment Date
                                          </TableCell>
                                          <TableCell
                                            sx={{
                                              border: "2px solid black",
                                              fontSize: "15px",
                                            }}
                                          >
                                            Installment Amount
                                          </TableCell>

                                          <TableCell
                                            sx={{
                                              border: "2px solid black",
                                              fontSize: "15px",
                                            }}
                                          >
                                            Paid Status
                                          </TableCell>
                                        </TableRow>
                                      </TableHead>
                                      <TableBody>
                                        {studentDetails?.courseData?.installmentDetails.map(
                                          (item: any, index: any) => (
                                            <TableRow key={item.slipNo}>
                                              <TableCell
                                                sx={{
                                                  border: "2px solid black",
                                                  fontSize: "15px",
                                                }}
                                              >
                                                {page * rowsPerPage + index + 1}
                                              </TableCell>

                                              <TableCell
                                                sx={{
                                                  border: "2px solid black",
                                                  fontSize: "15px",
                                                }}
                                              >
                                                {item.date}
                                              </TableCell>
                                              <TableCell
                                                sx={{
                                                  border: "2px solid black",
                                                  fontSize: "15px",
                                                }}
                                              >
                                                {item.amount}
                                              </TableCell>

                                              <TableCell
                                                sx={{
                                                  border: "2px solid black",
                                                  fontSize: "15px",
                                                }}
                                              >
                                                {item.verifyPayment}
                                              </TableCell>
                                            </TableRow>
                                          )
                                        )}
                                      </TableBody>
                                    </Table>
                                  </TableContainer>
                                </Dialog>
                              </TableCell>
                              <TableCell
                                sx={{
                                  textAlign: "center",
                                  border: "2px solid #ddd",
                                  fontSize: "15px",
                                }}
                              >
                                <Button
                                  variant="contained"
                                  onClick={() =>
                                    navigate(
                                      `Courier/${studentDetails.stUniqueId}`
                                    )
                                  }
                                >
                                  Courier
                                </Button>
                              </TableCell>
                            </TableRow>

                            {types.type === "Administrator" && (
                              <>
                                <TableRow>
                                  <TableCell
                                    sx={{
                                      textAlign: "center",
                                      border: "2px solid #ddd",
                                      fontSize: "15px",
                                    }}
                                  >
                                    <Button
                                      variant="contained"
                                      color={"info"}
                                      onClick={() => setOpenStatus(true)}
                                    >
                                      Status
                                    </Button>
                                    <Dialog open={openStatus} maxWidth={"xs"}>
                                      <DialogTitle>Status Changes</DialogTitle>
                                      <DialogContent>
                                        <FormControl fullWidth>
                                          <InputLabel id="demo-simple-select-required-label">
                                            Status
                                          </InputLabel>
                                          <Select
                                            required
                                            size="small"
                                            labelId="demo-simple-select-required-label"
                                            label="Status"
                                            value={status.status}
                                            onChange={(e: any) =>
                                              setStatus({
                                                ...status,
                                                status: e.target.value,
                                              })
                                            }
                                          >
                                            <MenuItem value={"Accept"}>
                                              Accept
                                            </MenuItem>
                                            <MenuItem value={"Reject"}>
                                              Reject
                                            </MenuItem>
                                            <MenuItem value={"Pending"}>
                                              Pending
                                            </MenuItem>
                                            <MenuItem value={"On Hold"}>
                                              On Hold
                                            </MenuItem>
                                          </Select>
                                        </FormControl>
                                        <TextField
                                          label="Remark"
                                          type="text"
                                          fullWidth
                                          variant="standard"
                                          required
                                          onChange={(e: any) =>
                                            setStatus({
                                              ...status,
                                              statusRemark: e.target.value,
                                            })
                                          }
                                        />
                                      </DialogContent>
                                      <DialogActions>
                                        <Button
                                          type="submit"
                                          onClick={() => statusSubmit()}
                                        >
                                          Submit
                                        </Button>
                                        <Button
                                          onClick={() => setOpenStatus(false)}
                                        >
                                          Cancel
                                        </Button>
                                      </DialogActions>
                                    </Dialog>
                                  </TableCell>
                                  <TableCell
                                    sx={{
                                      textAlign: "center",
                                      border: "2px solid #ddd",
                                      fontSize: "15px",
                                    }}
                                  >
                                    <Button
                                      variant="contained"
                                      onClick={() =>
                                        navigate("ManageUpdateInfo", {
                                          state: {
                                            id: studentDetails.studentId,
                                          },
                                        })
                                      }
                                    >
                                      Update Info
                                    </Button>
                                  </TableCell>

                                  <TableCell
                                    sx={{
                                      textAlign: "center",
                                      border: "2px solid #ddd",
                                      fontSize: "15px",
                                    }}
                                  >
                                    <Button
                                      variant="contained"
                                      onClick={() =>
                                        navigate("ManageUpdateFees", {
                                          state: {
                                            id: studentDetails.studentId,
                                          },
                                        })
                                      }
                                    >
                                      Update Fees
                                    </Button>
                                  </TableCell>
                                </TableRow>
                              </>
                            )}
                          </TableHead>
                        </TableContainer>
                      </Dialog>
                    </Fragment>
                  ))}
              </TableBody>
            </Table>
          </TableContainer>

          <TablePagination
            rowsPerPageOptions={[10, 25, 50, 100]}
            component="div"
            count={mappingData?.length}
            rowsPerPage={rowsPerPage}
            page={page}
            onPageChange={handleChangePage}
            onRowsPerPageChange={handleChangeRowsPerPage}
          />
          <CommonSnackBar
            open={openSnackBar}
            autoHideDuration={5000}
            onClose={handleCloseSnackBar}
            message={snackMessage}
          />
        </Paper>
      </Container>
    </>
  );
}
