import * as React from "react";
import Button from "@mui/material/Button";
import TextField from "@mui/material/TextField";
import Dialog from "@mui/material/Dialog";
import Box from "@mui/material/Box";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import Container from "@mui/material/Container";
import Grid from "@mui/material/Grid";
import { ChangeEvent, FormEvent, useState } from "react";
import useTypescriptValidation from "../../Hooks/useTypescriptValidation";
import CommonSnackBar from "../Common/CommonSnackbar";
import { postRequestMethod } from "../../api/api";
import { APPLY_JOB } from "../../api/server";

interface CareerData {
  firstName: string;
  lastName: string;
  email: string;
  phone: string;
  uploadResume: File | null;
  jobId: string;
}

export default function FormDialog(props: any) {
  let { eventHandler } = useTypescriptValidation();
  const { jobId } = props;

  const [data, setData] = useState<CareerData>({
    firstName: "",
    lastName: "",
    email: "",
    phone: "",
    uploadResume: null,
    jobId: jobId,
  });

  const [errors, setErrors] = useState<{ [key: string]: string }>({
    firstName: "",
    lastName: "",
    email: "",
    phone: "",
    uploadResume: "",
  });

  const [open, setOpen] = React.useState(false);
  const [snackMessage, setSnackMessage] = useState<string>("");

  //for displaying snackbar message
  const [openSnackBar, setOpenSnackBar] = useState(false);

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const handleOpenSnackBar = () => {
    setOpenSnackBar(true);
    setTimeout(() => {
      setOpenSnackBar(false);
    }, 3000);
  };

  const handleSubmit = async (event: FormEvent<HTMLFormElement>) => {
    event.preventDefault();
    if (validator()) {
      try {
        const res = await postRequestMethod(
          APPLY_JOB,
          "multipart/form-data",
          data
        );
        if (res?.data.message) {
          setSnackMessage(res.data.message);
          handleOpenSnackBar();
          setTimeout(handleClose, 4000);
        } else {
          setSnackMessage(res?.data.error);
          handleOpenSnackBar();
        }
      } catch (error) {
        console.error("error");
      }
    }
  };

  // validation handler for file upload field and message field
  const validationHandler = async (e: any, alterName?: any) => {
    let input: any = null;
    if (alterName === "pdfSize") {
       input = e.target.files[0];
      setData({ ...data, [e.target.name]: input });
    } else {
      input = e.target.value;
      setData({ ...data, [e.target.name]: input });
    }
    if (alterName) {
      let prom = new Promise((resolve) => {
        if (true) {
          resolve(eventHandler(alterName, input));
        }
      });
      prom.then((res: any) => {
        if (res === "") {
          setErrors((preErrors: { [key: string]: string }) => ({
            ...preErrors,
            [e.target.name]: "",
          }));
        } else {
          setErrors((preErrors: { [key: string]: string }) => ({
            ...preErrors,
            [e.target.name]: res,
          }));
        }
      });
    }
  };

  // to check fields are filled or not
  const validator = () => {
    for (let field in errors) {
      if (errors[field as keyof typeof errors] !== "") {
        return false;
      }
    }
    return true;
  };

  return (
    <Container>
      <CommonSnackBar message={snackMessage} open={openSnackBar} />
      <Grid item md={12}>
        <Button variant="contained" onClick={handleClickOpen}>
          APPLY NOW
        </Button>

        <Dialog open={open} onClose={handleClose}>
          <Box
            component="form"
            onSubmit={handleSubmit}
            encType="multipart/form-data"
          >
            <DialogContent>
              <Grid container spacing={2}>
                <Grid item xs={6}>
                  <TextField
                    autoFocus
                    required
                    margin="dense"
                    label="First Name"
                    name="firstName"
                    type="text"
                    fullWidth
                    variant="standard"
                    error={Boolean(errors.firstName)}
                    helperText={errors.firstName}
                    onChange={(e: ChangeEvent<HTMLInputElement>) =>
                      validationHandler(e, "alphabet")
                    }
                  />
                </Grid>

                <Grid item xs={6}>
                  <TextField
                    autoFocus
                    required
                    margin="dense"
                    label="Last Name"
                    name="lastName"
                    type="text"
                    fullWidth
                    variant="standard"
                    error={Boolean(errors.lastName)}
                    helperText={errors.lastName}
                    onChange={(e: ChangeEvent<HTMLInputElement>) =>
                      validationHandler(e, "alphabet")
                    }
                  />
                </Grid>
              </Grid>
              <TextField
                autoFocus
                required
                margin="dense"
                label="Email"
                name="email"
                type="email"
                fullWidth
                variant="standard"
                error={Boolean(errors.email)}
                helperText={errors.email}
                onChange={(e: ChangeEvent<HTMLInputElement>) =>
                  validationHandler(e, "email")
                }
              />
              <TextField
                autoFocus
                required
                margin="dense"
                label="Phone"
                name="phone"
                fullWidth
                variant="standard"
                error={Boolean(errors.phone)}
                helperText={errors.phone}
                onChange={(e: ChangeEvent<HTMLInputElement>) =>
                  validationHandler(e, "mobile")
                }
              />
              <TextField
                label="Upload Resume"
                name="uploadResume"
                type="file"
                required
                InputLabelProps={{ shrink: true }}
                inputProps={{ accept: "application/pdf" }}
                fullWidth
                sx={{ borderTop: 0, paddingTop: 1, marginTop: "11px" }}
                error={Boolean(errors.uploadResume)}
                helperText={errors.uploadResume}
                onChange={(e: ChangeEvent<HTMLInputElement>) =>
                  validationHandler(e, "pdfSize")
                }
              />
            </DialogContent>
            <DialogActions>
              <Button type="submit">Submit</Button>
              <Button onClick={handleClose}>Cancel</Button>
            </DialogActions>
          </Box>
        </Dialog>
      </Grid>
    </Container>
  );
}
