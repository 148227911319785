import SearchIcon from "@mui/icons-material/Search";
import {
  Container,
  FormControl,
  Grid,
  IconButton,
  InputAdornment,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TablePagination,
  TableRow,
  TextField,
  Typography,
} from "@mui/material";
import { ChangeEvent, Fragment, useEffect, useState } from "react";
import { getRequest } from "../api/api";
import { FEEDBACK_FORM_DETAILS } from "../api/server";

const MAX_DISPLAY_LENGTH = 30;

export default function StudentFeedback() {
  const [page, setPage] = useState<number>(0);
  const [rowsPerPage, setRowsPerPage] = useState<number>(10);
  const [detail, setDetail] = useState<any[]>([]);
  const [searchApiData, setSearchApiData] = useState<any[]>([]);
  const [filterVal, setFilterVal] = useState<string>("");

  // state for the Readmore and read less functionality
  const [isTruncatedComplain, setTruncatedComplain] = useState<{
    [key: string]: boolean;
  }>({});

  const [isTruncatedFeedback, setTruncatedFeedback] = useState<{
    [key: string]: boolean;
  }>({});

  // function to toggle the truncated state for a
  const toggleComplainTruncated = (itemId: number) => {
    setTruncatedComplain((prevStates: { [key: string]: boolean }) => ({
      ...prevStates,
      [itemId]: !prevStates[itemId],
    }));
  };

  // function to toggle the truncated state for a
  const toggleFeedbackTruncated = (itemId: number) => {
    setTruncatedFeedback((prevStates: { [key: string]: boolean }) => ({
      ...prevStates,
      [itemId]: !prevStates[itemId],
    }));
  };

  const getStudentData = async () => {
    await getRequest(FEEDBACK_FORM_DETAILS, "")
      .then((resp: any) => {
        setDetail(resp.data);
        setSearchApiData(resp.data);
      })
      .catch((error) => {
        console.error("Error fetching data:", error);
      });
  };

  const handleChangePage = (event: any, newPage: any) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event: any) => {
    setRowsPerPage(+event.target.value);
    setPage(0);
  };

  const handleFilter = (e: ChangeEvent<HTMLInputElement>) => {
    if (e.target.value === "") {
      setDetail(searchApiData);
    } else {
      const filterResult = searchApiData.filter((item: any) => {
        const searchValue = String(e.target.value).toLowerCase();
        return Object.values(item).some((value) => {
          if (typeof value === "string" || typeof value === "number") {
            const itemValue = String(value).toLowerCase();
            return itemValue.includes(searchValue);
          }
          return false;
        });
      });
      setDetail(filterResult);
    }
    setFilterVal(e.target.value);
  };

  useEffect(() => {
    if (detail) {
      const defaultState = detail?.reduce((acc: any, item: any) => {
        acc[item._id] = true;
        return acc;
      }, {});

      setTruncatedComplain(defaultState);
      setTruncatedFeedback(defaultState);
    }
  }, [detail]);

  useEffect(() => {
    getStudentData();
  }, []);

  return (
    <>
      <Container>
        <Paper elevation={20}>
          <Grid
            container
            sx={{
              p: 2,
              background: "#0288d1",
              color: "white",
            }}
          >
            <Grid item xs={6}>
              <Typography variant="h5" sx={{ mx: 3 }}>
                Student Complains & Feedback
              </Typography>
            </Grid>
            <Grid
              item
              xs={6}
              sx={{ display: "flex", justifyContent: "flex-end" }}
            >
              <FormControl>
                <TextField
                  label="Search "
                  size="small"
                  id="outlined-basic"
                  variant="outlined"
                  value={filterVal}
                  onInput={handleFilter}
                  sx={{ mx: 3, background: "white" }}
                  InputProps={{
                    endAdornment: (
                      <InputAdornment position="end">
                        <IconButton edge="end">
                          <SearchIcon />
                        </IconButton>
                      </InputAdornment>
                    ),
                  }}
                />
              </FormControl>
            </Grid>
          </Grid>
          <TableContainer sx={{ p: 1 }} className="scrollBarCss">
            <Table sx={{ minWidth: 650 }} aria-label="simple table">
              <TableHead>
                <TableRow>
                  <TableCell
                    sx={{
                      textAlign: "center",
                      border: "1px solid #ddd",
                      fontSize: "15px",
                    }}
                  >
                    Sr.No
                  </TableCell>
                  <TableCell
                    sx={{
                      textAlign: "center",
                      border: "1px solid #ddd",
                      fontSize: "15px",
                    }}
                  >
                    Employee Details
                  </TableCell>
                  <TableCell
                    sx={{
                      textAlign: "center",
                      border: "1px solid #ddd",
                      fontSize: "15px",
                    }}
                  >
                    Student Details
                  </TableCell>
                  <TableCell
                    sx={{
                      textAlign: "center",
                      border: "1px solid #ddd",
                      fontSize: "15px",
                    }}
                  >
                    Complain
                  </TableCell>
                  <TableCell
                    sx={{
                      textAlign: "center",
                      border: "1px solid #ddd",
                      fontSize: "15px",
                    }}
                  >
                    Feedback
                  </TableCell>
                </TableRow>
              </TableHead>

              <TableBody>
                {detail
                  .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                  .map((item: any, index: number) => (
                    <Fragment key={index}>
                      <TableRow
                        key={item._id}
                        sx={{
                          textAlign: "center",
                          borderRight: "1px solid #ddd",
                        }}
                      >
                        <TableCell
                          sx={{
                            textAlign: "center",
                            border: "1px solid #ddd",
                            fontSize: "15px",
                          }}
                        >
                          {page * rowsPerPage + index + 1}
                        </TableCell>
                        <TableCell
                          component="th"
                          scope="row"
                          sx={{
                            textAlign: "center",
                            borderRight: "1px solid #ddd",
                          }}
                        >
                          {item.empDetails}
                        </TableCell>
                        <TableCell
                          sx={{
                            textAlign: "center",
                            borderRight: "1px solid #ddd",
                          }}
                        >
                          {item.studentDetails}
                        </TableCell>
                        <TableCell
                          sx={{
                            border: "1px solid #ddd",
                            textAlign: "center",
                          }}
                          key={item._id} // Assuming 'id' is a unique identifier for each item
                        >
                          {item.complain.length > MAX_DISPLAY_LENGTH ? (
                            <>
                              {isTruncatedComplain[item._id]
                                ? `${item.complain.substring(
                                    0,
                                    MAX_DISPLAY_LENGTH
                                  )}...`
                                : item.complain}
                              <span
                                style={{
                                  color: "blue",
                                  cursor: "pointer",
                                  marginLeft: "5px",
                                }}
                                onClick={() =>
                                  toggleComplainTruncated(item._id)
                                }
                              >
                                {isTruncatedComplain[item._id]
                                  ? "Read More"
                                  : "Read Less"}
                              </span>
                            </>
                          ) : (
                            item.complain
                          )}
                        </TableCell>
                        <TableCell
                          sx={{
                            border: "1px solid #ddd",
                            textAlign: "center",
                          }}
                          key={item._id} // Assuming 'id' is a unique identifier for each item
                        >
                          {item.feedback.length > MAX_DISPLAY_LENGTH ? (
                            <>
                              {isTruncatedFeedback[item._id]
                                ? `${item.feedback.substring(
                                    0,
                                    MAX_DISPLAY_LENGTH
                                  )}...`
                                : item.feedback}
                              <span
                                style={{
                                  color: "blue",
                                  cursor: "pointer",
                                  marginLeft: "5px",
                                }}
                                onClick={() =>
                                  toggleFeedbackTruncated(item._id)
                                }
                              >
                                {isTruncatedFeedback[item._id]
                                  ? "Read More"
                                  : "Read Less"}
                              </span>
                            </>
                          ) : (
                            item.feedback
                          )}
                        </TableCell>
                      </TableRow>
                    </Fragment>
                  ))}
              </TableBody>
            </Table>
          </TableContainer>
          <TablePagination
            rowsPerPageOptions={[10, 25, 50, 100]}
            component="div"
            count={detail.length}
            rowsPerPage={rowsPerPage}
            page={page}
            onPageChange={handleChangePage}
            onRowsPerPageChange={handleChangeRowsPerPage}
          ></TablePagination>
        </Paper>
      </Container>
    </>
  );
}
