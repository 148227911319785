import { Paper } from "@mui/material";
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import Container from "@mui/material/Container";
import FormControl from "@mui/material/FormControl";
import Grid from "@mui/material/Grid";
import InputLabel from "@mui/material/InputLabel";
import LinearProgress from "@mui/material/LinearProgress";
import MenuItem from "@mui/material/MenuItem";
import Select from "@mui/material/Select";
import TextField from "@mui/material/TextField";
import Typography from "@mui/material/Typography";
import * as React from "react";
import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import CommonSnackBar from "../../Components/Common/CommonSnackbar";
import useValidation from "../../Hooks/useTypescriptValidation";
import { getRequest, postRequestMethod } from "../../api/api";
import {
  GET_EMPLOYEE_STATIC_DATA,
  GET_UNIVERSITY,
  POST_PAPER,
} from "../../api/server";

function AddPaper() {
  let { eventHandler } = useValidation();
  const navigate = useNavigate();

  // SnackBar
  const [snackMessage, setSnackMessage] = useState<string>("");
  const [openSnackBar, setOpenSnackBar] = useState(false);

  //for progress bar
  const [isLoading, setLoading] = useState(false);

  // dropdown data dynamically
  //to store fetched universities data from backend
  const [universities, setUniversities] = React.useState<any[]>([]);
  const [currentUniversity, setCurrentUniversity] = React.useState<any>({});

  //to store static data for form
  const [staticData, setStaticData] = React.useState<any[]>([]);
  const [paperData, setPaperData] = React.useState<any[]>([]);

  //to display the dropdownsdata dynamically
  const [course, setCourse] = React.useState<any>([]);
  const [specialization, setSpecialization] = React.useState<any>([]);
  const [mode, setMode] = React.useState<any>([]);

  const handleOpenSnackBar = (message: string) => {
    setSnackMessage(message);
    setOpenSnackBar(true);
  };

  const handleCloseSnackBar = () => {
    setOpenSnackBar(false);
  };

  const [data, setData] = useState<any>({
    paperTitle: "",
    university: "",
    course: "",
    department: "",
    specialization: "",
    noOfYearorSems: "",
    mode: "",
    examinationDate: "",
    timeType: "",
    paperTime: "",
    paperType: "",
    resultDoc: undefined,
    resultUrl: "",
    viewStartDate: "",
    viewEndDate: "",
  });

  const [errors, setErrors] = useState<{ [key: string]: string }>({
    paperTitle: "",
    resultUrl: "",
    resultDoc: "",
  });

  const handleSubmit = async (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault();
    if (validator()) {
      try {
        setLoading(true);
        const res = await postRequestMethod(
          POST_PAPER,
          "multipart/form-data",
          data
        );
        setLoading(false);
        if (res?.data?.message) {
          handleOpenSnackBar(res?.data?.message);
        } else {
          handleOpenSnackBar(res?.data?.error);
        }
        window.scrollTo(0, 0);
        setTimeout(() => {
          navigate("/dashboard/ManagePaper");
        }, 4000);
      } catch (error) {
        console.error(error);
      }
    }
  };

  async function getDropdownData() {
    try {
      const response1 = await getRequest(GET_EMPLOYEE_STATIC_DATA, "");
      if (response1) {
        setStaticData(response1.data[0].timeType);
        setPaperData(response1.data[0].paperType);
      }
      const response2 = await getRequest(GET_UNIVERSITY, "");
      if (response2) {
        if (validator()) {
          setUniversities(response2.data);
        }
      }
    } catch (error) {
      console.error("Error fetching dropdown data:", error);
    }
  }

  // to set the data of dropdowns dynamically
  const HandleChange = async (e: any, children: any) => {
    try {
      const { value, name } = e.target;
      const { id } = children.props;
      switch (name) {
        case "university":
          setData({ ...data, [name]: value });
          const university = universities?.find((item: any) => item.uId === id);
          setCurrentUniversity(university);
          setCourse([]);
          setSpecialization([]);
          setMode([]);
          break;
        case "department":
          setData({ ...data, [name]: value });
          const courseList = currentUniversity?.course?.filter(
            (item: any) => item.dId === id
          );
          setCourse(courseList);

          setSpecialization([]);
          setMode([]);
          break;
        case "course":
          setData({ ...data, [name]: value });
          const specializationList = currentUniversity?.specialization?.filter(
            (item: any) => item.cId === id
          );
          setSpecialization([]);
          setSpecialization(specializationList);
          break;
        case "specialization":
          setData({ ...data, [name]: value });
          const modeList = currentUniversity?.mode?.filter(
            (item: any) => item.mId === id
          );
          setMode(modeList);
          break;
      }
    } catch (error) {
      console.error("Error in HandleChange:", error);
    }
  };

  // validation for file upload and other fields
  const validationHandler = async (e: any, alterName?: any) => {
    let value: any = null;
    if (alterName !== "pdfSize") {
      value = e.target.value;
      setData({ ...data, [e.target.name]: value });
    } else {
      value = e.target.files[0];
      setData({ ...data, [e.target.name]: value });
    }
    if (alterName) {
      let prom = new Promise((resolve) => {
        if (true) {
          resolve(eventHandler(alterName, value));
        }
      });
      prom.then((res: any) => {
        if (res === "") {
          setErrors((preErrors: { [key: string]: string }) => ({
            ...preErrors,
            [e.target.name]: "",
          }));
        } else {
          setErrors((preErrors: { [key: string]: string }) => ({
            ...preErrors,
            [e.target.name]: res,
          }));
        }
      });
    }
  };

  const validator = () => {
    try {
      for (let field in errors) {
        if (errors[field as keyof typeof errors] !== "") {
          return false;
        }
      }
      return true;
    } catch (error) {
      console.error("Error in validator:", error);
      return false;
    }
  };

  //useeffect for validating the startdate is less than enddate
  useEffect(() => {
    if (
      data.viewStartDate &&
      data.viewEndDate &&
      data.viewStartDate !== "" &&
      data.viewEndDate !== ""
    ) {
      const sessionFromDate = new Date(data.viewStartDate);
      const sessionToDate = new Date(data.viewEndDate);

      if (sessionFromDate > sessionToDate) {
        setErrors((prevData: { [key: string]: string }) => ({
          ...prevData,
          viewStartDate: "Start date must be less than End date",
          viewEndDate: "End date must be greater than Start date",
        }));
      } else {
        setErrors((prevData: { [key: string]: string }) => ({
          ...prevData,
          viewStartDate: "",
          viewEndDate: "",
        }));
      }
    }
  }, [data.viewStartDate, data.viewEndDate]);

  useEffect(() => {
    getDropdownData();
  }, []);

  return (
    <>
      <Container>
        <Paper elevation={20} sx={{ p: 3 }}>
          {isLoading && <LinearProgress />}
          <Box
            sx={{
              display: "flex",
              flexDirection: "column",
              alignItems: "center",
              backgroundColor: "white",
            }}
          >
            <Typography
              variant="h6"
              align="center"
              sx={{ alignItems: "center", color: "#0288d1" }}
            >
              ADD PAPER
            </Typography>
            <Box
              sx={{ mt: 3 }}
              component="form"
              onSubmit={handleSubmit}
              encType="multipart/form-data"
            >
              <Grid container spacing={2}>
                <Grid item xs={12} sm={12}>
                  <TextField
                    sx={{ background: "white" }}
                    fullWidth
                    required
                    id="Paper Title With Subject Code"
                    label="Paper Title With Subject Code"
                    name="paperTitle"
                    type="text"
                    error={Boolean(errors.paperTitle)}
                    helperText={errors.paperTitle}
                    onChange={(e) => validationHandler(e, "alphanumeric")}
                  />
                </Grid>
                <Grid item xs={12} sm={6}>
                  <FormControl fullWidth required>
                    <InputLabel id="demo-simple-select-required-label">
                      University
                    </InputLabel>
                    <Select
                      name="university"
                      labelId="demo-simple-select-required-label"
                      className="form-control"
                      label="University"
                      type="text"
                      required
                      onChange={HandleChange}
                    >
                      {universities?.map((Object: any) => (
                        <MenuItem
                          value={Object.universityName}
                          key={Object._id}
                          id={Object.uId}
                        >
                          {Object.universityName}
                        </MenuItem>
                      ))}
                    </Select>
                  </FormControl>
                </Grid>

                <Grid item xs={12} sm={6}>
                  <FormControl fullWidth required>
                    <InputLabel id="demo-simple-select-required-label">
                      Department
                    </InputLabel>
                    <Select
                      labelId="demo-simple-select-required-label"
                      className="form-control"
                      label="Department"
                      type="text"
                      name="department"
                      required
                      onChange={HandleChange}
                    >
                      {currentUniversity?.department?.map((Object: any) => (
                        <MenuItem
                          value={Object.name}
                          key={Object._id}
                          id={Object.dId}
                        >
                          {Object.name}
                        </MenuItem>
                      ))}
                    </Select>
                  </FormControl>
                </Grid>

                <Grid item xs={12} sm={6}>
                  <FormControl fullWidth required>
                    <InputLabel id="demo-simple-select-required-label">
                      Course
                    </InputLabel>
                    <Select
                      labelId="demo-simple-select-required-label"
                      label="Course"
                      value={data.course}
                      name="course"
                      onChange={HandleChange}
                    >
                      {course?.map((Object: any) => (
                        <MenuItem
                          key={Object._id}
                          value={Object.name}
                          id={Object.cId}
                        >
                          {Object.name}
                        </MenuItem>
                      ))}
                    </Select>
                  </FormControl>
                </Grid>

                <Grid item xs={12} sm={6}>
                  <FormControl fullWidth required>
                    <InputLabel id="demo-simple-select-required-label">
                      Specialization
                    </InputLabel>
                    <Select
                      labelId="demo-simple-select-required-label"
                      label="Specialization"
                      value={data.specialization}
                      name="specialization"
                      onChange={HandleChange}
                    >
                      {specialization?.map((Object: any) => (
                        <MenuItem
                          key={Object.sId}
                          value={Object.name}
                          id={Object.sId}
                        >
                          {Object.name}
                        </MenuItem>
                      ))}
                    </Select>
                  </FormControl>
                </Grid>

                <Grid item xs={12} sm={6}>
                  <FormControl fullWidth required>
                    <InputLabel id="demo-simple-select-required-label">
                      Mode
                    </InputLabel>
                    <Select
                      labelId="demo-simple-select-required-label"
                      value={data.mode}
                      label="Mode"
                      required
                      name="mode"
                      onChange={(e: any) => validationHandler(e, "")}
                    >
                      {mode?.map((Object: any) => (
                        <MenuItem
                          key={Object.mId}
                          value={Object.type}
                          id={Object.mId}
                        >
                          {Object.type}
                        </MenuItem>
                      ))}
                    </Select>
                  </FormControl>
                </Grid>

                <Grid item xs={12} sm={6}>
                  <FormControl fullWidth>
                    <TextField
                      required
                      label="Examination Sem/Year"
                      type="number"
                      name="noOfYearorSems"
                      sx={{ background: "white" }}
                      onChange={(e: any) => validationHandler(e, "")}
                    />
                  </FormControl>
                </Grid>

                <Grid item xs={12} sm={6}>
                  <TextField
                    required
                    InputLabelProps={{ shrink: true }}
                    type="date"
                    sx={{ width: "100% !Important", background: "white" }}
                    label="Examination date"
                    name="examinationDate"
                    onChange={(e: any) => validationHandler(e, "")}
                    InputProps={{
                      inputProps: {
                        min: new Date().toISOString().slice(0, 10),
                      },
                    }}
                  />
                </Grid>

                <Grid item xs={12} sm={6}>
                  <FormControl required fullWidth>
                    <InputLabel id="demo-simple-select-required-label">
                      Time Type
                    </InputLabel>
                    <Select
                      labelId="demo-simple-select-required-label"
                      id="demo-simple-select-required"
                      label="Time Type"
                      name="timeType"
                      type="text"
                      onChange={(e: any) => validationHandler(e, "")}
                    >
                      {staticData.map((item: any) => {
                        return Object.keys(item).map((key: any) => {
                          if (key === "id") {
                            return (
                              <MenuItem value={item[key]} key={item[key]}>
                                {item[key]}
                              </MenuItem>
                            );
                          }
                          return null;
                        });
                      })}
                    </Select>
                  </FormControl>
                </Grid>

                <Grid item xs={12} sm={6}>
                  <TextField
                    sx={{ background: "white" }}
                    InputLabelProps={{ shrink: true }}
                    required
                    fullWidth
                    label="Paper Time"
                    type="Time"
                    id="PaperTime"
                    autoComplete="PaperTime"
                    name="paperTime"
                    onChange={(e: any) => validationHandler(e, "")}
                  />
                </Grid>

                <Grid item xs={12} sm={6}>
                  <FormControl required fullWidth>
                    <InputLabel id="demo-simple-select-required-label">
                      Paper Type
                    </InputLabel>
                    <Select
                      labelId="label"
                      id="select"
                      label="Paper Type"
                      type="text"
                      value={data.paperType}
                      name="paperType"
                      onChange={(e: any) => validationHandler(e, "")}
                    >
                      {paperData.map((item: any) => {
                        return Object.keys(item).map((key: any) => {
                          if (key === "id") {
                            return (
                              <MenuItem value={item[key]} key={item[key]}>
                                {item[key]}
                              </MenuItem>
                            );
                          }
                          return null;
                        });
                      })}
                    </Select>
                  </FormControl>
                </Grid>
                <Grid item xs={12} sm={6}>
                  <Typography>Result Doc</Typography>
                  <TextField
                    type="file"
                    inputProps={{ accept: "application/pdf" }}
                    required
                    fullWidth
                    name="resultDoc"
                    sx={{ background: "white" }}
                    error={Boolean(errors.resultDoc)}
                    helperText={errors.resultDoc}
                    onChange={(e) => validationHandler(e, "pdfSize")}
                  ></TextField>
                </Grid>

                <Grid item xs={12} sm={6}>
                  <TextField
                    sx={{ marginTop: 3, background: "white" }}
                    fullWidth
                    required
                    label="Result Url"
                    type="url"
                    id="ResultUrl"
                    autoComplete="ResultUrl"
                    name="resultUrl"
                    error={Boolean(errors.resultUrl)}
                    helperText={errors.resultUrl}
                    onChange={(e) => validationHandler(e, "url")}
                  />
                </Grid>

                <Grid item xs={12} sm={6}>
                  <TextField
                    InputLabelProps={{ shrink: true }}
                    type="date"
                    sx={{ width: "100% !Important", background: "white" }}
                    label="View Start Date"
                    name="viewStartDate"
                    error={Boolean(errors.viewStartDate)}
                    helperText={errors.viewStartDate}
                    InputProps={{
                      inputProps: {
                        min: new Date().toISOString().slice(0, 10),
                      },
                    }}
                    onChange={(e: any) => validationHandler(e, "")}
                  />
                </Grid>

                <Grid item xs={12} sm={6}>
                  <TextField
                    InputLabelProps={{ shrink: true }}
                    type="date"
                    sx={{ width: "100% !Important", background: "white" }}
                    label="View End Date"
                    name="viewEndDate"
                    error={Boolean(errors.viewEndDate)}
                    helperText={errors.viewEndDate}
                    InputProps={{
                      inputProps: {
                        min: new Date().toISOString().slice(0, 10),
                      },
                    }}
                    onChange={(e: any) => validationHandler(e, "")}
                  />
                </Grid>

                <Button
                  type="submit"
                  variant="contained"
                  sx={{ mt: 2, ml: 2, px: 4 }}
                >
                  Submit
                </Button>
                <CommonSnackBar
                  open={openSnackBar}
                  autoHideDuration={5000}
                  onClose={handleCloseSnackBar}
                  message={snackMessage}
                />
              </Grid>
            </Box>
          </Box>
        </Paper>
      </Container>
    </>
  );
}

export default AddPaper;
