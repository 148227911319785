import { Box, Grid, Typography } from "@mui/material";
import React from "react";

export default function PageNotFound() {
  return (
    // <Grid item xs={12}>
    <Box
      component="img"
      sx={{
        height: "100%",
        width: "100%",
      }}
      alt="The house from the offer."
      src="https://ik.imagekit.io/nwssoft/PageNotFound/pageNotFoundImg1.jpg?updatedAt=1686033583881"
    />
    // </Grid>
  );
}
