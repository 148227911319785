import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { BrowserRouter, Route, Routes } from "react-router-dom";
import AboutUs from "../Components/AboutUs/AboutUs";
import Career from "../Components/Career/Career";
import EmployeeInfo from "../Components/Common/EmployeeInfo";
import Failure from "../Components/Common/Payment/Failure";
import Success from "../Components/Common/Payment/Success";
import WebsitePayment from "../Components/Common/Payment/WebsitePayment";
import ProfilePreview from "../Components/Common/PersonalInformation/ProfilePreview";
import ContactUs from "../Components/ContactUs/ContactUs";
import FaqTab from "../Components/FaqTabts";
import Policy from "../Components/Footer/Policy";
import Refund from "../Components/Footer/Refund";
import Terms from "../Components/Footer/Terms";
import Home from "../Components/Home/Home";
import Layout from "../Components/Layout";
import Login from "../Components/Login";
import PageNotFound from "../Components/PageNotFound";
import Services from "../Components/ServicesPage/Services";
import LeaveForm from "../Views/ApplicationForLeave/LeaveForm";
import ManageLeave from "../Views/ApplicationForLeave/ManageLeave";
import ViewLeaveStatus from "../Views/ApplicationForLeave/ViewLeaveStatus";
import AddCenterHead from "../Views/CenterHead/AddCenterHead";
import ManageCenterHead from "../Views/CenterHead/ManageCenterHead";
import EditCourier from "../Views/Courier/EditCourier";
import ManageCourier from "../Views/Courier/ManageCourier";
import Dashboard from "../Views/Dashboard";
import AddExecutive from "../Views/Executive/AddExecutive";
import ManageExecutive from "../Views/Executive/ManageExecutive";
import FeedbackForm from "../Views/FeeedbackForm";
import MainLayout from "../Views/MainLayout";
import AddJobForm from "../Views/Manage Job/AddJobform";
import AddjobTable from "../Views/Manage Job/AddjobTable";
import ManageCareer from "../Views/Manage Job/ManageCareer";
import CenterHeadPaid from "../Views/ManageCommission/CenterHeadPaid";
import CenterHeadUnpaid from "../Views/ManageCommission/CenterHeadUnpaid";
import ExecutivePaid from "../Views/ManageCommission/ExecutivePaid";
import ExecutiveUnpaid from "../Views/ManageCommission/ExecutiveUnpaid";
import StudentPaid from "../Views/ManageCommission/StudentPaid";
import StudentUnpaid from "../Views/ManageCommission/StudentUnpaid";
import TeamLeadPaid from "../Views/ManageCommission/TeamLeadPaid";
import TeamLeadUnpaid from "../Views/ManageCommission/TeamLeadUnpaid";
import Activity from "../Views/ManageLead/Activity";
import AddLead from "../Views/ManageLead/AddLeadForm";
import ManageAssignLeadEx from "../Views/ManageLead/ManageAssignLeadEx";
import ManageAssignedLead from "../Views/ManageLead/ManageAssignedLeadAdmin";
import ManageAssignedLeadCH from "../Views/ManageLead/ManageAssignedLeadCH";
import ManageAssignedLeadTL from "../Views/ManageLead/ManageAssignedLeadTL";
import ManageUnassignLead from "../Views/ManageLead/ManageUnAssignLead";
import ReferenceUser from "../Views/ManageLead/ReferenceUser";
import RejectedLead from "../Views/ManageLead/RejectedLead";
import Upload from "../Views/ManageLead/Upload";
import ViewActivity from "../Views/ManageLead/ViewActivity";
import AddCourier from "../Views/ManageStudent/CourierForm";
import FeePayment from "../Views/ManageStudent/FeePayment";
import ManageNoc from "../Views/ManageStudent/ManageNoc";
import ManageReceiptVerification from "../Views/ManageStudent/ManageReceiptVerification";
import ManageStudent from "../Views/ManageStudent/ManageStudent";
import Courier from "../Views/ManageStudent/ManageStudentCourier";
import ManageUpdateFees from "../Views/ManageStudent/ManageUpdateFees";
import ManageUpdateInfo from "../Views/ManageStudent/ManageUpdateInfo";
import AddUniversity from "../Views/ManageUniversity/AddUniversity";
import EditUniversityData from "../Views/ManageUniversity/EditUniversity";
import ManageUniversities from "../Views/ManageUniversity/ManageUniversityData";
import UniversityCourse from "../Views/ManageUniversity/UniverstyCourse";
import UploadUniversity from "../Views/ManageUniversity/UploadUniversity";
import Paper from "../Views/Paper/Paper";
import PaperTable from "../Views/Paper/PaperTable";
import Profile from "../Views/ProfileForm";
import ComplainForm from "../Views/Reports/ComplainForm";
import ManageComplain from "../Views/Reports/ManageComplain";
import ManageContactUs from "../Views/Reports/ManageContactUs";
import ManageFeeReport from "../Views/Reports/ManageFeeReport";
import { ManageFeeStatic } from "../Views/Reports/ManageFeeStatic";
import ManageAllReceipt from "../Views/Reports/ManageReceipt";
import ManageSalary from "../Views/Reports/ManageSalary";
import ManageVisitor from "../Views/Reports/ManageVisitor";
import Result from "../Views/Result/Result";
import ResultTable from "../Views/Result/ResultTable";
import SettingForm from "../Views/SettingForm";
import StudentFeedback from "../Views/StudentFeedback";
import NewRegistration from "../Views/StudentRegistration/NewRegistration";
import AccountDetails from "../Views/StudentRegistration/PaidInstallment";
import ReRegistration from "../Views/StudentRegistration/ReRegistration";
import ReferenceStudent from "../Views/StudentRegistration/ReferenceStudent";
import RegistrationDetails from "../Views/StudentRegistration/RegistrationDetails";
import UnpaidInstallment from "../Views/StudentRegistration/UnpaidInstallments";
import AddTL from "../Views/TeamLead/AddTL";
import ManageTeamLead from "../Views/TeamLead/ManageTeamLead";
import { LOGIN } from "../redux/action";

export default function Routing() {
  const dispatchLogin = useDispatch();

  // state to maintain login details
  const [types, setTypes] = useState<any>();
  const { login } = useSelector((state: any) => state.LoginReducer);

  // Callback function to update the parent state
  const updateType = (newCount: any) => {
    setTypes(newCount);
  };

  const user: any = sessionStorage.getItem("user");
  const myObject = JSON.parse(user);

  useEffect(() => {
    if (user) {
      dispatchLogin({
        type: LOGIN,
        login: true,
        userToken: {
          auth: sessionStorage.getItem("token"),
          user: myObject,
        },
      });
    }
  }, []);

  useEffect(() => {
    console.log("types state in redux: ", login);
  }, [login]);

  return (
    <>
      <BrowserRouter>
        <Routes>
          <Route path="/" element={<Layout />}>
            <Route index element={<Home />} />
            <Route path="aboutUs" element={<AboutUs />} />
            <Route path="services" element={<Services />} />
            <Route path="faq" element={<FaqTab />} />
            <Route path="career" element={<Career />} />
            <Route path="contactUs" element={<ContactUs />} />
            <Route path="payment" element={<WebsitePayment />} />
            <Route path="success" element={<Success />} />
            <Route path="failure" element={<Failure />} />
            <Route path="login" element={<Login />} />
            <Route path="refund" element={<Refund />} />
            <Route path="policy" element={<Policy />} />
            <Route path="terms" element={<Terms />} />
          </Route>
          {login ? (
            <>
              <Route
                path="/dashboard"
                element={<MainLayout updateType={updateType} />}
              >
                <Route index element={<Dashboard types={types} />} />
                {types?.type === "Administrator" ? (
                  <>
                    {/* Profile Form */}
                    <Route path="Profile/:id/:type" element={<Profile />} />

                    {/* job */}
                    <Route path="ManageJob" element={<AddjobTable />} />
                    <Route path="AddJob" element={<AddJobForm />} />
                    <Route path="EditJob" element={<AddJobForm />} />
                    <Route path="manage-career" element={<ManageCareer />} />

                    {/* university */}
                    <Route
                      path="ManageUniversity"
                      element={<ManageUniversities />}
                    />
                    <Route path="AddUniversity" element={<AddUniversity />} />
                    <Route
                      path="UniversityCSVImport"
                      element={<UploadUniversity />}
                    />
                    <Route
                      path="EditUniversity/:_id"
                      element={<EditUniversityData />}
                    />
                    <Route
                      path="UniversityCourses"
                      element={<UniversityCourse />}
                    />

                    {/* Lead */}
                    <Route
                      path="ManageAssignedLead"
                      element={<ManageAssignedLead />}
                    />
                    <Route
                      path="ManageAssignedLead/Activity"
                      element={<Activity />}
                    />
                    <Route
                      path="ManageAssignedLead/ViewActivity/:leadId"
                      element={<ViewActivity />}
                    />
                    <Route
                      path="ManageUnassignedLead"
                      element={<ManageUnassignLead />}
                    />
                    <Route path="LeadCSVImport" element={<Upload />} />
                    <Route path="AddLead" element={<AddLead />} />
                    <Route path="RejectedLeads" element={<RejectedLead />} />
                    <Route path="ReferenceUser" element={<ReferenceUser />} />

                    {/* Center Head */}
                    <Route
                      path="ManageCenterHead"
                      element={<ManageCenterHead />}
                    />
                    <Route path="AddCenterHead" element={<AddCenterHead />} />
                    <Route path="EditCenterHead" element={<AddCenterHead />} />

                    {/* team lead*/}
                    <Route
                      path="ManageTeamLead"
                      element={<ManageTeamLead types={types} />}
                    />
                    <Route path="AddTeamLead" element={<AddTL />} />
                    <Route path="EditTeamLead" element={<AddTL />} />
                    <Route path="Employee" element={<EmployeeInfo />} />

                    {/* executive*/}
                    <Route
                      path="ManageExecutive"
                      element={<ManageExecutive types={types} />}
                    />
                    <Route path="AddExecutive" element={<AddExecutive />} />
                    <Route path="EditExecutive" element={<AddExecutive />} />
                    <Route path="Employee" element={<EmployeeInfo />} />

                    {/* student*/}
                    <Route
                      path="ManageStudent"
                      element={<ManageStudent types={types} />}
                    />
                    <Route
                      path="ManageStudent/PaidFees/:stUniqueId"
                      element={<ManageReceiptVerification />}
                    />
                    <Route
                      path="ManageStudent/Courier/:stUniqueId"
                      element={<Courier />}
                    />
                    <Route
                      path="ManageStudent/Courier/:stUniqueId/AddCourier/:stUniqueId"
                      element={<AddCourier />}
                    />
                    <Route
                      path="ManageStudent/Courier/:stUniqueId/EditCourier/"
                      element={<AddCourier />}
                    />
                    <Route
                      path="ManageStudent/ManageUpdateInfo"
                      element={<ManageUpdateInfo />}
                    />
                    <Route
                      path="ManageStudent/ManageUpdateFees"
                      element={<ManageUpdateFees />}
                    />
                    <Route
                      path="FeePayment"
                      element={<FeePayment types={types} />}
                    />
                    <Route
                      path="ReceiptVerification"
                      element={<ManageReceiptVerification />}
                    />
                    <Route
                      path="ManageNOC"
                      element={<ManageNoc types={types} />}
                    />

                    {/* Result*/}
                    <Route
                      path="ManageResult"
                      element={<ResultTable types={types} />}
                    />
                    <Route path="AddResult" element={<Result />} />

                    {/* Paper*/}
                    <Route
                      path="ManagePaper"
                      element={<PaperTable types={types} />}
                    />
                    <Route path="AddPaper" element={<Paper />} />

                    {/* commision*/}
                    <Route
                      path="CenterHeadUnPaid"
                      element={<CenterHeadUnpaid />}
                    />
                    <Route path="CenterHeadPaid" element={<CenterHeadPaid />} />
                    <Route path="TeamLeadUnPaid" element={<TeamLeadUnpaid />} />
                    <Route path="TeamLeadPaid" element={<TeamLeadPaid />} />

                    <Route
                      path="ExecutiveUnPaid"
                      element={<ExecutiveUnpaid />}
                    />
                    <Route path="ExecutivePaid" element={<ExecutivePaid />} />
                    <Route path="StudentUnPaid" element={<StudentUnpaid />} />
                    <Route path="StudentPaid" element={<StudentPaid />} />

                    {/*Report*/}
                    <Route path="AllReceipt" element={<ManageAllReceipt />} />
                    <Route path="FeesReport" element={<ManageFeeReport />} />
                    <Route
                      path="SalarySystem"
                      element={<ManageSalary types={types} />}
                    />
                    <Route path="FeeStatics" element={<ManageFeeStatic />} />
                    <Route path="ContactUs" element={<ManageContactUs />} />
                    <Route path="Visitor" element={<ManageVisitor />} />
                    <Route
                      path="manage-complains"
                      element={<ManageComplain />}
                    />
                    <Route
                      path="StudentFeedback"
                      element={<StudentFeedback />}
                    />

                    {/* Leave*/}
                    <Route path="ManageApplication" element={<ManageLeave />} />

                    {/* Setting*/}
                    <Route
                      path="Settings"
                      element={<SettingForm types={types} />}
                    />

                    {/* Courier*/}
                    <Route
                      path="/dashboard/Courier"
                      element={<ManageCourier types={types} />}
                    />
                    <Route
                      path="/dashboard/Courier/EditCourier"
                      element={<EditCourier />}
                    />
                  </>
                ) : types?.type === "Center Head" ? (
                  <>
                    <Route
                      path="PersonalInformation"
                      element={<ProfilePreview types={types} />}
                    />

                    {/* ProfileForm */}
                    <Route path="Profile/:id/:type" element={<Profile />} />

                    {/* University */}
                    <Route
                      path="UniversityCourses"
                      element={<UniversityCourse />}
                    />

                    {/* Lead */}
                    <Route
                      path="ManageAssignedLead"
                      element={<ManageAssignedLeadCH types={types} />}
                    />

                    {/* team lead*/}
                    <Route
                      path="ManageTeamLead"
                      element={<ManageTeamLead types={types} />}
                    />

                    {/* executive*/}
                    <Route
                      path="ManageExecutive"
                      element={<ManageExecutive types={types} />}
                    />

                    {/* student*/}
                    <Route
                      path="ManageStudent"
                      element={<ManageStudent types={types} />}
                    />
                    <Route
                      path="FeePayment"
                      element={<FeePayment types={types} />}
                    />
                    <Route
                      path="ManageNOC"
                      element={<ManageNoc types={types} />}
                    />
                    <Route
                      path="ManageStudent/PaidFees/:stUniqueId"
                      element={<ManageReceiptVerification />}
                    />
                    <Route
                      path="ManageStudent/Courier/:stUniqueId"
                      element={<Courier />}
                    />
                    <Route
                      path="ManageStudent/Courier/:stUniqueId/AddCourier/:stUniqueId"
                      element={<AddCourier />}
                    />
                    <Route
                      path="ManageStudent/Courier/:stUniqueId/EditCourier/"
                      element={<AddCourier />}
                    />

                    {/* Result*/}
                    <Route
                      path="ManageResult"
                      element={<ResultTable types={types} />}
                    />

                    {/* Paper*/}
                    <Route
                      path="ManagePaper"
                      element={<PaperTable types={types} />}
                    />

                    {/*Report*/}
                    <Route path="AllReceipt" element={<ManageAllReceipt />} />
                    <Route path="FeesReport" element={<ManageFeeReport />} />
                    <Route
                      path="SalarySystem"
                      element={<ManageSalary types={types} />}
                    />
                    <Route
                      path="complain"
                      element={<ComplainForm loginId={types.regId} />}
                    />

                    {/* Courier*/}
                    <Route
                      path="/dashboard/Courier"
                      element={<ManageCourier types={types} />}
                    />
                    <Route
                      path="/dashboard/Courier/EditCourier"
                      element={<EditCourier />}
                    />

                    {/* Leave*/}
                    <Route
                      path="AddApplication"
                      element={<LeaveForm types={types} />}
                    />
                    <Route
                      path="ViewApplication"
                      element={<ViewLeaveStatus types={types} />}
                    />
                  </>
                ) : types?.type === "Team Lead" ? (
                  <>
                    <Route
                      path="PersonalInformation"
                      element={<ProfilePreview types={types} />}
                    />

                    {/* ProfileForm */}
                    <Route path="Profile/:id/:type" element={<Profile />} />

                    {/* university */}
                    <Route
                      path="UniversityCourses"
                      element={<UniversityCourse />}
                    />

                    {/* Lead */}
                    <Route
                      path="ManageAssignedLead"
                      element={<ManageAssignedLeadTL types={types} />}
                    />

                    {/* executive*/}
                    <Route
                      path="ManageExecutive"
                      element={<ManageExecutive types={types} />}
                    />

                    {/* student*/}
                    <Route
                      path="ManageStudent"
                      element={<ManageStudent types={types} />}
                    />
                    <Route
                      path="FeePayment"
                      element={<FeePayment types={types} />}
                    />
                    <Route
                      path="ManageNOC"
                      element={<ManageNoc types={types} />}
                    />
                    <Route
                      path="ManageStudent/PaidFees/:stUniqueId"
                      element={<ManageReceiptVerification />}
                    />
                    <Route
                      path="ManageStudent/Courier/:stUniqueId"
                      element={<Courier />}
                    />
                    <Route
                      path="ManageStudent/Courier/:stUniqueId/AddCourier/:stUniqueId"
                      element={<AddCourier />}
                    />
                    <Route
                      path="ManageStudent/Courier/:stUniqueId/EditCourier/"
                      element={<AddCourier />}
                    />

                    {/* Result*/}
                    <Route
                      path="ManageResult"
                      element={<ResultTable types={types} />}
                    />

                    {/* Paper*/}
                    <Route
                      path="ManagePaper"
                      element={<PaperTable types={types} />}
                    />

                    {/*Report*/}
                    <Route
                      path="SalarySystem"
                      element={<ManageSalary types={types} />}
                    />
                    <Route
                      path="complain"
                      element={<ComplainForm loginId={types.regId} />}
                    />

                    {/* Courier*/}
                    <Route
                      path="/dashboard/Courier"
                      element={<ManageCourier types={types} />}
                    />
                    <Route
                      path="/dashboard/Courier/EditCourier"
                      element={<EditCourier />}
                    />

                    {/* Leave*/}
                    <Route
                      path="AddApplication"
                      element={<LeaveForm types={types} />}
                    />
                    <Route
                      path="ViewApplication"
                      element={<ViewLeaveStatus types={types} />}
                    />
                  </>
                ) : types?.type === "Executive" ? (
                  <>
                    <Route
                      path="PersonalInformation"
                      element={<ProfilePreview types={types} />}
                    />

                    {/* ProfileForm */}
                    <Route path="Profile/:id/:type" element={<Profile />} />

                    {/* university */}
                    <Route
                      path="UniversityCourses"
                      element={<UniversityCourse />}
                    />

                    {/* Lead */}
                    <Route
                      path="ManageAssignedLead"
                      element={<ManageAssignLeadEx types={types} />}
                    />
                    <Route
                      path="ManageAssignedLead/Activity"
                      element={<Activity />}
                    />
                    <Route
                      path="ManageAssignedLead/ViewActivity/:leadId"
                      element={<ViewActivity />}
                    />
                    <Route
                      path="ReferenceUser"
                      element={<ManageAssignLeadEx types={types} />}
                    />
                    <Route
                      path="ReferenceUser/Activity"
                      element={<Activity />}
                    />
                    <Route
                      path="ReferenceUser/ViewActivity/:leadId"
                      element={<ViewActivity />}
                    />

                    {/* student*/}
                    <Route
                      path="ManageStudent"
                      element={<ManageStudent types={types} />}
                    />
                    <Route
                      path="FeePayment"
                      element={<FeePayment types={types} />}
                    />
                    <Route
                      path="ManageNOC"
                      element={<ManageNoc types={types} />}
                    />
                    <Route
                      path="NewRegistration"
                      element={<NewRegistration types={types} />}
                    />
                    <Route
                      path="Re-Registration"
                      element={<ReRegistration />}
                    />
                    <Route
                      path="ManageStudent/PaidFees/:stUniqueId"
                      element={<ManageReceiptVerification />}
                    />
                    <Route
                      path="ManageStudent/Courier/:stUniqueId"
                      element={<Courier />}
                    />
                    <Route
                      path="ManageStudent/Courier/:stUniqueId/AddCourier/:stUniqueId"
                      element={<AddCourier />}
                    />
                    <Route
                      path="ManageStudent/Courier/:stUniqueId/EditCourier/"
                      element={<AddCourier />}
                    />

                    {/* Result*/}
                    <Route
                      path="ManageResult"
                      element={<ResultTable types={types} />}
                    />

                    {/* Paper*/}
                    <Route
                      path="ManagePaper"
                      element={<PaperTable types={types} />}
                    />

                    {/*Report*/}
                    <Route
                      path="SalarySystem"
                      element={<ManageSalary types={types} />}
                    />
                    <Route
                      path="complain"
                      element={<ComplainForm loginId={types.regId} />}
                    />

                    {/* Leave*/}
                    <Route
                      path="AddApplication"
                      element={<LeaveForm types={types} />}
                    />
                    <Route
                      path="ViewApplication"
                      element={<ViewLeaveStatus types={types} />}
                    />

                    {/* Courier*/}
                    <Route
                      path="/dashboard/Courier"
                      element={<ManageCourier types={types} />}
                    />
                    <Route
                      path="/dashboard/Courier/EditCourier"
                      element={<EditCourier />}
                    />
                  </>
                ) : types?.type === "Student" ? (
                  <>
                    {/* ProfileForm */}
                    <Route path="Profile/:id/:type" element={<Profile />} />

                    {/* student*/}
                    <Route
                      path="RegistrationDetails"
                      element={<RegistrationDetails types={types} />}
                    />
                    <Route
                      path="AccountDetails"
                      element={<AccountDetails types={types} />}
                    />
                    <Route
                      path="UnpaidInstallments"
                      element={<UnpaidInstallment types={types} />}
                    />
                    <Route
                      path="ManageNOC"
                      element={<ManageNoc types={types} />}
                    />

                    {/* Result*/}
                    <Route
                      path="ManageResult"
                      element={<ResultTable types={types} />}
                    />

                    {/* Paper*/}
                    <Route
                      path="ManagePaper"
                      element={<PaperTable types={types} />}
                    />

                    {/*Report*/}
                    <Route
                      path="FeedbackForm"
                      element={<FeedbackForm types={types} />}
                    />

                    {/* Courier*/}
                    <Route
                      path="Courier"
                      element={<ManageCourier types={types} />}
                    />

                    {/*Reference*/}
                    <Route
                      path="ReferenceStudent"
                      element={<ReferenceStudent types={types} />}
                    />
                  </>
                ) : (
                  <Route path="/dashboard/*" element={<PageNotFound />} />
                )}
              </Route>
            </>
          ) : (
            <>
              <Route path="*" element={<PageNotFound />} />
              <Route path="/dashboard/*" element={<PageNotFound />} />
            </>
          )}
        </Routes>
      </BrowserRouter>
    </>
  );
}
