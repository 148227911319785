import { Button, FormGroup, Stack } from "@mui/material";
import Checkbox from "@mui/material/Checkbox";
import FormControl from "@mui/material/FormControl";
import FormControlLabel from "@mui/material/FormControlLabel";
import Grid from "@mui/material/Grid";
import InputLabel from "@mui/material/InputLabel";
import MenuItem from "@mui/material/MenuItem";
import Select from "@mui/material/Select";
import TextField from "@mui/material/TextField";
import Typography from "@mui/material/Typography";
import { debounce } from "lodash";
import { useEffect, useState } from "react";
import CommonSnackBar from "../../Components/Common/CommonSnackbar";
import india from "../../Components/Common/india.json";
import useValidation from "../../Hooks/useTypescriptValidation";

export default function StudentDetails(props: any) {
  const { eventHandler } = useValidation();
  const { isPresent, setIsPresent } = props;

  const [showAdditionalFields, setShowAdditionalFields] = useState(false);
  const [openSnackBar, setOpenSnackBar] = useState(false);
  const [snackMessage, setSnackMessage] = useState<string>("");

  const [errors, setErrors] = useState<{ [key: string]: string }>({
    firstName: "",
    lastName: "",
    email: "",
    contactNumber: "",
    dateOfBirth: "",
    gender: "",
    whatsappNumber: "",
    language: "",
    nationality: "",
    aadhaar: "",
    domicile: "",
    working: "",
    maritalstatus: "",
    religion: "",
    categories: "",
    fatherName: "",
    motherName: "",
    annualIncome: "",
    parentMobileNumber: "",
    parentEmail: "",
    presentAddress1: "",
    presentAddress2: "",
    presentState: "",
    presentCity: "",
    presentZipCode: "",
    permanentAddress1: "",
    permanentAddress2: "",
    permanentState: "",
    permanentCity: "",
    permanentZipCode: "",
  });

  const validationHandler = debounce((e: any, alterName?: any) => {
    const val = e.target.value;
    const id = alterName;
    const err = eventHandler(id, val);
    setErrors((prevData: any) => ({
      ...prevData,
      [e.target.name]: err,
    }));
    if (val === "") {
      setErrors((prevData: any) => ({
        ...prevData,
        [e.target.name]: "",
      }));
    }
  }, 2000);

  const handleOpenSnackBar = () => {
    setOpenSnackBar(true);
    setTimeout(() => {
      setOpenSnackBar(false);
    }, 3000);
  };

  const handleNext = (e: any) => {
    e.preventDefault();
    if (validator()) {
      let isError: boolean = false;
      for (let key in errors) {
        if (errors[key] !== "") {
          isError = true;
          break;
        }
      }
      let isEmpty: boolean = false;
      for (let key in props.detail) {
        if (props.detail[key] === "") {
          if (props.detail.parentEmail === "") {
            continue;
          }
          if (props.detail.working === "No") {
            if (
              props.detail.jobType === "" ||
              props.detail.companyName === "" ||
              props.detail.employmentStartDate === "" ||
              props.detail.designation === ""
            ) {
              isEmpty = false;
            }
            isEmpty = true;
          }
          isEmpty = true;
          break;
        }
      }
      if (!isEmpty && !isError) {
        props.setActiveStep(2);
        window.scrollTo(0, 0);
        props.setShowSubmit((showSubmit: any) => ({
          ...showSubmit,
          studentDetail: true,
        }));
      }
    } else {
      window.scrollTo(0, 0);
      setSnackMessage("Fill all the fields properly!");
      handleOpenSnackBar();
    }
  };

  const handleWorkingChange = (event: any) => {
    const { value } = event.target;
    setShowAdditionalFields(value === "Yes");
    if (value === "No") {
      props.setStudentData((studentData: any) => ({
        ...studentData,
        jobType: undefined,
        companyName: undefined,
        employmentStartDate: undefined,
        designation: undefined,
      }));
    }
  };

  const validator = (): boolean => {
    if (props.detail?.religion === "") {
      setErrors((errors: any) => ({
        ...errors,
        religion: "Select this field",
      }));
      return false;
    }
    setErrors((errors: any) => ({
      ...errors,
      religion: "",
    }));

    if (props.detail?.categories === "") {
      setErrors((errors: any) => ({
        ...errors,
        categories: "Select this field",
      }));
      return false;
    }
    setErrors((errors: any) => ({
      ...errors,
      categories: "",
    }));

    if (props.detail?.maritalstatus === "") {
      setErrors((errors: any) => ({
        ...errors,
        maritalstatus: "Select this field",
      }));
      return false;
    }
    setErrors((errors: any) => ({
      ...errors,
      maritalstatus: "",
    }));
    return true;
  };

  useEffect(() => {
    props.detail.working === "Yes"
      ? setShowAdditionalFields(true)
      : setShowAdditionalFields(false);
  }, [props.detail.working]);

  useEffect(() => {
    setErrors((preData: any) => ({
      ...preData,
      email: props.dataPresent.email || "",
      contactNumber: props.dataPresent.contactNumber || "",
    }));
  }, [props.dataPresent]);

  useEffect(() => {
    if (
      errors.email === "Email is registered!" &&
      errors.contactNumber === "Number is registered!"
    ) {
      setSnackMessage("Email & Contact is registered!");
      handleOpenSnackBar();
    } else if (errors.email === "Email is registered!") {
      setSnackMessage(errors.email);
      handleOpenSnackBar();
    } else if (errors.contactNumber === "Number is registered!") {
      setSnackMessage(errors.contactNumber);
      handleOpenSnackBar();
    }
  }, [errors.email, errors.contactNumber]);

  return (
    <>
      <Grid
        container
        component="form"
        spacing={2}
        direction="row"
        display="flex"
        justifyContent="flex-start"
        alignItems="start"
        onSubmit={handleNext}
      >
        <Grid item xs={12}>
          <Typography variant="h6">Student Details:</Typography>
        </Grid>

        <Grid item xs={12} md={6}>
          <TextField
            required
            id="outlined-First-Name"
            label="First Name"
            name="firstName"
            value={props.detail?.firstName}
            type="text"
            fullWidth
            sx={{ background: "white" }}
            error={Boolean(errors.firstName)}
            helperText={errors.firstName}
            onChange={(e) => {
              validationHandler(e, "alphabet");
              props.handler(e);
            }}
          />
        </Grid>
        <Grid item xs={12} md={6}>
          <TextField
            id="outlined-Last-Name"
            label="Last Name"
            name="lastName"
            type="text"
            value={props.detail?.lastName}
            fullWidth
            required
            sx={{ background: "white" }}
            error={Boolean(errors.lastName)}
            helperText={errors.lastName}
            onChange={(e) => {
              validationHandler(e, "alphabet");
              props.handler(e);
            }}
          />
        </Grid>
        <Grid item xs={12} md={6}>
          <TextField
            required
            id="outlined-Email-flexible"
            label="Email"
            type="email"
            name="email"
            fullWidth
            sx={{ background: "white" }}
            value={props.detail?.email}
            error={Boolean(errors.email)}
            helperText={errors.email}
            onChange={(e) => {
              validationHandler(e, "email");
              props.handler(e);
            }}
          />
        </Grid>
        <Grid item xs={12} md={6}>
          <TextField
            id="outlined-Contact-flexible"
            label="Contact Number"
            name="contactNumber"
            type="number"
            fullWidth
            required
            sx={{ background: "white" }}
            value={props.detail?.contactNumber}
            error={Boolean(errors.contactNumber)}
            helperText={errors.contactNumber}
            onChange={(e) => {
              validationHandler(e, "mobile");
              props.handler(e);
            }}
          />
        </Grid>
        <Grid item xs={12} md={6}>
          <TextField
            id="outlined-Date-flexible"
            label="Date Of Birth"
            type="date"
            name="dateOfBirth"
            value={props.detail?.dateOfBirth}
            fullWidth
            required
            sx={{ background: "white" }}
            InputLabelProps={{ shrink: true }}
            InputProps={{
              inputProps: {
                max: new Date().toISOString().split("T")[0],
              },
            }}
            onChange={props.handler}
          />
        </Grid>

        <Grid item xs={12} md={6}>
          <FormControl fullWidth required>
            <InputLabel id="demo-simple-select-label">Gender</InputLabel>
            <Select
              labelId="demo-simple-select-label"
              id="demo-simple-select"
              name="gender"
              label="Gender"
              required
              value={props.detail?.gender}
              onChange={props.handler}
            >
              <MenuItem value={"Male"}>Male</MenuItem>
              <MenuItem value={"Female"}>Female</MenuItem>
              <MenuItem value={"Other"}>Other</MenuItem>
            </Select>
          </FormControl>
        </Grid>

        <Grid item xs={12} md={6}>
          <TextField
            id="outlined-Contact-flexible"
            label="WhatsApp Number"
            name="whatsappNumber"
            type="number"
            fullWidth
            required
            sx={{ background: "white" }}
            value={props.detail.whatsappNumber}
            error={Boolean(errors.whatsappNumber)}
            helperText={errors.whatsappNumber}
            onChange={(e) => {
              validationHandler(e, "mobile");
              props.handler(e);
            }}
          />
        </Grid>
        <Grid item xs={12} md={6}>
          <TextField
            id="outlined-Contact-flexible"
            label="Languages"
            name="language"
            type="text"
            placeholder="Use comma & spaces"
            fullWidth
            required
            sx={{ background: "white" }}
            value={props.detail.language}
            error={Boolean(errors.language)}
            helperText={errors.language}
            onChange={(e) => {
              validationHandler(e, "alphabetsCommaSpace");
              props.handler(e);
            }}
          />
        </Grid>
        <Grid item xs={12} md={6}>
          <TextField
            id="outlined-Contact-flexible"
            label="Nationality"
            name="nationality"
            type="text"
            fullWidth
            required
            sx={{ background: "white" }}
            value={props.detail.nationality}
            error={Boolean(errors.nationality)}
            helperText={errors.nationality}
            onChange={(e) => {
              validationHandler(e, "alphabet");
              props.handler(e);
            }}
          />
        </Grid>
        <Grid item xs={12} md={6}>
          <TextField
            id="outlined-Contact-flexible"
            label="Aadhaar Number"
            name="aadhaar"
            type="number"
            placeholder="12 digit aadhaar"
            fullWidth
            required
            sx={{ background: "white" }}
            value={props.detail.aadhaar}
            error={Boolean(errors.aadhaar)}
            helperText={errors.aadhaar}
            onChange={(e) => {
              validationHandler(e, "aadhaar");
              props.handler(e);
            }}
          />
        </Grid>
        <Grid item xs={12} md={6}>
          <TextField
            id="outlined-Contact-flexible"
            label="Domicile"
            name="domicile"
            type="text"
            placeholder="Domicile state"
            fullWidth
            required
            sx={{ background: "white" }}
            value={props.detail.domicile}
            error={Boolean(errors.domicile)}
            helperText={errors.domicile}
            onChange={(e) => {
              validationHandler(e, "alphabet");
              props.handler(e);
            }}
          />
        </Grid>
        <Grid item xs={12} md={6}>
          <FormControl fullWidth required>
            <InputLabel id="demo-simple-select-label">
              Are You Working
            </InputLabel>
            <Select
              labelId="demo-simple-select-label"
              id="demo-simple-select"
              name="working"
              label="Are You Working"
              required
              value={props.detail.working}
              onChange={(e) => {
                handleWorkingChange(e);
                props.handler(e);
              }}
            >
              <MenuItem value={"Yes"}>Yes</MenuItem>
              <MenuItem value={"No"}>No</MenuItem>
            </Select>
          </FormControl>
        </Grid>
        {showAdditionalFields && (
          <>
            <Grid item xs={12} md={6}>
              <FormControl fullWidth>
                <InputLabel id="demo-simple-select-label">
                  What type of job are you doing?
                </InputLabel>
                <Select
                  labelId="job-type"
                  id="job-type"
                  label="What type of job are you doing?"
                  name="jobType"
                  required={showAdditionalFields}
                  value={props.detail.jobType}
                  onChange={props.handler}
                >
                  <MenuItem value={"Private"}>Private</MenuItem>
                  <MenuItem value={"Government"}>Government</MenuItem>
                  <MenuItem value={"Semi Government"}>Semi Government</MenuItem>
                  <MenuItem value={"Other"}>Other</MenuItem>
                </Select>
              </FormControl>
            </Grid>

            <Grid item xs={12} md={6}>
              <TextField
                label="Current Company Name"
                name="companyName"
                fullWidth
                sx={{ background: "white" }}
                required={showAdditionalFields}
                value={props.detail.companyName}
                onChange={props.handler}
              />
            </Grid>

            <Grid item xs={12} md={6}>
              <TextField
                label="Since When"
                name="employmentStartDate"
                type="date"
                fullWidth
                sx={{ background: "white" }}
                required={showAdditionalFields}
                InputLabelProps={{ shrink: true }}
                value={props.detail.employmentStartDate}
                onChange={props.handler}
              />
            </Grid>

            <Grid item xs={12} md={6}>
              <TextField
                label="Designation"
                name="designation"
                fullWidth
                sx={{ background: "white" }}
                required={showAdditionalFields}
                value={props.detail.designation}
                onChange={props.handler}
              />
            </Grid>
          </>
        )}
        <Grid item xs={12} md={6}>
          <FormControl
            component="fieldset"
            fullWidth
            required
            onChange={() =>
              setErrors((errors: any) => ({
                ...errors,
                religion: "",
              }))
            }
          >
            <Typography>Religion:</Typography>
            <FormGroup style={{ display: "flex", flexDirection: "row" }}>
              <FormControlLabel
                control={
                  <Checkbox
                    checked={props.detail.religion === "Hindu"}
                    onChange={props.handler}
                    name="religion"
                    value="Hindu"
                  />
                }
                label="Hindu"
              />
              <FormControlLabel
                control={
                  <Checkbox
                    checked={props.detail.religion === "Muslim"}
                    onChange={props.handler}
                    name="religion"
                    value="Muslim"
                  />
                }
                label="Muslim"
              />
              <FormControlLabel
                control={
                  <Checkbox
                    checked={props.detail.religion === "Sikh"}
                    onChange={props.handler}
                    name="religion"
                    value="Sikh"
                  />
                }
                label="Sikh"
              />
              <FormControlLabel
                control={
                  <Checkbox
                    checked={props.detail.religion === "Christian"}
                    onChange={props.handler}
                    name="religion"
                    value="Christian"
                  />
                }
                label="Christian"
              />

              <FormControlLabel
                control={
                  <Checkbox
                    checked={props.detail.religion === "Isai"}
                    onChange={props.handler}
                    name="religion"
                    value="Isai"
                  />
                }
                label="Isai"
              />
              <FormControlLabel
                control={
                  <Checkbox
                    checked={props.detail.religion === "Other"}
                    onChange={props.handler}
                    name="religion"
                    value="Other"
                  />
                }
                label="Other"
              />
            </FormGroup>
          </FormControl>
          {errors.religion !== "" ? (
            <Typography color="red">{errors.religion}</Typography>
          ) : (
            ""
          )}
        </Grid>
        <Grid item xs={12} md={6}>
          <FormControl
            component="fieldset"
            fullWidth
            required
            onChange={() =>
              setErrors((errors: any) => ({
                ...errors,
                categories: "",
              }))
            }
          >
            <Typography>Categories:</Typography>
            <FormGroup style={{ display: "flex", flexDirection: "row" }}>
              <FormControlLabel
                control={
                  <Checkbox
                    checked={props.detail.categories === "GEN"}
                    onChange={props.handler}
                    name="categories"
                    value="GEN"
                  />
                }
                label="GEN"
              />
              <FormControlLabel
                control={
                  <Checkbox
                    checked={props.detail.categories === "OBC"}
                    onChange={props.handler}
                    name="categories"
                    value="OBC"
                  />
                }
                label="OBC"
              />
              <FormControlLabel
                control={
                  <Checkbox
                    checked={props.detail.categories === "SC"}
                    onChange={props.handler}
                    name="categories"
                    value="SC"
                  />
                }
                label="SC"
              />
              <FormControlLabel
                control={
                  <Checkbox
                    checked={props.detail.categories === "ST"}
                    onChange={props.handler}
                    name="categories"
                    value="ST"
                  />
                }
                label="ST"
              />
              <FormControlLabel
                control={
                  <Checkbox
                    checked={props.detail.categories === "EWS"}
                    onChange={props.handler}
                    name="categories"
                    value="EWS"
                  />
                }
                label="EWS"
              />
              <FormControlLabel
                control={
                  <Checkbox
                    checked={props.detail.categories === "Other"}
                    onChange={props.handler}
                    name="categories"
                    value="Other"
                  />
                }
                label="Other"
              />
            </FormGroup>
          </FormControl>
          {errors.categories !== "" ? (
            <Typography color="red">{errors.categories}</Typography>
          ) : (
            ""
          )}
        </Grid>

        <Grid item xs={12} md={6}>
          <FormControl
            component="fieldset"
            fullWidth
            required
            onChange={() =>
              setErrors((errors: any) => ({
                ...errors,
                maritalstatus: "",
              }))
            }
          >
            <Typography>Marital Status:</Typography>
            <FormGroup style={{ display: "flex", flexDirection: "row" }}>
              <FormControlLabel
                control={
                  <Checkbox
                    checked={props.detail.maritalstatus === "Single"}
                    onChange={props.handler}
                    name="maritalstatus"
                    value="Single"
                  />
                }
                label="Single"
              />
              <FormControlLabel
                control={
                  <Checkbox
                    checked={props.detail.maritalstatus === "Married"}
                    onChange={props.handler}
                    name="maritalstatus"
                    value="Married"
                  />
                }
                label="Married"
              />
              <FormControlLabel
                control={
                  <Checkbox
                    checked={props.detail.maritalstatus === "Divorced"}
                    onChange={props.handler}
                    name="maritalstatus"
                    value="Divorced"
                  />
                }
                label="Divorced"
              />
            </FormGroup>
          </FormControl>
          {errors.maritalstatus !== "" ? (
            <Typography color="red">{errors.maritalstatus}</Typography>
          ) : (
            ""
          )}
        </Grid>

        <Grid item xs={12}>
          <Typography variant="h6">Parent's Details:</Typography>
        </Grid>
        <Grid item xs={12} md={6}>
          <TextField
            required
            id="outlined-Name-flexible"
            label="Father Name"
            type="text"
            name="fatherName"
            fullWidth
            sx={{ background: "white" }}
            value={props.detail?.fatherName}
            error={Boolean(errors.fatherName)}
            helperText={errors.fatherName}
            onChange={(e) => {
              validationHandler(e, "alphabetsAndSpace");
              props.handler(e);
            }}
          />
        </Grid>

        <Grid item xs={12} md={6}>
          <TextField
            required
            id="outlined-Name-flexible"
            label="Mother Name:"
            type="text"
            name="motherName"
            fullWidth
            sx={{ background: "white" }}
            value={props.detail?.motherName}
            error={Boolean(errors.motherName)}
            helperText={errors.motherName}
            onChange={(e) => {
              validationHandler(e, "alphabetsAndSpace");
              props.handler(e);
            }}
          />
        </Grid>

        <Grid item xs={12} md={6}>
          <TextField
            required
            id="Annual_Income-flexible"
            label="Annual Income"
            name="annualIncome"
            type="number"
            fullWidth
            sx={{ background: "white" }}
            value={props.detail?.annualIncome}
            error={Boolean(errors.annualIncome)}
            helperText={errors.annualIncome}
            onChange={(e) => {
              validationHandler(e, "numeric");
              props.handler(e);
            }}
          />
        </Grid>

        <Grid item xs={12} md={6}>
          <TextField
            required
            id="outlined-Parent's_Mobile-flexible"
            label="Parent's Mobile:"
            name="parentMobileNumber"
            type="number"
            fullWidth
            sx={{ background: "white" }}
            value={props.detail?.parentMobileNumber}
            error={Boolean(errors.parentMobileNumber)}
            helperText={errors.parentMobileNumber}
            onChange={(e) => {
              validationHandler(e, "mobile");
              props.handler(e);
            }}
          />
        </Grid>

        <Grid item xs={12} md={6}>
          <TextField
            id="outlined-Parent's_Email-flexible"
            label="Parent's Email:"
            type="email"
            name="parentEmail"
            fullWidth
            sx={{ background: "white" }}
            value={props.detail?.parentEmail}
            error={Boolean(errors.parentEmail)}
            helperText={errors.parentEmail}
            onChange={(e) => {
              validationHandler(e, "email");
              props.handler(e);
            }}
          />
        </Grid>

        <Grid item xs={12}>
          <Typography variant="h6">Present Address:</Typography>
        </Grid>
        <Grid item xs={12} md={6}>
          <TextField
            required
            id="outlined-Address-flexible"
            label="Address 1:"
            multiline
            rows={2}
            type="text"
            name="presentAddress1"
            fullWidth
            sx={{ background: "white" }}
            value={props.detail?.presentAddress1}
            error={Boolean(errors.presentAddress1)}
            helperText={errors.presentAddress1}
            onChange={(e) => {
              validationHandler(e, "address");
              props.handler(e);
            }}
          />
        </Grid>

        <Grid item xs={12} md={6}>
          <TextField
            required
            id="outlined-Address_Name-flexible"
            label="Address 2:"
            type="text"
            name="presentAddress2"
            value={props.detail?.presentAddress2}
            fullWidth
            multiline
            rows={2}
            sx={{ background: "white" }}
            error={Boolean(errors.presentAddress2)}
            helperText={errors.presentAddress2}
            onChange={(e) => {
              validationHandler(e, "address");
              props.handler(e);
            }}
          />
        </Grid>

        <Grid item xs={12} md={6}>
          <FormControl fullWidth required>
            <InputLabel id="demo-simple-country-label">State</InputLabel>
            <Select
              required
              labelId="demo-simple-country-label"
              name="presentState"
              label="State"
              value={props.detail?.presentState}
              onChange={(e: any, key: any) => {
                props.handler(e, key);
              }}
              fullWidth
            >
              {india.map((item: any) => (
                <MenuItem
                  value={item.state_name}
                  key={item.state_name}
                  id={item.state_id}
                >
                  {item.state_name}
                </MenuItem>
              ))}
            </Select>
          </FormControl>
        </Grid>

        <Grid item xs={12} md={6}>
          <FormControl fullWidth required>
            <InputLabel id="demo-simple-country-label">City</InputLabel>
            <Select
              required
              labelId="demo-simple-country-label"
              name="presentCity"
              className="form-control"
              label="State"
              value={props.detail?.presentCity}
              onChange={props.handler}
              fullWidth
            >
              {props.presentCity?.map((item: any) => (
                <MenuItem value={item.city} key={item.city} id={item.state_id}>
                  {item.city}
                </MenuItem>
              ))}
            </Select>
          </FormControl>
        </Grid>

        <Grid item xs={12} md={6}>
          <TextField
            id="filled-password-input"
            label="Zip Code"
            required
            name="presentZipCode"
            type="number"
            fullWidth
            sx={{ background: "white" }}
            value={props.detail?.presentZipCode}
            error={Boolean(errors.presentZipCode)}
            helperText={errors.presentZipCode}
            onChange={(e) => {
              validationHandler(e, "zipcode");
              props.handler(e);
            }}
          />
        </Grid>

        <Grid item xs={12}>
          <Typography variant="h6">Permanent Address:</Typography>
        </Grid>

        <Grid item xs={12}>
          <FormControlLabel
            control={
              <Checkbox
                name="check"
                checked={isPresent}
                onChange={(e) => setIsPresent(e.target.checked)}
              />
            }
            label="Same as Present Address"
          />
        </Grid>

        <Grid item xs={12} md={6}>
          <TextField
            id="outlined-Address-flexible"
            label="Address 1:"
            required
            type="text"
            name="permanentAddress1"
            sx={{ background: "white" }}
            value={props.detail?.permanentAddress1}
            error={Boolean(errors.permanentAddress1)}
            helperText={errors.permanentAddress1}
            onChange={(e) => {
              validationHandler(e, "address");
              props.handler(e);
            }}
            fullWidth
            multiline
            rows={2}
          />
        </Grid>

        <Grid item xs={12} md={6}>
          <TextField
            id="outlined-Address_Name-flexible"
            label="Address 2:"
            type="text"
            name="permanentAddress2"
            value={props.detail?.permanentAddress2}
            fullWidth
            multiline
            rows={2}
            required
            sx={{ background: "white" }}
            error={Boolean(errors.permanentAddress2)}
            helperText={errors.permanentAddress2}
            onChange={(e) => {
              validationHandler(e, "address");
              props.handler(e);
            }}
          />
        </Grid>

        <Grid item xs={12} md={6}>
          <FormControl fullWidth required>
            <InputLabel id="demo-simple-country-label">State</InputLabel>
            <Select
              required
              labelId="demo-simple-country-label"
              name="permanentState"
              label="State"
              value={props.detail?.permanentState}
              onChange={(e: any, key: any) => {
                props.handler(e, key);
              }}
              fullWidth
            >
              {india.map((item: any) => (
                <MenuItem
                  value={item.state_name}
                  key={item.state_name}
                  id={item.state_id}
                >
                  {item.state_name}
                </MenuItem>
              ))}
            </Select>
          </FormControl>
        </Grid>

        <Grid item xs={12} md={6}>
          <FormControl fullWidth required>
            <InputLabel id="demo-simple-country-label">City</InputLabel>
            <Select
              required
              labelId="demo-simple-country-label"
              name="permanentCity"
              className="form-control"
              label="City"
              value={props.detail?.permanentCity}
              onChange={props.handler}
              fullWidth
            >
              {props.permanentCity?.map((item: any) => (
                <MenuItem value={item.city} key={item.city} id={item.state_id}>
                  {item.city}
                </MenuItem>
              ))}
            </Select>
          </FormControl>
        </Grid>

        <Grid item xs={12} md={6}>
          <TextField
            label="Zip Code"
            required
            value={props.detail?.permanentZipCode}
            name="permanentZipCode"
            type="number"
            fullWidth
            sx={{ background: "white" }}
            error={Boolean(errors.permanentZipCode)}
            helperText={errors.permanentZipCode}
            onChange={(e) => {
              validationHandler(e, "zipcode");
              props.handler(e);
            }}
          />
        </Grid>

        <Grid
          item
          xs={12}
          sm={12}
          sx={{ display: "flex", justifyContent: "center", m: "20px" }}
        >
          <Stack spacing={2} direction="row">
            <Button
              type="button"
              variant="contained"
              onClick={() => {
                props.setActiveStep(0);
                window.scrollTo(0, 0);
              }}
            >
              Back
            </Button>
            <Button variant="contained" type="submit">
              Next
            </Button>
            <CommonSnackBar message={snackMessage} open={openSnackBar} />
          </Stack>
        </Grid>
      </Grid>
    </>
  );
}
