import { Container } from "@mui/material";
import Avatar from "@mui/material/Avatar";
import Box from "@mui/material/Box";
import Paper from "@mui/material/Paper";
import Typography from "@mui/material/Typography";
import { styled } from "@mui/material/styles";
import { Fragment } from "react";
import Marquee from "react-fast-marquee";

const Item = styled(Paper)(({ theme }) => ({
  backgroundColor: theme.palette.mode === "dark" ? "#1A2027" : "#fff",
  ...theme.typography.body2,
  padding: theme.spacing(1),
  textAlign: "center",
  height: "100%",
}));

const reviewData = [
  {
    id: 1,
    image:
      "https://ik.imagekit.io/nwssoft/Google_review/Neeraj%20Kumar.png?updatedAt=1703053608807",
    name: "Neeraj Kumar",
    comment:
      "Your educational counseling center provides valuable guidance and support to students, helping them make informed decisions about their academic journey.I successfully completed my graduation with your support team prerna academy",
  },
  {
    id: 2,
    image:
      "https://ik.imagekit.io/nwssoft/Google_review/Birendra%20Kumar%20Nayak.png?updatedAt=1703053661534",
    name: "Birendra Kumar Nayak",
    comment:
      "This academy is the one of the best academy for distance education. All management staffs are good, supporting, immediate response. Overall very Good.",
  },
  {
    id: 3,
    image:
      "https://ik.imagekit.io/nwssoft/Google_review/Gourav%20vyas.png?updatedAt=1703053634084",
    name: "Gourav vyas",
    comment:
      "Very Good Place to study, I m glad to study with Prerna Acedemy, sunil sir and team are very supportive thanks for everyone for support me and encourage to study thanks",
  },
  {
    id: 4,
    image:
      "https://ik.imagekit.io/nwssoft/Google_review/DEEPAK%20SHARMA.png?updatedAt=1703053574361",
    name: "DEEPAK SHARMA",
    comment:
      "It was a great experience. All the members are very good and easily available at all time.",
  },
  {
    id: 5,
    image:
      "https://ik.imagekit.io/nwssoft/Google_review/MAUSAM%20KUMAR.png?updatedAt=1703053557939",
    name: "MAUSAM KUMAR",
    comment:
      "This academic is the one of the best for distance learning program. All management are super , immediate response, quick services. Its totally well for learning.",
  },
];

export default function Feedbackmarquee() {
  return (
    <>
      <Container>
        <Typography
          variant="h4"
          sx={{
            textAlign: "start",
            fontWeight: "500",
            color: "#191970",
            mt: 5,
          }}
        >
          Feedback Details
        </Typography>
      </Container>
      <Marquee>
        {reviewData.map((data) => (
          <Fragment key={data.id}>
            <Box sx={{ width: "300px", height: "350px", m: "40px" }}>
              <Item>
                <Avatar
                  alt={data.name}
                  sx={{
                    width: "30%",
                    height: "25%",
                    display: "block",
                    ml: "35%",
                    mr: "50%",
                    justify: "center",
                  }}
                  src={data.image}
                />

                <Typography variant="h5" gutterBottom>
                  {data.name}
                </Typography>
                <Typography variant="h6" gutterBottom>
                  Student
                </Typography>
                <Typography variant="body1" gutterBottom>
                  {data.comment}
                </Typography>
              </Item>
            </Box>
          </Fragment>
        ))}
      </Marquee>
    </>
  );
}
