import SearchIcon from "@mui/icons-material/Search";
import {
  Container,
  FormControl,
  Grid,
  IconButton,
  InputAdornment,
  TablePagination,
  TextField,
  Typography,
} from "@mui/material";
import Paper from "@mui/material/Paper";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import { ChangeEvent, Fragment, useEffect, useState } from "react";
import "../../Components/Home/Home.scss";
import { getRequest } from "../../api/api";
import { GET_COMPLAINT_FEEDBACK } from "../../api/server";

const tableHeaders = [
  { id: 1, heading: "Sr.No" },
  { id: 2, heading: "Complaint By" },
  { id: 3, heading: "Complaint Against" },
  { id: 4, heading: "Complaint Message" },
  { id: 5, heading: "Suggestion" },
];
const MAX_DISPLAY_LENGTH = 50;

export default function ManageComplain() {
  // state for table pagination
  const [page, setPage] = useState<number>(0);
  const [rowsPerPage, setRowsPerPage] = useState<number>(10);

  // state table data
  const [tableData, setTableData] = useState<any[]>([]);

  // state for data filteration
  const [searchApiData, setSearchApiData] = useState<any[]>([]);
  const [filterVal, setFilterVal] = useState<string>("");

  // state for the Readmore and read less functionality
  const [isTruncatedComplain, setTruncatedComplain] = useState<{
    [key: string]: boolean;
  }>({});

  const [isTruncatedSuggestion, setTruncatedSuggestion] = useState<{
    [key: string]: boolean;
  }>({});

  // function to toggle the truncated state for a
  const toggleComplainTruncated = (itemId: number) => {
    setTruncatedComplain((prevStates: { [key: string]: boolean }) => ({
      ...prevStates,
      [itemId]: !prevStates[itemId],
    }));
  };

  // function to toggle the truncated state for a
  const toggleSuggestionTruncated = (itemId: number) => {
    setTruncatedSuggestion((prevStates: { [key: string]: boolean }) => ({
      ...prevStates,
      [itemId]: !prevStates[itemId],
    }));
  };

  // fetching table data from backend
  const viewDetails = async () => {
    try {
      let result = await getRequest(GET_COMPLAINT_FEEDBACK, "");
      if (result) {
        setTableData(result.data);
        setSearchApiData(result.data);
      }
    } catch (error) {
      console.error("Error fetching data:", error);
    }
  };

  // functions for handling table pagination
  const handleChangePage = (event: any, newPage: any) => {
    setPage(newPage);
  };

  // function for handling rows change
  const handleChangeRowsPerPage = (event: any) => {
    setRowsPerPage(+event.target.value);
    setPage(0);
  };

  // function to filter data
  const handleFilter = (e: ChangeEvent<HTMLInputElement>) => {
    if (e.target.value === "") {
      setTableData(searchApiData);
    } else {
      const filterResult = searchApiData.filter((item: any) => {
        const searchValue = String(e.target.value).toLowerCase();
        return Object.values(item).some((value) => {
          if (typeof value === "string" || typeof value === "number") {
            const itemValue = String(value).toLowerCase();
            return itemValue.includes(searchValue);
          }
          return false;
        });
      });
      setTableData(filterResult);
    }
    setFilterVal(e.target.value);
  };

  useEffect(() => {
    if (tableData) {
      const defaultState = tableData?.reduce((acc: any, item: any) => {
        acc[item._id] = true;
        return acc;
      }, {});

      setTruncatedComplain(defaultState);
      setTruncatedSuggestion(defaultState);
    }
  }, [tableData]);

  useEffect(() => {
    viewDetails();
  }, []);

  return (
    <>
      <Container>
        <Paper elevation={20} sx={{ justifyItems: "start" }}>
          <Grid
            container
            sx={{
              p: 2,
              background: "#0288d1",
              color: "white",
            }}
          >
            <Grid item xs={6}>
              <Typography variant="h5" sx={{ mx: 3 }}>
                Employee Complains & Suggestions
              </Typography>
            </Grid>
            <Grid
              item
              xs={6}
              sx={{ display: "flex", justifyContent: "flex-end" }}
            >
              <FormControl>
                <TextField
                  label="Search "
                  size="small"
                  id="outlined-basic"
                  variant="outlined"
                  value={filterVal}
                  onInput={handleFilter}
                  sx={{ mx: 3, background: "white" }}
                  InputProps={{
                    endAdornment: (
                      <InputAdornment position="end">
                        <IconButton edge="end">
                          <SearchIcon />
                        </IconButton>
                      </InputAdornment>
                    ),
                  }}
                />
              </FormControl>
            </Grid>
          </Grid>

          <TableContainer className="scrollBarCss">
            <Table sx={{ minWidth: 650 }} aria-label="simple table">
              <TableHead>
                <TableRow>
                  {tableHeaders.map((item: any) => (
                    <Fragment key={item.id}>
                      <TableCell
                        sx={{ border: "1px solid #ddd", textAlign: "center" }}
                      >
                        {item.heading}
                      </TableCell>
                    </Fragment>
                  ))}
                </TableRow>
              </TableHead>
              <TableBody>
                {tableData
                  .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                  .map((item: any, index: number) => (
                    <Fragment key={item._id}>
                      <TableRow>
                        <TableCell
                          sx={{
                            border: "1px solid #ddd",
                            textAlign: "center",
                          }}
                        >
                          {page * rowsPerPage + index + 1}
                        </TableCell>
                        <TableCell
                          sx={{
                            border: "1px solid #ddd",
                            textAlign: "center",
                          }}
                        >
                          {`${item.byFirstName} ${item.byLastName} (${item.by})`}
                        </TableCell>
                        <TableCell
                          sx={{
                            border: "1px solid #ddd",
                            textAlign: "center",
                          }}
                        >
                          {`${item.againstFirstName} ${item.againstLastName} (${item.against})`}
                        </TableCell>
                        <TableCell
                          sx={{
                            border: "1px solid #ddd",
                            textAlign: "center",
                          }}
                          key={item._id} // Assuming 'id' is a unique identifier for each item
                        >
                          {item.complain.length > MAX_DISPLAY_LENGTH ? (
                            <>
                              {isTruncatedComplain[item._id]
                                ? `${item.complain.substring(
                                    0,
                                    MAX_DISPLAY_LENGTH
                                  )}...`
                                : item.complain}
                              <span
                                style={{
                                  color: "blue",
                                  cursor: "pointer",
                                  marginLeft: "5px",
                                }}
                                onClick={() =>
                                  toggleComplainTruncated(item._id)
                                }
                              >
                                {isTruncatedComplain[item._id]
                                  ? "Read More"
                                  : "Read Less"}
                              </span>
                            </>
                          ) : (
                            item.complain
                          )}
                        </TableCell>
                        <TableCell
                          sx={{
                            border: "1px solid #ddd",
                            textAlign: "center",
                          }}
                          key={item._id} // Assuming 'id' is a unique identifier for each item
                        >
                          {item.suggestion.length > MAX_DISPLAY_LENGTH ? (
                            <>
                              {isTruncatedSuggestion[item._id]
                                ? `${item.suggestion.substring(
                                    0,
                                    MAX_DISPLAY_LENGTH
                                  )}...`
                                : item.suggestion}
                              <span
                                style={{
                                  color: "blue",
                                  cursor: "pointer",
                                  marginLeft: "5px",
                                }}
                                onClick={() =>
                                  toggleSuggestionTruncated(item._id)
                                }
                              >
                                {isTruncatedSuggestion[item._id]
                                  ? "Read More"
                                  : "Read Less"}
                              </span>
                            </>
                          ) : (
                            item.suggestion
                          )}
                        </TableCell>
                      </TableRow>
                    </Fragment>
                  ))}
              </TableBody>
            </Table>
          </TableContainer>
          <TablePagination
            rowsPerPageOptions={[10, 25, 50, 100]}
            component="div"
            count={tableData.length}
            rowsPerPage={rowsPerPage}
            page={page}
            onPageChange={handleChangePage}
            onRowsPerPageChange={handleChangeRowsPerPage}
          />
        </Paper>
      </Container>
    </>
  );
}
