import { ExpandLess, ExpandMore } from "@mui/icons-material";
import ChevronLeftIcon from "@mui/icons-material/ChevronLeft";
import ChevronRightIcon from "@mui/icons-material/ChevronRight";
import DashboardIcon from "@mui/icons-material/Dashboard";
import DynamicFeedIcon from "@mui/icons-material/DynamicFeed";
import EditNoteIcon from "@mui/icons-material/EditNote";
import GroupIcon from "@mui/icons-material/Group";
import GroupsIcon from "@mui/icons-material/Groups";
import ManageAccountsIcon from "@mui/icons-material/ManageAccounts";
import MenuIcon from "@mui/icons-material/Menu";
import InboxIcon from "@mui/icons-material/MoveToInbox";
import NoteAltIcon from "@mui/icons-material/NoteAlt";
import PaymentsIcon from "@mui/icons-material/Payments";
import PersonIcon from "@mui/icons-material/Person";
import SchoolIcon from "@mui/icons-material/School";
import SettingsApplicationsIcon from "@mui/icons-material/SettingsApplications";
import SummarizeIcon from "@mui/icons-material/Summarize";
import TextSnippetIcon from "@mui/icons-material/TextSnippet";
import WorkIcon from "@mui/icons-material/Work";
import { Button, Collapse } from "@mui/material";
import MuiAppBar, { AppBarProps as MuiAppBarProps } from "@mui/material/AppBar";
import Box from "@mui/material/Box";
import CssBaseline from "@mui/material/CssBaseline";
import Divider from "@mui/material/Divider";
import Drawer from "@mui/material/Drawer";
import IconButton from "@mui/material/IconButton";
import List from "@mui/material/List";
import ListItemButton from "@mui/material/ListItemButton";
import ListItemIcon from "@mui/material/ListItemIcon";
import ListItemText from "@mui/material/ListItemText";
import Toolbar from "@mui/material/Toolbar";
import Typography from "@mui/material/Typography";
import { styled, useTheme } from "@mui/material/styles";
import useMediaQuery from "@mui/material/useMediaQuery";
import { useEffect, useState } from "react";
import { Outlet, useLocation, useNavigate } from "react-router-dom";
import "../Components/Home/Home.scss";
import AccountProfile from "./LogoutProfile";
import { userLogout } from "../api/api";

const drawerWidth = 240;

const Main = styled("main", { shouldForwardProp: (prop) => prop !== "open" })<{
  open?: boolean;
}>(({ theme, open }) => ({
  flexGrow: 1,
  padding: theme.spacing(3),
  transition: theme.transitions.create("margin", {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.leavingScreen,
  }),
  marginLeft: `-${drawerWidth}px`,
  ...(open && {
    transition: theme.transitions.create("margin", {
      easing: theme.transitions.easing.easeOut,
      duration: theme.transitions.duration.enteringScreen,
    }),
    marginLeft: 0,
  }),
}));

interface AppBarProps extends MuiAppBarProps {
  open?: boolean;
}

const AppBar = styled(MuiAppBar, {
  shouldForwardProp: (prop) => prop !== "open",
})<AppBarProps>(({ theme, open }) => ({
  backgroundColor: "#ffffff",
  color: "blue",
  transition: theme.transitions.create(["margin", "width"], {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.leavingScreen,
  }),
  ...(open && {
    width: `calc(100% - ${drawerWidth}px)`,
    marginLeft: `${drawerWidth}px`,
    transition: theme.transitions.create(["margin", "width"], {
      easing: theme.transitions.easing.easeOut,
      duration: theme.transitions.duration.enteringScreen,
    }),
  }),
}));

const DrawerHeader = styled("div")(({ theme }) => ({
  display: "flex",
  alignItems: "center",
  padding: theme.spacing(0, 1),
  // necessary for content to be below app bar
  ...theme.mixins.toolbar,
  justifyContent: "flex-end",
}));

export default function MainLayout(props: any) {
  const { updateType } = props;
  const theme = useTheme();
  const Location = useLocation();
  const navigate = useNavigate();
  const switchUser = Location?.state;
  const isSmallScreen = useMediaQuery(theme.breakpoints.down("md"));

  const [types, setTypes] = useState<any>({});
  const [admintype, setAdmintype] = useState<any>({});
  const [open, setOpen] = useState(true);
  const [open1, setOpen1] = useState(false);
  const [open2, setOpen2] = useState(false);
  const [open3, setOpen3] = useState(false);
  const [open4, setOpen4] = useState(false);
  const [open5, setOpen5] = useState(false);
  const [open6, setOpen6] = useState(false);
  const [open7, setOpen7] = useState(false);
  const [open8, setOpen8] = useState(false);
  const [open9, setOpen9] = useState(false);
  const [open10, setOpen10] = useState(false);
  const [open11, setOpen11] = useState(false);
  const [open12, setOpen12] = useState(false);
  const [open13, setOpen13] = useState(false);
  const [open14, setOpen14] = useState(false);

  // active drawer buttons styling dynamic route click
  const [activeButton, setActiveButton] = useState(null);

  // switch hiding and show
  const [switchbtn, setSwitchbtn] = useState<any>({});

  // 1.Administrator
  const Dropdown = {
    Job: [
      { name: "Manage Job", route: "ManageJob" },
      { name: "Add Job", route: "AddJob" },
      { name: "Manage Career", route: "manage-career" },
    ],
    University: [
      { name: "Manage University", route: "ManageUniversity" },
      { name: "Add University", route: "AddUniversity" },
      { name: "University CSV Import", route: "UniversityCSVImport" },
      { name: "University Courses", route: "UniversityCourses" },
    ],
    Lead: [
      { name: "Manage Assigned Lead", route: "ManageAssignedLead" },
      { name: "Manage Unassigned Lead", route: "ManageUnassignedLead" },
      { name: "Lead CSV Import", route: "LeadCSVImport" },
      { name: "Add Lead", route: "AddLead" },
      { name: "Rejected Leads", route: "RejectedLeads" },
      { name: "Reference Users", route: "ReferenceUser" },
    ],
    CenterHead: [
      { name: "Manage Center Head", route: "ManageCenterHead" },
      { name: "Add Center Head", route: "AddCenterHead" },
    ],

    TeamLead: [
      { name: "Manage Team Lead", route: "ManageTeamLead" },
      { name: "Add Team Lead", route: "AddTeamLead" },
    ],

    Executive: [
      { name: "Manage Executive", route: "ManageExecutive" },
      { name: "Add Executive", route: "AddExecutive" },
    ],

    Student: [
      { name: "Manage Student", route: "ManageStudent" },
      { name: "Fee Payment", route: "FeePayment" },
      { name: "Receipt Verification", route: "ReceiptVerification" },
      { name: "Manage NOC", route: "ManageNOC" },
    ],

    Result: [
      { name: "Manage Result", route: "ManageResult" },
      { name: "Add Result", route: "AddResult" },
    ],

    Paper: [
      { name: "Manage Paper", route: "ManagePaper" },
      { name: "Add Paper", route: "AddPaper" },
    ],

    Commision: [
      { name: "Centerhead Unpaid", route: "CenterHeadUnPaid" },
      { name: "Centerhead Paid", route: "CenterHeadPaid" },
      { name: "Teamlead Unpaid", route: "TeamLeadUnPaid" },
      { name: "Teamlead Paid", route: "TeamLeadPaid" },
      { name: "Executive Unpaid", route: "ExecutiveUnPaid" },
      { name: "Executive Paid", route: "ExecutivePaid" },
      { name: "Student Unpaid", route: "StudentUnPaid" },
      { name: "Student Paid", route: "StudentPaid" },
    ],

    Reports: [
      { name: "All Receipt", route: "AllReceipt" },
      { name: "Fees Report", route: "FeesReport" },
      { name: "Salary System", route: "SalarySystem" },
      { name: "Fee Statics", route: "FeeStatics" },
      { name: "Contact Us", route: "ContactUs" },
      { name: "Visitor", route: "Visitor" },
      { name: "Employee Complains & Suggestions", route: "manage-complains" },
      { name: "Student Complains & Feedback", route: "StudentFeedback" },
    ],

    Leave: [{ name: "Manage Application", route: "ManageApplication" }],
  };

  // 2.Centerhead
  const CenterHead = {
    PersonalInfo: [
      { name: "Personal Information", route: "PersonalInformation" },
    ],
    //Manage Student
    Student: [
      { name: "Manage Student", route: "ManageStudent" },
      { name: "Fee Payment", route: "FeePayment" },
      { name: "Manage NOC", route: "ManageNOC" },
    ],

    //Manage Lead
    Lead: [{ name: "Manage Assigned Lead", route: "ManageAssignedLead" }],

    //University & Courses
    University: [{ name: "University Courses", route: "UniversityCourses" }],

    //Results
    Result: [{ name: "View Result", route: "ManageResult" }],

    //Paper
    Paper: [{ name: "View Paper", route: "ManagePaper" }],

    //Manage Team Lead
    TeamLead: [{ name: "Manage Team Lead", route: "ManageTeamLead" }],

    //Manage Executive
    Executive: [{ name: "Manage Executive", route: "ManageExecutive" }],

    //Courier
    Courier: [{ name: "Manage Courier", route: "Courier" }],

    //Report
    Reports: [
      { name: "All Receipt", route: "AllReceipt" },
      { name: "Fees Receipt", route: "FeesReport" },
      { name: "Salary Slip", route: "SalarySystem" },
      { name: "Complain", route: "complain" },
    ],

    //Application For Leave
    Leave: [
      { name: "Add Application", route: "AddApplication" },
      { name: "View Application Status", route: "ViewApplication" },
    ],
  };

  // 3.Teamlead
  const TeamLead = {
    //Manage Follow-up
    Lead: [{ name: "Manage Assigned Lead", route: "ManageAssignedLead" }],

    //University & Courses
    University: [{ name: "University Courses", route: "UniversityCourses" }],

    //Manage Student
    Student: [
      { name: "Manage Student", route: "ManageStudent" },
      { name: "Fee Payment", route: "FeePayment" },
      { name: "Manage NOC", route: "ManageNOC" },
    ],

    //Results
    Result: [{ name: "View Result", route: "ManageResult" }],

    //Paper
    Paper: [{ name: "View Paper", route: "ManagePaper" }],

    //Manage Executive
    Executive: [{ name: "Manage Executive", route: "ManageExecutive" }],

    //Personal Information
    PersonalInformation: [
      { name: "Personal Information", route: "PersonalInformation" },
    ],

    //Manage Courier
    Courier: [{ name: "Manage Courier", route: "Courier" }],

    //Application For Leave
    Leave: [
      { name: "Add Application", route: "AddApplication" },
      { name: "View Application Status", route: "ViewApplication" },
    ],

    //Report
    Reports: [
      { name: "Salary Slip", route: "SalarySystem" },
      { name: "Complain", route: "complain" },
    ],
  };

  // 4.Executive
  const Executive = {
    //Manage Follow-up
    Lead: [
      { name: "Manage Assigned Lead", route: "ManageAssignedLead" },
      { name: "Reference User", route: "ReferenceUser" },
    ],

    //University & Courses
    University: [{ name: "University Courses", route: "UniversityCourses" }],

    //Manage Student
    Student: [
      { name: "Manage Student", route: "ManageStudent" },
      { name: "New Registration", route: "NewRegistration" },
      { name: "Re-Registration", route: "Re-Registration" },
      { name: "Fee Payment", route: "FeePayment" },
      { name: "Manage NOC", route: "ManageNOC" },
    ],

    //Results
    Result: [{ name: "View Result", route: "ManageResult" }],

    //Paper
    Paper: [{ name: "View Paper", route: "ManagePaper" }],

    //Personal Information
    PersonalInformation: [
      { name: "Personal Information", route: "PersonalInformation" },
    ],

    //Manage Courier
    Courier: [{ name: "Manage Courier", route: "Courier" }],

    //Application For Leave
    Leave: [
      { name: "Add Application", route: "AddApplication" },
      { name: "View Application Status", route: "ViewApplication" },
    ],

    //Report
    Reports: [
      { name: "Salary Slip", route: "SalarySystem" },
      { name: "Complain", route: "complain" },
    ],
  };

  // 5.Student
  const Student = {
    Student: [
      { name: "Profile", route: "RegistrationDetails" },
      { name: "Show NOC", route: "ManageNOC" },
    ],
    Account: [
      { name: "Paid Installments", route: "AccountDetails" },
      { name: "Unpaid Installments", route: "UnpaidInstallments" },
    ],
    //Results
    Result: [{ name: "View Result", route: "ManageResult" }],

    //Paper
    Paper: [{ name: "View Paper", route: "ManagePaper" }],

    //Manage Courier
    Courier: [{ name: "View Courier", route: "Courier" }],

    //Report
    Reports: [{ name: "Complain & Feedback", route: "FeedbackForm" }],

    //Reference
    Reference: [{ name: "Reference Student", route: "ReferenceStudent" }],
  };

  // check inactivity and logout
  const inactive = (): void => {
    const exptime: any = sessionStorage.getItem("exptime");
    if (exptime < Date.now()) {
      userLogout();
      sessionStorage.clear();
      navigate("/login");
    }
  };

  // update expiry date
  const updateExpiryDate = (): void => {
    const expiryTime: any = Date.now() + 3000000;
    sessionStorage.setItem("exptime", expiryTime);
  };

  const handleDrawerOpen = () => {
    setOpen(!open);
  };

  const handleClick = () => {
    // Call the function passed from the parent component and pass the props as arguments
    updateType(types);
  };

  const handleClick1 = () => {
    setOpen1(!open1);
  };

  const handleClick2 = () => {
    setOpen2(!open2);
    setOpen3(false);
    setOpen4(false);
    setOpen5(false);
    setOpen6(false);
    setOpen7(false);
    setOpen8(false);
    setOpen9(false);
    setOpen10(false);
    setOpen11(false);
  };

  const handleClick3 = () => {
    setOpen2(false);
    setOpen3(!open3);
    setOpen4(false);
    setOpen5(false);
    setOpen6(false);
    setOpen7(false);
    setOpen8(false);
    setOpen9(false);
    setOpen10(false);
    setOpen11(false);
  };

  const handleClick4 = () => {
    setOpen2(false);
    setOpen3(false);
    setOpen4(!open4);
    setOpen5(false);
    setOpen6(false);
    setOpen7(false);
    setOpen8(false);
    setOpen9(false);
    setOpen10(false);
    setOpen11(false);
  };

  const handleClick5 = () => {
    setOpen2(false);
    setOpen3(false);
    setOpen4(false);
    setOpen5(!open5);
    setOpen6(false);
    setOpen7(false);
    setOpen8(false);
    setOpen9(false);
    setOpen10(false);
    setOpen11(false);
  };

  const handleClick6 = () => {
    setOpen2(false);
    setOpen3(false);
    setOpen4(false);
    setOpen5(false);
    setOpen6(!open6);
    setOpen7(false);
    setOpen8(false);
    setOpen9(false);
    setOpen10(false);
    setOpen11(false);
  };

  const handleClick7 = () => {
    setOpen2(false);
    setOpen3(false);
    setOpen4(false);
    setOpen5(false);
    setOpen6(false);
    setOpen7(!open7);
    setOpen8(false);
    setOpen9(false);
    setOpen10(false);
    setOpen11(false);
  };

  const handleClick8 = () => {
    setOpen2(false);
    setOpen3(false);
    setOpen4(false);
    setOpen5(false);
    setOpen6(false);
    setOpen7(false);
    setOpen8(!open8);
    setOpen9(false);
    setOpen10(false);
    setOpen11(false);
  };

  const handleClick9 = () => {
    setOpen2(false);
    setOpen3(false);
    setOpen4(false);
    setOpen5(false);
    setOpen6(false);
    setOpen7(false);
    setOpen8(false);
    setOpen9(!open9);
    setOpen10(false);
    setOpen11(false);
  };

  const handleClick10 = () => {
    setOpen2(false);
    setOpen3(false);
    setOpen4(false);
    setOpen5(false);
    setOpen6(false);
    setOpen7(false);
    setOpen8(false);
    setOpen9(false);
    setOpen10(!open10);
    setOpen11(false);
  };

  const handleClick11 = () => {
    setOpen2(false);
    setOpen3(false);
    setOpen4(false);
    setOpen5(false);
    setOpen6(false);
    setOpen7(false);
    setOpen8(false);
    setOpen9(false);
    setOpen10(false);
    setOpen11(!open11);
  };

  const handleClick12 = () => {
    setOpen12(!open12);
  };

  const handleClick13 = () => {
    setOpen13(!open13);
  };

  const handleClick14 = () => {
    setOpen14(!open14);
    setOpen3(false);
    setOpen4(false);
    setOpen5(false);
    setOpen6(false);
    setOpen7(false);
    setOpen8(false);
    setOpen9(false);
    setOpen10(false);
    setOpen11(false);
  };

  const navigat = (route: any) => {
    navigate(route);
    setActiveButton(route);
  };

  const getButtonStyle = (route: any) => {
    return {
      color: activeButton === route ? "white" : "black",
      backgroundColor: activeButton === route ? "#0288d1" : "white",
      "&:hover": {
        backgroundColor: "lightblue",
        color: "black",
      },
    };
  };

  useEffect(() => {
    const auth = sessionStorage.getItem("user");
    if (auth) {
      const myObject = JSON.parse(auth);
      // Now you can use the object
      setTypes(myObject);
      setAdmintype(myObject);
    }
  }, []);

  useEffect(() => {
    const interval = setInterval(() => {
      inactive();
    }, 1000);
    return () => {
      clearInterval(interval);
    };
  }, []);

  // call update expdate function on user events
  useEffect(() => {
    // set initial time
    updateExpiryDate();

    // set event listeners
    window.addEventListener("click", updateExpiryDate);
    window.addEventListener("keypress", updateExpiryDate);
    window.addEventListener("scroll", updateExpiryDate);
    window.addEventListener("mousemove", updateExpiryDate);

    // remove event listeners on cleanup
    return () => {
      window.removeEventListener("click", updateExpiryDate);
      window.removeEventListener("keypress", updateExpiryDate);
      window.removeEventListener("scroll", updateExpiryDate);
      window.removeEventListener("mousemove", updateExpiryDate);
    };
  }, []);

  useEffect(() => {
    if (isSmallScreen) {
      // Call your function for small screens
      setOpen(false);
    } else {
      // Call your function for other screens
      setOpen(true);
    }
  }, [isSmallScreen]);

  useEffect(() => {
    if (switchUser) {
      setTypes(Location?.state);
      setSwitchbtn(Location?.state);
    }
  }, []);

  useEffect(() => {
    if (types) {
      handleClick();
    }
  }, [types]);

  return (
    <>
      <Box sx={{ display: "flex" }}>
        <CssBaseline />
        <AppBar
          position="fixed"
          sx={{ zIndex: (theme) => theme.zIndex.drawer + 1 }}
        >
          <Toolbar>
            <Typography
              variant="h6"
              noWrap
              component="img"
              src="https://ik.imagekit.io/nwssoft/NWS_Prerna_Project/prerna-academy-logo.png?updatedAt=1679569413646"
              sx={{
                width: "40px",
                mr: 2,
                display: { xs: "block", md: "flex" },
                fontFamily: "monospace",
                fontWeight: 700,
                letterSpacing: ".3rem",
                color: "inherit",
                textDecoration: "none",
              }}
            ></Typography>
            <Typography
              variant="h6"
              noWrap
              component="div"
              sx={{ display: { xs: "none", md: "block" } }}
            >
              Prerna Academy
            </Typography>
            <IconButton
              color="inherit"
              aria-label="open drawer"
              onClick={handleDrawerOpen}
              edge="start"
              sx={{ ml: 2, ...(open && { display: "block" }) }}
            >
              <MenuIcon />
            </IconButton>
            {admintype.type === "Administrator" &&
            (switchbtn.type === "Center Head" ||
              switchbtn.type === "Team Lead" ||
              switchbtn.type === "Executive") ? (
              <>
                <Button
                  variant="contained"
                  size="medium"
                  onClick={() => {
                    setTypes(admintype);
                    setSwitchbtn(admintype);
                    navigate("/dashboard");
                  }}
                >
                  Switch User
                </Button>
              </>
            ) : null}

            {admintype.type === "Center Head" &&
            (switchbtn.type === "Team Lead" ||
              switchbtn.type === "Executive") ? (
              <>
                <Button
                  variant="contained"
                  size="medium"
                  onClick={() => {
                    setTypes(admintype);
                    setSwitchbtn(admintype);
                    navigate("/dashboard");
                  }}
                >
                  Switch User
                </Button>
              </>
            ) : null}
            {admintype.type === "Team Lead" &&
            switchbtn.type === "Executive" ? (
              <>
                <Button
                  variant="contained"
                  size="medium"
                  onClick={() => {
                    setTypes(admintype);
                    setSwitchbtn(admintype);
                    navigate("/dashboard");
                  }}
                >
                  Switch User
                </Button>
              </>
            ) : null}

            <Box sx={{ flexGrow: 1 }} />

            {/* profile child component rendered in main layout */}
            <AccountProfile types={types} />
          </Toolbar>
        </AppBar>
        <Drawer
          sx={{
            width: drawerWidth,
            flexShrink: 0,
            "& .MuiDrawer-paper": {
              width: drawerWidth,
              boxSizing: "border-box",

              "&::-webkit-scrollbar": {
                width: 10,
                height: 10,
              },

              "&::-webkit-scrollbar-track": {
                backgroundColor: " #90caf9",
              },

              "&::-webkit-scrollbar-thumb": {
                backgroundColor: "#0288d1",
                borderRadius: 2,
              },
            },
          }}
          variant="persistent"
          anchor="left"
          open={open}
        >
          <DrawerHeader>
            <IconButton>
              {theme.direction === "ltr" ? (
                <ChevronLeftIcon />
              ) : (
                <ChevronRightIcon />
              )}
            </IconButton>
          </DrawerHeader>
          <Divider />
          {/* administrator */}
          {types.type === "Administrator" ? (
            <>
              <List
                sx={{
                  width: "100%",
                  maxWidth: 360,
                  bgcolor: "background.paper",
                }}
                component="nav"
                aria-labelledby="nested-list-subheader"
              >
                <ListItemButton
                  sx={getButtonStyle("/dashboard")}
                  onClick={() => navigat("/dashboard")}
                >
                  <ListItemIcon>
                    <DashboardIcon />
                  </ListItemIcon>
                  <ListItemText primary="Dashboard" />
                </ListItemButton>
                <ListItemButton onClick={handleClick1}>
                  <ListItemIcon>
                    <ManageAccountsIcon />
                  </ListItemIcon>
                  <ListItemText primary="Manage" />
                  {open1 ? <ExpandLess /> : <ExpandMore />}
                </ListItemButton>
                <Collapse in={open1} timeout="auto" unmountOnExit>
                  <List component="div" disablePadding>
                    <ListItemButton onClick={handleClick2}>
                      <ListItemIcon>
                        <WorkIcon />
                      </ListItemIcon>
                      <ListItemText primary="Job" />
                      {open2 ? <ExpandLess /> : <ExpandMore />}
                    </ListItemButton>
                    <Collapse in={open2} timeout="auto" unmountOnExit>
                      <List component="div" disablePadding>
                        {Dropdown.Job.map((text2) => (
                          <ListItemButton
                            sx={getButtonStyle(text2.route)}
                            key={text2.name}
                            onClick={() => navigat(text2.route)}
                          >
                            <ListItemIcon></ListItemIcon>
                            <ListItemText primary={text2.name} />
                          </ListItemButton>
                        ))}
                      </List>
                    </Collapse>
                    <ListItemButton onClick={handleClick3}>
                      <ListItemIcon>
                        <SchoolIcon />
                      </ListItemIcon>
                      <ListItemText primary="University" />
                      {open3 ? <ExpandLess /> : <ExpandMore />}
                    </ListItemButton>
                    <Collapse in={open3} timeout="auto" unmountOnExit>
                      <List component="div" disablePadding>
                        {Dropdown.University.map((text2) => (
                          <ListItemButton
                            key={text2.name}
                            sx={getButtonStyle(text2.route)}
                            onClick={() => navigat(text2.route)}
                          >
                            <ListItemIcon></ListItemIcon>
                            <ListItemText primary={text2.name} />
                          </ListItemButton>
                        ))}
                      </List>
                    </Collapse>
                    <ListItemButton onClick={handleClick4}>
                      <ListItemIcon>
                        <PersonIcon />
                      </ListItemIcon>
                      <ListItemText primary="Lead" />
                      {open4 ? <ExpandLess /> : <ExpandMore />}
                    </ListItemButton>
                    <Collapse in={open4} timeout="auto" unmountOnExit>
                      <List component="div" disablePadding>
                        {Dropdown.Lead.map((text2) => (
                          <ListItemButton
                            key={text2.name}
                            sx={getButtonStyle(text2.route)}
                            onClick={() => navigat(text2.route)}
                          >
                            <ListItemIcon></ListItemIcon>
                            <ListItemText primary={text2.name} />
                          </ListItemButton>
                        ))}
                      </List>
                    </Collapse>
                    <ListItemButton onClick={handleClick5}>
                      <ListItemIcon>
                        <PersonIcon />
                      </ListItemIcon>
                      <ListItemText primary="Center Head" />
                      {open5 ? <ExpandLess /> : <ExpandMore />}
                    </ListItemButton>
                    <Collapse in={open5} timeout="auto" unmountOnExit>
                      <List component="div" disablePadding>
                        {Dropdown.CenterHead.map((text2) => (
                          <ListItemButton
                            key={text2.name}
                            sx={getButtonStyle(text2.route)}
                            onClick={() => navigat(text2.route)}
                          >
                            <ListItemIcon></ListItemIcon>
                            <ListItemText primary={text2.name} />
                          </ListItemButton>
                        ))}
                      </List>
                    </Collapse>
                    <ListItemButton onClick={handleClick6}>
                      <ListItemIcon>
                        <GroupIcon />
                      </ListItemIcon>
                      <ListItemText primary="Teamlead" />
                      {open6 ? <ExpandLess /> : <ExpandMore />}
                    </ListItemButton>
                    <Collapse in={open6} timeout="auto" unmountOnExit>
                      <List component="div" disablePadding>
                        {Dropdown.TeamLead.map((text2) => (
                          <ListItemButton
                            key={text2.name}
                            sx={getButtonStyle(text2.route)}
                            onClick={() => navigat(text2.route)}
                          >
                            <ListItemIcon></ListItemIcon>
                            <ListItemText primary={text2.name} />
                          </ListItemButton>
                        ))}
                      </List>
                    </Collapse>
                    <ListItemButton onClick={handleClick7}>
                      <ListItemIcon>
                        <GroupIcon />
                      </ListItemIcon>
                      <ListItemText primary="Executive" />
                      {open7 ? <ExpandLess /> : <ExpandMore />}
                    </ListItemButton>
                    <Collapse in={open7} timeout="auto" unmountOnExit>
                      <List component="div" disablePadding>
                        {Dropdown.Executive.map((text2) => (
                          <ListItemButton
                            key={text2.name}
                            sx={getButtonStyle(text2.route)}
                            onClick={() => navigat(text2.route)}
                          >
                            <ListItemIcon></ListItemIcon>
                            <ListItemText primary={text2.name} />
                          </ListItemButton>
                        ))}
                      </List>
                    </Collapse>
                    <ListItemButton onClick={handleClick8}>
                      <ListItemIcon>
                        <GroupsIcon />
                      </ListItemIcon>
                      <ListItemText primary="Student" />
                      {open8 ? <ExpandLess /> : <ExpandMore />}
                    </ListItemButton>
                    <Collapse in={open8} timeout="auto" unmountOnExit>
                      <List component="div" disablePadding>
                        {Dropdown.Student.map((text2) => (
                          <ListItemButton
                            key={text2.name}
                            sx={getButtonStyle(text2.route)}
                            onClick={() => navigat(text2.route)}
                          >
                            <ListItemIcon></ListItemIcon>
                            <ListItemText primary={text2.name} />
                          </ListItemButton>
                        ))}
                      </List>
                    </Collapse>
                    <ListItemButton onClick={handleClick9}>
                      <ListItemIcon>
                        <TextSnippetIcon />
                      </ListItemIcon>
                      <ListItemText primary="Result" />
                      {open9 ? <ExpandLess /> : <ExpandMore />}
                    </ListItemButton>
                    <Collapse in={open9} timeout="auto" unmountOnExit>
                      <List component="div" disablePadding>
                        {Dropdown.Result.map((text2) => (
                          <ListItemButton
                            key={text2.name}
                            sx={getButtonStyle(text2.route)}
                            onClick={() => navigat(text2.route)}
                          >
                            <ListItemIcon></ListItemIcon>
                            <ListItemText primary={text2.name} />
                          </ListItemButton>
                        ))}
                      </List>
                    </Collapse>
                    <ListItemButton onClick={handleClick10}>
                      <ListItemIcon>
                        <NoteAltIcon />
                      </ListItemIcon>
                      <ListItemText primary="Papers" />
                      {open10 ? <ExpandLess /> : <ExpandMore />}
                    </ListItemButton>
                    <Collapse in={open10} timeout="auto" unmountOnExit>
                      <List component="div" disablePadding>
                        {Dropdown.Paper.map((text2) => (
                          <ListItemButton
                            key={text2.name}
                            sx={getButtonStyle(text2.route)}
                            onClick={() => navigat(text2.route)}
                          >
                            <ListItemIcon></ListItemIcon>
                            <ListItemText primary={text2.name} />
                          </ListItemButton>
                        ))}
                      </List>
                    </Collapse>
                    <ListItemButton onClick={handleClick11}>
                      <ListItemIcon>
                        <PaymentsIcon />
                      </ListItemIcon>
                      <ListItemText primary="Commision" />
                      {open11 ? <ExpandLess /> : <ExpandMore />}
                    </ListItemButton>
                    <Collapse in={open11} timeout="auto" unmountOnExit>
                      <List component="div" disablePadding>
                        {Dropdown.Commision.map((text2) => (
                          <ListItemButton
                            key={text2.name}
                            sx={getButtonStyle(text2.route)}
                            onClick={() => navigat(text2.route)}
                          >
                            <ListItemIcon></ListItemIcon>
                            <ListItemText primary={text2.name} />
                          </ListItemButton>
                        ))}
                      </List>
                    </Collapse>
                  </List>
                </Collapse>
                <ListItemButton
                  sx={getButtonStyle("Settings")}
                  onClick={() => navigat("Settings")}
                >
                  <ListItemIcon>
                    <SettingsApplicationsIcon />
                  </ListItemIcon>
                  <ListItemText primary="Settings" />
                </ListItemButton>
                <ListItemButton onClick={handleClick12}>
                  <ListItemIcon>
                    <SummarizeIcon />
                  </ListItemIcon>
                  <ListItemText primary="Reports" />
                  {open12 ? <ExpandLess /> : <ExpandMore />}
                </ListItemButton>
                <Collapse in={open12} timeout="auto" unmountOnExit>
                  <List component="div" disablePadding>
                    {Dropdown.Reports.map((text2) => (
                      <ListItemButton
                        key={text2.name}
                        sx={getButtonStyle(text2.route)}
                        onClick={() => navigat(text2.route)}
                      >
                        <ListItemIcon></ListItemIcon>
                        <ListItemText primary={text2.name} />
                      </ListItemButton>
                    ))}
                  </List>
                </Collapse>
                <ListItemButton
                  sx={getButtonStyle("Courier")}
                  onClick={() => navigat("Courier")}
                >
                  <ListItemIcon>
                    <DynamicFeedIcon />
                  </ListItemIcon>
                  <ListItemText primary="Courier" />
                </ListItemButton>
                <ListItemButton onClick={handleClick13}>
                  <ListItemIcon>
                    <EditNoteIcon />
                  </ListItemIcon>
                  <ListItemText primary="Application For Leave" />
                  {open13 ? <ExpandLess /> : <ExpandMore />}
                </ListItemButton>
                <Collapse in={open13} timeout="auto" unmountOnExit>
                  <List component="div" disablePadding>
                    {Dropdown.Leave.map((text2) => (
                      <ListItemButton
                        key={text2.name}
                        sx={getButtonStyle(text2.route)}
                        onClick={() => navigat(text2.route)}
                      >
                        <ListItemIcon></ListItemIcon>
                        <ListItemText primary={text2.name} />
                      </ListItemButton>
                    ))}
                  </List>
                </Collapse>
              </List>
            </>
          ) : (
            ""
          )}

          {/* center head */}
          {types.type === "Center Head" ? (
            <>
              <List
                sx={{
                  width: "100%",
                  maxWidth: 360,
                  bgcolor: "background.paper",
                }}
                component="nav"
                aria-labelledby="nested-list-subheader"
              >
                <ListItemButton
                  sx={getButtonStyle("/dashboard")}
                  onClick={() => navigat("/dashboard")}
                >
                  <ListItemIcon>
                    <DashboardIcon />
                  </ListItemIcon>
                  <ListItemText primary="Dashboard" />
                </ListItemButton>
                <List component="div" disablePadding>
                  {CenterHead.PersonalInfo.map((text2) => (
                    <ListItemButton
                      key={text2.name}
                      sx={getButtonStyle(text2.route)}
                      onClick={() => navigat(text2.route)}
                    >
                      <ListItemIcon>
                        {" "}
                        <DynamicFeedIcon />
                      </ListItemIcon>
                      <ListItemText primary={text2.name} />
                    </ListItemButton>
                  ))}
                </List>
                {/* </Collapse> */}

                <ListItemButton onClick={handleClick1}>
                  <ListItemIcon>
                    <ManageAccountsIcon />
                  </ListItemIcon>
                  <ListItemText primary="Manage" />
                  {open1 ? <ExpandLess /> : <ExpandMore />}
                </ListItemButton>
                <Collapse in={open1} timeout="auto" unmountOnExit>
                  <List component="div" disablePadding>
                    <ListItemButton onClick={handleClick4}>
                      <ListItemIcon>
                        <PersonIcon />
                      </ListItemIcon>
                      <ListItemText primary="University & Courses" />
                      {open4 ? <ExpandLess /> : <ExpandMore />}
                    </ListItemButton>
                    <Collapse in={open4} timeout="auto" unmountOnExit>
                      <List component="div" disablePadding>
                        {CenterHead.University.map((text2) => (
                          <ListItemButton
                            key={text2.name}
                            sx={getButtonStyle(text2.route)}
                            onClick={() => navigat(text2.route)}
                          >
                            <ListItemIcon></ListItemIcon>
                            <ListItemText primary={text2.name} />
                          </ListItemButton>
                        ))}
                      </List>
                    </Collapse>
                    <ListItemButton onClick={handleClick3}>
                      <ListItemIcon>
                        <SchoolIcon />
                      </ListItemIcon>
                      <ListItemText primary="Lead" />
                      {open3 ? <ExpandLess /> : <ExpandMore />}
                    </ListItemButton>
                    <Collapse in={open3} timeout="auto" unmountOnExit>
                      <List component="div" disablePadding>
                        {CenterHead.Lead.map((text2) => (
                          <ListItemButton
                            key={text2.name}
                            sx={getButtonStyle(text2.route)}
                            onClick={() => navigat(text2.route)}
                          >
                            <ListItemIcon></ListItemIcon>
                            <ListItemText primary={text2.name} />
                          </ListItemButton>
                        ))}
                      </List>
                    </Collapse>
                    <ListItemButton onClick={handleClick7}>
                      <ListItemIcon>
                        <GroupIcon />
                      </ListItemIcon>
                      <ListItemText primary="Team Lead" />
                      {open7 ? <ExpandLess /> : <ExpandMore />}
                    </ListItemButton>
                    <Collapse in={open7} timeout="auto" unmountOnExit>
                      <List component="div" disablePadding>
                        {CenterHead.TeamLead.map((text2) => (
                          <ListItemButton
                            key={text2.name}
                            sx={getButtonStyle(text2.route)}
                            onClick={() => navigat(text2.route)}
                          >
                            <ListItemIcon></ListItemIcon>
                            <ListItemText primary={text2.name} />
                          </ListItemButton>
                        ))}
                      </List>
                    </Collapse>
                    <ListItemButton onClick={handleClick8}>
                      <ListItemIcon>
                        <GroupsIcon />
                      </ListItemIcon>
                      <ListItemText primary="Executive" />
                      {open8 ? <ExpandLess /> : <ExpandMore />}
                    </ListItemButton>
                    <Collapse in={open8} timeout="auto" unmountOnExit>
                      <List component="div" disablePadding>
                        {CenterHead.Executive.map((text2) => (
                          <ListItemButton
                            key={text2.name}
                            sx={getButtonStyle(text2.route)}
                            onClick={() => navigat(text2.route)}
                          >
                            <ListItemIcon></ListItemIcon>
                            <ListItemText primary={text2.name} />
                          </ListItemButton>
                        ))}
                      </List>
                    </Collapse>
                    <ListItemButton onClick={handleClick2}>
                      <ListItemIcon>
                        <WorkIcon />
                      </ListItemIcon>
                      <ListItemText primary="Student" />
                      {open2 ? <ExpandLess /> : <ExpandMore />}
                    </ListItemButton>
                    <Collapse in={open2} timeout="auto" unmountOnExit>
                      <List component="div" disablePadding>
                        {CenterHead.Student.map((text2) => (
                          <ListItemButton
                            key={text2.name}
                            sx={getButtonStyle(text2.route)}
                            onClick={() => navigat(text2.route)}
                          >
                            <ListItemIcon></ListItemIcon>
                            <ListItemText primary={text2.name} />
                          </ListItemButton>
                        ))}
                      </List>
                    </Collapse>

                    <ListItemButton onClick={handleClick5}>
                      <ListItemIcon>
                        <PersonIcon />
                      </ListItemIcon>
                      <ListItemText primary="Results" />
                      {open5 ? <ExpandLess /> : <ExpandMore />}
                    </ListItemButton>
                    <Collapse in={open5} timeout="auto" unmountOnExit>
                      <List component="div" disablePadding>
                        {CenterHead.Result.map((text2) => (
                          <ListItemButton
                            key={text2.name}
                            sx={getButtonStyle(text2.route)}
                            onClick={() => navigat(text2.route)}
                          >
                            <ListItemIcon></ListItemIcon>
                            <ListItemText primary={text2.name} />
                          </ListItemButton>
                        ))}
                      </List>
                    </Collapse>
                    <ListItemButton onClick={handleClick6}>
                      <ListItemIcon>
                        <GroupIcon />
                      </ListItemIcon>
                      <ListItemText primary="Paper" />
                      {open6 ? <ExpandLess /> : <ExpandMore />}
                    </ListItemButton>
                    <Collapse in={open6} timeout="auto" unmountOnExit>
                      <List component="div" disablePadding>
                        {CenterHead.Paper.map((text2) => (
                          <ListItemButton
                            key={text2.name}
                            sx={getButtonStyle(text2.route)}
                            onClick={() => navigat(text2.route)}
                          >
                            <ListItemIcon></ListItemIcon>
                            <ListItemText primary={text2.name} />
                          </ListItemButton>
                        ))}
                      </List>
                    </Collapse>
                  </List>
                </Collapse>

                <ListItemButton onClick={handleClick12}>
                  <ListItemIcon>
                    <SummarizeIcon />
                  </ListItemIcon>
                  <ListItemText primary="Reports" />
                  {open12 ? <ExpandLess /> : <ExpandMore />}
                </ListItemButton>
                <Collapse in={open12} timeout="auto" unmountOnExit>
                  <List component="div" disablePadding>
                    {CenterHead.Reports.map((text2) => (
                      <ListItemButton
                        key={text2.name}
                        sx={getButtonStyle(text2.route)}
                        onClick={() => navigat(text2.route)}
                      >
                        <ListItemIcon>{/* <StarBorder /> */}</ListItemIcon>
                        <ListItemText primary={text2.name} />
                      </ListItemButton>
                    ))}
                  </List>
                </Collapse>
                <ListItemButton
                  sx={getButtonStyle("Courier")}
                  onClick={() => navigat("Courier")}
                >
                  <ListItemIcon>
                    <DynamicFeedIcon />
                  </ListItemIcon>
                  <ListItemText primary="Courier" />
                  {/* {open1 ? <ExpandLess /> : <ExpandMore />} */}
                </ListItemButton>
                <ListItemButton onClick={handleClick13}>
                  <ListItemIcon>
                    <EditNoteIcon />
                  </ListItemIcon>
                  <ListItemText primary="Leave" />
                  {open13 ? <ExpandLess /> : <ExpandMore />}
                </ListItemButton>
                <Collapse in={open13} timeout="auto" unmountOnExit>
                  <List component="div" disablePadding>
                    {CenterHead.Leave.map((text2) => (
                      <ListItemButton
                        key={text2.name}
                        sx={getButtonStyle(text2.route)}
                        onClick={() => navigat(text2.route)}
                      >
                        <ListItemIcon>{/* <StarBorder /> */}</ListItemIcon>
                        <ListItemText primary={text2.name} />
                      </ListItemButton>
                    ))}
                  </List>
                </Collapse>
              </List>
            </>
          ) : (
            ""
          )}
          {/* Team Lead */}
          {types.type === "Team Lead" ? (
            <>
              <List
                sx={{
                  width: "100%",
                  maxWidth: 360,
                  bgcolor: "background.paper",
                }}
                component="nav"
                aria-labelledby="nested-list-subheader"
              >
                <ListItemButton
                  sx={getButtonStyle("/dashboard")}
                  onClick={() => navigat("/dashboard")}
                >
                  <ListItemIcon>
                    <DashboardIcon />
                  </ListItemIcon>
                  <ListItemText primary="Dashboard" />
                </ListItemButton>
                <ListItemButton
                  sx={getButtonStyle("PersonalInformation")}
                  onClick={() => navigat("PersonalInformation")}
                >
                  <ListItemIcon>
                    <DynamicFeedIcon />
                  </ListItemIcon>
                  <ListItemText primary="Personal Information" />
                </ListItemButton>
                <ListItemButton onClick={handleClick1}>
                  <ListItemIcon>
                    <ManageAccountsIcon />
                  </ListItemIcon>
                  <ListItemText primary="Manage" />
                  {open1 ? <ExpandLess /> : <ExpandMore />}
                </ListItemButton>
                <Collapse in={open1} timeout="auto" unmountOnExit>
                  <List component="div" disablePadding>
                    <ListItemButton onClick={handleClick4}>
                      <ListItemIcon>
                        <PersonIcon />
                      </ListItemIcon>
                      <ListItemText primary="University & Courses" />
                      {open4 ? <ExpandLess /> : <ExpandMore />}
                    </ListItemButton>
                    <Collapse in={open4} timeout="auto" unmountOnExit>
                      <List component="div" disablePadding>
                        {TeamLead.University.map((text2) => (
                          <ListItemButton
                            key={text2.name}
                            sx={getButtonStyle(text2.route)}
                            onClick={() => navigat(text2.route)}
                          >
                            <ListItemIcon></ListItemIcon>
                            <ListItemText primary={text2.name} />
                          </ListItemButton>
                        ))}
                      </List>
                    </Collapse>

                    <ListItemButton onClick={handleClick3}>
                      <ListItemIcon>
                        <SchoolIcon />
                      </ListItemIcon>
                      <ListItemText primary="Lead" />
                      {open3 ? <ExpandLess /> : <ExpandMore />}
                    </ListItemButton>
                    <Collapse in={open3} timeout="auto" unmountOnExit>
                      <List component="div" disablePadding>
                        {TeamLead.Lead.map((text2) => (
                          <ListItemButton
                            key={text2.name}
                            sx={getButtonStyle(text2.route)}
                            onClick={() => navigat(text2.route)}
                          >
                            <ListItemIcon></ListItemIcon>
                            <ListItemText primary={text2.name} />
                          </ListItemButton>
                        ))}
                      </List>
                    </Collapse>
                    <ListItemButton onClick={handleClick8}>
                      <ListItemIcon>
                        <GroupsIcon />
                      </ListItemIcon>
                      <ListItemText primary="Executive" />
                      {open8 ? <ExpandLess /> : <ExpandMore />}
                    </ListItemButton>
                    <Collapse in={open8} timeout="auto" unmountOnExit>
                      <List component="div" disablePadding>
                        {TeamLead.Executive.map((text2) => (
                          <ListItemButton
                            key={text2.name}
                            sx={getButtonStyle(text2.route)}
                            onClick={() => navigat(text2.route)}
                          >
                            <ListItemIcon></ListItemIcon>
                            <ListItemText primary={text2.name} />
                          </ListItemButton>
                        ))}
                      </List>
                    </Collapse>

                    <ListItemButton onClick={handleClick2}>
                      <ListItemIcon>
                        <WorkIcon />
                      </ListItemIcon>
                      <ListItemText primary="Student" />
                      {open2 ? <ExpandLess /> : <ExpandMore />}
                    </ListItemButton>
                    <Collapse in={open2} timeout="auto" unmountOnExit>
                      <List component="div" disablePadding>
                        {TeamLead.Student.map((text2) => (
                          <ListItemButton
                            sx={getButtonStyle(text2.route)}
                            onClick={() => navigat(text2.route)}
                            key={text2.name}
                          >
                            <ListItemIcon></ListItemIcon>
                            <ListItemText primary={text2.name} />
                          </ListItemButton>
                        ))}
                      </List>
                    </Collapse>

                    <ListItemButton onClick={handleClick5}>
                      <ListItemIcon>
                        <PersonIcon />
                      </ListItemIcon>
                      <ListItemText primary="Results" />
                      {open5 ? <ExpandLess /> : <ExpandMore />}
                    </ListItemButton>
                    <Collapse in={open5} timeout="auto" unmountOnExit>
                      <List component="div" disablePadding>
                        {TeamLead.Result.map((text2) => (
                          <ListItemButton
                            key={text2.name}
                            sx={getButtonStyle(text2.route)}
                            onClick={() => navigat(text2.route)}
                          >
                            <ListItemIcon></ListItemIcon>
                            <ListItemText primary={text2.name} />
                          </ListItemButton>
                        ))}
                      </List>
                    </Collapse>
                    <ListItemButton onClick={handleClick6}>
                      <ListItemIcon>
                        <GroupIcon />
                      </ListItemIcon>
                      <ListItemText primary="Paper" />
                      {open6 ? <ExpandLess /> : <ExpandMore />}
                    </ListItemButton>
                    <Collapse in={open6} timeout="auto" unmountOnExit>
                      <List component="div" disablePadding>
                        {TeamLead.Paper.map((text2) => (
                          <ListItemButton
                            key={text2.name}
                            sx={getButtonStyle(text2.route)}
                            onClick={() => navigat(text2.route)}
                          >
                            <ListItemIcon></ListItemIcon>
                            <ListItemText primary={text2.name} />
                          </ListItemButton>
                        ))}
                      </List>
                    </Collapse>
                  </List>
                </Collapse>

                <ListItemButton onClick={handleClick12}>
                  <ListItemIcon>
                    <SummarizeIcon />
                  </ListItemIcon>
                  <ListItemText primary="Reports" />
                  {open12 ? <ExpandLess /> : <ExpandMore />}
                </ListItemButton>
                <Collapse in={open12} timeout="auto" unmountOnExit>
                  <List component="div" disablePadding>
                    {TeamLead.Reports.map((text2) => (
                      <ListItemButton
                        key={text2.name}
                        sx={getButtonStyle(text2.route)}
                        onClick={() => navigat(text2.route)}
                      >
                        <ListItemIcon></ListItemIcon>
                        <ListItemText primary={text2.name} />
                      </ListItemButton>
                    ))}
                  </List>
                </Collapse>
                <ListItemButton
                  sx={getButtonStyle("Courier")}
                  onClick={() => navigat("Courier")}
                >
                  <ListItemIcon>
                    <DynamicFeedIcon />
                  </ListItemIcon>
                  <ListItemText primary="Courier" />
                </ListItemButton>
                <ListItemButton onClick={handleClick13}>
                  <ListItemIcon>
                    <EditNoteIcon />
                  </ListItemIcon>
                  <ListItemText primary="Leave" />
                  {open13 ? <ExpandLess /> : <ExpandMore />}
                </ListItemButton>
                <Collapse in={open13} timeout="auto" unmountOnExit>
                  <List component="div" disablePadding>
                    {TeamLead.Leave.map((text2) => (
                      <ListItemButton
                        key={text2.name}
                        sx={getButtonStyle(text2.route)}
                        onClick={() => navigat(text2.route)}
                      >
                        <ListItemIcon></ListItemIcon>
                        <ListItemText primary={text2.name} />
                      </ListItemButton>
                    ))}
                  </List>
                </Collapse>
              </List>
            </>
          ) : (
            ""
          )}
          {/* Executive */}
          {types.type === "Executive" ? (
            <>
              <List
                sx={{
                  width: "100%",
                  maxWidth: 360,
                  bgcolor: "background.paper",
                }}
                component="nav"
                aria-labelledby="nested-list-subheader"
              >
                <ListItemButton
                  sx={getButtonStyle("/dashboard")}
                  onClick={() => navigat("/dashboard")}
                >
                  <ListItemIcon>
                    <DashboardIcon />
                  </ListItemIcon>
                  <ListItemText primary="Dashboard" />
                </ListItemButton>
                <ListItemButton
                  sx={getButtonStyle("PersonalInformation")}
                  onClick={() => navigat("PersonalInformation")}
                >
                  <ListItemIcon>
                    <DynamicFeedIcon />
                  </ListItemIcon>
                  <ListItemText primary="Personal Information" />
                </ListItemButton>
                <ListItemButton onClick={handleClick1}>
                  <ListItemIcon>
                    <ManageAccountsIcon />
                  </ListItemIcon>
                  <ListItemText primary="Manage" />
                  {open1 ? <ExpandLess /> : <ExpandMore />}
                </ListItemButton>
                <Collapse in={open1} timeout="auto" unmountOnExit>
                  <List component="div" disablePadding>
                    <ListItemButton onClick={handleClick4}>
                      <ListItemIcon>
                        <PersonIcon />
                      </ListItemIcon>
                      <ListItemText primary="University & Courses" />
                      {open4 ? <ExpandLess /> : <ExpandMore />}
                    </ListItemButton>
                    <Collapse in={open4} timeout="auto" unmountOnExit>
                      <List component="div" disablePadding>
                        {Executive.University.map((text2) => (
                          <ListItemButton
                            key={text2.name}
                            sx={getButtonStyle(text2.route)}
                            onClick={() => navigat(text2.route)}
                          >
                            <ListItemIcon></ListItemIcon>
                            <ListItemText primary={text2.name} />
                          </ListItemButton>
                        ))}
                      </List>
                    </Collapse>
                    <ListItemButton onClick={handleClick3}>
                      <ListItemIcon>
                        <SchoolIcon />
                      </ListItemIcon>
                      <ListItemText primary="Lead" />
                      {open3 ? <ExpandLess /> : <ExpandMore />}
                    </ListItemButton>
                    <Collapse in={open3} timeout="auto" unmountOnExit>
                      <List component="div" disablePadding>
                        {Executive.Lead.map((text2) => (
                          <ListItemButton
                            key={text2.name}
                            sx={getButtonStyle(text2.route)}
                            onClick={() => navigat(text2.route)}
                          >
                            <ListItemIcon></ListItemIcon>
                            <ListItemText primary={text2.name} />
                          </ListItemButton>
                        ))}
                      </List>
                    </Collapse>
                    <ListItemButton onClick={handleClick2}>
                      <ListItemIcon>
                        <WorkIcon />
                      </ListItemIcon>
                      <ListItemText primary="Student" />
                      {open2 ? <ExpandLess /> : <ExpandMore />}
                    </ListItemButton>
                    <Collapse in={open2} timeout="auto" unmountOnExit>
                      <List component="div" disablePadding>
                        {Executive.Student.map((text2) => (
                          <ListItemButton
                            sx={getButtonStyle(text2.route)}
                            onClick={() => navigat(text2.route)}
                            key={text2.name}
                          >
                            <ListItemIcon></ListItemIcon>
                            <ListItemText primary={text2.name} />
                          </ListItemButton>
                        ))}
                      </List>
                    </Collapse>

                    <ListItemButton onClick={handleClick5}>
                      <ListItemIcon>
                        <PersonIcon />
                      </ListItemIcon>
                      <ListItemText primary="Results" />
                      {open5 ? <ExpandLess /> : <ExpandMore />}
                    </ListItemButton>
                    <Collapse in={open5} timeout="auto" unmountOnExit>
                      <List component="div" disablePadding>
                        {Executive.Result.map((text2) => (
                          <ListItemButton
                            key={text2.name}
                            sx={getButtonStyle(text2.route)}
                            onClick={() => navigat(text2.route)}
                          >
                            <ListItemIcon></ListItemIcon>
                            <ListItemText primary={text2.name} />
                          </ListItemButton>
                        ))}
                      </List>
                    </Collapse>
                    <ListItemButton onClick={handleClick6}>
                      <ListItemIcon>
                        <GroupIcon />
                      </ListItemIcon>
                      <ListItemText primary="Paper" />
                      {open6 ? <ExpandLess /> : <ExpandMore />}
                    </ListItemButton>
                    <Collapse in={open6} timeout="auto" unmountOnExit>
                      <List component="div" disablePadding>
                        {Executive.Paper.map((text2) => (
                          <ListItemButton
                            key={text2.name}
                            sx={getButtonStyle(text2.route)}
                            onClick={() => navigat(text2.route)}
                          >
                            <ListItemIcon></ListItemIcon>
                            <ListItemText primary={text2.name} />
                          </ListItemButton>
                        ))}
                      </List>
                    </Collapse>
                  </List>
                </Collapse>

                <ListItemButton onClick={handleClick12}>
                  <ListItemIcon>
                    <SummarizeIcon />
                  </ListItemIcon>
                  <ListItemText primary="Reports" />
                  {open12 ? <ExpandLess /> : <ExpandMore />}
                </ListItemButton>
                <Collapse in={open12} timeout="auto" unmountOnExit>
                  <List component="div" disablePadding>
                    {Executive.Reports.map((text2) => (
                      <ListItemButton
                        key={text2.name}
                        sx={getButtonStyle(text2.route)}
                        onClick={() => navigat(text2.route)}
                      >
                        <ListItemIcon></ListItemIcon>
                        <ListItemText primary={text2.name} />
                      </ListItemButton>
                    ))}
                  </List>
                </Collapse>
                <ListItemButton
                  sx={getButtonStyle("Courier")}
                  onClick={() => navigat("Courier")}
                >
                  <ListItemIcon>
                    <DynamicFeedIcon />
                  </ListItemIcon>
                  <ListItemText primary="Courier" />
                </ListItemButton>
                <ListItemButton onClick={handleClick13}>
                  <ListItemIcon>
                    <EditNoteIcon />
                  </ListItemIcon>
                  <ListItemText primary="Leave" />
                  {open13 ? <ExpandLess /> : <ExpandMore />}
                </ListItemButton>
                <Collapse in={open13} timeout="auto" unmountOnExit>
                  <List component="div" disablePadding>
                    {Executive.Leave.map((text2) => (
                      <ListItemButton
                        key={text2.name}
                        sx={getButtonStyle(text2.route)}
                        onClick={() => navigat(text2.route)}
                      >
                        <ListItemIcon></ListItemIcon>
                        <ListItemText primary={text2.name} />
                      </ListItemButton>
                    ))}
                  </List>
                </Collapse>
              </List>
            </>
          ) : (
            ""
          )}
          {/* Student */}
          {types.type === "Student" ? (
            <>
              <List
                sx={{
                  width: "100%",
                  maxWidth: 360,
                  bgcolor: "background.paper",
                }}
                component="nav"
                aria-labelledby="nested-list-subheader"
              >
                <ListItemButton
                  sx={getButtonStyle("/dashboard")}
                  onClick={() => navigat("/dashboard")}
                >
                  <ListItemIcon>
                    <DashboardIcon />
                  </ListItemIcon>
                  <ListItemText primary="Dashboard" />
                </ListItemButton>
                <List component="div" disablePadding>
                  <ListItemButton onClick={handleClick2}>
                    <ListItemIcon>
                      <WorkIcon />
                    </ListItemIcon>
                    <ListItemText primary="My Course" />
                    {open2 ? <ExpandLess /> : <ExpandMore />}
                  </ListItemButton>
                  <Collapse in={open2} timeout="auto" unmountOnExit>
                    <List component="div" disablePadding>
                      {Student.Student.map((text2) => (
                        <ListItemButton
                          sx={getButtonStyle(text2.route)}
                          onClick={() => navigat(text2.route)}
                          key={text2.name}
                        >
                          <ListItemIcon></ListItemIcon>
                          <ListItemText primary={text2.name} />
                        </ListItemButton>
                      ))}
                    </List>
                  </Collapse>

                  <ListItemButton onClick={handleClick14}>
                    <ListItemIcon>
                      <PersonIcon />
                    </ListItemIcon>
                    <ListItemText primary="Account Details" />
                    {open14 ? <ExpandLess /> : <ExpandMore />}
                  </ListItemButton>
                  <Collapse in={open14} timeout="auto" unmountOnExit>
                    <List component="div" disablePadding>
                      {Student.Account.map((text2) => (
                        <ListItemButton
                          key={text2.name}
                          sx={getButtonStyle(text2.route)}
                          onClick={() => navigat(text2.route)}
                        >
                          <ListItemIcon></ListItemIcon>
                          <ListItemText primary={text2.name} />
                        </ListItemButton>
                      ))}
                    </List>
                  </Collapse>
                  <ListItemButton onClick={handleClick5}>
                    <ListItemIcon>
                      <PersonIcon />
                    </ListItemIcon>
                    <ListItemText primary="Results" />
                    {open5 ? <ExpandLess /> : <ExpandMore />}
                  </ListItemButton>
                  <Collapse in={open5} timeout="auto" unmountOnExit>
                    <List component="div" disablePadding>
                      {Student.Result.map((text2) => (
                        <ListItemButton
                          key={text2.name}
                          sx={getButtonStyle(text2.route)}
                          onClick={() => navigat(text2.route)}
                        >
                          <ListItemIcon></ListItemIcon>
                          <ListItemText primary={text2.name} />
                        </ListItemButton>
                      ))}
                    </List>
                  </Collapse>
                  <ListItemButton onClick={handleClick6}>
                    <ListItemIcon>
                      <GroupIcon />
                    </ListItemIcon>
                    <ListItemText primary="Paper" />
                    {open6 ? <ExpandLess /> : <ExpandMore />}
                  </ListItemButton>
                  <Collapse in={open6} timeout="auto" unmountOnExit>
                    <List component="div" disablePadding>
                      {Student.Paper.map((text2) => (
                        <ListItemButton
                          key={text2.name}
                          sx={getButtonStyle(text2.route)}
                          onClick={() => navigat(text2.route)}
                        >
                          <ListItemIcon></ListItemIcon>
                          <ListItemText primary={text2.name} />
                        </ListItemButton>
                      ))}
                    </List>
                  </Collapse>
                </List>

                <ListItemButton onClick={handleClick7}>
                  <ListItemIcon>
                    <SummarizeIcon />
                  </ListItemIcon>
                  <ListItemText primary="Report" />
                  {open7 ? <ExpandLess /> : <ExpandMore />}
                </ListItemButton>
                <Collapse in={open7} timeout="auto" unmountOnExit>
                  <List component="div" disablePadding>
                    {Student.Reports.map((text2) => (
                      <ListItemButton
                        key={text2.name}
                        sx={getButtonStyle(text2.route)}
                        onClick={() => navigat(text2.route)}
                      >
                        <ListItemIcon></ListItemIcon>
                        <ListItemText primary={text2.name} />
                      </ListItemButton>
                    ))}
                  </List>
                </Collapse>

                <ListItemButton onClick={handleClick8}>
                  <ListItemIcon>
                    <SummarizeIcon />
                  </ListItemIcon>
                  <ListItemText primary="Courier" />
                  {open8 ? <ExpandLess /> : <ExpandMore />}
                </ListItemButton>
                <Collapse in={open8} timeout="auto" unmountOnExit>
                  <List component="div" disablePadding>
                    {Student.Courier.map((text2) => (
                      <ListItemButton
                        key={text2.name}
                        sx={getButtonStyle(text2.route)}
                        onClick={() => navigat(text2.route)}
                      >
                        <ListItemIcon></ListItemIcon>
                        <ListItemText primary={text2.name} />
                      </ListItemButton>
                    ))}
                  </List>
                </Collapse>

                <ListItemButton onClick={handleClick9}>
                  <ListItemIcon>
                    <InboxIcon />
                  </ListItemIcon>
                  <ListItemText primary="Reference" />
                  {open9 ? <ExpandLess /> : <ExpandMore />}
                </ListItemButton>
                <Collapse in={open9} timeout="auto" unmountOnExit>
                  <List component="div" disablePadding color="grey">
                    {Student.Reference.map((text2) => (
                      <ListItemButton
                        key={text2.name}
                        sx={getButtonStyle(text2.route)}
                        onClick={() => navigat(text2.route)}
                      >
                        <ListItemIcon></ListItemIcon>
                        <ListItemText primary={text2.name} />
                      </ListItemButton>
                    ))}
                  </List>
                </Collapse>
              </List>
            </>
          ) : (
            ""
          )}
        </Drawer>

        <Main
          open={open}
          sx={{
            backgroundColor: "Lightblue",
            boxSizing: "border-box",
            overflowX: "hidden",
          }}
        >
          <DrawerHeader />
          <Outlet />
        </Main>
      </Box>
    </>
  );
}
