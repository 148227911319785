import React, { useState } from "react";
import DeleteIcon from "@mui/icons-material/Delete";
import useTypescriptValidation from "../../Hooks/useTypescriptValidation";
import CommonSnackBar from "../../Components/Common/CommonSnackbar";
import {
  TextField,
  Button,
  IconButton,
  Typography,
  Grid,
  Stack,
} from "@mui/material";

function FileUpload(props: any) {
  const { eventHandler } = useTypescriptValidation();
  const {
    detail,
    handlerFileChange,
    handlerRemoveFile,
    handlerAddFile,
    setShowSubmit,
    setActiveStep,
    showSubmit,
    setShow,
  } = props;

  const [openSnackBar, setOpenSnackBar] = useState<boolean>(false);
  const [snackMessage, setSnackMessage] = useState<string>("");
  const [errors, setErrors] = useState<{ [key: string]: string }>({});

  const handleOpenSnackBar = () => {
    setOpenSnackBar(true);
    setTimeout(() => {
      setOpenSnackBar(false);
    }, 3000);
  };

  //validation handler for file upload field
  const validationHandler = async (e: any, alterName?: any) => {
    const selectedFile = e.target.files[0];
    const id = alterName;
    if (id) {
      let prom = new Promise((resolve) => {
        if (true) {
          resolve(eventHandler(id, selectedFile));
        }
      });
      prom.then((res: any) =>
        setErrors((preErrors: { [key: string]: string }) => ({
          ...preErrors,
          [e.target.name]: res,
        }))
      );
    }
  };

  const formChecker = (e: any) => {
    e.preventDefault();

    setShowSubmit((showSubmit: any) => ({
      ...showSubmit,
      uploadDocument: true,
    }));

    if (!showSubmit.leadConnected) {
      setShow(false);
      setActiveStep(0);
    } else if (!showSubmit.studentDetail) {
      setShow(false);
      setActiveStep(1);
    } else if (!showSubmit.academicDetail) {
      setShow(false);
      setActiveStep(2);
    } else if (!showSubmit.courseDetail) {
      setShow(false);
      setActiveStep(3);
    } else if (!showSubmit.uploadDocument) {
      setShow(false);
      setActiveStep(4);
    } else {
      setShow(true);
      setSnackMessage("All Correct!");
      handleOpenSnackBar();
    }
  };

  return (
    <>
      <Grid
        container
        spacing={5}
        direction="row"
        display="flex"
        justifyContent="space-evenly"
        alignItems="center"
        component="form"
        encType="multipart/form-data"
        onSubmit={formChecker}
      >
        <Grid item xs={10} md={5}>
          <Typography variant="h6">Upload Documents</Typography>
        </Grid>

        <Grid item xs={10} md={5}>
          <Button variant="contained" onClick={handlerAddFile}>
            Add More Files
          </Button>
        </Grid>

        <Grid item xs={10} md={5}>
          <TextField
            required
            fullWidth
            label="Photo"
            type="file"
            name="photo"
            variant="outlined"
            error={Boolean(errors.photo)}
            helperText={errors.photo}
            inputProps={{ accept: "image/*" }}
            InputLabelProps={{ shrink: true }}
            onChange={(e) => {
              handlerFileChange(e);
              validationHandler(e, "imageSize");
            }}
          />
        </Grid>

        <Grid item xs={10} md={5}>
          <TextField
            required
            fullWidth
            label="Signature"
            type="file"
            name="signature"
            variant="outlined"
            error={Boolean(errors.signature)}
            helperText={errors.signature}
            inputProps={{ accept: "image/*" }}
            InputLabelProps={{ shrink: true }}
            onChange={(e) => {
              handlerFileChange(e);
              validationHandler(e, "imageSize");
            }}
          />
        </Grid>

        {detail?.additionalFiles.map((data: any) => (
          <React.Fragment key={data.id}>
            <Grid item xs={10} md={5}>
              <Stack direction={"row"}>
                <TextField
                  required
                  fullWidth
                  label={`Upload Files`}
                  type="file"
                  name={`additionalFiles${data.id}`}
                  variant="outlined"
                  error={Boolean(errors[`additionalFiles${data.id}`])}
                  helperText={errors[`additionalFiles${data.id}`]}
                  onChange={(e) => {
                    handlerFileChange(e, data.id);
                    validationHandler(e, "pdfImageSize");
                  }}
                  InputLabelProps={{
                    shrink: true,
                  }}
                />
                <IconButton
                  onClick={(e) => {
                    handlerRemoveFile(e, data.id);
                  }}
                >
                  <DeleteIcon color="error" />
                </IconButton>
              </Stack>
            </Grid>
          </React.Fragment>
        ))}

        <Grid
          item
          xs={12}
          sm={12}
          sx={{ display: "flex", justifyContent: "center" }}
        >
          <Stack spacing={2} direction="row">
            <Button
              type="button"
              variant="contained"
              onClick={() => setActiveStep(3)}
            >
              Back
            </Button>
            <Button variant="contained" type="submit">
              Check Form
            </Button>
          </Stack>
          <CommonSnackBar message={snackMessage} open={openSnackBar} />
        </Grid>
      </Grid>
    </>
  );
}

export default FileUpload;
