import React from "react";
import { Box, Container, Grid, Typography } from "@mui/material";

export default function Refund() {
  const refundPolicy = [
    "Once an applicant has been accepted, there is no refund.",
    "Only those candidates who have registered for the course are eligible for a refund if they do not meet the school's eligibility requirements.",
    "Before the start of the program, refund requests will be considered.",
    "After the start of the course, after the student stops going, leaves the course before it is finished, or is suspended by the university due to non-attendance or misconduct, fees are not refundable.",
    "Any request for a refund made after the start of the programme will not be considered.",
    "Student course fee is neither transferrable nor transferable to other student and other course.",
    "It will take at least 45 days to refund the course fees.",
    "All course fees will be refunded after deducting at least 10%",
  ];

  return (
    <Container sx={{ mt: "80px" }}>
      <Box sx={{ pb: 4 }}>
        <Grid container spacing={2}>
          <Grid item xs={12}>
            <Typography
              variant="h4"
              sx={{ justifyContent: "center", display: "flex", p: 3, textTransform: 'uppercase', fontWeight: 'bold'}}
            >
              Refund and Cancellation Policy
            </Typography>
            {refundPolicy.map((policy, index) => (
              <div
                key={index}
                style={{ display: "flex", alignItems: "flex-start" }}
              >
                <Typography
                  paragraph
                  sx={{
                    textAlign: "justify",
                    letterSpacing: "0px",
                    width: "30px",
                    flexShrink: 0,
                  }}
                >
                  {`${index + 1}.`}
                </Typography>
                <Typography
                  paragraph
                  sx={{
                    textAlign: "justify",
                    letterSpacing: "0px",
                    marginLeft: "10px",
                  }}
                >
                  {policy}
                </Typography>
              </div>
            ))}
          </Grid>
        </Grid>
      </Box>
    </Container>
  );
}
