import {
  FormControl,
  InputLabel,
  LinearProgress,
  MenuItem,
  Select,
} from "@mui/material";
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import Container from "@mui/material/Container";
import Grid from "@mui/material/Grid";
import TextField from "@mui/material/TextField";
import Typography from "@mui/material/Typography";
import { useState } from "react";
import "../../src/App.css";
import CommonSnackBar from "../Components/Common/CommonSnackbar";
import useValidation from "../Hooks/useTypescriptValidation";
import { postRequestMethod } from "../api/api";
import { CHATBOT_POST } from "../api/server";

interface ChatData {
  programsMode: String | null;
  programs: String | null;
  course: String | null;
  specialization: String | null;
  qualification: String | null;
  university: String | null;
  passYearPercent: String | null;
  fullName: String | null;
  mobileNumber: String | null;
  email: String | null;
}

export default function ChatBot(props: any) {
  let { eventHandler } = useValidation();

  // *******************************************For Intigration*******************************
  const [sendChat, setSendChat] = useState<ChatData>({
    programsMode: "",
    programs: "",
    course: "",
    specialization: "",
    qualification: "",
    university: "",
    passYearPercent: "",
    fullName: "",
    mobileNumber: "",
    email: "",
  });

  const [typing, setTyping] = useState({ emi: "", hi: "", yes: "" });
  const [openSnackBar, setOpenSnackBar] = useState(false);
  const [snackMessage, setSnackMessage] = useState<string>("");
  const [isLoading, setIsLoading] = useState(false);

  const [errors, setErrors] = useState({
    programsMode: "",
    programs: "",
    course: "",
    specialization: "",
    qualification: "",
    university: "",
    passYearPercent: "",
    fullName: "",
    mobileNumber: "",
    email: "",
  });

  const stateDataHandler = (e: any) => {
    setSendChat((preData: ChatData) => ({
      ...preData,
      [e.target.name]: e.target.value,
    }));
  };

  const stateClearHandler = () => {
    setSendChat((preData: ChatData) => ({
      ...preData,
      specialization: "",
      qualification: "",
      university: "",
      passYearPercent: "",
      fullName: "",
      mobileNumber: "",
      email: "",
    }));
  };

  const validationHandler = async (e: any, alterName?: any) => {
    const val = e.target.value;
    const id = alterName;
    let prom = new Promise((resolve) => {
      if (true) {
        resolve(eventHandler(id, val));
      }
    });
    prom.then((res) => setErrors({ ...errors, [e.target.name]: res }));
  };

  const validator = (errors: { [key: string]: string }) => {
    for (let field in errors) {
      if (errors[field] !== "") {
        return false;
      }
    }
    return true;
  };

  const handleSubmit = async (event: any) => {
    event.preventDefault();
    setIsLoading(true);
    if (validator(errors)) {
      await postRequestMethod(CHATBOT_POST, "", sendChat)
        .then((resp: any) => {
          if (resp) {
            setIsLoading(false);
            setSnackMessage(resp.data);
            handleOpenSnackBar();
            formClear();
          } else {
            setIsLoading(false);
            setSnackMessage("Something went wrong");
            handleOpenSnackBar();
          }
        })
        .catch((error) => {
          console.error("Error fetching data:", error);
        });
    }
  };

  const handleOpenSnackBar = () => {
    setOpenSnackBar(true);
    setTimeout(() => {
      setOpenSnackBar(false);
    }, 5000);
  };

  const formClear = () => {
    setSendChat({
      programsMode: "",
      programs: "",
      course: "",
      specialization: "",
      qualification: "",
      university: "",
      passYearPercent: "",
      fullName: "",
      mobileNumber: "",
      email: "",
    });
    setErrors({
      programsMode: "",
      programs: "",
      course: "",
      specialization: "",
      qualification: "",
      university: "",
      passYearPercent: "",
      fullName: "",
      mobileNumber: "",
      email: "",
    });
  };

  return (
    <Container>
      {isLoading && <LinearProgress />}
      <Box sx={{ p: "10px" }} component="form" onSubmit={handleSubmit}>
        <Typography
          variant="h6"
          align="center"
          sx={{
            alignItems: "center",
            color: "#0288d1",
            display: "block !important",
          }}
          className="typing-effect"
        >
          Welcome to Prerna Academy !
        </Typography>
        <Typography
          variant="h6"
          align="center"
          sx={{
            alignItems: "center",
            color: "#0288d1",
            display: "block !important",
          }}
          className="typing-effect"
        >
          How may assist you Today?
        </Typography>

        <Grid item xs={12}>
          <Typography variant="subtitle1" className="typing-effect">
            Q.Would you be interested in below programs Mode?
          </Typography>
        </Grid>

        <Grid item xs={12} sx={{ marginBottom: "20px" }}>
          <FormControl fullWidth>
            <InputLabel id="demo-simple-select-label">Programs Mode</InputLabel>
            <Select
              labelId="demo-simple-select-label"
              name="programsMode"
              id="demo-simple-select"
              label="Programs Mode"
              value={sendChat?.programsMode}
              type="text"
              required
              onChange={stateDataHandler}
            >
              <MenuItem value={"Regular"}>Regular</MenuItem>
              <MenuItem value={"Private"}>Private</MenuItem>
              <MenuItem value={"Open & Distance"}>Open & Distance</MenuItem>
              <MenuItem value={"Online"}>Online</MenuItem>
              <MenuItem value={"F-Tel"}>F-Tel</MenuItem>
            </Select>
          </FormControl>
        </Grid>
        {sendChat?.programsMode && (
          <>
            <Grid item xs={12}>
              <Typography variant="subtitle1" className="typing-effect">
                Q.Would you be interested in below programs?
              </Typography>
            </Grid>

            <Grid item xs={12} sx={{ marginBottom: "20px" }}>
              <FormControl fullWidth>
                <InputLabel id="demo-simple-select-label">Programs</InputLabel>
                <Select
                  labelId="demo-simple-select-label"
                  name="programs"
                  id="demo-simple-select"
                  label="Programs"
                  value={sendChat?.programs}
                  type="text"
                  required
                  onChange={(e: any) => {
                    stateDataHandler(e);
                    stateClearHandler();
                  }}
                >
                  <MenuItem value={"PG"}>PG</MenuItem>
                  <MenuItem value={"UG"}>UG</MenuItem>
                  <MenuItem value={"DIPLOMA"}>DIPLOMA</MenuItem>
                  <MenuItem value={"CERTIFICATE"}>CERTIFICATE</MenuItem>
                  <MenuItem value={"Any Other"}>Any Other</MenuItem>
                </Select>
              </FormControl>
            </Grid>
            {sendChat?.programs && (
              <>
                {sendChat?.programs === "PG" ? (
                  <>
                    <Grid item xs={12}>
                      <Typography variant="subtitle1" className="typing-effect">
                        Select:PG
                      </Typography>
                    </Grid>

                    <Grid item xs={12} sx={{ marginBottom: "20px" }}>
                      <FormControl fullWidth>
                        <InputLabel id="demo-simple-select-label">
                          Course PG
                        </InputLabel>

                        <Select
                          labelId="demo-simple-select-label"
                          name="course"
                          id="demo-simple-select"
                          label="Course PG"
                          value={sendChat?.course}
                          type="text"
                          required
                          onChange={stateDataHandler}
                        >
                          <MenuItem value={"MBA"}>MBA</MenuItem>
                          <MenuItem value={"MA"}>MA</MenuItem>
                          <MenuItem value={"MCOM"}>MCOM</MenuItem>
                          <MenuItem value={"MCA"}>MCA</MenuItem>
                          <MenuItem value={"MSC"}>MSC</MenuItem>
                          <MenuItem value={"MLIB"}>MLIB</MenuItem>
                          <MenuItem value={"MSW"}>MSW</MenuItem>
                          <MenuItem value={"M.TECH"}>M.TECH</MenuItem>
                          <MenuItem value={"M.PHARMA"}>M.PHARMA</MenuItem>
                          <MenuItem value={"Any Other"}>Any Other</MenuItem>
                        </Select>
                      </FormControl>
                    </Grid>
                  </>
                ) : sendChat?.programs === "UG" ? (
                  <>
                    <Grid item xs={12}>
                      <Typography variant="subtitle1" className="typing-effect">
                        Select:UG
                      </Typography>
                    </Grid>

                    <Grid item xs={12} sx={{ marginBottom: "20px" }}>
                      <FormControl fullWidth>
                        <InputLabel id="demo-simple-select-label">
                          Course UG
                        </InputLabel>

                        <Select
                          labelId="demo-simple-select-label"
                          name="course"
                          id="demo-simple-select"
                          label="Course UG"
                          value={sendChat?.course}
                          type="text"
                          required
                          onChange={stateDataHandler}
                        >
                          <MenuItem value={"BBA"}>BBA</MenuItem>
                          <MenuItem value={"BA"}>BA</MenuItem>
                          <MenuItem value={"BCOM"}>BCOM</MenuItem>
                          <MenuItem value={"BCA"}>BCA</MenuItem>
                          <MenuItem value={"BSC"}>BSC</MenuItem>
                          <MenuItem value={"BLIB"}>BLIB</MenuItem>
                          <MenuItem value={"BSW"}>BSW</MenuItem>
                          <MenuItem value={"B.TECH"}>B.TECH</MenuItem>
                          <MenuItem value={"B.PHARMA"}>B.PHARMA</MenuItem>
                          <MenuItem value={"Any Other"}>Any Other</MenuItem>
                        </Select>
                      </FormControl>
                    </Grid>
                  </>
                ) : sendChat?.programs === "DIPLOMA" ? (
                  <>
                    <Grid item xs={12}>
                      <Typography variant="subtitle1" className="typing-effect">
                        Select:DIPLOMA
                      </Typography>
                    </Grid>

                    <Grid item xs={12} sx={{ marginBottom: "20px" }}>
                      <FormControl fullWidth>
                        <InputLabel id="demo-simple-select-label">
                          Course DIPLOMA
                        </InputLabel>

                        <Select
                          labelId="demo-simple-select-label"
                          name="course"
                          id="demo-simple-select"
                          label="Course DIPLOMA"
                          value={sendChat?.course}
                          type="text"
                          required
                          onChange={stateDataHandler}
                        >
                          <MenuItem value={"POLYTECHNIC"}>POLYTECHNIC</MenuItem>
                          <MenuItem value={"D.PHARMA"}>D.PHARMA</MenuItem>
                          <MenuItem value={"DMLT"}>DMLT</MenuItem>
                          <MenuItem value={"Any Other"}>Any Other</MenuItem>
                        </Select>
                      </FormControl>
                    </Grid>
                  </>
                ) : sendChat?.programs === "CERTIFICATE" ? (
                  <>
                    <Grid item xs={12}>
                      <Typography variant="subtitle1" className="typing-effect">
                        Select:CERTIFICATE
                      </Typography>
                    </Grid>

                    <Grid item xs={12} sx={{ marginBottom: "20px" }}>
                      <FormControl fullWidth>
                        <InputLabel id="demo-simple-select-label">
                          Course CERTIFICATE
                        </InputLabel>

                        <Select
                          labelId="demo-simple-select-label"
                          name="course"
                          id="demo-simple-select"
                          label="Course CERTIFICATE"
                          value={sendChat?.course}
                          type="text"
                          required
                          onChange={stateDataHandler}
                        >
                          <MenuItem value={"TRADE CERTIFICATE"}>
                            TRADE CERTIFICATE
                          </MenuItem>
                          <MenuItem value={"INDUSTRIAL TRADE CERTIFICATE"}>
                            INDUSTRIAL TRADE CERTIFICATE
                          </MenuItem>
                          <MenuItem value={"Any Other"}>Any Other</MenuItem>
                        </Select>
                      </FormControl>
                    </Grid>
                  </>
                ) : null}
                {(sendChat?.course || sendChat?.programs === "Any Other") && (
                  <>
                    <Grid item xs={12}>
                      <Typography variant="subtitle1" className="typing-effect">
                        Q.May I know which specialization are you looking for?
                      </Typography>
                    </Grid>

                    <Grid item xs={12} sx={{ marginBottom: "20px" }}>
                      <FormControl fullWidth>
                        <InputLabel id="demo-simple-select-label">
                          Specialization
                        </InputLabel>

                        <Select
                          labelId="demo-simple-select-label"
                          name="specialization"
                          id="demo-simple-select"
                          label="Specialization"
                          value={sendChat?.specialization}
                          type="text"
                          required
                          onChange={stateDataHandler}
                        >
                          <MenuItem value={"HR"}>HR</MenuItem>
                          <MenuItem value={"Marketing"}>Marketing</MenuItem>
                          <MenuItem value={"Finance"}>Finance</MenuItem>
                          <MenuItem value={"IT"}>IT</MenuItem>
                          <MenuItem value={"Healthcare"}>Healthcare</MenuItem>
                          <MenuItem value={"Project Leadership"}>
                            Project Leadership
                          </MenuItem>
                          <MenuItem value={"Operations and Production"}>
                            Operations and Production
                          </MenuItem>
                          <MenuItem value={"Supply Chain Management"}>
                            Supply Chain Management
                          </MenuItem>
                        </Select>
                      </FormControl>
                    </Grid>
                    {sendChat?.specialization && (
                      <>
                        <Grid item xs={12}>
                          <Typography
                            variant="subtitle1"
                            className="typing-effect"
                          >
                            Q.To check your eligibility, may I know the highest
                            education qualification?
                          </Typography>
                        </Grid>

                        <Grid item xs={12} sx={{ marginBottom: "20px" }}>
                          <FormControl fullWidth>
                            <TextField
                              sx={{ background: "white" }}
                              required
                              value={sendChat?.qualification}
                              id="filled-password-input"
                              label="Qualification"
                              type="text"
                              name="qualification"
                              error={Boolean(errors.qualification)}
                              helperText={errors.qualification}
                              onChange={stateDataHandler}
                              onBlur={(e: any) =>
                                validationHandler(e, "alphanumeric")
                              }
                            />
                          </FormControl>
                        </Grid>
                        {sendChat?.qualification && (
                          <>
                            <Grid item xs={12}>
                              <Typography
                                variant="subtitle1"
                                className="typing-effect"
                              >
                                Q.May I know from which university you have
                                completed your graduation?
                              </Typography>
                            </Grid>

                            <Grid item xs={12} sx={{ marginBottom: "20px" }}>
                              <FormControl fullWidth>
                                <TextField
                                  value={sendChat?.university}
                                  id="filled-password-input"
                                  label="University"
                                  required
                                  sx={{ background: "white" }}
                                  type="text"
                                  name="university"
                                  error={Boolean(errors.university)}
                                  helperText={errors.university}
                                  onChange={stateDataHandler}
                                  onBlur={(e: any) =>
                                    validationHandler(e, "alphabetsAndSpace")
                                  }
                                />
                              </FormControl>
                            </Grid>
                            {sendChat?.university && (
                              <>
                                <Grid item xs={12}>
                                  <Typography
                                    variant="subtitle1"
                                    className="typing-effect"
                                  >
                                    Q.Passing year and percentage?
                                  </Typography>
                                </Grid>

                                <Grid
                                  item
                                  xs={12}
                                  sx={{ marginBottom: "20px" }}
                                >
                                  <FormControl fullWidth>
                                    <TextField
                                      value={sendChat?.passYearPercent}
                                      id="filled-password-input"
                                      label="Passing year & Percentage"
                                      type="text"
                                      required
                                      sx={{ background: "white" }}
                                      name="passYearPercent"
                                      error={Boolean(errors.passYearPercent)}
                                      helperText={errors.passYearPercent}
                                      onChange={stateDataHandler}
                                      onBlur={(e: any) =>
                                        validationHandler(e, "address")
                                      }
                                    />
                                  </FormControl>
                                </Grid>
                                {sendChat?.passYearPercent && (
                                  <>
                                    <Grid item xs={12}>
                                      <Typography
                                        variant="subtitle1"
                                        className="typing-effect"
                                      >
                                        Q.NO COST EMI AVAILABLE.To know more
                                        type "EMI"?
                                      </Typography>
                                    </Grid>

                                    <Grid
                                      item
                                      xs={12}
                                      sx={{ marginBottom: "20px" }}
                                    >
                                      <FormControl fullWidth>
                                        <TextField
                                          value={typing.emi}
                                          id="filled-password-input"
                                          label="Type Input"
                                          type="text"
                                          name="typingemi"
                                          required
                                          sx={{ background: "white" }}
                                          onChange={(e: any) =>
                                            setTyping({
                                              ...typing,
                                              emi: e.target.value,
                                            })
                                          }
                                        />
                                      </FormControl>
                                    </Grid>
                                    {typing.emi === "EMI" && (
                                      <>
                                        <Grid item xs={12}>
                                          <Typography
                                            variant="subtitle1"
                                            className="typing-effect"
                                          >
                                            1.Fees Details
                                          </Typography>
                                          <Typography
                                            variant="subtitle1"
                                            className="typing-effect"
                                          >
                                            2.Anytime & Anywhere Learning
                                          </Typography>
                                          <Typography
                                            variant="subtitle1"
                                            className="typing-effect"
                                          >
                                            3.Dedicated Student Support
                                          </Typography>
                                          <Typography
                                            variant="subtitle1"
                                            className="typing-effect"
                                          >
                                            4.Flexible Exam Mode
                                          </Typography>
                                          <Typography
                                            variant="subtitle1"
                                            className="typing-effect"
                                          >
                                            5.App Base Learning
                                          </Typography>
                                          <Typography
                                            variant="subtitle1"
                                            className="typing-effect"
                                          >
                                            6.Live & Interactive Lectures with
                                            Recorded Videos
                                          </Typography>
                                        </Grid>
                                        <Grid item xs={12}>
                                          <Typography
                                            variant="subtitle1"
                                            className="typing-effect"
                                          >
                                            Want to know more just ping "Hi"
                                          </Typography>
                                        </Grid>
                                        <Grid
                                          item
                                          xs={12}
                                          sx={{ marginBottom: "20px" }}
                                        >
                                          <FormControl fullWidth>
                                            <TextField
                                              value={typing.hi}
                                              id="filled-password-input"
                                              label="Type Input"
                                              type="text"
                                              name="typinghi"
                                              onChange={(e: any) =>
                                                setTyping({
                                                  ...typing,
                                                  hi: e.target.value,
                                                })
                                              }
                                              required
                                              sx={{ background: "white" }}
                                            />
                                          </FormControl>
                                        </Grid>
                                        {typing.hi === "Hi" && (
                                          <>
                                            <Grid item xs={12}>
                                              <Typography
                                                variant="subtitle1"
                                                className="typing-effect"
                                              >
                                                Want to know more just ping
                                                "Yes"
                                              </Typography>
                                            </Grid>
                                            <Grid
                                              item
                                              xs={12}
                                              sx={{ marginBottom: "20px" }}
                                            >
                                              <FormControl fullWidth>
                                                <TextField
                                                  value={typing.yes}
                                                  id="filled-password-input"
                                                  label="Type Input"
                                                  type="text"
                                                  name="typingyes"
                                                  onChange={(e: any) =>
                                                    setTyping({
                                                      ...typing,
                                                      yes: e.target.value,
                                                    })
                                                  }
                                                  required
                                                  sx={{ background: "white" }}
                                                />
                                              </FormControl>
                                            </Grid>
                                            {typing.yes === "Yes" && (
                                              <>
                                                <Grid item xs={12}>
                                                  <Typography
                                                    variant="subtitle1"
                                                    className="typing-effect"
                                                  >
                                                    Admissions for the current
                                                    batch closing soon.Check
                                                    your eligibility now"
                                                  </Typography>
                                                </Grid>
                                                <Grid item xs={12}>
                                                  <Typography
                                                    variant="subtitle1"
                                                    className="typing-effect"
                                                  >
                                                    Just help me with the below
                                                    information
                                                  </Typography>
                                                </Grid>
                                                <Grid item xs={12}>
                                                  <FormControl fullWidth>
                                                    <TextField
                                                      value={sendChat?.fullName}
                                                      id="filled-password-input"
                                                      label="Full Name"
                                                      type="text"
                                                      required
                                                      sx={{
                                                        background: "white",
                                                      }}
                                                      name="fullName"
                                                      error={Boolean(
                                                        errors.fullName
                                                      )}
                                                      helperText={
                                                        errors.fullName
                                                      }
                                                      onChange={
                                                        stateDataHandler
                                                      }
                                                      onBlur={(e: any) =>
                                                        validationHandler(
                                                          e,
                                                          "alphabetsAndSpace"
                                                        )
                                                      }
                                                    />
                                                  </FormControl>
                                                </Grid>

                                                <Grid item xs={12}>
                                                  <FormControl fullWidth>
                                                    <TextField
                                                      id="outlined-Contact-flexible"
                                                      label="Mobile Number"
                                                      name="mobileNumber"
                                                      type="number"
                                                      sx={{
                                                        my: 2,
                                                        background: "white",
                                                      }}
                                                      required
                                                      value={
                                                        props.detail
                                                          ?.mobileNumber
                                                      }
                                                      error={Boolean(
                                                        errors.mobileNumber
                                                      )}
                                                      helperText={
                                                        errors.mobileNumber
                                                      }
                                                      onChange={
                                                        stateDataHandler
                                                      }
                                                      onBlur={(e: any) =>
                                                        validationHandler(
                                                          e,
                                                          "mobile"
                                                        )
                                                      }
                                                    />
                                                  </FormControl>
                                                </Grid>
                                                <Grid item xs={12}>
                                                  <FormControl fullWidth>
                                                    <TextField
                                                      required
                                                      id="outlined-Email-flexible"
                                                      label="Email"
                                                      type="email"
                                                      name="email"
                                                      sx={{
                                                        background: "white",
                                                      }}
                                                      value={sendChat?.email}
                                                      error={Boolean(
                                                        errors.email
                                                      )}
                                                      helperText={errors.email}
                                                      onChange={
                                                        stateDataHandler
                                                      }
                                                      onBlur={(e: any) =>
                                                        validationHandler(
                                                          e,
                                                          "email"
                                                        )
                                                      }
                                                    />
                                                  </FormControl>
                                                </Grid>
                                                <Grid item xs={12}>
                                                  <Button
                                                    type="submit"
                                                    variant="contained"
                                                    sx={{ mt: 3, mb: 2 }}
                                                  >
                                                    Submit
                                                  </Button>
                                                </Grid>
                                              </>
                                            )}
                                          </>
                                        )}
                                      </>
                                    )}
                                  </>
                                )}
                              </>
                            )}
                          </>
                        )}
                      </>
                    )}
                  </>
                )}
              </>
            )}
          </>
        )}
      </Box>
      <CommonSnackBar message={snackMessage} open={openSnackBar} />
    </Container>
  );
}
