import { TableContainer } from "@material-ui/core";
import {
  CheckCircle as ActiveIcon,
  Delete as DeleteIcon,
  Edit as EditIcon,
  Cancel as InactiveIcon,
} from "@mui/icons-material";
import SchoolIcon from "@mui/icons-material/School";
import SearchIcon from "@mui/icons-material/Search";
import {
  Alert,
  Button,
  Container,
  FormControl,
  Grid,
  IconButton,
  InputAdornment,
  Paper,
  Snackbar,
  Stack,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TablePagination,
  TableRow,
  TextField,
  Tooltip,
  Typography,
} from "@mui/material";
import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import NoDataFound from "../../Components/Common/EmptyDataMessage";
import "../../Components/Home/Home.scss";
import { deleteRequest, getRequest } from "../../api/api";
import { DELETE_UNIVERSITY, GET_UNIVERSITY } from "../../api/server";

export default function ManageUniversities() {
  const navigate = useNavigate();

  const [universities, setUniversities] = useState<any[]>([]);
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = React.useState(5);
  const [searchApiData, setSearchApiData] = useState<any[]>([]);
  const [filterVal, setFilterVal] = useState<string>("");
  const [openSnackbar, setOpenSnackbar] = useState(false);

  const handleFilter = (e: any) => {
    if (e.target.value === "") {
      setUniversities(searchApiData);
    } else {
      const filterResult: any = searchApiData.filter((item: any) => {
        const searchValue = e.target.value.toLowerCase();
        return Object.values(item).some((value) => {
          if (typeof value === "string" || typeof value === "number") {
            const itemValue = String(value).toLowerCase();
            return itemValue.includes(searchValue);
          }
          return false;
        });
      });
      setUniversities(filterResult);
    }
    setFilterVal(e.target.value);
  };

  function handleDeleteUniversity(id: any, universityName: any) {
    const confirmed = window.confirm(
      `Are you sure you want to delete "${universityName}" University data ? `
    );
    if (confirmed) {
      deleteRequest(DELETE_UNIVERSITY, id, "")
        .then(() => {
          setUniversities((prevUniversities) =>
            prevUniversities.filter((university) => university.uId !== id)
          );
        })
        .catch((error) => {
          console.error(error);
        });
      setOpenSnackbar(true);
    }
  }

  const handleChangePage = (event: any, newPage: any) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event: any) => {
    setRowsPerPage(+event.target.value);
    setPage(0);
  };

  useEffect(() => {
    async function fetchUniversities() {
      const response = await getRequest(GET_UNIVERSITY, "");
      if (response) {
        setUniversities(response.data);
        setSearchApiData(response.data);
      }
    }
    fetchUniversities();
    return () => {
      setUniversities([]);
      setSearchApiData([]);
    };
  }, []);

  return (
    <>
      {universities.length === 0 ? (
        <NoDataFound />
      ) : (
        <>
          <Container>
            <Paper elevation={20}>
              <Grid
                container
                sx={{
                  p: 2,
                  background: "#0288d1",
                  color: "white",
                }}
              >
                <Grid item xs={4}>
                  <Typography variant="h5">Manage University</Typography>
                </Grid>
                <Grid
                  item
                  xs={4}
                  sx={{ display: "flex", justifyContent: "flex-end" }}
                >
                  <FormControl>
                    <Button
                      className="addUniversity"
                      sx={{ background: "white", p: 1, mx: 1 }}
                      variant="outlined"
                      endIcon={<SchoolIcon />}
                      onClick={() => {
                        navigate("/dashboard/AddUniversity");
                      }}
                    >
                      Add University
                    </Button>
                  </FormControl>
                </Grid>

                <Grid
                  item
                  xs={4}
                  sx={{ display: "flex", justifyContent: "flex-end" }}
                >
                  <FormControl>
                    <TextField
                      label="Search "
                      size="small"
                      value={filterVal}
                      onInput={(e: any) => handleFilter(e)}
                      sx={{ mx: 3, background: "white" }}
                      InputProps={{
                        endAdornment: (
                          <InputAdornment position="end">
                            <IconButton edge="end">
                              <SearchIcon />
                            </IconButton>
                          </InputAdornment>
                        ),
                      }}
                    />
                  </FormControl>
                </Grid>
              </Grid>

              <Grid sx={{ position: "relative" }}>
                <Snackbar
                  open={openSnackbar}
                  autoHideDuration={3000}
                  onClose={() => setOpenSnackbar(false)}
                  anchorOrigin={{ vertical: "top", horizontal: "right" }}
                >
                  <Alert
                    onClose={() => setOpenSnackbar(false)}
                    severity="success"
                  >
                    University data deleted successfully!
                  </Alert>
                </Snackbar>

                <TableContainer className="scrollBarCss">
                  <Table>
                    <TableHead>
                      <TableRow>
                        <TableCell
                          align="center"
                          sx={{
                            textAlign: "center",
                            border: "1px solid #ddd",
                            fontSize: "15px",
                          }}
                        >
                          S.No
                        </TableCell>
                        <TableCell
                          align="center"
                          sx={{
                            textAlign: "center",
                            border: "1px solid #ddd",
                            fontSize: "15px",
                          }}
                        >
                          University Name
                        </TableCell>
                        <TableCell
                          align="center"
                          sx={{
                            textAlign: "center",
                            border: "1px solid #ddd",
                            fontSize: "15px",
                          }}
                        >
                          Created Time
                        </TableCell>
                        <TableCell
                          align="center"
                          sx={{
                            textAlign: "center",
                            border: "1px solid #ddd",
                            fontSize: "15px",
                          }}
                        >
                          Last Updated Time
                        </TableCell>
                        <TableCell
                          align="center"
                          sx={{
                            textAlign: "center",
                            border: "1px solid #ddd",
                            fontSize: "15px",
                          }}
                        >
                          University Status
                        </TableCell>
                        <TableCell
                          align="center"
                          sx={{
                            textAlign: "center",
                            border: "1px solid #ddd",
                            fontSize: "15px",
                          }}
                        >
                          Actions
                        </TableCell>
                      </TableRow>
                    </TableHead>

                    <TableBody>
                      {universities
                        .slice(
                          page * rowsPerPage,
                          page * rowsPerPage + rowsPerPage
                        )
                        .map((university, index) => (
                          <TableRow
                            key={index}
                            sx={{ height: "5px", padding: "0px 16px" }}
                          >
                            <TableCell
                              align="center"
                              sx={{
                                textAlign: "center",
                                borderRight: "1px solid #ddd",
                              }}
                            >
                              {page * rowsPerPage + index + 1}
                            </TableCell>
                            <TableCell
                              align="center"
                              sx={{
                                textAlign: "center",
                                borderRight: "1px solid #ddd",
                              }}
                            >
                              {university.universityName}
                            </TableCell>

                            <TableCell
                              sx={{
                                textAlign: "center",
                                borderRight: "1px solid #ddd",
                              }}
                            >
                              {university.createdAt}
                            </TableCell>
                            <TableCell
                              sx={{
                                textAlign: "center",
                                borderRight: "1px solid #ddd",
                              }}
                            ></TableCell>
                            <TableCell
                              align="center"
                              sx={{
                                textAlign: "center",
                                borderRight: "1px solid #ddd",
                              }}
                            >
                              {university.universityStatus === "Active" ? (
                                <ActiveIcon sx={{ color: "green" }} />
                              ) : (
                                <InactiveIcon sx={{ color: "red" }} />
                              )}
                            </TableCell>
                            <TableCell
                              align="center"
                              sx={{
                                textAlign: "center",
                                borderRight: "1px solid #ddd",
                              }}
                            >
                              <div style={{ display: "flex" }}>
                                <Tooltip title="Edit University" arrow>
                                  <IconButton
                                    component={"span"}
                                    sx={{ m: 0 }}
                                    aria-label="edit"
                                    color="primary"
                                    onClick={() => {
                                      navigate(
                                        `/dashboard/EditUniversity/${university._id}`,
                                        {
                                          state: university,
                                        }
                                      );
                                    }}
                                  >
                                    <EditIcon />
                                  </IconButton>
                                </Tooltip>
                                <Tooltip title="Delete University" arrow>
                                  <IconButton
                                    sx={{ m: 0 }}
                                    aria-label="delete"
                                    color="error"
                                    onClick={() =>
                                      handleDeleteUniversity(
                                        university._id,
                                        university.universityName
                                      )
                                    }
                                  >
                                    <DeleteIcon />
                                  </IconButton>
                                </Tooltip>
                              </div>
                            </TableCell>
                          </TableRow>
                        ))}
                    </TableBody>
                  </Table>
                </TableContainer>
              </Grid>
              <Stack
                direction="row"
                justifyContent="center"
                alignItems="center"
                m={2}
              >
                <Grid sx={{ mt: 2 }}>
                  <TablePagination
                    rowsPerPageOptions={[5, 10, 20, 50]}
                    component="div"
                    count={universities.length}
                    rowsPerPage={rowsPerPage}
                    page={page}
                    onPageChange={handleChangePage}
                    onRowsPerPageChange={handleChangeRowsPerPage}
                  ></TablePagination>
                </Grid>
              </Stack>
            </Paper>
          </Container>
        </>
      )}
    </>
  );
}
