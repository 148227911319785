import CheckIcon from "@mui/icons-material/Check";
import PaidTwoToneIcon from "@mui/icons-material/PaidTwoTone";
import {
  Box,
  Button,
  Grid,
  Typography,
  Container,
  ImageList,
  ImageListItem,
  Divider,
} from "@mui/material";
import FolderOffTwoToneIcon from "@mui/icons-material/FolderOffTwoTone";
import DesignServicesTwoToneIcon from "@mui/icons-material/DesignServicesTwoTone";
import PeopleIcon from "@mui/icons-material/People";
import { styled } from "@mui/material/styles";
import { useNavigate } from "react-router-dom";

const ParentContainer = styled(Grid)(({}) => ({
  backgroundImage: `url(https://ik.imagekit.io/nwssoft/Explore_degree/blue-abstraction-background-with-transparency-lines-vector_162862-262.avif?updatedAt=1685436305096)`,
  padding: "50px",
  backgroundSize: "cover",
  backgroundRepeat: "no-repeat",
  backgroundAttachment: "fixed",
  zIndex: 0,
}));

function Services() {
  const navigate = useNavigate();
  return (
    <ParentContainer
      sx={{
        marginTop: { xs: "50px", sm: "10px", lg: "10px" },
        paddingTop: {
          sx: "10%",
          xs: "15%",
          lg: "7%",
          xl: "8",
          md: "10",
        },
      }}
    >
      <Container>
        <Box>
          <Grid container>
            <Grid container>
              <Grid
                container
                item
                xs={12}
                sm={12}
                md={12}
                alignItems="center"
                justifyContent="center"
              >
                <Typography
                  variant="h4"
                  sx={{
                    textAlign: "start",
                    fontWeight: "Bold",
                    color: "#191970",
                  }}
                >
                  Services
                </Typography>
              </Grid>
              {/* image1 */}
              <Grid
                container
                item
                sx={{ display: "flex" }}
                alignItems="center"
                xs={12}
                spacing={5}
              >
                <Grid item xs={12} sm={12} md={5}>
                  <ImageList sx={{ width: "100%", height: "100%" }} cols={1}>
                    <ImageListItem>
                      <img
                        src="https://ik.imagekit.io/nwssoft/prernaacademy_project/Profile_auditing.jpg?updatedAt=1711973079568"
                        loading="lazy"
                        alt="Image 1"
                      />
                    </ImageListItem>
                  </ImageList>
                </Grid>

                <Grid item xs={12} sm={12} md={7} justifyItems="center">
                  <Typography
                    variant="h5"
                    sx={{ color: "#191970", fontWeight: "500" }}
                  >
                    Profile auditing and comprehensive remarks
                  </Typography>
                  <Divider
                    style={{
                      width: "10%",
                      borderTop: "2px solid #01579b",
                      marginTop: 3,
                    }}
                  />
                  <br />
                  <Typography
                    variant="body1"
                    sx={{
                      textAlign: "justify",
                      justifyItems: "center",
                    }}
                  >
                    An expert evaluates your profile to determine whether you
                    meet the standards necessary for admittance to higher
                    education admission programmes in the area of study of your
                    choice.
                  </Typography>
                </Grid>
              </Grid>
              <Divider
                style={{
                  width: "100%",
                  borderTop: "1px solid #e0f2f1",
                  marginTop: "10px 0",
                }}
              />
              {/* image 2 */}
              <Grid
                container
                item
                sx={{ display: "flex" }}
                alignItems="center"
                xs={12}
                sm={12}
                md={12}
                flexWrap={"wrap-reverse"}
                spacing={5}
              >
                <Grid item xs={12} sm={12} md={6} justifyItems="center">
                  <Typography
                    variant="h5"
                    sx={{ color: "#191970", fontWeight: "500" }}
                  >
                    A feasible timeline for subsequent steps
                  </Typography>
                  <Divider
                    style={{
                      width: "10%",
                      borderTop: "2px solid #01579b",
                      marginTop: 3,
                    }}
                  />
                  <br />
                  <Typography
                    variant="body1"
                    textAlign="justify"
                    sx={{
                      justifyItems: "center",
                    }}
                  >
                    Ask the members of our team how much time they will need to
                    finish every task in order to create a realistic project
                    timeline.
                  </Typography>
                </Grid>

                <Grid item xs={12} sm={12} md={6}>
                  <ImageList
                    cols={1}
                    sx={{
                      width: { xs: "auto", sm: "auto", md: 400 },
                      height: { xs: "auto", sm: "auto", md: 400 },
                    }}
                  >
                    <ImageListItem>
                      <img
                        src="https://ik.imagekit.io/nwssoft/prernaacademy_project/Untitled_design__30_.png?updatedAt=1680152617473"
                        loading="lazy"
                        alt="Image 1"
                      />
                    </ImageListItem>
                  </ImageList>
                </Grid>
              </Grid>
              <Divider
                style={{
                  width: "100%",
                  borderTop: "1px solid #e0f2f1",
                  marginTop: "10px 0",
                }}
              />
              {/* image 3 */}
              <Grid
                container
                item
                sx={{ display: "flex" }}
                alignItems="center"
                xs={12}
                spacing={5}
              >
                <Grid item xs={12} sm={12} md={5}>
                  <ImageList
                    sx={{ width: "100%", height: "100%" }}
                    cols={1}
                    rowHeight="auto"
                  >
                    <ImageListItem>
                      <img
                        src="https://ik.imagekit.io/nwssoft/prernaacademy_project/6101073.jpg?updatedAt=1677331931961"
                        loading="lazy"
                        alt="Image 1"
                      />
                    </ImageListItem>
                  </ImageList>
                </Grid>

                <Grid item xs={12} sm={12} md={7} justifyItems="center">
                  <Typography
                    variant="h5"
                    sx={{ color: "#191970", fontWeight: "500" }}
                  >
                    Planning and shaping your career
                  </Typography>
                  <Divider
                    style={{
                      width: "10%",
                      borderTop: "2px solid #01579b",
                      marginTop: 3,
                    }}
                  />
                  <br />
                  <Typography
                    variant="body1"
                    sx={{
                      textAlign: "justify",
                      justifyItems: "center",
                    }}
                  >
                    A career that you enjoy and meets your needs can be found
                    and achieved with the aid of career planning. Some students
                    begin their studies in the major during the career
                    exploration phase, during which they must research various
                    professions and choose which one they want to follow.
                  </Typography>
                </Grid>
              </Grid>
              <Divider
                style={{
                  width: "100%",
                  borderTop: "1px solid #e0f2f1",
                  marginTop: "10px 0",
                }}
              />
              {/* image 4 */}
              <Grid
                container
                item
                alignItems="center"
                sx={{ display: "flex" }}
                xs={12}
                sm={12}
                md={12}
                flexWrap={"wrap-reverse"}
                spacing={5}
              >
                <Grid item xs={12} sm={12} md={7} justifyItems="center">
                  <Typography
                    variant="h5"
                    sx={{ color: "#191970", fontWeight: "500" }}
                  >
                    Prerequisites and accessible academic programmes 
                  </Typography>
                  <Divider
                    style={{
                      width: "10%",
                      borderTop: "2px solid #01579b",
                      marginTop: 3,
                    }}
                  />
                  <br />
                  <Typography variant="body1" textAlign="justify">
                    Academic options are a group of independent consultants with
                    a focus on education and therapy. Since its founding in
                    2006, Academic Options has helped thousands of families
                    around the globe. Helping every child, adolescent, and young
                    adult thrive in life is our aim.
                  </Typography>
                </Grid>

                <Grid item xs={12} sm={12} md={5}>
                  <ImageList
                    sx={{
                      width: { xs: "auto", sm: "auto", md: 400 },
                      height: { xs: "auto", sm: "auto", md: 400 },
                    }}
                    cols={1}
                    rowHeight="auto"
                  >
                    <ImageListItem>
                      <img
                        src="https://ik.imagekit.io/nwssoft/prernaacademy_project/5562408_21427.jpg?updatedAt=1712045923358"
                        loading="lazy"
                        alt="Image 1"
                      />
                    </ImageListItem>
                  </ImageList>
                </Grid>
              </Grid>
              <Divider
                style={{
                  width: "100%",
                  borderTop: "1px solid #e0f2f1",
                  marginTop: "10px 0",
                }}
              />
              {/* image 5 */}
              <Grid
                container
                item
                alignItems="center"
                sx={{ display: "flex" }}
                xs={12}
                spacing={5}
              >
                <Grid item xs={12} sm={12} md={5}>
                  <ImageList
                    sx={{ width: "100%", height: "100%" }}
                    cols={1}
                    rowHeight="auto"
                  >
                    <ImageListItem>
                      <img
                        src="https://ik.imagekit.io/nwssoft/prernaacademy_project/10516.jpg?updatedAt=1677331932056"
                        loading="lazy"
                        alt="Image 1"
                      />
                    </ImageListItem>
                  </ImageList>
                </Grid>

                <Grid item xs={12} sm={12} md={7} justifyItems="center">
                  <Typography
                    variant="h5"
                    sx={{ color: "#191970", fontWeight: "500" }}
                  >
                    Advice on acquiring certifications and specializing
                  </Typography>
                  <Divider
                    style={{
                      width: "10%",
                      borderTop: "2px solid #01579b",
                      marginTop: 3,
                    }}
                  />
                  <br />
                  <Typography
                    variant="body1"
                    sx={{
                      textAlign: "justify",
                      justifyItems: "center",
                    }}
                  >
                    Be ready to continue honing your technical abilities
                    throughout your career. Jobs and industries are constantly
                    changing, and these changes can occur rapidly. Don't expect
                    your college education, even if you have a degree, to
                    completely prepare you for the skills necessary to develop a
                    successful career.
                  </Typography>
                </Grid>
              </Grid>
              <Divider
                style={{
                  width: "100%",
                  borderTop: "1px solid #e0f2f1",
                  marginTop: "10px 0",
                }}
              />
              {/* image 6 */}
              <Grid
                sx={{ display: "flex" }}
                container
                item
                alignItems="center"
                xs={12}
                sm={12}
                md={12}
                spacing={5}
                flexWrap={"wrap-reverse"}
              >
                <Grid item xs={12} sm={12} md={7} justifyItems="center">
                  <Typography
                    variant="h5"
                    sx={{ color: "#191970", fontWeight: "500" }}
                  >
                    Professional advice for punctual applicants
                  </Typography>
                  <Divider
                    style={{
                      width: "10%",
                      borderTop: "2px solid #01579b",
                      marginTop: 3,
                    }}
                  />
                  <br />
                  <Typography
                    variant="body1"
                    sx={{
                      textAlign: "justify",
                      justifyItems: "center",
                    }}
                  >
                    Your top goal should be to consistently fulfill orders on
                    time in order to satisfy customer expectations. But for this
                    to work, you need a method that maintains all of your stock.
                  </Typography>
                </Grid>

                <Grid item xs={12} sm={12} md={5}>
                  <ImageList
                    sx={{
                      width: { xs: "auto", sm: "auto", md: 400 },
                      height: { xs: "auto", sm: "auto", md: 400 },
                    }}
                    cols={1}
                    rowHeight="auto"
                  >
                    <ImageListItem>
                      <img
                        src="https://ik.imagekit.io/nwssoft/prernaacademy_project/Untitled_design__18_.png?updatedAt=1680075869327"
                        loading="lazy"
                        alt="Image 1"
                      />
                    </ImageListItem>
                  </ImageList>
                </Grid>
              </Grid>
              <Divider
                style={{
                  width: "100%",
                  borderTop: "1px solid #e0f2f1",
                  marginTop: "10px 0",
                }}
              />
              {/* image 7 */}
              <Grid
                container
                item
                alignItems="center"
                sx={{ display: "flex" }}
                xs={12}
                spacing={5}
              >
                <Grid item xs={12} sm={12} md={5}>
                  <ImageList
                    sx={{ width: "100%", height: "100%" }}
                    cols={1}
                    rowHeight="auto"
                  >
                    <ImageListItem>
                      <img
                        src="https://ik.imagekit.io/nwssoft/prernaacademy_project/Untitled_design__10_.png?updatedAt=1680075848114"
                        loading="lazy"
                        alt="Image 1"
                      />
                    </ImageListItem>
                  </ImageList>
                </Grid>

                <Grid item xs={12} sm={12} md={7} justifyItems="center">
                  <Typography
                    variant="h5"
                    sx={{ color: "#191970", fontWeight: "500" }}
                  >
                    Assessment of Several Opportunities or Courses
                  </Typography>
                  <Divider
                    style={{
                      width: "10%",
                      borderTop: "2px solid #01579b",
                      marginTop: 3,
                    }}
                  />
                  <br />
                  <Typography
                    variant="body1"
                    sx={{
                      textAlign: "justify",
                      justifyItems: "center",
                    }}
                  >
                    In addition to novel methods of faculty evaluation and
                    professional development, this report describes current
                    research on how to rethink and restructure teaching and
                    learning.
                  </Typography>
                </Grid>
              </Grid>
              <Divider
                style={{
                  width: "100%",
                  borderTop: "1px solid #e0f2f1",
                  marginTop: "10px 0",
                }}
              />
              {/* image 8 */}

              <Grid
                sx={{ display: "flex" }}
                container
                item
                alignItems="center"
                xs={12}
                sm={12}
                md={12}
                flexWrap={"wrap-reverse"}
                spacing={5}
              >
                <Grid item xs={12} sm={12} md={7} justifyItems="center">
                  <Typography
                    variant="h5"
                    sx={{ color: "#191970", fontWeight: "500" }}
                  >
                    Real-time Data Exchange for Learning Options
                  </Typography>
                  <Divider
                    style={{
                      width: "10%",
                      borderTop: "2px solid #01579b",
                      marginTop: 3,
                    }}
                  />
                  <br />
                  <Typography
                    variant="body1"
                    sx={{
                      textAlign: "justify",
                      justifyItems: "center",
                    }}
                  >
                    Sharing of knowledge enables your team to curate your
                    material, share it, and start real-time discussions,
                    creating more intelligent, collaborative learning with the
                    information.
                  </Typography>
                </Grid>

                <Grid item xs={12} sm={12} md={5}>
                  <ImageList
                    sx={{
                      width: { xs: "auto", sm: "auto", md: 400 },
                      height: { xs: "auto", sm: "auto", md: 400 },
                    }}
                    cols={1}
                    rowHeight="auto"
                  >
                    <ImageListItem>
                      <img
                        src="https://ik.imagekit.io/nwssoft/prernaacademy_project/sammy-2.png?updatedAt=1677491803315"
                        loading="lazy"
                        alt="Image 1"
                      />
                    </ImageListItem>
                  </ImageList>
                </Grid>
              </Grid>
              <Divider
                style={{
                  width: "100%",
                  borderTop: "1px solid #e0f2f1",
                  marginTop: "10px 0",
                }}
              />
            </Grid>

            {/* heading for bottom */}

            <Grid container sx={{ marginBottom: "30px", marginTop: "10px" }}>
              <Grid item xs={12} sm={12} md={5}>
                <Typography variant="subtitle1" sx={{ color: "primary.main" }}>
                  Services and Experience
                </Typography>
                <Typography
                  variant="h4"
                  sx={{ color: "#191970", fontWeight: "500" }}
                >
                  What Makes Us Proud
                </Typography>
              </Grid>

              <Grid item xs={12} sm={12} md={7}>
                <Typography variant="subtitle1" textAlign="justify">
                  Prerna Academy with its impart quality of education program
                  which has been designed with its various group of education
                  from secondary to post graduate programs in different fields
                  bitcoin mixing and skills for those learners who are unable to
                  attend their continued programs.
                </Typography>
              </Grid>
            </Grid>
          </Grid>

          {/* Grid Content from about us page */}
          <Container sx={{ backgroundColor: "#D3D4D7", borderRadius: "90px" }}>
            <Grid
              item
              container
              sx={{
                padding: "30px 0px ",
                borderRadius: "90px",
                backgroundColor: "#D3D4D7",
                marginBottom: "10px",
                rowGap: 3,
              }}
              xs={12}
              sm={12}
              md={12}
              alignItems="center"
            >
              <Grid
                container
                item
                xs={12}
                sm={12}
                md={12}
                lg={4}
                sx={{ textAlign: "center", rowGap: 2 }}
              >
                <Grid
                  item
                  sx={{
                    backgroundColor: "#7396D1",
                    borderRadius: "90px",
                    padding: 3,
                    marginBottom: "10px",
                  }}
                  xs={12}
                  sm={12}
                  md={6}
                  lg={12}
                >
                  <PaidTwoToneIcon></PaidTwoToneIcon>
                  <Typography variant="h6">Decommissioned</Typography>
                  <Typography variant="subtitle1">
                    Colleges are not charged any compensation or referral fees.
                  </Typography>
                </Grid>

                <Grid
                  item
                  sx={{
                    backgroundColor: "#607d8b",
                    borderRadius: "90px",
                    padding: 3,
                  }}
                  xs={12}
                  sm={12}
                  md={6}
                  lg={12}
                >
                  <PeopleIcon></PeopleIcon>
                  <Typography variant="h6">Maestro Consultant</Typography>
                  <Typography variant="subtitle1">
                    All counsellors have a college degree from a prestigious
                    institution.
                  </Typography>
                </Grid>
              </Grid>

              <Grid
                item
                sx={{
                  backgroundColor: "#505F99",
                  borderRadius: "90px",
                  padding: 4,
                }}
                xs={12}
                sm={12}
                md={12}
                lg={4}
                textAlign="center"
              >
                <Typography variant="h6">15+ years of expertise</Typography>
                <Typography variant="subtitle1">
                  Prerna Academy offers excellent educational programmes that
                  range from secondary to Post Graduate level studies.
                </Typography>
                <Typography variant="subtitle1">
                  <CheckIcon></CheckIcon>Streamlined procedures
                </Typography>

                <Typography variant="subtitle1">
                  <CheckIcon></CheckIcon>No bogus promises
                </Typography>
                <Button
                  variant="contained"
                  sx={{ backgroundColor: "#44435f", borderRadius: "90px" }}
                  onClick={() => {
                    navigate("/contactus");
                    window.scroll(0, 0);
                  }}
                >
                  Connect To Us
                </Button>
              </Grid>

              <Grid
                container
                item
                xs={12}
                sm={12}
                md={12}
                lg={4}
                textAlign="center"
                sx={{ rowGap: 2 }}
              >
                <Grid
                  item
                  sx={{
                    backgroundColor: "#607d8b",
                    borderRadius: "90px",
                    padding: 3,
                    marginBottom: "10px",
                  }}
                  xs={12}
                  sm={12}
                  md={6}
                  lg={12}
                >
                  <FolderOffTwoToneIcon></FolderOffTwoToneIcon>
                  <Typography variant="h6">Networked testament</Typography>
                  <Typography variant="subtitle1">
                    Majority students from India collaborate online with us.
                  </Typography>
                </Grid>

                <Grid
                  item
                  boxShadow="3"
                  sx={{
                    backgroundColor: "#7396D1",
                    borderRadius: "90px",
                    padding: 3,
                  }}
                  xs={12}
                  sm={12}
                  md={6}
                  lg={12}
                >
                  <DesignServicesTwoToneIcon></DesignServicesTwoToneIcon>
                  <Typography variant="h6">
                    Standard / Quality assistance
                  </Typography>
                  <Typography variant="subtitle1">
                    Whoever purchased our five-college bundle received admission
                    in 99% of cases. 
                  </Typography>
                </Grid>
              </Grid>
            </Grid>
          </Container>
        </Box>
      </Container>
    </ParentContainer>
  );
}

export default Services;
