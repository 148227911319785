import { Paper } from "@mui/material";
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import Container from "@mui/material/Container";
import Grid from "@mui/material/Grid";
import TextField from "@mui/material/TextField";
import Typography from "@mui/material/Typography";
import { AxiosResponse } from "axios";
import { FormEvent, useState } from "react";
import { useNavigate } from "react-router-dom";
import CommonSnackBar from "../../Components/Common/CommonSnackbar";
import useValidation from "../../Hooks/useTypescriptValidation";
import { postRequestMethod } from "../../api/api";
import { POST_LEAVE } from "../../api/server";

interface LeaveData {
  fromDate: string;
  toDate: string;
  reasonForLeave: string;
  uploadFile: File | null;
  empId: string;
  type: string;
}

export default function LeaveForm(props: any) {
  const { eventHandler } = useValidation();
  const navigate = useNavigate();

  const [snackMessage, setSnackMessage] = useState<string>("");
  const [openSnackBar, setOpenSnackBar] = useState<boolean>(false);

  const [leaveData, setLeaveData] = useState<LeaveData>({
    fromDate: "",
    toDate: "",
    reasonForLeave: "",
    uploadFile: null,
    empId: props.types.lId,
    type: props.types.type,
  });

  const [errors, setErrors] = useState<{ [key: string]: string }>({
    fromDate: "",
    toDate: "",
    reasonForLeave: "",
    uploadFile: "",
  });

  const handleOpenSnackBar = (message: string) => {
    setSnackMessage(message);
    setOpenSnackBar(true);
  };

  const handleCloseSnackBar = () => {
    setOpenSnackBar(false);
  };

  const validationHandler = async (e: any, alterName?: any) => {
    let input: File | string;
    e.target.name === "uploadFile"
      ? (input = e.target.files[0])
      : (input = e.target.value);

    if (alterName && input) {
      let res: string = await new Promise<string>((resolve) => {
        if (true) {
          resolve(eventHandler(alterName, input));
        }
      });
      setErrors((preErrors: { [key: string]: string }) => ({
        ...preErrors,
        [e.target.name]: res,
      }));
    }
  };

  const handleInput = (e: any) => {
    const { name, value } = e.target;
    setLeaveData((prevData) => ({
      ...prevData,
      [name]: value,
    }));
  };

  const handleSubmit = async (event: FormEvent<HTMLFormElement>) => {
    event.preventDefault();
    try {
      const formData = new FormData();
      formData.append("fromDate", leaveData.fromDate);
      formData.append("toDate", leaveData.toDate);
      formData.append("reasonForLeave", leaveData.reasonForLeave);
      formData.append("empId", props.types.lId);
      formData.append("type", props.types.type);

      if (leaveData.uploadFile) {
        formData.append("uploadFile", leaveData.uploadFile);
      }

      //api for posting leave form data
      const res: void | AxiosResponse<any, any> = await postRequestMethod(
        POST_LEAVE,
        "multipart/form-data",
        leaveData
      );
      if (res && res.data) {
        if (res.data.message) {
          handleOpenSnackBar(res.data.message);
          formClear();
        } else {
          handleOpenSnackBar(res.data.error);
        }
      } else {
        handleOpenSnackBar("Unexpected error occurred");
      }
    } catch (error) {
      console.error(error);
    }
  };

  const handleFileChange = (e: any) => {
    if (e.target.files && e.target.files?.length > 0) {
      const file = e.target.files[0];
      setLeaveData((prevData) => ({
        ...prevData,
        uploadFile: file,
      }));
    }
  };

  const formClear = () => {
    setLeaveData({
      fromDate: "",
      toDate: "",
      reasonForLeave: "",
      uploadFile: null,
      empId: props.types.lId,
      type: props.types.type,
    });
  };

  return (
    <Container component="main" maxWidth="lg">
      <Paper elevation={20}>
        <Box
          sx={{
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
          }}
        >
          <Grid container sx={{ p: 2, background: "#0288d1", color: "white" }}>
            <Grid item xs={4}>
              <Typography variant="h5" sx={{ mx: 3 }}>
                Application For Leave
              </Typography>
            </Grid>
          </Grid>
          <Box
            component="form"
            onSubmit={handleSubmit}
            sx={{ mt: 3 }}
            encType="multipart/form-data"
          >
            <Grid
              container
              padding={1}
              sx={{ display: "flex", padding: "10px" }}
            >
              <Grid item xs={12}>
                <TextField
                  required
                  fullWidth
                  InputLabelProps={{ shrink: true }}
                  id="outlined-date-input"
                  name="fromDate"
                  label="From Date"
                  type="date"
                  value={leaveData.fromDate}
                  onChange={handleInput}
                  InputProps={{
                    inputProps: { min: new Date().toISOString().slice(0, 10) },
                  }}
                  sx={{ mt: 3, background: "white" }}
                />
              </Grid>
              <Grid item xs={12}>
                <TextField
                  required
                  fullWidth
                  InputLabelProps={{ shrink: true }}
                  id="outlined-date-input"
                  name="toDate"
                  label="To Date"
                  type="date"
                  value={leaveData.toDate}
                  sx={{ mt: 3, background: "white" }}
                  onChange={handleInput}
                  InputProps={{
                    inputProps: { min: leaveData.fromDate },
                  }}
                />
              </Grid>
              <Grid item xs={12}>
                <TextField
                  required
                  fullWidth
                  InputLabelProps={{ shrink: true }}
                  id="filled-multiline-static"
                  name="reasonForLeave"
                  label="Reason"
                  multiline
                  rows={4}
                  value={leaveData.reasonForLeave}
                  error={Boolean(errors.reasonForLeave)}
                  helperText={errors.reasonForLeave}
                  onChange={(e) => {
                    handleInput(e);
                    validationHandler(e, "message");
                  }}
                  sx={{ mt: 3, background: "white" }}
                />
              </Grid>
              <Grid item xs={12}>
                <TextField
                  fullWidth
                  InputLabelProps={{ shrink: true }}
                  id="filled-multiline-static"
                  name="uploadFile"
                  type="file"
                  error={Boolean(errors.uploadFile)}
                  helperText={errors.uploadFile}
                  sx={{ mt: 3, background: "white" }}
                  inputProps={{ accept: "image/*,.pdf" }}
                  onChange={(e) => {
                    handleFileChange(e);
                    validationHandler(e, "pdfImageSize");
                  }}
                />
              </Grid>
              <Grid
                container
                sx={{ display: "flex", justifyContent: "space-evenly" }}
              >
                <Button
                  type="submit"
                  variant="contained"
                  sx={{ mr: "30px", mt: 2, px: 4 }}
                >
                  Apply
                </Button>
                <Button
                  type="button"
                  variant="contained"
                  sx={{ mr: "30px", mt: 2, px: 4 }}
                  onClick={() => navigate(-1)}
                >
                  Cancel
                </Button>
                <CommonSnackBar
                  open={openSnackBar}
                  autoHideDuration={5000}
                  onClose={handleCloseSnackBar}
                  message={snackMessage}
                />
              </Grid>
            </Grid>
          </Box>
        </Box>
      </Paper>
    </Container>
  );
}
