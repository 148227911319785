import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import KeyboardArrowUpIcon from "@mui/icons-material/KeyboardArrowUp";
import SearchIcon from "@mui/icons-material/Search";
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Box,
  Button,
  Collapse,
  FormControl,
  Grid,
  IconButton,
  InputAdornment,
  List,
  TablePagination,
  Typography,
} from "@mui/material";
import Paper from "@mui/material/Paper";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import TextField from "@mui/material/TextField";
import { PDFDownloadLink } from "@react-pdf/renderer";
import * as React from "react";
import { useEffect, useState } from "react";
import { useNavigate } from "react-router";
import { Container, ListItem } from "semantic-ui-react";
import AppointmentLetter from "../../Components/Common/AppointmentLetter";
import ExperienceLetter from "../../Components/Common/ExperienceLetter";
import TableToExcel from "../../Components/Common/TableToExcel";
import "../../Components/Home/Home.scss";
import { getRequest } from "../../api/api";
import { GET_CENTER_HEAD } from "../../api/server";

const columns: any = [
  { id: 1, field: "", headerName: "" },
  { id: 2, field: "", headerName: "Sr.No" },
  { id: 3, field: "empId", headerName: "Employee Id" },
  { id: 4, field: "firstName", headerName: "First Name" },
  { id: 5, field: "lastName", headerName: "Last Name" },
  { id: 6, field: "email", headerName: "Email" },
  { id: 7, field: "phone", headerName: "Contact" },
  { id: 8, field: "password", headerName: "Password" },
  { id: 9, field: "Member", headerName: "Employee" },
  { id: 10, field: "CLCount", headerName: "CL Count" },
  { id: 11, field: "createdAt", headerName: "Created At" },
  { id: 12, field: "status", headerName: "Status" },
];

export default function ManageCenterHead(props: any) {
  const navigate = useNavigate();

  // state to manage table pages displaying
  const [page, setPage] = React.useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(10);

  // state to set table data
  const [tableData, setTableData] = useState<any>([]);

  // states for search data functionality
  const [searchApiData, setSearchApiData] = useState<any[]>([]);
  const [filterVal, setFilterVal] = useState("");

  // state to manage collapsible
  const [open, setOpen] = React.useState(
    new Array(tableData.length).fill(false)
  );

  const getTableData = async () => {
    try {
      let result = await getRequest(GET_CENTER_HEAD, "");
      if (result) {
        setTableData(result.data);
        setSearchApiData(result.data);
      }
    } catch (error) {
      console.error("Error getting center head data", error);
    }
  };

  const handleChangePage = (event: any, newPage: any) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event: any) => {
    setRowsPerPage(+event.target.value);
    setPage(0);
  };

  // function to filter data
  const handleFilter = (e: any) => {
    if (e.target.value === "") {
      setTableData(searchApiData);
    } else {
      const filterResult = searchApiData.filter((item: any) => {
        const searchValue = e.target.value.toLowerCase();
        return Object.values(item).some((value) => {
          if (typeof value === "string" || typeof value === "number") {
            const itemValue = String(value).toLowerCase();
            return itemValue.includes(searchValue);
          }
          return false;
        });
      });
      setTableData(filterResult);
    }
    setFilterVal(e.target.value);
  };

  //handler for email click and open mail tab
  const handleEmailClick = (email: any) => {
    window.open(`mailto:${email}`, "_blank");
  };

  // calling getTableData on page load
  useEffect(() => {
    getTableData();
  }, []);

  return (
    <>
      <Container>
        <Paper elevation={20}>
          <Grid
            container
            sx={{
              p: 2,
              background: "#0288d1",
              color: "white",
            }}
          >
            <Grid item xs={6}>
              <Typography variant="h5" sx={{ mx: 3 }}>
                Manage Center Head
              </Typography>
            </Grid>
            <Grid item xs={3}>
              <TableToExcel
                tableData={tableData}
                fileName="center_head_data"
                headerMapping={{
                  "Employee Id": "empId",
                  "First Name": "firstName",
                  "Last Name": "lastName",
                  "Email Id": "email",
                  Password: "password",
                  Phone: "phone",
                  DOB: "DOB",
                  Post: "post",
                  Department: "department",
                  Unit: "unit",
                  "Mobile Charge": "mobileCharge",
                  "Professional Tax": "pTax",
                  "Basic Salary": "basicSalary",
                  HRA: "hra",
                  Conveyance: "conveyance",
                  Allowances: "allowances",
                  "Job Location": "jobLocation",
                  "Share Percent": "sharePercent",
                  Status: "status",
                  "Offered Salary": "offeredSalary",
                  "Created At": "createdAt",
                }}
              />
            </Grid>
            <Grid
              item
              xs={3}
              sx={{ display: "flex", justifyContent: "flex-end" }}
            >
              <FormControl>
                <TextField
                  label="Search "
                  size="small"
                  value={filterVal}
                  onInput={(e: any) => handleFilter(e)}
                  sx={{ mx: 3, background: "white" }}
                  InputProps={{
                    endAdornment: (
                      <InputAdornment position="end">
                        <IconButton edge="end">
                          <SearchIcon />
                        </IconButton>
                      </InputAdornment>
                    ),
                  }}
                />
              </FormControl>
            </Grid>
          </Grid>
          <TableContainer className="scrollBarCss">
            <Table sx={{ border: "1px solid #ddd" }} aria-label="simple table">
              <TableHead>
                <TableRow>
                  {columns?.map((item: any) => (
                    <React.Fragment key={item.id}>
                      <TableCell
                        className="border"
                        sx={{
                          textAlign: "center",
                          borderRight: "1px solid #ddd",
                          fontSize: "15px",
                        }}
                      >
                        {item.headerName}
                      </TableCell>
                    </React.Fragment>
                  ))}
                </TableRow>
              </TableHead>
              <TableBody>
                {tableData
                  .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                  .map((item: any, index: any) => (
                    <React.Fragment key={item._id}>
                      <TableRow
                        key={item._id}
                        sx={{
                          "&:last-child td, &:last-child th": { border: 0 },
                        }}
                      >
                        <TableCell
                          sx={{
                            textAlign: "center",
                            borderRight: "1px solid #ddd",
                          }}
                        >
                          <IconButton
                            aria-label="expand row"
                            size="small"
                            onClick={() => {
                              const newOpen = [...open];
                              newOpen[index] = !newOpen[index];
                              setOpen(newOpen);
                            }}
                          >
                            {open[index] ? (
                              <KeyboardArrowUpIcon />
                            ) : (
                              <KeyboardArrowDownIcon />
                            )}
                          </IconButton>
                        </TableCell>
                        <TableCell
                          sx={{
                            textAlign: "center",
                            borderRight: "1px solid #ddd",
                          }}
                        >
                          {page * rowsPerPage + index + 1}
                        </TableCell>
                        <TableCell
                          sx={{
                            textAlign: "center",
                            borderRight: "1px solid #ddd",
                          }}
                        >
                          {item.empId}
                        </TableCell>
                        <TableCell
                          sx={{
                            textAlign: "center",
                            borderRight: "1px solid #ddd",
                          }}
                        >
                          {item.firstName}
                        </TableCell>
                        <TableCell
                          sx={{
                            textAlign: "center",
                            borderRight: "1px solid #ddd",
                          }}
                        >
                          {item.lastName}
                        </TableCell>

                        <TableCell
                          sx={{
                            textAlign: "center",
                            borderRight: "1px solid #ddd",
                          }}
                          onClick={() => handleEmailClick(item.email)}
                          style={{ cursor: "pointer", color: "blue" }}
                        >
                          {item.email}
                        </TableCell>
                        <TableCell
                          sx={{
                            textAlign: "center",
                            borderRight: "1px solid #ddd",
                          }}
                        >
                          {item.phone}
                        </TableCell>
                        <TableCell
                          sx={{
                            textAlign: "center",
                            borderRight: "1px solid #ddd",
                          }}
                        >
                          {item.password}
                        </TableCell>
                        <TableCell
                          className="border"
                          sx={{
                            textAlign: "center",
                            borderRight: "1px solid #ddd",
                          }}
                        >
                          <Accordion>
                            <AccordionSummary
                              expandIcon={<ExpandMoreIcon />}
                              aria-controls="panel1a-content"
                              id="panel1a-header"
                            >
                              <Typography
                                sx={{ fontSize: "small", fontWeight: "bold" }}
                              >
                                Employee
                              </Typography>
                            </AccordionSummary>
                            <AccordionDetails>
                              <List>
                                {item?.teamleads?.map((obj: any) => (
                                  <React.Fragment key={obj._id}>
                                    <Accordion>
                                      <AccordionSummary
                                        expandIcon={<ExpandMoreIcon />}
                                        aria-controls={`panel-${obj._id}-content`}
                                        id={`panel-${obj._id}-header`}
                                        sx={{ fontWeight: "bold" }}
                                      >
                                        <Typography
                                          sx={{
                                            fontSize: "small",
                                            fontWeight: "bold",
                                          }}
                                        >
                                          {`${obj.firstName} ${obj.lastName}`}
                                        </Typography>
                                      </AccordionSummary>
                                      <AccordionDetails>
                                        <List>
                                          {obj.executives.map((obj2: any) => (
                                            <ListItem
                                              key={obj2._id}
                                              sx={{ pl: 2 }}
                                            >
                                              <Typography
                                                sx={{ fontSize: "small" }}
                                              >
                                                {`${obj2.firstName} ${obj2.lastName}`}
                                              </Typography>
                                            </ListItem>
                                          ))}
                                        </List>
                                      </AccordionDetails>
                                    </Accordion>
                                  </React.Fragment>
                                ))}
                              </List>
                            </AccordionDetails>
                          </Accordion>
                        </TableCell>

                        <TableCell
                          className="border"
                          sx={{
                            textAlign: "center",
                            borderRight: "1px solid #ddd",
                          }}
                        >
                          {item.clcount}
                        </TableCell>
                        <TableCell
                          sx={{
                            textAlign: "center",
                            borderRight: "1px solid #ddd",
                          }}
                        >
                          {item.createdAt}
                        </TableCell>
                        <TableCell
                          sx={{
                            textAlign: "center",
                            borderRight: "1px solid #ddd",
                          }}
                        >
                          {item.status}
                        </TableCell>
                      </TableRow>

                      <TableRow>
                        <TableCell colSpan={15}>
                          <Collapse
                            in={open[index]}
                            timeout="auto"
                            unmountOnExit
                          >
                            <Box sx={{ margin: 1 }}>
                              <Table size="small" aria-label="purchases">
                                <TableBody>
                                  <TableRow>
                                    <TableCell
                                      sx={{ border: "InactiveBorder" }}
                                    >
                                      <Button
                                        sx={{ px: 3 }}
                                        variant="contained"
                                        size="small"
                                        onClick={() => {
                                          navigate("/dashboard", {
                                            state: {
                                              email: item.email,
                                              name: item.firstName,
                                              lId: item.chUniqueId,
                                              regId: item.empId,
                                              type: "Center Head",
                                            },
                                          });
                                          window.location.reload();
                                        }}
                                      >
                                        Switch User
                                      </Button>
                                    </TableCell>
                                    <TableCell
                                      sx={{ border: "InactiveBorder" }}
                                    >
                                      <Button
                                        sx={{ px: 3 }}
                                        type="button"
                                        variant="contained"
                                        size="small"
                                        onClick={() => {
                                          navigate(
                                            "/dashboard/EditCenterHead",
                                            {
                                              state: item,
                                            }
                                          );
                                        }}
                                      >
                                        Edit
                                      </Button>
                                    </TableCell>
                                    <TableCell
                                      sx={{ border: "InactiveBorder" }}
                                    >
                                      <Button
                                        sx={{ px: 3 }}
                                        type="button"
                                        variant="contained"
                                        size="small"
                                        onClick={() => {
                                          navigate("/dashboard/Employee", {
                                            state: item,
                                          });
                                        }}
                                      >
                                        Employee Info
                                      </Button>
                                    </TableCell>
                                    <TableCell
                                      sx={{ border: "InactiveBorder" }}
                                    >
                                      <PDFDownloadLink
                                        document={
                                          <ExperienceLetter item={item} />
                                        }
                                      >
                                        {({ loading }) =>
                                          loading ? (
                                            <Button sx={{ px: 3 }}>
                                              Loading Document...
                                            </Button>
                                          ) : (
                                            <Button
                                              variant="contained"
                                              size="small"
                                              sx={{ px: 5 }}
                                            >
                                              Experience Letter
                                            </Button>
                                          )
                                        }
                                      </PDFDownloadLink>
                                    </TableCell>
                                    <TableCell
                                      sx={{ border: "InactiveBorder" }}
                                    >
                                      <PDFDownloadLink
                                        document={
                                          <AppointmentLetter item={item} />
                                        }
                                      >
                                        {({ loading }) =>
                                          loading ? (
                                            <Button sx={{ px: 3 }}>
                                              Loading Document...
                                            </Button>
                                          ) : (
                                            <Button
                                              variant="contained"
                                              size="small"
                                              sx={{ px: 5 }}
                                            >
                                              Appointment Letter
                                            </Button>
                                          )
                                        }
                                      </PDFDownloadLink>
                                    </TableCell>
                                  </TableRow>
                                </TableBody>
                              </Table>
                            </Box>
                          </Collapse>
                        </TableCell>
                      </TableRow>
                    </React.Fragment>
                  ))}
              </TableBody>
            </Table>
          </TableContainer>
          <TablePagination
            rowsPerPageOptions={[10, 25, 50, 100]}
            component="div"
            count={tableData.length}
            rowsPerPage={rowsPerPage}
            page={page}
            onPageChange={handleChangePage}
            onRowsPerPageChange={handleChangeRowsPerPage}
          ></TablePagination>
        </Paper>
      </Container>
    </>
  );
}
