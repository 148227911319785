import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import KeyboardArrowUpIcon from "@mui/icons-material/KeyboardArrowUp";
import SearchIcon from "@mui/icons-material/Search";
import {
  Button,
  Container,
  FormControl,
  Grid,
  IconButton,
  InputAdornment,
  List,
  TablePagination,
  TextField,
  Typography,
} from "@mui/material";
import Box from "@mui/material/Box";
import Collapse from "@mui/material/Collapse";
import Paper from "@mui/material/Paper";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import { PDFDownloadLink } from "@react-pdf/renderer";
import * as React from "react";
import { useEffect, useState } from "react";
import { useNavigate } from "react-router";
import AppointmentLetter from "../../Components/Common/AppointmentLetter";
import ExperienceLetter from "../../Components/Common/ExperienceLetter";
import FollowupList from "../../Components/Common/FollowupList/FollowupList";
import TableToExcel from "../../Components/Common/TableToExcel";
import { getRequest, getRequestById } from "../../api/api";
import { GET_EXECUTIVE, GET_EX_BY_TLID } from "../../api/server";

const columns: any = [
  { id: 1, field: "", headerName: "" },
  { id: 2, field: "", headerName: "Sr.No" },
  { id: 3, field: "exeId", headerName: "Employee Id" },
  { id: 4, field: "firstName", headerName: "First Name" },
  { id: 5, field: "lastName", headerName: "Last Name" },
  { id: 6, field: "centerhead", headerName: "Center Head" },
  { id: 7, field: "teamLead", headerName: "Team Lead" },
  { id: 8, field: "department", headerName: "Department" },
  { id: 9, field: "unit", headerName: "Unit" },
  { id: 10, field: "email", headerName: "Email Id" },
  { id: 11, field: "phone", headerName: "Phone Number" },
  { id: 12, field: "password", headerName: "Password" },
  { id: 13, field: "createdAt", headerName: "Created At" },
  { id: 14, field: "status", headerName: "Status" },
];

export default function ManageExecutive(props: any) {
  const navigate = useNavigate();

  //state to maintain login details
  const [types, setTypes] = useState<any>({});

  //state to manage searched data
  const [searchApiData, setSearchApiData] = useState<any[]>([]);

  //state to manage search value
  const [filterVal, setFilterVal] = useState<string>("");

  //state to manage table page display
  const [page, setPage] = React.useState<number>(0);

  //state to set rows in table page
  const [rowsPerPage, setRowsPerPage] = React.useState<number>(10);

  //state to store table data
  const [tableData, setTableData] = useState<any[]>([]);

  //state to manage table row collapse
  const [open, setOpen] = React.useState(
    new Array(tableData.length).fill(false)
  );

  const handleChangePage = (event: any, newPage: any) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event: any) => {
    setRowsPerPage(+event.target.value);
    setPage(0);
  };

  // function to get table data
  const getTableData = async () => {
    try {
      if (props?.types?.type === "Center Head") {
        let result = await getRequestById(GET_EXECUTIVE, props?.types?.lId, "");
        if (result) {
          setTableData(result.data);
          setSearchApiData(result.data);
        }
      } else if (props?.types?.type === "Team Lead") {
        let result = await getRequestById(
          GET_EX_BY_TLID,
          props?.types?.lId,
          ""
        );
        if (result) {
          setTableData(result.data);
          setSearchApiData(result.data);
        }
      } else {
        let result = await getRequest(GET_EXECUTIVE, "");
        if (result) {
          setTableData(result.data);
          setSearchApiData(result.data);
        }
      }
    } catch (error) {
      console.log("Error getting team lead data", error);
    }
  };

  // function to handle filter data
  const handleFilter = (e: any) => {
    if (e.target.value === "") {
      setTableData(searchApiData);
    } else {
      const filterResult: any = searchApiData.filter((item: any) => {
        const searchValue = e.target.value.toLowerCase();
        return Object.values(item).some((value) => {
          if (typeof value === "string" || typeof value === "number") {
            const itemValue = String(value).toLowerCase();
            return itemValue.includes(searchValue);
          }
          return false;
        });
      });
      setTableData(filterResult);
    }
    setFilterVal(e.target.value);
  };

  //handler for email click and open mail tab
  const handleEmailClick = (email: any) => {
    window.open(`mailto:${email}`, "_blank");
  };

  //to set login details/type in state & calling getTableData
  useEffect(() => {
    setTypes(props.types);
    getTableData();
  }, [props]);

  return (
    <>
      <Container>
        <Paper elevation={20}>
          <Grid
            container
            sx={{
              p: 2,
              background: "#0288d1",
              color: "white",
            }}
          >
            <Grid item xs={6}>
              <Typography variant="h5" sx={{ mx: 3 }}>
                Manage Executive
              </Typography>
            </Grid>
            <Grid item xs={3}>
              <TableToExcel
                tableData={tableData}
                fileName="Executive_data"
                headerMapping={{
                  "Employee Id": "empId",
                  "First Name": "firstName",
                  "Last Name": "lastName",
                  "Email Id": "email",
                  Password: "password",
                  Phone: "phone",
                  DOB: "DOB",
                  Post: "post",
                  Department: "department",
                  Unit: "unit",
                  "Mobile Charge": "mobileCharge",
                  "Professional Tax": "pTax",
                  "Basic Salary": "basicSalary",
                  HRA: "hra",
                  Conveyance: "conveyance",
                  Allowances: "allowances",
                  "Job Location": "jobLocation",
                  "Share Percent": "sharePercent",
                  Status: "status",
                  "Offered Salary": "offeredSalary",
                  "Created At": "createdAt",
                }}
              />
            </Grid>
            <Grid
              item
              xs={3}
              sx={{ display: "flex", justifyContent: "flex-end" }}
            >
              <FormControl>
                <TextField
                  label="Search "
                  size="small"
                  value={filterVal}
                  onInput={(e: any) => handleFilter(e)}
                  sx={{ mx: 3, background: "white" }}
                  InputProps={{
                    endAdornment: (
                      <InputAdornment position="end">
                        <IconButton edge="end">
                          <SearchIcon />
                        </IconButton>
                      </InputAdornment>
                    ),
                  }}
                />
              </FormControl>
            </Grid>
          </Grid>
          <TableContainer className="scrollBarCss">
            <Table sx={{ border: "1px solid #ddd" }} aria-label="simple table">
              <TableHead>
                <TableRow>
                  {columns.map((item: any) => (
                    <React.Fragment key={item.id}>
                      <TableCell
                        sx={{
                          textAlign: "center",
                          borderRight: "1px solid #ddd",
                          fontSize: "15px",
                        }}
                      >
                        {item.headerName}
                      </TableCell>
                    </React.Fragment>
                  ))}
                </TableRow>
              </TableHead>
              <TableBody>
                {tableData
                  .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                  .map((item: any, index: any) => (
                    <React.Fragment key={item._id}>
                      <TableRow
                        key={item._id}
                        sx={{
                          "&:last-child td, &:last-child th": { border: 0 },
                        }}
                      >
                        <TableCell
                          sx={{
                            textAlign: "center",
                            borderRight: "1px solid #ddd",
                          }}
                        >
                          <IconButton
                            aria-label="expand row"
                            size="small"
                            onClick={() => {
                              const newOpen = [...open];
                              newOpen[index] = !newOpen[index];
                              setOpen(newOpen);
                            }}
                          >
                            {open[index] ? (
                              <KeyboardArrowUpIcon />
                            ) : (
                              <KeyboardArrowDownIcon />
                            )}
                          </IconButton>
                        </TableCell>
                        <TableCell
                          sx={{
                            textAlign: "center",
                            borderRight: "1px solid #ddd",
                          }}
                        >
                          {page * rowsPerPage + index + 1}
                        </TableCell>
                        <TableCell
                          sx={{
                            textAlign: "center",
                            borderRight: "1px solid #ddd",
                          }}
                        >
                          {item.empId}
                        </TableCell>
                        <TableCell
                          sx={{
                            textAlign: "center",
                            borderRight: "1px solid #ddd",
                          }}
                        >
                          {item.firstName}
                        </TableCell>
                        <TableCell
                          sx={{
                            textAlign: "center",
                            borderRight: "1px solid #ddd",
                          }}
                        >
                          {item.lastName}
                        </TableCell>
                        <TableCell
                          sx={{
                            textAlign: "center",
                            borderRight: "1px solid #ddd",
                          }}
                        >
                          {item.centerHeadData.map((obj: any) => (
                            <React.Fragment key={obj._id}>
                              <List>
                                {
                                  <Typography sx={{ fontSize: "small" }}>
                                    {obj.firstName + " " + obj.lastName}
                                  </Typography>
                                }
                              </List>
                            </React.Fragment>
                          ))}
                        </TableCell>
                        <TableCell
                          sx={{
                            textAlign: "center",
                            borderRight: "1px solid #ddd",
                          }}
                        >
                          {item.teamLeadData.map((obj: any) => (
                            <React.Fragment key={obj._id}>
                              <List>
                                {
                                  <Typography sx={{ fontSize: "small" }}>
                                    {obj.firstName + " " + obj.lastName}
                                  </Typography>
                                }
                              </List>
                            </React.Fragment>
                          ))}
                        </TableCell>
                        <TableCell
                          sx={{
                            textAlign: "center",
                            borderRight: "1px solid #ddd",
                          }}
                        >
                          {item.department}
                        </TableCell>
                        <TableCell
                          sx={{
                            textAlign: "center",
                            borderRight: "1px solid #ddd",
                          }}
                        >
                          {item.unit}
                        </TableCell>
                        <TableCell
                          sx={{
                            textAlign: "center",
                            borderRight: "1px solid #ddd",
                          }}
                          onClick={() => handleEmailClick(item.email)}
                          style={{ cursor: "pointer", color: "blue" }}
                        >
                          {item.email}
                        </TableCell>
                        <TableCell
                          sx={{
                            textAlign: "center",
                            borderRight: "1px solid #ddd",
                          }}
                        >
                          {item.phone}
                        </TableCell>
                        <TableCell
                          sx={{
                            textAlign: "center",
                            borderRight: "1px solid #ddd",
                          }}
                        >
                          {item.password}
                        </TableCell>
                        <TableCell
                          sx={{
                            textAlign: "center",
                            borderRight: "1px solid #ddd",
                          }}
                        >
                          {item.createdAt}
                        </TableCell>
                        <TableCell
                          sx={{
                            textAlign: "center",
                            borderRight: "1px solid #ddd",
                          }}
                        >
                          {item.status}
                        </TableCell>
                      </TableRow>

                      <TableRow>
                        <TableCell colSpan={15}>
                          <Collapse
                            in={open[index]}
                            timeout="auto"
                            unmountOnExit
                          >
                            <Box sx={{ margin: 1 }}>
                              <Table size="small" aria-label="purchases">
                                <TableBody>
                                  <TableRow>
                                    {types.type === "Administrator" ||
                                    types.type === "Center Head" ||
                                    types.type === "Team Lead" ? (
                                      <>
                                        <TableCell
                                          sx={{ border: "InactiveBorder" }}
                                        >
                                          <Button
                                            sx={{ px: 3 }}
                                            variant="contained"
                                            size="small"
                                            onClick={() => {
                                              navigate("/dashboard", {
                                                state: {
                                                  email: item.email,
                                                  name: item.firstName,
                                                  lId: item.exUniqueId,
                                                  regId: item.empId,
                                                  type: "Executive",
                                                },
                                              });
                                              window.location.reload();
                                            }}
                                          >
                                            Switch User
                                          </Button>
                                        </TableCell>
                                      </>
                                    ) : null}
                                    {types.type === "Administrator" ? (
                                      <>
                                        <TableCell
                                          sx={{ border: "InactiveBorder" }}
                                        >
                                          <Button
                                            sx={{ px: 3 }}
                                            type="button"
                                            variant="contained"
                                            size="small"
                                            onClick={() => {
                                              navigate(
                                                "/dashboard/EditExecutive",
                                                {
                                                  state: item,
                                                }
                                              );
                                            }}
                                          >
                                            Edit
                                          </Button>
                                        </TableCell>
                                        <TableCell
                                          sx={{ border: "InactiveBorder" }}
                                        >
                                          <Button
                                            sx={{ px: 3 }}
                                            type="button"
                                            variant="contained"
                                            size="small"
                                            onClick={() => {
                                              navigate("/dashboard/Employee", {
                                                state: item,
                                              });
                                            }}
                                          >
                                            Employee Info
                                          </Button>
                                        </TableCell>

                                        <TableCell
                                          sx={{ border: "InactiveBorder" }}
                                        >
                                          <FollowupList item={item} />
                                        </TableCell>

                                        <TableCell
                                          sx={{ border: "InactiveBorder" }}
                                        >
                                          <PDFDownloadLink
                                            document={
                                              <ExperienceLetter item={item} />
                                            }
                                          >
                                            {({ loading }) =>
                                              loading ? (
                                                <Button sx={{ px: 3 }}>
                                                  Loading Document...
                                                </Button>
                                              ) : (
                                                <Button
                                                  variant="contained"
                                                  size="small"
                                                  sx={{ px: 3 }}
                                                >
                                                  Experience Letter
                                                </Button>
                                              )
                                            }
                                          </PDFDownloadLink>
                                        </TableCell>
                                        <TableCell
                                          sx={{ border: "InactiveBorder" }}
                                        >
                                          <PDFDownloadLink
                                            document={
                                              <AppointmentLetter item={item} />
                                            }
                                          >
                                            {({ loading }) =>
                                              loading ? (
                                                <Button sx={{ px: 3 }}>
                                                  Loading Document...
                                                </Button>
                                              ) : (
                                                <Button
                                                  variant="contained"
                                                  size="small"
                                                  sx={{ px: 3 }}
                                                >
                                                  Appointment Letter
                                                </Button>
                                              )
                                            }
                                          </PDFDownloadLink>
                                        </TableCell>
                                      </>
                                    ) : null}
                                  </TableRow>
                                </TableBody>
                              </Table>
                            </Box>
                          </Collapse>
                        </TableCell>
                      </TableRow>
                    </React.Fragment>
                  ))}
              </TableBody>
            </Table>
          </TableContainer>
          <TablePagination
            rowsPerPageOptions={[10, 25, 50, 100]}
            component="div"
            count={tableData.length}
            rowsPerPage={rowsPerPage}
            page={page}
            onPageChange={handleChangePage}
            onRowsPerPageChange={handleChangeRowsPerPage}
          ></TablePagination>
        </Paper>
      </Container>
    </>
  );
}
