import {
  legacy_createStore as createStore,
  combineReducers,
} from "@reduxjs/toolkit";
import LoginReducer from "./Reducer/LoginReducer";

// combineReducers => make a combination for multiple reducer
const allApplicationReducer = combineReducers({
  LoginReducer: LoginReducer,
});

const store = createStore(allApplicationReducer);
export default store;
