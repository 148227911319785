import CloseIcon from "@mui/icons-material/Close";
import WhatsAppIcon from "@mui/icons-material/WhatsApp";
import { Box, Button, Dialog, Stack, Tooltip, Typography } from "@mui/material";
import React, { useState } from "react";

interface WhatsAppChatBoxProps {
  chatNumber: string;
}

const Whatsapp: React.FC<WhatsAppChatBoxProps> = ({ chatNumber }) => {
  const [open, setOpen] = useState(false);

  const handleOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  return (
    <>
      <Tooltip title="Whatsapp Lead">
        <Button
          variant="outlined"
          onClick={handleOpen}
          style={{ cursor: "pointer" }}
          sx={{ width: "100%", height: "100%", color: "#25D366" }}
        >
          <Stack
            direction={"row"}
            spacing={2}
            justifyContent={"center"}
            alignItems={"center"}
          >
            {!open ? (
              <Box>
                <WhatsAppIcon color="success" />
              </Box>
            ) : (
              <CloseIcon sx={{ fontSize: "50px" }} onClick={handleClose} />
            )}
          </Stack>
        </Button>
      </Tooltip>

      <Dialog open={open} onClose={handleClose}>
        <Stack
          direction={"row"}
          sx={{
            backgroundColor: "green",
            p: "10px",
            color: "#fff",
            width: { xs: "180px", sm: "350px" },
          }}
        >
          <WhatsAppIcon sx={{ fontSize: "50px" }} />
          <Box>
            <Typography variant="h6">Start a Conversation</Typography>
            <Typography>
              Hi! Click one of our member below to chat on <b>WhatsApp</b>
            </Typography>
          </Box>
        </Stack>
        <Stack
          direction={"column"}
          sx={{ backgroundColor: "white", p: "10px" }}
        >
          <Typography>The team typically replies in a few minutes.</Typography>

          <Button
            component="a"
            href={`https://api.whatsapp.com/send?phone=${chatNumber}`}
            target="_blank"
            variant="text"
            sx={{
              backgroundColor: "grey",
            }}
          >
            <WhatsAppIcon sx={{ fontSize: "50px", color: "green" }} />

            <Box>
              <Typography variant="h6" color="black">
                WhatsApp
              </Typography>
            </Box>
          </Button>
        </Stack>
      </Dialog>
    </>
  );
};

export default Whatsapp;
