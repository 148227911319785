import { Button, Typography, Box, Container, Paper } from "@mui/material";
import { styled } from "@mui/material/styles";
import AddReactionIcon from "@mui/icons-material/AddReaction";
import { useNavigate } from "react-router-dom";

const ImageWrapper = styled(Box)({
  display: "flex",
  justifyContent: "center",
  alignItems: "center",
  margin: "2rem 0",
});

const Image = styled("img")({
  width: "50%",
  height: "auto",
});

const NoDataFound = () => {
  const navigate = useNavigate();

  return (
    <>
      <Container
        maxWidth="lg"
        component={Paper}
        sx={{ mt: 2, mb: 2, p: 4, boxShadow: 5, textAlign: "center" }}
      >
        <ImageWrapper>
          <Image
            src="https://ik.imagekit.io/nwssoft/NWS_Prerna_Project/Dashboard/undraw_Profile_data_re_v81r.png?updatedAt=1682511043510"
            alt="No data found"
          />
        </ImageWrapper>
        <Typography
          variant="h2"
          color="primary"
          sx={{ margin: "10px", fontWeight: "700" }}
        >
          No Data Found!
        </Typography>
        <Button
          variant="outlined"
          endIcon={<AddReactionIcon />}
          onClick={() => {
            navigate(-1);
          }}
        >
          Go Back
        </Button>
      </Container>
    </>
  );
};

export default NoDataFound;
