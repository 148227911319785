import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import KeyboardArrowUpIcon from "@mui/icons-material/KeyboardArrowUp";
import { Collapse, IconButton, LinearProgress, Stack } from "@mui/material";
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import Checkbox from "@mui/material/Checkbox";
import Container from "@mui/material/Container";
import FormControl from "@mui/material/FormControl";
import FormControlLabel from "@mui/material/FormControlLabel";
import Grid from "@mui/material/Grid";
import MenuItem from "@mui/material/MenuItem";
import Select, { SelectChangeEvent } from "@mui/material/Select";
import TextField from "@mui/material/TextField";
import Typography from "@mui/material/Typography";
import { useEffect, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import CommonSnackBar from "../../Components/Common/CommonSnackbar";
import useValidation from "../../Hooks/useTypescriptValidation";
import { getRequest, updateRequest } from "../../api/api";
import {
  ADD_ACTIVITY,
  GET_UNIVERSITY,
  PUSH_ACTIVITY,
  STATIC_DATA
} from "../../api/server";

function Activity(props: any) {
  const navigate = useNavigate();
  const location = useLocation();
  const { eventHandler } = useValidation();

  // for applying form validations on 'HOT' outcome
  const [nofillData, setNofillData] = useState<any>({
    activity: props?.item?.activity ? props?.item?.activity : "",
    followUpDate: props?.item?.followUpDate ? props?.item?.followUpDate : "",
    followUpTime: props?.item?.followUpTime ? props?.item?.followUpTime : "",
    outcome: props?.item?.outcome ? props?.item?.outcome : undefined,
    courseCategory: props?.item?.courseCategory
      ? props?.item?.courseCategory
      : "",
    status: props?.item?.status ? props?.item?.status : "",
    universityName: props?.item?.universityName
      ? props?.item?.universityName
      : "",
    facultyDepartments: props?.item?.facultyDepartments
      ? props?.item?.facultyDepartments
      : "",
    programmeName: props?.item?.programmeName ? props?.item?.programmeName : "",
    specialization: props?.item?.specialization
      ? props?.item?.specialization
      : "",
    mode: props?.item?.mode ? props?.item?.mode : "",
    noOfYear_Semester: props?.item?.noOfYear_Semester
      ? props?.item?.noOfYear_Semester
      : "",
    selectcourse_Programme: props?.item?.selectcourse_Programme
      ? props?.item?.selectcourse_Programme
      : "",
    selectcourse_Programme_from: props?.item?.selectcourse_Programme_from
      ? props?.item?.selectcourse_Programme_from
      : "",
    selectcourse_Programme_to: props?.item?.selectcourse_Programme_to
      ? props?.item?.selectcourse_Programme_to
      : "",
    tutionFees: props?.item?.tutionFees ? props?.item?.tutionFees : "",
    otherFees: props?.item?.otherFees ? props?.item?.otherFees : "",
    discount: props?.item?.discount ? props?.item?.discount : "",
    totalFees: props?.item?.totalFees ? props?.item?.totalFees : "",
    universityDescription: props?.item?.universityDescription
      ? props?.item?.universityDescription
      : "",
    subject: props?.item?.subject ? props?.item?.subject : undefined,
    location: props?.item?.location ? props?.item?.location : undefined,
    scheduleDate: props?.item?.scheduleDate
      ? props?.item?.scheduleDate
      : undefined,
    scheduleTime: props?.item?.scheduleTime
      ? props?.item?.scheduleTime
      : undefined,
    timeZone: props?.item?.timeZone ? props?.item?.timeZone : undefined,
    remindTime: props?.item?.remindTime ? props?.item?.remindTime : undefined,
    taskDescription: props?.item?.taskDescription
      ? props?.item?.taskDescription
      : undefined,
  });

  const [data, setData] = useState<any>({
    activity: props?.item?.activity ? props?.item?.activity : "",
    activityDate: "",
    activityTime: "",
    followUpDate: props?.item?.followUpDate ? props?.item?.followUpDate : "",
    followUpTime: props?.item?.followUpTime ? props?.item?.followUpTime : "",
    outcome: props?.item?.outcome ? props?.item?.outcome : undefined,
    amount: props?.item?.amount ? props?.item?.amount : undefined,
    receiptNo: props?.item?.receiptNo ? props?.item?.receiptNo : undefined,
    file: undefined,
    courseCategory: props?.item?.courseCategory
      ? props?.item?.courseCategory
      : "",
    status: props?.item?.status ? props?.item?.status : "",
    universityName: props?.item?.universityName
      ? props?.item?.universityName
      : "",
    facultyDepartments: props?.item?.facultyDepartments
      ? props?.item?.facultyDepartments
      : "",
    programmeName: props?.item?.programmeName ? props?.item?.programmeName : "",
    specialization: props?.item?.specialization
      ? props?.item?.specialization
      : "",
    mode: props?.item?.mode ? props?.item?.mode : "",
    noOfYear_Semester: props?.item?.noOfYear_Semester
      ? props?.item?.noOfYear_Semester
      : "",
    selectcourse_Programme: props?.item?.selectcourse_Programme
      ? props?.item?.selectcourse_Programme
      : "",
    selectcourse_Programme_from: props?.item?.selectcourse_Programme_from
      ? props?.item?.selectcourse_Programme_from
      : "",
    selectcourse_Programme_to: props?.item?.selectcourse_Programme_to
      ? props?.item?.selectcourse_Programme_to
      : "",
    tutionFees: props?.item?.tutionFees ? props?.item?.tutionFees : "",
    otherFees: props?.item?.otherFees ? props?.item?.otherFees : "",
    discount: props?.item?.discount ? props?.item?.discount : 0,
    totalFees: props?.item?.totalFees ? props?.item?.totalFees : "",
    universityDescription: props?.item?.universityDescription
      ? props?.item?.universityDescription
      : "",
    subject: props?.item?.subject ? props?.item?.subject : undefined,
    location: props?.item?.location ? props?.item?.location : undefined,
    scheduleDate: props?.item?.scheduleDate
      ? props?.item?.scheduleDate
      : undefined,
    scheduleTime: props?.item?.scheduleTime
      ? props?.item?.scheduleTime
      : undefined,
    timeZone: props?.item?.timeZone ? props?.item?.timeZone : undefined,
    remindTime: props?.item?.remindTime ? props?.item?.remindTime : undefined,
    taskDescription: props?.item?.taskDescription
      ? props?.item?.taskDescription
      : undefined,
  });

  const [snackMessage, setSnackMessage] = useState<string>("");
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [openSnackBar, setOpenSnackBar] = useState<boolean>(false);
  const [show, setShow] = useState<string>("");

  // open close form click
  const [open, setOpen] = useState<boolean>(true);
  const [open1, setOpen1] = useState<boolean>(false);
  const [open2, setOpen2] = useState<boolean>(false);

  // hiding showing based on selection
  const [activity, setActivity] = useState<string>("");

  // to store fetched universities data from backend
  const [universities, setUniversities] = useState<any>([]);

  // filtered universities according to course category selection
  const [currentUniversities, setCurrentUniversities] = useState<any[]>(
    []
  );

  // to store static data for form
  const [staticData, setStaticData] = useState<any[]>([]);

  // to display the dropdownsdata dynamically
  const [pickedUniversity, setPickedUniversity] = useState<any>();
  const [courseData, setCourseData] = useState<any>([]);
  const [specializationData, setSpecializationData] = useState<any>([]);
  const [modeData, setModeData] = useState<any>([]);
  const [outcome, setOutcome] = useState<number>();
  const [outcomeData, setOutcomeData] = useState<any[]>([]);

  // current date and time function value
  const [date, setDate] = useState(new Date());

  // state to hold image url
  const [imageData, setImageData] = useState<string>(props?.item?.file?.path);

  // state for image validation errors
  const [fileError, setFileError] = useState<string>("");

  // handler to manage diplaying snackbar
  const handleOpenSnackBar = () => {
    setOpenSnackBar(true);
    setTimeout(() => {
      setOpenSnackBar(false);
    }, 3000);
  };

  // function to validate image
  const validationHandler = async (e: any, alterName?: any) => {
    const id = alterName;
    const selectedFile = e.target.files[0];
    if (id) {
      let prom = new Promise((resolve) => {
        if (true) {
          resolve(eventHandler(id, selectedFile));
        }
      });
      prom.then((res: any) => setFileError(res));
    }
  };

  // function call when image selection is changed
  const handleImageInputChange = async (event: any) => {
    const file = event.target.files[0];
    if (file) {
      const imageUrl = URL.createObjectURL(file);
      setImageData(imageUrl);
    }
  };

  // function called on onChange event of image input element
  const handleImageChange = (e: any) => {
    setData({
      ...data,
      [e.target.name]: e.target.files[0],
    });
    handleImageInputChange(e);
  };

  // function called on add button to add activity
  const postActivity = async () => {
    if (fileError !== "") {
      setSnackMessage(fileError);
      handleOpenSnackBar();
      return "";
    }
    // Check if any field is empty
    const isAnyFieldEmpty = Object.values(nofillData).some(
      (value) => value === ""
    );
    if (data.outcome === "Hot") {
      if (isAnyFieldEmpty) {
        setSnackMessage("Please fill required fields");
        handleOpenSnackBar();
      } else {
        try {
          if (props?.item?.activityObjectId) {
            setIsLoading(true);
            let response = await updateRequest(
              PUSH_ACTIVITY,
              props?.item?.activityObjectId,
              data,
              "multipart/form-data"
            );
            setIsLoading(false);
            if (response) {
              setSnackMessage("Data saved Successfully..!");
              handleOpenSnackBar();
              setTimeout(() => {
                navigate("/dashboard/ManageAssignedLead");
              }, 3000);
            } else {
              setSnackMessage("Data not saved!");
              handleOpenSnackBar();
            }
          } else {
            setIsLoading(true);
            const id = `${location?.state?.item?._id}/${location?.state?.lId}`;
            let response = await updateRequest(
              ADD_ACTIVITY,
              id,
              data,
              "multipart/form-data"
            );
            setIsLoading(false);
            if (response) {
              setSnackMessage("Data saved Successfully..!");
              handleOpenSnackBar();
              setTimeout(() => {
                navigate("/dashboard/ManageAssignedLead");
              }, 3000);
            } else {
              setSnackMessage("Data not saved!");
              handleOpenSnackBar();
            }
          }
        } catch (error) {
          console.error("Error updating data:", error);
        }
      }
    } else {
      try {
        if (props?.item?.activityObjectId) {
          setIsLoading(true);
          let response = await updateRequest(
            PUSH_ACTIVITY,
            props?.item?.activityObjectId,
            data,
            "multipart/form-data"
          );
          setIsLoading(false);
          if (response) {
            setSnackMessage("Data saved Successfully..!");
            handleOpenSnackBar();
            setTimeout(() => {
              navigate("/dashboard/ManageAssignedLead");
            }, 3000);
          } else {
            setSnackMessage("Data not saved!");
            handleOpenSnackBar();
          }
        } else {
          setIsLoading(true);
          const id = `${location?.state?.item?._id}/${location?.state?.lId}`;
          let response = await updateRequest(
            ADD_ACTIVITY,
            id,
            data,
            "multipart/form-data"
          );
          setIsLoading(false);
          if (response) {
            setSnackMessage("Data saved Successfully..!");
            handleOpenSnackBar();
            setTimeout(() => {
              navigate("/dashboard/ManageAssignedLead");
            }, 3000);
          } else {
            setSnackMessage("Data not saved!");
            handleOpenSnackBar();
          }
        }
      } catch (error) {
        console.error("Error updating data:", error);
      }
    }
  };

  // function to set values in state
  const setValue = async (e: any) => {
    //Logic to set fetched universities in state
    if (e.target.name === "courseCategory") {
      let arr: any = [];
      universities.forEach((obj: any) => {
        for (let i = 0; i < obj.courseCategory.length; i++) {
          if (obj.courseCategory[i].type === e.target.value) {
            arr.push(obj);
            break;
          }
        }
      });
      setCurrentUniversities(arr);
    }

    //Logic to update/store selected values in state
    activity === "Capture Payment" && e.target.name === "file"
      ? setData({ ...data, [e.target.name]: e.target.files[0] })
      : setData({ ...data, [e.target.name]: e.target.value });

    activity === "Capture Payment" && e.target.name === "file"
      ? setNofillData({ ...data, [e.target.name]: e.target.files[0] })
      : setNofillData({ ...data, [e.target.name]: e.target.value });
  };

  // function to calaculte and set discounted final value
  const setDiscountedValue = () => {
    let tutionFeeBeforeDiscount: number = Number(data.tutionFees);
    let otherFee: number = Number(data.otherFees);
    let discount: number = Number(data.discount);
    let feeAfterDiscount: number =
      (tutionFeeBeforeDiscount * (100 - discount)) / 100 + otherFee;
    setData({ ...data, totalFees: feeAfterDiscount });
    setNofillData({ ...data, totalFees: feeAfterDiscount });
  };

  // function to handle on activity-type selection dynamic fields
  const handleActivityTypeChange = (
    event: SelectChangeEvent<typeof activity>,
    children: any
  ) => {
    const { id } = children.props;
    setActivity(event.target.value);
    setShow(event.target.value);
    setOutcome(id);
    if (event.target.value !== "Capture Payment") {
      setData({
        ...data,
        file: undefined,
        amount: undefined,
        receiptNo: undefined,
        [event.target.name]: event.target.value,
      });
      setNofillData({
        ...data,
        file: undefined,
        amount: undefined,
        receiptNo: undefined,
        [event.target.name]: event.target.value,
      });
    } else {
      setData({
        ...data,
        outcome: undefined,
        [event.target.name]: event.target.value,
      });
      setNofillData({
        ...data,
        outcome: undefined,
        [event.target.name]: event.target.value,
      });
    }
  };

  // function to fetch static data for dropdowns and university details
  async function fetchDataForDropdowns() {
    const res = await getRequest(STATIC_DATA, "");
    if (res) setStaticData(res.data);
    const resp = await getRequest(GET_UNIVERSITY, "");
    if (resp) setUniversities(resp.data);
  }

  // to set the data of dropdowns dynamically
  const HandleDropdownChange = async (e: any, children: any) => {
    const { value, name } = e.target;
    const { id } = children.props;
    switch (name) {
      case "universityName":
        setData({ ...data, [name]: value });
        setNofillData({ ...data, [name]: value });
        const university = await currentUniversities?.find(
          (item: any) => item.uId === id
        );
        setPickedUniversity(university);
        setCourseData([]);
        setSpecializationData([]);
        setModeData([]);
        break;
      case "facultyDepartments":
        setCourseData([]);
        setData({ ...data, [name]: value });
        setNofillData({ ...data, [name]: value });
        const courses = await pickedUniversity?.course?.filter(
          (item: any) => item.dId === id
        );
        setCourseData(courses);
        setSpecializationData([]);
        setModeData([]);
        break;
      case "programmeName":
        setSpecializationData([]);
        setData({ ...data, [name]: value });
        setNofillData({ ...data, [name]: value });
        const specializations = await pickedUniversity?.specialization?.filter(
          (item: any) => item.cId === id
        );
        setSpecializationData(specializations);
        setModeData([]);
        break;
      case "specialization":
        setModeData([]);
        setData({ ...data, [name]: value });
        setNofillData({ ...data, [name]: value });
        const modes = await pickedUniversity?.mode?.filter(
          (item: any) => item?.sId === id
        );
        setModeData(modes);
        const noOfYearsorSems =
          await pickedUniversity.noOfSemsOrNoOfYears?.filter(
            (item: any) => item.nSYId === id
          );
        const tuitionFees = await pickedUniversity?.tuitionFee?.filter(
          (item: any) => item.tuId === id
        );
        const otherFees = await pickedUniversity?.otherFee?.filter(
          (item: any) => item.oId === id
        );
        const totalFees = await pickedUniversity?.totalFee?.filter(
          (item: any) => item.tfId === id
        );
        setData({
          ...data,
          [name]: value,
          ["noOfYear_Semester"]: noOfYearsorSems[0]["value"],
          ["tutionFees"]: tuitionFees[0]["value"],
          ["otherFees"]: otherFees[0]["value"],
          ["totalFees"]: totalFees[0]["value"],
        });
        setNofillData({
          ...data,
          [name]: value,
          ["noOfYear_Semester"]: noOfYearsorSems[0]["value"],
          ["tutionFees"]: tuitionFees[0]["value"],
          ["otherFees"]: otherFees[0]["value"],
          ["totalFees"]: totalFees[0]["value"],
        });
        break;
      default:
        console.log("Inside Default Block!");
    }
  };

  useEffect(() => {
    fetchDataForDropdowns();
  }, []);

  useEffect(() => {
    if (props?.item?.activity) {
      setShow(props?.item?.activity);
    }
  }, [props]);

  useEffect(() => {
    let outcomeList = staticData[0]?.outcome?.filter(
      (text: any) => text.id === outcome
    );
    setOutcomeData(outcomeList);
  }, [outcome]);

  useEffect(() => {
    let filteredUniversities = universities?.filter((university: any) => {
      let flag = university.courseCategory.filter(
        (object: any) => object.type === props?.item?.courseCategory
      );
      if (flag) {
        return true;
      }
      return false;
    });
    setCurrentUniversities(filteredUniversities);
    staticData[0]?.activityType?.map((object: any) => {
      if (object.value === props?.item?.activity) {
        let outcomeList = staticData[0]?.outcome?.filter(
          (obj: any) => object.id === obj.id
        );
        setOutcomeData(outcomeList);
      }
    });
  }, [universities, props]);

  useEffect(() => {
    let pickedUni = currentUniversities?.find(
      (university: any) =>
        university.universityName === props?.item?.universityName
    );
    setPickedUniversity(pickedUni);
  }, [currentUniversities]);

  useEffect(() => {
    let cId: any;
    let sId: any;
    pickedUniversity?.department?.map((object: any) => {
      if (object.name === props?.item?.facultyDepartments) {
        let filteredCourses = pickedUniversity?.course?.filter((obj: any) => {
          if (obj.dId === object.dId) {
            cId = obj.cId;
            return true;
          }
        });
        setCourseData(filteredCourses);
      }
    });
    let specializations = pickedUniversity?.specialization?.filter(
      (object: any) => {
        if (object.cId == cId) {
          sId = object.sId;
          return true;
        }
      }
    );
    setSpecializationData(specializations);
    let modes = pickedUniversity?.mode?.filter(
      (object: any) => object.sId == sId
    );
    setModeData(modes);
  }, [pickedUniversity]);

  useEffect(() => {
    const timer = setInterval(() => setDate(new Date()), 1000);
    return function cleanup() {
      clearInterval(timer);
    };
  }, []);

  useEffect(() => {
    let currentDate = date.toISOString().split("T")[0];
    let hours = date.getHours().toString().padStart(2, "0");
    let minutes = date.getMinutes().toString().padStart(2, "0");
    setData({
      ...data,
      activityDate: currentDate,
      activityTime: `${hours}:${minutes}`,
    });
  }, [date]);

  return (
    <>
      <Container component="main" maxWidth="lg">
        <Box
          component="form"
          encType="multipart/form-data"
          sx={{
            marginTop: 8,
            marginBottom: 8,
            boxShadow: "2",
            padding: "5%",
            backgroundColor: "white",
          }}
        >
          {isLoading && <LinearProgress />}
          <Box sx={{ mb: 2 }}>
            <IconButton
              aria-label="expand row"
              size="small"
              onClick={() => setOpen(!open)}
            >
              {open ? <KeyboardArrowUpIcon /> : <KeyboardArrowDownIcon />}
            </IconButton>
            <Typography fontWeight="bold">Activity Log</Typography>
          </Box>
          <Collapse in={open} timeout="auto" unmountOnExit>
            <Grid container spacing={2}>
              <Grid item xs={12} md={2}>
                <Typography> Activity Type :</Typography>
              </Grid>
              <Grid item xs={12} md={10}>
                <FormControl fullWidth>
                  <Select
                    id="demo-controlled-open-select"
                    value={data.activity}
                    onChange={handleActivityTypeChange}
                    displayEmpty
                    name="activity"
                    inputProps={{ "aria-label": "Without label" }}
                  >
                    {staticData[0]?.activityType?.map((item: any) => (
                      <MenuItem value={item.value} key={item._id} id={item.id}>
                        {item.value}
                      </MenuItem>
                    ))}
                  </Select>
                </FormControl>
              </Grid>

              {/* activity date */}
              <Grid item xs={12} md={2}>
                <Typography> Activity Date :</Typography>
              </Grid>
              <Grid item xs={12} md={4}>
                <Stack direction={{ xs: "column", md: "row" }} spacing={2}>
                  <TextField
                    InputLabelProps={{ shrink: true }}
                    id="outlined-date-input"
                    label="Date"
                    value={data.activityDate}
                    type="date"
                    autoComplete="current-date"
                    name="activityDate"
                    onChange={setValue}
                    disabled
                  />
                  <TextField
                    InputLabelProps={{ shrink: true }}
                    id="outlined-time-input"
                    label="Time"
                    type="time"
                    value={data.activityTime}
                    autoComplete="current-time"
                    name="activityTime"
                    onChange={setValue}
                    disabled
                  />
                </Stack>
              </Grid>

              {/* follow up date */}
              <Grid item xs={12} md={2}>
                <Typography>Next Follow-up Date:</Typography>
              </Grid>
              <Grid item xs={12} md={4}>
                <Stack direction={{ xs: "column", md: "row" }} spacing={2}>
                  <TextField
                    InputLabelProps={{ shrink: true }}
                    id="outlined-date-input"
                    label="Date"
                    type="date"
                    value={data.followUpDate}
                    autoComplete="current-date"
                    name="followUpDate"
                    onChange={setValue}
                  />
                  <TextField
                    InputLabelProps={{ shrink: true }}
                    id="outlined-time-input"
                    label="Time"
                    type="time"
                    value={data.followUpTime}
                    autoComplete="current-time"
                    name="followUpTime"
                    onChange={setValue}
                  />
                </Stack>
              </Grid>

              {/* Capture payment */}
              {show === "Capture Payment" ? (
                <>
                  <Grid item xs={12} md={2}>
                    <Typography> Amount Received:</Typography>
                  </Grid>
                  <Grid item xs={12} md={4}>
                    <FormControl fullWidth>
                      <TextField
                        type="number"
                        id="outlined-basic"
                        variant="outlined"
                        name="amount"
                        value={data.amount}
                        onChange={setValue}
                      />
                    </FormControl>
                  </Grid>
                  <Grid item xs={12} md={2}>
                    <Typography>Receipt Number:</Typography>
                  </Grid>
                  <Grid item xs={12} md={4}>
                    <FormControl fullWidth>
                      <TextField
                        type="number"
                        id="outlined-basic"
                        variant="outlined"
                        name="receiptNo"
                        value={data.receiptNo}
                        onChange={setValue}
                      />
                    </FormControl>
                  </Grid>
                  <Grid item xs={12} md={2}>
                    <Typography> Upload Received:</Typography>
                  </Grid>
                  <Grid item xs={12} md={4}>
                    <FormControl fullWidth>
                      <TextField
                        type="file"
                        id="outlined-basic"
                        variant="outlined"
                        name="file"
                        error={Boolean(fileError)}
                        helperText={fileError}
                        inputProps={{ accept: "image/*" }}
                        onChange={(e) => {
                          handleImageChange(e);
                          validationHandler(e, "imageSize");
                        }}
                      />
                      <img
                        src={imageData}
                        alt={"payment receipt"}
                        style={{ height: 150, marginTop: 10 }}
                      />
                    </FormControl>
                  </Grid>
                </>
              ) : (
                <>
                  <Grid item xs={12} md={2}>
                    <Typography> Outcome :</Typography>
                  </Grid>
                  <Grid item xs={12} md={4}>
                    <FormControl fullWidth>
                      <Select
                        displayEmpty
                        inputProps={{ "aria-label": "Without label" }}
                        name="outcome"
                        value={data.outcome}
                        onChange={setValue}
                      >
                        {outcomeData?.map((options: any) => (
                          <MenuItem value={options.value} key={options._id}>
                            {options.value}
                          </MenuItem>
                        ))}
                      </Select>
                    </FormControl>
                  </Grid>
                </>
              )}
            </Grid>
          </Collapse>
          <Box sx={{ mb: 2 }}>
            <IconButton
              aria-label="expand row"
              size="small"
              onClick={() => setOpen2(!open2)}
            >
              {open2 ? <KeyboardArrowUpIcon /> : <KeyboardArrowDownIcon />}
            </IconButton>
            <Typography fontWeight="bold">Select University</Typography>
          </Box>
          <Collapse in={open2} timeout="auto" unmountOnExit>
            <Grid container spacing={2}>
              <Grid item xs={12} md={2}>
                <Typography>Course Category :</Typography>
              </Grid>
              <Grid item xs={12} md={4}>
                <FormControl fullWidth>
                  <Select
                    displayEmpty
                    value={data.courseCategory}
                    inputProps={{ "aria-label": "Without label" }}
                    name="courseCategory"
                    onChange={setValue}
                  >
                    {staticData[0]?.courseCategory?.map((text: any) => (
                      <MenuItem value={text.id} key={text._id}>
                        {text.id}
                      </MenuItem>
                    ))}
                  </Select>
                </FormControl>
              </Grid>
              <Grid item xs={12} md={2}>
                <Typography>Status :</Typography>
              </Grid>
              <Grid item xs={12} md={4}>
                <FormControl fullWidth>
                  <Select
                    displayEmpty
                    inputProps={{ "aria-label": "Without label" }}
                    value={data.status}
                    name="status"
                    onChange={setValue}
                  >
                    {staticData[0]?.status?.map((text: any) => (
                      <MenuItem value={text.id} key={text._id}>
                        {text.id}
                      </MenuItem>
                    ))}
                  </Select>
                </FormControl>
              </Grid>
              <Grid item xs={12} md={2}>
                <Typography>University Name:</Typography>
              </Grid>
              <Grid item xs={12} md={10}>
                <FormControl fullWidth>
                  <Select
                    value={data.universityName}
                    name="universityName"
                    onChange={HandleDropdownChange}
                    id="universityName"
                  >
                    {currentUniversities?.map((option: any) => (
                      <MenuItem
                        value={option.universityName}
                        key={option.uId}
                        id={option.uId}
                      >
                        {option.universityName}
                      </MenuItem>
                    ))}
                  </Select>
                </FormControl>
              </Grid>
              <Grid item xs={12} md={2}>
                <Typography>Departments/ Discipline :</Typography>
              </Grid>
              <Grid item xs={12} md={4}>
                <FormControl fullWidth>
                  <Select
                    value={data.facultyDepartments}
                    name="facultyDepartments"
                    onChange={HandleDropdownChange}
                  >
                    {pickedUniversity?.department.map((item: any) => (
                      <MenuItem value={item.name} key={item.dId} id={item.dId}>
                        {item.name}
                      </MenuItem>
                    ))}
                  </Select>
                </FormControl>
              </Grid>

              <Grid item xs={12} md={2}>
                <Typography>Programme Name :</Typography>
              </Grid>
              <Grid item xs={12} md={4}>
                <FormControl fullWidth>
                  <Select
                    value={data.programmeName}
                    name="programmeName"
                    onChange={HandleDropdownChange}
                  >
                    {courseData?.map((item: any) => (
                      <MenuItem key={item.cId} value={item.name} id={item.cId}>
                        {item.name}
                      </MenuItem>
                    ))}
                  </Select>
                </FormControl>
              </Grid>
              <Grid item xs={12} md={2}>
                <Typography>Specialization :</Typography>
              </Grid>
              <Grid item xs={12} md={4}>
                <FormControl fullWidth>
                  <Select
                    value={data.specialization}
                    name="specialization"
                    onChange={HandleDropdownChange}
                  >
                    {specializationData?.map((item: any) => (
                      <MenuItem key={item.sId} value={item.name} id={item.sId}>
                        {item.name}
                      </MenuItem>
                    ))}
                  </Select>
                </FormControl>
              </Grid>
              <Grid item xs={12} md={2}>
                <Typography> Mode :</Typography>
              </Grid>
              <Grid item xs={12} md={4}>
                <FormControl fullWidth>
                  <Select
                    value={data.mode}
                    autoComplete="given-name"
                    placeholder="mode"
                    required
                    name="mode"
                    onChange={setValue}
                  >
                    {modeData?.map((item: any) => (
                      <MenuItem key={item.mId} value={item.type} id={item.mId}>
                        {item.type}
                      </MenuItem>
                    ))}
                  </Select>
                </FormControl>
              </Grid>
              <Grid item xs={12} md={2}>
                <Typography>No.of years & Semester :</Typography>
              </Grid>
              <Grid item xs={12} md={4}>
                <FormControl fullWidth>
                  <TextField
                    value={data.noOfYear_Semester}
                    autoComplete="given-name"
                    disabled
                    placeholder="years-semister"
                    required
                    name="noOfYear_Semester"
                    onChange={setValue}
                  />
                </FormControl>
              </Grid>
              <Grid item xs={12} md={2}>
                <Typography> Select Course/ Programme Session :</Typography>
              </Grid>
              <Grid item xs={12} md={4}>
                <FormControl fullWidth>
                  <Select
                    displayEmpty
                    value={data.selectcourse_Programme}
                    inputProps={{ "aria-label": "Without label" }}
                    name="selectcourse_Programme"
                    onChange={setValue}
                  >
                    {staticData[0]?.programmeSession?.map((text: any) => (
                      <MenuItem value={text.id} key={text._id}>
                        {text.id}
                      </MenuItem>
                    ))}
                  </Select>
                </FormControl>
              </Grid>
              <Grid item xs={12} md={2}>
                <Typography>Select Course/ Programme Duration :</Typography>
              </Grid>
              <Grid item xs={12} md={4}>
                <FormControl fullWidth>
                  <TextField
                    InputLabelProps={{ shrink: true }}
                    id="outlined-month-input"
                    label="From"
                    type="date"
                    value={data.selectcourse_Programme_from}
                    autoComplete="current-date"
                    name="selectcourse_Programme_from"
                    InputProps={{
                      inputProps: {
                        min: new Date().toISOString().slice(0, 10),
                      },
                    }}
                    onChange={setValue}
                  />
                </FormControl>
              </Grid>
              <Grid item xs={12} md={2}></Grid>
              <Grid item xs={12} md={4}>
                <FormControl fullWidth>
                  <TextField
                    InputLabelProps={{ shrink: true }}
                    id="outlined-month-input"
                    label="To"
                    type="date"
                    value={data.selectcourse_Programme_to}
                    autoComplete="current-date"
                    name="selectcourse_Programme_to"
                    InputProps={{
                      inputProps: {
                        min: new Date().toISOString().slice(0, 10),
                      },
                    }}
                    onChange={setValue}
                  />
                </FormControl>
              </Grid>

              <Grid item xs={12} md={2}>
                <Typography>Tution Fees :</Typography>
              </Grid>
              <Grid item xs={12} md={4}>
                <FormControl fullWidth>
                  <TextField
                    value={data.tutionFees}
                    autoComplete="given-name"
                    placeholder="tution fees"
                    required
                    disabled
                    name="tutionFees"
                  />
                </FormControl>
              </Grid>
              <Grid item xs={12} md={2}>
                <Typography>Other Fees :</Typography>
              </Grid>
              <Grid item xs={12} md={4}>
                <FormControl fullWidth>
                  <TextField
                    value={data.otherFees}
                    autoComplete="given-name"
                    placeholder="other fees"
                    required
                    disabled
                    name="otherFees"
                  />
                </FormControl>
              </Grid>
              <Grid item xs={12} md={2}>
                <Typography> Discount :</Typography>
              </Grid>
              <Grid item xs={12} md={4}>
                <FormControl fullWidth>
                  <TextField
                    name="discount"
                    value={data.discount}
                    placeholder="discount"
                    type="number"
                    inputProps={{
                      min: 0,
                      max: 100,
                    }}
                    required
                    onChange={setValue}
                    onBlur={setDiscountedValue}
                  />
                </FormControl>
              </Grid>

              <Grid item xs={12} md={2}>
                <Typography>Total Fees :</Typography>
              </Grid>
              <Grid item xs={12} md={4}>
                <FormControl fullWidth>
                  <TextField
                    value={data.totalFees}
                    autoComplete="given-name"
                    placeholder="total fees"
                    required
                    disabled
                    name="totalFees"
                    onChange={setValue}
                  />
                </FormControl>
              </Grid>

              <Grid item container spacing={2}>
                <Grid item xs={12} md={2}>
                  <Typography>Description :</Typography>
                </Grid>
                <Grid item xs={12} md={10}>
                  <FormControl fullWidth>
                    <TextField
                      aria-label="minimum height"
                      minRows={5}
                      placeholder="comment"
                      multiline
                      value={data.universityDescription}
                      name="universityDescription"
                      onChange={setValue}
                    />
                  </FormControl>
                </Grid>
              </Grid>
            </Grid>
          </Collapse>
          <Box sx={{ mb: 2 }}>
            <IconButton
              aria-label="expand row"
              size="small"
              onClick={() => {
                setOpen1(!open1);
              }}
            >
              {open ? <KeyboardArrowUpIcon /> : <KeyboardArrowDownIcon />}
            </IconButton>
            <Typography fontWeight="bold">Add Task</Typography>
          </Box>
          <Collapse in={open1} timeout="auto" unmountOnExit>
            <Grid container spacing={2}>
              <Grid item xs={12} md={2}>
                <Typography>Subject :</Typography>
              </Grid>
              <Grid item xs={12} md={10}>
                <TextField
                  autoComplete="given-name"
                  fullWidth
                  placeholder="Meeting with nitesh"
                  required
                  value={data.subject}
                  name="subject"
                  onChange={setValue}
                />
              </Grid>
              <Grid item xs={12} md={2}>
                <Typography> Location :</Typography>
              </Grid>
              <Grid item xs={12} md={10}>
                <TextField
                  autoComplete="given-name"
                  fullWidth
                  id="ResultTitle"
                  required
                  value={data.location}
                  name="location"
                  onChange={setValue}
                />
              </Grid>

              <Grid item xs={12} md={2}>
                <Typography>Schedule :</Typography>
              </Grid>
              <Grid item xs={12} md={5}>
                <Stack direction={{ xs: "column", md: "row" }} spacing={4}>
                  <TextField
                    InputLabelProps={{ shrink: true }}
                    id="outlined-date-input"
                    label="Date"
                    type="date"
                    autoComplete="current-date"
                    value={data.scheduleDate}
                    name="scheduleDate"
                    onChange={setValue}
                  />
                  <TextField
                    InputLabelProps={{ shrink: true }}
                    id="outlined-time-input"
                    label="Time"
                    type="time"
                    value={data.scheduleTime}
                    autoComplete="current-time"
                    name="scheduleTime"
                    onChange={setValue}
                  />
                </Stack>
              </Grid>
              <Grid item xs={12} md={5}>
                <Stack direction={{ xs: "column", md: "row" }} spacing={2}>
                  <FormControl>
                    <Select
                      displayEmpty
                      inputProps={{ "aria-label": "Without label" }}
                      value={data.timeZone}
                      name="timeZone"
                      onChange={setValue}
                    >
                      {["IST", "GMT"].map((text, index) => (
                        <MenuItem value={text} key={index}>
                          {text}
                        </MenuItem>
                      ))}
                    </Select>
                  </FormControl>

                  <FormControlLabel control={<Checkbox />} label="Remind" />
                  <FormControl>
                    <Select
                      displayEmpty
                      inputProps={{ "aria-label": "Without label" }}
                      value={data.remindTime}
                      name="remindTime"
                      onChange={setValue}
                    >
                      {["5 mins", "10 mins", "15 mins"].map((text, index) => (
                        <MenuItem value={text} key={index}>
                          {text}
                        </MenuItem>
                      ))}
                    </Select>
                  </FormControl>
                </Stack>
              </Grid>

              <Grid item xs={12} md={2}>
                <Typography>Description :</Typography>
              </Grid>
              <Grid item xs={12} md={10}>
                <FormControl fullWidth>
                  <TextField
                    aria-label="minimum height"
                    minRows={5}
                    placeholder="comment"
                    multiline
                    value={data.taskDescription}
                    name="taskDescription"
                    onChange={setValue}
                  />
                </FormControl>
              </Grid>
            </Grid>
          </Collapse>
          <Grid item xs={12} md={6} sx={{ mt: "30px" }}>
            <Button
              variant="contained"
              sx={{ mr: "30px" }}
              onClick={() => {
                window.scrollTo(0, 0);
                postActivity();
              }}
            >
              Add
            </Button>
            <CommonSnackBar message={snackMessage} open={openSnackBar} />
            <Button
              type="submit"
              variant="contained"
              onClick={() => {
                setOpen1(!open1);
                navigate(-1);
              }}
            >
              Cancel
            </Button>
          </Grid>
        </Box>
      </Container>
    </>
  );
}

export default Activity;
