import AutoStoriesIcon from "@mui/icons-material/AutoStories";
import HistoryIcon from "@mui/icons-material/History";
import RateReviewIcon from "@mui/icons-material/RateReview";
import SchoolIcon from "@mui/icons-material/School";
import TipsAndUpdatesIcon from "@mui/icons-material/TipsAndUpdates";
import WorkspacePremiumIcon from "@mui/icons-material/WorkspacePremium";
import {
  Button,
  Grid,
  ImageList,
  ImageListItem,
  Stack,
  Typography,
} from "@mui/material";
import Box from "@mui/material/Box";
import Paper from "@mui/material/Paper";
import { styled } from "@mui/material/styles";
import { makeStyles } from "@mui/styles";
import { Container } from "@mui/system";
import { useNavigate } from "react-router-dom";

const Item = styled(Paper)(({ theme }: any) => ({
  backgroundColor: theme.palette.mode === "dark" ? "#1A2027" : "#fff",
  ...theme.typography.body2,
  padding: theme.spacing(2),
  textAlign: "center",
  color: theme.palette.text.secondary,
}));

const useStyles = makeStyles(() => ({
  animatedCircle: {
    borderRadius: "50%",
    border: `2px solid #0000ff`,
    padding: "20px",
    marginLeft: "-7px",
    width: "70px",
    height: "70px",
    position: "relative",
  },
  animatedTypography: {
    animation: `$colorAnimation 4s infinite linear`,
  },
  "@keyframes colorAnimation": {
    "0%": {
      color: `#1E90FF`,
    },
    "25%": {
      color: `#4169E1`,
    },
    "50%": {
      color: `#0000CD`,
    },
    "75%": {
      color: `#4682B4`,
    },
    "100%": {
      color: `#6495ED`,
    },
  },
}));

function Counselling() {
  const classes = useStyles();
  const navigate = useNavigate();

  return (
    <>
      <Container maxWidth="lg">
        <Typography
          variant="h6"
          sx={{ color: "blue", textAlign: "start", mt: 5 }}
        >
          Career guidance
        </Typography>
        <Typography
          variant="h4"
          sx={{ textAlign: "start", fontWeight: "500", color: "#191970" }}
        >
          Counselling Procedures!
        </Typography>
        <Grid container spacing={2}>
          <Grid item xs={12} sm={12} md={6}>
            <Typography variant="subtitle2" sx={{ color: "#666" }}>
              Don’t know how to target top University in India ? Use our study
              in India Education counselling services.
            </Typography>
            <div className="MuiMenuUnstyled-root">
              <ul className="MuiMenuUnstyled-listbox">
                <li className="MuiMenuItemUnstyled-root">
                  <Typography
                    variant="body2"
                    sx={{ fontFamily: "'Nunito Sans', sans-serif" }}
                  >
                    All the counselors are very well qualified. They all have
                    gone through the application process themselves, which makes
                    them even more suited for providing guidance.
                  </Typography>
                </li>
                <br />
                <li className="MuiMenuItemUnstyled-root">
                  <Typography
                    variant="body2"
                    sx={{ fontFamily: "'Nunito Sans', sans-serif" }}
                  >
                    Your personalized one-hour counseling session will be
                    conducted on call, mail or Skype. You can use the hour to
                    discuss any of the mentioned here.
                  </Typography>
                </li>
                <br />
                <li className="MuiMenuItemUnstyled-root">
                  <Typography
                    variant="body2"
                    sx={{ fontFamily: "'Nunito Sans', sans-serif" }}
                  >
                    Prerna Academy experts will evaluate your profile,
                    background and help align your interests and goals with the
                    right fit of colleges.
                  </Typography>
                </li>
                <br />
                <li className="MuiMenuItemUnstyled-root">
                  <Typography
                    variant="body2"
                    sx={{ fontFamily: "'Nunito Sans', sans-serif" }}
                  >
                    Prerna Academy provides counselling, consultation,
                    coordination and advocacy services to all students, parents
                    and the college community. Our Counsellor works in
                    preventive, curative and developmental mode how to view
                    followers on facebook page. If there is a need, the
                    Counsellor meets and extends the support of parents and
                  </Typography>
                </li>
              </ul>
            </div>
          </Grid>

          <Grid item xs={12} sm={12} md={6}>
            <ImageList sx={{ width: "100%", height: "100%" }} cols={1}>
              <ImageListItem>
                <img
                  src="https://ik.imagekit.io/nwssoft/Home_Page/councelling.png?updatedAt=1689833275333"
                  loading="lazy"
                  alt="Image 1"
                />
              </ImageListItem>
            </ImageList>
          </Grid>
        </Grid>

        <Box
          sx={{
            mt: 2,
          }}
        >
          <Grid
            container
            rowSpacing={2}
            columnSpacing={{ xs: 2, sm: 2, md: 4 }}
            sx={{ mt: 3 }}
          >
            <Grid item sm={12} md={6}>
              <Item
                sx={{ borderRadius: "90px", color: "purple", padding: 0.7 }}
              >
                <Grid item sm={12}>
                  <Stack direction="row">
                    <Grid
                      display="grid"
                      alignItems="center"
                      sx={{
                        borderRadius: "80px ",
                        border: "2px solid #880e4f",
                        bgcolor: "#fff",
                        color: "blue",
                        padding: "20px",
                        marginLeft: "-7px",
                        fontSize: "2rem",
                      }}
                      className={classes.animatedCircle}
                    >
                      <RateReviewIcon />
                    </Grid>

                    <Stack direction="column">
                      <Typography
                        variant="subtitle2"
                        sx={{ fontWeight: "bold" }}
                        className={classes.animatedTypography}
                      >
                        Profile Evaluation with Detailed Feedback
                      </Typography>
                      <Typography
                        sx={{ color: "#152238", fontSize: "0.7rem" }}
                        variant="body2"
                      >
                        Profile evaluation is that an expert provides you with
                        feedback about whether you meet the standards
                        requirements for admission to higher education admission
                        programs.
                      </Typography>
                    </Stack>
                  </Stack>
                </Grid>
              </Item>
            </Grid>

            <Grid item sm={12} md={6}>
              <Item
                sx={{ borderRadius: "90px", color: "purple", padding: 0.7 }}
              >
                <Grid item sm={12}>
                  <Stack direction="row">
                    <Grid
                      display="grid"
                      alignItems="center"
                      sx={{
                        borderRadius: "80px ",
                        border: "2px solid #0000ff",
                        bgcolor: "#fff",
                        color: "blue",
                        padding: "20px",
                        marginLeft: "-7px",
                        fontSize: "2rem",
                      }}
                      className={classes.animatedCircle}
                    >
                      <SchoolIcon />
                    </Grid>

                    <Stack direction="column">
                      <Typography
                        variant="subtitle2"
                        sx={{ fontWeight: "bold" }}
                        className={classes.animatedTypography}
                      >
                        Academic Options and Pre-requisites
                      </Typography>
                      <Typography
                        variant="body2"
                        sx={{ color: "#152238", fontSize: "0.7rem" }}
                      >
                        AO is a practice of Independent Consultants specializing
                        in therapeutic and educational. Founded in 2006, AO has
                        assisted thousands of families from all over the world.
                      </Typography>
                    </Stack>
                  </Stack>
                </Grid>
              </Item>
            </Grid>

            <Grid item sm={12} md={6}>
              <Item
                sx={{ borderRadius: "90px", color: "purple", padding: 0.7 }}
              >
                <Grid item sm={12} md={12} lg={12}>
                  <Stack direction="row">
                    <Stack direction="column">
                      <Typography
                        variant="subtitle2"
                        sx={{ fontWeight: "bold" }}
                        className={classes.animatedTypography}
                      >
                        Career Planning & Guidance
                      </Typography>
                      <Typography
                        variant="body2"
                        sx={{ color: "#152238", fontSize: "0.7rem" }}
                      >
                        Career planning is designed to help you find and succeed
                        in a career that you love and that meets your needs.
                      </Typography>
                    </Stack>
                    <Grid
                      display="grid"
                      alignItems="center"
                      sx={{
                        borderRadius: "80px ",
                        border: "2px solid #0000ff",
                        bgcolor: "#fff",
                        color: "blue",
                        padding: "20px",
                        marginRight: "-7px",
                        fontSize: "2rem",
                      }}
                    >
                      <AutoStoriesIcon />
                    </Grid>
                  </Stack>
                </Grid>
              </Item>
            </Grid>

            <Grid item sm={12} md={6}>
              <Item
                sx={{ borderRadius: "90px", color: "purple", padding: 0.7 }}
              >
                <Grid item sm={12}>
                  <Stack direction="row">
                    <Stack direction="column">
                      <Typography
                        variant="subtitle2"
                        sx={{ fontWeight: "bold" }}
                        className={classes.animatedTypography}
                      >
                        Next Steps with a Realistic Time-Line
                      </Typography>
                      <Typography
                        variant="body2"
                        sx={{ color: "#152238", fontSize: "0.7rem" }}
                      >
                        To estimate a realistic project timeline, ask our team
                        members how much time they will need to complete each
                        task.
                      </Typography>
                    </Stack>
                    <Grid
                      display="grid"
                      alignItems="center"
                      sx={{
                        borderRadius: "80px ",
                        border: "2px solid #0000ff",
                        bgcolor: "#fff",
                        color: "blue",
                        padding: "20px",
                        marginRight: "-7px",
                        fontSize: "2rem",
                      }}
                    >
                      <HistoryIcon />
                    </Grid>
                  </Stack>
                </Grid>
              </Item>
            </Grid>

            <Grid item sm={12} md={6}>
              <Item
                sx={{ borderRadius: "90px", color: "purple", padding: 0.7 }}
              >
                <Grid item sm={12}>
                  <Stack direction="row">
                    <Grid
                      display="grid"
                      alignItems="center"
                      sx={{
                        borderRadius: "80px ",
                        border: "2px solid #0000ff",
                        bgcolor: "#fff",
                        color: "blue",
                        padding: "20px",
                        marginLeft: "-7px",
                        fontSize: "2rem",
                      }}
                    >
                      <WorkspacePremiumIcon />
                    </Grid>

                    <Stack direction="column">
                      <Typography
                        variant="subtitle2"
                        sx={{ fontWeight: "bold" }}
                        className={classes.animatedTypography}
                      >
                        Advice on Up-skilling and Certifications
                      </Typography>
                      <Typography
                        variant="body2"
                        sx={{ color: "#152238", fontSize: "0.7rem" }}
                      >
                        To work on your hard skills throughout your career.
                        Industries change, jobs change, and those changes can
                        happen quickly.
                      </Typography>
                    </Stack>
                  </Stack>
                </Grid>
              </Item>
            </Grid>

            <Grid item sm={12} md={6}>
              <Item
                sx={{ borderRadius: "90px", color: "purple", padding: 0.7 }}
              >
                <Grid item sm={12}>
                  <Stack direction="row">
                    <Grid
                      display="grid"
                      alignItems="center"
                      sx={{
                        borderRadius: "80px ",
                        border: "2px solid #0000ff",
                        bgcolor: "#fff",
                        color: "blue",
                        padding: "20px",
                        marginLeft: "-7px",
                        fontSize: "2rem",
                      }}
                    >
                      <TipsAndUpdatesIcon />
                    </Grid>

                    <Stack direction="column">
                      <Typography
                        variant="subtitle2"
                        sx={{ fontWeight: "bold" }}
                        className={classes.animatedTypography}
                      >
                        Expert Tips for Just in Time Applicants
                      </Typography>
                      <Typography
                        variant="body2"
                        sx={{ color: "#152238", fontSize: "0.7rem" }}
                      >
                        Customer expectations must be top priority.Do this
                        effectively, however, you need solution that keeps all
                        of your inventory. More About Services
                      </Typography>
                    </Stack>
                  </Stack>
                </Grid>
              </Item>
            </Grid>

            <Grid item xs={12} sx={{ textAlign: "center", my: 2 }}>
              <Button
                variant="contained"
                sx={{ borderRadius: "90px", textTransform: "capitalize" }}
                onClick={() => {
                  navigate("/services");
                  window.scroll(0, 0);
                }}
              >
                More About Services
              </Button>
            </Grid>
          </Grid>
        </Box>
      </Container>
    </>
  );
}

export default Counselling;
