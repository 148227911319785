import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import KeyboardArrowUpIcon from "@mui/icons-material/KeyboardArrowUp";
import SearchIcon from "@mui/icons-material/Search";
import {
  Box,
  Button,
  Collapse,
  Container,
  Grid,
  IconButton,
  InputAdornment,
  TablePagination,
  Typography,
} from "@mui/material";
import Paper from "@mui/material/Paper";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import TextField from "@mui/material/TextField";
import { Fragment, useState } from "react";
import CommonSnackBar from "../../Components/Common/CommonSnackbar";
import { getRequestByParams } from "../../api/api";
import { MANAGE_ALL_RECEIPT } from "../../api/server";

// interface for date
interface FilterData {
  fromDate: string;
  toDate: string;
}

export default function ManageAllReceipt() {
  const [mappingData, setMappingData] = useState<any[]>([]);
  // for pagination
  const [page, setPage] = useState<number>(0);
  const [rowsPerPage, setRowsPerPage] = useState<number>(10);
  // for toggle
  const [open, setOpen] = useState(new Map());
  // for search bar
  const [searchApiData, setSearchApiData] = useState<any[]>([]);
  const [filterVal, setFilterVal] = useState<string>("");
  const [snackMessage, setSnackMessage] = useState<string>("");
  const [openSnackBar, setOpenSnackBar] = useState<boolean>(false);

  // date filter state
  const [filterData, setFilterData] = useState<FilterData>({
    fromDate: "",
    toDate: "",
  });

  const toggleOpen = (id: any) => {
    setOpen((prevOpen) => {
      const newOpen = new Map(prevOpen);
      newOpen.set(id, !newOpen.get(id));
      return newOpen;
    });
  };

  // for table pagination
  const handleChangePage = (event: any, newPage: any) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event: any) => {
    setRowsPerPage(+event.target.value);
    setPage(0);
  };

  const handleOpenSnackBar = () => {
    setOpenSnackBar(true);
    setTimeout(() => {
      setOpenSnackBar(false);
    }, 3000);
  };

  const validator = () => {
    for (let field in filterData) {
      if (filterData[field as keyof typeof filterData] === "") {
        return true;
      }
    }
    return false;
  };

  const getFilteredData = async () => {
    try {
      if (validator()) {
        setSnackMessage("Select date");
        return handleOpenSnackBar();
      }

      const result = await getRequestByParams(
        MANAGE_ALL_RECEIPT,
        "",
        "",
        filterData
      );

      if (result.data && result.data.length) {
        setMappingData(result?.data);
        setSearchApiData(result?.data);
      } else {
        setSnackMessage("no data found");
        handleOpenSnackBar();
      }
    } catch (error: any) {
      console.error("Error fetching data:", error.message);
    }
  };

  // for search bar
  const handleFilter = (e: any) => {
    const inputValue = e.target.value;
    setFilterVal(inputValue);
    if (inputValue === "") {
      setMappingData(searchApiData);
    } else {
      const searchValue = inputValue.toLowerCase();
      const filterResult = searchApiData.filter((item) => {
        return Object.values(item).some((value) => {
          if (typeof value === "string" || typeof value === "number") {
            const itemValue = String(value).toLowerCase();
            return itemValue.includes(searchValue);
          }
          return false;
        });
      });
      setMappingData(filterResult);
    }
  };

  // handle date change function
  const handleFilterChange = (e: any) => {
    setFilterData((preFilter: FilterData) => ({
      ...preFilter,
      [e.target.name]: e.target.value,
    }));
  };

  return (
    <>
      <Container>
        <Paper>
          <Grid container>
            <Grid
              item
              xs={12}
              sx={{
                background: "#0288d1",
                color: "white",
              }}
            >
              <Grid container padding={2}>
                <Grid item xs={6}>
                  <Typography variant="h5" sx={{ mx: 3 }}>
                    Manage All Receipt
                  </Typography>
                </Grid>
                <Grid
                  item
                  xs={6}
                  sx={{ display: "flex", justifyContent: "flex-end" }}
                >
                  <TextField
                    label="Search "
                    size="small"
                    value={filterVal}
                    onInput={(e: any) => handleFilter(e)}
                    sx={{ mx: 3, background: "white" }}
                    InputProps={{
                      endAdornment: (
                        <InputAdornment position="end">
                          <IconButton edge="end">
                            <SearchIcon />
                          </IconButton>
                        </InputAdornment>
                      ),
                    }}
                  />
                </Grid>
              </Grid>
            </Grid>
            <Grid
              item
              xs={6}
              padding={2}
              display="flex"
              justifyContent="space-between"
            >
              <TextField
                InputLabelProps={{ shrink: true }}
                label="From"
                id="outlined-date-input"
                type="date"
                size="small"
                name="fromDate"
                autoComplete="current-date"
                sx={{ background: "white" }}
                value={filterData.fromDate}
                onChange={handleFilterChange}
              />
              <TextField
                InputLabelProps={{ shrink: true }}
                label="To"
                id="outlined-date-input"
                type="date"
                size="small"
                name="toDate"
                autoComplete="current-date"
                sx={{ background: "white" }}
                onChange={handleFilterChange}
                value={filterData.toDate}
              />
              <Button variant="contained" onClick={getFilteredData}>
                Filter
              </Button>
              <CommonSnackBar message={snackMessage} open={openSnackBar} />
            </Grid>
          </Grid>
          <TableContainer className="scrollBarCss">
            <Table sx={{ minWidth: 650 }} aria-label="simple table">
              <TableHead>
                <TableRow>
                  <TableCell
                    sx={{
                      textAlign: "center",
                      border: "1px solid #ddd",
                      fontSize: "15px",
                    }}
                  ></TableCell>
                  <TableCell
                    sx={{
                      textAlign: "center",
                      border: "1px solid #ddd",
                      fontSize: "15px",
                    }}
                  >
                    Sr.No
                  </TableCell>
                  <TableCell
                    sx={{
                      textAlign: "center",
                      border: "1px solid #ddd",
                      fontSize: "15px",
                    }}
                  >
                    Receipt Id
                  </TableCell>
                  <TableCell
                    sx={{
                      textAlign: "center",
                      border: "1px solid #ddd",
                      fontSize: "15px",
                    }}
                  >
                    Installment Date
                  </TableCell>
                  <TableCell
                    sx={{
                      textAlign: "center",
                      border: "1px solid #ddd",
                      fontSize: "15px",
                    }}
                  >
                    Deposit Date
                  </TableCell>
                  <TableCell
                    sx={{
                      textAlign: "center",
                      border: "1px solid #ddd",
                      fontSize: "15px",
                    }}
                  >
                    PA Enrollment No.
                  </TableCell>
                  <TableCell
                    sx={{
                      textAlign: "center",
                      border: "1px solid #ddd",
                      fontSize: "15px",
                    }}
                  >
                    Student Name
                  </TableCell>
                  <TableCell
                    sx={{
                      textAlign: "center",
                      border: "1px solid #ddd",
                      fontSize: "15px",
                    }}
                  >
                    Father Name
                  </TableCell>
                  <TableCell
                    sx={{
                      textAlign: "center",
                      border: "1px solid #ddd",
                      fontSize: "15px",
                    }}
                  >
                    Fees
                  </TableCell>
                  <TableCell
                    sx={{
                      textAlign: "center",
                      border: "1px solid #ddd",
                      fontSize: "15px",
                    }}
                  >
                    Registration Fees
                  </TableCell>
                  <TableCell
                    sx={{
                      textAlign: "center",
                      border: "1px solid #ddd",
                      fontSize: "15px",
                    }}
                  >
                    Caution Money
                  </TableCell>
                </TableRow>
              </TableHead>

              <TableBody>
                {mappingData?.map((item: any) => {
                  return item.installmentDetails
                    ?.slice(
                      page * rowsPerPage,
                      page * rowsPerPage + rowsPerPage
                    )
                    .map((install: any, index: number) => {
                      return (
                        <Fragment key={item._id}>
                          <TableRow
                            key={`${item._id} - ${install._id}`}
                            sx={{
                              "&:last-child td, &:last-child th": { border: 0 },
                            }}
                          >
                            <TableCell>
                              <IconButton
                                aria-label="expand row"
                                size="small"
                                onClick={() => {
                                  toggleOpen(install._id);
                                }}
                              >
                                {open.get(install._id) ? (
                                  <KeyboardArrowUpIcon />
                                ) : (
                                  <KeyboardArrowDownIcon />
                                )}
                              </IconButton>
                            </TableCell>
                            <TableCell
                              sx={{
                                textAlign: "center",
                                border: "1px solid #ddd",
                              }}
                            >
                              {page * rowsPerPage + index + 1}
                            </TableCell>
                            <TableCell
                              sx={{
                                textAlign: "center",
                                border: "1px solid #ddd",
                              }}
                            >
                              {install.receiptNo}
                            </TableCell>
                            <TableCell
                              sx={{
                                textAlign: "center",
                                border: "1px solid #ddd",
                              }}
                            >
                              {install.date}
                            </TableCell>
                            <TableCell
                              sx={{
                                textAlign: "center",
                                border: "1px solid #ddd",
                              }}
                            >
                              {install.createdAt}
                            </TableCell>
                            <TableCell
                              sx={{
                                textAlign: "center",
                                border: "1px solid #ddd",
                              }}
                            >
                              {item?.studentId}
                            </TableCell>
                            <TableCell
                              sx={{
                                textAlign: "center",
                                border: "1px solid #ddd",
                              }}
                            >
                              {item.firstName} {item.lastName}
                            </TableCell>
                            <TableCell
                              sx={{
                                textAlign: "center",
                                border: "1px solid #ddd",
                              }}
                            >
                              {item.fatherName}
                            </TableCell>
                            <TableCell
                              sx={{
                                textAlign: "center",
                                border: "1px solid #ddd",
                              }}
                            >
                              {item.fees}
                            </TableCell>
                            <TableCell
                              sx={{
                                textAlign: "center",
                                border: "1px solid #ddd",
                              }}
                            >
                              {item?.regFees || 0}
                            </TableCell>
                            <TableCell
                              sx={{
                                textAlign: "center",
                                border: "1px solid #ddd",
                              }}
                            >
                              {item?.cautionMoney || 0}
                            </TableCell>
                          </TableRow>
                          <TableRow>
                            <TableCell
                              style={{ paddingBottom: 0, paddingTop: 0 }}
                              colSpan={10}
                            >
                              <Collapse
                                in={open.get(install._id)}
                                timeout="auto"
                                unmountOnExit
                              >
                                <Box sx={{ margin: 1 }}>
                                  <Table size="small" aria-label="purchases">
                                    <TableHead>
                                      <TableRow>
                                        <TableCell
                                          sx={{
                                            textAlign: "center",
                                            border: "1px solid #ddd",
                                          }}
                                        >
                                          Other Fee
                                        </TableCell>
                                        <TableCell
                                          sx={{
                                            textAlign: "center",
                                            border: "1px solid #ddd",
                                          }}
                                        >
                                          Payment Mode
                                        </TableCell>
                                        <TableCell
                                          sx={{
                                            textAlign: "center",
                                            border: "1px solid #ddd",
                                          }}
                                        >
                                          Slip No.
                                        </TableCell>
                                        <TableCell
                                          sx={{
                                            textAlign: "center",
                                            border: "1px solid #ddd",
                                          }}
                                        >
                                          Bank Name
                                        </TableCell>
                                        <TableCell
                                          sx={{
                                            textAlign: "center",
                                            border: "1px solid #ddd",
                                          }}
                                        >
                                          Cheque No.
                                        </TableCell>
                                        <TableCell
                                          sx={{
                                            textAlign: "center",
                                            border: "1px solid #ddd",
                                          }}
                                        >
                                          Account No.
                                        </TableCell>
                                        <TableCell
                                          sx={{
                                            textAlign: "center",
                                            border: "1px solid #ddd",
                                          }}
                                        >
                                          Transfer Id
                                        </TableCell>
                                        <TableCell
                                          sx={{
                                            textAlign: "center",
                                            border: "1px solid #ddd",
                                          }}
                                        >
                                          Transaction Type
                                        </TableCell>
                                        <TableCell
                                          sx={{
                                            textAlign: "center",
                                            border: "1px solid #ddd",
                                          }}
                                        >
                                          Status
                                        </TableCell>
                                      </TableRow>
                                    </TableHead>
                                    <TableBody>
                                      <TableRow key={item._id}>
                                        <TableCell
                                          sx={{
                                            textAlign: "center",
                                            border: "1px solid #ddd",
                                          }}
                                        >
                                          {item.otherFees || 0}
                                        </TableCell>
                                        <TableCell
                                          sx={{
                                            textAlign: "center",
                                            border: "1px solid #ddd",
                                          }}
                                        >
                                          {install.paymentMode}
                                        </TableCell>
                                        <TableCell
                                          sx={{
                                            textAlign: "center",
                                            border: "1px solid #ddd",
                                          }}
                                        >
                                          {install.slipNo || null}
                                        </TableCell>
                                        <TableCell
                                          sx={{
                                            textAlign: "center",
                                            border: "1px solid #ddd",
                                          }}
                                        >
                                          {install.bankName}
                                        </TableCell>
                                        <TableCell
                                          sx={{
                                            textAlign: "center",
                                            border: "1px solid #ddd",
                                          }}
                                        >
                                          {install.chequeNo}
                                        </TableCell>
                                        <TableCell
                                          sx={{
                                            textAlign: "center",
                                            border: "1px solid #ddd",
                                          }}
                                        >
                                          {install.accountNo}
                                        </TableCell>
                                        <TableCell
                                          sx={{
                                            textAlign: "center",
                                            border: "1px solid #ddd",
                                          }}
                                        >
                                          {install.transferId}
                                        </TableCell>
                                        <TableCell
                                          sx={{
                                            textAlign: "center",
                                            border: "1px solid #ddd",
                                          }}
                                        >
                                          {install.Transection_Type}
                                        </TableCell>
                                        <TableCell
                                          sx={{
                                            textAlign: "center",
                                            border: "1px solid #ddd",
                                          }}
                                        >
                                          {install.verifyPayment}
                                        </TableCell>
                                      </TableRow>
                                    </TableBody>
                                  </Table>
                                </Box>
                              </Collapse>
                            </TableCell>
                          </TableRow>
                        </Fragment>
                      );
                    });
                })}
              </TableBody>
            </Table>
          </TableContainer>
          <TablePagination
            rowsPerPageOptions={[10, 25, 50, 100]}
            component="div"
            count={mappingData?.length}
            rowsPerPage={rowsPerPage}
            page={page}
            onPageChange={handleChangePage}
            onRowsPerPageChange={handleChangeRowsPerPage}
          />
        </Paper>
      </Container>
    </>
  );
}
