import Button from "@mui/material/Button";
import TextField from "@mui/material/TextField";
import Grid from "@mui/material/Grid";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import Container from "@mui/material/Container";
import { Paper } from "@mui/material";
import { useState, useRef, useEffect } from "react";
import JoditEditor from "jodit-react";
import "../App.scss";
import useValidation from "../Hooks/useTypescriptValidation";
import { getRequest, updateRequest } from "../api/api";
import { GET_SETTING, UPDATE_SETTING } from "../api/server";
import CommonSnackBar from "../Components/Common/CommonSnackbar";

export default function SettingForm(props: any) {
  let { eventHandler } = useValidation();
  const { types } = props;

  // text editor
  const editor = useRef(null);

  const [errors, setErrors] = useState({
    studentShare: "",
    queryEmail: "",
    admissionEmail: "",
    technicalCall: "",
    officeAddress: "",
    officeContact: "",
    officePhone: "",
    officeEmail: "",
    facebookURL: "",
    twitterURL: "",
    instagramURL: "",
    googleURL: "",
    linkedInURL: "",
  });

  const [setting, setSetting] = useState<any>({
    studentShare: "",
    queryEmail: "",
    admissionEmail: "",
    technicalCall: "",
    homePage: "",
    officeAddress: "",
    officeContact: "",
    officePhone: "",
    officeEmail: "",
    accountDetails: "",
    facebookURL: "",
    twitterURL: "",
    instagramURL: "",
    googleURL: "",
    linkedInURL: "",
  });

  // for displaying snackbar message
  const [snackMessage, setSnackMessage] = useState<string>("");

  //for displaying snackbar message
  const [openSnackBar, setOpenSnackBar] = useState(false);

  const handleOpenSnackBar = () => {
    setOpenSnackBar(true);
    setTimeout(() => {
      setOpenSnackBar(false);
    }, 5000);
  };

  const updateData = async (e: any) => {
    e.preventDefault();
    window.scrollTo(0, 0);
    if (validator()) {
      try {
        const response = await updateRequest(
          UPDATE_SETTING,
          types.lId,
          setting,
          ""
        );
        if (response) {
          response
            ? setSnackMessage("Data Updated Successfully..!")
            : setSnackMessage("Data not saved!");
          handleOpenSnackBar();
        }
      } catch (error) {
        console.error("Error updating data:", error);
      }
    }
  };

  // to check fields are filled or not
  const validator: any = () => {
    for (let field in errors) {
      if (errors[field as keyof typeof errors] !== "") {
        return false;
      }
    }
    return true;
  };

  const ValidationHandler = async (e: any, alterName?: any) => {
    const val = e.target.value;
    const id = alterName;

    if (id) {
      let prom = new Promise((resolve, reject) => {
        if (true) {
          resolve(eventHandler(id, val));
        }
      });
      prom.then((res) => setErrors({ ...errors, [e.target.name]: res }));
    }
  };

  const handleChange = (e: any) => {
    setSetting({ ...setting, [e.target.name]: e.target.value });
  };

  useEffect(() => {
    async function fetchData() {
      const res = await getRequest(GET_SETTING, "");
      if (res) {
        setSetting(res.data[0]);
      }
    }
    fetchData();
  }, []);

  return (
    <Container component="main" maxWidth="md">
      <Paper elevation={20} sx={{ p: 3 }}>
        <Box
          component="form"
          sx={{
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
            backgroundColor: "white",
          }}
        >
          <Grid container sx={{ padding: "10px" }}>
            <Grid item xs={12}>
              <Grid item xs={12}>
                <TextField
                  required
                  InputLabelProps={{ shrink: true }}
                  id="studentShare"
                  label="Student Share:"
                  type="number"
                  inputProps={{ min: 0 }}
                  name="studentShare"
                  value={setting?.studentShare}
                  onChange={handleChange}
                  onBlur={(e: any) => ValidationHandler(e, "")}
                  sx={{ mt: 3, width: { xs: "100%" }, background: "white" }}
                />

                <TextField
                  required
                  InputLabelProps={{ shrink: true }}
                  id="queryEmail"
                  label="For any query/issue Please Email at :"
                  type="text"
                  name="queryEmail"
                  value={setting?.queryEmail}
                  error={Boolean(errors.queryEmail)}
                  helperText={errors.queryEmail}
                  onChange={handleChange}
                  onBlur={(e: any) => ValidationHandler(e, "email")}
                  sx={{ mt: 3, width: { xs: "100%" }, background: "white" }}
                />

                <TextField
                  required
                  InputLabelProps={{ shrink: true }}
                  id="admissionEmail"
                  label="For Admission Please Email at :"
                  type="text"
                  name="admissionEmail"
                  value={setting?.admissionEmail}
                  error={Boolean(errors.admissionEmail)}
                  helperText={errors.admissionEmail}
                  onChange={handleChange}
                  onBlur={(e: any) => ValidationHandler(e, "email")}
                  sx={{ mt: 3, width: { xs: "100%" }, background: "white" }}
                />

                <TextField
                  required
                  InputLabelProps={{ shrink: true }}
                  id="technicalCall"
                  label="For Technical Issue, Call :"
                  name="technicalCall"
                  value={setting?.technicalCall}
                  error={Boolean(errors.technicalCall)}
                  helperText={errors.technicalCall}
                  onChange={handleChange}
                  onBlur={(e: any) => ValidationHandler(e, "mobile")}
                  sx={{ mt: 3, width: { xs: "100%" }, background: "white" }}
                />

                <Typography sx={{ mt: 2 }}>Home Page Note :</Typography>

                <JoditEditor
                  ref={editor}
                  value={setting?.homePage}
                  onChange={(e: String) => {
                    setSetting({ ...setting, homePage: e });
                  }}
                />

                <TextField
                  required
                  InputLabelProps={{ shrink: true }}
                  id="officeAddress"
                  label="Office Address :"
                  type="text"
                  name="officeAddress"
                  value={setting?.officeAddress}
                  error={Boolean(errors.officeAddress)}
                  helperText={errors.officeAddress}
                  onChange={handleChange}
                  onBlur={(e: any) => ValidationHandler(e, "address")}
                  sx={{ mt: 3, width: { xs: "100%" }, background: "white" }}
                />

                <TextField
                  required
                  InputLabelProps={{ shrink: true }}
                  id="officeContact"
                  label="Office Contact Number :"
                  name="officeContact"
                  value={setting?.officeContact}
                  error={Boolean(errors.officeContact)}
                  helperText={errors.officeContact}
                  onChange={handleChange}
                  onBlur={(e: any) => ValidationHandler(e, "mobile")}
                  sx={{ mt: 3, width: { xs: "100%" }, background: "white" }}
                />

                <TextField
                  required
                  InputLabelProps={{ shrink: true }}
                  id="officePhone"
                  label="Office Contact Phone Number :"
                  type="text"
                  name="officePhone"
                  value={setting?.officePhone}
                  error={Boolean(errors.officePhone)}
                  helperText={errors.officePhone}
                  onChange={handleChange}
                  onBlur={(e: any) => ValidationHandler(e, "mobile")}
                  sx={{ mt: 3, width: { xs: "100%" }, background: "white" }}
                />

                <TextField
                  required
                  InputLabelProps={{ shrink: true }}
                  id="officeEmail"
                  label="Office Email :"
                  type="text"
                  name="officeEmail"
                  value={setting?.officeEmail}
                  error={Boolean(errors.officeEmail)}
                  helperText={errors.officeEmail}
                  onChange={handleChange}
                  onBlur={(e: any) => ValidationHandler(e, "email")}
                  sx={{ mt: 3, width: { xs: "100%" }, background: "white" }}
                />

                <Typography sx={{ mt: 2 }}>Account Details :</Typography>

                <JoditEditor
                  ref={editor}
                  value={setting?.accountDetails}
                  onChange={(e: String) => {
                    setSetting({ ...setting, accountDetails: e });
                  }}
                />

                <TextField
                  InputLabelProps={{ shrink: true }}
                  id="facebookURL"
                  label="Facebook URL :"
                  type="text"
                  name="facebookURL"
                  value={setting?.facebookURL}
                  error={Boolean(errors.facebookURL)}
                  helperText={errors.facebookURL}
                  onChange={handleChange}
                  onBlur={(e: any) => ValidationHandler(e, "url")}
                  sx={{ mt: 3, width: { xs: "100%" }, background: "white" }}
                />

                <TextField
                  InputLabelProps={{ shrink: true }}
                  id="twitterURL"
                  label="Twitter URL :"
                  type="text"
                  name="twitterURL"
                  value={setting?.twitterURL}
                  error={Boolean(errors.twitterURL)}
                  helperText={errors.twitterURL}
                  onChange={handleChange}
                  onBlur={(e: any) => ValidationHandler(e, "url")}
                  sx={{ mt: 3, width: { xs: "100%" }, background: "white" }}
                />

                <TextField
                  InputLabelProps={{ shrink: true }}
                  id="instagramURL"
                  label="Instagram URL :"
                  type="text"
                  name="instagramURL"
                  value={setting?.instagramURL}
                  error={Boolean(errors.instagramURL)}
                  helperText={errors.instagramURL}
                  onChange={handleChange}
                  onBlur={(e: any) => ValidationHandler(e, "url")}
                  sx={{ mt: 3, width: { xs: "100%" }, background: "white" }}
                />

                <TextField
                  InputLabelProps={{ shrink: true }}
                  id="googleURL"
                  label="Google URL :"
                  type="text"
                  required
                  name="googleURL"
                  value={setting?.googleURL}
                  error={Boolean(errors.googleURL)}
                  helperText={errors.googleURL}
                  onChange={handleChange}
                  onBlur={(e: any) => ValidationHandler(e, "url")}
                  sx={{ mt: 3, width: { xs: "100%" }, background: "white" }}
                />

                <TextField
                  InputLabelProps={{ shrink: true }}
                  id="linkedInURL"
                  label="Linkedin URL :"
                  type="text"
                  name="linkedInURL"
                  value={setting?.linkedInURL}
                  error={Boolean(errors.linkedInURL)}
                  helperText={errors.linkedInURL}
                  onChange={handleChange}
                  onBlur={(e: any) => ValidationHandler(e, "url")}
                  sx={{ mt: 3, width: { xs: "100%" }, background: "white" }}
                />
              </Grid>
            </Grid>

            <Grid item xs={12}>
              <Button
                type="submit"
                variant="contained"
                onClick={updateData}
                sx={{ mt: 3, mb: 2 }}
              >
                Submit
              </Button>
            </Grid>
            <CommonSnackBar message={snackMessage} open={openSnackBar} />
          </Grid>
        </Box>
      </Paper>
    </Container>
  );
}
