import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import KeyboardArrowUpIcon from "@mui/icons-material/KeyboardArrowUp";
import SearchIcon from "@mui/icons-material/Search";
import {
  Box,
  Collapse,
  Container,
  FormControl,
  Grid,
  IconButton,
  InputAdornment,
  TablePagination,
  TextField,
} from "@mui/material";
import Paper from "@mui/material/Paper";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import { Fragment, useEffect, useState } from "react";
import "../Components/Home/Home.scss";
import { getRequestByParams } from "../api/api";
import { GET_DASHBOARD_DETAILS } from "../api/server";
import DashboardData from "./DashboardData";

export default function Dashboard(props: any) {
  const { types } = props;

  const collapsibleFields = ["Installment Date", "Installment Amount"];

  const headerFields = [
    "",
    "Sr.No",
    "Registration No",
    "Student Name",
    "Father Name",
    "Email Id",
    "Contact No.",
  ];

  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [dashboard, setDashboard] = useState<any[]>([]);
  const [open, setOpen] = useState(new Array(dashboard.length).fill(false));
  const [searchApiData, setSearchApiData] = useState<any[]>([]);
  const [filterVal, setFilterVal] = useState("");

  const handleChangePage = (event: any, newPage: any) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event: any) => {
    setRowsPerPage(+event.target.value);
    setPage(0);
  };

  const handleFilter = (e: any) => {
    if (e.target.value === "") {
      setDashboard(searchApiData);
    } else {
      const filterResult = searchApiData.filter((item: any) => {
        const searchValue = e.target.value.toLowerCase();
        return (
          item.studentData.firstName.toLowerCase().includes(searchValue) ||
          Object.values(item).some((value) => {
            if (typeof value === "string" || typeof value === "number") {
              const itemValue = String(value).toLowerCase();
              return itemValue.includes(searchValue);
            }
            return false;
          })
        );
      });
      setDashboard(filterResult);
    }
    setFilterVal(e.target.value);
  };

  const getStudentData = async () => {
    try {
      if (types?.type && types?.lId) {
        const resp: any = await getRequestByParams(
          GET_DASHBOARD_DETAILS,
          types?.lId,
          { params: { type: types?.type } }
        );
        setDashboard(resp.data);
        setSearchApiData(resp.data);
      }
    } catch (error) {
      console.error("Error fetching data:", error);
    }
  };

  useEffect(() => {
    if (types?.type && types?.lId) {
      getStudentData();
    }
  }, [types]);

  return (
    <>
      {types?.type !== "Administrator" ? (
        <>
          <DashboardData
            types={types}
            studentDetails={{
              studentId: dashboard[0]?.studentId,
              enrollmentNo: dashboard[0]?.enrollmentNo,
            }}
          />
        </>
      ) : null}
      {types?.type !== "Student" ? (
        <Grid container justifyContent="space-between">
          <Container>
            <Paper elevation={20}>
              <Grid
                container
                sx={{
                  p: 2,
                  background: "#0288d1",
                  color: "white",
                }}
              >
                <Grid
                  item
                  xs={12}
                  sx={{ display: "flex", justifyContent: "flex-end" }}
                >
                  <FormControl>
                    <TextField
                      label="Search"
                      size="small"
                      value={filterVal}
                      onInput={(e: any) => handleFilter(e)}
                      sx={{ mx: 3, background: "white" }}
                      InputProps={{
                        endAdornment: (
                          <InputAdornment position="end">
                            <IconButton edge="end">
                              <SearchIcon />
                            </IconButton>
                          </InputAdornment>
                        ),
                      }}
                    />
                  </FormControl>
                </Grid>
              </Grid>
              <TableContainer className="scrollBarCss">
                <Table sx={{ minWidth: 650 }} aria-label="simple table">
                  <TableHead>
                    <TableRow>
                      {headerFields.map((item: string, index: number) => (
                        <Fragment key={index}>
                          <TableCell
                            key={index}
                            sx={{
                              textAlign: "center",
                              border: "1px solid #ddd",
                              fontSize: "15px",
                            }}
                          >
                            {item}
                          </TableCell>
                        </Fragment>
                      ))}
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {dashboard
                      ?.slice(
                        page * rowsPerPage,
                        page * rowsPerPage + rowsPerPage
                      )
                      .map((item, index) => (
                        <Fragment key={item._id}>
                          <TableRow
                            key={item._id}
                            sx={{
                              "&:last-child td, &:last-child th": {
                                border: 0,
                              },
                            }}
                          >
                            <TableCell>
                              <IconButton
                                aria-label="expand row"
                                size="small"
                                onClick={() => {
                                  const newOpen = [...open];
                                  newOpen[index] = !newOpen[index];
                                  setOpen(newOpen);
                                }}
                              >
                                {open[index] ? (
                                  <KeyboardArrowUpIcon />
                                ) : (
                                  <KeyboardArrowDownIcon />
                                )}
                              </IconButton>
                            </TableCell>
                            <TableCell
                              sx={{
                                textAlign: "center",
                                border: "1px solid #ddd",
                              }}
                            >
                              {page * rowsPerPage + index + 1}
                            </TableCell>
                            <TableCell
                              sx={{
                                textAlign: "center",
                                border: "1px solid #ddd",
                              }}
                            >
                              {item.studentId}
                            </TableCell>
                            <TableCell
                              sx={{
                                textAlign: "center",
                                border: "1px solid #ddd",
                              }}
                            >
                              {item.studentName}
                            </TableCell>
                            <TableCell
                              sx={{
                                textAlign: "center",
                                border: "1px solid #ddd",
                              }}
                            >
                              {item.fatherName}
                            </TableCell>
                            <TableCell
                              sx={{
                                textAlign: "center",
                                border: "1px solid #ddd",
                              }}
                              align="center"
                            >
                              {item.email}
                            </TableCell>
                            <TableCell
                              sx={{
                                textAlign: "center",
                                border: "1px solid #ddd",
                              }}
                              align="center"
                            >
                              {item.contactNumber}
                            </TableCell>
                          </TableRow>
                          <TableRow>
                            <TableCell
                              style={{ paddingBottom: 0, paddingTop: 0 }}
                              colSpan={10}
                            >
                              <Collapse
                                in={open[index]}
                                timeout="auto"
                                unmountOnExit
                              >
                                <Box
                                  sx={{ margin: "10px auto", maxWidth: "60%" }}
                                >
                                  <Table
                                    size="small"
                                    aria-label="purchases"
                                    style={{ width: "100%" }}
                                  >
                                    <TableHead>
                                      <TableRow>
                                        {collapsibleFields.map(
                                          (item: string, index: number) => (
                                            <TableCell
                                              key={index}
                                              sx={{
                                                textAlign: "center",
                                                border: "1px solid #ddd",
                                                fontSize: "15px",
                                              }}
                                            >
                                              {item}
                                            </TableCell>
                                          )
                                        )}
                                      </TableRow>
                                    </TableHead>
                                    <TableBody>
                                      {item.installmentDetails
                                        .filter(
                                          (installment: any) =>
                                            installment.verifyPayment ===
                                            "Pending"
                                        )
                                        ?.map((installment: any, i: any) => (
                                          <TableRow key={i}>
                                            <TableCell
                                              sx={{
                                                textAlign: "center",
                                                border: "1px solid #ddd",
                                              }}
                                            >
                                              {installment.date}
                                            </TableCell>
                                            <TableCell
                                              sx={{
                                                textAlign: "center",
                                                border: "1px solid #ddd",
                                              }}
                                            >
                                              {installment.amount}
                                            </TableCell>
                                          </TableRow>
                                        ))}
                                    </TableBody>
                                  </Table>
                                </Box>
                              </Collapse>
                            </TableCell>
                          </TableRow>
                        </Fragment>
                      ))}
                  </TableBody>
                </Table>
              </TableContainer>
              <TablePagination
                rowsPerPageOptions={[10, 25, 50, 100]}
                component="div"
                count={dashboard?.length}
                rowsPerPage={rowsPerPage}
                page={page}
                onPageChange={handleChangePage}
                onRowsPerPageChange={handleChangeRowsPerPage}
              ></TablePagination>
            </Paper>
          </Container>
        </Grid>
      ) : null}
    </>
  );
}
