import ErrorBoundries from "./Components/ErrorBoundries";
import Routing from "./Routing/Routing";

function App() {
  return (
    <>
      <ErrorBoundries>
        <Routing />
      </ErrorBoundries>
    </>
  );
}

export default App;
