import { useEffect, useState } from "react";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Paper from "@mui/material/Paper";
import Button from "@mui/material/Button";
import CommissionPayment from "./CommissionPayment";
import Dialog from "@mui/material/Dialog";
import {
  Grid,
  IconButton,
  InputAdornment,
  TablePagination,
  Typography,
} from "@mui/material";
import { Container, FormControl, TextField } from "@mui/material";
import SearchIcon from "@mui/icons-material/Search";
import "../../Components/Home/Home.scss";
import CommonSnackBar from "../../Components/Common/CommonSnackbar";
import { getRequest } from "../../api/api";
import { GET_UNPAID_COMMISSION_EXECUTIVE } from "../../api/server";

export default function ExecutiveUnpaid() {
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [users, setUsers] = useState<any[]>([]);
  const [id, setId] = useState<string>("");
  const [showDialog, setShowDialog] = useState<boolean>(false);
  const [searchApiData, setSearchApiData] = useState<any>([]);
  const [filterVal, setFilterVal] = useState<string>("");
  const [snackMessage, setSnackMessage] = useState<string>("");
  const [openSnackBar, setOpenSnackBar] = useState(false);

  const closeDialog = () => setShowDialog(false);

  const handleChangePage = (event: any, newPage: any) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event: any) => {
    setRowsPerPage(+event.target.value);
  };

  const handleFilter = (e: any) => {
    if (e.target.value === "") {
      setUsers(searchApiData);
    } else {
      const filterResult = searchApiData.filter((item: any) => {
        const searchValue = e.target.value.toLowerCase();
        return Object.values(item).some((value) => {
          if (typeof value === "string" || typeof value === "number") {
            const itemValue = String(value).toLowerCase();
            return itemValue.includes(searchValue);
          }
          return false;
        });
      });
      setUsers(filterResult);
    }
    setFilterVal(e.target.value);
  };

  const handlePay = (id: string) => {
    setShowDialog(true);
    setId(id);
  };

  const handleOpenSnackBar = () => {
    setOpenSnackBar(true);
    setTimeout(() => {
      setOpenSnackBar(false);
    }, 3000);
  };

  const fetchData = async () => {
    try {
      const resultUnpaid: any = await getRequest(
        GET_UNPAID_COMMISSION_EXECUTIVE,
        ""
      );
      if (resultUnpaid.data?.error) {
        setSnackMessage("Commission Data not found...!");
        handleOpenSnackBar();
      } else {
        setUsers(resultUnpaid.data);
        setSearchApiData(resultUnpaid.data);
      }
    } catch (error) {
      console.error("Error loading users:", error);
    }
  };

  useEffect(() => {
    fetchData();
  }, [showDialog]);

  return (
    <>
      <Container>
        <Paper elevation={20}>
          <Grid container sx={{ p: 2, background: "#0288d1", color: "white" }}>
            <Grid item xs={8}>
              <Typography variant="h5" sx={{ mx: 3 }}>
                Manage Executive Un-Paid Commission
              </Typography>
            </Grid>
            <Grid
              item
              xs={4}
              sx={{ display: "flex", justifyContent: "flex-end" }}
            >
              <FormControl>
                <TextField
                  label="Search"
                  size="small"
                  value={filterVal}
                  onInput={(e: any) => handleFilter(e)}
                  sx={{ mx: 3, background: "white" }}
                  InputProps={{
                    endAdornment: (
                      <InputAdornment position="end">
                        <IconButton edge="end">
                          <SearchIcon />
                        </IconButton>
                      </InputAdornment>
                    ),
                  }}
                />
              </FormControl>
            </Grid>
          </Grid>
          <TableContainer component={Paper} className="scrollBarCss">
            <Table>
              <TableHead>
                <TableRow>
                  <TableCell
                    sx={{
                      textAlign: "center",
                      border: "1px solid #ddd",
                    }}
                  >
                    Sr.No
                  </TableCell>
                  <TableCell
                    sx={{
                      textAlign: "center",
                      border: "1px solid #ddd",
                    }}
                  >
                    Employee Id
                  </TableCell>
                  <TableCell
                    sx={{
                      textAlign: "center",
                      border: "1px solid #ddd",
                    }}
                  >
                    Name
                  </TableCell>
                  <TableCell
                    sx={{
                      textAlign: "center",
                      border: "1px solid #ddd",
                    }}
                  >
                    Email
                  </TableCell>
                  <TableCell
                    sx={{
                      textAlign: "center",
                      border: "1px solid #ddd",
                    }}
                  >
                    Phone No.
                  </TableCell>
                  <TableCell
                    sx={{
                      textAlign: "center",
                      border: "1px solid #ddd",
                    }}
                  >
                    Commission
                  </TableCell>
                  <TableCell
                    sx={{
                      textAlign: "center",
                      border: "1px solid #ddd",
                    }}
                  >
                    Commission By
                  </TableCell>
                  <TableCell
                    sx={{
                      textAlign: "center",
                      border: "1px solid #ddd",
                    }}
                  >
                    Status
                  </TableCell>
                  <TableCell
                    sx={{
                      textAlign: "center",
                      border: "1px solid #ddd",
                    }}
                  >
                    Action
                  </TableCell>
                </TableRow>
              </TableHead>

              <TableBody>
                {users
                  ?.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                  .map((employee: any, index: number) => (
                    <TableRow key={employee._id}>
                      <TableCell
                        sx={{
                          textAlign: "center",
                          border: "1px solid #ddd",
                        }}
                      >
                        {page * rowsPerPage + index + 1}
                      </TableCell>
                      <TableCell
                        sx={{
                          textAlign: "center",
                          border: "1px solid #ddd",
                        }}
                      >
                        {employee.empId}
                      </TableCell>
                      <TableCell
                        sx={{
                          textAlign: "center",
                          border: "1px solid #ddd",
                        }}
                      >
                        {employee.name}
                      </TableCell>
                      <TableCell
                        sx={{
                          textAlign: "center",
                          border: "1px solid #ddd",
                        }}
                      >
                        {employee.email}
                      </TableCell>
                      <TableCell
                        sx={{
                          textAlign: "center",
                          border: "1px solid #ddd",
                        }}
                      >
                        {employee.phone}
                      </TableCell>
                      <TableCell
                        sx={{
                          textAlign: "center",
                          border: "1px solid #ddd",
                        }}
                      >
                        {employee.commission}
                      </TableCell>
                      <TableCell
                        sx={{
                          textAlign: "center",
                          border: "1px solid #ddd",
                        }}
                      >
                        {employee.commissionBy}
                      </TableCell>
                      <TableCell
                        sx={{
                          textAlign: "center",
                          border: "1px solid #ddd",
                        }}
                      >
                        {employee.status}
                      </TableCell>
                      <TableCell
                        sx={{
                          textAlign: "center",
                          border: "1px solid #ddd",
                        }}
                      >
                        <Button
                          variant="text"
                          onClick={() => handlePay(employee._id)}
                        >
                          Pay
                        </Button>
                      </TableCell>
                    </TableRow>
                  ))}
              </TableBody>
              <Dialog open={showDialog}>
                <CommissionPayment id={id} closeDialog={closeDialog} />
              </Dialog>
            </Table>
          </TableContainer>
          <TablePagination
            rowsPerPageOptions={[10, 25, 50, 100]}
            component="div"
            count={users?.length}
            rowsPerPage={rowsPerPage}
            page={page}
            onPageChange={handleChangePage}
            onRowsPerPageChange={handleChangeRowsPerPage}
          ></TablePagination>
        </Paper>
      </Container>
      <CommonSnackBar message={snackMessage} open={openSnackBar} />
    </>
  );
}
