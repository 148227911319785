import { Box, Grid, Typography } from "@mui/material";
import Container from "@mui/material/Container";

export default function Policy() {
  return (
    <>
      <Container
        sx={{ mt: { xs: "30%", sm: "18%", md: "18%", lg: "6%", xl: "20%" } }}
      >
        <Box sx={{ pb: 4 }}>
          <Grid item xs={12}>
            <Typography
              variant="h4"
              sx={{
                justifyContent: "center",
                display: "flex",
                p: 2,
                textTransform: "uppercase",
                fontWeight: "bold",
              }}
            >
              Privacy Policy
            </Typography>

            <Typography
              paragraph
              sx={{
                pb: 4,
                lineHeight: "2.1",
                textAlign: "justify",
                letterSpacing: "0px",
              }}
            >
              The institution/school respects and safeguards the privacy of
              those who view the information and make use of the services made
              available by them. Without the user's consent, as described in the
              privacy policy, any third party will not intentionally receive
              personally recognisable information about the user.
            </Typography>

            <Typography variant="h6" paragraph sx={{ fontWeight: "bold" }}>
              {" "}
              Access to confidential data is typically restricted to the
              following
            </Typography>

            <Typography paragraph>
              An employee or representative of The Prerna Academy who has been
              granted entry because of a genuine need to know for work or
              school.
            </Typography>

            <Typography paragraph>
              An employee or representative of The Prerna Academy who has been
              granted entry because of a genuine need to know for work or
              school.
            </Typography>
            <Typography paragraph>
              Any situation where The Prerna Academy is required to disclose
              personal data by an official legal or governing body,
              representative or other party.
            </Typography>
            <Typography paragraph>
              Any additional person or organisation that is allowed by law and
              is considered essential for the lawful conduct of Prerna Academy
              business.
            </Typography>

            <Typography variant="h6" sx={{ fontWeight: "bold", py: "10px" }}>
              Privacy
            </Typography>

            <Typography
              paragraph
              sx={{
                lineHeight: "2.1",
                textAlign: "justify",
                letterSpacing: "0px",
              }}
            >
              The Prerna Academy does not disclose a student's personal
              information to any third parties for marketing purposes through
              sales, rentals, or other means. Despite the aforementioned
              exceptions, The University will only divulge any student or
              alumni's confidential information if it complies with the
              following rules: Inquiries to The Prerna Academy regarding whether
              a person possesses a designation or degree from The Prerna Academy
              in good standing may be confirmed, and this information may be
              made public through The Prerna Academy website. A small number of
              professional industry organisations or associations are given
              access to students' names and addresses by The Prerna Academy
              solely for the purpose of notifying them of their eligibility for
              membership or other benefits or marketing local classes.
            </Typography>
          </Grid>
        </Box>
      </Container>
    </>
  );
}
