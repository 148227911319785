import {
  Button,
  Container,
  Dialog,
  Grid,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TablePagination,
  TableRow,
  Typography,
} from "@mui/material";
import { Fragment, useEffect, useState } from "react";
import StudentInstallmentPayment from "../../Components/Common/Payment/StudentInstallmentPayment";
import "../../Components/Home/Home.scss";
import { getRequestByParams } from "../../api/api";
import { GET_PROFILE } from "../../api/server";
import PaymentForm from "../ManageStudent/PaymentForm";

export default function UnpaidInstallment(props: any) {
  const [page, setPage] = useState<number>(0);
  const [rowsPerPage, setRowsPerPage] = useState<number>(5);
  const [detail, setDetail] = useState<any[]>([]);
  const [open, setOpen] = useState<boolean>(false);
  const [fee, setFee] = useState({});
  const [openPay, setOpenPay] = useState<boolean>(false);
  const [email, setEmail] = useState<string>("");

  const handleClose = () => {
    setOpen(false);
    getStudentData();
  };

  const handleClickOpen = (value: any, id: any) => {
    setOpen(true);
    setFee({ ...fee, fee: value, id: id });
  };

  const handleClosePay = () => {
    setOpenPay(false);
  };

  const handlePayButton = () => {
    setOpenPay(true);
  };

  const getStudentData = () => {
    const myData = {
      type: props.types.type,
      lId: props.types.lId,
    };
    getRequestByParams(GET_PROFILE, "", "", myData)
      .then((response: any) => {
        if (response.data.courseData.installmentDetails) {
          const filteredData =
            response.data.courseData.installmentDetails.filter(
              (item: any) =>
                item.verifyPayment === "Pending" ||
                item.verifyPayment === "Process" ||
                item.verifyPayment === "Reject"
            );
          setDetail(filteredData);
          setEmail(response.data.studentData.email);
        }
      })
      .catch((error) => {
        console.error("Error fetching profile data:", error);
      });
  };

  const handleChangePage = (event: any, newPage: any) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event: any) => {
    setRowsPerPage(+event.target.value);
    setPage(0);
  };

  useEffect(() => {
    getStudentData();
  }, []);

  return (
    <>
      <Container>
        <Paper elevation={20}>
          <Grid
            container
            sx={{
              p: 2,
              background: "#0288d1",
              color: "white",
            }}
          >
            <Grid item xs={6}>
              <Typography variant="h5" sx={{ mx: 3 }}>
                Unpaid Installments
              </Typography>
            </Grid>
          </Grid>
          <TableContainer sx={{ p: 1 }} className="scrollBarCss">
            <Table sx={{ minWidth: 650 }} aria-label="simple table">
              <TableHead>
                <TableRow>
                  <TableCell
                    sx={{
                      textAlign: "center",
                      border: "1px solid #ddd",
                      fontSize: "15px",
                    }}
                  >
                    Sr.No
                  </TableCell>
                  <TableCell
                    sx={{
                      textAlign: "center",
                      border: "1px solid #ddd",
                      fontSize: "15px",
                    }}
                  >
                    Installment Date
                  </TableCell>
                  <TableCell
                    sx={{
                      textAlign: "center",
                      border: "1px solid #ddd",
                      fontSize: "15px",
                    }}
                  >
                    Installment Fees
                  </TableCell>
                  <TableCell
                    sx={{
                      textAlign: "center",
                      border: "1px solid #ddd",
                      fontSize: "15px",
                    }}
                  >
                    Status
                  </TableCell>
                  <TableCell
                    sx={{
                      textAlign: "center",
                      border: "1px solid #ddd",
                      fontSize: "15px",
                    }}
                  >
                    Comment
                  </TableCell>
                  <TableCell
                    sx={{
                      textAlign: "center",
                      border: "1px solid #ddd",
                      fontSize: "15px",
                    }}
                  >
                    Payment Date
                  </TableCell>
                  <TableCell
                    sx={{
                      textAlign: "center",
                      border: "1px solid #ddd",
                      fontSize: "15px",
                    }}
                  >
                    Action
                  </TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {detail
                  .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                  .map((item, index) => (
                    <Fragment key={index}>
                      <TableRow
                        key={item._id}
                        sx={{
                          textAlign: "center",
                          borderRight: "1px solid #ddd",
                        }}
                      >
                        <TableCell
                          sx={{
                            textAlign: "center",
                            border: "1px solid #ddd",
                            fontSize: "15px",
                          }}
                        >
                          {page * rowsPerPage + index + 1}
                        </TableCell>
                        <TableCell
                          component="th"
                          scope="row"
                          sx={{
                            textAlign: "center",
                            borderRight: "1px solid #ddd",
                          }}
                        >
                          {item.date}
                        </TableCell>
                        <TableCell
                          sx={{
                            textAlign: "center",
                            borderRight: "1px solid #ddd",
                          }}
                        >
                          {item.amount}
                        </TableCell>
                        <TableCell
                          sx={{
                            textAlign: "center",
                            borderRight: "1px solid #ddd",
                          }}
                        >
                          {item.verifyPayment}
                        </TableCell>
                        <TableCell
                          sx={{
                            textAlign: "center",
                            borderRight: "1px solid #ddd",
                          }}
                        >
                          {item.rejectRecieptComment
                            ? item.rejectRecieptComment
                            : "----"}
                        </TableCell>
                        <TableCell
                          sx={{
                            textAlign: "center",
                            borderRight: "1px solid #ddd",
                          }}
                        >
                          {item.createdAt ? item.createdAt : "----"}
                        </TableCell>
                        <TableCell
                          sx={{
                            textAlign: "center",
                            borderRight: "1px solid #ddd",
                            display: "flex",
                            justifyContent: "space-around",
                          }}
                        >
                          <Button
                            size="small"
                            variant="contained"
                            onClick={handlePayButton}
                            disabled={item.verifyPayment === "Process"}
                          >
                            Pay
                          </Button>
                          <Dialog
                            open={openPay}
                            maxWidth={"sm"}
                            onClose={handleClosePay}
                          >
                            <StudentInstallmentPayment
                              amount={item.amount}
                              email={email}
                            />
                          </Dialog>
                          <Button
                            size="small"
                            variant="contained"
                            onClick={() =>
                              handleClickOpen(item.amount, item._id)
                            }
                            disabled={item.verifyPayment === "Process"}
                          >
                            Fill Info
                          </Button>
                          <Dialog open={open} maxWidth={"md"}>
                            <PaymentForm handleClose={handleClose} fee={fee} />
                          </Dialog>
                        </TableCell>
                      </TableRow>
                    </Fragment>
                  ))}
              </TableBody>
            </Table>
          </TableContainer>

          <TablePagination
            rowsPerPageOptions={[5, 10, 25, 50]}
            component="div"
            count={detail.length}
            rowsPerPage={rowsPerPage}
            page={page}
            onPageChange={handleChangePage}
            onRowsPerPageChange={handleChangeRowsPerPage}
          ></TablePagination>
        </Paper>
      </Container>
    </>
  );
}
