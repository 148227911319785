import {
  Page,
  Text,
  Image,
  Document,
  StyleSheet,
  View,
} from "@react-pdf/renderer";

const styles = StyleSheet.create({
  body: {
    paddingTop: 35,
    paddingBottom: 50,
    paddingHorizontal: 40,
  },
  para1: {
    flexDirection: "row",
    flexWrap: "wrap",
  },
  para2: {
    flexDirection: "row",
    flexWrap: "wrap",
    marginTop: 15,
    padding: 10,
  },
  column1: {
    width: "20%",
    padding: 20,
  },
  column2: {
    width: "80%",
    padding: 10,
  },
  column3: {
    width: "100%",
  },
  text: {
    fontSize: 12,
    textAlign: "justify",
  },
  text1: {
    fontSize: 15,
    textAlign: "center",
    marginBottom: 10,
  },
  text2: {
    fontSize: 12,
    textAlign: "justify",
    padding: 2,
    letterSpacing: "0px",
    lineHeight: "1.5px",
  },
  text5: {
    textIndent: "50px",
    fontSize: 12,
    textAlign: "justify",
    padding: 2,
    letterSpacing: "0px",
    lineHeight: "1.5px",
  },
  para3: {
    padding: 35,
    marginBottom: 5,
    marginTop: 10,
  },
  image: {
    height: 70,
    width: 70,
  },
  pageNumber: {
    position: "absolute",
    fontSize: 12,
    bottom: 30,
    left: 0,
    right: 0,
    textAlign: "center",
    color: "grey",
  },
  border: {
    borderBottom: " 1px solid black",
    margin: 5,
  },
  wish: {
    marginLeft: 30,
    fontSize: 12,
    lineHeight: "1.5px",
  },
  sign: {
    margin: 20,
    fontSize: 12,
    lineHeight: "1.5px",
    textIndent: "30px",
  },
  text3: {
    fontSize: 13,
    padding: 7,
    marginLeft: 5,
  },
  text4: {
    fontSize: 12,
    padding: 5,
    lineHeight: "1px",
  },
  text6: {
    fontSize: 12,
    marginLeft: 15,
    lineHeight: "1px",
  },
  emp: {
    marginTop: 65,
    padding: 30,
  },
  text7: {
    fontSize: 12,
    padding: 5,
    lineHeight: "1px",
    marginLeft: "10px",
  },
});

const AppointmentLetter = (props: any) => {
  let date = new Date();
  let day = date.getDate();
  let month = date.getMonth() + 1;
  let year = date.getFullYear();
  let currentDate = `${day}-${month}-${year}`;
  return (
    <>
      <Document>
        return(
        <Page size="A4">
          <View style={styles.para1}>
            <View style={styles.column1}>
              <Image
                style={styles.image}
                src="https://ik.imagekit.io/nwssoft/AboutUS-College-Logo/Logo/New_Logo_with_Crop/prena_acedemy_logo.jfif?updatedAt=1685344751380"
              ></Image>
            </View>
            <View style={styles.column2}>
              <Text style={styles.text}>PRERNA ACADEMY PVT. LTD., INDORE</Text>
              <Text style={styles.text}>
                Corporate Office : 82 Swami Vivekanand Nagar Telephone Square
                Kanadia Road Indore
              </Text>
              <Text style={styles.text}>(M.P) pin 452018</Text>
              <Text style={styles.text}>
                Ph. : (+91) 835 889 9900, (0731) 4298599
              </Text>
              <Text style={styles.text}>Toll Free No. : 1800120-224466</Text>
              <Text style={styles.text}>Email :info@prernaacademy.in</Text>
              <Text style={styles.text}>website: www.prernaacademy.in</Text>
            </View>
          </View>
          <View style={styles.border}></View>
          <View>
            <Text style={styles.text3}>Group of Education India</Text>
          </View>
          <View style={styles.para2}>
            <View style={styles.column3}>
              <Text style={styles.text4}>Date:{" " + `${currentDate}`}</Text>
            </View>
            <View style={styles.column3}>
              <Text style={styles.text4}>
                Reference No.:{props?.item?.empId}
              </Text>
            </View>
            <View style={styles.column3}>
              <Text style={styles.text4}>To,</Text>
            </View>
            <View style={styles.column3}>
              <Text style={styles.text4}>
                Mr./Mrs./Miss. :{" "}
                {`${props?.item?.firstName}` +
                  " " +
                  `${props?.item?.lastName}`}
              </Text>
            </View>
            <View style={styles.column3}>
              <Text style={styles.text4}>
                Address. :{" " + `${props?.item?.jobLocation}`}
              </Text>
            </View>
          </View>

          <View>
            <Text style={styles.text1}>Appointment Letter</Text>
          </View>
          <View>
            <Text style={styles.text6}>Dear,</Text>
          </View>
          <View style={styles.para3}>
            <Text style={styles.text5}>
              With reference to the discussion we had with you, we, on behalf of
              "PRERNA ACADEMY PVT. LTD., Indore" ,are pleased to offer your the
              position of "
              {`${props?.item?.post}` + " " + `${props?.item?.type}`}". And
              invite you to join PRERNA ACADEMY family.{" "}
            </Text>
            <Text style={styles.text2}>
              The allowances, benefits and other terms and conditions of your
              employment will be as per company policy as applicable form time
              to time. Your compensation will be reviewed in future as per
              company policy. On joining the company you shall be on probation
              for three months. You will abide by the rules and regulations of
              the company as may be in force from time to time. The company
              looks for a long term association with all its employees and
              expects the same from you.
            </Text>
            <Text style={styles.text2}>
              You are the appointment to the post of "
              {`${props?.item?.post}` + " " + `${props?.item?.type}`}" at our
              facility at Indore on the following terms and conditions. <br />
            </Text>
            <Text style={styles.text2}>
              1. Your reporting person is " Director "; you are all discussing
              any matter any decision or any planning concern with center head.
            </Text>
            <Text style={styles.text2}>
              2. Closing of sales 10th of every month & basic salary on 15th of
              every month.
            </Text>
            <Text style={styles.text2}>
              {" "}
              3. Working hours-10:30 A.M. to 6:30 P.M.
            </Text>
            <Text style={styles.text2}>
              4. Your provision period is six month. Based on your performance
              your services will be confirmed with the company in written after
              six months.At anytime should you decide to leave the employment
              you will be required to give one month's notice or forfeit a
              salary for one month in lieu of notice period.
            </Text>
            <Text style={styles.text2}>
              5. Your offered salary is {`${props?.item?.offeredSalary}` + "/-"}.{" "}
            </Text>
          </View>
          <View style={styles.para3}>
            <Text style={styles.text2}>
              6. During the probation period your services can be terminated
              with seven days notice on either side and without any reasons
              whatsoever.If your services are found satisfactory during the
              probation period, you will be confirmed in present position and
              there after your services can be terminated on one months notice
              or either side.
            </Text>
            <Text style={styles.text2}>
              {" "}
              7. Absence for a continuous period of ten days without prior
              approval of your superior, (including overstay on leave/training )
              would result in your losing your lien on the service and the shall
              automatically come to an end without any notice or intimation.{" "}
            </Text>
            <Text style={styles.text2}>
              8. You will be eligible to the benefits of the company's leave
              rules on your confirmation in the company's service.
            </Text>
            <Text style={styles.text2}>
              {" "}
              9. If at any time in our opinion, which is final in this matter
              you are found non-performer or guilty of fraud, dishonest,
              disobedience, disorderly behavior, negligence, indiscipline,
              absence from due without permission or any other conduct
              considered by us deterrent to our interest or of violation of one
              and more terms this letter, your service may be terminated without
              notice and on account of reason of any of the acts or omission the
              company shall be entitled to recover the damages from you.
            </Text>
            <Text style={styles.text2}>
              10. You will not accept any present, commission or any sort of
              gratification in cash or kind from any person, party or firm or
              company having dealing with the company and if you are offered
              any, you should immediately report the same of the management.
            </Text>
            <Text style={styles.text2}>
              11. This appointment letter is being issued to you on the basis of
              the information and particulars furnished by you in your
              application (including bio-data ), at the time of your interview
              and subsequent discussions. If it transpires that you have made a
              false statement (or have not disclosed a material fact ) resulting
              in your being offered this appointment, the management may take
              such action as it deems fit in its sole discretion, including
              termination of your employment.
            </Text>
            <Text style={styles.text2}>
              12. You will be responsible for safekeeping and return in good
              condition and order of all company property, which may be in your
              use, custody or charge.
            </Text>
            <Text style={styles.text2}>
              13.Your present place of work will be at Head office, Indore, but
              during the course of the service, you shall be liable to be posted
              / transferred anywhere to serve any of the company's projects or
              any other establishment in India or outside, at the sole
              discretion of the management. 14.Please sign and return to the
              undersigned the duplicate copy of this letter signifying your
              acceptance
            </Text>
          </View>

          <View>
            <Text style={styles.wish}>
              We welcome you to the " PRERNA ACADEMY PVT. LTD. Indore " family
              and look forward to a fruitful collaboration.{" "}
            </Text>
          </View>
          <View>
            <Text style={styles.sign}>
              Again, congratulations and welcome to the " PRERNA ACADEMY PVT.
              LTD. Indore " family With best wishes For PRERNA ACADEMY PVT. LTD.
              Indore
            </Text>
          </View>
          <View style={styles.emp}>
            {props?.item?.type === "Center Head" ? (
              ''
            ) : (
              <>
                <Text style={styles.text4}>
                  Center Head Name :
                  {`${props?.item?.centerHeadData[0]?.firstName}` +
                    " " +
                    `${props?.item?.centerHeadData[0]?.lastName}`}
                </Text>

                <Text style={styles.text4}>
                  Designation:
                  {`${props?.item?.centerHeadData[0]?.post}` +
                    " " +
                    "Center Head"}
                </Text>
              </>
            )}

            <Text style={styles.text4}>
              Employee Name :{" "}
              {`${props?.item?.firstName}` +
                " " +
                `${props?.item?.lastName}`}
            </Text>

            <Text style={styles.text4}>
              Designation:{" "}
              {`${props?.item?.post}` + " " + `${props?.item?.type}`}
            </Text>
          </View>
          <View>
            <Text style={styles.text7}>Prerna Academy PVT. LTD. Indore</Text>
            <Text style={styles.text7}>
              Address :- 333 A, 334 Indraprasth Tower,3rd Floor, 6 M.G. Road
              Indore-452001(M.P.)
            </Text>
            <Text style={styles.text7}>
              Email :- info@prernaacademy.in Web :- www.prernaacademy.in Contact
            </Text>{" "}
            <Text style={styles.text7}> No.:- 0731-4298599</Text>
          </View>
          <View>
            <Text
              style={styles.pageNumber}
              render={({ pageNumber, totalPages }) =>
                `${pageNumber} / ${totalPages}`
              }
            />
          </View>
        </Page>
        )
      </Document>
    </>
  );
};

export default AppointmentLetter;
