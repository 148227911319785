import {
  Button,
  Container,
  Dialog,
  Grid,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  TextField,
  Typography,
} from "@mui/material";
import { ChangeEvent, Fragment, useState } from "react";
import CommonSnackBar from "../../Components/Common/CommonSnackbar";
import { getRequestByParams } from "../../api/api";
import { GET_STUDENT_FEE_PAYMENT } from "../../api/server";
import PaymentForm from "./PaymentForm";

interface FeeData {
  student: object;
  course: object;
  installmentDetails: [];
  studentId: string;
  _id: string;
}

function FeePayment(props: any) {
  const { types } = props;

  const [users, setUsers] = useState<FeeData[]>([]);
  const [open, setOpen] = useState<boolean>(false);
  const [snackMessage, setSnackMessage] = useState<string>("");
  const [openSnackBar, setOpenSnackBar] = useState<boolean>(false);

  const [fee, setFee] = useState<{ [key: string]: string | null }>({
    fee: null,
    id: null,
  });

  const [paramsObject, setParamsObject] = useState<{ [key: string]: string }>({
    lId: types.lId,
    type: types.type,
  });

  const handleOpenSnackBar = (message: string) => {
    try {
      setSnackMessage(message);
      setOpenSnackBar(true);
    } catch (error) {
      console.error("Error in handleOpenSnackBar:", error);
    }
  };

  const handleCloseSnackBar = () => {
    try {
      setOpenSnackBar(false);
    } catch (error) {
      console.error("Error in handleCloseSnackBar:", error);
    }
  };

  const fetchData = async () => {
    try {
      const response = await getRequestByParams(
        GET_STUDENT_FEE_PAYMENT,
        "",
        "",
        paramsObject
      );
      if (response.data.length === 0) {
        handleOpenSnackBar("Student not found!");
        setUsers([]);
      } else {
        setUsers(response?.data);
        if (response.data[0]?.installmentDetails.length === 0) {
          handleOpenSnackBar("Installment data not found!");
          setUsers([]);
        }
      }
    } catch (error) {
      console.error("Error fetching data:", error);
    }
  };

  const handleClickOpen = (value: any, id: any) => {
    setOpen(true);
    setFee({ ...fee, fee: value, id: id });
  };

  const handleClose = () => {
    setOpen(false);
    fetchData();
  };

  const handleChange = (e: ChangeEvent<HTMLInputElement>) => {
    setParamsObject((preData: { [key: string]: string }) => ({
      ...preData,
      [e.target.name]: e.target.value,
    }));
  };

  return (
    <>
      <Container>
        <Paper elevation={20}>
          <Grid container padding={2} columnSpacing={2}>
            <Grid
              item
              xs={6}
              sx={{
                display: "flex",
                justifyContent: "space-between",
                alignItems: "center",
              }}
            >
              <Typography>Search By Registration ID:</Typography>
              <TextField
                name="studentId"
                type="text"
                size="small"
                sx={{ background: "white" }}
                onChange={handleChange}
              />
            </Grid>
            <Grid
              item
              xs={6}
              sx={{
                display: "flex",
                justifyContent: "flex-start",
              }}
            >
              <Button variant="contained" onClick={fetchData}>
                Find
              </Button>
              <CommonSnackBar
                open={openSnackBar}
                autoHideDuration={3000}
                onClose={handleCloseSnackBar}
                message={snackMessage}
              />
            </Grid>
          </Grid>
          <Grid
            container
            sx={{
              p: 2,
              background: "#0288d1",
              color: "white",
            }}
          >
            <Grid item xs={12}>
              <Typography variant="h5" sx={{ mx: 3 }}>
                Manage Installment Payment
              </Typography>
            </Grid>
          </Grid>
          <TableContainer sx={{ p: 1 }} className="scrollBarCss">
            <Table>
              <TableHead>
                <TableRow>
                  <TableCell
                    sx={{
                      textAlign: "center",
                      border: "1px solid #ddd",
                      fontSize: "15px",
                    }}
                  >
                    Sr.No
                  </TableCell>
                  <TableCell
                    sx={{
                      textAlign: "center",
                      border: "1px solid #ddd",
                      fontSize: "15px",
                    }}
                  >
                    Registration Id
                  </TableCell>
                  <TableCell
                    sx={{
                      textAlign: "center",
                      border: "1px solid #ddd",
                      fontSize: "15px",
                    }}
                  >
                    Student Name
                  </TableCell>
                  <TableCell
                    sx={{
                      textAlign: "center",
                      border: "1px solid #ddd",
                      fontSize: "15px",
                    }}
                  >
                    Email
                  </TableCell>
                  <TableCell
                    sx={{
                      textAlign: "center",
                      border: "1px solid #ddd",
                      fontSize: "15px",
                    }}
                  >
                    Contact Number
                  </TableCell>
                  <TableCell
                    sx={{
                      textAlign: "center",
                      border: "1px solid #ddd",
                      fontSize: "15px",
                    }}
                  >
                    Course Name
                  </TableCell>

                  <TableCell
                    sx={{
                      textAlign: "center",
                      border: "1px solid #ddd",
                      fontSize: "15px",
                    }}
                  >
                    Installment Date
                  </TableCell>
                  <TableCell
                    sx={{
                      textAlign: "center",
                      border: "1px solid #ddd",
                      fontSize: "15px",
                    }}
                  >
                    Installment Amount
                  </TableCell>
                  <TableCell
                    sx={{
                      textAlign: "center",
                      border: "1px solid #ddd",
                      fontSize: "15px",
                    }}
                  >
                    Status
                  </TableCell>
                  <TableCell
                    sx={{
                      textAlign: "center",
                      border: "1px solid #ddd",
                      fontSize: "15px",
                    }}
                  >
                    Rejected Comment
                  </TableCell>
                  <TableCell
                    sx={{
                      textAlign: "center",
                      border: "1px solid #ddd",
                      fontSize: "15px",
                    }}
                  >
                    Action
                  </TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {users?.map((item: any) => (
                  <Fragment key={item._id}>
                    {item?.installmentDetails?.map(
                      (data: any, index: number) => (
                        <Fragment key={index}>
                          <TableRow>
                            <TableCell
                              sx={{
                                textAlign: "center",
                                border: "1px solid #ddd",
                              }}
                            >
                              {index + 1}
                            </TableCell>
                            <TableCell
                              sx={{
                                textAlign: "center",
                                border: "1px solid #ddd",
                              }}
                            >
                              {item.studentId}
                            </TableCell>
                            <TableCell
                              sx={{
                                textAlign: "center",
                                border: "1px solid #ddd",
                              }}
                            >
                              {`${item.student.firstName} ${item.student.lastName}`}
                            </TableCell>
                            <TableCell
                              sx={{
                                textAlign: "center",
                                border: "1px solid #ddd",
                              }}
                            >
                              {item.student.email}
                            </TableCell>
                            <TableCell
                              sx={{
                                textAlign: "center",
                                border: "1px solid #ddd",
                              }}
                            >
                              {item.student.contactNumber}
                            </TableCell>
                            <TableCell
                              sx={{
                                textAlign: "center",
                                border: "1px solid #ddd",
                              }}
                            >
                              {item.course.courseName}
                            </TableCell>
                            <TableCell
                              sx={{
                                textAlign: "center",
                                borderRight: "1px solid #ddd",
                              }}
                            >
                              {data.date}
                            </TableCell>
                            <TableCell
                              sx={{
                                textAlign: "center",
                                borderRight: "1px solid #ddd",
                              }}
                            >
                              {data.amount}
                            </TableCell>
                            <TableCell
                              sx={{
                                textAlign: "center",
                                border: "1px solid #ddd",
                              }}
                            >
                              {data.verifyPayment}
                            </TableCell>
                            <TableCell
                              sx={{
                                textAlign: "center",
                                border: "1px solid #ddd",
                              }}
                            >
                              {data.rejectRecieptComment
                                ? data.rejectRecieptComment
                                : "----"}
                            </TableCell>
                            <TableCell
                              sx={{
                                textAlign: "center",
                                border: "1px solid #ddd",
                              }}
                            >
                              <Button
                                onClick={() =>
                                  handleClickOpen(data.amount, data._id)
                                }
                              >
                                Pay
                              </Button>
                            </TableCell>
                            <Dialog open={open} maxWidth={"md"}>
                              <PaymentForm
                                handleClose={handleClose}
                                fee={fee}
                              />
                            </Dialog>
                          </TableRow>
                        </Fragment>
                      )
                    )}
                  </Fragment>
                ))}
              </TableBody>
            </Table>
          </TableContainer>
        </Paper>
      </Container>
    </>
  );
}

export default FeePayment;
