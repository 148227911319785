import {
  Avatar,
  Container,
  Grid,
  InputLabel,
  Paper,
  TextField,
  Typography,
} from "@mui/material";
import { makeStyles } from "@mui/styles";

const useStyles = makeStyles(() => ({
  avatar: {
    width: 100,
    height: 100,
    borderRadius: "50%",
    border: "1px solid black",
  },
}));

export default function PersonalPreview(props: any) {
  const classes = useStyles();

  return (
    <Container sx={{ mt: 3 }}>
      <Paper sx={{ p: 2 }} elevation={0}>
        {/* Personal Details */}
        <Grid
          container
          rowSpacing={1}
          columnSpacing={{ xs: 1, sm: 2, md: 3 }}
          sx={{ m: 2 }}
        >
          <Grid container xs={12} sm={6} md={12}>
            <Grid item xs={12}>
              <Typography fontWeight="500">Student Details:</Typography>
            </Grid>
            <Grid item xs={12} sm={6} md={3}>
              <InputLabel
                htmlFor="standard-basic"
                sx={{ mt: 3, color: "#0288d1" }}
              >
                First Name :
              </InputLabel>
              <TextField
                id="standard-basic"
                variant="standard"
                sx={{ backgroundColor: "white" }}
                value={props.Data?.studentData?.firstName}
              />
              <InputLabel
                htmlFor="standard-basic"
                sx={{ mt: 3, color: "#0288d1" }}
              >
                Date Of Birth:
              </InputLabel>
              <TextField
                id="standard-basic"
                variant="standard"
                sx={{ backgroundColor: "white" }}
                value={props.Data?.studentData?.dateOfBirth}
              />
            </Grid>
            <Grid item xs={12} sm={6} md={3}>
              <InputLabel
                htmlFor="standard-basic"
                sx={{ mt: 3, color: "#0288d1" }}
              >
                Last Name:
              </InputLabel>
              <TextField
                id="standard-basic"
                variant="standard"
                sx={{ backgroundColor: "white" }}
                value={props.Data?.studentData?.lastName}
              />
              <InputLabel
                htmlFor="standard-basic"
                sx={{ mt: 3, color: "#0288d1" }}
              >
                Mobile No :
              </InputLabel>
              <TextField
                id="standard-basic"
                variant="standard"
                sx={{ backgroundColor: "white" }}
                value={props.Data?.studentData?.contactNumber}
              />
            </Grid>
            <Grid item xs={12} sm={6} md={3}>
              <InputLabel
                htmlFor="standard-basic"
                sx={{ mt: 3, color: "#0288d1" }}
              >
                Email :
              </InputLabel>
              <TextField
                id="standard-basic"
                variant="standard"
                sx={{ backgroundColor: "white" }}
                value={props.Data?.studentData?.email}
              />
              <InputLabel
                htmlFor="standard-basic"
                sx={{ mt: 3, color: "#0288d1" }}
              >
                Whatsapp No :
              </InputLabel>
              <TextField
                id="standard-basic"
                variant="standard"
                sx={{ backgroundColor: "white" }}
                value={props.Data?.studentData?.whatsappNumber}
              />
            </Grid>
            <Grid
              item
              xs={12}
              sm={6}
              md={3}
              display="flex"
              justifyContent={{ sm: "flex-start", md: "center" }}
            >
              <InputLabel
                htmlFor="standard-basic"
                sx={{ mt: 2, color: "#0288d1" }}
              >
                Photo :
              </InputLabel>
              {props.Data?.studentData?.image?.name !== undefined ? (
                <Avatar
                  src={props.Data?.studentData?.image?.path}
                  alt={props.Data?.studentData?.image?.originalname}
                  sx={{ mt: 5 }}
                  className={classes.avatar}
                />
              ) : (
                <Avatar
                  alt="PrernaAcademy"
                  src="https://ik.imagekit.io/nwssoft/NWS_Prerna_Project/prerna-academy-logo.png?updatedAt=1679569413646"
                  sx={{ mt: 5 }}
                  className={classes.avatar}
                />
              )}
            </Grid>
            <Grid container xs={12}>
              <Grid xs={12} sm={6} md={3}>
                <InputLabel
                  htmlFor="standard-basic"
                  sx={{ mt: 3, color: "#0288d1" }}
                >
                  Gender :
                </InputLabel>
                <TextField
                  id="standard-basic"
                  variant="standard"
                  sx={{ backgroundColor: "white" }}
                  value={props.Data?.studentData?.gender}
                />
              </Grid>
              <Grid xs={12} sm={6} md={3}>
                <InputLabel
                  htmlFor="standard-basic"
                  sx={{ mt: 3, color: "#0288d1" }}
                >
                  Aadhaar No :
                </InputLabel>
                <TextField
                  id="standard-basic"
                  variant="standard"
                  sx={{ backgroundColor: "white" }}
                  value={props.Data?.studentData?.aadhaar}
                />
              </Grid>
              <Grid xs={12} sm={6} md={3}>
                <InputLabel
                  htmlFor="standard-basic"
                  sx={{ mt: 3, color: "#0288d1" }}
                >
                  Catageory :
                </InputLabel>
                <TextField
                  id="standard-basic"
                  variant="standard"
                  sx={{ backgroundColor: "white" }}
                  value={props.Data?.studentData?.categories}
                />
              </Grid>
              <Grid xs={12} sm={6} md={3}>
                <InputLabel
                  htmlFor="standard-basic"
                  sx={{ mt: 3, color: "#0288d1" }}
                >
                  Religion :
                </InputLabel>
                <TextField
                  id="standard-basic"
                  variant="standard"
                  sx={{ backgroundColor: "white" }}
                  value={props.Data?.studentData?.religion}
                />
              </Grid>
            </Grid>
            <Grid container xs={12} sx={{ mb: 5 }}>
              <Grid xs={12} sm={6} md={3}>
                <InputLabel
                  htmlFor="standard-basic"
                  sx={{ mt: 3, color: "#0288d1" }}
                >
                  Domacile :
                </InputLabel>
                <TextField
                  id="standard-basic"
                  variant="standard"
                  sx={{ backgroundColor: "white" }}
                  value={props.Data?.studentData?.domicile}
                />
              </Grid>
              <Grid xs={12} sm={6} md={3}>
                <InputLabel
                  htmlFor="standard-basic"
                  sx={{ mt: 3, color: "#0288d1" }}
                >
                  Nationality :
                </InputLabel>
                <TextField
                  id="standard-basic"
                  variant="standard"
                  sx={{ backgroundColor: "white" }}
                  value={props.Data?.studentData?.nationality}
                />
              </Grid>
              <Grid xs={12} sm={6} md={3}>
                <InputLabel
                  htmlFor="standard-basic"
                  sx={{ mt: 3, color: "#0288d1" }}
                >
                  Language:
                </InputLabel>
                <TextField
                  id="standard-basic"
                  variant="standard"
                  sx={{ backgroundColor: "white" }}
                  value={props.Data?.studentData?.language}
                />
              </Grid>
              <Grid xs={12} sm={6} md={3}>
                <InputLabel
                  htmlFor="standard-basic"
                  sx={{ mt: 3, color: "#0288d1" }}
                >
                  Marital Status :
                </InputLabel>
                <TextField
                  id="standard-basic"
                  variant="standard"
                  sx={{ backgroundColor: "white" }}
                  value={props.Data?.studentData?.maritalstatus}
                />
              </Grid>
            </Grid>
          </Grid>

          {/* Parent Details */}
          <Grid
            container
            rowSpacing={1}
            columnSpacing={{ xs: 1, sm: 2, md: 3 }}
            sx={{ mb: 5 }}
          >
            <Grid item xs={12}>
              <Typography fontWeight="500">Parent Details:</Typography>
            </Grid>
            <Grid item xs={12} sm={6} md={4}>
              <InputLabel
                htmlFor="standard-basic"
                sx={{ mt: 3, color: "#0288d1" }}
              >
                Father Name :
              </InputLabel>
              <TextField
                id="standard-basic"
                variant="standard"
                sx={{ backgroundColor: "white" }}
                value={props.Data?.studentData?.fatherName}
              />
              <InputLabel
                htmlFor="standard-basic"
                sx={{ mt: 3, color: "#0288d1" }}
              >
                Parent's Email :
              </InputLabel>
              <TextField
                id="standard-basic"
                variant="standard"
                sx={{ backgroundColor: "white" }}
                value={props.Data?.studentData?.parentEmail}
              />
            </Grid>
            <Grid item xs={12} sm={6} md={4}>
              <InputLabel
                htmlFor="standard-basic"
                sx={{ mt: 3, color: "#0288d1" }}
              >
                Mother Name:
              </InputLabel>
              <TextField
                id="standard-basic"
                variant="standard"
                sx={{ backgroundColor: "white" }}
                value={props.Data?.studentData?.motherName}
              />
              <InputLabel
                htmlFor="standard-basic"
                sx={{ mt: 3, color: "#0288d1" }}
              >
                Annual Income:
              </InputLabel>
              <TextField
                id="standard-basic"
                variant="standard"
                sx={{ backgroundColor: "white" }}
                value={props.Data?.studentData?.annualIncome}
              />
            </Grid>
            <Grid item xs={12} sm={6} md={4}>
              <InputLabel
                htmlFor="standard-basic"
                sx={{ mt: 3, color: "#0288d1" }}
              >
                Parent's Mobile :
              </InputLabel>
              <TextField
                id="standard-basic"
                variant="standard"
                sx={{ backgroundColor: "white" }}
                value={props.Data?.studentData?.parentMobileNumber}
              />
            </Grid>
          </Grid>

          {/* Address Details */}
          <Grid
            container
            rowSpacing={1}
            columnSpacing={{ xs: 1, sm: 2, md: 3 }}
            sx={{ mb: 5 }}
          >
            <Grid item xs={12}>
              <Typography fontWeight="500">
                Permanent and Current Address:
              </Typography>
            </Grid>
            {/* Permanent Address */}
            <Grid item xs={12}>
              <Typography fontWeight="450" sx={{ mt: 2 }}>
                Permanent Address:
              </Typography>
            </Grid>
            <Grid item xs={12} sm={6} md={4}>
              <InputLabel
                htmlFor="standard-basic"
                sx={{ mt: 2, color: "#0288d1" }}
              >
                Address 1 :
              </InputLabel>
              <TextField
                id="standard-basic"
                variant="standard"
                sx={{ backgroundColor: "white" }}
                value={props.Data?.studentData?.permanentAddress1}
              />
              <InputLabel
                htmlFor="standard-basic"
                sx={{ mt: 2, color: "#0288d1" }}
              >
                City:
              </InputLabel>
              <TextField
                id="standard-basic"
                variant="standard"
                sx={{ backgroundColor: "white" }}
                value={props.Data?.studentData?.permanentCity}
              />
            </Grid>
            <Grid item xs={12} sm={6} md={4}>
              <InputLabel
                htmlFor="standard-basic"
                sx={{ mt: 2, color: "#0288d1" }}
              >
                Address 2:
              </InputLabel>
              <TextField
                id="standard-basic"
                variant="standard"
                sx={{ backgroundColor: "white" }}
                value={props.Data?.studentData?.permanentAddress2}
              />
              <InputLabel
                htmlFor="standard-basic"
                sx={{ mt: 2, color: "#0288d1" }}
              >
                ZipCode:
              </InputLabel>
              <TextField
                id="standard-basic"
                variant="standard"
                sx={{ backgroundColor: "white" }}
                value={props.Data?.studentData?.permanentZipCode}
              />
            </Grid>
            <Grid item xs={12} sm={6} md={4}>
              <InputLabel
                htmlFor="standard-basic"
                sx={{ mt: 2, color: "#0288d1" }}
              >
                State :
              </InputLabel>
              <TextField
                id="standard-basic"
                variant="standard"
                sx={{ backgroundColor: "white" }}
                value={props.Data?.studentData?.permanentState}
              />
            </Grid>

            {/* Current Address */}

            <Grid item xs={12}>
              <Typography fontWeight="450" sx={{ mt: 2 }}>
                Present Address:
              </Typography>
            </Grid>
            <Grid item xs={12} sm={6} md={4}>
              <InputLabel
                htmlFor="standard-basic"
                sx={{ mt: 2, color: "#0288d1" }}
              >
                Address 1 :
              </InputLabel>
              <TextField
                id="standard-basic"
                variant="standard"
                sx={{ backgroundColor: "white" }}
                value={props.Data?.studentData?.presentAddress1}
              />
              <InputLabel
                htmlFor="standard-basic"
                sx={{ mt: 2, color: "#0288d1" }}
              >
                City:
              </InputLabel>
              <TextField
                id="standard-basic"
                variant="standard"
                sx={{ backgroundColor: "white" }}
                value={props.Data?.studentData?.presentCity}
              />
            </Grid>
            <Grid item xs={12} sm={6} md={4}>
              <InputLabel
                htmlFor="standard-basic"
                sx={{ mt: 2, color: "#0288d1" }}
              >
                Address 2:
              </InputLabel>
              <TextField
                id="standard-basic"
                variant="standard"
                sx={{ backgroundColor: "white" }}
                value={props.Data?.studentData?.presentAddress2}
              />
              <InputLabel
                htmlFor="standard-basic"
                sx={{ mt: 2, color: "#0288d1" }}
              >
                ZipCode:
              </InputLabel>
              <TextField
                id="standard-basic"
                variant="standard"
                sx={{ backgroundColor: "white" }}
                value={props.Data?.studentData?.presentZipCode}
              />
            </Grid>
            <Grid item xs={12} sm={6} md={4}>
              <InputLabel
                htmlFor="standard-basic"
                sx={{ mt: 2, color: "#0288d1" }}
              >
                State :
              </InputLabel>
              <TextField
                id="standard-basic"
                variant="standard"
                sx={{ backgroundColor: "white" }}
                value={props.Data?.studentData?.presentState}
              />
            </Grid>
          </Grid>
        </Grid>
      </Paper>
    </Container>
  );
}
