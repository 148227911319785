import React, { useState, useEffect } from "react";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableRow from "@mui/material/TableRow";
import Paper from "@mui/material/Paper";
import {
  Button,
  Checkbox,
  Collapse,
  Container,
  FormControl,
  Grid,
  IconButton,
  InputAdornment,
  InputLabel,
  LinearProgress,
  MenuItem,
  Select,
  TableHead,
  TablePagination,
  TextField,
  Typography,
} from "@mui/material";
import SearchIcon from "@mui/icons-material/Search";
import CommonSnackBar from "../../Components/Common/CommonSnackbar";
import { getRequestById, updateRequest } from "../../api/api";
import {
  TEAMLEAD_LIST,
  GET_EX_LIST_BY_CH_ID,
  GET_CH_LED,
  PUT_CH_ASSIGN_LEAD,
} from "../../api/server";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import "../../Components/Home/Home.scss";

// Define form fields and table column name
const columns: any = [
  { id: 1, field: "fname", headerName: "First Name" },
  { id: 2, field: "lname", headerName: "Last Name" },
  { id: 3, field: "email", headerName: "Email" },
  { id: 4, field: "phone", headerName: "Phone" },
  { id: 5, field: "refBy", headerName: "Reference By" },
  { id: 6, field: "refName", headerName: "Reference Name" },
];
export default function ManageAssignedLeadCH(props: any) {
  // for lead data
  const [LeadData, setLeadData] = useState<any[]>([]);

  // state to manage searched data
  const [searchApiData, setSearchApiData] = useState<any[]>([]);

  // for Team Lead List
  const [teamLeadList, setTeamLeadList] = useState<any[]>([]);
  const [selectedTeamLead, setSelectedTeamLead] = useState<string>("");

  // for executive list
  const [executiveList, setExecutiveList] = useState<any[]>([]);
  const [filterExecutiveList, setFilterExecutiveList] = useState<any[]>([]);
  const [selectedExecutive, setSelectedExecutive] = useState<string>("");

  // for pagination
  const [page, setPage] = React.useState(0);
  const [rowsPerPage, setRowsPerPage] = React.useState(10);

  //state to manage search value
  const [filterVal, setFilterVal] = useState<string>("");

  //for progress bar
  const [isLoading] = useState(false);

  //state to maintain executive data
  const [executiveData, setExecutiveData] = useState({});

  // for snack bar
  const [snackMessage, setSnackMessage] = useState("");
  const [openSnackBar, setOpenSnackBar] = useState(false);

  //for expandeable functionality
  const [expandedRow, setExpandedRow] = useState<string | null>(null);

  //state to maintain checked/selected leads in state
  const [selectedLeads, setSelectedLeads] = useState<string[]>([]);

  const handleOpenSnackBar = (message: string) => {
    setSnackMessage(message);
    setOpenSnackBar(true);
  };

  const handleCloseSnackBar = () => {
    setOpenSnackBar(false);
  };

  const handleChangePage = (event: any, newPage: any) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event: any) => {
    setRowsPerPage(+event.target.value);
    setPage(0);
  };

  // Fetch Team Lead Data list
  const getTeamLeadList = async () => {
    try {
      const userId = props?.types?.lId;
      const response: any = await getRequestById(TEAMLEAD_LIST, userId, "");
      setTeamLeadList(response.data);
    } catch (error) {
      console.error("Error loading users:", error);
    }
  };

  // Fetch Executive Data list
  const getExecutiveList = async () => {
    try {
      const userId = props?.types?.lId;
      const response: any = await getRequestById(
        GET_EX_LIST_BY_CH_ID,
        userId,
        ""
      );
      const result = response.data;
      setExecutiveList(result);
      setFilterExecutiveList(result);
    } catch (error) {
      // Handle the error here
      console.error("Error occurred while fetching executive list:", error);
    }
  };

  // Fetch lead record by center head id
  const getCustomers = async () => {
    try {
      const userId = props?.types?.lId;
      const response: any = await getRequestById(GET_CH_LED, userId, "");
      const users = response.data;
      setLeadData(users);
      setSearchApiData(users);
    } catch (error) {
      console.error("Error loading users:", error);
    }
  };

  const handleFilter = (e: any) => {
    if (e.target.value === "") {
      setLeadData(searchApiData);
    } else {
      const filterResult: any = searchApiData.filter((item: any) => {
        const searchValue = e.target.value.toLowerCase();

        return Object.values(item).some((value) => {
          if (typeof value === "string" || typeof value === "number") {
            const itemValue = String(value).toLowerCase();

            return itemValue.includes(searchValue);
          }
          return false;
        });
      });
      setLeadData(filterResult);
    }
    setFilterVal(e.target.value);
  };

  const filterExecutive = (e: any) => {
    setExecutiveList(filterExecutiveList);
    const selectedTeamLead = e.target.value;
    const filteredExecutives = filterExecutiveList.filter(
      (item: any) => item.teamLead === selectedTeamLead
    );
    setExecutiveList(filteredExecutives);
  };

  // update the center head record
  const handleDataAssign = async () => {
    if (Object.keys(executiveData).length === 0) {
      handleOpenSnackBar("Select at least one employee");
      return; // Stop execution if executiveData is empty
    }
    if (Object.keys(selectedLeads).length === 0) {
      handleOpenSnackBar("Select at least one lead to assign");
      return;
    }
    try {
      const userId = props?.types?.lId;
      const data1 = {
        executiveData: executiveData,
        selectedLeads: selectedLeads,
      };
      const response: any = await updateRequest(
        PUT_CH_ASSIGN_LEAD,
        userId,
        data1,
        ""
      );
      if (response) {
        // Handle the response accordingly, for example, show a success message
        handleOpenSnackBar(response.data.message);
        // This will clear the selected executive
      }
      setTimeout(() => {
        getCustomers();
        setSelectedTeamLead(""); // This will clear the selected team lead
        setSelectedExecutive("");
        setExecutiveList(filterExecutiveList);
      }, 3000);
    } catch (error) {
      console.error(error);
      // Handle the error, for example, show an error message
      handleOpenSnackBar("An error occurred. Please try again later.");
    }
  };

  const handleExecutiveChange = (e: any) => {
    const { name, value } = e.target;
    setExecutiveData((prevState: any) => ({
      ...prevState,
      [name]: value,
    }));
  };

  // function for updating selected leads in state
  const handleCheckboxChange = (leadId: string) => {
    if (selectedLeads.includes(leadId)) {
      setSelectedLeads((prevSelected) =>
        prevSelected.filter((id) => id !== leadId)
      );
    } else {
      setSelectedLeads((prevSelected) => [...prevSelected, leadId]);
    }
  };

  const handleRowClick = (rowId: string) => {
    setExpandedRow((prevRowId) => (prevRowId === rowId ? null : rowId));
  };

  //handler for email click and open mail tab
  const handleEmailClick = (email: any) => {
    window.open(`mailto:${email}`, "_blank");
  };

  useEffect(() => {
    getCustomers();
    getTeamLeadList();
    getExecutiveList();
  }, []);

  return (
    <>
      <Container>
        {isLoading && <LinearProgress />}
        <Paper elevation={20}>
          <Grid
            container
            sx={{
              p: 2,
              background: "#0288d1",
              color: "white",
            }}
          >
            <Grid item xs={6}>
              <Typography variant="h5" sx={{ mx: 3 }}>
                Manage Assigned Lead
              </Typography>
            </Grid>
            <Grid
              item
              xs={6}
              sx={{ display: "flex", justifyContent: "flex-end" }}
            >
              <FormControl>
                <TextField
                  label="Search "
                  size="small"
                  value={filterVal}
                  onInput={(e: any) => handleFilter(e)}
                  sx={{ mx: 3, background: "white" }}
                  InputProps={{
                    endAdornment: (
                      <InputAdornment position="end">
                        <IconButton edge="end">
                          <SearchIcon />
                        </IconButton>
                      </InputAdornment>
                    ),
                  }}
                />
              </FormControl>
            </Grid>
          </Grid>

          <Grid container rowSpacing={1} sx={{ mt: 1, p: 1 }}>
            <Grid
              item
              xs={5}
              sx={{
                justifyContent: "center",
                alignItems: "center",
                display: "flex",
              }}
            >
              <FormControl size="small" sx={{ width: "80%" }}>
                <InputLabel id="demo-simple-select-required-label">
                  Team Lead
                </InputLabel>
                <Select
                  sx={{ textAlign: "center" }}
                  labelId="demo-simple-select-required-label"
                  className="form-control"
                  label="Department"
                  type="text"
                  name="teamLeadId"
                  required
                  value={selectedTeamLead}
                  onChange={(e) => {
                    setSelectedTeamLead(e.target.value);
                    filterExecutive(e);
                    handleExecutiveChange(e);
                  }}
                >
                  {teamLeadList?.map((Object: any) => (
                    <MenuItem
                      value={Object.tlUniqueId}
                      key={Object._id}
                      id={Object.empId}
                    >
                      {Object.firstName} {Object.lastName}({Object.empId})
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
            </Grid>
            <Grid
              item
              xs={5}
              sx={{
                justifyContent: "center",
                alignItems: "center",
                display: "flex",
              }}
            >
              <FormControl size="small" sx={{ width: "80%" }}>
                <InputLabel id="demo-simple-select-required-label">
                  Executive
                </InputLabel>
                <Select
                  sx={{ textAlign: "center" }}
                  labelId="demo-simple-select-required-label"
                  label="Department"
                  name="executiveId"
                  value={selectedExecutive}
                  onChange={(e) => {
                    setSelectedExecutive(e.target.value);
                    handleExecutiveChange(e);
                  }}
                >
                  {executiveList?.map((Object: any) => (
                    <MenuItem
                      value={Object._id}
                      key={Object.firstName}
                      id={Object.empId}
                    >
                      {Object.firstName} {Object.lastName}({Object.empId})
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
            </Grid>
            <Grid
              item
              xs={2}
              sx={{
                justifyContent: "center",
                alignItems: "center",
                display: "flex",
              }}
            >
              <Button
                variant="contained"
                color="primary"
                type="submit"
                onClick={() => handleDataAssign()}
              >
                Submit
              </Button>
              <CommonSnackBar
                open={openSnackBar}
                autoHideDuration={5000}
                onClose={handleCloseSnackBar}
                message={snackMessage}
              />
            </Grid>
          </Grid>

          <TableContainer className="scrollBarCss">
            <Table aria-label="simple table" sx={{ mt: 4 }}>
              <TableHead>
                <TableRow>
                  {/* Add an empty cell for the expand icon */}
                  <TableCell
                    sx={{
                      textAlign: "center",
                      fontWeight: "bold",
                      border: "1px solid #ddd",
                    }}
                  ></TableCell>
                  <TableCell
                    sx={{
                      textAlign: "center",
                      fontWeight: "bold",
                      border: "1px solid #ddd",
                    }}
                  >
                    Sr.No
                  </TableCell>
                  {columns.map((user: any) => (
                    <React.Fragment key={user.id}>
                      <TableCell
                        className="border"
                        sx={{
                          textAlign: "center",
                          border: "1px solid #ddd",
                          fontSize: "15px",
                        }}
                      >
                        {user.headerName}
                      </TableCell>
                    </React.Fragment>
                  ))}
                  <TableCell
                    key="assignToHeader"
                    sx={{
                      textAlign: "center",
                      fontWeight: "bold",
                      border: "1px solid #ddd",
                    }}
                  >
                    Action
                  </TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {LeadData.slice(
                  page * rowsPerPage,
                  page * rowsPerPage + rowsPerPage
                ).map((item: any, index: number) => (
                  <React.Fragment key={item._id}>
                    <TableRow
                      className="onhover"
                      sx={{
                        textAlign: "center",
                        border: "1px solid #ddd",
                      }}
                    >
                      {/* Add the expand icon here */}
                      <TableCell
                        sx={{
                          textAlign: "center",

                          cursor: "pointer",
                          border: "1px solid #ddd",
                        }}
                      >
                        <IconButton
                          size="small"
                          aria-label="expand row"
                          sx={{
                            color:
                              expandedRow === item._id ? "#f50057" : "#000",
                          }}
                          onClick={(e) => {
                            e.stopPropagation();
                            handleRowClick(item._id);
                          }}
                        >
                          <ExpandMoreIcon />
                        </IconButton>
                      </TableCell>
                      <TableCell
                        sx={{
                          textAlign: "center",
                          borderRight: "1px solid #ddd",
                          borderLeft: "1px solid #ddd",
                        }}
                      >
                        {page * rowsPerPage + index + 1}
                      </TableCell>
                      {columns.map((column: any) => {
                        if (column.field === "email") {
                          return (
                            <>
                              <TableCell
                                key={column.id}
                                sx={{
                                  textAlign: "center",
                                  borderRight: "1px solid #ddd",
                                  borderLeft: "1px solid #ddd",
                                }}
                                onClick={() =>
                                  handleEmailClick(item[column.field])
                                }
                                style={{ cursor: "pointer", color: "blue" }}
                              >
                                {item[column.field]}
                              </TableCell>
                            </>
                          );
                        } else {
                          return (
                            <>
                              <TableCell
                                key={column.id}
                                sx={{
                                  textAlign: "center",
                                  borderRight: "1px solid #ddd",
                                  borderLeft: "1px solid #ddd",
                                }}
                              >
                                {item[column.field]}
                              </TableCell>
                            </>
                          );
                        }
                      })}
                      <TableCell
                        key="assignTo"
                        sx={{
                          textAlign: "center",
                          borderRight: "1px solid #ddd",
                          borderLeft: "1px solid #ddd",
                        }}
                      >
                        <Checkbox
                          checked={selectedLeads.includes(item._id)}
                          onChange={() => handleCheckboxChange(item._id)}
                          color="primary"
                        />
                      </TableCell>
                    </TableRow>

                    <TableRow>
                      <TableCell colSpan={columns.length + 3}>
                        <Collapse
                          in={expandedRow === item._id}
                          timeout="auto"
                          unmountOnExit
                        >
                          <Grid container>
                            <Grid item xs={12} sm={4}>
                              <Typography
                                sx={{
                                  fontWeight: "bold",
                                  display: "inline",
                                  mx: 2,
                                }}
                              >
                                Experience:
                              </Typography>
                              <Typography sx={{ display: "inline" }}>
                                {item.experience}
                              </Typography>
                            </Grid>
                            <Grid item xs={12} sm={4}>
                              <Typography
                                sx={{
                                  fontWeight: "bold",
                                  display: "inline",
                                  mx: 2,
                                }}
                              >
                                Qualification:
                              </Typography>
                              <Typography sx={{ display: "inline" }}>
                                {item.qualification}
                              </Typography>
                            </Grid>
                            <Grid item xs={12} sm={4}>
                              <Typography
                                sx={{
                                  fontWeight: "bold",
                                  display: "inline",
                                  mx: 2,
                                }}
                              >
                                State:
                              </Typography>
                              <Typography sx={{ display: "inline" }}>
                                {item.state}
                              </Typography>
                            </Grid>
                            <Grid xs={12} sm={4}>
                              <Typography
                                sx={{
                                  fontWeight: "bold",
                                  display: "inline",
                                  mx: 2,
                                }}
                              >
                                City:
                              </Typography>
                              <Typography sx={{ display: "inline" }}>
                                {item.city}
                              </Typography>
                            </Grid>
                            <Grid xs={12} sm={4}>
                              <Typography
                                sx={{
                                  fontWeight: "bold",
                                  display: "inline",
                                  mx: 2,
                                }}
                              >
                                Passing Year:
                              </Typography>
                              <Typography sx={{ display: "inline" }}>
                                {item.passingYear}
                              </Typography>
                            </Grid>
                          </Grid>
                        </Collapse>
                      </TableCell>
                    </TableRow>
                  </React.Fragment>
                ))}
              </TableBody>
            </Table>
          </TableContainer>

          <Grid sx={{ mt: 2 }}>
            <TablePagination
              rowsPerPageOptions={[10, 25, 50, 100]}
              component="div"
              count={LeadData.length}
              rowsPerPage={rowsPerPage}
              page={page}
              onPageChange={handleChangePage}
              onRowsPerPageChange={handleChangeRowsPerPage}
            ></TablePagination>
          </Grid>
        </Paper>
      </Container>
    </>
  );
}
