import {
  Button,
  Container,
  FormControl,
  FormControlLabel,
  Grid,
  Radio,
  RadioGroup,
} from "@mui/material";
import TextField from "@mui/material/TextField";
import Typography from "@mui/material/Typography";
import { debounce } from "lodash";
import React, { useEffect, useState } from "react";
import CommonSnackBar from "../../Components/Common/CommonSnackbar";
import useValidation from "../../Hooks/useTypescriptValidation";
import { getRequestById } from "../../api/api";
import { FIND_LEAD } from "../../api/server";

interface SearchData {
  input: string;
  type: string;
}

export default function LeadSearch(props: any) {
  const {
    setActiveStep,
    setLeadId,
    leadId,
    setLeads,
    leads,
    setShowSubmit,
    types,
  } = props;
  const { eventHandler } = useValidation();

  const [inputValue, setInputValue] = useState<SearchData>({
    input: "",
    type: "text",
  });

  const [errors, setErrors] = useState<{ [key: string]: string }>({
    text: "",
    email: "",
    number: "",
  });

  const [show, setShow] = useState<string>("hidden");
  const [showDetails, setShowDetails] = useState<number>(0);
  const [openSnackBar, setOpenSnackBar] = useState(false);
  const [snackMessage, setSnackMessage] = useState<string>("");

  const handleOpenSnackBar = () => {
    setOpenSnackBar(true);
    setTimeout(() => {
      setOpenSnackBar(false);
    }, 5000);
  };

  const validationHandler = debounce((e: any, alterName?: any) => {
    const val = e.target.value;
    const id = alterName;
    setInputValue({ input: val, type: show });
    const err = eventHandler(id, val);
    setErrors((prevData: any) => ({
      ...prevData,
      [e.target.name]: err,
    }));

    if (e.target.value.length === 0) {
      setErrors((prevData: any) => ({
        ...prevData,
        [e.target.name]: "",
      }));
    }
  }, 2000);

  const validator = () => {
    for (let field in errors) {
      if (errors[field] !== "") {
        return false;
      }
    }
    return true;
  };

  const fetchLeads = async () => {
    try {
      if (validator()) {
        const response: any = await getRequestById(
          FIND_LEAD,
          `${inputValue.input}/${inputValue.type}/${types.lId}`,
          ""
        );

        if (leadId !== "" || leads) {
          setLeads(undefined);
          setLeadId("");
        }

        if (response.data?.result) {
          const data = response.data?.result;
          setLeads(data);
        }

        if (response.data?.message === "Lead not found") {
          setLeads(undefined);
          setLeadId("");
          setSnackMessage(response.data?.message);
          handleOpenSnackBar();
        }
      } else {
        setSnackMessage("Clear the errors!");
        handleOpenSnackBar();
      }
    } catch (error) {
      console.error("Error fetching leads:", error);
    }
  };

  const handleInputChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    if (event.target.name === "text") {
      const userInput: string = String(event.target.value);
      const upperCaseInput: string = userInput.toUpperCase();
      setInputValue({ input: upperCaseInput, type: show });
    } else {
      const userInput = event.target.value;
      setInputValue({ input: userInput, type: show });
    }
  };

  const setInputField = (e: React.ChangeEvent<HTMLInputElement>) => {
    setShow(e.target.value);
    setInputValue({
      input: "",
      type: e.target.value,
    });
    setErrors({ text: "", email: "", number: "" });
  };

  const setId = () => {
    if (leads?.ldUniqueId) {
      setLeadId(leads.ldUniqueId);
      setActiveStep(1);
      window.scrollTo(0, 0);
      setShowSubmit((showSubmit: any) => ({
        ...showSubmit,
        leadConnected: true,
      }));
    } else {
      alert("Error in connecting Lead");
    }
  };

  useEffect(() => {
    setShowDetails((showDetails) => showDetails + 1);
  }, [leadId]);

  return (
    <>
      <Container>
        <Grid item>
          <Grid
            item
            sx={{
              display: "flex",
              flexDirection: "column",
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            <Typography variant="h6">FIND LEAD BY</Typography>
            <FormControl>
              <RadioGroup
                aria-labelledby="demo-radio-buttons-group-label"
                defaultValue="leadId"
                name="radio-buttons-group"
                onChange={setInputField}
                sx={{ flexDirection: "row" }}
              >
                <FormControlLabel
                  value="text"
                  control={<Radio />}
                  label="Lead ID"
                />
                <FormControlLabel
                  value="email"
                  control={<Radio />}
                  label="Email"
                />
                <FormControlLabel
                  value="number"
                  control={<Radio />}
                  label="Phone"
                />
              </RadioGroup>
            </FormControl>
          </Grid>
          <Grid container spacing={2}>
            <Grid
              item
              xs={12}
              sx={{
                display: "flex",
                flexDirection: "column",
                justifyContent: "center",
                alignItems: "center",
              }}
            >
              {show === "text" && (
                <TextField
                  label="ID"
                  variant="outlined"
                  type="text"
                  name="text"
                  value={inputValue.input}
                  error={Boolean(errors.text)}
                  helperText={errors.text}
                  sx={{ width: "60%", background: "white" }}
                  onChange={(e: any) => {
                    validationHandler(e, "alphanumeric");
                    handleInputChange(e);
                  }}
                />
              )}
              {show === "email" && (
                <TextField
                  label="Email"
                  variant="outlined"
                  type="email"
                  name="email"
                  value={inputValue.input}
                  error={Boolean(errors.email)}
                  helperText={errors.email}
                  sx={{ width: "60%", background: "white" }}
                  onChange={(e: any) => {
                    validationHandler(e, "email");
                    handleInputChange(e);
                  }}
                />
              )}
              {show === "number" && (
                <TextField
                  label="Phone"
                  variant="outlined"
                  type="number"
                  name="number"
                  value={inputValue.input}
                  error={Boolean(errors.number)}
                  helperText={errors.number}
                  sx={{ width: "60%", background: "white" }}
                  onChange={(e: any) => {
                    validationHandler(e, "mobile");
                    handleInputChange(e);
                  }}
                />
              )}
            </Grid>
            {show !== "hidden" && (
              <>
                <Grid
                  item
                  xs={12}
                  sx={{
                    display: "flex",
                    flexDirection: "column",
                    justifyContent: "center",
                    alignItems: "center",
                  }}
                >
                  <Button variant="contained" onClick={fetchLeads}>
                    Find
                  </Button>
                  <CommonSnackBar message={snackMessage} open={openSnackBar} />
                </Grid>
              </>
            )}
          </Grid>
          {leads !== undefined && leads && (
            <Grid item>
              <Grid
                item
                sx={{
                  mt: 2,
                  display: "flex",
                  flexDirection: "column",
                  justifyContent: "center",
                  alignItems: "center",
                }}
              >
                <Typography variant="h6">Record Found!</Typography>
                <Typography>Lead Id: {leads.ldUniqueId}</Typography>
                <Typography>First Name: {leads.fname}</Typography>
                <Typography>Last Name: {leads.lname}</Typography>
                <Typography>Email: {leads.email}</Typography>
                <Typography>Phone: {leads.phone}</Typography>
                <Grid
                  item
                  container
                  justifyContent="center"
                  sx={{
                    mt: 2,
                  }}
                >
                  {showDetails && leadId === "" && (
                    <Button
                      variant="contained"
                      sx={{
                        flex: 1,
                        margin: "0 35%",
                      }}
                      onClick={setId}
                    >
                      Connect This Lead
                    </Button>
                  )}
                  {showDetails && leadId !== "" && (
                    <Grid container spacing={2} justifyContent="center">
                      <Grid item>
                        <Button
                          variant="contained"
                          onClick={() => {
                            setLeadId("");
                            setLeads(undefined);
                            setShowSubmit((showSubmit: any) => ({
                              ...showSubmit,
                              leadConnected: false,
                            }));
                          }}
                        >
                          Disconnect This Lead
                        </Button>
                      </Grid>
                      <Grid item>
                        <Button
                          variant="contained"
                          onClick={() => {
                            setActiveStep(1);
                            window.scrollTo(0, 0);
                          }}
                        >
                          Next
                        </Button>
                      </Grid>
                    </Grid>
                  )}
                </Grid>
              </Grid>
            </Grid>
          )}
        </Grid>
      </Container>
    </>
  );
}
